import { Field } from "components/input/field";
import {
  Button,
  Spinner,
  SubmitButton,
  TextWithTooltip,
} from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { useAsyncFunction } from "controllers/hooks";
import React, { useState } from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { reduxForm } from "redux-form";
import { FIRESTORE } from "constants/firestore";
import firebase from "firebase";
import { forzarPeajeMinYmax } from "controllers/validations";

export const BillCabifyModal = ({
  show,
  idTransfer,
  uidDriver,
  idModule,
  handleClose,
}: {
  idTransfer: string;
  uidDriver: string;
  show: boolean;
  idModule?: string;
  handleClose: any;
}) => {
  const handleSubmitData = async (values: any) => {
    console.log(values);

    let monto;
    if (values.amount.includes(",") || values.amount.includes(".")) {
      monto = values.amount;
      if (monto.includes(".")) {
        monto = monto.replace(".", "");
      }
      if (monto.includes(",")) {
        monto = monto.replace(",", ".");
      }
    }
    const data = {
      amount: monto ? monto : values.amount,
      origin_time: values.origin_date
        ? (values.origin_date as Date).toJSON()
        : undefined,
      destination_time: values.destination_date
        ? (values.destination_date as Date).toJSON()
        : undefined,
      lat_or: values.lat_or,
      long_or: values.long_or,
      lat_des: values.lat_des,
      long_des: values.long_des,
      tolls: values.tolls,
    };
    console.log(data);

    await CALLABLE_FUNCTIONS.ADMIN_BILL_CABIFY_EDIT(
      idTransfer,
      uidDriver,
      data
    );
    const moduleRef = (
      await FIRESTORE.DOCUMENT_MODULE_TRANSFER(idModule!).get()
    ).ref;

    await moduleRef?.update({
      reject: firebase.firestore.FieldValue.delete(),
      "sale.billed": 0,
    });
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Facturar Compra</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormEditTransferRedux onSubmit={handleSubmitData} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          style={{ textAlign: "center", width: "4rem" }}
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
//const [monto, setMonto] = useState<string>();
// const handleChange = (e: any) => {
//   let palabra: string = e.target.value;
//   if (palabra.includes(".")) {
//     palabra = palabra.replace(".", "");
//   }
//   if (palabra.includes(",")) {
//     palabra = palabra.replace(",", ".");
//   }
//   setMonto(palabra);
// };
const FormEditTransfer = ({
  handleSubmit,
  submitSucceeded,
  submitting,
  error,
}: {
  handleSubmit: any;
  submitSucceeded: boolean;
  submitting: boolean;
  error: any;
}) => {
  if (submitSucceeded) {
    return <Alert variant="success">Traslado modificado</Alert>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error.message}</Alert>}</Row>

      <Row>
        <Col>
          <Row>
            <Col xl={6}>
              <Field
                labelinput={
                  <TextWithTooltip textTooltip="Valor del viaje">
                    Valor Viaje
                  </TextWithTooltip>
                }
                name="amount"
                type="text"
                validate={cantbezero}
              />
              <Field
                labelinput={<>Fecha y hora en Origen</>}
                name="origin_date"
                type="datetime-picker"
              />
              <Field
                labelinput={
                  <TextWithTooltip textTooltip="Latitud Origen">
                    Latitud Origen
                  </TextWithTooltip>
                }
                name="lat_or"
                type="number"
              />
              <Field
                labelinput={
                  <TextWithTooltip textTooltip="Latitud Destino">
                    Latitud Destino
                  </TextWithTooltip>
                }
                name="lat_des"
                type="number"
              />
            </Col>
            <Col xl={6}>
              <Field
                labelinput={
                  <TextWithTooltip textTooltip="Valor de los peajes">
                    Peajes
                  </TextWithTooltip>
                }
                name="tolls"
                type="number"
                validate={forzarPeajeMinYmax}
              />{" "}
              <Field
                labelinput={<>Fecha y hora en Destino</>}
                name="destination_date"
                type="datetime-picker"
              />
              <Field
                labelinput={
                  <TextWithTooltip textTooltip="Longitud Origen">
                    Longitud Origen
                  </TextWithTooltip>
                }
                name="long_or"
                type="number"
              />
              <Field
                labelinput={
                  <TextWithTooltip textTooltip="Longitud Destino">
                    Longitud Destino
                  </TextWithTooltip>
                }
                name="long_des"
                type="number"
              />
            </Col>
          </Row>
          <SubmitButton block submitting={submitting}>
            Realizar cambios
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};

const FormEditTransferRedux = reduxForm({
  form: "edit-cabifyData",
})(FormEditTransfer);

export const cantbezero = (value: any) =>
  Number(value) < 0 || Number(value) > 20000 ? "El valor no puede ser menor a $0 o mayor a $20000" : undefined;
