import React from 'react';
import {Container} from 'react-bootstrap'
import {Center,Padding,P} from 'components/styles'
import { HeaderLandingSubpages } from '../Privacy';

const TermsAndConditionsPage = () => (
    <>
    <HeaderLandingSubpages />
    <Container style={{backgroundColor:"white"}}>
    <Padding pad={"20px"}>
    <Center><h4>Términos y condiciones generales de uso de la PLATAFORMA de SATAPP</h4></Center>

    <Center><h5>Definiciones preliminares</h5></Center>
    <P><b>CONDUCTOR/OFERENTE:</b> se denomina a la persona que presta el servicio de
    transporte privado de personas y/o equipaje y/o cosas y/o mensajería en forma
    directa al USUARIO SOLICITANTE, y a su exclusivo costo y responsabilidad, pudiendo
    ser este titular dominial o no de la unidad vehicular.</P>
    <P><b>SATAPP:</b> SATAPP S.R.L., CUIT : 30-71620728-1, una agencia de remises, titular del Sitio
    Web y la Aplicación a través del cual se pondrá en funcionamiento la intermediación
    que permitirá conectar al CONDUCTOR con el Usuario Solicitante que requiera de los
    servicios de transporte de pasajeros y/o equipaje y/o mensajería y/o cosas a brindados
    por el CONDUCTOR.</P>
    <P><b>SITIO WEB:</b> www.satapp.com.ar</P>
    <P><b>APLICACIÓN:</b> Es la aplicación informática de titularidad de SATAPP para dispositivos
    inteligentes comúnmente denominados como smartphones.</P>
    <P><b>PLATAFORMA:</b> Plataforma tecnológica desarrollada por SATAPP, por intermedio de la
    cual se llevará acabo los servicios de intermediación asumidos y que permitirá
    conectar al CONDUCTOR con las ofertas de Orden efectuadas por el USUARIO
    SOLICITANTE. La presente definición comprende el SITIO WEB y la APLICACIÓN. Por
    PLATAFORMA también se entenderá cualquier cana de comunicación puesto a
    disposición del CONDUCTOR y/o del USUARIO SOLICITANTE por parte de SATAPP.</P>
    <P><b>SERVICIO:</b> constituye el traslado de un pasajero y/o equipaje y/o cosas y/o mensajería
    de un punto de origen a uno de destino en un horario preestablecido.</P>
    <P><b>PASAJERO:</b> persona a trasladar por el CONDUCTOR.</P>
    <P><b>CLIENTE/ USUARIO SOLICITANTE:</b> es el tomador del servicio de transporte llevado
    adelante por el CONDUCTOR.</P>

    <P><b>ESPERA:</b> constituye el tiempo que transcurre desde que finaliza el tramo de ida hasta
    que comienza el tramo de vuelta. Por ESPERA también se entenderá el tiempo
    transcurrido desde que el CONDUCTOR llega al PUNTO DE ORIGEN y el PASAJERO sube
    al vehículo y/o el CONDUCTOR recibe el equipaje y/o cosas y/o mensajería a ser
    trasladada.</P>
    <P><b>RED O NUBE:</b> constituye el ciber espacio donde se intercambia información y servicios
    relacionados con la prestación del servicio.</P>
    <P><b>INICIO:</b> se entiende que es el momento en que el CONDUCTOR informa que está en
    camino a buscar al PASAJERO y/o la mercadería y/o equipaje y/o cosas al punto de
    origen.</P>
    <P><b>COMIENZO:</b> se entiende que el pasajero está a bordo y/o el CONDUCTOR ha recibido
    el equipaje y/o cosa y/o mensajería a ser trasladada y comienza el viaje desde el punto
    de origen hacia el punto de destino.</P>
    <P><b>FINALIZACION:</b> se entiende que el CONDUCTOR llego con el pasajero y/o el equipaje
    y/o la cosa y/o la mensajería al punto de destino.</P>
    <P><b>BASE:</b> dirección que el CONDUCTOR declara lugar desde el cual generalmente iniciará
    el viaje para ir en búsqueda del pasajero y/o el equipaje y/o la cosa y/o la mensajería.</P>
    <P><b>PUNTO DE GEOLOCALIZACION:</b> dirección en tiempo real en la que se encuentra el
    CONDUCTOR con su vehículo al momento de tomar el servicio.</P>
    <P><b>PUNTO DE ORIGEN:</b> dirección en la cual el pasajero y/o el equipaje y/o la cosa y/o la
    mensajería ingresa al vehículo.</P>
    <P><b>PUNTO DE DESTINO:</b> dirección en la cual el pasajero y/o el equipaje y/o la cosa y/o la
    mensajería desciende del vehículo y se da por finalizado el viaje.</P>
    <P><b>TRASLADO o VIAJE NEGATIVO:</b> consiste en un servicio que no pudo realizarse o
    finalizarse por alguna de las causales previstas en los presentes términos y condiciones.</P>
    <P><b>VIAJE CANCELADO:</b> tendrá el sentido indicado en la cláusula2.38 de los presentes.</P>

    <P><b>ORDEN:</b> órdenes de traslados ingresadas por el USUARIO SOLICITANTE por medio de la
    PLATAFORMA y/o cualquier otro medio de comunicación dispuestos por SATAPP y
    remitidas al CONDUCTOR.</P>

    <Center><h5>CLÁUSULAS</h5></Center>

    <P><b>Primera: Objeto. Primeras aproximaciones. Contenido.</b></P>
    <P>1.1 Los presentes términos y condiciones generales regulan la relación jurídica entre el
    CONDUCTOR, SATAPP y el USUARIO SOLICITANTE, como asimismo el mecanismo de
    funcionamiento de la PLATAFORMA de SATAPP (SATAPP S.R.L., una sociedad de
    responsabilidad limitada constituida y existente de conformidad con la legislación de la
    República Argentina, CUIT 30-71620728-1).</P>
    <P>1.2 La PLATAFORMA está dirigida exclusivamente a usuarios residentes en Argentina.
    Los usuarios que residan o se encuentren domiciliados en cualquier otro lugar y que
    decidan acceder y/o utilizar la PLATAFORMA lo harán bajo su propia responsabilidad y
    riesgo y deberán asegurarse de que tal acceso y/o utilización cumple con la legislación
    local aplicable.</P>
    <P>1.3 Por intermedio de la PLATAFORMA, SATAPP desarrollará la intermediación entre
    los servicios brindados por el CONDUCTOR y el SERVICIO requerido por el USUARIO
    SOLICITANTE.</P>
    <P>1.4 Al acceder y utilizar la PLATAFORMA y/o los canales de comunicación rest appi y/o
    comunicaciones por correo electrónico y/o cualquier otro medio de comunicación habilitado
    por SATAPP, el CONDUCTOR y el USUARIO SOLICITANTE aceptan y reconocen que han
    leído, comprendido y aceptado los presentes términos y condiciones generales de uso
    de la PLATAFORMA, comprometiéndose en todo momento a cumplirlos en su
    totalidad.</P>

    <P>1.5 Asimismo, el CONDUCTOR y el USUARIO SOLICITANTE al utilizar la PLATAFORMA
    y/o los canales de comunicación rest appi y/o cualquier otro medio de comunicación
    dispuesto por SATAPP, aceptan y reconocen que han leído, comprendido y aceptado la
    política de privacidad de SATAPP.</P>
    <P>1.6 Por su parte, el CONDUCTOR al acceder a la PLATAFORMA también acepta y
    reconoce que ha leído, comprendido y aceptado los términos y condiciones
    particulares de uso de la PLATAFORMA para conductores.</P>
    <P>1.7 SATAPP se reserva el derecho de proceder a modificar en cualquier momento los
    presentes términos y condiciones generales de uso de la PLATAFORMA, la política de
    privacidad de SATAPP y/o los términos y condiciones particulares de uso de la
    PLATAFORMA para conductores. Cada vez que se produzca una modificación en los
    presentes términos y condiciones y/o en la política de privacidad de SATAPP y/o los
    términos y condiciones particulares de uso de la PLATAFORMA para los conductores,
    SATAPP deberá notificar las modificaciones al CONDUCTOR y/o al USUARIO
    SOLICITANTE con la finalidad de que estos procedan a aceptarlas por intermedio de la
    PLATAFORMA- dentro de las 48 horas de recibida la notificación. El CONDUCTOR y/o el
    USUARIO SOLICITANTE no podrán utilizar la PLATAFORMA hasta tanto haya aceptados
    dichas modificaciones, y por consiguiente no podrán emitir y/o aceptar y/o rechazar la
    ORDEN, según corresponda en cada caso.</P>
    <P>1.8 SATAPP se reserva el derecho de suspender, interrumpir o dejar de operar la
    PLATAFORMA en cualquier momento, sin que esto le genere responsabilidad ni el
    derecho al CONDUCTOR ni al USUARIO SOLICITANTE de obtener indemnización alguna.</P>
    <P>1.9 El ingreso a la PLATAFORMA le facilitará al CONDUCTOR y al USUARIO SOLICITANTE
    el acceso a determinados contenidos, información y datos proporcionados por
    SATAPP. SATAPP se reserva el derecho de efectuar los cambios y actualización que
    considere pertinentes sobre la PLATAFORMA, su contenido y configuración como
    respecto de las condiciones de acceso y uso.</P>
    <P>1.10 SATAPP no garantiza que el contenido proporcionado por intermedio de la
    PLATAFORMA sea correcto, certeros, actualizados y completo.</P>

    <P>1.11 Es responsabilidad exclusiva del CONDUCTOR la prestación y ejecución de los
    servicios de transporte privado de pasajeros y/o equipaje y/o cosas y/o mensajería en
    función de la orden de pedido (la “ORDEN”) que emita el USUARIO SOLICITANTE.</P>
    <P>1.12 SATAPP únicamente pone a disposición del CONDUCTOR y el USUARIO
    SOLICITANTE la PLATAFORMA y canales de comunicación, lo que permitirá al
    CONDUCTOR aceptar y/o rechazar la ORDEN y al USUARIO SOLICITANTE requerir los
    servicios de transporte brindados por el CONDUCTOR.</P>
    <P>1.13 El CONDUCTOR podrá conectarse y desconectarse de la PLATAFORMA cuando así
    lo desee, en función de los horarios y días que el mismo disponga. Sin perjuicio de ello,
    una vez aceptada la ORDEN y acontecido el INICIO, el CONDUCTOR deberá permanecer
    conectado a la PLATAFORMA.</P>
    <P>1.14 No existe relación laboral, de subordinación ni de dependencia entre el
    CONDUCTOR y SATAPP. El CONDUCTOR no es dependiente, subordinado, empleado,
    agente, representante ni funcionario de SATAPP. Por ello es que entre SATAPP y el
    CONDUCTOR existe un vínculo contractual de prestación de servicios, donde cada uno
    de ellos actúa de forma autónoma, independiente, por cuenta propia y a su propio
    riesgo, debiendo cada uno de ellos llevar adelante su actividad específica, siendo la
    actividad del CONDUCTOR el servicio de transporte requerido por el USUARIO
    SOLICITANTE.</P>
    <P>1.15 Es de responsabilidad exclusiva del CONDUCTOR llevar adelante los SERVICIOS
    requeridos por el USUARIO SOLICITANTE, siendo decisión exclusiva del CONDUCTOR
    aceptar o rechazar la ORDEN, elegir los trayectos de viaje, conectarse o desconectarse
    a la PLATAFORMA a los efectos de recibir las diversas ordenes de SERVICIO.</P>
    <P>1.16 Es responsabilidad del CONDUCTOR cumplir los protocolos de seguridad, higiene
    y prevención dictados por el Estado Nacional, provincial, municipal y/o de la Ciudad
    Autónoma de Buenos Aires con motivo de la pandemia ocasionada por el COVID-19
    que resulten aplicables a los servicios de transporte.</P>
    <P>1.17 Asimismo, el CONDUCTOR asume el compromiso de respetar los lineamientos y/o
    recomendaciones brindadas por SATAPP para el desarrollo del SERVICIO, dejando
    expresa constancia de que dichos lineamientos no son vinculantes para el
    CONDUCTOR atento la independencia y autonomía existente entre las partes.</P>
    <P>1.18 SATAPP se reserva el derecho de modificar la configuración, el contenido,
    presentación y software de la PLATAFORMA, en forma unilateral y sin que ello genere
    responsabilidad alguna de su parte para con el CONDUCTOR y/o el USUARIO
    SOLICITANTE.</P>
    <P>1.19 SATAPP actúa en calidad de intermediario en la prestación de servicios de
    transporte al USUARIO SOLICITANTE. Será responsabilidad exclusiva del CONDUCTOR
    la prestación de los servicios de transporte contratados por el USUARIO SOLICITANTE
    en función de los servicios ofrecidos por SATAPP.</P>

    <P><b>Segunda. Registro del usuario. Acceso y uso de la PLATAFORMA.</b></P>
    <P>2.1 Para poder acceder a los servicios de intermediación brindados por SATAPP, el
    CONDUCTOR y el USUARIO SOLICITANTE (en adelante, los “USUARIOS”) deberán
    contar con un usuario registrado en la PLATAFORMA.</P>
    <P>2.2 El proceso de registro y habilitación de usuario en la PLATAFORMA para el
    CONDUCTOR, como su funcionamiento y procesos de registro han sido especificados y
    detallados en los términos y condiciones particulares de uso de la PLATAFORMA para
    conductores, a los cuales se puede acceder desde el SITIO WEB y/o la APLICACIÓN.</P>
    <P>2.3 El usuario en la PLATAFORMA para el USUARIO SOLICITANTE será creado
    directamente por SATAPP en función de lo informado en los contratos suscriptos entre
    SATAPP y el USUARIO SOLICITANTE.</P>
    <P>2.4 SATAPP se reserva el derecho de aceptar o rechazar libremente la solicitudes de
    registro de los USUARIOS.</P>
    <P>2.5 Todos los datos introducidos la PLATAFORMA por los USUARIOS deberán ser
    exactos, actuales y veraces en todo momento.</P>

    <P>2.6 El acceso y uso de la PLATAFORMA estará sujeto una contraseña establecida por
    cada uno de los USUARIOS.</P>
    <P>2.7 Los USUARIOS serán responsables en todo momento de la custodia de su
    contraseña, asumiendo, en consecuencia, cualesquiera daños y perjuicios que
    pudieran derivarse de su uso indebido, así como de la cesión, revelación o extravío de
    la misma.</P>
    <P>2.8 En caso de olvido de la contraseña o cualquier otra circunstancia que suponga un
    riesgo de acceso y/o utilización por parte de terceros no autorizados, los USUARIOS
    deberán comunicarlo a SATAPP en forma inmediata a fin de que éste proceda a
    bloquear y sustituir la misma. Cualquier operación y/o comunicación realizada ante de
    la mencionada comunicación se considerará que ha sido efectuada por el titular del
    usuario, quien será responsable de todos los daños que se deriven de cualquier acceso
    y/o uso no autorizado que se realice con anterioridad a dicha comunicación, debiendo
    hacerse cargo del pago de todos los gastos, daños y perjuicios ocasionados.</P>
    <P>2.10 Los USUARIOS deberán mantener actualizados los datos e información
    introducida en la PLATAFORMA.</P>
    <P>2.11 Efectuado el registro de los USUARIOS, SATAPP otorgará una cuenta personal
    para acceder con la contraseña que elijan (en adelante la “Cuenta”). Los USUARIOS
    accederán a su Cuenta mediante el ingreso de su usuario y clave de seguridad personal
    elegida. La Cuenta es personal, única e intransferible, y está prohibido que un mismo
    sujeto registre o posea más de una Cuenta. En caso de que SATAPP detecte distintas
    Cuentas que contengan datos coincidentes o relacionados, podrá cancelar, suspender
    o inhabilitarlas. El usuario será el único responsable por el uso de su Cuenta.</P>
    <P>2.12 Los datos ingresados en la PLATAFORMA se integrarán en una base de datos
    personales de la que es responsable SATAPP.</P>
    <P>2.21 El acceso a la PLATAFORMA estará limitado: a mayores de 18 años; que tengan
    capacidad jurídica plena para aceptar y comprender la política de privacidad y/o los
    presentes términos y condiciones y/o los términos y condiciones particulares de uso de
    la PLATAFORMA para los conductores y/o suscribir los contratos correspondientes con
    SATAPP; tener documento nacionalidad de identidad y/o residencia temporal y/o
    permanente emitida por la Dirección Nacional de Migraciones de la República
    Argentina y/o ser una sociedad debidamente registrada de conformidad con las
    normales estipuladas en la República Argentina, ya sea que se trate de sociedades
    constituidas en el país y/o en el extranjero. Todo ello será confirmado por cada usuario
    el suscribir los contratos respectivos con SATAPP.</P>
    <P>2.22 SATAPP no es responsable del uso y/o acceso que los USUARIOS y/o terceros
    hagan de la CUENTA y/o la PLATAFORMA, ni de los daños y perjuicios que pudieran
    ocasionarse.</P>
    <P>2.23 En todo momento, los USUARIOS deberán efectuar un uso lícito y legal de la
    PLATAFORMA de conformidad con la legislación y normativa aplicable y/o los
    presentes términos y condiciones y/o los términos y condiciones particulares de uso de
    la PLATAFORMA para conductores y/o los contratos que cada uno de ellos haya
    suscripto con SATAPP y/o la política de privacidad de SATAPP y/o la moral y/o el orden
    público y/o las buenas costumbres. Asimismo, los USUARIOS tampoco podrán crear,
    introducir, divulgar y/o difundir virus informáticos en la PLATAFORMA o que de algún
    modo afecten la seguridad y/o funcionamiento de la PLATAFORMA.</P>
    <P>2.24 Los USUARIOS deberán abstenerse de utilizar la PLATAFORMA para planificar,
    realizar y/o concretar acciones fraudulentas y/o usos no autorizados de la
    PLATAFORMA. Asimismo, deberán abstenerse de efectuar cualquier uso de la
    PLATAFORMA que pueda ocasionar daños a terceros, que sea lesivo de los intereses y
    derechos de terceros, o que de cualquier forma pueda dañar, entorpecer o
    sobrecargar el uso de la PLATAFORMA.</P>
    <P>2.25 Los USUARIOS deberán abstenerse de: (i) divulgar, reproducir, copiar, distribuir,
    editar y/o modificar el contenido de la PLATAFORMA y/o la información recibida en, o
    por intermedio de, la PLATAFORMA; (ii) utilizar el contenido de la PLATAFORMA con fin
    distinto para el cual ha sido puesto a su disposición.</P>

    <P>2.26 La creación y validación de la CUENTA le otorga a los USUARIOS: (i) el derecho de
    ingresar y salir libremente de la PLATAFORMA, cuando ellos lo dispongan; (ii) la
    oportunidad no exclusiva de acceder a la PLATAFORMA y su contenido.</P>
    <P>2.27 Los USUARIOS no podrán divulgar el contenido de la PLATAFORMA, ni publicarlo
    en diarios, boletines, redes sociales ni en ningún otro soporte de acceso público y/o
    privado.</P>
    <P>2.28 Los USUARIOS declaran que SATAPP no tiene ni tendrá responsabilidad alguna por
    pérdidas, daños, perjuicios, gastos de cualquier clase, ocasionados con motivo de y/o
    derivados del uso de la PLATAFORMA.</P>
    <P>2.29 SATAPP podrá revocar el acceso a la CUENTA y a la PLATAFORMA en todo
    momento, sin necesidad de invocar justa causa, a su libre discrecionalidad, sin
    necesidad de informarlo previamente a los USUARIOS, sin que ello genere
    responsabilidad alguna para SATAPP. La revocación de la CUENTA, cualquiera sea su
    causa, no dará derecho a los USUARIOS a obtener compensación alguna.</P>

    <P>2.30 Cuando el CONDUCTOR llega al punto de origen( COMIENZO conforme las
    definiciones estipuladas al comienzo de los presentes términos y condiciones) , en caso
    de que el SERVICIO consista en el traslado del PASAJERO, incluyendo o no su equipaje
    y/o elementos como silla de ruedas, muletas y/o similares, éste último ingresará al
    automóvil y previo a dar inicio el SERVICIO deberá: (i) escanear su DNI y/o (ii) para el
    caso en el cual el PASAJERO no tenga DNI, cualquiera sea el motivo, deberá
    informárselo al CONDUCTOR de modo tal que éste pueda iniciar el viaje dejando
    constancia en la PLATAFORMA de que el PASAJERO no tiene el DNI y en consecuencia
    realizar el transporte con registro de tiempo y espacio del trayecto en función de lo
    autorizado por la PLATAFORMA.. Acto seguido se producirá el COMIENZO del viaje. Es
    responsabilidad exclusiva del USUARIO SOLICITANTE, y a su propio riesgo y costo,
    realizar las verificaciones correspondientes que le permitan individualizar al PASAJERO,
    siendo SATAPP ajeno a dicha circunstancia.</P>
    <P>2.31 Cuando el CONDUCTOR llegue al punto de origen (COMIENZO, de conformidad
    con las definiciones establecidas al inicio de los presentes términos y condiciones) en
    caso de que el SERVICIO consista en el traslado de equipaje y/o cosas y/o mensajería,
    el sujeto que hace entrega de dichos elementos, previo al inicio del viaje, deberá
    escanear su DNI en el dispositivo móvil del CONDUCTOR y, en caso de no poseer DNI,
    deberá informarle dicha circunstancia al CONDUCTOR de modo tal que éste proceda a
    dejar constancia de la ausencia del DNI en la PLATAFORMA y realizar el transporte con
    registro de tiempo y espacio del trayecto en función de lo autorizado por la
    PLATAFORMA. Es responsabilidad exclusiva del USUARIO SOLICITANTE, y su exclusivo
    riesgo, realizar en forma previa al inicio del traslado las verificaciones
    correspondientes que les permita individualizar al sujeto que hace entrega del
    equipaje y/o cosas y/o mensajería a transportar por el CONDUCTOR, siendo SATAPP
    ajeno a dicha circunstancia.</P>
    <P>2.32 Si la realización del SERVICIO se desarrolla normalmente, el CONDUCTOR con el
    uso de la aplicación finalizará el viaje y generará un comprobante firmado digitalmente
    por el PASAJERO, por cada tramo de viaje. Cuando el SERVICIO implique únicamente el
    transporte de equipaje y/o cosas y/o mensajería, al finalizar el SERVICIO, el
    CONDUCTOR finalizará el viaje y generará un comprobante a ser firmado digitalmente
    por parte de la persona que recibe el equipaje y/o mensajería y/o cosa entregada en el
    lugar indicado como punto de finalización del viaje.</P>
    <P>2.33 Sí el CONDUCTOR que aceptó el SERVICIO no lo realiza por alguno de los
    siguientes motivos (i) no hizo contacto con el PASAJERO y/o con la persona que debía
    entregar la cosa /o equipaje y/o mensajería a transportar; (ii) el PASAJERO no quiere
    viajar, no está en condiciones de viajar o (iii) por cualquier otra circunstancia que le
    impida realizar el viaje, respetando los requisitos para otorgarle el traslado negativo,
    tales como informar la situación con celeridad, el CONDUCTOR deberá esperar a que el
    USUARIO SOLICITANTE lo autorice: (i) a retirarse, o a (ii) la espera suficiente en ORIGEN
    sin respuesta informada a tiempo. Asimismo deberá resultar comprobable que
    cumpliendo con el horario en destino el SERVICIO se podría haber materializado.
    Cumplido todo ello, se procederá a cancelar el viaje ofertado inicialmente, y el
    USUARIO SOLICITANTE abonará el SERVICIO al CONDUCTOR por un importe que
    implique el reconocimiento de gastos, conforme tarifario para viaje negativo, previa
    emisión de factura legal a través de la PLATAFORMA, en función de las condiciones de
    pago y facturación aquí establecidas.</P>
    <P>2.34 Es importante aclarar que para que sea otorgado un viaje negativo se deberá
    acreditar que el CONDUCTOR empleó todos los recursos a su alcance para que el
    SERVICIO se pudiera concretar y que si este no se pudo realizar lo ha sido por causas
    y/o motivos que no le son imputables.</P>
    <P>2.35 Si el viaje ha sido iniciado y se encuentra el PASAJERO y/o el equipaje y/o la cosa
    y/o la mensajería a transportar en el automóvil del CONDUCTOR y por algún motivo no
    es posible llegar a destino, se debe proceder a interrumpir el viaje. En estos casos, el
    CONDUCTOR informará a SATAPP, por intermedio de la PLATAFORMA y/o los canales
    de comunicación puestos a disposición por SATAPP, quien informará al USUARIO
    SOLICITANTE y se procederá a generar un viaje de retorno del PASAJET y/o cosas y/o
    mensajería transportadas al punto de COMIENZO. El USUARIO SOLICITANTE deberá
    abonar el traslado como un viaje de ida y retorno, desde el lugar de COMIENZO hasta
    el punto de interrupción.</P>
    <P>2.36 Si el CONDUCTOR se ve afectado por algunas de los motivos mencionados en los
    incisos 2.33 y 2.35 que anteceden, no podrá por sí decidir cancelar el SERVICIO, sino
    que deberá informar a SATAPP, por intermedio de la PLATAFORMA y/o cualquier otro
    canal de comunicación puesto a disposición por SATAPP, la circunstancia que impide
    que el SERVICIO se preste en condiciones normales, de modo tal que SATAPP proceda
    a retransmitir dicha información al USUARIO SOLICITANTE, quien deberá informarle a
    SATAPP como debe proceder el CONDUCTOR respecto del SERVICIO, decisión que le
    será informada al CONDUCTOR por la PLATAFORMA y/o cualquier otro medio de
    comunicación puesto a disposición .</P>
    <P>2.37 El USUARIO SOLICITANTE tendrá un lapso de 15 (quince) minutos para decidir
    como deberá proseguirse con el SERVICIO en cuestión. El plazo mencionado
    comenzará a regir a partir del momento en que el USUARIO SOLICITANTE C toma
    conocimiento de la situación que pueda dar lugar a que el viaje sea categorizado como
    viaje negativo, de lo que será informado por intermedio de alertas enviadas por el
    CONDUCTOR a través de la PLATAFORMA y/o por intermedio de cualquier medio de
    comunicación puesto a disposición por SATAPP. Dicho lapso solo podrá ser prorrogado
    por el USUARIO SOLICITANTE, por una única vez, y por medio de una manifestación
    positiva en virtud de la cual se solicite una prórroga de igual término para poder
    decidir como proseguir. Si transcurrido dicho lapso no se reciben órdenes del USUARIO
    SOLICITANTE, el viaje podrá ser catalogado como viaje negativo si el CONDUCTOR lo
    finalizara como viaje negativo, todo lo cual será informado por intermedio de SATAPP
    al CONDUCTOR y al USUARIO SOLICITANTE.</P>
    <P>2.38 Tanto el USUARIO SOLICITANTE como el CONDUCTOR tendrán acceso al[ Servicio
    de Atención y/o Centro de Ayuda] telefónica brindada por SATAPP ante cualquier
    inconveniente derivado de la comunicación por intermedio de la PLATAFORMA y/o
    cualquier otro medio de comunicación digital implementado por SATAPP. El número
    de teléfono del [Servicio de Atención y/o Centro de Ayuda[ se encuentra publicado en
    la PLATAFORMA. Toda información y/o gestión efectuada por intermedio del Centro
    de Ayuda y/o Servicio de Atención será transcripta por SATAPP en la PLATAFORMA y
    restantes canales de comunicación digital, de modo tal que quede registro electrónico
    de lo acontecido.</P>
    <P>2.39 El CONDUCTOR no recibirá ordenes por parte del PASAJERO ni del sujeto que
    proceda a hacer entrega o recepción de la mensajería y/o equipaje y/o cosas a
    transportar ya que el usuario del servicio de transporte es el USUARIO SOLICITANTE, y
    es este último quien genera e imparte las ordenes de traslado y transporte al
    CONDUCTOR por intermedio de las comunicaciones efectuadas a SATAPP.</P>
    <P>2.40 El SERVICIO podrá ser cancelado sin que ello devengue cargo alguno solo si se da
    alguna de las siguientes situaciones: i. El viaje programado no va a realizarse y esto se
    avisa con una antelación suficiente en forma previa a que el CONDUCTOR haya iniciado
    el viaje hacia el lugar o punto de COMIENZO en donde subiría el PASAJERO y/o el
    equipaje y/o cosas y/o mensajería a transportar.</P>
    <P>ii. El CONDUCTOR no cumplió con las obligaciones para obtener el viaje negativo
    indicado ut supra.</P>
    <P>iii. El CONDUCTOR interrumpe el SERVICIO por cuestiones ajenas a la propia prestación
    del SERVICIO y/o que le fueran imputables y/o de naturaleza personal.</P>

    <P>iv. El CONDUCTOR no llega a concretar el SERVICIO por cuestiones de caso fortuito o
    de fuerza mayor, en este caso último supuesto el USUARIO SOLICITANTE solo abonará
    el recorrido efectivamente realizado y comprobable.</P>

    <P>2.41 Una vez iniciado el traslado del PASAJERO y/o el equipaje y/o cosas y/o
    mensajería, según lo que se esté transportando, el CONDUCTOR en todo momento
    deberá contar con herramientas a su alcance para estar comunicado con SATAPP, ello
    con el objeto de que SATAPP pueda retransmitir toda la información al USUARIO
    SOLICITANTE, lo que le permitirá a este último estar debidamente informado de que el
    viaje se desarrolla en forma normal y/o de cualquier imprevisto que surja en el mismo,
    sin perjuicio de que el CONDUCTOR deberá arbitrar todos los medios a su alcance para
    poder solucionar cualquier inconveniente que se presente en el transcurso del mismo.</P>
    <P>2.42 El CONDUCTOR contará con la posibilidad restituir el SERVICIO aceptado a la
    PLATAFORMA en caso de que así lo desee, cualquiera sea el motivo.</P>
    <P>2.43 A través de la PLATAFORMA, el CONDUCTOR y SATAPP cuentan con la posibilidad,
    en cualquier momento, de controlar los traslados realizados, las liquidaciones, los
    montos abonados y las calificaciones por los servicios.</P>
    <P>2.44 En todo momento se podrá acceder a la GEOLOCALIZACIÓN de los móviles que
    ingresan a la PLATAFORMA, autorizando expresamente a SATAPP a que cuente con la
    GEOLOCALIZACIÓN de los vehículos que ingresen a la misma. En consecuencia, cada
    CONDUCTOR que acepte los términos y condiciones, conforme fuera explicado
    precedentemente, autoriza a SATAPP a acceder a su GEOLOCALIZACIÓN y compartirla
    con el USUARIO SOLICITANTE.</P>
    <P>2.45 El USUARIO SOLICITANTE podrá calificar al CONDUCTOR en virtud de la calidad del
    servicio prestado por éste último.</P>
    <P>2.46 Es importante resaltar que, una vez aceptada la ORDEN, la PLATAFORMA calculará
    el momento en el cual el CONDUCTOR debería iniciar el viaje, trasladándose del lugar
    donde se encuentra hasta el punto de COMIENZO del viaje, de modo tal que pueda
    cumplir con los horarios de traslado solicitados por el USUARIO SOLICITANTE.</P>

    <P><b>Tercera. Condiciones de pago y facturación.</b></P>
    <P>3.1 Las tarifas cobradas no serán reembolsables. Las tarifas, sus actualizaciones, y
    condiciones de uso estarán disponibles en todo momento en la Aplicación y/o Sitio
    Web y están sujetas a posibles modificaciones.</P>
    <P>3.2 SATAPP recomienda a los USUARIOS acceder en forma periódica a la Aplicación y/o
    al Sitio Web para conocer las tarifas aplicables en cada momento.</P>
    <P>3.3 SATAPP se reserva el derecho de cobrar a los USUARIOS una tasa adicional al
    precio total del trayecto del viaje efectuado por el CONDUCTOR, la que será
    imprescindible para cubrir los servicios de intermediación brindados por SATAPP a los
    USUARIOS, y se encontrará detallada en el resumen e historial de viajes y trayectos de
    los USUARIOS.</P>
    <P>3.4 El USUARIO SOLICITANTE acepta que en las franjas de mayor demanda se cobre un
    costo adicional variable. Este costo adicional se encontrará incluido en la tarifa final
    informada.</P>
    <P>3.5 En el momento en que el USUARIO SOLICITANTE emite la ORDEN, podrá consultar
    una estimación del precio del transporte. Sin perjuicio de ello, SATAPP se reserva el
    derecho de variar las tarifas informadas en función de ciertos adicionales.</P>
    <P>3.6 El CONDUCTOR cobrará al USUARIO SOLICITANTE el valor de los servicios de
    transporte prestado, de conformidad con los valores de trayecto y adicionales que
    sean informados en la PLATAFORMA. En tal sentido, el CONDUCTOR autoriza
    expresamente a SATAPP y/o a las personas jurídicas que éste establezca, a recibir el
    pago de cualquier suma de dinero que tuviere derecho a percibir el CONDUCTOR a raíz
    de la prestación de servicios de transportes originados y/o derivados del uso de la
    PLATAFORMA. En dicho entendimiento, SATAPP realizará los cobros al USUARIO
    SOLICITANTE por cuenta y orden del CONDUCTOR. Por ello, el CONDUCTOR autoriza en
    este acto a SATAPP a llevar adelante todas las medidas, judiciales y/o extrajudiciales,

    que estime correspondientes para obtener el pago de los montos adeudados por el
    USUARIO SOLICITANTE en virtud del servicio de transporte ejecutado por el
    CONDUCTOR y a realizar la emisión de las facturas correspondientes por dichos servicios de
    transporte y/ cobro de adicionales y/o gastos derivados del transporte a nombre propio de
    SATAPP y por cuenta y orden del CONDUCTOR.</P>
    <P>3.7 En dicho entendimiento, el USUARIO SOLICITANTE deberá realizar los pagos del
    transporte efectuado por el CONDUCTOR y/o los adicionales y/o costos derivados de
    dicho transporte a SATAPP, quien se encargará de transferir las sumas
    correspondientes al CONDUCTOR que prestó los servicios de transporte al Usuario
    Solicitante.</P>
    <P>3.8 SATAPP no asume riesgo, responsabilidad, garantía ni obligación alguna en relación
    con el pago del SERVICIO.</P>
    <P>3.9 Cada viaje será facturado una vez que el CONDUCTOR informe a SATAPP-por
    intermedio de la PLATAFORMA- que el viaje ha finalizado.</P>
    <P>3.10 SATAPP, por motivos de seguridad, tendrá la potestad de bloquear, cancelar o
    anular cualquier método de pago cuando existan sospechas razonables sobre un uso
    indebido del mismo.</P>
    <P>3.11 Para en el caso en el cual SATAPP proceda a abonar al CONDUCTOR los viajes
    realizados y/o los costos y/o adicionales implicados en dichos traslados en forma
    previa a obtener por parte del USUARIO SOLICITANTE el pago de las facturas y
    liquidaciones correspondientes, se entenderá que SATAPP ha subrogado al
    CONDUCTOR en todos los derechos derivados del pago, los que ahora podrán ser
    ejercidos en forma directa y en ejercicio de un derecho propio por parte de SATAPP.</P>

    <P><b>Cuarta. Derechos de propiedad intelectual e industrial.</b></P>
    <P>4.1 Los USUARIOS aceptan y reconocen que todos los derechos de propiedad industrial
    e intelectual sobre el contenido y/o elementos de la PLATAFORMA (incluyendo,
    aunque no limitando, logotipos, denominaciones sociales, marcas, denominaciones

    comerciales, textos, imágenes, gráficos, diseños, sonidos, base de datos, audio y/o
    vídeo) son de titularidad exclusiva de SATAPP.</P>
    <P>4.2 SATAPP autoriza al CONDUCTOR y al USUARIO SOLICITANTE a utilizar, acceder,
    imprimir, descargar y/o almacenar contenidos y/o elementos insertados en la
    PLATAFORMA exclusivamente para uso personal, privado, no comercial ni oneroso,
    absteniéndose de realizar sobre dicho contenido acciones de divulgación, suministro
    y/o modificación y/o usos no permitidos ni autorizados por SATAPP.</P>
    <P>4.3 Cualquier otro uso o explotación de los contenidos de la PLATAFORMA de lo aquí
    expresamente previstos estará sujeto a la autorización previa de SATAPP.</P>

    <P><b>Quinta. Datos personales.</b></P>
    <P>5.1 Los Datos Personales (conforme se los define en el artículo primero de la Ley
    <P>25.326 y/o las que en un futuro la reemplacen) proporcionados por el CONDUCTOR
    y/o el USUARIO SOLICITANTE en la PLATAFORMA y/o por cualquier otro canal de
    comunicación puesto a disposición por SATAPP, integran la base de datos de SATAPP.</P>
    Para más información por favor consulte la política de privacidad de SATTAP publicada
    en el Sitio Web y la Aplicación.</P>

    <P><b>Sexta. Indemnidades. Responsabilidades.</b></P>
    <P>6.1 SATAPP no garantiza la disponibilidad y continuidad del funcionamiento de la
    PLATAFORMA.</P>
    <P>6.2 SATAPP no es responsable de los daños y perjuicios que puedan derivarse de: (i)
    problemas de conexión y/o averías y/o retrasos y/o fallas informáticas y/o de la
    PLATAFORMA; (ii) interrupciones en el funcionamiento de la PLATAFORMA; (iii)
    cualquier daño ocasionado por terceros mediante intromisiones no autorizadas en la
    PLATAFORMA.</P>

    <P>6.3 SATAPP no garantiza la ausencia de virus ni de otros elementos en la PLATAFORMA
    introducidos por terceros ajenos a SATAPP.</P>
    <P>6.4 SATAPP no será responsable por los daños y perjuicios que pudieran derivarse de la
    presencia de virus y/o cualquier otra alteración en la PLATAFORMA.</P>
    <P>6.5 SATAPP no será en ningún caso responsable de los daños y perjuicios que pudieran
    derivarse de accesos no autorizados a la PLATAFORMA.</P>
    <P>6.6 Con la aceptación de los presentes términos y condiciones, los USUARIOS
    mantendrán indemne a SATAPP frente a cualquier reclamo efectuado contra SATAPP,
    sus socios, gerentes, empleados, proveedores y/o sociedades vinculadas, derivados
    del: (i) incumplimiento por parte de los USUARIOS de cualquier disposición contenida
    en las presentes términos y condiciones de uso generales o de cualquier ley o
    regulación aplicable a las mismas, de la política de privacidad de SATAPP, los contratos
    que hayan suscripto con SATAPP y/o los términos y condiciones de uso de la
    PLATAFORMA para conductores ; (ii) incumplimiento o violación de los derechos de
    terceros, CONDUCTOR y/o USUARIO SOLICITANTE; (iii) cualquier daño o perjuicio
    causado por el USUARIO SOLICITANTE y/o los sujetos transportados y/o sus
    acompañantes y/o sus pertenencias y/o cosas o mensajería transportada y/o los
    conductores con motivo y/o en ocasión del uso de la PLATAFORMA y/o el servicio de
    transporte efectuado por el CONDUCTOR; (iv) incumplimiento del uso permitido de la
    PLATAFORMA.</P>
    <P>6.6 Asimismo, el CONDUCTOR y el USUARIO SOLICITANTE declaran que SATAPP
    únicamente presta servicios de intermediación, poniendo a disposición de ambos la
    PLATAFORMA y redirigiendo las comunicaciones y ordenes efectuadas por el USUARIO
    SOLICITANTE al CONDUCTOR. La prestación del servicio de transporte es efectuada en
    forma exclusiva por parte del CONDUCTOR. Por ello, el USUARIO SOLICITANTE
    renuncia y exonera a SATAPP de cualquier obligación, reclamo o daños surgidos como
    consecuencia del servicio de transporte, al cual es ajeno SATAPP.</P>
    <P>6.7. Toda responsabilidad u obligación nacida en virtud de la relación entre el USUARIO
    SOLICITANTE y el CONDUCTOR transportista, descansa únicamente en el USUARIO</P>

    <P>SOLICITANTE, con todas las implicaciones que ello conlleva. En consecuencia, el
    USUARIO SOLICITANTE comprende el transporte es prestado por el CONDUCTOR a su
    exclusivo costo, riesgo y responsabilidad, de lo cual es ajeno SATAPP.</P>

    <P><b>Séptima. Notificaciones. Ley aplicable y Jurisdicción competente.</b></P>
    <P>7.1 SATAPP realizará las notificaciones derivadas de los presentes términos y al correo
    electrónico ingresado por el CONDUCTOR y/o el USUARIO SOLICITANTE que se
    encuentren vinculadas a sus respectivos usuarios, salvo aquellas excepciones previstas
    expresamente previstas en los presentes términos y condiciones y/o la normativa
    aplicable.</P>
    <P>7.2 Los presentes términos y condiciones regirán por la normativa vigente en la
    República Argentina, con exclusión de las normas sobre conflicto de leyes. En adición,
    las partes se someten a la jurisdicción de los tribunales competentes en la materia con
    asiento en la Ciudad Autónoma de Buenos Aires, renunciando a cualquier otro fuero o
    jurisdicción que pudiera corresponder.</P>

    <P><b>Octava. Cesión.</b></P>
    <P>8.1 Los USUARIOS no podrán ceder los derechos y obligaciones asumidos en los
    presentes términos y condiciones y/o la política de privacidad de SATAPP y/o los
    términos y condiciones particulares de uso de la PLATAFORMA de SATAPP y/o el
    acuerdo de servicios suscripto con SATAPP, sin contar con el previo consentimiento
    por escrito de SATAPP.</P>
    
    </Padding>
    </Container>
    </>
)

export default TermsAndConditionsPage;