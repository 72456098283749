import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Center, ContainerPage, H3, Spinner } from "components/styles";
import {
  getRevisionTaxable,
  setRevisionTaxable,
} from "components/User/Newcomers/finished/reducer";
import { getUser } from "controllers/user";
import { getTaxableEntity } from "controllers/user/info/taxable";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { QuizParams } from "types/module-transfer";
import {
  TaxableEntity,
  TaxableEntityType,
  Revision,
  User,
} from "types/user-data";
import { FormReview, InfoRow, textStatus } from "../Profile/review";

const AdminTaxableRevision = () => {
  let { uidUser } = useParams<QuizParams>();
  const history = useHistory();
  const [item, setItem] = useState<User | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (uidUser)
      getUser(uidUser)
        .then(setItem)
        .then(() => setLoading(false));
  }, []);

  if (loading) return <Spinner />;
  if (!uidUser) return <>Error uid</>;
  return (
    <ContainerPage>
      {item && (
        <H3>
          <div onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faChevronLeft} /> Revisión -{" "}
            {item.name + " " + item.surname + " (" + item.email + ")"}
          </div>{" "}
        </H3>
      )}
      <TaxableRevision uidUser={uidUser} />
    </ContainerPage>
  );
};

const TaxableRevision = ({ uidUser }: { uidUser: string }) => {
  const [item, setItem] = useState<TaxableEntity | undefined>(undefined);
  const [revision, setRevision] = useState<Revision | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [labelsWrong, setLabelsWrong] = useState<Set<string>>(
    new Set<string>()
  );
  const add = (s: string) =>
    setLabelsWrong(new Set<string>(labelsWrong).add(s));
  const rm = (s: string) => {
    let cp = new Set(labelsWrong);
    cp.delete(s);
    setLabelsWrong(cp);
  };
  useEffect(() => {
    setLoading(true);
    getTaxableEntity(uidUser)
      .then(setItem)
      .then(() => setLoading(false));
    getRevisionTaxable(uidUser).then(setRevision);
  }, []);

  if (loading) return <Spinner />;
  if (item === undefined) return <>Error cargando los datos</>;

  return (
    <>
      <H3>Datos impositivos</H3>
      <Row>
        <Col>
          {item.cbu ? (
            <InfoRow add={add} rm={rm} label={"CBU"} info={item.cbu} />
          ) : (
            <InfoRow add={add} rm={rm} label={"CVU"} info={item.cvu} />
          )}
          <InfoRow add={add} rm={rm} label={"CUIT"} info={item.cuit} />
          <InfoRow
            add={add}
            rm={rm}
            label={"Tipo de tax"}
            info={TaxableEntityType[item.tax_entity]}
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Punto de venta"}
            info={item.point_of_sale}
          />
        </Col>
      </Row>
      <br />
      <Center>ESTADO ACTUAL: {revision && textStatus(revision.status)}</Center>
      <FormReview
        uidUser={uidUser}
        setRevision={setRevisionTaxable}
        wrong_fields={labelsWrong}
      />
    </>
  );
};

export default AdminTaxableRevision;
