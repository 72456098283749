import { InfoRow, Title } from "components/Admin/Transfers/view/scheduled";
import {
  AdminTable,
  AsyncButton,
  Button,
  ButtonWithConfirmation,
  ContainerPage,
  ErrorPage,
  H3,
  Link,
  NoData,
  PageTitle,
  Spinner,
} from "components/styles";
import ExcelJS from "exceljs";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import { WithId } from "controllers/database";
import { useDatabaseDocref } from "controllers/hooks";
import _, { iteratee } from "lodash";
import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import Moment from "react-moment";
import { useParams, useHistory } from "react-router";
import {
  BillInfoTransfer,
  PaymentOrderDriver,
  SupportedBanks,
  TIPOS_COMPROBANTES,
  TypePayment,
} from "types/billing";
import { FbDocument } from "types/transfer";
import { GetProperty, UserName } from "../Sales/view-bill";
import * as fs from "file-saver";
import moment from "moment";
import { useSaveSelection } from "../reducer";
import { List } from "antd";
import { QuizParams } from "types/module-transfer";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";

const ViewDriverPaymentOrder = () => {
  const { id } = useParams<QuizParams>();
  const order = useDatabaseDocref<PaymentOrderDriver>(
    FIRESTORE.DOCUMENT_PAYMENT_DRIVER(id)
  );
  const [bankOperation, setBankOperation] = useState<string | undefined>(
    undefined
  );
  const [bills, setBills] = useState<(BillInfoTransfer & WithId)[]>([]);
  const [loading, setLoading] = useState(false);
  const [organizador, setOrganizador] = useState<boolean>(false);
  const [selected, setSelected] = useState(new Set());
  const [erasedBill, setErasedBill] = useState(false);
  const [items2, setItems2] = useState<any[]>([]);
  const [show, setShow] = useSaveSelection("driver-payment-show");
  const [contraer, setContraer] = useState<boolean>(true);
  const [query, setQuery] = useState<boolean>(false);
  const history = useHistory();
  let choferes: any[] = [];
  let items: any = [];
  useEffect(() => {
    if (order?.bills) {
      setLoading(true);
      getTransfersInfo(order.bills)
        .then(setBills)
        .then(() => setLoading(false))
        .then(() => setOrganizador(true));
    }
  }, [order?.bills]);
  const mostrar = async () => {
    setLoading(true);
    choferes = [];
    items = [];
    await Promise.all(
      bills.map((b) => {
        if (!choferes.includes(b.driver)) {
          choferes.push(b.driver);
        }
      })
    );
    console.log(choferes, "segundo");
    for (let i = 0; i < choferes.length; i++) {
      const chofer = choferes[i];
      const data = (await FIRESTORE.DOCUMENT_USER(chofer).get()).data();
      const mail = data?.email;
      let nombre = `${data?.surname} ${data?.name}`;
      nombre = nombre.toUpperCase();
      const choferFcs = bills.filter((f) => f.driver === chofer);
      choferFcs.map(async (fc: any) => {
        const traslado = (await fc.transfers[0].get()).data();
        fc.fchTraslado = moment(traslado.origin.time.toDate()).format(
          "DD/MM HH:mm"
        );
        if (traslado?.art.authorization) {
          fc.sin = `${traslado.art.accident} ${traslado.art.authorization}`;
        } else {
          fc.sin = traslado.art.accident;
        }
      });
      let monto = 0;
      for (let j = 0; j < choferFcs.length; j++) {
        const impTotal = choferFcs[j].ImpTotal;
        monto += impTotal;
      }
      items.push({
        name: nombre,
        user: chofer,
        mail,
        monto,
        fcs: choferFcs,
      });
    }
    setItems2(items);
    console.log(items);
    console.log(items2, "items2");
    setQuery(true);
    setLoading(false);
  };
  useEffect(() => {
    mostrar();
  }, [organizador]);
  const updateOpBank = async () =>
    FIRESTORE.DOCUMENT_PAYMENT_DRIVER(id).update({
      bank_operation: bankOperation,
    } as Partial<PaymentOrderDriver>);

  const returnSelectedBills = async () => {
    if (!order) return;

    const returnBills = [...selected].map((v: any) => v.split("-")[0]);
    const newBills = order.bills.filter(
      (v) => returnBills.indexOf(v.id) === -1
    );
    console.log(returnBills, "return");
    console.log(newBills, "newBills");
    await Promise.all(
      returnBills.map(async (v) => {
        await FIRESTORE.DOCUMENT_DRIVER_BILL(v).update({ payment_order: null });
        return true;
      })
    );

    if (newBills.length > 0) {
      const dataNewBills = await Promise.all(
        newBills.map((v) => v.get().then((v) => v.data()!))
      );
      await FIRESTORE.DOCUMENT_PAYMENT_DRIVER(id).update({
        bills: newBills,
        amount: dataNewBills.reduce((a, b) => a + b.ImpTotal, 0),
      });
    } else {
      //No queda ninguna factura, eliminamos el pago
      setErasedBill(true);
      await FIRESTORE.DOCUMENT_PAYMENT_DRIVER(id).delete();
    }
  };
  const downloadExcel = async () => {
    let workbook = new ExcelJS.Workbook();
    const fname =
      "Orden_de_transferencia_" +
      moment(order?.creation_date.toDate()).format("MM-DD");
    let worksheet = workbook.addWorksheet(fname);
    worksheet.columns = [
      { header: "cbu", key: "cbu", width: 25 },
      { header: "monto", key: "amount", width: 12 },
      { header: "titularDesc", key: "tit", width: 35 },
      { header: "titularCui", key: "cui", width: 12 },
      { header: "tipoCtaDestino", key: "accType", width: 12 },
      { header: "cuentaDestino", key: "acc", width: 20 },
      { header: "cuentaDestinoPBF", key: "acc2", width: 20 },
      { header: "esCuentaPropia", key: "n", width: 15 },
      { header: "tipoPersona", key: "f", width: 12 },
      { header: "concepto", key: "FAC", width: 12 },
      { header: "descConcepto", key: "desc", width: 13 },
      { header: "observaciones", key: "obs", width: 25 },
      { header: "email", key: "mail", width: 40 },
    ];
    worksheet.getRow(1).font = {
      color: { argb: "0000FF" },
    };

    let billsGrouped = [
      ...bills
        .reduce((r, o) => {
          const key = o.driver;

          const item =
            r.get(key) ||
            Object.assign({}, o, {
              ImpTotal: 0,
              cuit: o.cuit,
              driver: o.driver,
            });

          item["ImpTotal"] += o["ImpTotal"];
          console.log(o["ImpTotal"]);

          return r.set(key, item);
        }, new Map() as Map<string, { ImpTotal: number; cuit: string; driver: string }>)
        .values(),
    ];
    for (const x of billsGrouped) {
      const user = (await FIRESTORE.DOCUMENT_USER(x.driver).get())?.data()!;
      const taxableInfo = (
        await FIRESTORE.DOCUMENT_USER_TAXABLE_INFO(x.driver).get()
      )?.data()!;
      if (!taxableInfo.cbu) continue;
      let slicedCbu = taxableInfo.cbu?.slice(3, -1);
      worksheet.addRow({
        cbu: taxableInfo.cbu,
        amount: x.ImpTotal.toFixed(2),
        tit: user.name + " " + user.surname,
        cui: taxableInfo.cuit,
        accType: "CAP",
        acc: slicedCbu,
        acc2: slicedCbu,
        n: "N",
        f: "F",
        FAC: "FAC",
        desc: "Pago viajes",
        obs: order?.concept2,
        mail: user.email,
      });
    }
    //const buffer = await workbook.xlsx.writeBuffer();
    await workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, fname + ".xlsx");
    });
  };
  const cancelPaymentOrder = async () => {
    console.log("Hola Cami, estas en mi pantalla (?(?(?(?(?");
    await CALLABLE_FUNCTIONS.ADMIN_CANCEL_PAYMENT_ORDER({ id });
    history.goBack();
  };
  const downloadTxt = async () => {
    if (!order) return;
    const fecha = moment(order.creation_date.toDate()).format("MM-DD");
    let textCBU = "";
    let otroText = "";

    //Agrupamos las facturas que son del mismo conductor
    let billsGrouped = [
      ...bills
        .reduce((r, o) => {
          const key = o.driver;

          const item =
            r.get(key) ||
            Object.assign({}, o, {
              ImpTotal: 0,
              cuit: o.cuit,
              driver: o.driver,
            });

          item["ImpTotal"] += o["ImpTotal"];
          console.log(o["ImpTotal"]);

          return r.set(key, item);
        }, new Map() as Map<string, { ImpTotal: number; cuit: string; driver: string }>)
        .values(),
    ];

    for (const x of billsGrouped) {
      const user = (await FIRESTORE.DOCUMENT_USER(x.driver).get())?.data()!;
      const taxableInfo = (
        await FIRESTORE.DOCUMENT_USER_TAXABLE_INFO(x.driver).get()
      )?.data()!;
      if (taxableInfo.cbu) {
        const lineText =
          // _.padEnd(order.sucursal, 8) + //Entidad/Sucursal 8
          "CBU SATAPP" +
          ";" + //Cuenta a acreditar 14
          x.ImpTotal.toFixed(2) +
          ";" + //Importe 10 -> 8 enteros y 2 decimales
          user.name +
          " " +
          user.surname +
          ";" + //Beneficiario 40 -> apellido y nombre razón social
          x.cuit.toString() +
          ";" + //Tipo y Nº doc 22 -> 1=CUIT, 2=CUIL, 3=CDI, número 11 dígitos y 10 espacios en blanco
          "CCP/CAP;" +
          "NRO DE CUENTA;" + //18
          "NRO DE CUENTA OTRA VEZ;" + //18
          "N;" +
          "F;" +
          order.concept +
          ";" + //Concepto1 3 -> Ingresar código
          order.concept2 +
          ";" + //Concepto2 12 -> Ingresar descripción
          _.padEnd("", 59) +
          ";" + //Observaciones 60
          user.email + //Correo electrónico 50 -> mail del beneficiario para avisar sobre la transferencia
          "\n";

        textCBU += lineText;
      } else if (taxableInfo.cvu) {
        const lineText =
          _.padEnd(order.sucursal, 8) + //Entidad/Sucursal 8
          _.padEnd(taxableInfo.cvu.toString(), 14) + //Cuenta a acreditar 14
          _.padStart(x.ImpTotal.toFixed(2).toString(), 10).toString() + //Importe 10 -> 8 enteros y 2 decimales
          _.padEnd("1" + x.cuit.toString(), 22) + //Tipo y Nº doc 22 -> 1=CUIT, 2=CUIL, 3=CDI, número 11 dígitos y 10 espacios en blanco
          _.padEnd(user.name + " " + user.surname, 40) + //Beneficiario 40 -> apellido y nombre razón social
          _.padEnd("", 60) + //Observaciones 60
          _.padEnd(order.concept, 3) + //Concepto1 3 -> Ingresar código
          _.padEnd(order.concept2, 12) + //Concepto2 12 -> Ingresar descripción
          _.padEnd(user.email, 50) + //Correo electrónico 50 -> mail del beneficiario para avisar sobre la transferencia
          "\n";

        otroText += lineText;
      }
    }

    const file = new Blob([textCBU], { type: "text/plain" });
    const tempLink = document.createElement("a");
    tempLink.href = URL.createObjectURL(file);
    tempLink.setAttribute(
      "download",
      `Orden_de_transferencia_${fecha}_${id}_CBU.txt`
    );
    document.body.appendChild(tempLink); // Required for this to work in FireFox
    tempLink.click();

    const file2 = new Blob([otroText], { type: "text/plain" });
    const tempLink2 = document.createElement("a");
    tempLink2.href = URL.createObjectURL(file2);
    tempLink2.setAttribute(
      "download",
      `Orden_de_transferencia_${fecha}_${id}_CVU.txt`
    );
    document.body.appendChild(tempLink2); // Required for this to work in FireFox
    tempLink2.click();
  };

  const generateTxt = async () => {
    if (!order) return;

    let data = "";
    const billsGrouped = [
      ...bills
        .reduce((r, o) => {
          const key = o.driver;

          const item =
            r.get(key) ||
            Object.assign({}, o, {
              ImpTotal: 0,
              cuit: o.cuit,
              driver: o.driver,
            });

          item["ImpTotal"] += o["ImpTotal"];
          console.log(o["ImpTotal"]);

          return r.set(key, item);
        }, new Map() as Map<string, { ImpTotal: number; cuit: string; driver: string }>)
        .values(),
    ];

    for (const x of billsGrouped) {
      const user = (await FIRESTORE.DOCUMENT_USER(x.driver).get())?.data()!;
      const taxableInfo = (
        await FIRESTORE.DOCUMENT_USER_TAXABLE_INFO(x.driver).get()
      )?.data()!;
      const contador = (
        await FIRESTORE.DOCUMENT_CONFIGURATION_CONTADOR().get()
      ).data()?.ordenes_de_pago_bas!;
      await FIRESTORE.DOCUMENT_CONFIGURATION_CONTADOR().update({
        ordenes_de_pago_bas: contador + 1,
      });
      console.log(taxableInfo);
      data =
        data +
        `\n[Orden de Pago]\n${moment(order?.creation_date.toDate()).format(
          "DD/MM/YYYY"
        )}|${String(taxableInfo.point_of_sale)?.padStart(5, "0")}|${String(
          contador
        )?.padStart(8, "0")}|${
          user?.user_code
            ? "C" + String(user?.user_code).padStart(5, "0")
            : "no tiene"
        }|${taxableInfo.tax_entity === 0 ? "I" : "M"}||${order.amount.toFixed(
          2
        )}||L|||||||P||`;

      if (order.type === 0) {
        console.log("ENTRE ACÁ!");
        data =
          data +
          `\nPAGO|000|0000|${x.ImpTotal.toFixed(2)}|${x.ImpTotal.toFixed(
            2
          )}||||`;
      }
      bills.map((bill) => {
        if (bill.driver === x.driver) {
          data =
            data +
            `\nAPL|${
              bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
                ? "FA"
                : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
                ? "FB"
                : bill!.type === TIPOS_COMPROBANTES["FACTURA C"]
                ? "FC"
                : "FE"
            }|${String(bill?.point_of_sale).padStart(5, "0")}|${String(
              bill?.voucher_number
            ).padStart(8, "0")}|${moment(bill?.expiration_date.toDate()).format(
              "DD/MM/YYYY"
            )}|${bill?.ImpTotal.toFixed(2)}|${bill?.cae ? bill.cae : 0}`;
        }
      });
    }

    let blob = new Blob([data], { type: "text/plain;charset=utf-8" });
    fs.saveAs(blob, "ORDEN DE PAGO" + "-" + ".txt");
  };

  const sendPaymentMail = async () => {
    const itemsArranged = [];
    for (let i = 0; i < items2.length; i++) {
      const itemArranged = items2[i];
      const fcsIds: any[] = [];
      for (let j = 0; j < itemArranged.fcs.length; j++) {
        const fc = itemArranged.fcs[j];
        fcsIds.push(fc.id);
      }
      itemsArranged.push({
        totalAmount: itemArranged.monto,
        fcsIds,
        driver: itemArranged.user,
        driverName: itemArranged.name,
        paymentOrder: id,
        driverMail: itemArranged.mail,
      });
    }
    console.log(itemsArranged, "salgo del front");
    await CALLABLE_FUNCTIONS.SEND_PAYMENT_MAIL(itemsArranged);
  };
  if (erasedBill) return <Alert variant="warning">Factura eliminada</Alert>;

  if (!order) return <ErrorPage />;

  return (
    <ContainerPage>
      <Row>
        <Col>
          <PageTitle>
            Pago Prestador #{id}{" "}
            <Moment format="DD/MM/YYYY" date={order.creation_date.toDate()} />
          </PageTitle>
        </Col>
        <Col lg="auto">
          <AsyncButton
            variant="danger"
            className="pull-right"
            onClick={cancelPaymentOrder}
          >
            Anular orden de pago
          </AsyncButton>
        </Col>
        <Col lg="auto">
          <AsyncButton variant="dark" onClick={sendPaymentMail}>
            {" "}
            Informar por Mail
          </AsyncButton>
        </Col>
        <Col lg="auto">
          <Button
            variant="success"
            className="pull-right"
            onClick={downloadTxt}
          >
            Descargar TXT para banco {SupportedBanks[order.bank]} CBU/CVU
          </Button>
        </Col>
        <Col lg="auto">
          <AsyncButton
            variant="success"
            className="pull-right"
            onClick={downloadExcel}
          >
            Descargar Excel para banco {SupportedBanks[order.bank]} solo CBU
          </AsyncButton>
        </Col>
      </Row>

      <Row>
        <Title>Detalles</Title>
      </Row>
      <AdminTable responsive>
        <thead>
          <tr>
            <th>Importe</th>
            <th>Banco</th>
            <th>Concepto</th>
            <th>Concepto2</th>
            <th>Sucursal</th>
            <th>Tipo de pago</th>
            <th>Operación bancaria</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{order.amount.toFixed(2)}</td>
            <td>{SupportedBanks[order.bank]}</td>
            <td>{order.concept}</td>
            <td>{order.concept2}</td>
            <td>{order.sucursal}</td>
            <td>{TypePayment[order.type]}</td>
            <td>{order.bank_operation && order.bank_operation}</td>
          </tr>
        </tbody>
      </AdminTable>
      <Row>
        <Title>Facturas Adjuntas</Title>
      </Row>
      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : bills.length === 0 ? (
        <NoData />
      ) : (
        <AdminTable responsive>
          <thead>
            <tr>
              <th>Conductor</th>
              <th>Número Facturas</th>
              <th>Monto Acumulado</th>
              <th>Ver facturas</th>
            </tr>
          </thead>
          <tbody>
            {items2.map((item: any) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.fcs.length}</td>
                <td>{`$${item.monto.toFixed(2)}`}</td>
                <td>
                  <Button
                    onClick={() => {
                      const z = new Set();
                      item.fcs.map((fc: any) =>
                        z.add(
                          `${fc.id}-${fc.driver}-${fc.voucher_number}-${fc.ImpTotal}-${fc.fchTraslado}-${fc.sin}`
                        )
                      );
                      setShow(z);
                      setContraer(false);
                    }}
                  >
                    Ver
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </AdminTable>
      )}

      {contraer && query ? (
        <>
          <br />
          <Button onClick={() => setContraer(false)}>Mostrar</Button>
        </>
      ) : !contraer && query ? (
        <>
          <br />
          <Button onClick={() => setContraer(true)}>Ocultar</Button>
        </>
      ) : (
        ""
      )}
      {!contraer ? (
        <>
          <H3>Facturas del chofer seleccionado</H3>
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={[...show]}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Link
                    to={ROUTES.ADMIN_SALES_SALES_DRIVER_BILL_INFO(
                      item.split("-")[0]
                    )}
                  >
                    <Button variant="light">Ver</Button>
                  </Link>,
                  !selected.has(item) ? (
                    <Button
                      variant="success"
                      onClick={() => {
                        const z = new Set(selected);
                        z.add(item);
                        setSelected(z);
                      }}
                    >
                      Agregar
                    </Button>
                  ) : (
                    <Button
                      variant="danger"
                      key="list-loadmore-edit"
                      onClick={() => {
                        const z = new Set(selected);
                        z.delete(item);
                        setSelected(z);
                      }}
                    >
                      ELIMINAR
                    </Button>
                  ),
                ]}
              >
                <div>
                  <UserName uid={item.split("-")[1]} surname /> -{" "}
                  {item.split("-")[2]} - ${item.split("-")[3]} -{" "}
                  {item.split("-")[4]} - {item.split("-")[5]}
                </div>
              </List.Item>
            )}
          />
        </>
      ) : (
        ""
      )}
      {!contraer ? (
        <>
          <br />
          <Button onClick={() => setContraer(true)}>Ocultar</Button>
        </>
      ) : (
        ""
      )}
      <Row>
        <Col>
          <ButtonWithConfirmation
            variant="danger"
            className="pull-right"
            onClick={returnSelectedBills}
            confirmText="¿Está seguro que quiere remover las facturas del pago y devolverlas a facturas pendientes de pago?"
            style={{ marginLeft: 10 }}
          >
            Devolver facturas seleccionados a pendientes
          </ButtonWithConfirmation>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col xl={6}>
          <Form.Group>
            <Form.Label>
              Actualizar Número de Operación de banco al pago
            </Form.Label>
            <Form.Control
              value={bankOperation}
              onChange={(e) => setBankOperation(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xl={2}>
          <AsyncButton onClick={updateOpBank} className="pull-right">
            ACTUALIZAR
          </AsyncButton>
        </Col>
      </Row>
    </ContainerPage>
  );
};

const getTransfersInfo = async (t: FbDocument<BillInfoTransfer>[]) => {
  return Promise.all(
    t.map((t) => t.get().then((v) => ({ id: v.id, ...v.data()! })))
  );
};

export default ViewDriverPaymentOrder;
