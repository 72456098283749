import { Button, SubmitButton } from "components/styles";
import React from "react";
import { Modal, Row, Alert, Col } from "react-bootstrap";
import _ from "lodash";
import { Field } from "components/input/field";
import { required } from "controllers/validations";
import { reduxForm } from "redux-form";

export enum TransferLocations {
    ORIGIN,
    DESTINATION,
    BACKTRACK,
}

export const ChangeLocationAndCreateModuleOrigin = ({
    saver,
    visible,
    handleClose,
}: {
    saver: any;
    visible: boolean;
    handleClose: any;
}) => {
    const changeLocation = async (values: any) => {
        console.log(values);
        saver(values);
    };

    return (
        <Modal show={visible} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Cambiar Ubicación de Origen</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FormWithReduxOrigin onSubmit={changeLocation} />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const FormOrigin = ({ submitting, handleSubmit, submitSucceeded }: any) => {
    if (submitSucceeded) return <Alert variant="success">Ubicación modificada</Alert>;

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Field labelinput={<>Dirección de origen</>} name="address" type="address-with-map-coords" validate={required} />
                </Col>
            </Row>
            <SubmitButton block submitting={submitting}>
                Cambiar
            </SubmitButton>
        </form>
    );
};

export const FormWithReduxOrigin = reduxForm<{}, any>({
    form: "changeloc-form",
})(FormOrigin);

export const ChangeLocationAndCreateModuleDestination = ({
    saver,
    visible,
    handleClose,
}: {
    saver: any;
    visible: boolean;
    handleClose: any;
}) => {
    const changeLocation = async (values: any) => {
        saver(values);
    };

    return (
        <Modal show={visible} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Cambiar Ubicación de Destino</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FormWithReduxDestination onSubmit={changeLocation} />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const FormDestination = ({ submitting, handleSubmit, submitSucceeded }: any) => {
    if (submitSucceeded) return <Alert variant="success">Ubicación modificada</Alert>;

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Field labelinput={<>Dirección de destino</>} name="address" type="address-with-map-coords" validate={required} />
                </Col>
            </Row>
            <SubmitButton block submitting={submitting}>
                Cambiar
            </SubmitButton>
        </form>
    );
};

export const FormWithReduxDestination = reduxForm<{}, any>({
    form: "changeloc-form",
})(FormDestination);
