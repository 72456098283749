import { Uid } from "controllers/auth";
import database, { WithId, WithUid } from "controllers/database";
import { PersonalInformation, User } from "types/user-data";

export const getPersonalInformation = (
  uid: Uid
): Promise<PersonalInformation> =>
  database
    .collection("user")
    .doc(uid)
    .collection("personal")
    .doc(uid)
    .as(PersonalInformation);

export const getPersonalInformationColDoc = (uid: Uid) => [
  `user/${uid}/personal`,
  uid,
];
export const getLicenceInformationColDoc = (uid: Uid) => [
  `user/${uid}/licence`,
  uid,
];
export const getTaxableInformationColDoc = (uid: Uid) => [
  `user/${uid}/taxable`,
  uid,
];

export const setPersonalInformation = (
  uid: Uid,
  personalInformation: PersonalInformation
): Promise<void> =>
  database
    .collection("user")
    .doc(uid)
    .collection("personal")
    .doc(uid)
    .set(personalInformation, PersonalInformation);

export const getUsers = (): Promise<(WithId & User)[]> =>
  database.collection("user").as(User);

export const getPersonalInformationAdminWithEmail = (): Promise<
  (WithId & PersonalInformation & { email: string })[]
> => {
  //const result = database.collection('user').as(User)
  const result = database.collectionGroup("personal").as(PersonalInformation);
  const qqResult = result.then((aPersonalInfo) =>
    Promise.all(
      aPersonalInfo.map((personalInfo) => {
        const result = database
          .collection("user")
          .doc(personalInfo.id)
          .as(User);
        return result.then(
          (user) =>
            ({
              ...personalInfo,
              email: user.email,
            } as WithId & PersonalInformation & { email: string })
        );
      })
    )
  );
  return qqResult;
};

export const getPersonalInformationAdmin = (): Promise<
  (WithId & WithUid & PersonalInformation)[]
> => {
  //const result = database.collection('user').as(User)
  return database.collectionGroup("personal").as(PersonalInformation);
};
