import firebase from "./firebase";
import { WithCopyConstructor } from "util/meta";
import { Uid } from "./auth";

const fbDatabase = firebase.firestore();

export declare type Indexable = { [key: string]: any; id?: never };

export declare type WithId = { id: string };
export declare type WithRef = { ref: Reference };
export declare type WithUid = { uid: Uid };

export declare type Reference =
  firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;

const collection = <T extends unknown>(ref: string): Collection =>
  new Collection(fbDatabase.collection(ref));

const collectionGroup = <T extends unknown>(id: string) =>
  new CollectionGroup(fbDatabase.collectionGroup(id));

const document = <T extends unknown>(ref: string): Document =>
  new Document(fbDatabase.doc(ref));

abstract class GroupOfDocuments {
  collection!:
    | firebase.firestore.Query<firebase.firestore.DocumentData>
    | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  /**
   * Creates and returns a new Query with the additional filter that documents
   * must contain the specified field and the value should satisfy the
   * relation constraint provided.
   *
   * @param fieldPath The path to compare
   * @param opStr The operation string (e.g "<", "<=", "==", ">", ">=").
   * @param value The value for comparison
   * @return The created Query.
   */
  where = (field: string) => {
    const f = (operator: any /*WhereFilterOp*/) => (value: any) =>
      new CollectionGroup(this.collection.where(field, operator, value));
    return {
      is: f("=="),
      isGreaterThan: f(">"),
      isGreaterOrEqualThan: f(">="),
      isLowerThan: f("<"),
      isLowerOrEqualThan: f("<="),
      arrayContains: f("array-contains"),
      in: f("in"),
      arrayContainsAny: f("array-contains-any"),
    };
  };

  /**
   * Creates and returns a new Query that's additionally sorted by the
   * specified field, optionally in ascending order instead of descending.
   *
   * @param fieldPath The field to sort by.
   * @param directionStr Optional direction to sort by (`asc` or `desc`). If
   * not specified, order will be descending.
   * @return The created Query.
   */
  orderBy = (field: string, ord: "desc" | "asc" = "desc") =>
    new CollectionGroup(this.collection.orderBy(field, ord));

  /**
   * Creates and returns a new Query that only returns the first matching
   * documents.
   *
   * @param limit The maximum number of items to return.
   * @return The created Query.
   */
  limit = (num: number) => new CollectionGroup(this.collection.limit(num));

  /**
   * Creates and returns a new Query that starts after the provided fields
   * relative to the order of the query. The order of the field values
   * must match the order of the order by clauses of the query.
   *
   * @param fieldValues The field values to start this query after, in order
   * of the query's order by.
   * @return The created Query.
   */
  startAt = (fieldValues: any[]) =>
    new CollectionGroup(this.collection.startAt(...fieldValues));

  /**
   * Creates and returns a new Query that starts after the provided fields
   * relative to the order of the query. The order of the field values
   * must match the order of the order by clauses of the query.
   *
   * @param fieldValues The field values to start this query after, in order
   * of the query's order by.
   * @return The created Query.
   */
  startAfter = (fieldValues: any[]) =>
    new CollectionGroup(this.collection.startAfter(...fieldValues));

  /**
   * Creates and returns a new Query that ends before the provided fields
   * relative to the order of the query. The order of the field values
   * must match the order of the order by clauses of the query.
   *
   * @param fieldValues The field values to end this query before, in order
   * of the query's order by.
   * @return The created Query.
   */
  endBefore = (fieldValues: any[]) =>
    new CollectionGroup(this.collection.endBefore(...fieldValues));

  /**
   * Creates and returns a new Query that ends at the provided document
   * (inclusive). The end position is relative to the order of the query. The
   * document must contain all of the fields provided in the orderBy of this
   * query.
   *
   * @param snapshot The snapshot of the document to end at.
   * @return The created Query.
   */
  endAt = (fieldValues: any[]) =>
    new CollectionGroup(this.collection.endAt(...fieldValues));
}

export class CollectionGroup extends GroupOfDocuments {
  collection!: firebase.firestore.Query<firebase.firestore.DocumentData>;
  constructor(
    _collection: firebase.firestore.Query<firebase.firestore.DocumentData>
  ) {
    super();
    this.collection = _collection;
  }
  as = <T extends unknown>(
    type: WithCopyConstructor<T>
  ): Promise<(WithUid & WithId & T)[]> =>
    this.collection.get().then((snap) =>
      Promise.all(
        snap.docs
          .filter((doc) => doc.ref.parent.parent !== undefined)
          .map((doc) =>
            new Document(doc.ref).as(type).then((val) => {
              (val as WithId & T).id = doc.ref.id;
              (val as WithUid & T).uid = (doc.ref.parent.parent as any).id;
              return val as WithUid & (WithId & T);
            })
          )
      )
    );

  asWithoutUid = <T extends unknown>(
    type: WithCopyConstructor<T>
  ): Promise<(WithId & T)[]> =>
    this.collection.get().then((snap) =>
      Promise.all(
        snap.docs.map((doc) =>
          new Document(doc.ref).as(type).then((val) => {
            (val as WithId & T).id = doc.ref.id;
            return val as WithUid & (WithId & T);
          })
        )
      )
    );
}

export class Collection extends GroupOfDocuments {
  collection!: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  constructor(_collection: firebase.firestore.CollectionReference) {
    super();
    this.collection = _collection;
  }
  doc = (ref: string) => new Document(this.collection.doc(ref));
  as = <T extends unknown>(
    type: WithCopyConstructor<T>
  ): Promise<(WithId & T)[]> =>
    this.collection.get().then((snap) =>
      Promise.all(
        snap.docs.map((doc) =>
          new Document(doc.ref).as(type).then((val) => {
            (val as WithId & T).id = doc.id;
            return val as WithId & T;
          })
        )
      )
    );

  get = <T extends unknown>(): Promise<(WithId & T)[]> =>
    this.collection.get().then((snap) =>
      Promise.all(
        snap.docs.map((doc) =>
          new Document(doc.ref).get().then((val) => {
            (val as WithId & T).id = doc.id;
            return val as WithId & T;
          })
        )
      )
    );

  asCollectionGroup = (): CollectionGroup =>
    new CollectionGroup(this.collection);

  add = <T extends Object>(data: T, type: WithCopyConstructor<T>) => {
    return this.collection.add({
      ...new type(data),
    } as firebase.firestore.DocumentData);
  };
}

class Document {
  data!: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  constructor(
    _data: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  ) {
    this.data = _data;
  }
  collection = (ref: string) => new Collection(this.data.collection(ref));
  as = <T extends unknown>(type: WithCopyConstructor<T>): Promise<T> =>
    this.get()
      .then((data) => new type(data as T))
      .catch((e) => {
        throw Error(e + " path:" + this.data.path);
      });

  /**
   * Usar cuando no sabemos si el documento existe. El primer valor del arreglo nos dice si el documento existe.
   */
  asWithExists = <T extends unknown>(
    type: WithCopyConstructor<T>
  ): Promise<[boolean, T | undefined]> =>
    this.data
      .get()
      .then((data) => [
        data.exists,
        data.data() !== undefined ? new type(data.data() as T) : undefined,
      ]);

  get = <T extends unknown>(): Promise<T> => {
    return this.data.get().then((snap) => {
      const data = snap.data();
      if (data) {
        return data as T;
      } else {
        throw new Error(`El documento '${this.data.path}' no existe.`);
      }
    });
  };

  delete = (): Promise<void> => {
    return this.data.delete().then();
  };

  set = <T extends Object>(
    newdata: T,
    type: WithCopyConstructor<T>
  ): Promise<void> =>
    this.data
      .set({ ...new type(newdata) } as firebase.firestore.DocumentData)
      .then();

  update = <T extends Object>(
    newdata: T,
    type: WithCopyConstructor<T>
  ): Promise<void> =>
    this.data
      .update({ ...new type(newdata) } as firebase.firestore.DocumentData)
      .then();
  /* .catch( (e:FirebaseError) => {
            if(e.code === '')
        })*/
}

const database = {
  collection: collection,
  collectionGroup: collectionGroup,
  doc: document,
};

export default database;
