import { List } from "antd";
import { Field } from "components/input/field";
import {
  AdminTable,
  Button,
  ContainerPage,
  H3,
  Link,
  PageTitle,
  Spinner,
  SubmitButton,
} from "components/styles";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import { required } from "controllers/validations";
import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Alert,
  Pagination as BPagination,
} from "react-bootstrap";
import { reduxForm } from "redux-form";
import {
  BillInfoTransfer,
  PaymentOrderDriver,
  SupportedBanks,
  TypePayment,
} from "types/billing";
import { getEnumKeys } from "util/typescript";
import { SelectDriverInput } from "../Purchases";
import { UserName } from "../Sales/view-bill";
import _ from "lodash";
import {
  FbDate,
  ScheduledTransfer,
  TransferStage,
  TransferType,
} from "types/transfer";
import moment from "moment";
import { DatePickerInput } from "components/input/date-picker";
import * as fs from "file-saver";
import * as ExcelJS from "exceljs";
import { getTaxableEntity } from "controllers/user/info/taxable";
import { RevisionStatus } from "types/user-data";
const GeneratePaymentOrder = () => {
  const [lastDigitDNI, setLastDigitDNI] = useState("");
  const [driver, setDriver] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().endOf("month").toDate()
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [newDocument, setNewDocument] = useState<string | undefined>(undefined);
  const [items2, setItems2] = useState<any[]>([]);
  const [selected, setSelected] = useState<Set<any>>(new Set());
  const [query, setQuery] = useState<boolean>(false);
  const [show, setShow] = useState<Set<any>>(new Set());
  const [contraer, setContraer] = useState<boolean>(true);

  useEffect(() => {
    setSelected(new Set());
    setShow(new Set());
  }, []);

  const addAll = () => {
    const z = new Set(selected);

    items2.map((item) => {
      if (item.bank_entry === true)
        item.fcs.map((fc: any) => {
          const element = `${fc.ref.id}-${fc.data.driver}-${fc.data.voucher_number}-${fc.data.ImpTotal}`;

          if (!selected.has(element)) {
            z.add(element);
            setSelected(z);
          }
        });
    });
  };
  const removeAll = () => {
    setSelected(new Set());
  };
  let choferes: any[] = [];
  let items: any = [];
  let facturasImpagas: { data: BillInfoTransfer | undefined; ref: any }[] = [];
  const buscar = async () => {
    setSelected(new Set());
    setLoading(true);
    items = [];
    choferes = [];
    setItems2([]);
    let billedTransfers = (
      await getUnpaidTransfers(driver, startDate, endDate).get()
    ).docs.map((e) => ({ data: e.data(), ref: e.ref }));

    await Promise.all(
      billedTransfers.map(async (bT) => {
        const factura = await bT.data.sale?.bill_info?.ref.get();
        const fcRef = factura?.ref;
        const fcData = factura?.data();

        if (lastDigitDNI.length === 3) {
          const num1 = lastDigitDNI.split("-")[0];
          console.log(num1, "num1");

          const num2 = lastDigitDNI.split("-")[1];
          console.log(num2, "num2");
          console.log(
            fcData?.deleted_vouche_number,
            !fcData?.deleted_vouche_number,
            "false?"
          );

          if (!fcData?.payment_order && !fcData?.deleted_vouche_number) {
            if (
              fcData?.keywords.includes(num1) ||
              fcData?.keywords.includes(num2)
            ) {
              if (!choferes.includes(fcData?.driver)) {
                if (fcData?.driver) {
                  choferes.push(fcData?.driver);
                }
              }
              facturasImpagas.push({ data: fcData, ref: fcRef });
              console.log(facturasImpagas, "facturas impagas1");
            }
          }
        } else if (!fcData?.payment_order && !fcData?.deleted_vouche_number) {
          if (!choferes.includes(fcData?.driver)) {
            if (fcData?.driver) {
              choferes.push(fcData?.driver);
            }
          }
          facturasImpagas.push({ data: fcData, ref: fcRef });
        }
      })
    );
    //Elimo las FC repetidas
    const fcUnicos: { data: BillInfoTransfer | undefined; ref: any }[] = [];

    for (var indice = 0; indice < facturasImpagas.length; indice++) {
      const factura = facturasImpagas[indice];
      let esDuplicado = false;
      for (var i = 0; i < fcUnicos.length; i++) {
        if (
          fcUnicos[i].data?.voucher_number === factura.data?.voucher_number &&
          factura.data?.cuit === fcUnicos[i].data?.cuit
        ) {
          esDuplicado = true;
          break;
        }
      }
      if (!esDuplicado) {
        fcUnicos.push(factura);
      }
    }

    console.log(fcUnicos, "fcUnicos");
    console.log(choferes, "choferes");

    for (let i = 0; i < choferes.length; i++) {
      const chofer = choferes[i];
      const data = (await FIRESTORE.DOCUMENT_USER(chofer).get()).data();

      let nombre = `${data?.surname} ${data?.name}`;
      nombre = nombre.toUpperCase();
      const choferFcs = fcUnicos.filter((f) => f.data?.driver === chofer);
      let monto = 0;
      for (let j = 0; j < choferFcs.length; j++) {
        const impTotal = choferFcs[j].data!.ImpTotal;
        monto += impTotal;
      }
      items.push({
        name: nombre,
        user: chofer,
        monto,
        fcs: choferFcs,
        bank_entry: data?.bank_entry,
      });
    }
    setItems2(items);
    setQuery(true);
    setLoading(false);
  };
  const search = () => {
    buscar();
    console.log(startDate, "startDate");
    console.log(endDate, "endDate");
  };
  const contador = (fcs: any[]) => {
    let cantidad = 0;
    fcs.map((fc) => {
      const element = `${fc.ref.id}-${fc.data.driver}-${fc.data.voucher_number}-${fc.data.ImpTotal}`;
      if (selected.has(element)) {
        cantidad++;
      }
    });
    return `${cantidad}/${fcs.length}`;
  };
  const generatePayment = async (values: any) => {
    let data = await Promise.all(
      [...selected].map((v) =>
        FIRESTORE.DOCUMENT_DRIVER_BILL(v.split("-")[0])
          .get()
          .then((v) => ({ ...v.data()!, id: v.id }))
      )
    );
    data = data.sort();

    const configuracionContadoresData = (
      await FIRESTORE.DOCUMENT_CONFIGURATION_CONTADOR().get()
    ).data();
    const totalPagos = configuracionContadoresData?.ordenes_de_pago;
    const payment = {
      bills: data.map((t) => FIRESTORE.DOCUMENT_DRIVER_BILL(t.id)),
      bank: values.bank,
      sucursal: values.sucursal,
      concept: values.concept,
      concept2: values.concept2,
      driver: data[0].driver,
      type: Number(values.typePayment),
      amount: data.reduce((a, b) => a + b.ImpTotal, 0),
      creation_date: FbDate.fromDate(new Date()),
      keywords: [
        ..._.flattenDeep(data.map((v) => v.keywords)),
        //Número de factura<br/>Siniestro<br/>Cód. Aut<br/>NPA
      ],
      orden_pago_int: totalPagos! + 1,
    } as PaymentOrderDriver;

    const doc = await FIRESTORE.COLLECTION_PAYMENTS_DRIVERS().add(payment);

    if (totalPagos) {
      await FIRESTORE.DOCUMENT_CONFIGURATION_CONTADOR().update({
        ordenes_de_pago: totalPagos + 1,
      });
    }
    let contadorBas = (
      await FIRESTORE.DOCUMENT_CONFIGURATION_CONTADOR().get()
    ).data()?.ordenes_de_pago_bas!;
    let dirverActual: string = "";

    await Promise.all(
      data.map(async (v) => {
        console.log(contadorBas, "contador");
        console.log(v.driver, "v.driver");
        console.log(dirverActual, "DA");
        if (v.driver !== dirverActual) {
          contadorBas++;
          dirverActual = v.driver;
        }
        await FIRESTORE.DOCUMENT_DRIVER_BILL(v.id).update({
          payment_order: doc,
          orden_pago_bas: contadorBas,
        });
        return true;
      })
    );
    await FIRESTORE.DOCUMENT_CONFIGURATION_CONTADOR().update({
      ordenes_de_pago_bas: contadorBas,
    });
    setSelected(new Set());
    setNewDocument(doc.id);
    window.location.reload(false);
  };

  const changeDigitDNI = (v: string) => {
    setLastDigitDNI(v);
  };
  console.log(items, "items");

  return (
    <ContainerPage>
      <Row>
        <Col>
          <PageTitle>Agregar Pago de prestador</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Últimos dígitos DNI</Form.Label>
            <Form.Control
              as="select"
              value={lastDigitDNI}
              onChange={(e) => changeDigitDNI(e.target.value)}
            >
              <option key={5} value={undefined}>
                TODOS LOS PRESTADORES
              </option>
              <option key={0} value={"0-1"}>
                0-1
              </option>
              <option key={1} value={"2-3"}>
                2-3
              </option>
              <option key={2} value={"4-5"}>
                4-5
              </option>
              <option key={3} value={"6-7"}>
                6-7
              </option>
              <option key={4} value={"8-9"}>
                8-9
              </option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col style={{ marginTop: 32 }}>
          <SelectDriverInput onChange={setDriver} value={driver} />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Desde (fch de Traslado)</Form.Label>
            <DatePickerInput value={startDate} onChange={setStartDate} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Hasta (fch de Traslado)</Form.Label>
            <DatePickerInput value={endDate} onChange={setEndDate} />
          </Form.Group>
        </Col>
        <Col>
          <Button style={{ marginTop: 32 }} onClick={search}>
            Buscar
          </Button>
          <Button
            variant="success"
            style={{ marginTop: 32 }}
            //disabled={[...selected].find(}
            onClick={() => generateExcel([...selected], driver)}
          >
            Excel
          </Button>
        </Col>
        <Col>
          <Form.Group>
            <Button
              disabled={query ? false : true}
              variant="warning"
              onClick={addAll}
            >
              Agregar TODAS las facturas
            </Button>

            <Button
              disabled={query ? false : true}
              variant="danger"
              onClick={removeAll}
            >
              Quitar TODAS las facturas
            </Button>
          </Form.Group>
        </Col>
      </Row>

      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : (
        <AdminTable responsive>
          <thead>
            <tr>
              <th>Conductor</th>
              <th>Ingresó Banco</th>
              <th>Número Facturas</th>
              <th>Facturas agregadas</th>
              <th>Monto Total</th>
              <th>Ver facturas</th>
              <th>Quitar</th>
              <th>Agregar</th>
            </tr>
          </thead>
          <tbody>
            {items2.map((item: any) => (
              <tr key={item.name}>
                <td>{item.name}</td>
                <td>{item.bank_entry === true ? "Si" : "No"}</td>
                <td>{item.fcs.length}</td>
                <td>{contador(item.fcs)}</td>
                <td>{item.monto.toFixed(2)}</td>
                <td>
                  <Button
                    disabled={item.bank_entry !== true}
                    onClick={() => {
                      const z = new Set();
                      item.fcs.map((fc: any) =>
                        z.add(
                          `${fc.ref.id}-${fc.data.driver}-${fc.data.voucher_number}-${fc.data.ImpTotal}`
                        )
                      );
                      setShow(z);
                      setContraer(false);
                    }}
                  >
                    Ver
                  </Button>
                </td>
                <td>
                  <Button
                    variant="danger"
                    disabled={item.bank_entry !== true}
                    onClick={() => {
                      const z = new Set(selected);
                      item.fcs.map((fc: any) => {
                        const element = `${fc.ref.id}-${fc.data.driver}-${fc.data.voucher_number}-${fc.data.ImpTotal}`;
                        if (selected.has(element)) {
                          z.delete(element);
                          setSelected(z);
                        }
                      });
                    }}
                  >
                    Quitar seleccionadas
                  </Button>
                </td>
                <td>
                  <Button
                    variant="success"
                    disabled={item.bank_entry !== true}
                    onClick={() => {
                      const z = new Set(selected);
                      item.fcs.map((fc: any) => {
                        const element = `${fc.ref.id}-${fc.data.driver}-${fc.data.voucher_number}-${fc.data.ImpTotal}`;
                        if (!selected.has(element)) {
                          z.add(element);
                          setSelected(z);
                        }
                      });
                    }}
                  >
                    Agregar todas
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </AdminTable>
      )}
      {contraer && query ? (
        <>
          <br />
          <Button onClick={() => setContraer(false)}>Mostrar</Button>
        </>
      ) : !contraer && query ? (
        <>
          <br />
          <Button onClick={() => setContraer(true)}>Ocultar</Button>
        </>
      ) : (
        ""
      )}

      {!contraer ? (
        <>
          <H3>Facturas del chofer seleccionado</H3>
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={[...show]}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Link
                    to={ROUTES.ADMIN_SALES_SALES_DRIVER_BILL_INFO(
                      item.split("-")[0]
                    )}
                  >
                    <Button variant="light">Ver</Button>
                  </Link>,
                  !selected.has(item) ? (
                    <Button
                      variant="success"
                      onClick={() => {
                        const z = new Set(selected);
                        z.add(item);
                        setSelected(z);
                      }}
                    >
                      Agregar
                    </Button>
                  ) : (
                    <Button
                      variant="danger"
                      key="list-loadmore-edit"
                      onClick={() => {
                        const z = new Set(selected);
                        z.delete(item);
                        setSelected(z);
                      }}
                    >
                      ELIMINAR
                    </Button>
                  ),
                ]}
              >
                <div>
                  <UserName uid={item.split("-")[1]} surname /> -{" "}
                  {item.split("-")[2]} - ${item.split("-")[3]}
                </div>
              </List.Item>
            )}
          />
        </>
      ) : (
        ""
      )}
      {!contraer ? (
        <>
          <br />
          <Button onClick={() => setContraer(true)}>Ocultar</Button>
        </>
      ) : (
        ""
      )}
      <br />
      <b>
        <i>Importe Total:</i> <TotalAmount set={selected} />
      </b>

      {newDocument && (
        <Alert variant="success" style={{ width: "100%" }}>
          Nuevo pago creado con id<span> </span>
          <Link to={ROUTES.ADMIN_SALES_DRIVER_PAYMENT(newDocument)}>
            {newDocument}
          </Link>
        </Alert>
      )}
      <FormGeneratePaymentRedux onSubmit={generatePayment} />
    </ContainerPage>
  );
};
const TotalAmount = ({ set }: { set: Set<string> }) => {
  const [amount, setAmount] = useState(0);
  useEffect(() => {
    calcAmount();
  }, [set]);
  const calcAmount = async () => {
    const data = await Promise.all(
      [...set].map((v) =>
        FIRESTORE.DOCUMENT_DRIVER_BILL(v.split("-")[0])
          .get()
          .then((v) => v.data()!)
      )
    );
    setAmount(data.reduce((a, b) => a + b.ImpTotal, 0));
  };
  return <>${amount.toFixed(2)}</>;
};

const FormGeneratePayment = ({
  handleSubmit,
  submitting,
  error,
  submitSucceeded,
}: any) => {
  if (submitting) return <Spinner />;
  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error.message}</Alert>}</Row>
      <Row>
        <Col xl={4}>
          <Field
            name="bank"
            type="select"
            labelinput="Seleccionar Banco"
            validate={required}
          >
            {getEnumKeys(SupportedBanks).map((v) => (
              <option value={SupportedBanks[v as any]} key={v}>
                {v}
              </option>
            ))}
          </Field>
        </Col>
        <Col xl={4}>
          <Field
            name="concept"
            type="text"
            labelinput="Concepto (3 letras)"
            validate={required}
          />
        </Col>
        <Col xl={4}>
          <Field
            name="typePayment"
            type="select"
            labelinput="Tipo de pago"
            validate={required}
          >
            {getEnumKeys(TypePayment).map((v) => (
              <option value={TypePayment[v as any]} key={v}>
                {v}
              </option>
            ))}
          </Field>
        </Col>
        <Col>
          <Field
            name="concept2"
            type="text"
            labelinput="Concepto (largo)"
            validate={required}
          />
        </Col>
        <Col>
          <Field
            name="sucursal"
            type="text"
            labelinput="Número de cuenta bancaria"
            validate={required}
          />
        </Col>
        <Col>
          <SubmitButton
            block
            submitting={submitting}
            variant="warning"
            style={{ marginTop: 34 }}
          >
            Generar Pago
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};

const FormGeneratePaymentRedux = reduxForm({
  form: "bill-transfer",
})(FormGeneratePayment);

const getUnpaidTransfers = (
  uid: string | undefined,
  startDate: Date,
  endDate: Date
) => {
  let col;
  if (uid) {
    col = FIRESTORE.COLLECTION_USER_TRANSFERS(uid);
  } else {
    col = FIRESTORE.COLLECTION_TRANSFERS();
  }

  col = col.where("sale.billed_from_driver", "==", 1);
  return col
    .where("origin.time", ">=", startDate)
    .where("origin.time", "<=", endDate)
    .orderBy("origin.time", "desc");
};

const generateExcel = async (seleccionado: any[], chofer?: string) => {
  console.log(seleccionado);
  const dataForName = chofer
    ? (await FIRESTORE.DOCUMENT_USER(chofer).get()).data()
    : null;
  let name = dataForName
    ? `${dataForName?.surname} ${dataForName?.name}`
    : null;
  name ? (name = name.toUpperCase()) : (name = null);
  let workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet(
    name ? `Detalle ${name}` : "Traslados facturados"
  );
  worksheet.columns = [
    { header: "Prestador", key: "driver", width: 30 },
    { header: "Siniestro-Cod.Aut.", key: "sin", width: 18 },
    { header: "Fecha del Traslado", key: "fecha", width: 20 },
    { header: "Hora Origen", key: "hora_or", width: 15 },
    { header: "Hora Destino", key: "hora_des", width: 15 },
    { header: "Paciente", key: "paciente", width: 30 },
    { header: "Origen", key: "domicilio_or", width: 25 },
    { header: "Destino", key: "domicilio_de", width: 25 },
    { header: "Tiempo viaje", key: "tiempo", width: 18 },
    { header: "KM viaje", key: "km", width: 18 },
    { header: "Estado", key: "status", width: 20 },
    { header: "Valor base del viaje", key: "base", width: 20 },
    { header: "Minutos Espera", key: "extra_wait_time_min", width: 15 },
    { header: "Valor Espera", key: "extra_wait_time", width: 15 },
    {
      header: "Minutos Espera Informe",
      key: "extra_wait_time_report_min",
      width: 15,
    },
    {
      header: "Valor Espera Informe",
      key: "extra_wait_time_report",
      width: 15,
    },
    { header: "Adicional Km muerto", key: "additional_K", width: 20 },
    { header: "Adicional 5M", key: "additional_5M", width: 20 },
    { header: "Adicional Cumplimiento", key: "additional_C", width: 20 },
    { header: "Adicional Multiplicador", key: "additional_M", width: 20 },
    { header: "Adicional G", key: "additional_G", width: 20 },
    { header: "Peajes", key: "tolls", width: 10 },
    { header: "Valor total traslado", key: "total", width: 20 },
    { header: "NRO Factura", key: "fact", width: 15 },
    { header: "Fecha Factura", key: "fecha-fact", width: 15 },
    { header: "CUIT Presador", key: "cuit", width: 20 },
    { header: "Tipo de Cuenta", key: "type", width: 20 },
    { header: "Nro de Cuenta", key: "acc_num", width: 20 },
    { header: "CBU", key: "cbu", width: 24 },
    { header: "CVU", key: "cvu", width: 24 },
    { header: "mail", key: "mail", width: 35 },

    //si tiene pago, agregar la orden de pago y fecha orden de pago
  ];
  await Promise.all(
    seleccionado.map(async (b) => {
      const bill = (
        await FIRESTORE.DOCUMENT_DRIVER_BILL(b.split("-")[0]).get()
      ).data();
      const data = (await FIRESTORE.DOCUMENT_USER(bill!.driver).get()).data();
      for (let i = 0; i < bill!.transfers.length; i++) {
        const transferData: ScheduledTransfer | undefined = (
          await bill!.transfers[i].get()
        ).data();
        if (transferData && bill) {
          const finish = transferData.status
            ? transferData.status.history
              ? transferData.status.history.stages.find(
                  (element: any) => element.stage == 9
                )
              : null
            : null;

          let nombre = `${data?.surname} ${data?.name}`;
          nombre = nombre.toUpperCase();
          let amountWaitRequestMin;
          try {
            amountWaitRequestMin = calculateExtraAwaitTimeRequest(
              transferData,
              transferData.info!.amount!.minimum_wait!
            );
          } catch (e) {
            console.log(bill.pdf, nombre);
          }

          let waitTimeAmount: number = 0;
          try {
            waitTimeAmount =
              transferData!.type === TransferType.TRIP_AND_RETURN
                ? calculateExtraAwaitTimeRequest(
                    transferData,
                    transferData.info!.amount!.minimum_wait!
                  ) * transferData.info.amount?.price_minute_wait!
                : 0;
          } catch (e) {
            console.log(bill.pdf, nombre);
          }
          console.log(waitTimeAmount, "waitTimeAmount");

          let amountTolls = 0;
          if (transferData.tolls !== undefined) {
            amountTolls += transferData.tolls.data
              .map((t) => {
                if (t.status === RevisionStatus.Aprobado)
                  return t.amount_corrected !== undefined
                    ? Number(t.amount_corrected)
                    : Number(t.amount);
                return 0;
              })
              .reduce((a, b) => a + b, 0);
          }
          let amountTransfer = 0;
          let amountTotal = 0;
          let additional_K = 0;
          let additional_5M = 0;
          let additional_M = 0;
          let additional_C = 0;
          let additional_G = 0;
          amountTransfer =
            transferData.amount +
            (transferData.info.amount?.additional_E
              ? transferData.info.amount?.additional_E
              : 0);
          additional_K = transferData.info.amount?.additional_K
            ? transferData.info.amount?.additional_K
            : 0;
          additional_5M = transferData.info.amount?.additional_5M
            ? transferData.info.amount?.additional_5M
            : 0;
          additional_M = transferData.info.amount?.addition
            ? transferData.info.amount?.addition
            : 0;
          additional_C = transferData.info.amount?.additional_C
            ? transferData.info.amount?.additional_C
            : 0;
          additional_G = transferData.info.amount?.additional_G
            ? transferData.info.amount?.additional_G
            : 0;
          amountTotal =
            amountTransfer +
            additional_K +
            additional_5M +
            additional_M +
            additional_C +
            additional_G +
            amountTolls +
            waitTimeAmount;
          const amountWaitReportsMin = calculateAwaitTimeReports(transferData);
          const taxableData = await getTaxableEntity(bill.driver);
          let accNum = data?.account_number;
          if (accNum) {
            accNum = accNum.padStart(18, "0");
          }
          taxableData.cbu
            ? worksheet.addRow({
                driver: nombre,
                sin: transferData.art.authorization
                  ? transferData.art.accident +
                    "-" +
                    transferData.art.authorization
                  : transferData.art.accident,
                fecha: moment(transferData.origin.time.toDate()).format(
                  "DD/MM/YYYY"
                ),
                hora_or: moment(transferData.origin.time.toDate()).format(
                  "HH:mm"
                ),
                hora_des: finish
                  ? moment(finish.time.toDate()).format("HH:mm")
                  : moment(transferData.destination.time.toDate()).format(
                      "HH:mm"
                    ),
                paciente: transferData?.passenger.name,
                domicilio_or: transferData.origin.address.formatted_address,
                domicilio_de:
                  transferData.destination.address.formatted_address,
                tiempo:
                  transferData.info.duration_origin_destination + " (minutos)",
                km: transferData.info.distance_origin_destination + " (metros)",
                status: transferData.status
                  ? transferData.status.finished_status == 0
                    ? "Finalizado Exitoso"
                    : transferData.status.finished_status == 1
                    ? "Cancelado"
                    : transferData.status.finished_status == 2
                    ? "Finalizado Negativo"
                    : "no terminado"
                  : "no terminado",
                base: amountTransfer,
                extra_wait_time_min: amountWaitRequestMin,
                extra_wait_time: waitTimeAmount ? waitTimeAmount : "",
                extra_wait_time_report_min: amountWaitReportsMin,
                extra_wait_time_report: bill.amounts
                  ? bill.amounts.wait_reports
                  : "",
                tolls: amountTolls,
                additional_K: additional_K,
                additional_5M: additional_5M,
                additional_M: additional_M,
                additional_C: additional_C,
                additional_G: additional_G,
                total: amountTotal,
                fact: bill.point_of_sale + "-" + bill.voucher_number,
                cuit: bill.cuit,
                "fecha-fact": moment(bill.creation_date.toDate()).format(
                  "DD/MM/YYYY"
                ),
                type: taxableData.typeAccount,
                acc_num: accNum ? accNum : "",
                cbu: taxableData.cbu,
                mail: data?.email,
              })
            : worksheet.addRow({
                driver: nombre,
                sin: transferData.art.authorization
                  ? transferData.art.accident +
                    "-" +
                    transferData.art.authorization
                  : transferData.art.accident,
                fecha: moment(transferData.origin.time.toDate()).format(
                  "DD/MM/YYYY"
                ),
                hora_or: moment(transferData.origin.time.toDate()).format(
                  "HH:mm"
                ),
                hora_des: finish
                  ? moment(finish.time.toDate()).format("HH:mm")
                  : moment(transferData.destination.time.toDate()).format(
                      "HH:mm"
                    ),
                paciente: transferData?.passenger.name,
                domicilio_or: transferData.origin.address.formatted_address,
                domicilio_de:
                  transferData.destination.address.formatted_address,
                tiempo:
                  transferData.info.duration_origin_destination + " (minutos)",
                km: transferData.info.distance_origin_destination + " (metros)",
                status: transferData.status
                  ? transferData.status.finished_status == 0
                    ? "Finalizado Exitoso"
                    : transferData.status.finished_status == 1
                    ? "Cancelado"
                    : transferData.status.finished_status == 2
                    ? "Finalizado negativo"
                    : "no terminado"
                  : "no terminado",
                base: amountTransfer,
                extra_wait_time_min: amountWaitRequestMin,
                extra_wait_time: waitTimeAmount,
                extra_wait_time_report_min: amountWaitReportsMin,
                extra_wait_time_report: bill.amounts
                  ? bill.amounts.wait_reports
                  : "",
                tolls: amountTolls,
                additional_K: additional_K,
                additional_5M: additional_5M,
                additional_M: additional_M,
                additional_C: additional_C,
                additional_G: additional_G,
                total: amountTotal,
                fact: bill.point_of_sale + "-" + bill.voucher_number,
                cuit: bill.cuit,
                "fecha-fact": moment(bill.creation_date.toDate()).format(
                  "DD/MM/YYYY"
                ),
                type: taxableData.typeAccount,
                acc_num: accNum ? accNum : "",
                cvu: taxableData.cvu,
                mail: data?.email,
              });
        }
      }
    })
  );

  const descarga = await workbook.xlsx.writeBuffer();
  const blob = new Blob([descarga], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  fs.saveAs(
    blob,
    name ? `detalle_facturas_${name}` + ".xlsx" : "detalle_facturas_" + ".xlsx"
  );
};
const calculateExtraAwaitTimeRequest = (
  dataTransfer: ScheduledTransfer,
  config: number
) => {
  let amountTime = 0;

  if (dataTransfer.type === TransferType.TRIP_AND_RETURN) {
    const recordWaitStarted = dataTransfer.status.history.stages.find(
      (v) => v.stage === TransferStage.waiting_return_request
    );
    let recordReturnRequested = dataTransfer.status.history.stages.find(
      (v) => v.stage === TransferStage.return_requested
    );
    if (recordReturnRequested === undefined) {
      recordReturnRequested = dataTransfer.status.history.stages.find(
        (v) => v.stage === TransferStage.finished
      );
    }
    if (
      recordWaitStarted !== undefined &&
      recordReturnRequested !== undefined
    ) {
      const amountTimeWaitingRequest = moment(
        recordReturnRequested.time.toDate()
      ).diff(recordWaitStarted.time.toDate(), "minutes");
      if (amountTimeWaitingRequest > config)
        amountTime += amountTimeWaitingRequest - config;
    }
  }
  return amountTime;
};

const calculateAwaitTimeReports = (dataTransfer: ScheduledTransfer) => {
  let amountTime = 0;
  if (dataTransfer?.status?.history?.reports !== undefined)
    dataTransfer.status.history.reports.forEach((v) => {
      if (v.date_buy15 !== undefined) amountTime += 15;
    });
  return amountTime;
};
export default GeneratePaymentOrder;
