import { pxToPt } from 'constants/css';
import ROUTES from 'constants/routes';
import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import * as R from 'react-router-dom';
import styled from 'styled-components';

const MiniNavigation = () => {
    const location = R.useLocation();
    const route = location.pathname
    return(
        <Container> 
            <Navbar bg="light" expand="lg" className="mainMenu">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse>
                    <Nav>
                        <NavLink to={ROUTES.ADMIN_SALES_PURCHASES} active={route.startsWith(ROUTES.ADMIN_SALES_PURCHASES)}>Compras</NavLink>
                        <NavLink to={ROUTES.ADMIN_SALES_SALES} active={route.startsWith(ROUTES.ADMIN_SALES_SALES)}>Ventas</NavLink>
                        <NavLink to={ROUTES.ADMIN_SALES_DRIVERS_PAYMENTS} active={route.startsWith(ROUTES.ADMIN_SALES_DRIVERS_PAYMENTS)}>Pagos</NavLink>
                        <NavLink to={ROUTES.ADMIN_SALES_CHARGE} active={route.startsWith(ROUTES.ADMIN_SALES_CHARGE)}>Cobranza</NavLink>
                        <NavLink to={ROUTES.ADMIN_SALES_FINANCES} active={route.startsWith(ROUTES.ADMIN_SALES_FINANCES)}>Finanzas</NavLink>
                        <NavLink to={ROUTES.ADMIN_SALES_MANAGEMENT} active={route.startsWith(ROUTES.ADMIN_SALES_MANAGEMENT)}>Gestión</NavLink>
                        <NavLink to={ROUTES.ADMIN_SALES_BAS} active={route.startsWith(ROUTES.ADMIN_SALES_BAS)}>BAS</NavLink>
                    </Nav>                
                </Navbar.Collapse>
            </Navbar>
        </Container>
    )
}

const NavLink = styled(({active, ...props}) => <R.NavLink {...props}/>)`
    padding: ${pxToPt(10)} ${pxToPt(15)};
    color: ${(props:any) => props.active ? '#000 !important' : '#777 !important'};
    margin-right: 10px;
    border-radius: 5px;
    background-color: ${(props:any) => props.active && '#e6e6e6'};
    font-size:${pxToPt(16)} !important;
    &:hover{
        color: #000 !important;
        background-color:#f3f3f3;
        border-radius: ${pxToPt(5)};
        text-decoration: none;
    }
    
`

export default MiniNavigation;