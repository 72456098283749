import codigosTelefono, { getValue } from "constants/codigos-telefono"
import React from "react"
import { Col, Form, InputGroup } from "react-bootstrap"
import CustomSelect from "./custom-select"

// Usar con Fields.

export const PhoneInput = ({placeholder = "Tu número de celular", labelinput, ...props} : any) => {
    const [area_code, number] = props.names.map(
        (name : string) => 
            name
            .split('.')
            .reduce(
                ((fieldGroup : any, name : string) => fieldGroup[name]),
                props
        )
    )
    const {touched, valid, invalid, error} = number.meta
    const isInvalid = invalid && touched
    const isValid = valid && touched
    return (
    <Form.Group>
        {labelinput && <Form.Label>{labelinput}</Form.Label>}
        <div>
            <InputGroup>
                <Col
                    as={CustomSelect}
                    className="input-group-prepend form-control"
                    id="area_code"
                    name={area_code.name}
                    placeholder="Cod. Area"
                    xs={4}
                    {...area_code.input}
                    >
                        <option value="" label="Cod." key={-1} disabled/>
                    { codigosTelefono.map((cod, index) => (
                        <option
                            value={getValue(cod)} key={index}>
                                {"(" + cod[0] + ") " + cod[1]}
                        </option>
                    ))}
                </Col>
                <Form.Control
                    className="col"
                    type="text"
                    id="txt_last_name"
                    name={number.name}
                    placeholder={placeholder} 
                    isInvalid={isInvalid}
                    isValid={isValid}
                    {...number.input}/>

            </InputGroup> 
        </div>
        <div>
            {isInvalid && <span className='invalid-feedback d-block'>{error}</span>}    
        </div>  
    </Form.Group>
)}



export default PhoneInput