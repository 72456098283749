import firebase from "firebase/app";
import React from "react";
import { Alert, Button, Col, Row, Accordion, Card } from "react-bootstrap";
import { BillStatus } from "types/billing";
import { ModuleTransfer } from "types/module-transfer";
import { FbDocument } from "types/transfer";

export const SubHeaderModule = ({
  module,
  doc,
}: {
  module: ModuleTransfer;
  doc: FbDocument<ModuleTransfer>;
}) => {
  const reset = async () => {
    await doc.update({
      "sale.billed": BillStatus.No,
      "sale.billed_comment": firebase.firestore.FieldValue.delete(),
    });
  };
  return (
    <Row style={{ marginTop: 10 }}>
      <Col>
        {module.sale?.billed === BillStatus["Error al facturar"] && (
          <Accordion>
            <Alert variant="danger" style={{ overflow: "auto" }}>
              <span>
                <b>Hubo un error al facturar automáticamente el módulo</b>
              </span>
              {/* <AsyncButton onClick={reset} variant='warning' className='pull-right'>Reset Error</AsyncButton> */}
              <Accordion.Toggle
                as={Button}
                style={{ fontSize: 14, margin: 5 }}
                variant="info"
                eventKey="0"
                className="pull-right"
              >
                Más Info
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="0">
                <Card.Body>{module.sale?.billed_comment}</Card.Body>
              </Accordion.Collapse>
            </Alert>
          </Accordion>
        )}
      </Col>
    </Row>
  );
};
