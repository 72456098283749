import { Uid } from "controllers/auth";
import database from "controllers/database";
import { Licence } from "types/user-data";

export const getLicence = (uid: Uid): Promise<Licence> =>
  database
    .collection("user")
    .doc(uid)
    .collection("licence")
    .doc(uid)
    .as(Licence);

export const setLicence = (uid: Uid, licence: Licence): Promise<void> =>
  database
    .collection("user")
    .doc(uid)
    .collection("licence")
    .doc(uid)
    .set(licence, Licence);
