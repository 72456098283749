import React, { FunctionComponent } from 'react'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Provider, useSelector } from 'react-redux'
import { ReactReduxFirebaseProvider, firebaseReducer } from 'react-redux-firebase'
import { createFirestoreInstance, reduxFirestore, firestoreReducer } from 'redux-firestore'
import { createLogger } from 'redux-logger'
import { reducer as formReducer } from 'redux-form'
import * as session from 'redux/session'
//import { connect } from './session'
import firebase from '../controllers/firebase'
import { User } from 'types/user-data'

const reduxLogger = createLogger({
    // ...options
  });

export const rootReducer = combineReducers({
    // ...your other reducers here
    // you have to pass formReducer under 'form' key,
    // for custom keys look up the docs for 'getFormState'
    form: formReducer,
    [session.namespace]:  session.reducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
})

// Firebase setup

const rrfConfig = {
    userProfile: 'user',
    useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

const store = createStore(
    rootReducer,
    compose(
        reduxFirestore(firebase),
        //applyMiddleware(reduxLogger)  //<---- Loguear todo lo realizado por redux
    )
)

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
}

export const ReduxApp = (App : FunctionComponent) => ( (props: any) => (
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <App/>
        </ReactReduxFirebaseProvider>
    </Provider>
))

if(process.env.NODE_ENV === 'development') {
    firebase.functions().useFunctionsEmulator('http://localhost:5001')
}

export const useProfile = () => useSelector((state:any) => state.firebase.profile) as User

//const store = createStore(rootReducer,   //viejo
//    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__())
