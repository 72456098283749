import database, { WithId } from "controllers/database"
import { GlobalConfig, ZoneConfig, CustomConfig } from "types/config"
import { ConfigContract } from "types/config"

export const getGlobalConfiguration = () : Promise<GlobalConfig & ConfigContract> => 
    database.collection('configuration').doc('all').as(GlobalConfig)
    .then( config =>  
        database.collection('configuration').doc('contract').as(ConfigContract)
        .then( contract => (
            {
                ...config,
                ...contract
            } as GlobalConfig & ConfigContract 
        )    
        )
    )

export const setGlobalConfiguration = (c : GlobalConfig, cc : ConfigContract) => 
    database.collection('configuration').doc('all').set(c, GlobalConfig)
    .then( () => database.collection('configuration').doc('contract').set(cc, ConfigContract) )

export const getContract = () => 
    database.collection('configuration').doc('contract').as(ConfigContract)


export const getZonesConfig = () : Promise<(WithId & ZoneConfig)[]> => 
    database.collection('configuration').doc('all').collection('zone_configuration').as(ZoneConfig)

export const getZoneConfig = (id:string) : Promise<ZoneConfig> => 
    database.collection('configuration').doc('all').collection('zone_configuration').doc(id).as(ZoneConfig)

export const setZoneConfig = (cz : ZoneConfig, id:string) : Promise<void> => 
    database.collection('configuration').doc('all').collection('zone_configuration').doc(id).set(cz,ZoneConfig)

export const addZoneConfig = (cz : ZoneConfig) => 
    database.collection('configuration').doc('all').collection('zone_configuration').add(cz,ZoneConfig)

export const deleteZoneConfig = (id: string) => 
    database.collection('configuration').doc('all').collection('zone_configuration').doc(id).delete()


export const getProvinceConfig = (id:string) => 
    database.collection('configuration').doc('all').collection('province_configuration').doc(id).as(CustomConfig)

export const getProvincesConfig = () => 
    database.collection('configuration').doc('all').collection('province_configuration').as(CustomConfig)

export const setProvinceConfig = (c : CustomConfig, id:string) : Promise<void> => 
    database.collection('configuration').doc('all').collection('province_configuration').doc(id).set(c,CustomConfig)

export const addProvinceConfig = (c : CustomConfig) => 
    database.collection('configuration').doc('all').collection('province_configuration').add(c,CustomConfig)


export const getLocalityConfig = (id:string) => 
    database.collection('configuration').doc('all').collection('locality_configuration').doc(id).as(CustomConfig)

export const getLocalitiesConfig = () => 
    database.collection('configuration').doc('all').collection('locality_configuration').as(CustomConfig)

export const setLocalityConfig = (c : CustomConfig, id:string) : Promise<void> => 
    database.collection('configuration').doc('all').collection('locality_configuration').doc(id).set(c,CustomConfig)

export const addLocalityConfig = (c : CustomConfig) => 
    database.collection('configuration').doc('all').collection('locality_configuration').add(c,CustomConfig)
