import { reduxForm, Field } from "redux-form";
import { ReduxDecorator } from "components/bootstrap-redux";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { Container, Center, Button, AsyncButton } from "components/styles";

import React, { useState } from "react";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";

const Formulario = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [reason, setReason] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Validación de la confirmación

    if (!confirmation) {
      setError("Por favor, confirma que deseas cancelar tu suscripción.");
      setIsSubmitting(false);
      return;
    }
    const d = { name, email, reason };
    console.log(d);

    await CALLABLE_FUNCTIONS.USER_UNSUSCRIBE(d)
      .then(() => setSuccess("Tu solicitud a sido enviada."))
      .then(() => setIsSubmitting(false))
      .then(() => setError(""));
  };
  return (
    <Container>
      {/* Mostrar el mensaje de error si no se marca la confirmación */}
      {error && <Alert variant="danger">{error}</Alert>}

      {/* Mostrar el mensaje de éxito después de la cancelación */}
      {success && <Alert variant="success">{success}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formEmail">
          <Form.Label>Correo electrónico</Form.Label>
          <Form.Control
            type="email"
            placeholder="Introduce tu correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="name">
          <Form.Label>Nombre y Apellido</Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Tu nombre y apellido"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formReason">
          <Form.Label>Razón para cancelar</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="¿Por qué deseas cancelar tu suscripción?"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formConfirmation">
          <Form.Check
            type="checkbox"
            label="Confirmo que quiero eliminar mi cuenta"
            checked={confirmation}
            onChange={() => setConfirmation(!confirmation)}
          />
        </Form.Group>
        <div>
          <Button
            variant="danger"
            type="submit"
            disabled={isSubmitting}
            className="w-100"
            style={{ width: 300 }}
          >
            {isSubmitting ? "Enviando..." : "Cancelar suscripción"}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default Formulario;
