import React, { useState, useEffect } from "react";
import { FIRESTORE } from "constants/firestore";
import {
  Spinner,
  NoData,
  H2,
  Padding,
  Button,
  AsyncButton,
} from "components/styles";
import styled from "styled-components";
import { Col, Row, Table as BTable } from "react-bootstrap";
import { EditLocalityModal, AddNewLocalyModal } from "./modalsLocality";
import { usePaginator } from "util/hooks";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import MiniNavigationErrors from "./MiniNavigation";
import { useHistory } from "react-router";
import queryString from "query-string";

const AdminLocalities = () => {
  return (
    <MiniNavigationErrors active={2}>
      <MainContainer />
    </MiniNavigationErrors>
  );
};
export const MainContainer = () => {
  const history = useHistory();
  const parsed = queryString.parse(window.location.search);
  const [initialValues, setInitialValues] = useState({
    locality_id: "",
    locality: "",
    province: "",
    province_id: "",
  });
  const [documentId, setDocumentId] = useState("");
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [searchKeyWord, setSearchKeyWord] = useState<string>("");
  const [searchId, setSearchId] = useState<string>("");
  const [localities, loading, Pagination, setLocalities] = usePaginator(
    FIRESTORE.COLLECTION_LOCALITIES_SATAPP().orderBy("locality", "asc"),
    100
  );

  const editLocality = (item: any) => {
    setInitialValues({
      locality_id: item["locality_id"],
      locality: item.locality,
      province: item.province,
      province_id: item["province_id"],
    });
    console.log(item);
    setDocumentId(item.id);
    setShowEditModal(true);
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <Padding pad="5px">
      <Row>
        <Col md={4}>
          <H2>Localidades</H2>
        </Col>
        <Col md={6}></Col>
        <Col md={2}>
          <Button variant="success" onClick={() => setShowAddModal(true)}>
            Agregar Localidad
          </Button>
        </Col>
      </Row>
      {localities !== undefined && localities.length == 0 ? (
        <NoData />
      ) : (
        <Table hover responsive>
          <thead>
            <tr>
              <th>ID Localidad</th>
              <th>Localidad</th>
              <th>Provincia</th>
              <th>Editar</th>
              <th>Borrar</th>
            </tr>
          </thead>
          <tbody>
            {localities !== undefined &&
              localities.map(
                (item) =>
                  item !== undefined && (
                    <tr key={item["locality_id"]}>
                      <Td>{item["locality_id"]}</Td>
                      <Td>{item.locality}</Td>
                      <Td>{item.province}</Td>
                      <Td>
                        <Button onClick={() => editLocality(item)}>
                          Editar
                        </Button>
                      </Td>
                      <Td>
                        <AsyncButton
                          onClick={async () => {
                            await CALLABLE_FUNCTIONS.ADMIN_DELETE_LOCALITY_DATABASE(
                              { id: item.id }
                            );
                            setLocalities(
                              FIRESTORE.COLLECTION_LOCALITIES_SATAPP().orderBy(
                                "locality",
                                "asc"
                              )
                            );
                          }}
                          variant="danger"
                        >
                          Borrar
                        </AsyncButton>
                      </Td>
                    </tr>
                  )
              )}
          </tbody>
        </Table>
      )}
      <EditLocalityModal
        documentId={documentId}
        show={showEditModal}
        reset={() =>
          setLocalities(
            FIRESTORE.COLLECTION_LOCALITIES_SATAPP().orderBy("locality", "asc")
          )
        }
        handleClose={() => {
          setShowEditModal(false);
        }}
        amounts={initialValues}
      />
      <AddNewLocalyModal
        show={showAddModal}
        reset={() =>
          setLocalities(
            FIRESTORE.COLLECTION_LOCALITIES_SATAPP().orderBy("locality", "asc")
          )
        }
        handleClose={() => setShowAddModal(false)}
      />
      <Pagination />
    </Padding>
  );
};
const Tr = styled.tr`
  th {
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  height: 10px;
`;

const Table = styled(BTable)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default AdminLocalities;
