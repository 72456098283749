import React from 'react'
import googlePlayImg from './assets/google_play.png'
import mobileImg from './assets/mobile.png'
import ROUTES from 'constants/routes'
import * as B from 'react-bootstrap'
import styled from 'styled-components'

const Application = () => (
    <Container className="container-fluid pt-5 pb-3 app">  
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-sm-6 app-bb">
                    <img alt="app" height="350" src={mobileImg} />
                </div>
                <div className="col-md-8 col-sm-6">        
                    <div className="get-mobile">          
                        <h2>Cómo me registro</h2>
                        <h3>Convertirte en conductor es muy simple.</h3>
                        <p>Completá el formulario y elegí la manera en la que quieras ser parte de Satapp.<br />
                            Nuestro equipo validará tus datos y te enviará la habilitación en 48 horas hábiles. <br /> Es así de simple. Descargá nuestra app y empezá a ganar tu dinero, libre de comisión.</p>
                        <div className="get-mobile-playstore">
                            <a href={ROUTES.PLAYSTORE} target="_blank">
                            <img alt="google-play" src={googlePlayImg}/>
                            </a>
                            {/* <!-- <a href="#"><img src="img/app-store.png" alt="app-store.png"/></a> --> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Container>
)

const Container = styled(B.Container)`
    h2 {
        color: #00426d;
        text-transform: uppercase;
        font-weight: 600 !important;
    }
    h3 {
        color: #73ccdd;
        text-transform: uppercase;
        font-weight: 600 !important
    }
`


export default Application