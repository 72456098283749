import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePickerInput } from "components/input/date-picker";
import FbFile from "components/Primitives/File";
import {
  AdminTable,
  AsyncButton,
  ContainerPage,
  H2,
  H3,
  Link,
  NoData,
  QuestionTooltip,
  Spinner,
  TextWithTooltip,
} from "components/styles";
import ROUTES from "constants/routes";
import { WithId, WithUid } from "controllers/database";
import {
  getActiveUsers,
  getUser,
  getInRevisionUsers,
  getFailedUsers,
} from "controllers/user";
import { useDbDocument } from "controllers/hooks";
import {
  getPersonalInformationColDoc,
  getUsers,
} from "controllers/user/info/personal";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import Moment from "react-moment";
import { BillStatus, TIPOS_COMPROBANTES } from "types/billing";
import {
  FbDate,
  FinishStatus,
  ScheduledTransfer,
  TransferStageDefinitions,
} from "types/transfer";
import { User, PersonalInformation } from "types/user-data";
import { usePaginatorWithUid, useQueryParam } from "util/hooks";
import { getInvoices, getInvoicesForBas, useSaveSelection } from "../reducer";
import { GetProperty, UserName } from "../Sales/view-bill";
import queryString from "query-string";
import { useHistory } from "react-router";
import { FIRESTORE } from "constants/firestore";
import ExcelJS from "exceljs";
import * as fs from "file-saver";
import { getUid } from "controllers/auth";
import firebase from "firebase";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { ExcelModal } from "./excel-modal";
import { List } from "antd";
import { BillModal } from "components/Admin/Transfers/view/scheduled/modals/bill";
import { InfoRow } from "components/Admin/Transfers/view/scheduled";
import { AnyARecord } from "dns";

const Purchases = () => {
  const parsed = queryString.parse(window.location.search);
  const history = useHistory();
  const [type, setType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState<string | undefined>(undefined);
  const [driver, setDriver] = useState<string | undefined>(undefined);
  const [showExcelModal, setShowExcelModal] = useState(false);
  const [items2, setItems2] = useState<any[]>([]);
  const [items3, setItems3] = useState<any[]>([]);
  const [contraer, setContraer] = useState<boolean>(true);
  const [show, setShow] = useSaveSelection("driver-payments-show");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [transferRefForModal, setTransferRefForModal] = useState("");
  const [userIDforModal, setUserIDforModal] = useState("");
  const [showInfoComprasModal, setShowInfoComprasModal] =
    useState<boolean>(false);
  const [gestorando, setGestorando] = useState<boolean>(false);
  const fecha = new Date(
    Date.UTC(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
  );
  let diaDeLaSemana = fecha.getUTCDay();
  let lunesAnterior = 7 + diaDeLaSemana - 1;
  const [startDate, setStartDate] = useQueryParam(
    moment().subtract(lunesAnterior, "days").toDate(),
    "startDate",
    (v) => new Date(v),
    (v) => v.toISOString()
  );
  const [endDate, setEndDate] = useQueryParam(
    moment().subtract(diaDeLaSemana, "day").toDate(),
    "endDate",
    (v) => new Date(v),
    (v) => v.toISOString()
  );
  const [showBillModal, setShowBillModal] = useState(false);
  const [cabify, loadingCabify, Pagination, setCabify] = usePaginatorWithUid(
    getInvoices(5, startDate, endDate, undefined, undefined)
  );

  let choferes: any[] = [];
  let items: any = [];
  // useEffect(() => {
  //   if (type === 4 || type === 5)
  //     setCabify(getInvoices(type, startDate, endDate, driver, keyword));
  // }, [startDate, endDate, type]);
  const mostrar = async () => {
    setLoading(true);
    setContraer(true);
    if (type !== 5 && type !== 4) {
      console.log(type, "soy el tipo");
      let dataT = (
        await getInvoices(type, startDate, endDate, driver, keyword).get()
      ).docs.map((t) => ({ data: t.data(), ref: t.ref }));
      console.log(dataT.length, "cantidad");
      console.log(dataT);

      if (type === 7)
        dataT = dataT.filter(
          (d) => d.data.sale?.billed_from_driver == undefined
        );
      if (type !== 8 && type !== 9 && type !== 10 && type !== 11)
        dataT = dataT.filter(
          (d) => !d.data.challenge || d.data.challenge.to_be_billed === 2
        );
      console.log(dataT, "dataT");

      dataT = dataT.filter((d) => d.data.status?.finished_status !== 1);

      choferes = [];
      items = [];
      await Promise.all(
        dataT.map((b) => {
          if (!choferes.includes(b.ref.parent.parent?.id)) {
            choferes.push(b.ref.parent.parent?.id);
          }
        })
      );
      console.log(choferes, "choferes 0");
      for (let i = 0; i < choferes.length; i++) {
        const chofer = choferes[i];
        if (chofer === "zdrEGB4N9dQuGBItxAqWlmTcVtn2") continue;
        const data = (await FIRESTORE.DOCUMENT_USER(chofer).get()).data();
        let nombre = `${data?.surname} ${data?.name}`;
        nombre = nombre.toUpperCase();
        const choferTransfers = dataT.filter(
          (f) => f.ref.parent.parent?.id === chofer
        );
        const gestorando = data?.delegationGestorando === true ? true : false;
        items.push({
          name: nombre,
          user: chofer,
          transfers: choferTransfers,
          gestorando: gestorando,
        });
      }
      setItems2(items);
      console.log(items);
      console.log(choferes, "choferes");
    } else if (type === 4 || type === 5) {
      setCabify(getInvoices(type, startDate, endDate, driver, keyword));
    }

    setLoading(false);
  };
  useEffect(() => {
    setType(parsed.type ? Number(parsed.type) : 0);
  }, [parsed.type]);
  /* useEffect(() => {
    mostrar();
  }, [driver, startDate, endDate, type]); */
  const search = () => {
    mostrar();
  };
  useEffect(() => {
    let e: any[] = [];
    items2.map((i) => {
      e.push(i);
    });
    setItems3(e);
  }, [items2]);
  const facturarConjunto = async (data: any[]) => {
    console.log();
    let tramos: { idTransfer: string; uidDriver: string }[] = [];
    const prestador = data[0].ref.parent.parent.id;
    data.map((d) =>
      tramos.push({ idTransfer: d.ref.id, uidDriver: prestador })
    );
    const dataUser = (await FIRESTORE.DOCUMENT_USER(prestador).get()).data();
    console.log(data, tramos, "tramos");
    console.log(dataUser, "dataUser");
    if (dataUser?.delegationGestorando === true) {
      console.log("a gestorando");
      await CALLABLE_FUNCTIONS.ADMIN_FORCE_MULTIPLE_DRIVER_BILLS({
        transfers: tramos,
        allInOneBill: true,
        forced: true,
        gestorando: true,
      });
    } else {
      console.log("como siempre");
      console.log(tramos, "tramos");

      await CALLABLE_FUNCTIONS.ADMIN_FORCE_MULTIPLE_DRIVER_BILLS({
        transfers: tramos,
        allInOneBill: true,
        forced: false,
        gestorando: false,
      });
    }
  };
  const billAllDriver = async () => {
    await CALLABLE_FUNCTIONS.FACTURAR_CONJUNTO({
      desde: startDate,
      hasta: endDate,
    });
  };
  const optionsFilter = [
    "Pendientes de facturar",
    "Facturados",
    "Error al facturar",
    "Missing PDF",
    "Traslado Cabify sin Factura",
    "Cabify",
    "NO FACTURAR",
    "Sin billed_from_driver",
    "TODOS LOS TRASLADOS",
    "Desafíos pendientes de facturar",
    "Desafíos facturados",
    "Desafíos en espera",
    "Facturando",
  ];
  console.log(show, "1");
  console.log(items3, "2");

  return (
    <>
      <ContainerPage>
        <Row style={{ marginBottom: 15 }}>
          <Col>
            <H2>Facturas Conductores</H2>
          </Col>

          <Col>
            <Link to={ROUTES.ADMIN_SALES_PURCHASES_GENERATE_BILL}>
              <Button className="pull-right">Generar/Ingresar Factura</Button>
            </Link>
          </Col>
          <Col>
            <Button
              className="pull-right"
              onClick={async () => {
                generateExcel(
                  (
                    await getInvoices(
                      type,
                      startDate,
                      endDate,
                      driver,
                      keyword
                    ).get()
                  ).docs.map((v) => ({ data: v.data(), ref: v.ref }))
                );
              }}
            >
              Descargar Excel
            </Button>
          </Col>
          <Col>
            <Button
              className="pull-right"
              onClick={async () => {
                setShowInfoComprasModal(true);
                //generateExcelFacturas(startDate, endDate);
              }}
            >
              Enviar info compras
            </Button>
          </Col>
          <Col>
            <Button
              className="pull-right"
              onClick={() => {
                setShowExcelModal(true);
              }}
            >
              Cargar Feleval
            </Button>
          </Col>
        </Row>
        <Row>
          <ExcelModal
            show={showExcelModal}
            handleClose={() => setShowExcelModal(false)}
          />

          <Col xl={4}>
            <Form.Group>
              <Form.Label>Tipo</Form.Label>
              <Form.Control
                as="select"
                value={type.toString()}
                onChange={(event) =>
                  history.push(
                    `?type=${(event.target as HTMLTextAreaElement).value}`
                  )
                }
              >
                {optionsFilter.map((op, index) => (
                  <option key={index} value={index}>
                    {op}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col xl={2}>
            <Form.Group>
              <Form.Label>Desde</Form.Label>
              <DatePickerInput value={startDate} onChange={setStartDate} />
            </Form.Group>
          </Col>
          <Col xl={2}>
            <Form.Group>
              <Form.Label>Hasta (Inclusive)</Form.Label>
              <DatePickerInput value={endDate} onChange={setEndDate} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Label style={{ marginLeft: 2 }}>Prestador</Form.Label>
            <SelectDriverInput onChange={setDriver} value={driver} />
          </Col>
          <Col>
            <Button style={{ marginTop: 30 }} onClick={search}>
              BUSCAR
            </Button>
          </Col>
          {/* <Col>
            {type === 0 ? (
              <AsyncButton
                onClick={billAllDriver}
                variant="success"
                style={{ marginTop: 30 }}
              >
                Facturar todo lo pendiente
              </AsyncButton>
            ) : (
              ""
            )}
          </Col> */}
        </Row>
        {loading ? (
          <Spinner style={{ margin: 20 }} />
        ) : items3.length === 0 ? (
          <NoData />
        ) : type !== 5 && type !== 4 ? (
          <AdminTable responsive>
            <thead>
              <tr>
                <th>Prestador</th>
                <th>Cantidad de traslados</th>
                <th>Ver Traslados</th>
                <th>Facturar Traslados</th>
              </tr>
            </thead>
            <tbody>
              {items3.map(
                (item: {
                  name: string;
                  user: string;
                  transfers: any[];
                  gestorando: boolean;
                }) => (
                  <tr key={item.name}>
                    <td>{item.name}</td>
                    <td>{item.transfers.length}</td>
                    <td>
                      <Button
                        onClick={async () => {
                          const z = new Set();
                          item.transfers.map((fc: any) => z.add(fc));
                          setShow(z);
                          setSelectedDriver(item.name);
                          setContraer(false);
                          setGestorando(
                            item.gestorando === true ? true : false
                          );
                        }}
                      >
                        Ver traslados
                      </Button>
                    </td>
                    <td>
                      {type === 0 || type === 2 || type === 9 || type === 11 ? (
                        <AsyncButton
                          onClick={() => facturarConjunto(item.transfers)}
                          variant="warning"
                        >
                          Facturar conjunto
                        </AsyncButton>
                      ) : type === 12 ? (
                        "En proceso"
                      ) : (
                        "Facturado"
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </AdminTable>
        ) : (
          <>
            <AdminTable responsive>
              <thead>
                <tr>
                  <th>Prestador</th>
                  <th>Siniestro-Aut/NPA</th>
                  <th>Fecha Traslado</th>
                  <th>Fecha Facturado</th>
                  <th>Peajes</th>
                  <th>Voucher</th>
                  <th>Número de factura</th>
                  <th>
                    <span>Factura </span>
                    <TextWithTooltip textTooltip="Puede tardar hasta 24hs desde que terminó el traslado. También tienen que haber sido revisados todos sus peajes.">
                      <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
                    </TextWithTooltip>
                  </th>
                  <th>Ver Traslado</th>
                </tr>
              </thead>
              <tbody>
                {cabify.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <GetProperty
                        doc={FIRESTORE.DOCUMENT_USER(item.uid)}
                        property="name"
                      />{" "}
                      <GetProperty
                        doc={FIRESTORE.DOCUMENT_USER(item.uid)}
                        property="surname"
                      />
                    </td>
                    <td>{showID(item)}</td>
                    <td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY"
                      />
                    </td>
                    <td>
                      <Moment
                        date={item.sale?.bill_info?.creation_date?.toDate()}
                        format="DD/MM/YYYY"
                      />
                    </td>
                    <td>
                      {item.tolls &&
                        item.tolls.data.map((v, index) => (
                          <>
                            <FbFile key={v.photo} src={v.photo}>
                              Foto {index > 0 && index + 1}
                            </FbFile>
                            {index < item.tolls!.data.length - 1 && <br />}
                          </>
                        ))}
                    </td>
                    <td>
                      {item.sale &&
                        item.sale.voucher?.map((v, index) => (
                          <>
                            <FbFile key={v} src={v}>
                              PDF {index > 0 && index + 1}
                            </FbFile>
                            {index < item.sale!.voucher!.length - 1 && <br />}
                          </>
                        ))}
                    </td>
                    <td>
                      {item.sale?.bill_info?.ref && (
                        <>
                          <GetProperty
                            doc={item.sale.bill_info.ref}
                            property="point_of_sale"
                          />
                          -
                          <GetProperty
                            doc={item.sale.bill_info.ref}
                            property="voucher_number"
                          />
                        </>
                      )}
                    </td>
                    <td>
                      {item.sale !== undefined &&
                      item.sale.billed_from_driver !== undefined ? (
                        item.sale.billed_from_driver === BillStatus.No ? (
                          "Falta que se le facture al Conductor"
                        ) : item.sale.billed_from_driver === BillStatus.Si ? (
                          <Link
                            to={ROUTES.ADMIN_SALES_SALES_DRIVER_BILL_INFO(
                              item.sale.bill_info?.ref?.id
                            )}
                          >
                            <Button>Ver factura</Button>
                          </Link>
                        ) : item.sale.billed_from_driver ===
                          BillStatus["No facturar"] ? (
                          "No Facturar"
                        ) : null
                      ) : null}
                    </td>
                    <td>
                      <Link
                        to={ROUTES.ADMIN_MODULE_TRANSFER_VIEW(item.module.id)}
                      >
                        <Button variant="light">Ver Traslado</Button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </AdminTable>
            <Pagination />
          </>
        )}

        {contraer ? (
          <>
            <br />
            <Button onClick={() => setContraer(false)}>Mostrar</Button>
          </>
        ) : !contraer ? (
          <>
            <br />
            <Button onClick={() => setContraer(true)}>Ocultar</Button>
          </>
        ) : (
          ""
        )}
        {!contraer ? (
          <>
            <H3>Traslados de {selectedDriver}</H3>
            <AdminTable responsive>
              <thead>
                <tr>
                  <th>Siniestro-Aut/NPA</th>
                  <th>Fecha Traslado</th>
                  <th>Peajes</th>
                  <th>Voucher</th>
                  <th>Número de factura</th>
                  <th>
                    <span>Factura </span>
                    <TextWithTooltip textTooltip="Puede tardar hasta 24hs desde que terminó el traslado. También tienen que haber sido revisados todos sus peajes.">
                      <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
                    </TextWithTooltip>
                  </th>
                  <th>Ver Traslado</th>
                </tr>
              </thead>
              <tbody>
                {[...show].map((item: any) => (
                  <tr key={item.ref.id}>
                    <td>{showID(item.data)}</td>
                    <td>
                      <Moment
                        date={item.data.origin.time.toDate()}
                        format="DD/MM/YYYY"
                      />
                    </td>
                    <td>
                      {item.data.tolls &&
                        item.data.tolls.data.map((v: any, index: any) => (
                          <>
                            <FbFile key={v.photo} src={v.photo}>
                              Foto {index > 0 && index + 1}
                            </FbFile>
                            {index < item.data.tolls!.data.length - 1 && <br />}
                          </>
                        ))}
                    </td>
                    <td>
                      {item.data.sale &&
                        item.data.sale.voucher?.map((v: any, index: any) => (
                          <>
                            <FbFile key={v} src={v}>
                              PDF {index > 0 && index + 1}
                            </FbFile>
                            {index < item.data.sale!.voucher!.length - 1 && (
                              <br />
                            )}
                          </>
                        ))}
                    </td>
                    <td>
                      {item.data.sale?.bill_info?.ref && (
                        <>
                          <GetProperty
                            doc={item.data.sale.bill_info.ref}
                            property="point_of_sale"
                          />
                          -
                          <GetProperty
                            doc={item.data.sale.bill_info.ref}
                            property="voucher_number"
                          />
                        </>
                      )}
                    </td>
                    <td>
                      {item.data.sale !== undefined &&
                      item.data.sale.billed_from_driver !== undefined ? (
                        item.data.sale.billed_from_driver === BillStatus.No &&
                        item.data.sale.bill_info !== undefined ? (
                          <AsyncButton
                            onClick={async () => {
                              console.log(
                                item.ref.parent.parent.id,
                                item.ref.id
                              );

                              await FIRESTORE.DOCUMENT_SCHEDULED_TRANSFER(
                                item.ref.parent.parent.id,
                                item.ref.id
                              ).update({
                                "sale.billed_from_driver": 1,
                              });
                            }}
                          >
                            Marcar como facturado
                          </AsyncButton>
                        ) : item.data.sale.billed_from_driver ===
                            BillStatus.No && gestorando !== true ? (
                          <Button
                            onClick={() => {
                              setUserIDforModal(item.ref.parent.parent.id);
                              setTransferRefForModal(item.ref.id);
                              setShowBillModal(true);
                            }}
                          >
                            Forzar factura compra
                          </Button>
                        ) : item.data.sale.billed_from_driver ===
                          BillStatus.Si ? (
                          <Link
                            to={ROUTES.ADMIN_SALES_SALES_DRIVER_BILL_INFO(
                              item.data.sale.bill_info?.ref?.id
                            )}
                          >
                            <Button>Ver factura</Button>
                          </Link>
                        ) : gestorando === true ? (
                          "Facturar Conjunto"
                        ) : item.data.sale.billed_from_driver ===
                          BillStatus["No facturar"] ? (
                          "No Facturar"
                        ) : null
                      ) : null}
                    </td>
                    <td>
                      <Link
                        to={ROUTES.ADMIN_MODULE_TRANSFER_VIEW(
                          item.data.module.id
                        )}
                      >
                        <Button variant="light">Ver Traslado</Button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </AdminTable>
          </>
        ) : (
          ""
        )}
        {!contraer ? (
          <>
            <br />
            <Button onClick={() => setContraer(true)}>Ocultar</Button>
          </>
        ) : (
          ""
        )}
      </ContainerPage>
      <BillModal
        idTransfer={transferRefForModal}
        uidDriver={userIDforModal}
        show={showBillModal}
        handleClose={() => setShowBillModal(false)}
      />
      <InfoComprasModal
        show={showInfoComprasModal}
        driver={driver}
        startDate={startDate}
        endDate={endDate}
        type={type}
        handleClose={() => setShowInfoComprasModal(false)}
      />
    </>
  );
};
const InfoComprasModal = ({
  show,
  driver,
  startDate,
  endDate,
  type,
  handleClose,
}: {
  show: any;
  driver?: string;
  startDate: Date;
  endDate: Date;
  type?: number;
  handleClose: any;
}) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [mails, setMails] = useState<string[]>([]);
  const [addMail, setAddMail] = useState<string | undefined>(undefined);
  const enviarInfoCompras = async () => {
    setLoading(true);
    try {
      await generateExcelFacturas(startDate, endDate, mails);
      /* await generateExcelFacturasNuevo({
        driver,
        startDate: startDate.toJSON(),
        endDate: endDate.toJSON(),
        type,
        mailsSelected: mails,
      }); */
      setSuccess(true);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };
  const closeAndReset = () => {
    setSuccess(false);
    setError(undefined);
    setAddMail("");
    setMails([]);
    handleClose();
  };
  return (
    <Modal show={show} onHide={closeAndReset} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Esto enviará información de <b>TODOS LOS TRASLADOS</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <Alert variant="success">Realizado con éxito</Alert>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : loading ? (
          <Spinner />
        ) : (
          <>
            <InfoRow
              label="Mails"
              info={
                <Button
                  onClick={() => {
                    const z = mails;
                    if (addMail) {
                      z.push(addMail);
                      setMails(z);
                    }
                    setAddMail("");
                  }}
                >
                  Añadir
                </Button>
              }
            />
            <input
              value={addMail}
              onChange={(e) => setAddMail(e.target.value)}
              placeholder="Agregar Mail destino"
            ></input>
            <br />
            <br />
            {mails.length > 0 ? <h4>Mails confirmados</h4> : ""}
            {mails &&
              mails.map((mail) => (
                <>
                  <h6>{mail}</h6>
                </>
              ))}
          </>
        )}
      </Modal.Body>
      {!success && (
        <Modal.Footer>
          <Button onClick={enviarInfoCompras}>
            ENVIAR A LOS MAILS SELECCIONADOS
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
const generateExcelExperta = async () => {
  let workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet("Tutorials");
  worksheet.columns = [
    { header: "SML ID", key: "sml", width: 10 },
    { header: "Siniestro", key: "sin", width: 10 },
    { header: "Autorización", key: "auth", width: 10 },
    { header: "Fecha", key: "fecha", width: 10 },
    { header: "Paciente", key: "paciente", width: 10 },
    { header: "Estado", key: "status_fin", width: 10 },
    { header: "Tipo", key: "type", width: 10 },
    { header: "Ida OK?", key: "ida", width: 10 },
    { header: "Traslado Asociado", key: "negative", width: 10 },
  ];
  let fname = `Excel-Experta-${moment(new Date()).format("DD/MM/YYYY HH:mm")}`;

  const modules = await firebase
    .firestore()
    .collection("module_transfer")
    .where("art.name", "==", "Experta SML")
    .where("origin.time", "<", FbDate.fromDate(new Date()))
    .get();

  await Promise.all(
    modules.docs.map(async (module) => {
      const moduleData = module.data();

      let fin;
      let type;
      if (moduleData.status.finished_status === 0) {
        fin = "Exitoso";
      } else if (moduleData.status.finished_status === 1) {
        fin = "Cancelado";
      } else if (
        moduleData.status.finished_status === 2 &&
        moduleData.status.doing_return
      ) {
        fin = "Retorno Negativo";
      } else if (moduleData.status.finished_status === 2) {
        fin = "Negativo";
      } else {
        if (moduleData.status.stage == 4) {
          fin = "Buscando conductor Retorno";
        } else {
          fin = "No terminado";
        }
      }
      if (moduleData.type === 0) {
        type = "Tramo";
      } else if (moduleData.type === 1) {
        type = "Modulo con Espera";
      } else if (moduleData.type === 2) {
        type = "Modulo";
      }
      worksheet.addRow({
        sin: moduleData.art.accident,
        auth: moduleData.art.authorization,
        sml: moduleData.transfer_id ? moduleData.transfer_id : "-",
        fecha: moduleData.origin.time
          ? moment(moduleData!.destination.time.toDate()).format("DD/MM/YYYY")
          : "no hay fecha de origen",
        status_fin: fin,
        negative: moduleData.negative ? moduleData.negative.id_module : "-",
        paciente: moduleData.passenger.name,
        type: type,
        ida:
          moduleData.status.doing_return == true && moduleData.type > 0
            ? "Exitosa"
            : fin != "Cancelado"
            ? "Exitosa"
            : "Cancelada",
      });
    })
  );

  const buffer = await workbook.xlsx.writeBuffer();

  console.log(buffer.toString());

  await workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, fname + ".xlsx");
  });
};

const generateExcelFacturas = async (
  startDate: Date,
  endDate: Date,
  mailsSelected: string[]
) => {
  startDate.toJSON();
  endDate.toJSON();
  await CALLABLE_FUNCTIONS.GENERATE_EXCEL(
    startDate.setHours(0, 0, 0, 0),
    endDate.setHours(23, 59, 59, 0),
    mailsSelected
  );
};
const generateExcelFacturasNuevo = async (queryParams: {
  driver?: string;
  startDate?: any;
  endDate?: any;
  type?: number;
  mailsSelected: string[];
}) => {
  await CALLABLE_FUNCTIONS.GENERATE_EXCEL_NEW(queryParams);
};

const generateExcel = async (arr: { data: ScheduledTransfer; ref: any }[]) => {
  let workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet("Tutorials");
  worksheet.columns = [
    { header: "Prestador", key: "driver", width: 30 },
    { header: "Siniestro-Cod.Aut.", key: "sin", width: 18 },
    { header: "Fecha", key: "fecha", width: 20 },
    { header: "Paciente", key: "paciente", width: 30 },
    { header: "Domicilio Origen", key: "domicilio_or", width: 25 },
    { header: "Localidad Origen", key: "localidad_or", width: 25 },
    { header: "Provincia Origen", key: "provincia_or", width: 25 },
    { header: "Domicilio Destino", key: "domicilio_de", width: 25 },
    { header: "Localidad Destino", key: "localidad_de", width: 25 },
    { header: "Provincia Destino", key: "provincia_de", width: 25 },
    { header: "Retorno", key: "return", width: 25 },
    { header: "Negativo", key: "negativo", width: 25 },
    { header: "Tiempo de Espera", key: "wait_time", width: 25 },
    { header: "Valor Espera", key: "extra_wait_time", width: 25 },
    { header: "Valor Viaje", key: "base", width: 25 },
    { header: "Peajes", key: "tolls", width: 25 },
    { header: "Total", key: "total", width: 25 },
  ];
  let fname = `Resumen_Compras-${moment(new Date()).format(
    "DD/MM/YYYY HH:mm"
  )}`;

  await Promise.all(
    arr.map(async (it) => {
      const mod = it.data;
      const suma = () => {
        let tolls = mod.tolls
          ? mod.tolls.data.reduce((acc, toll) => {
              let t = 0;
              if (toll.amount !== undefined) t = toll.amount;
              return t + acc;
            }, 0)
          : 0;
        let wait = mod.info.amount?.extra_wait
          ? mod.info.amount?.price_minute_wait
            ? mod.info.amount?.extra_wait! * mod.info.amount?.price_minute_wait!
            : mod.info.amount?.extra_wait! * 3.58
          : 0;
        let base = mod.info.amount?.base_value
          ? mod.info.amount?.base_value
          : Number(mod.amount);
        return tolls + wait + base;
      };

      let name = "";
      const data = (
        await FIRESTORE.DOCUMENT_USER(it.ref.parent.parent.id).get()
      ).data();
      name = `${data?.surname} ${data?.name}`;
      name = name.toUpperCase();
      if (mod?.status.finished_status == 2) {
        worksheet.addRow({
          driver: name,
          sin: mod.art.accident + "-" + mod.art.authorization,
          fecha: mod.origin.time
            ? mod.origin.time.toDate().toISOString()
            : "no hay fecha de origen",
          paciente: mod.passenger.name,
          domicilio_or: `${mod.origin.address.street} ${mod.origin.address.number}`,
          localidad_or: mod.origin.address.district,
          provincia_or: mod?.origin.address.province,
          domicilio_de: `${mod.destination.address.street} ${mod?.destination.address.number}`,
          localidad_de: mod?.destination.address.district,
          provincia_de: mod?.destination.address.province,
          return: mod?.type == 0 ? "No" : "Si",
          negativo: mod?.status.finished_status == 2 ? "Si" : "No",
          wait_time: mod.info.amount?.extra_wait
            ? mod.info.amount.extra_wait
            : 0,
          extra_wait_time: mod.info.amount?.extra_wait
            ? mod.info.amount?.price_minute_wait
              ? mod.info.amount?.extra_wait! *
                mod.info.amount?.price_minute_wait!
              : mod.info.amount?.extra_wait! * 3.58
            : 0,
          base: mod.info.amount?.base_value
            ? mod.info.amount?.base_value
            : Number(mod.amount),
          tolls: mod.tolls
            ? mod.tolls.data.reduce((acc, toll) => {
                let t = 0;
                if (toll.amount !== undefined) t = toll.amount;
                return t + acc;
              }, 0)
            : 0,
          total: suma(),
        });
      } else {
        worksheet.addRow({
          driver: name,
          sin: mod.art.accident + "-" + mod.art.authorization,
          fecha: mod.origin.time
            ? mod.origin.time.toDate().toISOString()
            : "no hay fecha de origen",
          paciente: mod.passenger.name,
          domicilio_or: `${mod.origin.address.street} ${mod.origin.address.number}`,
          localidad_or: mod.origin.address.district,
          provincia_or: mod?.origin.address.province,
          domicilio_de: `${mod.destination.address.street} ${mod?.destination.address.number}`,
          localidad_de: mod?.destination.address.district,
          provincia_de: mod?.destination.address.province,
          return: mod?.type == 0 ? "No" : "Si",
          negativo: "NO",
          wait_time: mod.info.amount?.extra_wait
            ? mod.info.amount.extra_wait
            : 0,
          extra_wait_time: mod.info.amount?.extra_wait
            ? mod.info.amount?.price_minute_wait
              ? mod.info.amount?.extra_wait! *
                mod.info.amount?.price_minute_wait!
              : mod.info.amount?.extra_wait! * 3.58
            : 0,
          base: mod.info.amount?.base_value
            ? mod.info.amount?.base_value
            : Number(mod.amount),
          tolls: mod.tolls
            ? mod.tolls.data.reduce((acc, toll) => {
                let t = 0;
                if (toll.amount !== undefined) t = toll.amount;
                return t + acc;
              }, 0)
            : 0,
          total: suma(),
        });
      }
    })
  );
  const buffer = await workbook.xlsx.writeBuffer();

  console.log(buffer.toString());

  await workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, fname + ".xlsx");
  });
};

const generateTxt = async (items: any[], starDate: any, endDate: any) => {
  let fechaInicio: any = FbDate.fromDate(starDate);
  let fechaFin: any = FbDate.fromDate(endDate);
  fechaInicio = moment(fechaInicio.toDate()).format("DD/MM/YYYY");
  fechaFin = moment(fechaFin.toDate()).format("DD/MM/YYYY");
  let data = "";
  console.log(items);
  await Promise.all(
    items.map(async (trans) => {
      if (true) {
        //const trans = (await trans.sale!.trans_info!.ref.get()).data();
        const driver = (
          await FIRESTORE.DOCUMENT_USER(trans.driver).get()
        ).data();
        const invoiceRef = await FIRESTORE.DOCUMENT_DRIVER_BILL(trans.id).get();
        invoiceRef.ref.update({ downloaded: true });
        data =
          data +
          `[REGISTRO]\nFactura compra\n${moment(
            trans?.creation_date.toDate()
          ).format("DD/MM/YY")}|${
            trans!.type === TIPOS_COMPROBANTES["FACTURA A"]
              ? "MA"
              : trans!.type === TIPOS_COMPROBANTES["FACTURA B"]
              ? "MB"
              : trans!.type === TIPOS_COMPROBANTES["FACTURA C"]
              ? "MC"
              : "MM"
          }|${String(trans?.point_of_sale).padStart(5, "0")}|${String(
            trans?.voucher_number
          ).padStart(8, "0")}|${
            driver?.user_code
              ? String(driver?.user_code).padStart(5, "0")
              : "no tiene"
          }|${driver?.name} ${driver?.surname}||C|001|${
            trans?.tax_entity === 0 ? "I" : "M"
          }|${String(trans?.ImpNeto.toFixed(2))}|||||${String(
            trans?.ImpTotal.toFixed(2)
          )}||L|L|||||||006|N||20000|||||||||||||||||||||||\nITEM|PRECIO VIAJE|||N|||${String(
            trans?.amounts?.transfer.toFixed(2)
          )}||${String(trans?.amounts?.transfer.toFixed(2))}|${String(
            trans?.amounts?.transfer.toFixed(2)
          )}||||||||||||||||||||||||||||||||||||\n${
            trans?.amounts?.tolls
              ? `ITEM|PEAJES|||N|||${String(
                  trans?.amounts?.tolls.toFixed(2)
                )}||${String(trans?.amounts?.tolls.toFixed(2))}|${String(
                  trans?.amounts?.tolls.toFixed(2)
                )}||||||||||||||||||||||||||||||||||||\n`
              : ""
          }${
            trans?.amounts?.tolls
              ? `ITEM|ESPERA|||N|||${String(
                  trans?.amounts?.wait_request.toFixed(2)
                )}||${String(trans?.amounts?.wait_request.toFixed(2))}|${String(
                  trans?.amounts?.wait_request.toFixed(2)
                )}||||||||||||||||||||||||||||||||||||\n`
              : ""
          }${
            trans?.amounts?.wait_reports! > 0 &&
            trans?.amounts?.wait_reports != undefined
              ? `ITEM|ESPERA COMPRADA|||N|||${String(
                  trans?.amounts?.wait_reports.toFixed(2)
                )}||${String(trans?.amounts?.wait_reports.toFixed(2))}|${String(
                  trans?.amounts?.wait_reports.toFixed(2)
                )}||||||||||||||||||||||||||||||||||||\n`
              : ""
          }VENCIMIENTO|${moment(trans?.expiration_date.toDate()).format(
            "DD/MM/YY"
          )}|${String(trans?.ImpTotal.toFixed(2))}|
                    \n`;
        console.log(data);
      }
    })
  );

  let blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  fs.saveAs(blob, `COMPRAS-${fechaInicio}/${fechaFin} .txt`);
};

const generateTxtTest = async (items: any[], starDate: any, endDate: any) => {
  let fechaInicio: any = FbDate.fromDate(starDate);
  let fechaFin: any = FbDate.fromDate(endDate);
  fechaInicio = moment(fechaInicio.toDate()).format("DD/MM/YYYY");
  fechaFin = moment(fechaFin.toDate()).format("DD/MM/YYYY");
  let data = "";
  console.log(items);
  await Promise.all(
    items.map(async (trans) => {
      if (true) {
        //const trans = (await trans.sale!.trans_info!.ref.get()).data();
        const driver = (
          await FIRESTORE.DOCUMENT_USER(trans.driver).get()
        ).data();
        data =
          data +
          `[REGISTRO]\nFactura compra\n${moment(
            trans?.creation_date.toDate()
          ).format("DD/MM/YY")}|${
            trans!.type === TIPOS_COMPROBANTES["FACTURA A"]
              ? "MA"
              : trans!.type === TIPOS_COMPROBANTES["FACTURA B"]
              ? "MB"
              : trans!.type === TIPOS_COMPROBANTES["FACTURA C"]
              ? "MC"
              : "MM"
          }|${String(trans?.point_of_sale).padStart(5, "0")}|${String(
            trans?.voucher_number
          ).padStart(8, "0")}|${
            driver?.user_code
              ? String(driver?.user_code).padStart(5, "0")
              : "no tiene"
          }|${driver?.name} ${driver?.surname}||C|001|${
            trans?.tax_entity === 0 ? "I" : "M"
          }|${String(trans?.ImpNeto.toFixed(2))}|||||${String(
            trans?.ImpTotal.toFixed(2)
          )}||L|L|||||||006|N||20000|||||||||||||||||||||||\nITEM|PRECIO VIAJE|||N|||${String(
            trans?.amounts?.transfer.toFixed(2)
          )}||${String(trans?.amounts?.transfer.toFixed(2))}|${String(
            trans?.amounts?.transfer.toFixed(2)
          )}||||||||||||||||||||||||||||||||||||\n${
            trans?.amounts?.tolls
              ? `ITEM|PEAJES|||N|||${String(
                  trans?.amounts?.tolls.toFixed(2)
                )}||${String(trans?.amounts?.tolls.toFixed(2))}|${String(
                  trans?.amounts?.tolls.toFixed(2)
                )}||||||||||||||||||||||||||||||||||||\n`
              : ""
          }${
            trans?.amounts?.tolls
              ? `ITEM|ESPERA|||N|||${String(
                  trans?.amounts?.wait_request.toFixed(2)
                )}||${String(trans?.amounts?.wait_request.toFixed(2))}|${String(
                  trans?.amounts?.wait_request.toFixed(2)
                )}||||||||||||||||||||||||||||||||||||\n`
              : ""
          }${
            trans?.amounts?.wait_reports! > 0 &&
            trans?.amounts?.wait_reports != undefined
              ? `ITEM|ESPERA COMPRADA|||N|||${String(
                  trans?.amounts?.wait_reports.toFixed(2)
                )}||${String(trans?.amounts?.wait_reports.toFixed(2))}|${String(
                  trans?.amounts?.wait_reports.toFixed(2)
                )}||||||||||||||||||||||||||||||||||||\n`
              : ""
          }VENCIMIENTO|${moment(trans?.expiration_date.toDate()).format(
            "DD/MM/YY"
          )}|${String(trans?.ImpTotal.toFixed(2))}|
                    \n`;
        console.log(data);
      }
    })
  );

  let blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  fs.saveAs(blob, `COMPRAS-PRUEBA-${fechaInicio}/${fechaFin} .txt`);
};

export const showID = (item: ScheduledTransfer & WithId) => {
  if (item.art?.accident && item.art?.authorization) {
    return item.art.accident + "-" + item.art.authorization;
  } else if (item.art.accident) {
    return item.art.accident;
  } else {
    return "NPA: " + item.art.npa;
  }
};

export const SelectDriverInput = ({
  onChange,
  value,
  label,
}: {
  onChange: (v: string) => void;
  value: string | undefined;
  label?: string | JSX.Element;
}) => {
  const [userOptions, setUserOptions] = useState<(WithId & User)[]>([]);
  useEffect(() => {
    getActiveUsers().then((v) =>
      setUserOptions(
        _.sortBy(v, [
          (z) => _.toLower(`${z.surname} ${z.name} ${z.phone.number}`),
        ])
      )
    );
  }, []);

  return (
    <Form.Group>
      <Row>
        <Col xl={16}>
          <Form.Control
            as="select"
            value={value}
            onChange={(v) => onChange(v.target.value)}
          >
            <option label="Seleccione un prestador"></option>
            {userOptions.map((e) => (
              <option key={e.id} value={e.id}>
                {_.startCase(
                  _.toLower(
                    `${e.surname} ${e.name} ${
                      e.user_code ? e.user_code : "sin código"
                    }`
                  )
                )}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
    </Form.Group>
  );
};

export const SelectDriverInputWithoutLabel = ({
  onChange,
  value,
  label,
}: {
  onChange: (v: string) => void;
  value: string | undefined;
  label?: string | JSX.Element;
}) => {
  const [userOptions, setUserOptions] = useState<(WithId & User)[]>([]);
  useEffect(() => {
    getActiveUsers().then((v) =>
      setUserOptions(
        _.sortBy(v, [
          (z) => _.toLower(`${z.surname} ${z.name} ${z.phone.number}`),
        ])
      )
    );
  }, []);

  return (
    <Form.Group>
      <Form.Control
        as="select"
        value={value}
        onChange={(v) => onChange(v.target.value)}
      >
        <option label="Prestador"></option>
        {userOptions.map((e) => (
          <option key={e.id} value={e.id}>
            {_.startCase(
              _.toLower(
                `${e.surname} ${e.name} ${
                  e.user_code ? e.user_code : "sin código"
                }`
              )
            )}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export const SelectDriverInputCustom = ({
  onChange,
  value,
  label,
  type,
}: {
  onChange: (v: string) => void;
  value: string | undefined;
  label?: string | JSX.Element;
  type?: number | undefined;
}) => {
  const [userOptions, setUserOptions] = useState<(WithId & User)[]>([]);
  useEffect(() => {
    if (type === -1) {
      getUsers().then((v) =>
        setUserOptions(
          _.sortBy(v, [
            (z) => _.toLower(`${z.surname} ${z.name} ${z.phone.number}`),
          ])
        )
      );
    } else if (type === 0) {
      getInRevisionUsers().then((v) =>
        setUserOptions(
          _.sortBy(v, [
            (z) => _.toLower(`${z.surname} ${z.name} ${z.phone.number}`),
          ])
        )
      );
    } else if (type === 1) {
      getActiveUsers().then((v) =>
        setUserOptions(
          _.sortBy(v, [
            (z) => _.toLower(`${z.surname} ${z.name} ${z.phone.number}`),
          ])
        )
      );
    } else {
      getFailedUsers().then((v) =>
        setUserOptions(
          _.sortBy(v, [
            (z) => _.toLower(`${z.surname} ${z.name} ${z.phone.number}`),
          ])
        )
      );
    }
  }, [type]);

  return (
    <Form.Group>
      <Form.Control
        as="select"
        value={value}
        onChange={(v) => onChange(v.target.value)}
      >
        <option label="Prestador"></option>
        {userOptions.map((e) => (
          <option key={e.id} value={e.id}>
            {_.startCase(
              _.toLower(
                `${e.surname} ${e.name} ${
                  e.user_code ? e.user_code : "sin código"
                }`
              )
            )}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};
export default Purchases;
