import { Spinner } from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { useAsyncFunction } from "controllers/hooks";
import queryString from 'query-string';
import React, { useEffect } from "react";
import { useParams } from "react-router";

const LateTransferArt = (props:any) => {
    const { idModule } = useParams() as { idModule:string }
    const parsed = queryString.parse(props.location.search);
    const [func, loading, error] = useAsyncFunction(()=>CALLABLE_FUNCTIONS.ART_LATE_MODULE_TRANSFER(idModule, parsed.publish === '1'))
    
    useEffect(() => {
        if(idModule !== undefined)
            func()
    },[parsed.publish])

    if(loading)
        return <Spinner />
    if(error)
        return <>{error}</>
    if(idModule === undefined || parsed.publish === undefined)
        return <>Link inválido</>
    if(parsed.publish === '0')
        return <>El viaje ha sido cancelado</>
    return (
        <div>
            {parsed.publish === '1' ? 
                <p>Hemos republicado el módulo #{idModule}. Puede seguirlo desde su página de control.</p>
            :   <p>Hemos cancelado el módulo #{idModule}.</p>}
        </div>
   )
}

export default LateTransferArt