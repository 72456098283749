import { getContract } from 'components/Admin/Config/Global/reducer';
import { Center, Container, Spinner, SubmitButton } from 'components/styles';
import { getCars } from 'components/User/Cars/reducer';
import { getAddress } from 'components/User/Zones/reducer';
import { getPersonalInformation } from 'controllers/user/info/personal';
import { getTaxableEntity } from 'controllers/user/info/taxable';
import Handlebars from 'handlebars';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IdType } from 'types/user-data';

const TermsAndConditions = ({submitting}:{submitting:boolean}) => {

    const [contract, setContract] = useState<string|undefined>(undefined)
    const [loading, setLoading] = useState(true)
    const [finishReading, setFinishReading] = useState(false)
    const uid : string = useSelector((state:any) => state.firebase.auth.uid)
    const divRef = useRef<HTMLDivElement|undefined>()

    useEffect(() => {
        Promise.all([
            getContract().then( (v) =>  v.driver_text),
            getPersonalInformation(uid),
            getTaxableEntity(uid),
            getCars(uid),
        ]).then(
            ([contractTemplate, personalInfo, taxableInfo, carsInfo]) => {
                const contract = Handlebars.compile(contractTemplate)(
                    {
                        nombre_conductor: personalInfo.name,
                        apellido_conductor: personalInfo.surname,
                        tipo_documento: IdType[personalInfo.idType],
                        documento: personalInfo.id,
                        domicilio_conductor: getAddress(personalInfo.address.address),
                        fecha_contrato: moment().format('LLLL'),
                        vehiculos: carsInfo.map(v => ({
                            vehiculo_marca: v.brand,
                            vehiculo_modelo: v.model,
                            vehiculo_numero_motor: v.engine,
                            vehiculo_patente: v.licence
                        }))
                    }
                )
                setContract(contract)

            }
        ).then(()=>setLoading(false))
    }, [])

    const checkContractRead = () => {
        if(divRef.current && Math.ceil(divRef.current.scrollTop) + divRef.current.offsetHeight >= divRef.current.scrollHeight)
            setFinishReading(true)
    }

    if(loading)
        return <Spinner />
    return(
    <>
        <ContainerTermsAndConditions 
            onScroll={checkContractRead} 
            ref={divRef}>
        <Pre>
            {contract}
        </Pre> 
        </ContainerTermsAndConditions>
        <Center>
            <SubmitButton 
                disabled={!finishReading}
                submitting={submitting} 
                variant="success" 
                type="submit" 
                style={{minWidth:120}}>
                Leí, comprendí y acepto los términos y condiciones particulares, los términos y condiciones generales del uso de la 
                PLATAFORMA y la política de privacidad de SATAPP en su totalidad
            </SubmitButton>
        </Center>
    </>
)}

const ContainerTermsAndConditions = styled(Container)`
    overflow-y: scroll;
    height: 500px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    margin-bottom: 25px;
`

const Pre = styled.pre`
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    font-family: inherit;
    font-size: 1rem;

`

export default TermsAndConditions;