import React, { useState } from "react";
import { Button, Spinner, ButtonWithTooltip } from "components/styles";
import { Modal, Col } from "react-bootstrap";
import { useAsyncFunction } from "controllers/hooks";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { ModuleTransfer } from "types/module-transfer";
import { TransferStage } from "types/transfer";
import { SelectDriverInput } from "components/Admin/Sales/Purchases";

export const CancelModal = ({
  show,
  module,
  handleClose,
  idModule,
}: {
  module: ModuleTransfer;
  show: boolean;
  handleClose: any;
  idModule: string;
}) => {
  const [func, loading, error] = useAsyncFunction(
    (republish: boolean) =>
      CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_CANCEL(idModule, republish),
    undefined,
    handleClose
  );
  const [func2, loading2, error2] = useAsyncFunction(
    () =>
      CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_CANCEL(idModule, false, true),
    undefined,
    handleClose
  );
  const [func3, loading3, error3] = useAsyncFunction(
    () =>
      CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_CANCEL(
        idModule,
        false,
        true,
        undefined,
        true
      ),
    undefined,
    handleClose
  );

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Cancelar Traslado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading || loading2 || loading3 ? (
          <Spinner />
        ) : error ? (
          error
        ) : error2 ? (
          error2
        ) : error3 ? (
          error3
        ) : (
          <h6>¿Desea cancelar el traslado?</h6>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Col>
          <ButtonWithTooltip
            className="pull-left"
            variant="danger"
            style={{ textAlign: "center" }}
            textTooltip="Se cancela el traslado actual (no el módulo entero) y NO se notifica por mail a la ART"
            onClick={() => func2()}
          >
            Cancelar ART Sin aviso
          </ButtonWithTooltip>
        </Col>

        {module.status.stage !== TransferStage.searching_driver && (
          <ButtonWithTooltip
            variant="danger"
            style={{ textAlign: "center" }}
            textTooltip="Se le desasigna el traslado al conductor actual y se vuelve a publicar solamente el tramo actual (no el módulo entero)"
            onClick={() => func(true)}
          >
            Cancelar y Republicar
          </ButtonWithTooltip>
        )}
        {module.status.stage === TransferStage.searching_driver && (
          <ButtonWithTooltip
            variant="success"
            style={{ textAlign: "center" }}
            textTooltip="Se cancela el módulo y la ART lo envia a Feleval.(NO envia email)"
            onClick={() => func3()}
          >
            Devolver ART/Feleval
          </ButtonWithTooltip>
        )}

        <ButtonWithTooltip
          variant="danger"
          style={{ textAlign: "center" }}
          textTooltip="Se cancela el traslado actual (no el módulo entero) y se notifica por mail a la ART"
          onClick={() => func(false)}
        >
          Cancelar ART
        </ButtonWithTooltip>
        <Button
          variant="secondary"
          style={{ textAlign: "center", width: "4rem" }}
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const CancelAndTakeAction = ({
  show,
  module,
  handleClose,
  idModule,
}: {
  module: ModuleTransfer;
  show: boolean;
  handleClose: any;
  idModule: string;
}) => {
  const [driver, setDriver] = useState<string | undefined>(undefined);
  const [func, loading, error] = useAsyncFunction(
    (republish: boolean) =>
      CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_CANCEL(idModule, republish),
    undefined,
    handleClose
  );
  const [func2, loading2, error2] = useAsyncFunction(
    () =>
      CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_CANCEL(
        idModule,
        undefined,
        undefined,
        driver
      ),
    undefined,
    handleClose
  );
  console.log(driver, "driver");

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Cancelar y Republicar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading || loading2 ? (
          <Spinner />
        ) : error ? (
          error
        ) : error2 ? (
          error2
        ) : (
          <>
            {/* <h6>Elija una acción</h6> */}
            {/* <SelectDriverInput onChange={setDriver} value={driver} /> */}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {module.status.stage !== TransferStage.searching_driver && (
          <ButtonWithTooltip
            variant="danger"
            style={{ textAlign: "center" }}
            textTooltip="Se le desasigna el traslado al conductor actual y se vuelve a publicar solamente el tramo actual (no el módulo entero)"
            onClick={() => func(true)}
          >
            Cancelar y Republicar
          </ButtonWithTooltip>
        )}

        {/* {module.status.stage !== TransferStage.searching_driver && (
          <ButtonWithTooltip
            disabled={driver ? false : true}
            variant="warning"
            style={{ textAlign: "center" }}
            textTooltip="Se le desasigna el traslado al conductor actual y se reasigna al prestador seleccionado"
            onClick={() => func2()}
          >
            Cancelar y Reasignar
          </ButtonWithTooltip>
        )} */}

        <Button
          variant="secondary"
          style={{ textAlign: "center", width: "4rem" }}
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
