import { Uid } from "controllers/auth"
import database, { WithId, WithUid } from "controllers/database"
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Contract, User } from "types/user-data"

export const getContracts = (uid : Uid) : Promise<(WithId & Contract)[]> => (
    database.collection('user').doc(uid).collection('contract').as(Contract)
)

export const getContract = (uid: Uid, id: string) : Promise<Contract> => (
    database.collection('user').doc(uid).collection('contract').doc(id).as(Contract)
)

export const newContract = (uid: Uid, text: string) : Promise<void> => (
    database.collection('user').doc(uid).collection('contract').doc(uid).set( {text: text, date : firebase.firestore.Timestamp.fromDate( new Date() ), validated : false} as Contract, Contract)
)

export const invalidateContract = (uid : Uid, id : string) : Promise<null> => (
    new Promise(()=>null)
)

//m= Maybe, p = Promise, a = array
export const getContractsAdmin = () : Promise<(Contract & User & WithUid & WithId)[]> => {
    const qqaContractAdmin = database
    .collectionGroup('contract')
    .as(Contract)
    .then(contracts => 
        Promise.all(
            contracts.map(
                contract => database
                    .collection('user')
                    .doc(contract.uid)
                    .as(User)
                    .then(user =>
                        ({
                            ...contract,
                            ...user
                        } as any)
                    )
            )
        )
    )
    return qqaContractAdmin

}

export const minutesToString = (m:number) => {
    if(m < 10)
        return "0"+m
    return m
}

export const monthToString = (m:number) => {
    if(m === 0)
        return "enero"
    if(m === 1)
        return "febrero"
    if(m === 2)
        return "marzo"
    if(m === 3)
        return "abril"
    if(m === 4)
        return "mayo"
    if(m === 5)
        return "junio"
    if(m === 6)
        return "julio"
    if(m === 7)
        return "agosto"
    if(m === 8)
        return "septiembre"
    if(m === 9)
        return "octubre"
    if(m === 10)
        return "noviembre"
    if(m === 11)
        return "diciembre"
    return "Error"
}

export const monthToStringShort = (m:number) => {
    return monthToString(m).substr(0, 3)
}

export const capitalize = (s : string) => (s[0].toUpperCase() + s.slice(1))
