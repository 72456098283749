import { Field } from "components/input/field";
import { SubmitButton, TextWithTooltip, AsyncButton } from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import React from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { reduxForm, SubmissionError } from "redux-form";
import { removeUndefined } from "util/meta";
import {
  forzarPrecioMinYMax,
  forzarPeajeMinYmax,
} from "controllers/validations";

interface EditTransfer {
  amount?: number;
  obs?: string;
  cabify?: string;
  obs_satapp?: string;
  toll?: number;
  additional_K?: number;
}

export const EditModal = ({
  show,
  handleClose,
  idTransfer,
  idDriver,
  cabify,
  finished,
  bill,
}: {
  show: boolean;
  handleClose: any;
  idTransfer: string;
  idDriver: string;
  cabify?: boolean;
  finished?: boolean;
  bill?: boolean;
}) => {
  const handleSubmit = async (values: any) => {
    const data: EditTransfer = {
      obs: values.obs,
      amount:
        values.amount !== "" && values.amount !== undefined
          ? Number(values.amount)
          : undefined,
      obs_satapp: values.obs_satapp,
      toll:
        values.toll !== "" && values.toll !== undefined
          ? Number(values.toll)
          : undefined,
      additional_K:
        values.add_k !== "" && values.add_k !== undefined
          ? Number(values.add_k)
          : undefined,
    };
    removeUndefined(data);
    console.log(data, "soy data");
    return CALLABLE_FUNCTIONS.ADMIN_TRANSFER_EDIT(
      idTransfer,
      idDriver,
      data
    ).catch((e) => {
      console.log(e);
      throw new SubmissionError({ _error: e.message });
    });
  };
  const handleSubmitCabify = async (values: any) => {
    const data: EditTransfer = {
      obs: values.obs,
      amount:
        values.amount !== "" && values.amount !== undefined
          ? Number(values.amount)
          : undefined,
      cabify: values.link !== "" ? values.link : undefined,
    };
    removeUndefined(data);
    if (data.cabify) {
      return CALLABLE_FUNCTIONS.ADMIN_TRANSFER_EDIT_CABIFY(
        idTransfer,
        idDriver,
        data.cabify
      ).catch((e) => {
        console.log(e);
        throw new SubmissionError({ _error: e.message });
      });
    } else {
      return CALLABLE_FUNCTIONS.ADMIN_TRANSFER_EDIT(
        idTransfer,
        idDriver,
        data
      ).catch((e) => {
        console.log(e);
        throw new SubmissionError({ _error: e.message });
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Editar Módulo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>SOLO COMPLETAR LOS CAMPOS QUE SE QUIERAN MODIFICAR</h4>

        {finished && bill ? (
          <FormEditTransferReduxFinishedAndBill onSubmit={handleSubmit} />
        ) : finished ? (
          <FormEditTransferReduxFinished onSubmit={handleSubmit} />
        ) : cabify ? (
          <FormEditTransferReduxCabify onSubmit={handleSubmitCabify} />
        ) : (
          <FormEditTransferRedux onSubmit={handleSubmit} />
        )}
      </Modal.Body>
    </Modal>
  );
};

const FormEditTransfer = ({
  handleSubmit,
  submitSucceeded,
  submitting,
  error,
}: {
  handleSubmit: any;
  submitSucceeded: boolean;
  submitting: boolean;
  error: any;
}) => {
  if (submitSucceeded) {
    return <Alert variant="success">Traslado modificado</Alert>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error}</Alert>}</Row>

      <Row>
        <Col xl={6}>
          <Field
            labelinput={
              <TextWithTooltip textTooltip="Se fuerza el precio base. A este precio luego se le suma los peajes y los tiempos de espera. Si llegase a ser negativo o cancelado entonces no se toma en cuenta este precio">
                Forzar Precio
              </TextWithTooltip>
            }
            name="amount"
            type="number"
            validate={forzarPrecioMinYMax}
          />
        </Col>
        <Col xl={6}>
          <Field
            labelinput={<>Observaciones SATAPP</>}
            name="obs_satapp"
            as="textarea"
            type="textarea"
          />
        </Col>
        <Col xl={6}>
          <Field
            labelinput={<>Observaciones</>}
            name="obs"
            as="textarea"
            rows="4"
            type="textarea"
          />
        </Col>

        <Col xl={6}>
          <Field
            labelinput={
              <TextWithTooltip textTooltip="Se fuerza el precio TOTAL de los peajes. Esto sobreescribe cualquier peaje existente">
                Forzar Peaje
              </TextWithTooltip>
            }
            name="toll"
            type="number"
            validate={forzarPeajeMinYmax}
          />
        </Col>
        <Col xl={6}>
          <Field
            labelinput={
              <TextWithTooltip textTooltip="Se fuerza el precio TOTAL de los kilometros muertos. Esto sobreescribe cualquier kilometros muertos existente">
                Forzar Kilómetro Muerto
              </TextWithTooltip>
            }
            name="add_k"
            type="number"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <SubmitButton block submitting={submitting}>
            Actualizar
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};

const FormEditTransferCabify = ({
  handleSubmit,
  submitSucceeded,
  submitting,
  error,
}: {
  handleSubmit: any;
  submitSucceeded: boolean;
  submitting: boolean;
  error: any;
}) => {
  if (submitSucceeded) {
    return <Alert variant="success">Traslado modificado</Alert>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error.message}</Alert>}</Row>

      <Row>
        <Col xl={6}>
          <Field
            labelinput={
              <TextWithTooltip textTooltip="Se fuerza el precio base. A este precio luego se le suma los peajes y los tiempos de espera. Si llegase a ser negativo o cancelado entonces no se toma en cuenta este precio">
                Forzar Precio
              </TextWithTooltip>
            }
            name="amount"
            type="number"
            validate={forzarPrecioMinYMax}
          />
          <Field
            labelinput={
              <TextWithTooltip textTooltip="Agregar link Cabify">
                Link Cabify
              </TextWithTooltip>
            }
            name="link"
            type="text"
          />
        </Col>
        <Col xl={6}>
          <Field
            labelinput={<>Observaciones</>}
            name="obs"
            as="textarea"
            rows="4"
            type="textarea"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <SubmitButton block submitting={submitting}>
            Actualizar
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};

const FormEditTransferFinished = ({
  handleSubmit,
  submitSucceeded,
  submitting,
  error,
}: {
  handleSubmit: any;
  submitSucceeded: boolean;
  submitting: boolean;
  error: any;
}) => {
  if (submitSucceeded) {
    return <Alert variant="success">Traslado modificado</Alert>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error}</Alert>}</Row>

      <Row>
        <Col xl={6}>
          <Field
            labelinput={<>Observaciones SATAPP</>}
            name="obs_satapp"
            as="textarea"
            type="textarea"
          />
        </Col>
        <Col xl={6}>
          <Field
            labelinput={
              <TextWithTooltip textTooltip="Se fuerza el precio TOTAL de los peajes. Esto sobreescribe cualquier peaje existente">
                Forzar Peaje
              </TextWithTooltip>
            }
            name="toll"
            type="number"
            validate={forzarPeajeMinYmax}
          />
        </Col>
        <Col xl={6}>
          <Field
            labelinput={
              <TextWithTooltip textTooltip="Se fuerza el precio de los kilometros muertos. Esto sobreescribe cualquier kilometro muerto existente">
                Forzar Kilómetro Muerto
              </TextWithTooltip>
            }
            name="add_k"
            type="number"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SubmitButton block submitting={submitting}>
            Actualizar
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};

const FormEditTransferFinishedAndBill = ({
  handleSubmit,
  submitSucceeded,
  submitting,
  error,
}: {
  handleSubmit: any;
  submitSucceeded: boolean;
  submitting: boolean;
  error: any;
}) => {
  if (submitSucceeded) {
    return <Alert variant="success">Traslado modificado</Alert>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error}</Alert>}</Row>

      <Row>
        <Col xl={6}>
          <Field
            labelinput={<>Observaciones SATAPP</>}
            name="obs_satapp"
            as="textarea"
            type="textarea"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SubmitButton block submitting={submitting}>
            Actualizar
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};
const FormEditTransferReduxCabify = reduxForm({
  form: "edit-transfer",
})(FormEditTransferCabify);

const FormEditTransferReduxFinished = reduxForm({
  form: "edit-transfer",
})(FormEditTransferFinished);

const FormEditTransferRedux = reduxForm({
  form: "edit-transfer",
})(FormEditTransfer);
const FormEditTransferReduxFinishedAndBill = reduxForm({
  form: "edit-transfer",
})(FormEditTransferFinishedAndBill);
