//import { Client } from '@googlemaps/google-maps-services-js'
import { GeocodeResponseData } from "@googlemaps/google-maps-services-js/dist/geocode/geocode";
import ROUTES from "constants/routes";
import { FirePoint } from "geofirex";
import { get } from "util/http";

//const key = 'AIzaSyCK5NSBuD4qseCXZhE9IWvoIvCfvDDc09w'
//const Map = new Client({})

export declare type Location = MapPoint & {
  address: Address;
};

export declare type MapPoint = FirePoint;

export declare type Address = {
  district: string;
  number: string;
  postal_code?: string;
  province: string;
  locality?: string;
  street: string;
  country?: string;
  formatted_address: string;
  place_id: string;
};

export const getFullAddress = (z: Location): string => {
  /* if (z.address.locality?.length == 3 && z.address.number) {
    return `${z.address.street}, ${z.address.number}, CABA, ${z.address.province}`;
  } else */ 
  if(z.address.locality && z.address.locality.length <= 3) {
    return `${z.address.street}, ${z.address.number},${z.address.district}, ${z.address.province}`
  }
  else if (z.address.locality && z.address.number) {
    return `${z.address.street}, ${z.address.number}, ${z.address.locality}, ${z.address.province}`;
  } else if (!z.address.locality && z.address.number) {
    return `${z.address.street}, ${z.address.number}, CABA, ${z.address.province}`;
  } else if (z.address.locality && !z.address.number) {
    return `${z.address.street}, S/N, ${z.address.locality}, ${z.address.province}`;
  } else {
    return `${z.address.street}, S/N, CABA, ${z.address.province}`;
  }
};

export const getLocation = (
  address: string
): Promise<GeocodeResponseData> /*Location*/ =>
  get(ROUTES.API_MAP_LOCATION, { address: address });

export const getAddressFromPartial = (address: string): Promise<string[]> =>
  get(ROUTES.API_MAP_COMPLETE_ADDRESS, { address: address });
//Map.placeAutocomplete({params:{key, input:address, language:'es', types: PlaceAutocompleteType.address}}) //components:['country:ar']} ) //RESTRICTO A ARGENTINA
//.then( result => JSON.stringify(result.data.predictions.map( (s) => s.description ) )
//) as Promise<string[]>

export const getAddressFromLatLng = (v: {
  lat: number;
  lng: number;
}): Promise<Address> => get(ROUTES.API_MAP_ADDRESS_FROM_LATLNG, v);

export default Map;
