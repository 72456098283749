import React, { useState } from "react";
import { Button } from "components/styles";
import { Modal, Dropdown } from "react-bootstrap";

import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";

export const EditStateModal = ({
  idTransfer,
  show,
  handleClose,
  uidDriver,
}: {
  idTransfer: string;
  show: boolean;
  handleClose: any;
  uidDriver: string;
}) => {
  const [state, setState] = useState("");
  const handleClick = (e: any) => {
    setState(e.target.name);
    console.log(state);
  };
  const changeState = async () => {
    const data = { state: state, idTransfer: idTransfer, uidDriver: uidDriver };
    CALLABLE_FUNCTIONS.ADMIN_CHANGE_TRANSFER_STATE(data);
  };
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Editar Traslado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            {state ? state : "Editar estado"}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.ItemText>Selecciona un estado</Dropdown.ItemText>
            <Dropdown.Item name="Iniciado" onClick={handleClick}>
              Iniciado
            </Dropdown.Item>
            <Dropdown.Item name="En Origen" onClick={handleClick}>
              En Origen
            </Dropdown.Item>
            <Dropdown.Item name="En Viaje" onClick={handleClick}>
              En Viaje
            </Dropdown.Item>
            <Dropdown.Item name="Finalizado" onClick={handleClick}>
              Finalizado
            </Dropdown.Item>
            {/* <Dropdown.Item name="Negativo" onClick={handleClick}>
              Negativo
            </Dropdown.Item> */}
            {/* <Dropdown.Item name="Feleval" onClick={handleClick}>
              Feleval
            </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={changeState}>Confirmar cambio</Button>
        <Button
          variant="secondary"
          style={{ textAlign: "center", width: "4rem" }}
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
