import React from "react";
import {
  Container,
  Button,
  ButtonGroup,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ROUTES from "constants/routes";
//import 'index.css'
import { Link } from "components/styles";
import styled from "styled-components";
import { pxToPt } from "constants/css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router";

//class MiniNavigation extends React.Component<any, any> {
const MiniNavigationErrors = (props: any) => {
  let location = useLocation();

  return (
    <Container style={{ maxWidth: "100%" }}>
      <Container style={{ margin: 20 }}>
        <Row>
          <Col md={11}>
            <LinkMiniNavigation to={ROUTES.ADMIN_HOME_PROFILES}>
              <Button
                className="left"
                variant="info"
                active={location.pathname === ROUTES.ADMIN_HOME_PROFILES}
              >
                USUARIOS
              </Button>
            </LinkMiniNavigation>
            <LinkMiniNavigation to={ROUTES.ADMIN_HOME_PROFILES_PENDING}>
              <Button
                variant="info"
                active={
                  location.pathname === ROUTES.ADMIN_HOME_PROFILES_PENDING
                }
              >
                A REVISAR
              </Button>
            </LinkMiniNavigation>
            <LinkMiniNavigation to={ROUTES.ADMIN_HOME_PROFILES_REVIEWED}>
              <Button
                variant="info"
                active={
                  location.pathname === ROUTES.ADMIN_HOME_PROFILES_REVIEWED
                }
              >
                REVISADOS
              </Button>
            </LinkMiniNavigation>
            <LinkMiniNavigation to={ROUTES.ADMIN_HOME_PROFILES_PASSED}>
              <Button
                variant="info"
                active={location.pathname === ROUTES.ADMIN_HOME_PROFILES_PASSED}
              >
                APROBADOS
              </Button>
            </LinkMiniNavigation>
            <LinkMiniNavigation to={ROUTES.ADMIN_HOME_PROFILES_REJECTED}>
              <Button
                variant="info"
                active={
                  location.pathname === ROUTES.ADMIN_HOME_PROFILES_REJECTED
                }
              >
                DESAPROBADOS
              </Button>
            </LinkMiniNavigation>
          </Col>
        </Row>
      </Container>
      <Container style={{ maxWidth: "100%" }}>{props.children}</Container>
    </Container>
  );
};

const LinkMiniNavigation = styled(Link)`
  color: #fff !important;
  Button {
    font-size: ${pxToPt(14)};
  }
  &:hover {
    text-decoration: none;
  }
  .btn {
    border-radius: 0;
  }
  .left {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .right {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;

export default MiniNavigationErrors;
