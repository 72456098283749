import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Center, Container, LinkWithoutColor, Padding, RibbonContainer, RibbonTopLeft, Spinner } from 'components/styles';
import ROUTES from 'constants/routes';
import { WithId } from 'controllers/database';
import { useDbCollection, useDbDocument } from 'controllers/hooks';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import styled from 'styled-components';
import { Car } from 'types/car';
import { Revision, RevisionStatus } from 'types/user-data';
import { uidSelector } from '../../../controllers/auth';

const UserCarsPage = () => {
    const uid = useSelector(uidSelector)
    const itemsCars = useDbCollection<Car>(`user/${uid}/car`)
    
    if(!isLoaded(itemsCars))
        return <Spinner/>
    if(isEmpty(itemsCars))
        return <>No hay autos</>
    return(
    <Padding pad="5px">
    <Container>
        <Row>
        {itemsCars?.map(c => <CarCard car={c} key={c.id} uid={uid}/>)}
            <Col md={9} xl={4}>
                <LinkWithoutColor to={ROUTES.USER_CARS_ADD_OR_EDIT('0')}>
                    <AddNewCarContainer>
                        <Center>
                            <h1><FontAwesomeIcon icon={faPlus}/></h1>
                            <h4>Agregar nuevo Auto</h4>
                            <p>Recuerde tener la documentación necesaria a mano: cédula verde, azul, vtv y seguro.</p>
                        </Center>
                    </AddNewCarContainer>
                </LinkWithoutColor>
            </Col>
        </Row>
    </Container>
    </Padding>
)}

const CarCard = ({car, uid}:{car:Car & WithId, uid:string}) => {
    const revision = useDbDocument<Revision>(...[`user/${uid}/car_revision`, car.id]) 

    if(!isLoaded(revision))
        return <Spinner/>
    if(!revision)
        return <>Error</>
    return (
        <Col md={9} xl={4}>
            <LinkWithoutColor to={ROUTES.USER_CARS_VIEW(car.id)}>
                <RibbonContainerPlates>
                    {revision.status === RevisionStatus.Aprobado ?
                        <RibbonTopLeft color="#00426A"><span>VALIDADO</span></RibbonTopLeft> 
                    : revision.status === RevisionStatus.Desaprobado ?
                        <RibbonTopLeft color="#af0000"><span>DESAPROBADO</span></RibbonTopLeft>
                    :
                        <RibbonTopLeft color="grey"><span>EN REVISIÓN</span></RibbonTopLeft>
                    }

                    <CarBrand>{car.brand} {car.model}</CarBrand>
                    <Center>
                        <Plate country="Argentina" licence={car.licence}/>
                        <h4>{car.year}</h4>
                    </Center>
                </RibbonContainerPlates>
            </LinkWithoutColor>
        </Col>
)}

const Plate = ({licence, country}:{licence : string, country : string}) => {
    if(licence.length === 6)
        licence = licence.substring(0,3).toUpperCase()+' '+licence.substring(3)
    else //ES LICENCIA NUEVA (AA 123 AA)
        licence = licence.substring(0,2).toUpperCase()+' '+licence.substring(2,5)+' '+licence.substring(5).toUpperCase()

    return(
    <div style={{width:"14rem", display:"inline"}}>
        <TitlePlate>{country}</TitlePlate>
        <BodyPlate>{licence}</BodyPlate>
    </div>
)}
const BodyPlate = styled.div`
    font-size: 2.8rem;
    font-weight: 700;
    align-items: center;
    border: solid #ccc 1px;
    background: repeating-linear-gradient(-45deg, #fff, #fff 5px, #ECECEC 5px, #ECECEC 10px);
    padding: 5px;
`
const TitlePlate = styled.div`
    background-color: #174D9B;
    border: solid #174D9B 1px;
    border-bottom: none;
    border-radius: 3px 3px 0px 0px;
    color: #f1f1f1;
    font-size: 9px;
    text-align: center;
    padding: 2px 0px;
`

const RibbonContainerPlates = styled(RibbonContainer)`
    height: 17rem;
`

const AddNewCarContainer = styled(RibbonContainerPlates)`
    border: dashed 2px #ccc;
`

const CarBrand = styled.div`
    text-align: center;
    margin: 5px 0px 10px;
    font-size: 2rem;
    color: #777;
`

const Ribbon = styled.div`
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    left: -10px;
    &:before,&:after {
        position: absolute;
        z-index: -1;
        content: '';
        display: block;
        border: 5px solid #2980b9;
        border-top-color: transparent;
        border-left-color: transparent;
    }
    &:before {
        top: 0;
        right: 0;
    }
    &:after {
        bottom: 0;
        left: 0;
    }
    & span {
        right: -25px;
        top: 30px;
        transform: rotate(-45deg);
        position: absolute;
        display: block;
        width: 225px;
        padding: 15px 0;
        background-color: #00426A;
        box-shadow: 0 5px 10px rgba(0,0,0,.1);
        color: #fff;
        font: 700 18px/1 'Lato', sans-serif;
        text-shadow: 0 1px 1px rgba(0,0,0,.2);
        text-transform: uppercase;
        text-align: center;
    }
`

export default UserCarsPage;