import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Button, AsyncButton, Spinner } from "components/styles";
import { InfoRow } from "components/Admin/Transfers/view/scheduled";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { FIRESTORE } from "constants/firestore";

export const EditLocalityModal = ({
  documentId,
  show,
  handleClose,
  amounts,
  reset,
}: {
  documentId: string;
  show: boolean;
  handleClose: () => void;
  amounts: {
    locality_id: string;
    locality: string;
    province: string;
    province_id: string;
  };
  reset: () => void;
}) => {
  const [idLocality, setIdLocality] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [locality, setLocality] = useState<string>("");
  const [idProvince, setIdProvince] = useState<string>("");
  const [originSelected, setOriginSelected] = useState<string | undefined>(
    undefined
  );

  const [dataOrigin, setDataOrigin] = useState<
    | {
        province: {
          description: string;
          id: string;
        };
      }
    | undefined
  >(undefined);

  useEffect(() => {
    setIdLocality(amounts.locality_id);
    setProvince(amounts.province);
    setLocality(amounts.locality);
    setIdProvince(amounts.province_id);
  }, [amounts]);

  useEffect(() => {
    documentId &&
      FIRESTORE.DOCUMENT_LOCALITIES_SATAPP(documentId)
        .get()
        .then((doc) => doc.data())
        .then((data) => {
          console.log(data);
        });
  }, [documentId]);

  const submitChanges = async () => {
    const data = {
      id: documentId,
      locality: locality,
      locality_id: idLocality,
      province: dataOrigin!.province.description,
      province_id: dataOrigin!.province.id,
    };
    console.log(data, "data1");

    await CALLABLE_FUNCTIONS.ADMIN_EDIT_LOCALITY_DATABASE(data);
    console.log(data, "data2");

    handleClose();
    reset();
  };

  return idLocality ? (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{`Editar Localidad ${locality}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <br />
          <InfoRow
            label="Id Localidad"
            info={
              <input
                value={idLocality}
                placeholder="Id Localidad"
                onChange={(e) => setIdLocality(e.target.value)}
                type="number"
              />
            }
          />
          <br />
          <InfoRow
            label="Localidad"
            info={
              <input
                value={locality}
                placeholder="Localidad"
                onChange={(e) => setLocality(e.target.value)}
                type="string"
              />
            }
          />
          <br />
          <InfoRow
            label="Provincia"
            info={
              <PovinceSelector
                localitySelected={originSelected}
                setLocalitySelected={setOriginSelected}
                setDataSelected={setDataOrigin}
                province={province}
                province_id={idProvince}
              />
            }
          />
        </>
      </Modal.Body>
      <Modal.Footer>
        <AsyncButton onClick={submitChanges}>Confirmar cambios</AsyncButton>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <>Cargando</>
  );
};

export const AddNewLocalyModal = ({
  show,
  handleClose,
  reset,
}: {
  show: boolean;
  handleClose: () => void;
  reset: () => void;
}) => {
  const [newLocality, setNewLocality] = useState<string>("");
  const [newIdLocality, setNewIdLocality] = useState<string>("");
  const [originSelected, setOriginSelected] = useState<string | undefined>(
    undefined
  );
  const [dataOrigin, setDataOrigin] = useState<
    | {
        province: {
          description: string;
          id: string;
        };
      }
    | undefined
  >(undefined);

  const closeAndReset = () => {
    setNewLocality("");
    setNewIdLocality("");
    setDataOrigin(undefined);
    setOriginSelected(undefined);
    handleClose();
  };

  const submitLocality = async () => {
    const data = {
      locality: newLocality,
      locality_id: newIdLocality,
      province: dataOrigin!.province.description,
      province_id: dataOrigin!.province.id,
    };
    console.log(data);

    await CALLABLE_FUNCTIONS.ADMIN_ADD_LOCALITY_DATABASE(data);
    closeAndReset();
    reset();
  };
  return (
    <Modal show={show} onHide={closeAndReset} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Agregar Localidad</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <InfoRow
            label="Id Localidad"
            info={
              <input
                placeholder="Id Localidad"
                onChange={(e) => setNewIdLocality(e.target.value)}
                type="string"
              />
            }
          />
          <br />
          <InfoRow
            label="Localidad"
            info={
              <input
                placeholder="Localidad"
                onChange={(e) => setNewLocality(e.target.value)}
                type="string"
              />
            }
          />
          <br />
          <InfoRow
            label="Provincia"
            info={
              <PovinceSelector
                localitySelected={originSelected}
                setLocalitySelected={setOriginSelected}
                setDataSelected={setDataOrigin}
              />
            }
          />
        </>
      </Modal.Body>
      <Modal.Footer>
        <AsyncButton onClick={submitLocality} disabled={originSelected}>
          Agregar nueva localidad
        </AsyncButton>
        <Button variant="secondary" onClick={closeAndReset}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const PovinceSelector = ({
  localitySelected,
  setLocalitySelected,
  setDataSelected,
  province,
  province_id,
}: {
  localitySelected: string | undefined;
  setLocalitySelected: (a: string) => void;
  setDataSelected: any;
  province?: string;
  province_id?: string;
}) => {
  const cloudProvince = [
    { province_id: 0, province: "Capital Federal" },
    { province_id: 1, province: "Buenos Aires" },
    { province_id: 2, province: "Catamarca" },
    { province_id: 3, province: "Córdoba" },
    { province_id: 4, province: "Corrientes" },
    { province_id: 5, province: "Entre Ríos" },
    { province_id: 6, province: "Jujuy" },
    { province_id: 7, province: "Mendoza" },
    { province_id: 8, province: "La Rioja" },
    { province_id: 9, province: "Salta" },
    { province_id: 10, province: "San Juan" },
    { province_id: 11, province: "San Luis" },
    { province_id: 12, province: "Santa Fé" },
    { province_id: 13, province: "Santiago del Estero" },
    { province_id: 14, province: "Tucuman" },
    { province_id: 16, province: "Chaco" },
    { province_id: 17, province: "Chubut" },
    { province_id: 18, province: "Formosa" },
    { province_id: 19, province: "Misiones" },
    { province_id: 20, province: "Neuquen" },
    { province_id: 21, province: "La Pampa" },
    { province_id: 22, province: "Río Negro" },
    { province_id: 23, province: "Santa Cruz" },
    { province_id: 24, province: "Tierra del Fuego" },
  ];

  return (
    <Form.Group controlId="exampleForm.SelectCustom">
      <Form.Control
        as="select"
        value={localitySelected}
        onChange={(e) => {
          const splited = e.target.value.split("-");
          const province_name = splited[0];
          const province_id = splited[1];

          const location_satapp = {
            province: {
              description: province_name,
              id: province_id,
            },
          };

          setDataSelected(location_satapp);
        }}
      >
        <option value={-1}>{"Provincia"} </option>
        {cloudProvince?.map((item) => (
          <option>
            {`${item.province.toLowerCase()}-${item.province_id}`}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};
