import {
  locToString,
  newZone,
  toLocation,
  Zone,
} from "components/User/Zones/reducer";
import { Uid } from "controllers/auth";
import database from "controllers/database";
import { setLicence } from "controllers/user/info/licence";
import { setPersonalInformation } from "controllers/user/info/personal";
import {
  add_point_of_sale,
  setTaxableEntity,
} from "controllers/user/info/taxable";
import firebase from "firebase/app";
import "firebase/firestore";
import { SubmissionError } from "redux-form";
import {
  IdType,
  Licence,
  LicenceCategory,
  PersonalInformation,
  TaxableEntity,
  TaxableEntityType,
  User,
} from "types/user-data";
import { newCar, updateCar } from "../../Cars/reducer";
import AfipForm from "./AfipForm";
import BeginningForm from "./Beginning";
import CarForm from "./CarForm";
import LicenseForm from "./LicenseForm";
import PersonalForm from "./PersonalForm";
import QuizForm from "./QuizForm/QuizForm";
import TaxableForm from "./TaxableForm";
import TermsAndConditions from "./TermsAndConditions";
import { Car } from "types/car";
import { FbDate } from "types/transfer";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import _ from "lodash";
import Admin from "components/Admin";
import { FIRESTORE } from "constants/firestore";

export enum StepsFormNewcomers {
  beginning,
  profile,
  licence,
  taxable,
  car,
  terms_conditions,
  quiz,
  afip,
  finished,
}

export const Pages = [
  {
    component: BeginningForm,
    title: "Bienvenido",
    handleSubmit: async (uid: Uid, values: any) => {
      //MANDAR MAIL EN UNA HORA SI NO SE COMPLETO NADA
      await CALLABLE_FUNCTIONS.USER_STARTER_FORM();
    },
    initialValues: async (uid: Uid) => ({}),
  },
  {
    component: PersonalForm,
    title: "Datos Personales",
    handleSubmit: async (uid: Uid, values: any) => {
      try {
        const loc = await toLocation(values.address);
        const insertData: PersonalInformation = {
          ...values,
          idType: Number(values.idType),
          birthday: FbDate.fromDate(new Date(values.birthday)),
          address: loc,
          civil_status: Number(values.civil_status),
          genre: Number(values.genre),
        };
        setPersonalInformation(uid, insertData);
        const currentEmail = firebase.auth().currentUser?.email;
        if (currentEmail !== values.email) {
          await firebase
            .firestore()
            .collection("user")
            .doc(uid)
            .update({ email: values.email });
          await firebase.auth().currentUser?.updateEmail(values.email);
        }
        await newZone(uid, { enabled: true, location: loc } as Zone);
      } catch (ex) {
        throw new SubmissionError({ _error: ex });
      }
    },
    initialValues: async (uid: Uid) => {
      const snapData = await firebase
        .firestore()
        .collection("user")
        .doc(uid)
        .collection("personal")
        .doc(uid)
        .get();
      if (snapData.exists) {
        const data = snapData.data() as PersonalInformation;
        return {
          ...data,
          email: firebase.auth().currentUser?.email,
          birthday: data.birthday.toDate(),
          address: locToString(data.address),
        };
      } else {
        const profile = (
          await firebase.firestore().collection("user").doc(uid).get()
        ).data() as User;
        return {
          name: profile.name,
          surname: profile.surname,
          email: profile.email,
          idType: IdType.DNI,
          phone: profile.phone,
        };
      }
    },
  },
  {
    component: LicenseForm,
    title: "Datos de Licencia",
    handleSubmit: (uid: Uid, values: any) =>
      setLicence(uid, {
        ...values,
        category: Number(values.category),
      }),
    initialValues: async (uid: Uid) => {
      const snapData = await firebase
        .firestore()
        .collection("user")
        .doc(uid)
        .collection("licence")
        .doc(uid)
        .get();
      if (snapData.exists) {
        const data = snapData.data() as Licence;
        return {
          ...data,
          expire_date: data.expire_date.toDate(),
        };
      }

      return {};
    },
  },
  {
    component: TaxableForm,
    title: "Datos Impositivos",
    handleSubmit: async (uid: Uid, values: any) => {
      await setTaxableEntity(uid, {
        ...values,
        tax_entity: Number(values.tax_entity),
      });
    },
    initialValues: async (uid: Uid) => {
      const snapData = await firebase
        .firestore()
        .collection("user")
        .doc(uid)
        .collection("taxable")
        .doc(uid)
        .get();
      if (snapData.exists) {
        const data = snapData.data() as TaxableEntity;
        return data;
      } else {
        return {
          tax_entity: Number(TaxableEntityType.Monotributista),
        };
      }
    },
  },
  {
    component: CarForm,
    title: "Datos de Auto",
    handleSubmit: (uid: Uid, values: any) => {
      if (values.vtv_expiration_date) {
        const car: Car = {
          ...values,
          insurance_expiration_date: FbDate.fromDate(
            new Date(values.insurance_expiration_date)
          ),
          vtv_expiration_date: FbDate.fromDate(
            new Date(values.vtv_expiration_date)
          ),
        };
        if (values.id !== undefined) return updateCar(uid, values.id, car);
        else return newCar(uid, car);
      } else {
        const car: Car = {
          ...values,
          insurance_expiration_date: FbDate.fromDate(
            new Date(values.insurance_expiration_date)
          ),
        };
        if (values.id !== undefined) return updateCar(uid, values.id, car);
        else return newCar(uid, car);
      }
    },
    initialValues: async (uid: Uid) => {
      const cars = await database
        .collection("user")
        .doc(uid)
        .collection("car")
        .as(Car);
      if (cars.length > 0) {
        const data = cars[0];
        if (data.vtv_expiration_date) {
          return {
            ...data,
            insurance_expiration_date: data.insurance_expiration_date.toDate(),
            vtv_expiration_date: data.vtv_expiration_date.toDate(),
          };
        } else {
          return {
            ...data,
            insurance_expiration_date: data.insurance_expiration_date.toDate(),
          };
        }
      }
      return {};
    },
  },
  {
    component: TermsAndConditions,
    title: "Términos y Condiciones",
    handleSubmit: async (_: Uid, __: any) => {
      await CALLABLE_FUNCTIONS.USER_ACCEPT_CONTRACT();
    },
    initialValues: async (uid: Uid) => ({}),
  },
  {
    component: QuizForm,
    title: "Capacitación",
    handleSubmit: (uid: Uid, values: any) => null,
    initialValues: async (uid: Uid) => ({}),
  },
  {
    component: AfipForm,
    title: "Gestorando",
    handleSubmit: async (uid: Uid, values: any) => {
      await add_point_of_sale(uid, Number(values.point_of_sale));
    },
    initialValues: async (uid: Uid) => {
      const snapData = await firebase
        .firestore()
        .collection("user")
        .doc(uid)
        .collection("taxable")
        .doc(uid)
        .get();
      if (snapData.exists) {
        const data = snapData.data() as TaxableEntity;
        return {
          point_of_sale: data.point_of_sale,
        };
      }
      return {};
    },
  },
];
