import styled from "styled-components"
import React from 'react'

const ServiceBox = (props : any) => {
    const {title, text, src, alt, className} = props
    return (
        <div className="col-lg-4 col-md-6 col-sm-8 col-xs-12 mb-2">
            <div className={"m-2 p-4 " + className}>
                <div className="section-box-title">
                <img alt={alt} style={{"maxHeight": "50px"}} src={src} /><br />
                <h5>{title}</h5>
                </div>
                <p className="mt-5 mb-5">{text}</p>
            </div>
        </div>

)}

export default styled(ServiceBox)`
    background-color: #00426A;
    height: 100%;
    border-radius: 30px;
    text-align: center;
    h5 {
        color: white;
    }
    p {
        color: white;
    }

`