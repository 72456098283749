import { FIRESTORE } from "constants/firestore";
import moment from "moment";

export const getModule = (startDate: Date) => {
  console.log(moment(startDate).startOf("day").toDate());
  const collection = FIRESTORE.COLLECTION_MODULES_TRANSFER();

  return collection
    .where("destination.time", ">=", moment(startDate).startOf("day").toDate())
    .where("destination.time", "<=", moment(startDate).endOf("day").toDate())
    .orderBy("destination.time", "asc");
};

export const getDistanceFromLatLonInKm = (
  lat1: any,
  lon1: any,
  lat2: any,
  lon2: any
) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
};
const deg2rad = (deg: any) => deg * (Math.PI / 180);
