import { Button, H2, Padding, Spinner, NoData } from "components/styles";
import ROUTES from "constants/routes";
import { WithId } from "controllers/database";
import { getFullAddress } from "controllers/map";
import React, { useEffect, useState, useMemo } from "react";
import { Col, Form, Pagination, Row, Table as TableB } from "react-bootstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ModuleTransfer } from "types/module-transfer";
import { DefinitionsModuleTransferType } from "types/module-transfer";
import { TransferEventMotive } from "types/report";
import { ScheduledTransfer } from "types/transfer";
import { useInterval } from "util/hooks";
import { Paginator } from "util/paginator";
import { collectionFromType, transform } from "./reducer";

export const ArtReportsPage = () => {
  const pageLimit = 15;
  const [_items, setItems] = useState(
    [] as (WithId & ModuleTransfer & { transfer: ScheduledTransfer })[]
  );
  const paginator = useMemo(
    () =>
      new Paginator(
        ModuleTransfer,
        collectionFromType(0),
        [
          "transfers.active",
          "destination.time",
          "origin.time",
          "destination.geohash",
        ],
        pageLimit,
        transform
      ),
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const firstPage = pageNumber === 1;
  const lastPage = _items.length < pageLimit;

  const items = _items.filter(
    (v) => v.transfer.status.active_report !== undefined
  );

  const changeType = (type: number) => {
    paginator.changeCollection(collectionFromType(type));
    updateData();
  };
  const prevPage = () => {
    if (firstPage) return;

    setPageNumber(pageNumber - 1);
    paginator.next();
    updateData();
  };
  const nextPage = () => {
    if (lastPage) return;

    setPageNumber(pageNumber + 1);
    updateData();
  };

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    setLoading(true);
    paginator
      .getDataTransformedWithoutUid()
      .then((a: any) => setItems(a))
      .then(() => setLoading(false));
  };

  const optionsFilter = ["Todos", "Iniciado", "En Origen", "Comenzado"];

  return (
    <Padding pad="5px">
      <Row style={{ marginBottom: "2rem" }}>
        <Col md={3}>
          <H2>Informes Activos</H2>
        </Col>
        <Col>
          <Row>
            <Col md={6} />
            <Col md={6}>
              <Form.Control
                as="select"
                onChange={(event) =>
                  changeType(
                    parseInt((event.target as HTMLTextAreaElement).value)
                  )
                }
              >
                {optionsFilter.map((op, index) => (
                  <option key={index} value={index}>
                    {op}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>
        </Col>
      </Row>
      {loading ? (
        <Spinner />
      ) : items.length > 0 ? (
        <>
          <Table hover>
            <thead>
              <tr>
                <th>Traslado ID</th>
                <th>Modalidad</th>
                <th>Hora en origen</th>
                <th>Fecha</th>
                <th>Paciente</th>
                <th>Origen</th>
                <th>Destino</th>
                <th>Motivo</th>
                <th>Tiempo</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <Td>{item.id}</Td>
                  <Td>{DefinitionsModuleTransferType[item.type]}</Td>
                  <Td>
                    <Moment format="HH:mm">{item.origin.time.toDate()}</Moment>
                  </Td>
                  <Td>
                    <Moment format="DD/MM/YYYY">
                      {item.origin.time.toDate()}
                    </Moment>
                  </Td>
                  <Td>{item.passenger.name}</Td>
                  <Td>{getFullAddress(item.origin)}</Td>
                  <Td>{getFullAddress(item.destination)}</Td>
                  <Td>
                    {
                      TransferEventMotive[
                        item.transfer.status.active_report!.motive
                      ]
                    }
                  </Td>
                  <Td>
                    {
                      <Timer
                        d={
                          item.transfer.status.active_report?.new_date_end
                            ? item.transfer.status.active_report.new_date_end.toDate()
                            : item.transfer.status.active_report!.date_end.toDate()
                        }
                      />
                    }
                  </Td>
                  <Td>
                    <Link to={ROUTES.ART_TRANSFER_VIEW(item.id)}>
                      <Button variant="light">Accionar</Button>
                    </Link>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination style={{ justifyContent: "center" }}>
            {!firstPage && <Pagination.Prev onClick={prevPage} />}
            <Pagination.Item active>{pageNumber}</Pagination.Item>
            {!lastPage && <Pagination.Next onClick={nextPage} />}
          </Pagination>
        </>
      ) : (
        <NoData />
      )}
    </Padding>
  );
};

export const Timer = ({ d }: { d: Date }) => {
  const [time, setTime] = useState<number>(
    ~~((d.getTime() - new Date().getTime()) / 1000)
  );
  const [timeString, setTimeString] = useState<string>("");

  useEffect(() => {
    setTime(~~((d.getTime() - new Date().getTime()) / 1000));
  }, [d]);

  useInterval(() => {
    setTime(time - 1);
    calculateTime();
  }, 1000);

  useEffect(() => {
    setTime(~~((d.getTime() - new Date().getTime()) / 1000));
  }, [d]);

  const calculateTime = () => {
    const hours = ~~(time / 60 / 60);
    let minutes = (~~((time / 60) % 60)).toString();
    if (minutes.length < 2) minutes = "0" + minutes;
    let seconds = (~~(time % 60)).toString();
    if (seconds.length < 2) seconds = "0" + seconds;

    let finalString;

    if (time < 0) finalString = "00:00";
    else {
      if (hours > 0) finalString = `${hours}:${minutes}:${seconds}`;
      else finalString = `${minutes}:${seconds}`;
    }

    setTimeString(finalString);
  };

  return <>{timeString}</>;
};

export const TimerMinutes = ({ d }: { d: Date }) => {
  const [time, setTime] = useState<number>(
    ~~((d.getTime() - new Date().getTime()) / 1000)
  );
  const [timeString, setTimeString] = useState<string>("");

  useEffect(() => {
    setTime(~~((d.getTime() - new Date().getTime()) / 1000));
  }, [d]);

  useInterval(() => {
    setTime(time - 1);
    calculateTime();
  }, 1000);

  useEffect(() => {
    setTime(~~((d.getTime() - new Date().getTime()) / 1000));
  }, [d]);

  const calculateTime = () => {
    const hours = ~~(time / 60 / 60);
    let minutes = (~~((time / 60) % 60)).toString();
    if (minutes.length < 2) minutes = "0" + minutes;
    let seconds = (~~(time % 60)).toString();
    if (seconds.length < 2) seconds = "0" + seconds;

    let finalString;

    if (time < 0) finalString = "00:00";
    else {
      if (hours > 0) finalString = `${hours}:${minutes}:${seconds}`;
      else finalString = `${minutes}`;
    }

    setTimeString(finalString);
  };

  return (
    <button>
      <b>{timeString}</b>
    </button>
  );
};

const Td = styled.td`
  word-wrap: break-word;
`;

const Table = styled(TableB)`
  background-color: #fff;
  table-layout: fixed;
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

const ButtonSearch = styled(Button)`
  background-color: transparent !important;
  color: grey !important;
`;
export default ArtReportsPage;
