import "bootstrap/dist/css/bootstrap.min.css";
import SubRouteAdmin from "components/Admin";
import SubRouteArt from "components/Art";
import Landing from "components/Landing";
import FAQ from "components/Landing/FAQ";
import PrivacyPage from "components/Landing/Privacy";
import TermsAndConditionsPage from "components/Landing/TermsAndConditions";
import SignInPage from "components/Login/SignIn";
import ResetPasswordPage from "components/Login/SignIn/ResetPassword";
import SendInstructionsPage from "components/Login/SignIn/SendInstructions";
import VerificationSuccessPage from "components/Login/SignIn/verification-success";
import SignOut from "components/Login/SignOut";
import SignUpPage from "components/Login/SignUp";
import Microlanding from "components/Passengers/Microlanding";
import SubRouteUser from "components/User";
import { pxToPt } from "constants/css";
import { UserType } from "controllers/user";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ReduxApp } from "redux/app";
import styled from "styled-components";
import ROUTES from "./constants/routes";
import AskReturnWithoutWait from "components/Passengers/AskReturn/without-wait";
import AskReturnWithWait from "components/Passengers/AskReturn/with-wait";
import LateTransferArt from "components/Art/LateTransfers";
import { profileUserSelector } from "redux/selectors";
import CancelCabify from "components/Art/Reports/cancel_cabify";
import ChangeCabify from "components/Art/Reports/change_cabify";
import UnsuscribePage from "components/Landing/unsuscribe";

const App = (props: any) => {
  useEffect(() => {
    document.querySelector(".mask")?.classList.add("mask--hide"); // Loader
    document.querySelector(".loader")?.classList.add("loader--hide"); // Loader
  }, []);

  return (
    <Router>
      <Switch>
        {/*--------------------------  PAGINAS PARA PACIENTE  --------------------------*/}
        <Route path={ROUTES.MICROLANDING()} component={Microlanding} />
        <Route
          path={ROUTES.ASK_RETURN_WITHOUT_WAIT()}
          component={AskReturnWithoutWait}
        />
        <Route
          path={ROUTES.ASK_RETURN_WITH_WAIT()}
          component={AskReturnWithWait}
        />
        <Route path={ROUTES.CANCEL_CABIFY()} component={CancelCabify} />
        <Route path={ROUTES.CHANGE_CABIFY()} component={ChangeCabify} />

        {/*------------------------  PAGINAS PUBLICAS PARA ART  ------------------------*/}
        <Route path={ROUTES.LATE_TRANSFER_ART()} component={LateTransferArt} />

        {/*----------------------------  PAGINAS LANDING  ------------------------------*/}
        <Route exact path={ROUTES.LANDING} component={Landing} />
        <Route exact path={ROUTES.FAQ} component={FAQ} />
        <Route path={ROUTES.PRIVACY} component={PrivacyPage} />
        <Route
          path={ROUTES.TERMS_AND_CONDITIONS}
          component={TermsAndConditionsPage}
        />
        <Route path={ROUTES.UNSUSCRIBE} component={UnsuscribePage} />

        {/*----------------------------  PAGINAS LOGUEO  ------------------------------*/}
        <Route exact path={ROUTES.SIGN_IN} component={SignInPage}>
          {(props) => <SignInPage {...props} />}
        </Route>
        <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_OUT} component={SignOut} />
        <Route path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
        <Route
          path={ROUTES.SEND_INSTRUCTIONS}
          component={SendInstructionsPage}
        />
        <Route
          path={ROUTES.VERIFICATION_SUCCESS}
          component={VerificationSuccessPage}
        />

        {/*--------------------------  PAGINAS YA LOGUEADO  ---------------------------*/}
        <PrivateRoute
          type={UserType.admin}
          path={ROUTES.ADMIN}
          component={SubRouteAdmin}
        />
        <PrivateRoute
          type={UserType.art}
          path={ROUTES.ART}
          component={SubRouteArt}
        />
        <PrivateRoute
          type={UserType.regular}
          path={ROUTES.USER}
          component={SubRouteUser}
        />

        {/*----------------------------  REDIRECCIONAMIENTO  ---------------------------*/}
        <Redirect from="*" to={ROUTES.LANDING} />
      </Switch>
    </Router>
  );
};

const PrivateRoute = ({ component: Component, type, ...rest }: any) => {
  const profile = useSelector(profileUserSelector);
  const userType = profile.type;

  if (!isLoaded(profile)) return <>Autenticando...</>;
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoaded(userType) && userType === type ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.SIGN_IN,
              //state: { from: location } //Mostrar que se necesita loguear para ver la pag
            }}
          />
        )
      }
    />
  );
};

const AppStyled = styled(App)`
  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(${pxToPt(10)} + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default ReduxApp(AppStyled);
