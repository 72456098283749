import firebase from "firebase/app";
import "firebase/storage";
import React, { useEffect, useState } from "react";

const FbFile = ({
  src,
  openOnSamePage,
  children,
}: {
  src: string | undefined;
  children?: any;
  openOnSamePage?: boolean;
}) => {
  const [downloadURL, setDownloadURL] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (src) {
      setLoading(true);
      firebase
        .storage()
        .ref(src)
        .getDownloadURL()
        .then(setDownloadURL)
        .catch((x) => setError(x))
        .then(() => setLoading(false));
    }
  }, []);

  if (loading) return <>Cargando...</>;
  if (error) return <>Error: {error}</>;
  if (downloadURL === undefined) return <>No hay archivo</>;

  return (
    <a
      target={openOnSamePage === undefined ? "_blank" : undefined}
      href={downloadURL}
    >
      {children ? children : "Archivo"}
    </a>
  );
};

export const getStorageFileUrl = (src: string): Promise<string> =>
  firebase.storage().ref(src).getDownloadURL();

export default FbFile;
