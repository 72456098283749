import { dbEntry } from "util/meta";
import { FbDate } from "./transfer";

@dbEntry
export class Car {
  model!: string;
  licence!: string;
  year!: number;
  brand!: string;
  color!: string;
  wheelchair!: boolean;
  engine?: number;

  owner?: CarOwner;
  is_owner!: boolean;

  policy_number!: string;
  insurance_company!: string;
  insurance_number!: number;

  insurance_front?: string; //Photo
  green_card_front?: string; //Photo
  green_card_back?: string; //Photo
  vtv_front?: string; //Photo

  insurance_expiration_date!: FbDate;
  vtv_expiration_date?: FbDate | undefined;
}

export interface CarOwner {
  dni_or_cuit: string;
  name: string;
  blue_card_front: string;
  blue_card_back: string;
}
