import { Button } from 'components/styles';
import { getFullAddress } from 'controllers/map';
import React from 'react';
import { Modal, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { ModuleTransfer } from 'types/module-transfer';
import { DefinitionsModuleTransferType } from 'types/module-transfer';
import { InfoRow, Title } from '../../scheduled';
import _ from 'lodash'
import { GetProperty } from 'components/Admin/Sales/Sales/view-bill';
import { FIRESTORE } from 'constants/firestore';

export const InfoModal = ({ show, handleClose, idModule, module }: 
    { show: boolean, handleClose: any, idModule:string, module:ModuleTransfer }) => (
    <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Información Módulo #{idModule}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Title>Información ART</Title>
            </Row>
            <InfoRow label="Nombre Art" info={<GetProperty doc={FIRESTORE.DOCUMENT_USER(module.art.id)} property='name' />}/>
            <InfoRow label="Siniestro" info={module.art.accident}/>
            <InfoRow label="Código Autorización" info={module.art.authorization || "No especificado"}/>
            <InfoRow label="Código Experta" info={module.art.expert || "No especificado"}/>
            <InfoRow label="NPA" info={module.art.npa || "No especificado"}/>
            <Row>
                <Title>Información Puntos de Encuentro</Title>
            </Row>
            <InfoRow label="Tipo de traslado" info={_.capitalize(DefinitionsModuleTransferType[module.type])}/>
            <InfoRow label="Fecha y Hora Turno" info={<Moment date={module.destination.time.toDate()} format="DD/MM/YYYY HH:mm" />}/>
            <InfoRow label="Hora estimada en origen" info={<Moment date={module.origin.time.toDate()} format="HH:mm" />}/>
            {module.backtrack && <InfoRow label="Hora estimada en retorno" info={<Moment date={module.backtrack.time.toDate()} format="HH:mm" />}/>}
            <InfoRow label="Dirección Origen" info={getFullAddress(module.origin)}/>
            <InfoRow label="Dirección Destino" info={getFullAddress(module.destination)}/>   
            {module.backtrack && <InfoRow label="Dirección Retorno" info={getFullAddress(module.backtrack)}/>}
        </Modal.Body>
        <Modal.Footer>
            <Button style={{textAlign:"center",width:"4rem"}} onClick={handleClose}>Cerrar</Button>
        </Modal.Footer>
    </Modal>
)
