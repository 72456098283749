import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectYears as sy } from "components/input/enums";
import { Field } from "components/input/field";
import ImageInput from "components/input/image";
import { Container, FormControl, H4, Required } from "components/styles";
import {
  futureDate as ft,
  imageRequired,
  minkLength,
  onlyNumbersOrAlphabet,
  required,
} from "controllers/validations";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Fields } from "redux-form";

const futureDate = ft("No puede estar vencida por más de un año");
const selectYears = sy(17);
const min6Length = minkLength(6);
const validateOwner = (value: any, allValues: any, props: any, name: any) => {
  if (!allValues.is_owner) {
    if (name === "owner.dni_or_cuit") return required(value);
    if (name === "owner.name") return required(value);
  }
  return undefined;
};
const validateIdentificationPhotos = (
  value: any,
  allValues: any,
  props: any,
  name: any
) => {
  if (!allValues.is_owner) {
    if (name === "owner.blue_card_front") return imageRequired(value);
  } else {
    if (name === "green_card_front") return imageRequired(value);
  }
  return undefined;
};

export const CarForm = () => {
  return (
    <Container>
      <Fields
        names={["is_owner", "owner.name", "owner.dni_or_cuit"]}
        component={OwnerField}
        validate={validateOwner}
      />

      <H4>Datos del auto</H4>
      <Row>
        <Col xl={4}>
          <Field
            labelinput={
              <>
                <Required /> Marca
              </>
            }
            name="brand"
            type="text"
            validate={required}
            placeholder="Marca. Ejemplo: Chevrolet"
          />
        </Col>
        <Col xl={4}>
          <Field
            labelinput={
              <>
                <Required /> Modelo
              </>
            }
            name="model"
            type="text"
            validate={required}
            placeholder="Modelo. Ejemplo: Onix"
          />
        </Col>
        <Col xl={4}>
          <Field
            labelinput={<> Nro motor</>}
            name="engine"
            type="text"
            placeholder="Nro motor"
          />
        </Col>
        <Col xl={4}>
          <Field
            labelinput={
              <>
                <Required /> Color
              </>
            }
            name="color"
            type="text"
            validate={required}
            placeholder="Color"
          />
        </Col>
        <Col xl={4}>
          <Field
            labelinput={
              <>
                <Required /> Año
              </>
            }
            name="year"
            type="select"
            validate={required}
          >
            {selectYears}
          </Field>
        </Col>
        <Col xl={4}>
          <Field
            labelinput={
              <>
                <Required /> Dominio (sin espacios)
              </>
            }
            name="licence"
            type="text"
            validate={[required, min6Length, onlyNumbersOrAlphabet]}
            placeholder="Nro de patente"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            name="wheelchair"
            type="switch"
            labelLeft="No acepta silla de ruedas"
            labelRight="Acepta silla de ruedas"
          />
        </Col>
      </Row>
      <hr />

      <H4>Datos del seguro</H4>
      <Row>
        <Col>
          <Field
            labelinput={
              <>
                <Required /> Companía del seguro
              </>
            }
            name="insurance_company"
            type="text"
            validate={required}
            placeholder=""
          />

          <Field
            labelinput={
              <>
                <Required /> Número de poliza de seguro
              </>
            }
            name="policy_number"
            type="text"
            validate={required}
            placeholder=""
          />

          <Field
            labelinput={
              <>
                <Required /> Fecha de vencimiento
              </>
            }
            name="insurance_expiration_date"
            type="date-picker"
            //max="9999-01-01"
            validate={[required, futureDate]}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>
              <Required />
              Foto frente poliza de seguro
            </Form.Label>
            <Field
              type="image"
              name="insurance_front"
              validate={imageRequired}
            />
          </Form.Group>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <H4>Documentación del Vehículo</H4>
          <Fields
            names={["green_card_front", "owner.blue_card_front", "is_owner"]}
            component={CarIdentificationPhotos}
            validate={validateIdentificationPhotos}
          />
          <Fields
            names={["green_card_back", "owner.blue_card_back", "is_owner"]}
            component={CarIdentificationBackPhotos}
            validate={validateIdentificationPhotos}
          />
        </Col>
        <Col>
          <H4>Datos de la VTV (Verificación Técnica Vehicular)</H4>
          <Col>
            <Form.Group>
              <Form.Label>Fecha Vencimiento VTV</Form.Label>
              <Field
                name="vtv_expiration_date"
                type="date-picker"
                //max="9999-01-01"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Foto VTV</Form.Label>
              <Field type="image" name="vtv_front" />
            </Form.Group>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

const OwnerField = (fields: any) => {
  return (
    <>
      <Row>
        <Col xs="auto">
          <H4>
            Datos del dueño del auto <FontAwesomeIcon icon={faChevronRight} />
          </H4>
        </Col>
        <Col>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Soy dueño del auto"
              //checked={fields.is_owner.input.value}
              //{...fields.is_owner.input}
              defaultChecked={fields.is_owner.input.value}
              onChange={(e: any) => {
                fields.owner.name.input.onChange(undefined);
                fields.owner.dni_or_cuit.input.onChange(undefined);
                fields.is_owner.input.onChange(e.target.checked);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <Form.Group>
            <Form.Label>
              <Required /> Nombre Completo/Razón Social
            </Form.Label>
            <FormControl
              {...fields.owner.name.input}
              type="text"
              name="name"
              disabled={fields.is_owner.input.value}
              placeholder="Nombre o Razón Social del dueño"
              isInvalid={
                fields.owner.name.meta.touched &&
                fields.owner.dni_or_cuit.meta.invalid
              }
              isValid={
                !fields.is_owner.input.value &&
                fields.owner.name.meta.touched &&
                fields.owner.dni_or_cuit.meta.valid
              }
            />
            <Form.Control.Feedback type="invalid">
              {fields.owner.name.meta.error}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xl={6}>
          <Form.Group>
            <Form.Label>
              <Required /> DNI/CUIT (sin puntos ni espacios){" "}
              {fields.owner.dni_or_cuit.meta.valid}
            </Form.Label>
            <FormControl
              {...fields.owner.dni_or_cuit.input}
              type="text"
              disabled={fields.is_owner.input.value}
              placeholder="DNI o CUIT del dueño"
              isInvalid={
                fields.owner.dni_or_cuit.meta.touched &&
                fields.owner.dni_or_cuit.meta.invalid
              }
              isValid={
                !fields.is_owner.input.value &&
                fields.owner.dni_or_cuit.meta.touched &&
                fields.owner.dni_or_cuit.meta.valid
              }
            />
            <Form.Control.Feedback type="invalid">
              {fields.owner.dni_or_cuit.meta.error}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

const CarIdentificationPhotos = (fields: any) => {
  if (fields.is_owner.input.value)
    return (
      <Form.Group>
        <Form.Label>
          <Required />
          Fernte Cédula Verde
        </Form.Label>
        <ImageInput
          {...fields.green_card_front.input}
          isInvalid={
            fields.green_card_front.meta.invalid &&
            fields.owner.blue_card_front.meta.touched
          }
        />
        <Form.Control.Feedback type="invalid">
          {fields.green_card_front.meta.error}
        </Form.Control.Feedback>
      </Form.Group>
    );

  return (
    <Form.Group>
      <Form.Label>
        <Required />
        Frente Cédula Azul
      </Form.Label>
      <ImageInput
        {...fields.owner.blue_card_front.input}
        isInvalid={
          fields.owner.blue_card_front.meta.invalid &&
          fields.owner.blue_card_front.meta.touched
        }
      />
      <Form.Control.Feedback type="invalid">
        {fields.owner.blue_card_front.meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const CarIdentificationBackPhotos = (fields: any) => {
  if (fields.is_owner.input.value)
    return (
      <Form.Group>
        <Form.Label>
          <Required />
          Dorso Cédula Verde
        </Form.Label>
        <ImageInput
          {...fields.green_card_back.input}
          isInvalid={
            fields.green_card_back.meta.invalid &&
            fields.owner.blue_card_back.meta.touched
          }
        />
        <Form.Control.Feedback type="invalid">
          {fields.green_card_back.meta.error}
        </Form.Control.Feedback>
      </Form.Group>
    );

  return (
    <Form.Group>
      <Form.Label>
        <Required />
        Dorso Cédula Azul
      </Form.Label>
      <ImageInput
        {...fields.owner.blue_card_back.input}
        isInvalid={
          fields.owner.blue_card_back.meta.invalid &&
          fields.owner.blue_card_back.meta.touched
        }
      />
      <Form.Control.Feedback type="invalid">
        {fields.owner.blue_card_back.meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default CarForm;
