import { css, CSSObject, SimpleInterpolation } from "styled-components";

export const pxToPt = (pixels : number) : string => 
    (pixels * 72 / 92) + 'pt'

export enum breakpoints {
    xs = '0px',
    sm = '576px',
    md = '768px',
    lg = '992px',
    xl = '1200px',
}

export const mediaBreakpointUp = (Object.keys(breakpoints) as (keyof typeof breakpoints)[])
    .reduce((accumulator : any, label : keyof typeof breakpoints) => {
	    accumulator[label] = (first: TemplateStringsArray | CSSObject,
            ...interpolations: SimpleInterpolation[]) => css`
		@media (min-width: ${breakpoints[label]}) {
			${css(first, ...interpolations)};
		}
	`;
	return accumulator;
}, {});