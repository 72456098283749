import {
  InfoRow,
  VoucherButton,
} from "components/Admin/Transfers/view/scheduled";
import {
  Button,
  H2,
  NoData,
  Padding,
  Spinner,
  AsyncButton,
} from "components/styles";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Table as TableB } from "react-bootstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  DefinitionsModuleTransferType,
  TypeModuleReject,
} from "types/module-transfer";
import { FinishStatus, FbDate } from "types/transfer";
import MiniNavigationErrors from "../MiniNavigation";
import { getCrossExperta } from "./wrong_amount";
import { BillStatus } from "types/billing";

const AdminTransfersWrongRejectedPage = () => {
  return (
    <MiniNavigationErrors active={4}>
      <MainContainer
        viewTransfer={ROUTES.ADMIN_MODULE_TRANSFER_VIEW}
        art={false}
      />
    </MiniNavigationErrors>
  );
};

export const MainContainer = ({
  viewTransfer,
  art,
}: {
  viewTransfer: (a: any) => string;
  art: boolean;
}) => {
  // const [items, loading, Pagination, setCollection] = usePaginator(
  //   FIRESTORE.COLLECTION_MODULES_TRANSFER()
  //     .where("reject.type", "==", TypeModuleReject["Experta invoice"])
  //     .orderBy("origin.time", "desc"),
  //   800
  // );
  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showSolutionModal, setShowSolutionModal] = useState(false);
  const [module, setModule] = useState<any>("");
  const [accidente, setAccidente] = useState<any>("");
  const [selected, setSelected] = useState<any[]>([]);
  const [listOfPricesCross, setListOfPricesCross] = useState<any[]>([]);
  const [reload, setReload] = useState(false);
  const initialSet = () => {
    setLoading(true);
    FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("reject.type", "==", TypeModuleReject["Experta invoice"])
      .get()
      .then((data) => data.docs)
      .then((docs) =>
        docs
          .map((s) => ({ ...s.data(), id: s.id }))
          .filter(
            (a) =>
              a.sale?.billed !== BillStatus["No facturar"] &&
              (a.status.finished_status === FinishStatus.Exitoso ||
                a.status.finished_status === FinishStatus.Negativo)
          )
          .sort((a, b) => {
            if (
              a.origin_location?.id === b.origin_location?.id &&
              a.destination_location?.id === b.destination_location?.id
            ) {
              return (
                b.destination.time.toMillis() - a.destination.time.toMillis()
              );
            } else {
              return (
                Number(a.origin_location?.id) - Number(b.origin_location?.id)
              );
            }
          })
      )
      .then((sI) => {
        setItems(sI);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (items.length > 0) {
      const itemsCross = items.filter(
        (item) => item.origin.time < FbDate.fromDate(new Date("12/05/2022"))
      );

      const itemsKm = items.filter(
        (item) => item.origin.time >= FbDate.fromDate(new Date("12/05/2022"))
      );

      FIRESTORE.COLLECTION_CROSS_EXPERTA()
        .get()
        .then((data) => data.docs)
        .then((docs) => docs.map((doc) => doc.data()))
        .then((crossArray) => {
          getListOfPricesCross(itemsCross, crossArray);
        });
    }
  }, [items]);

  const getListOfPricesCross = (items: any[], crossArray: any[]) => {
    const arr: any[] = [];

    items.map((item) => {
      let multiply = 1;
      if (
        item!.origin.time.toDate() >= new Date("2021/04/01") &&
        item!.origin.time.toDate() < new Date("2021/06/01")
      ) {
        multiply = 1.14;
      } else if (
        item!.origin.time.toDate() >= new Date("2021/06/01") &&
        item!.origin.time.toDate() < new Date("2021/07/01")
      ) {
        multiply = 1.14 * 1.13;
      } else if (
        item!.origin.time.toDate() >= new Date("2021/07/01") &&
        item!.origin.time.toDate() < new Date("2021/10/01")
      ) {
        multiply = 1.14 * 1.13 * 1.08;
      } else if (
        item!.origin.time.toDate() >= new Date("2021/10/01") &&
        item!.origin.time.toDate() < new Date("2022/01/01")
      ) {
        multiply = 1.14 * 1.13 * 1.08 * 1.06;
      } else if (
        item!.origin.time.toDate() >= new Date("2022/01/01") &&
        item!.origin.time.toDate() < new Date("2022/04/01")
      ) {
        multiply = 1.14 * 1.13 * 1.08 * 1.06 * 1.075;
      }
      const price = getCrossExperta(
        item.origin_location,
        item.destination_location,
        item.status.finished_status == 2 ? 2 : item.type == 0 ? 0 : 1,
        item.origin.time,
        crossArray
      );
      arr.push({ price: Math.floor(price! * multiply), id: item.id });
    });
    setListOfPricesCross(arr);
  };

  const resolveJointLiquidation = async () => {
    const dato: { accidente: string; module: string }[] = [];

    await Promise.all(
      items.map(async (item) => {
        if (
          item.reject!.expertaInvoice!.some(function (v: any) {
            return v.indexOf("El viaje ya fue liquidado") >= 0;
          })
        ) {
          dato.push({
            accidente: item.art.accident,
            module: item.id,
          });
        }
      })
    );

    const billsData = (
      await FIRESTORE.COLLECTION_ARTS_INVOICES()
        .where("canceled", "!=", true)
        .get()
    ).docs.map((f) => ({ data: f.data(), ref: f.ref }));

    const dataUpdate: {
      moduleId: string;
      facturaId: string;
      factura: string | number;
      pdf: string;
    }[] = [];
    dato.map((obj) => {
      const bills = billsData.filter((bill) =>
        bill.data.keywords.some((keywords) => keywords === obj.accidente)
      );

      let found = false;
      for (let i = 0; i < bills.length; i++) {
        if (found) {
          break;
        }
        const bill = bills[i];
        const billedModules = bill.data.modules;

        for (let j = 0; j < billedModules.length; j++) {
          const moduleInTheBill = billedModules[j];
          if (moduleInTheBill.id_module === obj.module) {
            dataUpdate.push({
              moduleId: obj.module,
              facturaId: bill.ref.id,
              factura: bill.data.voucher_number,
              pdf: bill.data.pdf ? bill.data.pdf : "",
            });

            found = true;

            break;
          }
        }
      }
    });

    dataUpdate.map(async (item) => {
      const facturaRef = await FIRESTORE.DOCUMENT_ART_INVOICE(
        item.facturaId
      ).get();
      const dataUpdateBis = {
        reject: firebase.firestore.FieldValue.delete(),
        "sale.billed": BillStatus.Si,
        "sale.bill_info": {
          pdf: item.pdf ? item.pdf : "",
          ref: facturaRef.ref,
          missing: item.pdf ? false : true,
          creation_date: facturaRef.data()?.creation_date,
        },
      };

      await FIRESTORE.DOCUMENT_MODULE_TRANSFER(item.moduleId).update(
        dataUpdateBis
      );
    });
  };

  useEffect(() => {
    initialSet();
  }, [reload]);

  return (
    <Padding pad="5px">
      <Row>
        <Col md={2}>
          <H2>Factura Rechazada</H2>
        </Col>
        <Col md={2}>
          <AsyncButton
            className="pull-rigth"
            onClick={async () => {
              await moveMultiple(selected);
              setReload(!reload);
            }}
          >
            Mover seleccionados
          </AsyncButton>
        </Col>
        <Col md={2}>
          <AsyncButton
            disabled={
              items.map((item) => {
                item.reject!.expertaInvoice!.some(function (v: any) {
                  return v.indexOf("El viaje ya fue liquidado") >= 0;
                });
              })
                ? false
                : true
            }
            variant="secondary"
            onClick={async () => {
              await resolveJointLiquidation();
              setReload(!reload);
            }}
          >
            Resolver Liquidación
          </AsyncButton>
        </Col>
      </Row>

      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        <Table hover responsive>
          <thead>
            <Tr>
              <th>Siniestro-Aut/NPA</th>
              <th>SML ID</th>
              <th>Fecha</th>
              <th>Modalidad</th>
              <th>ART</th>
              <th>Errores</th>
              <th>Cruce Experta</th>
              <th>Valor ART pactado</th>
              <th>Ver</th>
              <th>Mover</th>
              <th>Agregar</th>
              <th>Liquidación</th>
            </Tr>
          </thead>
          <tbody>
            {items.map((item) => {
              const resultC = listOfPricesCross.filter((price) => {
                return price.id == item.id;
              });

              return (
                <tr key={item.id}>
                  <Td style={{ wordBreak: "break-all" }}>
                    {item.art.accident && item.art.authorization
                      ? item.art.accident + "-" + item.art.authorization
                      : item.art.accident
                      ? item.art.accident
                      : "NPA: " + item.art.npa}
                  </Td>
                  <Td>{item.transfer_id}</Td>
                  <Td>
                    <Moment
                      date={item.origin.time.toDate()}
                      format="DD/MM/YYYY HH:mm"
                    />
                  </Td>
                  <Td>{DefinitionsModuleTransferType[item.type]}</Td>
                  <Td>{item.art.name}</Td>
                  <Td>
                    <ul>
                      {item.reject?.expertaInvoice?.map((error: string) => {
                        return (
                          <>
                            <li key={error}>{error}</li>
                          </>
                        );
                      })}
                    </ul>
                  </Td>
                  <Td>
                    {item.origin_location?.id +
                      " , " +
                      item.destination_location?.id +
                      " " +
                      item.origin_location?.description +
                      " - " +
                      item.destination_location?.description}
                  </Td>
                  <Td>
                    {resultC[0]?.price !== undefined && resultC.length > 0
                      ? Number(resultC[0].price).toFixed(2)
                      : 0}
                  </Td>
                  <Td>
                    <Link to={viewTransfer(item.id)}>
                      <Button variant="light">Ver</Button>
                    </Link>
                  </Td>

                  <Td>
                    {item.reject!.expertaInvoice!.some(function (v: any) {
                      return v.indexOf("Precio del cruce exedido:") >= 0;
                    }) ? (
                      <Button
                        onClick={async () => {
                          await move(item.id);
                          setReload(!reload);
                        }}
                        variant="light"
                      >
                        Mover
                      </Button>
                    ) : item.reject!.expertaInvoice!.some(function (v: any) {
                        return (
                          v.indexOf("El importe del negativo debe ser") >= 0
                        );
                      }) ? (
                      <Button
                        onClick={async () => {
                          await move(item.id);
                          setReload(!reload);
                        }}
                        variant="light"
                      >
                        Mover
                      </Button>
                    ) : item.reject!.expertaInvoice!.some(function (v: any) {
                        return v.indexOf("Precio del KM exedido") >= 0;
                      }) ? (
                      <Button
                        onClick={async () => {
                          await move(item.id);
                          setReload(!reload);
                        }}
                        variant="light"
                      >
                        Mover
                      </Button>
                    ) : item.reject!.expertaInvoice!.some(function (v: any) {
                        return (
                          v.indexOf("Precio del cruce no coincide con los") >= 0
                        );
                      }) ? (
                      <Button
                        onClick={async () => {
                          await move(item.id);
                          setReload(!reload);
                        }}
                        variant="light"
                      >
                        Mover
                      </Button>
                    ) : item.reject!.expertaInvoice!.some(function (v: any) {
                        return (
                          v.indexOf(
                            "Precio del cruce no puede superar precio minimo por tramo"
                          ) >= 0
                        );
                      }) ? (
                      <Button
                        onClick={async () => {
                          await move(item.id);
                          setReload(!reload);
                        }}
                        variant="light"
                      >
                        Mover
                      </Button>
                    ) : item.reject!.expertaInvoice!.some(function (v: any) {
                        return v.indexOf("Precio MIN exedido:") >= 0;
                      }) ? (
                      <Button
                        onClick={async () => {
                          await move(item.id);
                          setReload(!reload);
                        }}
                        variant="light"
                      >
                        Mover
                      </Button>
                    ) : (
                      ""
                    )}
                  </Td>
                  <Td>
                    {!selected.some((select) => select.id == item.id) &&
                    item.reject!.expertaInvoice!.some(function (v: any) {
                      return v.indexOf("Precio del KM exedido:") >= 0;
                    }) ? (
                      <Button
                        onClick={() => {
                          const z = [...selected];
                          z.push(item);
                          setSelected(z);
                        }}
                      >
                        Agregar
                      </Button>
                    ) : !selected.some((select) => select.id == item.id) &&
                      item.reject!.expertaInvoice!.some(function (v: any) {
                        return v.indexOf("Precio MIN exedido:") >= 0;
                      }) ? (
                      <Button
                        onClick={() => {
                          const z = [...selected];
                          z.push(item);
                          setSelected(z);
                        }}
                      >
                        Agregar
                      </Button>
                    ) : !selected.some((select) => select.id == item.id) &&
                      item.reject!.expertaInvoice!.some(function (v: any) {
                        return (
                          v.indexOf("El importe del negativo debe ser") >= 0
                        );
                      }) ? (
                      <Button
                        onClick={() => {
                          const z = [...selected];
                          z.push(item);
                          setSelected(z);
                        }}
                      >
                        Agregar
                      </Button>
                    ) : !selected.some((select) => select.id == item.id) &&
                      item.reject!.expertaInvoice!.some(function (v: any) {
                        return (
                          v.indexOf("Precio del cruce no puede superar") >= 0
                        );
                      }) ? (
                      <Button
                        onClick={() => {
                          const z = [...selected];
                          z.push(item);
                          setSelected(z);
                        }}
                      >
                        Agregar
                      </Button>
                    ) : !selected.some((select) => select.id == item.id) &&
                      item.reject!.expertaInvoice!.some(function (v: any) {
                        return v.indexOf("Precio del cruce exedido") >= 0;
                      }) ? (
                      <Button
                        onClick={() => {
                          const z = [...selected];
                          z.push(item);
                          setSelected(z);
                        }}
                      >
                        Agregar
                      </Button>
                    ) : !selected.some((select) => select.id == item.id) &&
                      item.reject!.expertaInvoice!.some(function (v: any) {
                        return (
                          v.indexOf("Precio del cruce no coincide con los") >= 0
                        );
                      }) ? (
                      <Button
                        onClick={() => {
                          const z = [...selected];
                          z.push(item);
                          setSelected(z);
                        }}
                      >
                        Agregar
                      </Button>
                    ) : item.reject!.expertaInvoice!.some(function (v: any) {
                        return v.indexOf("Precio del KM exedido:") >= 0;
                      }) ? (
                      <Button
                        variant="danger"
                        onClick={() => {
                          const z = [...selected];
                          z.splice(
                            z.findIndex((element) => element.id == item.id),
                            1
                          );
                          setSelected(z);
                        }}
                      >
                        Quitar
                      </Button>
                    ) : !selected.some((select) => select.id == item.id) &&
                      item.reject!.expertaInvoice!.some(function (v: any) {
                        return v.indexOf("Precio MIN exedido:") >= 0;
                      }) ? (
                      <Button
                        variant="danger"
                        onClick={() => {
                          const z = [...selected];
                          z.splice(
                            z.findIndex((element) => element.id == item.id),
                            1
                          );
                          setSelected(z);
                        }}
                      >
                        Quitar
                      </Button>
                    ) : item.reject!.expertaInvoice!.some(function (v: any) {
                        return (
                          v.indexOf("El importe del negativo debe ser") >= 0
                        );
                      }) ? (
                      <Button
                        variant="danger"
                        onClick={() => {
                          const z = [...selected];
                          z.splice(
                            z.findIndex((element) => element.id == item.id),
                            1
                          );
                          setSelected(z);
                        }}
                      >
                        Quitar
                      </Button>
                    ) : item.reject!.expertaInvoice!.some(function (v: any) {
                        return (
                          v.indexOf("Precio del cruce no puede superar") >= 0
                        );
                      }) ? (
                      <Button
                        variant="danger"
                        onClick={() => {
                          const z = [...selected];
                          z.splice(
                            z.findIndex((element) => element.id == item.id),
                            1
                          );
                          setSelected(z);
                        }}
                      >
                        Quitar
                      </Button>
                    ) : item.reject!.expertaInvoice!.some(function (v: any) {
                        return v.indexOf("Precio del cruce exedido") >= 0;
                      }) ? (
                      <Button
                        variant="danger"
                        onClick={() => {
                          const z = [...selected];
                          z.splice(
                            z.findIndex((element) => element.id == item.id),
                            1
                          );
                          setSelected(z);
                        }}
                      >
                        Quitar
                      </Button>
                    ) : item.reject!.expertaInvoice!.some(function (v: any) {
                        return (
                          v.indexOf("Precio del cruce no coincide con los") >= 0
                        );
                      }) ? (
                      <Button
                        variant="danger"
                        onClick={() => {
                          const z = [...selected];
                          z.splice(
                            z.findIndex((element) => element.id == item.id),
                            1
                          );
                          setSelected(z);
                        }}
                      >
                        Quitar
                      </Button>
                    ) : (
                      ""
                    )}
                  </Td>
                  <Td>
                    {item.reject!.expertaInvoice!.some(function (v: any) {
                      return v.indexOf("El viaje ya fue liquidado") >= 0;
                    }) ? (
                      <Button
                        variant="light"
                        onClick={() => {
                          //searchInvoices(item.id, item.art.accident);
                          setAccidente(item.art.accident);
                          setModule(item.id);
                          //setSolutionModal(invoices);
                          setShowSolutionModal(true);
                        }}
                      >
                        Liquidación
                      </Button>
                    ) : (
                      ""
                    )}
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <SolutionLiquidationModal
        accident={accidente}
        module={module}
        show={showSolutionModal}
        handleClose={() => {
          setShowSolutionModal(false);
        }}
        setCollection={() => {
          setReload(!reload);
        }}
      />
    </Padding>
  );
};
const move = async (id: string) => {
  const mod = await FIRESTORE.DOCUMENT_MODULE_TRANSFER(id).get();
  const modul = (await FIRESTORE.DOCUMENT_MODULE_TRANSFER(id).get()).data();
  const kmModul = modul!.info.distance_origin_destination;
  const kmBerifArray = modul?.reject?.expertaInvoice!.map(function (v: any) {
    if (v.indexOf("el calculo de") >= 0) {
      const inicio = v.indexOf("el calculo de") + 14;
      const fin = v.indexOf("por tramo,") - 3;
      return v.slice(inicio, fin);
    }
  });
  let kmBerif: number = 0;
  kmBerifArray.map((a) => {
    console.log(a, "a");

    if (a !== undefined) {
      kmBerif = (Number(a) + 2.5) * 1000;
    }
  });
  const difeKm = kmModul - kmBerif;
  console.log(kmBerif, "aqui!", difeKm);
  if (kmModul < kmBerif) {
    console.log("updeteamos los km", kmBerif);
    await mod.ref.update({
      reject: {
        status: true,
        type: 3,
      },
      "info.distance_origin_destination": kmBerif,
    });
  } else if (difeKm < 100) {
    console.log("entra");

    await mod.ref.update({
      reject: {
        status: true,
        type: 3,
      },
      "info.distance_origin_destination": kmBerif,
    });
  } else {
    console.log("solo sacamos el reject ");
    await mod.ref.update({
      reject: {
        status: true,
        type: 3,
      },
    });
  }
};

const moveMultiple = async (arr: any[]) => {
  for (let i = 0; i < arr.length; i++) {
    const mod = (await FIRESTORE.DOCUMENT_MODULE_TRANSFER(arr[i].id).get()).ref;
    const modul = (
      await FIRESTORE.DOCUMENT_MODULE_TRANSFER(arr[i].id).get()
    ).data();
    const kmModul = modul!.info.distance_origin_destination;
    console.log(modul!.reject!.expertaInvoice!, "q es?");

    const kmBerifArray = modul?.reject?.expertaInvoice?.map(function (v: any) {
      if (v.indexOf("el calculo de") >= 0) {
        const inicio = v.indexOf("el calculo de") + 14;
        const fin = v.indexOf("por tramo,") - 3;
        return v.slice(inicio, fin);
      }
    });
    let kmBerif: number = 0;
    kmBerifArray?.map((a) => {
      console.log(a, "a");

      if (a !== undefined) {
        kmBerif = (Number(a) + 2.5) * 1000;
      }
    });
    const difeKm = kmModul - kmBerif;
    console.log(kmBerif, "aqui!", difeKm);
    if (kmModul < kmBerif) {
      console.log("updeteamos los km", kmBerif);
      await mod.update({
        reject: {
          status: true,
          type: 3,
        },
        "info.distance_origin_destination": kmBerif,
      });
    } else if (difeKm < 100) {
      console.log("entra");

      await mod.update({
        reject: {
          status: true,
          type: 3,
        },
        "info.distance_origin_destination": kmBerif,
      });
    } else {
      console.log("solo sacamos el reject ");
      await mod.update({
        reject: {
          status: true,
          type: 3,
        },
      });
    }
  }
};

const SolutionLiquidationModal = ({
  module,
  accident,
  show,
  handleClose,
  setCollection,
}: {
  module: string;
  accident: string;
  show: boolean;
  handleClose: any;
  setCollection?: any;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [factura, setFactura] = useState<any>("");
  const [facturaId, setFacturaId] = useState<any>("");
  const [pdf, setPdf] = useState<any>("");
  async function searchInovice() {
    setLoading(true);
    let found = false;
    const bills = (
      await FIRESTORE.COLLECTION_ARTS_INVOICES()
        .where("canceled", "!=", true)
        .where("keywords", "array-contains", accident)
        .get()
    ).docs.map((f) => ({ data: f.data(), ref: f.ref }));
    for (let i = 0; i < bills.length; i++) {
      if (found) {
        break;
      }
      const bill = bills[i];
      const billedModules = bill.data.modules;
      for (let j = 0; j < billedModules.length; j++) {
        const moduleInTheBill = billedModules[j];
        if (moduleInTheBill.id_module === module) {
          setFacturaId(bill.ref.id);
          setFactura(bill.data.voucher_number);
          setPdf(bill.data.pdf);
          found = true;
          break;
        }
      }
    }

    setLoading(false);
  }

  console.log(factura, facturaId, "factura y id");

  const resolveLiquidation = async () => {
    const moduleRef = await FIRESTORE.DOCUMENT_MODULE_TRANSFER(module).get();
    const facturaRef = await FIRESTORE.DOCUMENT_ART_INVOICE(facturaId).get();
    await moduleRef.ref.update({
      reject: firebase.firestore.FieldValue.delete(),
      "sale.billed": BillStatus.Si,
      "sale.bill_info": {
        pdf: pdf ? pdf : "",
        ref: facturaRef.ref,
        missing: pdf ? false : true,
        creation_date: facturaRef.data()?.creation_date,
      },
    });
    setSuccess(true);
  };

  const closeAndReset = () => {
    setFactura("");
    setFacturaId("");
    setPdf("");
    handleClose();
    if (success) {
      setCollection();
      setSuccess(false);
    }
  };
  useEffect(() => {
    if (show) searchInovice();
  }, [show]);
  return (
    <Modal show={show} onHide={closeAndReset} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Revisar Liquidación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <p>Se resolvió exitosamente la liquidación!</p>
        ) : loading ? (
          <Spinner />
        ) : (
          <>
            <Row>
              <Col>
                <InfoRow
                  label={
                    factura
                      ? `Viaje ya liquidado en Fac ${factura}`
                      : `Factura de liquidación no encontrada`
                  }
                  info={
                    pdf ? (
                      <VoucherButton src={pdf} text="Factura" />
                    ) : factura ? (
                      <Button
                        variant="secondary"
                        disabled
                        className="pull-right"
                      >
                        FACTURADO SIN PDF
                      </Button>
                    ) : (
                      <Button
                        variant="secondary"
                        disabled
                        className="pull-right"
                      >
                        SIN FACTURA
                      </Button>
                    )
                  }
                />
              </Col>
            </Row>
            <Row>
              {factura ? (
                <Button
                  className="pull-right"
                  style={{ margin: "0 10px 5px" }}
                  onClick={() => resolveLiquidation()}
                >
                  Resolver
                </Button>
              ) : (
                <Button
                  disabled
                  className="pull-right"
                  style={{ margin: "0 10px 5px" }}
                  onClick={() => resolveLiquidation()}
                >
                  Resolver
                </Button>
              )}
            </Row>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeAndReset}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Tr = styled.tr`
  th {
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  word-wrap: break-word;
`;

const Table = styled(TableB)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default AdminTransfersWrongRejectedPage;
