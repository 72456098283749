import { Button, Padding, Spinner, NoData, H2 } from "components/styles";
import { getFullAddress } from "controllers/map";
import React, { useState, useEffect } from "react";
import { Card, Col, Row, Table as BTable, Form } from "react-bootstrap";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { uidSelector } from "redux/selectors";
import styled from "styled-components";
import { Zone } from "../Zones/reducer";
import moment from "moment";
import { usePaginator } from "util/hooks";
import { FIRESTORE } from "constants/firestore";
import { ModuleTransfer } from "types/module-transfer";
import { ExtraAdditionals } from "components/Admin/Transfers/view/unassigned";
import { BillStatus, BillInfoTransfer } from "types/billing";
import { DatePickerInput } from "components/input/date-picker";
import { WithId } from "controllers/database";

const UserPayments = () => {
  const uid = useSelector(uidSelector);
  const [items, setItems] = useState<(BillInfoTransfer & WithId)[]>();
  const [loading, setLoading] = useState<boolean>(false);
  //const [concept, setConcept] = useState();

  useEffect(() => {
    setLoading(true);
    (async function () {
      let bills = (
        await FIRESTORE.COLLECTION_DRIVER_INVOICES()
          .where("driver", "==", uid)
          .get()
      ).docs.map((bill) => ({ ...bill.data(), id: bill.id }));
      bills = bills.filter((bill) => !!bill.payment_order);
      bills.sort((a, b) => Number(a.voucher_number) - Number(b.voucher_number));
      setItems(bills);
    })();
    //getAllData();
    setLoading(false);
    //FIRESTORE.COLLECTION_DRIVER_INVOICES()
    //   .where("driver", "==", uid)
    //   .get()
    //   .then((d) => d.docs)
    //   .then((docs) => docs.map((d) => ({ ...d.data(), id: d.id })))
    //   .then((d) => d.filter((doc) => doc.payment_order !== null))
    //     .then((docsData) => docsData.map(doc=>{

    //     })
    //   .then((docs) => setItems(docs))
    //   .finally(() => setLoading(false));
  }, []);

  //useEffect(() => {}, []);
  return (
    <Padding pad="5px">
      <Row>
        <Col md={12}>
          <H2>Historial de Pagos</H2>
        </Col>
      </Row>

      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items !== undefined && items.length === 0 ? (
        <NoData />
      ) : (
        <Table hover responsive>
          <thead>
            <Tr>
              <th>Fch Orden Pago</th>
              <th>Orden de Pago</th>
              <th>Descripción</th>
              <th>Fecha Factura</th>
              <th>Factura</th>
              <th>Monto Fc</th>
              <th>Siniestro</th>
            </Tr>
          </thead>
          <tbody>
            {items !== undefined &&
              items.map(
                (item) =>
                  item !== undefined && (
                    <tr key={item.id}>
                      <Td>
                        <GetOrderData bill={item} value={"creation_date"} />
                      </Td>
                      <Td>
                        <GetOrderData bill={item} value={"orden_pago_int"} />
                      </Td>
                      <Td>
                        <GetOrderData bill={item} value={"concept2"} />
                      </Td>
                      <Td>
                        {moment(item.creation_date.toDate()).format(
                          "DD-MM-YYYY"
                        )}
                      </Td>
                      <Td>{item.voucher_number}</Td>
                      <Td>${item.ImpTotal}</Td>
                      <Td>
                        {item.keywords[3] !== undefined
                          ? item.keywords[2] + "-" + item.keywords[3]
                          : item.keywords[2]}
                      </Td>
                    </tr>
                  )
              )}
          </tbody>
        </Table>
      )}
    </Padding>
  );
};

const GetOrderData = ({
  bill,
  value,
}: {
  bill: BillInfoTransfer & WithId;
  value: string;
}) => {
  const [object, setObject] = useState<any>();
  useEffect(() => {
    bill
      .payment_order!.get()
      .then((d: any) => d.data())
      .then((data: any) => setObject(data));
  }, []);
  return (
    <>
      {value === "creation_date"
        ? object && moment(object[value].toDate()).format("DD-MM-YYYY")
        : object && object[value]}
    </>
  );
};

const Tr = styled.tr`
  th {
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  height: 10px;
`;

const Table = styled(BTable)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;
export default UserPayments;
