import { DatePickerInput } from "components/input/date-picker";
import {
  AdminTable,
  Button,
  ContainerPage,
  H2,
  Link,
  NoData,
  QuestionTooltip,
  Spinner,
} from "components/styles";
import ROUTES from "constants/routes";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Moment from "react-moment";
import { ArtBill } from "types/billing";
import { usePaginator } from "util/hooks";
import { ArtSelector } from "./generate-bill";
import {
  getArtInvoicesCanceled,
  getArtInvoicesDebited,
  getArtInvoicesNotCanceled,
  getArtInvoicesWithError,
  getArtModulesNotForBill,
  getArtModulesNonCompliance,
} from "./reducer";
import { GetProperty } from "./view-bill";
import { FIRESTORE } from "constants/firestore";
import ExcelJS from "exceljs";
import * as fs from "file-saver";
import { FbDate, ScheduledTransfer } from "types/transfer";
import {
  LocationModule,
  ModuleTransferType,
  ModuleTransfer,
} from "types/module-transfer";
import { getStorageFileUrl } from "components/Primitives/File";
import * as crossExperta from "./cruces_experta.json";

const Sales = () => {
  const [keyword, setKeyword] = useState<string | undefined>(undefined);
  const [artSelected, setArtSelected] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(5, "months").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(moment().toDate());
  const [items, loading, Pagination, setCol] = usePaginator<ArtBill>(
    getArtInvoicesNotCanceled(artSelected, startDate, endDate, keyword)
  );
  const [items2, loading2, Pagination2, setCol2] = usePaginator<ArtBill>(
    getArtInvoicesCanceled(artSelected, startDate, endDate, keyword)
  );
  const [items3, loading3, Pagination3, setCol3] = usePaginator<ArtBill>(
    getArtInvoicesDebited(artSelected, startDate, endDate, keyword)
  );
  const [items4, loading4, Pagination4, setCol4] = usePaginator<ArtBill>(
    getArtInvoicesWithError(artSelected, startDate, endDate, keyword)
  );
  const [items5, loading5, Pagination5, setCol5] = usePaginator<ModuleTransfer>(
    getArtModulesNotForBill(artSelected, startDate, endDate, keyword)
  );
  const [items6, loading6, Pagination6, setCol6] = usePaginator<ModuleTransfer>(
    getArtModulesNonCompliance(artSelected, startDate, endDate, keyword)
  );
  const [filter, setFilter] = useState("Facturas venta");

  useEffect(() => {
    if (filter === "Facturas venta") {
      setCol(
        getArtInvoicesNotCanceled(artSelected, startDate, endDate, keyword)
      );
    } else if (filter === "Facturas canceladas") {
      setCol2(getArtInvoicesCanceled(artSelected, startDate, endDate, keyword));
    } else if (filter === "Notas de débito") {
      setCol3(getArtInvoicesDebited(artSelected, startDate, endDate, keyword));
    } else if (filter === "Error al facturar") {
      setCol4(
        getArtInvoicesWithError(artSelected, startDate, endDate, keyword)
      );
    } else if (filter === "No facturable") {
      setCol5(
        getArtModulesNotForBill(artSelected, startDate, endDate, keyword)
      );
    } else if (filter === "Incumplimiento") {
      setCol6(
        getArtModulesNonCompliance(artSelected, startDate, endDate, keyword)
      );
    }
  }, [artSelected, startDate, endDate, keyword]);

  return (
    <ContainerPage>
      <Row style={{ marginBottom: 15 }}>
        <Col md={3}>
          <H2>Facturas Art</H2>
        </Col>
        <Col>
          <Link to={ROUTES.ADMIN_SALES_SALES_GENERATE_BILL}>
            <Button className="pull-right">Generar/Ingresar Factura</Button>
          </Link>
          <Link to={ROUTES.ADMIN_SALES_SALES_IIBB}>
            <Button className="pull-right" style={{ marginRight: 15 }}>
              Liquidación IIBB
            </Button>
          </Link>
          <Button
            className="pull-right"
            style={{ marginRight: 15 }}
            onClick={generateExcel}
          >
            Generar Excel
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xl={3}>
          <ArtSelector
            artSelected={artSelected}
            setArtSelected={setArtSelected}
            label="Seleccionar Cliente"
          />
        </Col>
        <Col xl={3}>
          <Form.Group>
            <Form.Label>Desde</Form.Label>
            <DatePickerInput value={startDate} onChange={setStartDate} />
          </Form.Group>
        </Col>
        <Col xl={3}>
          <Form.Group>
            <Form.Label>Hasta (Inclusive)</Form.Label>
            <DatePickerInput value={endDate} onChange={setEndDate} />
          </Form.Group>
        </Col>
        <Col xl={3}>
          <Form.Group>
            <Form.Label>
              Buscador{" "}
              <QuestionTooltip text="Número Factura, Siniestro/s, NPA/s" />
            </Form.Label>
            <Form.Control
              value={keyword}
              onChange={(v) => setKeyword(v.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>

      <Form.Control
        as="select"
        value={filter}
        onChange={(event) => setFilter(event.target.value)}
      >
        <option key={0} value={"Facturas venta"}>
          Facturas venta
        </option>
        <option key={1} value={"Facturas canceladas"}>
          Facturas canceladas
        </option>
        <option key={2} value={"Notas de débito"}>
          Notas de débito
        </option>
        <option key={3} value={"Error al facturar"}>
          Error al facturar
        </option>
        <option key={4} value={"No facturable"}>
          No facturable
        </option>
        <option key={5} value={"Incumplimiento"}>
          Incumplimiento
        </option>
      </Form.Control>

      {loading || loading2 || loading3 || loading4 || loading5 || loading6 ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : filter === "Facturas venta" ? (
        <>
          <AdminTable responsive>
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Número de Factura</th>
                <th>Fecha facturado</th>
                <th>Importe</th>
                <th>Mensaje Api</th>
                <th>Ver Factura</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.id}>
                  <td>
                    <GetProperty
                      doc={FIRESTORE.DOCUMENT_USER(item.art)}
                      property="name"
                    />
                  </td>
                  <td>
                    {item.point_of_sale}-{item.voucher_number}
                  </td>
                  <td>
                    <Moment
                      date={item.creation_date.toDate()}
                      format="DD/MM/YYYY HH:mm"
                    />
                  </td>
                  <td>
                    {"$" +
                      item.ImpTotal.toFixed(2).toString().split(".").join(",")}
                  </td>
                  <td>
                    {item.respuesta_api
                      ? `${item.respuesta_api?.status} - ${item.respuesta_api?.texto}`
                      : null}
                  </td>
                  <td>
                    <Link to={ROUTES.ADMIN_SALES_SALES_ART_BILL_INFO(item.id)}>
                      <Button variant="light">Ver Factura</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </AdminTable>
          <Pagination />
        </>
      ) : filter === "Facturas canceladas" ? (
        <>
          <AdminTable responsive>
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Factura No.</th>
                <th>Fecha facturado</th>
                <th>Nota de Crédito No.</th>
                <th>Fecha Factura cancelada</th>
                <th>Ver Factura</th>
              </tr>
            </thead>
            <tbody>
              {items2.map((item) => (
                <tr key={item.id}>
                  <td>
                    <GetProperty
                      doc={FIRESTORE.DOCUMENT_USER(item.art)}
                      property="name"
                    />
                  </td>
                  <td>
                    {item.point_of_sale}-{item.voucher_number}
                  </td>
                  <td>
                    <Moment
                      date={item.creation_date.toDate()}
                      format="DD/MM/YYYY HH:mm"
                    />
                  </td>
                  <td>{item.deleted_vouche_number}</td>
                  <td>
                    {item.canceled_date ? (
                      <Moment
                        date={item.canceled_date.toDate()}
                        format="DD/MM/YYYY HH:mm"
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {item.respuesta_api
                      ? `${item.respuesta_api?.status} - ${item.respuesta_api?.texto}`
                      : null}
                  </td>
                  <td>
                    <Link to={ROUTES.ADMIN_SALES_SALES_ART_BILL_INFO(item.id)}>
                      <Button variant="light">Ver Factura</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </AdminTable>
          <Pagination2 />
        </>
      ) : filter === "Notas de débito" ? (
        <>
          <AdminTable responsive>
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Factura No.</th>
                <th>Fecha facturado</th>
                <th>Nota de Débito No.</th>
                <th>Fecha nota de débito</th>
                <th>Ver Factura</th>
              </tr>
            </thead>
            <tbody>
              {items3.map((item) => (
                <tr key={item.id}>
                  <td>
                    <GetProperty
                      doc={FIRESTORE.DOCUMENT_USER(item.art)}
                      property="name"
                    />
                  </td>
                  <td>
                    {item.point_of_sale}-{item.voucher_number}
                  </td>
                  <td>
                    <Moment
                      date={item.creation_date.toDate()}
                      format="DD/MM/YYYY HH:mm"
                    />
                  </td>
                  <td>{item.debit_voucher_number}</td>
                  <td>
                    {item.debit_date ? (
                      <Moment
                        date={item.debit_date.toDate()}
                        format="DD/MM/YYYY HH:mm"
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {item.respuesta_api
                      ? `${item.respuesta_api?.status} - ${item.respuesta_api?.texto}`
                      : null}
                  </td>
                  <td>
                    <Link to={ROUTES.ADMIN_SALES_SALES_ART_BILL_INFO(item.id)}>
                      <Button variant="light">Ver Factura</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </AdminTable>
          <Pagination3 />
        </>
      ) : filter === "Error al facturar" ? (
        <>
          <AdminTable responsive>
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Número de Factura</th>
                <th>Fecha facturado</th>
                <th>Importe</th>
                <th>Mensaje Api</th>
                <th>Ver Factura</th>
              </tr>
            </thead>
            <tbody>
              {items4.map((item) => (
                <tr key={item.id}>
                  <td>
                    <GetProperty
                      doc={FIRESTORE.DOCUMENT_USER(item.art)}
                      property="name"
                    />
                  </td>
                  <td>
                    {item.point_of_sale}-{item.voucher_number}
                  </td>
                  <td>
                    <Moment
                      date={item.creation_date.toDate()}
                      format="DD/MM/YYYY HH:mm"
                    />
                  </td>
                  <td>
                    {"$" +
                      item.ImpTotal.toFixed(2).toString().split(".").join(",")}
                  </td>
                  <td>
                    {item.respuesta_api
                      ? `${item.respuesta_api?.status} - ${item.respuesta_api?.texto}`
                      : null}
                  </td>
                  <td>
                    <Link to={ROUTES.ADMIN_SALES_SALES_ART_BILL_INFO(item.id)}>
                      <Button variant="light">Ver Factura</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </AdminTable>
          <Pagination4 />
        </>
      ) : filter === "No facturable" ? (
        <>
          <AdminTable responsive>
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Fecha</th>
                {/* <th>Importe</th> */}
                <th>Siniestro-Auth</th>
                <th>SML id</th>
                <th>Ver Traslado</th>
              </tr>
            </thead>
            <tbody>
              {items5.map((item) => (
                <tr key={item.id}>
                  <td>{item.art.name}</td>
                  <td>
                    <Moment
                      date={item.origin.time.toDate()}
                      format="DD/MM/YYYY HH:mm"
                    />
                  </td>
                  <td>
                    {item.art.authorization
                      ? `${item.art.accident}-${item.art.authorization}`
                      : item.art.accident}
                  </td>
                  <td>{item.transfer_id ? item.transfer_id : ""}</td>
                  <td>
                    <Link to={ROUTES.ADMIN_MODULE_TRANSFER_VIEW(item.id)}>
                      <Button variant="light">Ver Traslado</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </AdminTable>
          <Pagination5 />
        </>
      ) : filter === "Incumplimiento" ? (
        <>
          <AdminTable responsive>
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Fecha</th>
                {/* <th>Importe</th> */}
                <th>Siniestro-Auth</th>
                <th>SML id</th>
                <th>Ver Traslado</th>
              </tr>
            </thead>
            <tbody>
              {items6.map((item) => (
                <tr key={item.id}>
                  <td>{item.art.name}</td>
                  <td>
                    <Moment
                      date={item.origin.time.toDate()}
                      format="DD/MM/YYYY"
                    />
                  </td>
                  <td>
                    {item.art.authorization
                      ? `${item.art.accident}-${item.art.authorization}`
                      : item.art.accident}
                  </td>
                  <td>{item.transfer_id ? item.transfer_id : ""}</td>
                  <td>
                    <Link to={ROUTES.ADMIN_MODULE_TRANSFER_VIEW(item.id)}>
                      <Button variant="light">Ver Traslado</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </AdminTable>
          <Pagination6 />
        </>
      ) : null}
    </ContainerPage>
  );
};

const generateExcel = async () => {
  const bills = (
    await FIRESTORE.COLLECTION_ARTS_INVOICES()
      .where("art", "==", "MekXauxMVPd1ayCGpIwWjg7j6Z73")
      .get()
  ).docs;
  let workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet("Tutorials");

  worksheet.columns = [
    { header: "Nro Factura", key: "nro", width: 10 },
    { header: "Id Modulo", key: "modId", width: 10 },
    { header: "Siniestro-Cod.Aut.", key: "sin", width: 10 },
    { header: "Fecha", key: "fecha", width: 10 },
    { header: "Paciente", key: "paciente", width: 10 },
    { header: "Domicilio Origen", key: "domicilio_or", width: 10 },
    { header: "Localidad Origen", key: "localidad_or", width: 10 },
    { header: "Provincia Origen", key: "provincia_or", width: 10 },
    { header: "Domicilio Destino", key: "domicilio_de", width: 10 },
    { header: "Localidad Destino", key: "localidad_de", width: 10 },
    { header: "Provincia Destino", key: "provincia_de", width: 10 },
    { header: "Retorno", key: "return", width: 25 },
    { header: "Negativo", key: "negativo", width: 25 },
    { header: "Tiempo Comprado", key: "wait_time", width: 25 },
    { header: "Valor Espera", key: "extra_wait_time", width: 25 },
    { header: "Valor Viaje", key: "base", width: 25 },
    { header: "Comisión Agencia", key: "agency", width: 25 },
    { header: "Peajes", key: "tolls", width: 25 },
    { header: "Bonificación", key: "bonif", width: 25 },
    { header: "IVA", key: "iva", width: 25 },
    { header: "Total", key: "total", width: 25 },
    { header: "Total+IVA", key: "total_iva", width: 25 },
    { header: "Valor Cruce", key: "cross", width: 25 },
  ];

  worksheet.getCell("T1").font = {
    underline: false,
    color: { argb: "FF000000" },
  };
  worksheet.getCell("U1").font = {
    underline: false,
    color: { argb: "FF000000" },
  };

  await Promise.all(
    bills.map(async (bill) => {
      const billData = bill.data();
      if (!billData.canceled) {
        await Promise.all(
          billData.modules.map(async (mod) => {
            const module = await FIRESTORE.DOCUMENT_MODULE_TRANSFER(
              mod.id_module
            );
            const data = (
              await FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module).get()
            ).data();
            let voucher1data: ScheduledTransfer | undefined | null;
            let voucher2data: ScheduledTransfer | undefined | null;

            if (
              data!.type ==
              ModuleTransferType.TRIP_AND_RETURN_WITH_DIFFERENT_DRIVER
            ) {
              const cross = await getCrossExperta(
                data!.origin_location,
                data!.destination_location,
                data!.status.finished_status == 2 ? 2 : 1
              );
              worksheet.addRow({
                nro: billData.point_of_sale + "-" + billData.voucher_number,
                modId: module.id,
                sin: mod.id_accident + "-" + data?.art.authorization,
                fecha: data?.origin.time
                  ? (data?.origin.time).toDate().toISOString()
                  : "no hay fecha de origen",
                paciente: data?.passenger.name,
                domicilio_or: `${data?.origin.address.street} ${data?.origin.address.number}`,
                localidad_or: data?.origin.address.district,
                provincia_or: data?.origin.address.province,
                domicilio_de: `${data?.destination.address.street} ${data?.destination.address.number}`,
                localidad_de: data?.destination.address.district,
                provincia_de: data?.destination.address.province,
                return: "Si",
                negativo: data?.status.finished_status == 2 ? "Si" : "No",
                wait_time: mod.wait_time,
                extra_wait_time: mod.extra_wait_time,
                base: mod.base,
                agency: mod.agency_commission,
                tolls: mod.tolls,
                bonif: billData.bonif ? billData.bonif : 0,
                iva: mod.ImpIVA,
                total: mod.total,
                total_iva: mod.total + mod.ImpIVA,
                cross: cross,
              });
            } else {
              const cross = await getCrossExperta(
                data!.origin_location,
                data!.destination_location,
                data!.status.finished_status == 2 ? 2 : data!.type == 0 ? 0 : 1
              );

              worksheet.addRow({
                nro: billData.point_of_sale + "-" + billData.voucher_number,
                modId: module.id,
                sin: mod.id_accident + "-" + data?.art.authorization,
                fecha: data?.origin.time
                  ? (data?.origin.time).toDate().toISOString()
                  : "no hay fecha de origen",
                paciente: data?.passenger.name,
                domicilio_or: `${data?.origin.address.street} ${data?.origin.address.number}`,
                localidad_or: data?.origin.address.district,
                provincia_or: data?.origin.address.province,
                domicilio_de: `${data?.destination.address.street} ${data?.destination.address.number}`,
                localidad_de: data?.destination.address.district,
                provincia_de: data?.destination.address.province,
                return: data?.type == 0 ? "No" : "Si",
                negativo: data?.status.finished_status == 2 ? "Si" : "No",
                wait_time: mod.wait_time,
                extra_wait_time: mod.extra_wait_time,
                base: mod.base,
                agency: mod.agency_commission,
                tolls: mod.tolls,
                iva: mod.ImpIVA,
                bonif: billData.bonif ? billData.bonif : 0,
                total: mod.total,
                total_iva: mod.total + mod.ImpIVA,
                cross: cross,
              });
            }
          })
        );
      }
    })
  );
  const buffer = await workbook.xlsx.writeBuffer();

  console.log(buffer.toString());

  await workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, "resumen-facturas-experta" + ".xlsx");
  });
};

const getCrossExperta = async (
  origin: LocationModule | undefined,
  destination: LocationModule | undefined,
  type: number
) => {
  let price;
  const cruces = Object.values(crossExperta);
  if (origin && destination) {
    cruces.forEach((cruce) => {
      const arrayCruces: any = Object.values(cruce);
      arrayCruces[0].forEach((item: any) => {
        if (
          origin.id == destination.id &&
          destination.province?.id == origin.province?.id
        ) {
          if (
            item["ID ciudad origen"] === destination.id &&
            item["ID provincia origen"] === destination.province?.id &&
            item["ID ciudad destino"] === destination.id &&
            item["ID provincia destino"] === destination.province?.id
          ) {
            if (type == 0) {
              price = item.tramo;
            }

            if (type == 1) {
              price = item.modulo;
            }

            if (type == 2) {
              price = item.negativo;
            }
          }
        } else {
          if (
            (item["ID ciudad origen"] == origin.id &&
              item["ID provincia origen"] == origin.province?.id) ||
            (item["ID ciudad destino"] == origin.id &&
              item["ID provincia destino"] == origin.province?.id)
          ) {
            if (
              (item["ID ciudad origen"] === destination.id &&
                item["ID provincia origen"] === destination.province?.id) ||
              (item["ID ciudad destino"] === destination.id &&
                item["ID provincia destino"] === destination.province?.id)
            ) {
              if (type == 0) {
                price = item.tramo;
              }

              if (type == 1) {
                price = item.modulo;
              }

              if (type == 2) {
                price = item.negativo;
              }
            }
          }
        }
      });
    });
    return price;
  }
};
export default Sales;
