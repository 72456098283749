import { Center, Container, ContainerGrey, H1, H3, Padding } from "components/styles";
import React from "react";
import styled from "styled-components";

const UserMyProfileLogsPage = () => {
  return (
    <Container>
        <ContainerGrey>
        <Padding pad="15px">
            <Center>
            <H1>Historial del perfil</H1>
            </Center>
        </Padding>
        </ContainerGrey>
        <Container>
            <Timeline>
            <li className="event" data-date="18/09/2019 07:52 hs">
                <H3>Perfil Validado</H3>
                <p>Cuerpo</p>
                <p>email</p>
            </li>
            <li className="event" data-date="18/09/2019 07:52 hs">
                <H3>Perfil Validado</H3>
                <p>Cuerpo</p>
                <p>email</p>
            </li>
            </Timeline>
        </Container>
    </Container>
  );
};

const Timeline = styled.ul`
    border-left: 4px solid #62d1e1;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    margin: 40px 20% 40px auto;
    letter-spacing: 0.5px;
    position: relative;
    line-height: 1.4em;
    font-size: 1.03em;
    padding: 40px;
    list-style: none;
    text-align: left;
    font-weight: 100;
    max-width: 40%;

  h1 {
    letter-spacing: 1.5px;
    font-weight: 100;
    font-size: 1.4em;
  }
  h2,
  h3 {
    letter-spacing: 1.5px;
    font-weight: 400;
    font-size: 1.4em;
    margin: 0px;
  }
  .event {
    border-bottom: 1px dashed #d1d3d4;
    padding-bottom: 20px;
    margin-bottom: 40px;
    position: relative;
  }
  .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }
  .event:before,
  .event:after {
    position: absolute;
    display: block;
    top: 0;
  }
  .event:before {
    left: -216px;
    color: #6c6d6d;
    content: attr(data-date);
    text-align: left;
    font-weight: 100;
    font-size: 0.9em;
    min-width: 120px;
    height: 30px;
    line-height: 30px;
  }
  .event:after {
    box-shadow: 0 0 0 4px #62d1e1;
    left: -51px;
    background: #00426a;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    content: "";
    top: 5px;
  }
`;

export default UserMyProfileLogsPage;

