import "firebase/firestore";
import { FIRESTORE } from "constants/firestore";

export const getCollectionByArt = (
  type: string,
  startDate: Date,
  endDate: Date
) => {
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 0);
  if (type === "Experta SML") {
    return FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("art.name", "==", "Experta SML")
      .where("status.finished_status", "==", 0)
      .where("origin.time", ">=", startDate)
      .where("origin.time", "<", endDate)
      .orderBy("origin.time", "desc");
  }
  if (type === "Feleval SRL") {
    return FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("art.name", "==", "Feleval SRL")
      .where("status.finished_status", "==", 0)
      .where("origin.time", ">=", startDate)
      .where("origin.time", "<", endDate)
      .orderBy("origin.time", "desc");
  }
  if (type === "No finalizados") {
    return FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("origin.time", ">=", startDate)
      .where("origin.time", "<", endDate)
      .orderBy("origin.time", "desc");
  }
};
