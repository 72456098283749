import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/firestore"
import { Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { ButtonInput } from "./file";
const FileUploader: any = require("react-firebase-file-uploader").default;

//import {useDropzone} from 'react-dropzone' //<-- para agregar en algun futuro
//interesante para leer: https://stackoverflow.com/questions/33279153/rest-api-file-ie-images-processing-best-practices

const ImageInput = ({ isInvalid, name, value, onChange, ...props }: any) => {
	const [downloadURL, setDownloadURL] = useState<string | undefined>(undefined);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    
    useEffect(() => {
        if(value)
            firebase.storage().ref(value).getDownloadURL().then( setDownloadURL )
            .catch( (e) => {console.log(e);setDownloadURL(undefined)} )
        else
            setDownloadURL(undefined)
    }, [value])

	const handleUploadStart = () => {
		setIsUploading(true);
		setUploadProgress(0);
	};

	const handleUploadSuccess = async (filename: string) => {
		const downloadURL = await firebase.storage().ref("images").child(filename).getDownloadURL();

		onChange("images/" + filename); //Change redux-form value

		setDownloadURL(downloadURL);
		setUploadProgress(100);
		setIsUploading(false);
    };
    
    let className = ''
    if(isInvalid)
        className = "is-invalid"

	if (downloadURL)
		return (
			<Container>
				<Row>
					<ButtonInput onClick={() => setDownloadURL(undefined)}>Modificar</ButtonInput>
					<ImageContainer>
						<img style={{ maxWidth: "100%" }} src={downloadURL} />
					</ImageContainer>
				</Row>
			</Container>
		);
	if (isUploading) return <>{uploadProgress}%</>;
	if (errorMessage) return <>errorMessage</>;
	return (
		<Container className={className}>
            <ButtonInput>
                Selecciona foto
                <FileUploader
                    hidden
                    accept='image/*'
                    randomizeFilename
                    storageRef={firebase.storage().ref("images")}
                    onUploadStart={handleUploadStart}
                    onUploadError={setErrorMessage}
                    onUploadSuccess={handleUploadSuccess}
                    onProgress={setUploadProgress}
                />
            </ButtonInput>
		</Container>
	);
};

const ImageContainer = styled.div`
	padding: 5px;
	border: 1px solid grey;
    border-radius: 5px;
    -webkit-box-shadow: 0px 5px 8px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 5px 8px -1px rgba(0,0,0,0.75);
    box-shadow: 0px 5px 8px -1px rgba(0,0,0,0.75);
`;

export default ImageInput;
