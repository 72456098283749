import FbFile from "components/Primitives/File";
import {
  Button,
  Center,
  ContainerPage,
  H3,
  PageTitle,
  Spinner,
} from "components/styles";
import {
  getRevisionPersonal,
  setRevisionPersonal,
} from "components/User/Newcomers/finished/reducer";
import { locToString } from "components/User/Zones/reducer";
import { getPersonalInformation } from "controllers/user/info/personal";
import firebase from "firebase/app";
import "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Col, Form as BForm, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { QuizParams } from "types/module-transfer";
import {
  CivilStatus,
  Genre,
  IdType,
  PersonalInformation,
  Revision,
  RevisionStatus,
  RevisionAdmin,
  ProfileStatus,
} from "types/user-data";
import { FIRESTORE } from "constants/firestore";

const ProfileRevision = () => {
  let { uidUser } = useParams<QuizParams>();

  if (!uidUser) return <>Error uid</>;
  return (
    <ContainerPage>
      <PersonalRevision uidUser={uidUser} />
    </ContainerPage>
  );
};

const PersonalRevision = ({ uidUser }: { uidUser: string }) => {
  const [item, setItem] = useState<PersonalInformation | undefined>(undefined);
  const [revision, setRevision] = useState<Revision | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [labelsWrong, setLabelsWrong] = useState<Set<string>>(
    new Set<string>()
  );
  const add = (s: string) =>
    setLabelsWrong(new Set<string>(labelsWrong).add(s));
  const rm = (s: string) => {
    let cp = new Set(labelsWrong);
    cp.delete(s);
    setLabelsWrong(cp);
  };
  useEffect(() => {
    setLoading(true);

    getPersonalInformation(uidUser)
      .then(setItem)
      .then(() => setLoading(false));
    getRevisionPersonal(uidUser).then(setRevision);
  }, []);

  if (loading) return <Spinner />;
  if (item === undefined) return <>Error cargando los datos</>;
  return (
    <>
      <PageTitle>
        Revision del perfil: {item.name + " " + item.surname}{" "}
        {IdType[item.idType] + " " + item.id}
      </PageTitle>
      <H3>Datos Personales</H3>
      <Row>
        <Col lg="6">
          <InfoRow add={add} rm={rm} label={"Nombre"} info={item.name} />
          <InfoRow add={add} rm={rm} label={"Apellido"} info={item.surname} />
          <InfoRow
            add={add}
            rm={rm}
            label={"DNI/CUIT"}
            info={IdType[item.idType] + " " + item.id}
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Nacimiento"}
            info={item.birthday.toDate().toLocaleDateString()}
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Nacionalidad"}
            info={item.country}
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Dirección"}
            info={locToString(item.address)}
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Dirección2"}
            info={item.address2}
          />
        </Col>
        <Col lg="6">
          <InfoRow
            add={add}
            rm={rm}
            label={"Teléfono"}
            info={item.phone.area_code + " " + item.phone.number}
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Estado civil"}
            info={CivilStatus[item.civil_status]}
          />
          <InfoRow add={add} rm={rm} label={"Trabajo"} info={item.job} />
          <InfoRow
            add={add}
            rm={rm}
            label={"Género"}
            info={Genre[item.genre]}
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Antecedentes penales"}
            info={<FbFile src={item.criminal_records} />}
          />
        </Col>
      </Row>
      <br />
      <Center>ESTADO ACTUAL: {revision && textStatus(revision.status)}</Center>
      <FormReview
        uidUser={uidUser}
        setRevision={setRevisionPersonal}
        wrong_fields={labelsWrong}
      />
    </>
  );
};

export const textStatus = (status: RevisionStatus) => {
  if (status === RevisionStatus["No revisado"])
    return (
      <span style={{ color: "#CCCC00" }}>
        <b>EN REVISIÓN</b>
      </span>
    );
  if (status === RevisionStatus.Desaprobado)
    return (
      <span style={{ color: "#8b0000" }}>
        <b>DESAPROBADO</b>
      </span>
    );
  if (status === RevisionStatus.Aprobado)
    return (
      <span style={{ color: "#006400	" }}>
        <b>APROBADO</b>
      </span>
    );
};

export const InfoRow = ({ label, info, add, rm }: any) => (
  <Row style={{ margin: 10, padding: 5, borderBottom: "solid #0000001a 1px" }}>
    <Col md={4} style={{ color: "#888" }}>
      {label}
    </Col>
    <Col style={{ color: "#333" }}>{info}</Col>
    <Col xs="auto" className="pull-right">
      <BForm.Check
        custom
        type="checkbox"
        id={label}
        label={""}
        defaultChecked={true}
        onChange={(e: any) => (e.target.checked ? rm(label) : add(label))}
      />
      {/*Agregamos label vacío: https://github.com/twbs/bootstrap/issues/26221*/}
    </Col>
  </Row>
);

export const FormReview = ({
  wrong_fields,
  uidUser,
  setRevision,
}: {
  wrong_fields: Set<string>;
  uidUser: string;
  setRevision: (a: string, v: RevisionAdmin) => Promise<void>;
}) => {
  const [comment, setComment] = useState("");
  const [acctionDone, setAcctionDone] = useState<RevisionStatus | undefined>(
    undefined
  );

  if (acctionDone === RevisionStatus.Aprobado)
    return (
      <Center>
        <span style={{ color: "green" }}>
          <b>APROBADO</b>
        </span>
      </Center>
    );

  if (acctionDone === RevisionStatus.Desaprobado)
    return (
      <Center>
        <span style={{ color: "red" }}>
          <b>DESAPROBADO</b>
        </span>
      </Center>
    );

  if (wrong_fields.size === 0)
    return (
      <Center style={{ margin: 10 }}>
        <Button
          variant="success"
          onClick={() => {
            setRevision(uidUser, {
              comment_revised: comment,
              status: RevisionStatus.Aprobado,
              date_revised: firebase.firestore.Timestamp.fromDate(new Date()),
            });
            setAcctionDone(RevisionStatus.Aprobado);
          }}
          style={{ minWidth: 300 }}
        >
          Aprobar
        </Button>
        <Button
          variant="warning"
          onClick={async () => {
            setRevision(uidUser, {
              comment_revised: comment,
              status: RevisionStatus["No revisado"],
              date_revised: firebase.firestore.Timestamp.fromDate(new Date()),
            });
            setAcctionDone(RevisionStatus["No revisado"]);
            await FIRESTORE.DOCUMENT_USER(uidUser).update({
              profile_user_status: ProfileStatus["A REVISAR"],
            });
          }}
          style={{ minWidth: 300 }}
        >
          En Revisión
        </Button>
      </Center>
    );

  return (
    <>
      <textarea
        value={comment}
        className="form-control"
        onChange={(e) => setComment(e.target.value)}
        rows={3}
        placeholder="Agrega un comentario a la corrección"
        style={{ width: "100%" }}
      />
      <Center style={{ margin: 10 }}>
        <Button
          variant="danger"
          onClick={() => {
            setRevision(uidUser, {
              comment_revised: comment,
              status: RevisionStatus.Desaprobado,
              date_revised: firebase.firestore.Timestamp.fromDate(new Date()),
              wrong_fields: Array.from(wrong_fields),
            });
            setAcctionDone(RevisionStatus.Desaprobado);
          }}
          style={{ minWidth: 300 }}
        >
          No Aprobar
        </Button>
      </Center>
    </>
  );
};

export default ProfileRevision;
