import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Center, H2, Padding, Spinner } from "components/styles";
import { CarForm as CarFormNewcomers } from "components/User/Newcomers/pages/CarForm";
import ROUTES from "constants/routes";
import { uidSelector } from "controllers/auth";
import firebase from "firebase/app";
import "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { reduxForm } from "redux-form";
import { Car } from "types/car";
import { QuizParams } from "types/module-transfer";
import { getCar, newCar, updateCar } from "./reducer";

const loadInitialValues = async (uid: string, idCar: string) =>
  getCar(uid, idCar).then((d: Car) => ({
    model: d.model,
    licence: d.licence,
    year: d.year,
    brand: d.brand,
    color: d.color,
    wheelchair: d.wheelchair,
    engine: d.engine,
    is_owner: d.is_owner,
    owner: d.owner,
    policy_number: d.policy_number,
    insurance_company: d.insurance_company,
    insurance_number: d.insurance_number,
    insurance_front: d.insurance_front,
    green_card_front: d.green_card_front,
    vtv_front: d.vtv_front,
    insurance_expiration_date: d.insurance_expiration_date.toDate(),
    vtv_expiration_date: d.vtv_expiration_date
      ? d.vtv_expiration_date.toDate()
      : undefined,
  }));

const UserCarAddPage = () => {
  let { idCar } = useParams<QuizParams>();
  const history = useHistory();
  const uid = useSelector(uidSelector);
  const [finished, setfinished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({
    wheelchair: false,
    is_owner: true,
  });

  useEffect(() => {
    if (idCar && idCar !== "0") {
      setLoading(true);
      loadInitialValues(uid, idCar)
        .then(setInitialValues)
        .then(() => setLoading(false));
    }
  }, []);

  const handleSubmit = (values: any) => {
    const car: Car = {
      model: values.model,
      licence: values.licence,
      year: values.year,
      brand: values.brand,
      color: values.color,
      wheelchair: values.wheelchair,
      engine: values.engine,
      is_owner: values.is_owner,
      owner: values.owner,
      policy_number: values.policy_number,
      insurance_company: values.insurance_company,
      insurance_number: values.insurance_number,
      insurance_expiration_date: firebase.firestore.Timestamp.fromDate(
        new Date(values.insurance_expiration_date)
      ),
      vtv_expiration_date: firebase.firestore.Timestamp.fromDate(
        new Date(values.vtv_expiration_date)
      ),
      insurance_front: values.insurance_front,
      green_card_front: values.green_card_front,
      vtv_front: values.vtv_front,
    };
    if (idCar !== undefined && idCar !== "0") updateCar(uid, idCar, car);
    else newCar(uid, car).then(() => setfinished(true));
  };

  if (finished) return <Redirect to={ROUTES.USER_CARS} />;
  if (loading) return <Spinner />;
  return (
    <Padding pad="5px">
      <H2>
        <FontAwesomeIcon
          onClick={() => history.goBack()}
          icon={faChevronLeft}
        />{" "}
        {idCar !== "0" ? "Editar Auto" : "Nuevo Auto"}
      </H2>
      <hr />
      <CarFormRedux onSubmit={handleSubmit} initialValues={initialValues} />
    </Padding>
  );
};

const CarForm = ({ handleSubmit, submitSucceeded }: any) => (
  <>
    {submitSucceeded ? (
      <Alert variant="success">Valores Guardados</Alert>
    ) : (
      <form onSubmit={handleSubmit}>
        <CarFormNewcomers />
        <hr />
        <Center>
          <Button type="submit" variant="success" style={{ width: 300 }}>
            Terminar
          </Button>
        </Center>
      </form>
    )}
  </>
);

const CarFormRedux = reduxForm({
  form: "new-car",
})(CarForm);

export default UserCarAddPage;
