import { Button, ContainerPage, Link, PageTitle, Spinner, SubmitButton } from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { FIRESTORE } from "constants/firestore";
import { useAsyncFunction } from "controllers/hooks";
import { required } from "controllers/validations";
import React, { useState } from "react";
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import { reduxForm } from "redux-form";
import XLSX from "xlsx";
import { requiredPtoVenta, requiredVoucher } from "./generate-bill";
import { Field } from "components/input/field";

export const ExcelModal = ({ show, handleClose }: { show: boolean; handleClose: any }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string[]>([]);
    const [success, setSuccess] = useState<boolean>(false);
    const [transfersData, setTransfersData] = useState<Object[]>([]);

    const readXLSX = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        setLoading(true);
        reader.onload = (event) => {
            const data = new Uint8Array(event.target!.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: "array", cellDates: true });
            const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]) as Object[];
            setTransfersData(jsonData);
            console.log(jsonData);
            setLoading(false);
        };
        reader.readAsArrayBuffer(file);
    };

    const uploadTransfers = async (values: any) => {
        setLoading(true);
        // const _errors: string[] = [];
        console.log(transfersData);

        const data: any[] = [];
        transfersData.forEach((transfer: any) => {
            const elements = {
                total: transfer.Total,
                link: transfer.Vinculo,
                transfer_id_feleval: transfer["SML ID"],
                tolls: transfer.Peajes,
                base: transfer["Valor Viaje"] != 0 ? transfer["Valor Viaje"] : transfer["Valor Negativo"],
                negative: transfer.Modo == "negativo" ? true : false,
            };
            data.push(elements);
        });
        console.log(values);
        const billData = {
            arr: data,
            caeVto: values.caefchvto.toISOString(),
            fechaCreate: values.creation_date.toISOString(),
            ptoVenta: values.point_of_sale,
            voucher_number: values.voucher_number,
            pdf: values.pdf,
            monto: values.monto,
            cae: values.cae,
        };
        await CALLABLE_FUNCTIONS.ADMIN_UPLOAD_BILLING_FELEVAL(billData);
        setLoading(false);
        setSuccess(true);

        // const getArts = await FIRESTORE.COLLECTION_USERS()
        //     .where("type", "==", UserType.art)
        //     .get()
        //     .then((v) => v.docs)
        //     .then((v2) => v2.map((v3) => ({ name: v3.data().name, id: v3.id })));
        // await Promise.all(
        //     transfersData.map(async (data: any, index: number) => {
        //         try {
        //             if (
        //                 data["CUIL/DNI"] === undefined ||
        //                 data["TELEFONO ACCIDENTADO"] === undefined ||
        //                 data["ACCIDENTADO"] === undefined ||
        //                 data["SINIESTRO"] === undefined ||
        //                 data["CATEGORIA"] === undefined ||
        //                 data["FECHA DE TURNO"] === undefined
        //             )
        //                 throw Error("Algún campo obligatorio no está completado");

        //             const uidArt = getArts.find((v) => v.name === data["ART"])?.id;
        //             if (uidArt === undefined) throw Error("No existe art llamada:" + data["ART"]);

        //             let addOr;

        // if (data["CALLE Y NUMERO ORIGEN"]) {
        //     const res = await getAddressFromPartial(
        //         `${data["CALLE Y NUMERO ORIGEN"]} ${data["LOCALIDAD ORIGEN"]} ${data["PROVINCIA ORIGEN"]}`
        //     );
        //     addOr = res.length > 0 ? res[0] : data["CALLE Y NUMERO ORIGEN"];
        // }
        // let addDes;

        // if (data["CALLE Y NUMERO DESTINO"]) {
        //     const res = await getAddressFromPartial(
        //         `${data["CALLE Y NUMERO DESTINO"]} ${data["LOCALIDAD DESTINO"]} ${data["PROVINCIA DESTINO"]}`
        //     );
        //     addDes = res.length > 0 ? res[0] : data["CALLE Y NUMERO DESTINO"];
        // }

        // const formatedData = {
        //     origin_date: data["HORA ORIGEN"]
        //         ? new Date(
        //               Number("20" + data["FECHA DE TURNO"].split("/")[2]),
        //               data["FECHA DE TURNO"].split("/")[1] - 1,
        //               data["FECHA DE TURNO"].split("/")[0],
        //               data["HORA ORIGEN"].split(":")[0],
        //               data["HORA ORIGEN"].split(":")[1]
        //           ).toISOString()
        //         : undefined,
        //     destination_date: new Date(
        //         Number("20" + data["FECHA DE TURNO"].split("/")[2]),
        //         Number(data["FECHA DE TURNO"].split("/")[1]) - 1,
        //         Number(data["FECHA DE TURNO"].split("/")[0]),
        //         Number(data["HORA DE TURNO"].split(":")[0]),
        //         Number(data["HORA DE TURNO"].split(":")[1])
        //     ).toISOString(),
        //     backtrack_date: data["HORA RETORNO"]
        //         ? new Date(
        //               Number("20" + data["FECHA DE TURNO"].split("/")[2]),
        //               data["FECHA DE TURNO"].split("/")[1] - 1,
        //               data["FECHA DE TURNO"].split("/")[0],
        //               data["HORA RETORNO"].split(":")[0],
        //               data["HORA RETORNO"].split(":")[1]
        //           ).toISOString()
        //         : undefined,

        //     origin_address: {
        //         street: addOr ? addOr.split(",")[0] : undefined,
        //         locality: addOr ? addOr.split(",")[1] : undefined,
        //         province: addOr ? addOr.split(",")[2] : undefined,
        //     },
        //     origin_coords:
        //         data["LATITUD ORIGEN"] && data["LONGITUD ORIGEN"]
        //             ? {
        //                   lat: Number(data["LATITUD ORIGEN"]),
        //                   lng: Number(data["LONGITUD ORIGEN"]),
        //               }
        //             : undefined,
        //     destination_address: {
        //         street: addDes ? addDes.split(",")[0] : undefined,
        //         locality: addDes ? addDes.split(",")[1] : undefined,
        //         province: addDes ? addDes.split(",")[2] : undefined,
        //     },
        //     destination_coords:
        //         data["LATITUD DESTINO"] && data["LONGITUD DESTINO"]
        //             ? {
        //                   lat: Number(data["LATITUD DESTINO"]),
        //                   lng: Number(data["LONGITUD DESTINO"]),
        //               }
        //             : undefined,

        //     passenger: {
        //         dni: data["CUIL/DNI"],
        //         name: data["ACCIDENTADO"],
        //         tel: (data["TELEFONO ACCIDENTADO"] as Number).toString(),
        //     },
        //     type: data["CATEGORIA"] === "TRAMO" ? 0 : data["CATEGORIA"] === "MODULO CON ESPERA" ? 1 : 2,
        //     art_id_accident: String(data["SINIESTRO"]),
        //     art_id_expert: data["EXPERTA"],
        //     art_id_authorization: data["AUTORIZACION"],
        //     art_id_npa: data["NPA"],
        //     wheelchair: data["SILLA DE RUEDAS"] === "SI" ? true : false,
        //     acom_aut: data["ACOMPAÑANTE"] === "SI" ? true : false,
        //     art: uidArt,
        //     obs:
        //         (data["PISO"] ? `PISO : ${data["PISO"]} ` : "") +
        //         (data["DPTO"] ? `DPTO : ${data["DPTO"]} ` : "") +
        //         (data["MOTIVO DE TRASLADO"] ? `MOTIVO DE TRASLADO : ${data["MOTIVO DE TRASLADO"]} ` : "") +
        //         (data["OBSERVACIONES"] ? `OBSERVACIONES : ${data["OBSERVACIONES"]} ` : ""),
        // };
        // removeUndefined(formatedData);
        // console.log(addOr);
        // await CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_CREATE_EXCEL(formatedData);
        // return true;
        //         } catch (e) {
        //             console.log(e);
        //             _errors.push(`Error en la línea ${index} : ${e.message}`);
        //         }
        //     })
        // );
        // setLoading(false);
        // if (_errors.length === 0) {
        //     setSuccess(true);
        // } else {
        //     _errors.push("Los demás traslados fueron creados con éxito.");
        //     setError(_errors);
        // }
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Cargar facturación Feleval desde Excel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {success ? (
                    <Row>
                        <Col xl={8}>
                            <Alert variant="success">Traslados cargados correctamente</Alert>
                        </Col>
                        <Col xl={4}>
                            <Button
                                variant="info"
                                block
                                onClick={() => {
                                    setSuccess(false);
                                    setTransfersData([]);
                                }}
                            >
                                Cargar otro excel
                            </Button>
                        </Col>
                    </Row>
                ) : loading ? (
                    <Spinner />
                ) : error.length > 0 ? (
                    <Alert variant="danger">
                        {error.map((err) => (
                            <>
                                {err}
                                <br />
                            </>
                        ))}
                    </Alert>
                ) : (
                    <>
                        {transfersData.length > 0 ? (
                            ""
                        ) : (
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.File label="Cargar Excel (.xlsx)" onChange={readXLSX} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        )}
                        <FormGenerateBillRedux onSubmit={uploadTransfers} />
                    </>
                )}
                <hr style={{ marginTop: 50 }} />
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant="danger" onClick={func}>
                    Facturar
                </Button> */}
                <Button variant="secondary" style={{ textAlign: "center", width: "4rem" }} onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const FormGenerateBill = ({ handleSubmit, submitting, error, submitSucceeded }: any) => {
    if (submitting) return <Spinner />;
    return (
        <form onSubmit={handleSubmit}>
            <Row>{error && <Alert variant="danger">{error.message}</Alert>}</Row>
            <Row>
                {submitSucceeded && (
                    <Alert variant="success" style={{ width: "100%" }}>
                        Valores Guardados
                    </Alert>
                )}
            </Row>
            <Row>
                <Col>
                    <Field labelinput={<>Fecha Vencimiento Cae</>} name="caefchvto" type="date-picker" validate={required} />
                </Col>
                <Col>
                    <Field labelinput={<>Fecha de Creación</>} name="creation_date" type="date-picker" validate={required} />
                </Col>
                <Col>
                    <Field
                        labelinput={<>Número Talonario / Pto de Venta</>}
                        name="point_of_sale"
                        type="text"
                        validate={[required, requiredPtoVenta]}
                    />
                </Col>
                <Col>
                    <Field
                        labelinput={<>Número Factura/Voucher</>}
                        name="voucher_number"
                        type="text"
                        validate={[required, requiredVoucher]}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field labelinput={<>Monto factura</>} name="monto" type="text" validate={[required]} />
                </Col>
                <Col>
                    <Field labelinput={<>CAE</>} name="cae" type="text" validate={[required]} />
                </Col>
                <Col>
                    <Field labelinput={<>PDF Factura</>} name="pdf" type="file" validate={required} />
                </Col>
            </Row>

            <Row>
                <Col>
                    <SubmitButton block submitting={submitting}>
                        Enviar
                    </SubmitButton>
                </Col>
            </Row>
        </form>
    );
};

const FormGenerateBillRedux = reduxForm({
    form: "bill-transfer",
})(FormGenerateBill);
