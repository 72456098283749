import { Uid } from "controllers/auth";
import database from "controllers/database";
import { Revision, RevisionAdmin } from "types/user-data";

export const getRevisionPersonal = (uid: Uid): Promise<Revision> =>
  database
    .collection("user")
    .doc(uid)
    .collection("personal_revision")
    .doc(uid)
    .as(Revision);

export const setRevisionPersonal = (
  uid: Uid,
  revision: RevisionAdmin
): Promise<void> =>
  database
    .collection("user")
    .doc(uid)
    .collection("personal_revision")
    .doc(uid)
    .update(revision, Revision);

export const getRevisionLicence = (uid: Uid): Promise<Revision> =>
  database
    .collection("user")
    .doc(uid)
    .collection("licence_revision")
    .doc(uid)
    .as(Revision);

export const setRevisionLicence = (
  uid: Uid,
  revision: RevisionAdmin
): Promise<void> =>
  database
    .collection("user")
    .doc(uid)
    .collection("licence_revision")
    .doc(uid)
    .update(revision, Revision);

export const getRevisionTaxable = (uid: Uid): Promise<Revision> =>
  database
    .collection("user")
    .doc(uid)
    .collection("taxable_revision")
    .doc(uid)
    .as(Revision);

export const setRevisionTaxable = (
  uid: Uid,
  revision: RevisionAdmin
): Promise<void> =>
  database
    .collection("user")
    .doc(uid)
    .collection("taxable_revision")
    .doc(uid)
    .update(revision, Revision);

export const getRevisionCar = (uid: Uid, idCar: string): Promise<Revision> =>
  database
    .collection("user")
    .doc(uid)
    .collection("car_revision")
    .doc(idCar)
    .as(Revision);

export const setRevisionCar =
  (idCar: string) =>
  (uid: Uid, revision: RevisionAdmin): Promise<void> =>
    database
      .collection("user")
      .doc(uid)
      .collection("car_revision")
      .doc(idCar)
      .update(revision, Revision);
