import firebase from "firebase/app";
import "firebase/firestore"
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import styled from "styled-components";
const FileUploader: any = require("react-firebase-file-uploader").default;

//import {useDropzone} from 'react-dropzone' //<-- para agregar en algun futuro
//interesante para leer: https://stackoverflow.com/questions/33279153/rest-api-file-ie-images-processing-best-practices

const FileInput = ({ isInvalid, name, value, onChange, meta, ...props }: any) => {
	const [downloadURL, setDownloadURL] = useState<string | undefined>(undefined);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    
    useEffect(() => {
        if(value)
            firebase.storage().ref(value).getDownloadURL().then( setDownloadURL )
            .catch( (e) => {console.log(e);setDownloadURL(undefined)} )
        else
            setDownloadURL(undefined)
    }, [])

	const handleUploadStart = () => {
		setIsUploading(true);
		setUploadProgress(0);
	};

	const handleUploadSuccess = async (filename: string) => {
		const downloadURL = await firebase.storage().ref("files").child(filename).getDownloadURL();

		onChange("files/" + filename); //Change redux-form value

		setDownloadURL(downloadURL);
		setUploadProgress(100);
		setIsUploading(false);
    };
    
    let className = ''
    if(isInvalid)
        className = "is-invalid"

	if (downloadURL)
		return (
            <Container>
			<Row>
                <ButtonInput onClick={() => setDownloadURL(undefined)}>
					Modificar
                </ButtonInput>
				<ul>
					<li>
						<a href={downloadURL} target="_blank">Archivo</a>
					</li>
				</ul>
			</Row>
            </Container>
		);
	if (isUploading) return <>{uploadProgress}%</>;
	if (errorMessage) return <>errorMessage</>;
	return (
		<Container className={className} >
            <ButtonInput>
                Selecciona archivo
                <FileUploader
                    hidden
                    accept='*'
                    randomizeFilename
                    storageRef={firebase.storage().ref("files")}
                    onUploadStart={handleUploadStart}
                    onUploadError={setErrorMessage}
                    onUploadSuccess={handleUploadSuccess}
                    onProgress={setUploadProgress}
                />
            </ButtonInput>
		</Container>
	);
};

export const ButtonInput = styled.label`
    background-color: #00426A;
    color: white;
	padding: 10px;
	border-radius: 4px;
	cursor: pointer;
`

export default FileInput;
