import FbImageModal from "components/Primitives/Image/with-modal";
import {
  Center,
  ContainerPage,
  H3,
  PageTitle,
  Spinner,
} from "components/styles";
import { getCar } from "components/User/Cars/reducer";
import {
  getRevisionCar,
  setRevisionCar,
} from "components/User/Newcomers/finished/reducer";
import { getUser } from "controllers/user";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { Car } from "types/car";
import { FormReview, InfoRow, textStatus } from "../Profile/review";
import { Revision } from "types/user-data";
import { User } from "types/user-data";
import Moment from "react-moment";
import { QuizParams } from "types/module-transfer";

const AdminCarRevision = () => {
  const [item, setItem] = useState<Car | undefined>(undefined);
  const [userData, setUserData] = useState<User | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [revision, setRevision] = useState<Revision | undefined>(undefined);

  const { uidUser, idCar } = useParams<QuizParams>();

  const [labelsWrong, setLabelsWrong] = useState<Set<string>>(
    new Set<string>()
  );
  const add = (s: string) =>
    setLabelsWrong(new Set<string>(labelsWrong).add(s));
  const rm = (s: string) => {
    let cp = new Set(labelsWrong);
    cp.delete(s);
    setLabelsWrong(cp);
  };
  useEffect(() => {
    if (uidUser && idCar)
      Promise.all([
        getUser(uidUser).then(setUserData),
        getCar(uidUser, idCar).then(setItem),
        getRevisionCar(uidUser, idCar).then(setRevision),
      ]).then(() => setLoading(false));
  }, []);

  if (loading) return <Spinner />;

  if (!item || !userData || !uidUser || !idCar)
    return <>Error cargando los datos</>;

  return (
    <ContainerPage>
      <PageTitle>
        Revisión -{" "}
        {userData.name + " " + userData.surname + " (" + userData.email + ")"}
      </PageTitle>

      <H3>Datos de Auto</H3>
      <Row>
        <Col lg="6">
          {item.owner ? (
            <>
              <InfoRow add={add} rm={rm} label={"Es dueño"} info={"No"} />
              <InfoRow
                add={add}
                rm={rm}
                label={"Nombre Dueño"}
                info={item.owner.name}
              />
              <InfoRow
                add={add}
                rm={rm}
                label={"DNI/CUIT Dueño"}
                info={item.owner.dni_or_cuit}
              />
            </>
          ) : (
            <InfoRow add={add} rm={rm} label={"Es dueño"} info={"Si"} />
          )}

          <InfoRow add={add} rm={rm} label={"Modelo"} info={item.model} />
          <InfoRow add={add} rm={rm} label={"Marca"} info={item.brand} />
          <InfoRow add={add} rm={rm} label={"Color"} info={item.color} />
          <InfoRow add={add} rm={rm} label={"Año"} info={item.year} />
          <InfoRow
            add={add}
            rm={rm}
            label={"Número Patente"}
            info={item.licence}
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Número Poliza"}
            info={item.policy_number}
          />
        </Col>
        <Col lg="6">
          <InfoRow
            add={add}
            rm={rm}
            label={"Discapacitados"}
            info={item.wheelchair ? "Si" : "No"}
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Número motor"}
            info={item.engine}
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Companía Seguro"}
            info={item.insurance_company}
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Vencimiento Seguro"}
            info={
              <Moment
                date={item.insurance_expiration_date.toDate()}
                format="DD/MM/YYYY"
              />
            }
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Foto Seguro"}
            info={<FbImageModal src={item.insurance_front} />}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          {item.owner ? (
            <InfoRow
              add={add}
              rm={rm}
              label={"Foto Frente Cédula Azul"}
              info={<FbImageModal src={item.owner.blue_card_front} />}
            />
          ) : (
            <InfoRow
              add={add}
              rm={rm}
              label={"Foto  Frente Cédula Verde"}
              info={<FbImageModal src={item.green_card_front} />}
            />
          )}
        </Col>
        <Col lg="6">
          {item.owner ? (
            <InfoRow
              add={add}
              rm={rm}
              label={"Foto Dorso Cédula Azul"}
              info={<FbImageModal src={item.owner.blue_card_back} />}
            />
          ) : (
            <InfoRow
              add={add}
              rm={rm}
              label={"Foto Dorso Cédula Verde"}
              info={<FbImageModal src={item.green_card_back} />}
            />
          )}
        </Col>
        <Col lg="6">
          <InfoRow
            add={add}
            rm={rm}
            label={"Foto VTV"}
            info={<FbImageModal src={item.vtv_front} />}
          />
          {item.vtv_expiration_date ? (
            <InfoRow
              add={add}
              rm={rm}
              label={"Expiración VTV"}
              info={
                <Moment
                  date={item.vtv_expiration_date.toDate()}
                  format="DD/MM/YYYY"
                />
              }
            />
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <br />
      <Center>ESTADO ACTUAL: {revision && textStatus(revision.status)}</Center>
      <FormReview
        uidUser={uidUser}
        setRevision={setRevisionCar(idCar)}
        wrong_fields={labelsWrong}
      />
    </ContainerPage>
  );
};

export default AdminCarRevision;
