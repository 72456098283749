import { useSelector, useDispatch } from "react-redux";
import { profileUserSelector } from "redux/selectors";
import { StepsFormNewcomers, ProfileStatus } from "types/user-data";
import { Button, SubmitButton, Link } from "components/styles";
import React, { useState, useEffect } from "react";
import {
  submit,
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed,
  setSubmitFailed,
} from "redux-form";
import ROUTES from "constants/routes";

export const FinishEditing = ({
  setPage,
  isFinishedScreen,
}: {
  setPage: (d: StepsFormNewcomers) => void;
  isFinishedScreen: boolean;
}) => {
  const dispatch = useDispatch();
  const _isSubmitting = useSelector(isSubmitting("NewcomersForm"));
  const _hasSubmitSucceeded = useSelector(hasSubmitSucceeded("NewcomersForm"));
  const _hasSubmitFailed = useSelector(hasSubmitFailed("NewcomersForm"));
  const profileUser = useSelector(profileUserSelector);
  const [submited, setSubmited] = useState(false);
  const isFirstTimeForm =
    profileUser.step_form_newcomers !== StepsFormNewcomers.finished;

  const goToLastPage = () => setPage(StepsFormNewcomers.finished);

  useEffect(() => {
    if (submited) {
      setSubmited(false);
      if (_hasSubmitSucceeded) goToLastPage();
    }
  }, [_hasSubmitSucceeded, _hasSubmitFailed]);

  if (
    profileUser.profile_user_status === ProfileStatus.APROBADO &&
    isFinishedScreen
  )
    return (
      <Link to={ROUTES.USER_HOME}>
        <Button
          variant="info"
          className="pull-right"
          style={{ marginTop: 10, marginLeft: 10 }}
        >
          Ir a inicio
        </Button>
      </Link>
    );

  if (isFirstTimeForm || isFinishedScreen) return <></>;

  const submitAndGoToLastPage = () => {
    dispatch(submit("NewcomersForm"));
    setSubmited(true);
  };

  return (
    <>
      <Button
        onClick={goToLastPage}
        variant="warning"
        className="pull-right"
        style={{ marginTop: 10, marginLeft: 10 }}
      >
        Descartar
      </Button>
      <SubmitButton
        onClick={submitAndGoToLastPage}
        submitting={_isSubmitting && submited}
        variant="success"
        className="pull-right"
        style={{ marginTop: 10 }}
      >
        Guardar
      </SubmitButton>
    </>
  );
};
