import { Table } from "antd";
import { Link, Padding, Spinner } from "components/styles";
import ROUTES from "constants/routes";
import { WithId } from "controllers/database";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { getLocalitiesConfig } from "../Global/reducer";
import { CustomConfig, ZoneConfig } from "types/config";

const Localities = () => {
  const [items, setItems] = useState([] as (CustomConfig & WithId)[]);
  const [loading, setLoading] = useState(true);

  const updateData = () => {
    setLoading(true);
    getLocalitiesConfig()
      .then(setItems)
      .then(() => setLoading(false));
  };
  useEffect(() => {
    updateData();
  }, []);

  if (loading) return <Spinner />;
  return (
    <Padding pad="10px">
      <Row style={{ marginBottom: 10 }}>
        <Col>
          <Link to={ROUTES.ADMIN_CONFIGURATION_NEW_LOCALITY}>
            <Button className="pull-right" variant="dark">
              Nueva Localidad
            </Button>
          </Link>
        </Col>
      </Row>
      <TableConfig
        items={items}
        link={ROUTES.ADMIN_CONFIGURATION_VIEW_LOCALITY}
      />
    </Padding>
  );
};

export const TableConfig = ({
  items,
  link,
}: {
  items: (CustomConfig & WithId)[] | (ZoneConfig & WithId)[];
  link: (id: string) => string;
}) => {
  const columns: any = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      width: 80,
      fixed: "left",
    },
    {
      title: "Precio",
      children: [
        {
          title: "Km Viaje Largo",
          dataIndex: "precioKmLargo",
          key: "precioKmLargo",
          width: 70,
        },
        {
          title: "Hasta 10Km",
          dataIndex: "precioKmCorto",
          key: "precioKmCorto",
          width: 70,
        },
        {
          title: "Excedente 10-25KM",
          dataIndex: "precioKmExcess",
          key: "precioKmExcess",
          width: 85,
        },
        {
          title: "Excedente >25KM",
          dataIndex: "precioKmSecondExcess",
          key: "precioKmSecondExcess",
          width: 85,
        },
        {
          title: "Minuto Traslado",
          dataIndex: "price_minute_trip",
          key: "price_minute_trip",
          width: 70,
        },
        {
          title: "Minuto Espera",
          dataIndex: "price_minute_wait",
          key: "price_minute_wait",
          width: 70,
        },
      ],
    },
    {
      title: "Minimos",
      children: [
        {
          title: "Precio Viaje",
          dataIndex: "minimosPrecio",
          key: "minimosPrecio",
          width: 70,
        },
        {
          title: "Minutos Espera",
          dataIndex: "minimosEspera",
          key: "minimosEspera",
          width: 70,
        },
        {
          title: "Salario Viaje Corto",
          dataIndex: "minimum_salary_short_transfer",
          key: "minimum_salary_short_transfer",
          width: 70,
        },
        {
          title: "Salario Viaje Largo",
          dataIndex: "minimum_salary_long_transfer",
          key: "minimum_salary_long_transfer",
          width: 70,
        },
      ],
    },
    {
      title: "Máximo Precio Viaje Negativo",
      dataIndex: "maximum_price_negative_transfer",
      key: "maximum_price_negative_transfer",
      width: 100,
    },
    {
      title: "Mínimo Precio Viaje Negativo",
      dataIndex: "minimum_price_negative_transfer",
      key: "minimum_price_negative_transfer",
      width: 100,
    },
    {
      title: "Radio de disparo de alertas",
      dataIndex: "radioAlertas",
      key: "radioAlertas",
      width: 100,
    },
    {
      title: "Max distancia a punto de encuentro",
      dataIndex: "maxDistancia",
      key: "maxDistancia",
      width: 100,
    },
    {
      title: "Minutos día",
      dataIndex: "minutosDia",
      key: "minutosDia",
      width: 100,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 110,
      fixed: "right",
      render: (_: any, record: CustomConfig & WithId) => (
        <Link to={link(record.id)}>
          <Button variant="light">Ver/Editar</Button>
        </Link>
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < items.length; i++) {
    data.push({
      key: i,
      nombre: items[i].name,
      precioKmLargo: items[i].price_km_long_trip,
      precioKmExcess: items[i].price_km_excess,
      precioKmSecondExcess: items[i].price_km_second_excess,
      precioKmCorto: items[i].price_km_short_trip,
      price_minute_wait: items[i].price_minute_wait,
      price_minute_trip: items[i].price_minute_trip,
      maximum_price_negative_transfer: items[i].maximum_price_negative_transfer,
      minimum_price_negative_transfer: items[i].minimum_price_negative_transfer,
      minimosPrecio: items[i].price_minimum_trip,
      minimosEspera: items[i].minimum_wait,
      minimum_salary_short_transfer: items[i].minimum_salary_short_transfer,
      minimum_salary_long_transfer: items[i].minimum_salary_long_transfer,
      radioAlertas: items[i].minimum_radius,
      maxDistancia: items[i].maximum_distance_origin,
      minutosDia: items[i].minutes_day,
      id: items[i].id,
    });
  }

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      scroll={{ x: 1500, y: 700 }}
    />
  );
};

export default Localities;
