import { minkLength, min3Length, required } from 'controllers/validations'

export const validate = (values : any) => {
    var errors : {[k: string] : string} = {}
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'E-mail inválido.'
    }
    if(values.password !== values.confirm_password) {
        errors.confirm_password = 'Las contraseñas no coinciden.'
    }
    return errors
}

export { minkLength, min3Length, required }