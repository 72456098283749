const encode = (params : any) => {
    var stack = [{namespace: '', value: params}]
    var res = ""
    var first = true
    while(stack.length > 0) {
        var top = stack.pop() as any
        if(typeof top.value !== 'object') {
            res += `${first? '?' : '&'}${top.namespace}=${encodeURIComponent(top.value)}`
            first = false
        } else {
            Object.keys(top.value).forEach(key => {
                stack.push({namespace: `${top.namespace}${top.namespace===''?'':'.'}${key}`, value: top.value[key]})
            })
        }

    }
    return res
}

export const get = (url : string, params : any) : Promise<any> => {
    const encodedParams = encode(params)
    return fetch(url+encodedParams)
        .then(res => res.json())
}

/*
* Send POST request to API. Params needs to be an javascript object.
*/
export const post = (url : string, params : any) : Promise<Response> => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify( params )
    };
    return fetch(url, requestOptions)
 
}