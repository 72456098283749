import {
  Button,
  H2,
  Padding,
  Spinner,
  NoData,
  Searcher,
} from "components/styles";
import ROUTES from "constants/routes";
import React, { useEffect, useState } from "react";
import { Col, Row, Table as TableB, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { usePaginatorLive } from "util/hooks";
import { StepsFormNewcomers } from "components/User/Newcomers/pages";
import MiniNavigationErrors from "./MiniNavigation/index";
import queryString from "query-string";
import {
  getCollectionSearchByKeyWords,
  getCollectionByType,
  getCollectionUser,
} from "./users_reducer";
import { WithId } from "controllers/database";
import { User } from "types/user-data";

const AdminProfile = () => {
  return (
    <MiniNavigationErrors active={1}>
      <MainContainer />
    </MiniNavigationErrors>
  );
};

export const MainContainer = () => {
  const [searchKeyWord, setSearchKeyWord] = useState<string>("");
  const [result, setResult] = useState<(User & WithId)[]>([]);
  const history = useHistory();
  const parsed = queryString.parse(window.location.search);
  const pageLimit = 10000;

  const [items, loading, Pagination, setCollection] = usePaginatorLive(
    getCollectionUser(),
    pageLimit
  );

  const searchByKeyWord = (s: string) => {
    if (s === "") {
      return changeType(0);
    } else {
      history.push(`?keyword=${s}`.toLocaleLowerCase());
    }
  };
  console.log(
    parsed.filterType,
    "parsed.filterType",
    parsed.keyword,
    "parsed.keyword"
  );
  console.log(searchKeyWord, "items");
  const changeType = (type: number) => {
    console.log(type);
    if (type === 0 && searchKeyWord) {
      history.push(`?filterType=${type}&keyword=${searchKeyWord}`);
    } else if (type === 0 && searchKeyWord && parsed.filterType) {
      history.push(`?filterType=${type}&keyword=${searchKeyWord}`);
    } else if (type === 0 && parsed.filterType) {
      history.push(`?filterType=${type}`);
    } else if (searchKeyWord) {
      history.push(`?filterType=${type}&keyword=${searchKeyWord}`);
    } else {
      history.push(`?filterType=${type}`);
    }
  };
  useEffect(() => {
    if (Object.keys(parsed).length === 0) {
      setResult([]);
      getCollectionUser();
    } else if (parsed.keyword === undefined && parsed.filterType === "0") {
      console.log("entro");
      setResult([]);
      setCollection(getCollectionUser());
    } else if (parsed.keyword === undefined && parsed.filterType !== "0") {
      console.log("entro2");
      setResult([]);
      setCollection(getCollectionByType(Number(parsed.filterType) - 1));
    } else if (
      parsed.keyword !== undefined &&
      (parsed.filterType == undefined || parsed.filterType === "0")
    ) {
      setResult([]);
      setSearchKeyWord(parsed.keyword as string);
      const results = items.filter(
        (key) =>
          key.surname
            .toLocaleLowerCase()
            .includes(searchKeyWord.toLocaleLowerCase()) ||
          key.name
            .toLocaleLowerCase()
            .includes(searchKeyWord.toLocaleLowerCase())
      );

      setResult(results);
    } else if (parsed.keyword !== undefined && parsed.filterType !== "0") {
      setSearchKeyWord(parsed.keyword as string);
      setResult([]);
      const results = items
        .filter(
          (key) =>
            key.surname
              .toLocaleLowerCase()
              .includes(searchKeyWord.toLocaleLowerCase()) ||
            key.name
              .toLocaleLowerCase()
              .includes(searchKeyWord.toLocaleLowerCase())
        )
        .filter(
          (user) => user.step_form_newcomers === Number(parsed.filterType) - 1
        );

      setResult(results);
    }
  }, [parsed.filterType, parsed.keyword]);
  console.log(result, "veo");
  const optionsFilter = [
    "Todas",
    "Bienvenida",
    "Perfil",
    "Licencia",
    "Facturación",
    "Auto",
    "Terminos y condiciones",
    " Quiz",
    "Afip/Gestorando",
    "Finalizado",
  ];
  return (
    <>
      <Padding pad="5px">
        <Row>
          <Col md={2}>
            <H2>USUARIOS</H2>
          </Col>
          <Col md={3}>
            <Searcher
              onChange={setSearchKeyWord}
              onSearch={searchByKeyWord}
              placeHolder="Buscar por apellido/nombre"
              value={searchKeyWord}
              tooltip={<>Apellido</>}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              as="select"
              value={parsed.filterType !== undefined ? parsed.filterType! : "0"}
              onChange={(event) =>
                changeType(
                  parseInt((event.target as HTMLTextAreaElement).value)
                )
              }
            >
              {optionsFilter.map((op, index) => (
                <option key={index} value={index}>
                  {op}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <br />
        {loading ? (
          <Spinner style={{ margin: 20 }} />
        ) : items.length === 0 ? (
          <NoData />
        ) : (
          <Table hover responsive>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Email</th>
                <th>Teléfono</th>
                <th>Estado de Registro</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {result.length === 0
                ? items !== undefined &&
                  items.map((item, index) => (
                    <tr key={index}>
                      <td style={{ wordBreak: "break-word" }}>
                        {item.name + " " + item.surname}
                      </td>
                      <td>{item.email}</td>
                      <td>{item.phone.area_code + " " + item.phone.number}</td>
                      <td>
                        {item.step_form_newcomers ===
                        StepsFormNewcomers["beginning"]
                          ? "Bienvenida"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["profile"]
                          ? " Perfil"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["licence"]
                          ? "Licencia"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["taxable"]
                          ? "Facturación"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["car"]
                          ? "Auto"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["terms_conditions"]
                          ? "Terminos y condiciones"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["quiz"]
                          ? " Quiz"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["afip"]
                          ? "Afip"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["finished"]
                          ? "Finalizado"
                          : null}
                      </td>
                      <td>
                        <Link to={ROUTES.ADMIN_USER_PROFILE_VIEW(item.id)}>
                          <Button variant="light">Ver Usuario</Button>
                        </Link>
                      </td>
                    </tr>
                  ))
                : result !== undefined &&
                  result.map((item, index) => (
                    <tr key={index}>
                      <td style={{ wordBreak: "break-word" }}>
                        {item.name + " " + item.surname}
                      </td>
                      <td>{item.email}</td>
                      <td>{item.phone.area_code + " " + item.phone.number}</td>
                      <td>
                        {item.step_form_newcomers ===
                        StepsFormNewcomers["beginning"]
                          ? "Bienvenida"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["profile"]
                          ? " Perfil"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["licence"]
                          ? "Licencia"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["taxable"]
                          ? "Facturación"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["car"]
                          ? "Auto"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["terms_conditions"]
                          ? "Terminos y condiciones"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["quiz"]
                          ? " Quiz"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["afip"]
                          ? "Afip"
                          : item.step_form_newcomers ===
                            StepsFormNewcomers["finished"]
                          ? "Finalizado"
                          : null}
                      </td>
                      <td>
                        <Link to={ROUTES.ADMIN_USER_PROFILE_VIEW(item.id)}>
                          <Button variant="light">Ver Usuario</Button>
                        </Link>
                      </td>
                    </tr>
                  ))}
              <Pagination />
            </tbody>
          </Table>
        )}
      </Padding>
    </>
  );
};

const Table = styled(TableB)`
  background-color: #fff;
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export const ButtonSearch = styled(Button)`
  background-color: transparent !important;
  color: grey !important;
`;

export default AdminProfile;
