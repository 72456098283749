import {
  Button,
  H2,
  NoData,
  Padding,
  Searcher,
  Spinner,
} from "components/styles";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import { WithId } from "controllers/database";
import { useDbCollection } from "controllers/hooks";
import { getFullAddress } from "controllers/map";
import firebase from "firebase";
import { isEmpty } from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Modal, Row, Table as TableB } from "react-bootstrap";
import Moment from "react-moment";
import { isLoaded } from "react-redux-firebase";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  DefinitionsModuleTransferType,
  ModuleTransfer,
  TypeModuleReject,
} from "types/module-transfer";
import { TransferStage } from "types/transfer";
import { usePaginator } from "util/hooks";
import * as crossExperta from "./cruces_experta.json";
import MiniNavigationErrors from "../MiniNavigation";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";

const AdminTransfersWrongIDPage = () => {
  return (
    <MiniNavigationErrors active={5}>
      <MainContainer
        viewTransfer={ROUTES.ADMIN_MODULE_TRANSFER_VIEW}
        art={false}
      />
    </MiniNavigationErrors>
  );
};

export const MainContainer = ({
  viewTransfer,
  art,
}: {
  viewTransfer: (a: any) => string;
  art: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [transferModal, setTransferModal] = useState<any>("");
  const pageLimit = 10;
  const [items, loading, Pagination, setCollection] = usePaginator(
    FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("art.name", "==", "Experta SML")
      .where("reject.type", "==", TypeModuleReject["Location not exist"]),
    pageLimit
  );
  return (
    <Padding pad="5px">
      <Row>
        <Col md={2}>
          <H2>Cruce Inexistente</H2>
        </Col>
      </Row>

      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        <Table hover responsive>
          <thead>
            <Tr>
              <th>Siniestro-Aut/NPA</th>
              <th>Fecha</th>
              <th>Modalidad</th>
              <th>ART</th>
              <th>Paciente</th>
              <th>Origen</th>
              <th>Destino</th>
              <th>Retorno</th>
              <th></th>
            </Tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id}>
                <Td style={{ wordBreak: "break-all" }}>
                  {item.art.accident && item.art.authorization
                    ? item.art.accident + "-" + item.art.authorization
                    : item.art.accident
                    ? item.art.accident
                    : "NPA: " + item.art.npa}
                </Td>
                <Td>
                  {moment(item.origin.time.toDate()).format("DD/MM/YYYY")}
                </Td>

                <Td>{DefinitionsModuleTransferType[item.type]}</Td>
                <Td>{item.art.name}</Td>
                <Td>{item.passenger.name}</Td>
                <Td>
                  {item.origin
                    ? item.origin.address
                      ? item.origin.address.formatted_address
                      : "dirección a corregir"
                    : "dirección a corregir"}
                </Td>
                <Td>
                  {item.destination
                    ? item.destination.address
                      ? item.destination.address.formatted_address
                      : "dirección a corregir"
                    : "dirección a corregir"}
                </Td>
                <Td>
                  {item.backtrack && item.backtrack.address.formatted_address}
                </Td>
                <Td>
                  <Button
                    onClick={() => {
                      setShowModal(true);
                      setTransferModal(item.id);
                    }}
                    variant="light"
                  >
                    Agregar Cruce
                  </Button>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Pagination />
      <AddLocationModal
        module={transferModal}
        show={showModal}
        handleClose={(success: boolean) => setShowModal(false)}
        setCollection={() => {
          setCollection(
            FIRESTORE.COLLECTION_MODULES_TRANSFER().where(
              "reject.type",
              "==",
              TypeModuleReject["Location not exist"]
            )
          );
        }}
      />
    </Padding>
  );
};

export const AddLocationModal = ({
  module,
  show,
  handleClose,
  setCollection,
}: {
  module: string;
  show: boolean;
  handleClose: any;
  setCollection: any;
}) => {
  const [field1, setField1] = useState("");
  const [options1, setOptions1] = useState<any[]>([]);
  const [success, setSuccess] = useState(false);

  const [field2, setField2] = useState("");
  const [options2, setOptions2] = useState<any[]>([]);

  const cruces = crossExperta as any;
  console.log(crossExperta);
  const crucesUnique = cruces.default.cruces.filter(
    (value: any, index: any, self: any) => {
      return (
        self.findIndex(
          (v: any) => v["ID ciudad origen"] === value["ID ciudad origen"]
        ) === index
      );
    }
  );

  const changeOptions1 = (str: string) => {
    setField1(str);
    if (str.length > 3) {
      const filtrado = crucesUnique.filter((cruce: any) =>
        cruce["ciudad origen"].includes(str)
      );
      setOptions1(filtrado);
      const finalStr = str.split("-");
      setOptions2(
        cruces.default.cruces.filter(
          (cruce: any) =>
            cruce["ciudad origen"].includes(finalStr[0]) &&
            cruce["provincia origen"].includes(finalStr[1])
        )
      );
    }
  };
  console.log(field1);

  const submit = async (module: string) => {
    const finalStr = field1.split("-");
    const finalStr2 = field2.split("-");

    const encontrado = cruces.default.cruces.filter(
      (cruce: any) =>
        cruce["ciudad origen"].includes(finalStr[0]) &&
        cruce["provincia origen"].includes(finalStr[1]) &&
        cruce["ciudad destino"].includes(finalStr2[0]) &&
        cruce["provincia destino"].includes(finalStr2[1])
    );
    await CALLABLE_FUNCTIONS.ADMIN_ADD_CROSS(module, encontrado[0]);
    setSuccess(true);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        setField1("");
        setField2("");
        if (success) {
          setCollection();
          setSuccess(false);
        }
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Buscar y Agregar</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {success ? (
          <p>Se modificó exitosamente el traslado!</p>
        ) : (
          <>
            <input
              style={{ width: "30%", margin: "5px 5px 0 5px" }}
              list="crosses"
              value={field1}
              onChange={(e) => {
                changeOptions1(e.target.value);
              }}
            ></input>
            <datalist id="crosses">
              {options1.map((option: any) => {
                return (
                  <option
                    key={options1.indexOf(option)}
                    value={`${option["ciudad origen"]}-${option["provincia origen"]}`}
                  />
                );
              })}
            </datalist>
            <input
              style={{ width: "30%", margin: "5px 5px 0 5px" }}
              list="crosses2"
              value={field2}
              onChange={(e) => {
                setField2(e.target.value);
              }}
            ></input>
            <datalist id="crosses2">
              {options2.map((option: any) => {
                return (
                  <option
                    key={options1.indexOf(option)}
                    value={`${option["ciudad destino"]}-${option["provincia destino"]}`}
                  />
                );
              })}
            </datalist>
            <Button
              className="pull-right"
              style={{ width: "30%" }}
              onClick={() => {
                submit(module);
              }}
            >
              Ingresar Cruce
            </Button>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose();
            setField1("");
            setField2("");
            if (success) {
              setCollection();
              setSuccess(false);
            }
          }}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Tr = styled.tr`
  th {
    text-align: center;
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  word-wrap: break-word;
`;

const Table = styled(TableB)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default AdminTransfersWrongIDPage;
