import React, { useState, useEffect } from "react";
import { Container } from "components/styles";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { stepsArray } from "./data";

const Stepper = ({ currentStepNumber }: { currentStepNumber:number }) => {
	const [steps, setSteps] = useState<any[]>([]);

	useEffect(() => {
		const stepsState = stepsArray.map((s, index) => {
			return {
				description: s,
				completed: false,
				selected: index === 0 ? true : false,
				highlighted: index === 0 ? true : false,
			};
		});
		const currentSteps = updateStep(currentStepNumber, stepsState);
		setSteps(currentSteps);
	}, [currentStepNumber]);

	const updateStep = (stepNumber: number, steps: any) => {
		const newSteps = [...steps];
		let stepCounter = 0;
		while (stepCounter < newSteps.length) {
			if (stepCounter === stepNumber) {
				newSteps[stepCounter] = {
					...newSteps[stepCounter],
					highlighted: true,
					selected: true,
					completed: false,
				};
				stepCounter++;
			} else if (stepCounter < stepNumber) {
				newSteps[stepCounter] = {
					...newSteps[stepCounter],
					highlighted: false,
					selected: true,
					completed: true,
				};
				stepCounter++;
			} else {
				newSteps[stepCounter] = {
					...newSteps[stepCounter],
					highlighted: false,
					selected: false,
					completed: false,
				};
				stepCounter++;
			}
		}
		return newSteps;
	};
	const stepsDisplay = steps.map((step: any, index: number) => {
		const { completed, highlighted, description, selected } = step;
		return (
			<StepWrapper key={index}>
				{completed ? (
					<StepNumber key={index} style={{ borderColor: "#67c23a" }}>
						<FontAwesomeIcon icon={faCheck} color='#67c23a' key={index} />
					</StepNumber>
				) : null}
				{!completed !== highlighted ? (
					<StepNumber key={index} active>
						{" "}
						{index + 1}
					</StepNumber>
				) : null}
				{highlighted ? (
					<StepNumber style={{ borderColor: "#303133", color: "#303133" }}>
						{index + 1}
					</StepNumber>
				) : null}

				<h6>
					{" "}
					{!completed !== highlighted ? (
						<StepDescription style={{ color: "LightGray", width: "253px" }}>
							{description}
						</StepDescription>
					) : null}
				</h6>
				{highlighted ? (
					<StepDescription style={{ width: "253px" }}>{description}</StepDescription>
				) : null}
				{completed ? (
					<StepDescription style={{ color: "#67c23a", width: "253px" }}>
						{description}
					</StepDescription>
				) : null}
				{index === steps.length - 1 ? null : <DividerLine />}
				{completed ? <DividerLine style={{ backgroundColor: "#67c23a" }} /> : null}
			</StepWrapper>
		);
	});
	return <Container className='pl-0'>{stepsDisplay}</Container>;
}

const StepWrapper = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 2rem;
	width: 423px;

	height: 36px;
	padding-left: 0px;
`;

interface IProps {
	active?: boolean;
	primary?: boolean;
}

const StepNumber = styled.div<IProps>`
	border-radius: 80%;
	border: 2px solid;
	border-color: ${(props) => (props.active ? "LightGray" : "#303133")};
	color: LightGray;
	width: 26px;
	height: 26px;
	padding: 1px;
	text-align: center;
	background-color: white;
	font-weight: bold;
	z-index: 1;
`;

const StepDescription = styled.div<IProps>`
	margin-left: 0.6rem;
	color: black;
	font-weight: normal;
	color: ${(props) => (props.active ? "LightGray" : "black")};
	font-weight: ${(props) => (props.active ? "normal" : "bold")};
	${({ primary }) =>
		primary &&
		css`
			color: red;
		`}
`;

const DividerLine = styled.div`
	width: 3px;
	height: 50px;
	background-color: #cacaca;
	position: absolute;
	top: 78%;
	left: 12px;
`;

export default Stepper;
