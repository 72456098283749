import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H3, Padding, Spinner, Button } from "components/styles";
import { FIRESTORE } from "constants/firestore";
import { useDatabaseDocref } from "controllers/hooks";
import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { isLoaded } from "react-redux-firebase";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { QuizParams } from "types/module-transfer";
import { ChargeFrequency, UserType, VatOptions } from "types/user-data";
import firebase from "firebase/app";

const AdminViewArtProfile = () => {
  const { uid } = useParams<QuizParams>();
  let user = useDatabaseDocref(FIRESTORE.DOCUMENT_USER(uid));
  let artInfo = useDatabaseDocref(FIRESTORE.DOCUMENT_ART_INFO(uid));

  const history = useHistory();
  const [editFrequency, setEditFrequency] = useState<boolean>(false);
  const [newFrequency, setNewFrequency] = useState<number>(0);
  const [newCode, setNewCode] = useState<string>("");
  const [editCode, setEditCode] = useState<boolean>(false);
  const [newOperacionales, setNewOperacionales] = useState<string[]>([]);
  const [tempMail, setTempMail] = useState<string>("");
  const [editOperacionales, setEditOperacionales] = useState<boolean>(false);
  const [newMail, setNewMail] = useState<string>("");
  const [editMail, setEditMail] = useState<boolean>(false);
  const [newArea, setNewArea] = useState<string>("");
  const [newPhone, setNewPhone] = useState<string>("");
  const [editPhone, setEditPhone] = useState<boolean>(false);
  const [editIva, setEditIva] = useState<boolean>(false);
  const [newIva, setNewIva] = useState<number>(0);
  const [editCuit, setEditCuit] = useState<boolean>(false);
  const [newCuit, setNewCuit] = useState<string>("");
  const editData = async (campo: string) => {
    switch (campo) {
      case "Frecuencia":
        {
          (
            await firebase
              .firestore()
              .collection("user")
              .doc(uid)
              .collection("art_info")
              .doc(uid)
              .get()
          ).ref
            .update({
              charge_frequency: newFrequency,
            })
            .then(() => {
              window.location.reload(false);
            });
        }
        break;

      case "cuit":
        {
          (
            await firebase
              .firestore()
              .collection("user")
              .doc(uid)
              .collection("art_info")
              .doc(uid)
              .get()
          ).ref
            .update({
              cuit: newCuit,
            })
            .then(() => {
              window.location.reload(false);
            });
        }
        break;

      case "iva":
        {
          (
            await firebase
              .firestore()
              .collection("user")
              .doc(uid)
              .collection("art_info")
              .doc(uid)
              .get()
          ).ref
            .update({
              vat: newIva,
            })
            .then(() => {
              window.location.reload(false);
            });
        }
        break;

      case "phone":
        {
          (await firebase.firestore().collection("user").doc(uid).get()).ref
            .update({
              phone: {
                area_code: newArea,
                number: newPhone,
              },
            })
            .then(() => {
              window.location.reload(false);
            });
        }
        break;

      case "mail":
        {
          (await firebase.firestore().collection("user").doc(uid).get()).ref
            .update({
              email: newMail,
            })
            .then(() => {
              window.location.reload(false);
            });
        }
        break;

      case "code":
        {
          (
            await firebase
              .firestore()
              .collection("user")
              .doc(uid)
              .collection("art_info")
              .doc(uid)
              .get()
          ).ref
            .update({
              internal_code: newCode,
            })
            .then(() => {
              window.location.reload(false);
            });
        }
        break;
    }
  };
  if (!isLoaded(user, artInfo)) return <Spinner />;

  if (!user || !artInfo) return <>Error</>;
  return (
    <Padding pad="5px">
      <Row>
        <Col md={2}>
          <H3 onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </H3>
        </Col>
        <Col md={8}>
          <Row>
            <span style={{ margin: "auto", fontSize: "1.5em" }}>
              {user.name + " " + user.surname} -{" "}
              <b>
                {user.type !== undefined && UserType[user.type].toUpperCase()}
              </b>
            </span>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <Title>Datos del Usuario</Title>
          </Row>
          {editMail ? (
            <InfoRow
              label="Email"
              info={
                <Form.Control
                  type="text"
                  onChange={(e) => setNewMail(e.target.value)}
                />
              }
              info2={
                <Button onClick={() => editData("mail")}>
                  Guardar cambios
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="Email"
              info={user.email}
              info2={
                <Button onClick={() => setEditMail(true)}>Editar Mail</Button>
              }
            />
          )}

          {editPhone ? (
            <InfoRow3
              label="Teléfono"
              info={
                <Form.Control
                  type="text"
                  placeholder="cód. área Ej: 11"
                  onChange={(e) => setNewArea(e.target.value)}
                />
              }
              info2={
                <Form.Control
                  type="text"
                  placeholder="num Tel. Ej: 54888867"
                  onChange={(e) => setNewPhone(e.target.value)}
                />
              }
              info3={
                <Button onClick={() => editData("phone")}>
                  Guardar cambios
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="Teléfono"
              info={user.phone.area_code + " " + user.phone.number}
              info2={
                <Button onClick={() => setEditPhone(true)}>
                  Editar TELEFONO
                </Button>
              }
            />
          )}
          <InfoRow
            label="Dirección"
            info={artInfo.billing_address.address.formatted_address}
          />

          <Row>
            <Title>Datos de ART</Title>
          </Row>
          {editCuit ? (
            <InfoRow
              label="CUIT"
              info={
                <Form.Control
                  type="text"
                  onChange={(e) => setNewCuit(e.target.value)}
                />
              }
              info2={
                <Button onClick={() => editData("cuit")}>
                  Guardar cambios
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="CUIT"
              info={artInfo.cuit}
              info2={
                <Button onClick={() => setEditCuit(true)}>Editar CUIT</Button>
              }
            />
          )}

          {editIva ? (
            <InfoRow
              label="Condición frente IVA"
              info={
                <Form.Control
                  as="select"
                  value={newIva}
                  onChange={(e) => setNewIva(Number(e.target.value))}
                  placeholder={"Seleccione condiciónde IVA"}
                >
                  <option value={0}>Iva excento</option>
                  <option value={1}>Iva inscripto</option>
                </Form.Control>
              }
              info2={
                <Button onClick={() => editData("iva")}>Guardar cambios</Button>
              }
            />
          ) : (
            <InfoRow
              label="Condición frente IVA"
              info={VatOptions[artInfo.vat]}
              info2={
                <Button onClick={() => setEditIva(true)}>
                  Editar Condición
                </Button>
              }
            />
          )}

          <InfoRow
            label="Dirección de facturación"
            info={artInfo.billing_address.address.formatted_address}
          />
          <InfoRow
            label="SMS Habilitado"
            info={<>{artInfo.sms_enabled ? "Si" : "No"}</>}
          />
          {editFrequency ? (
            <InfoRow
              label="Tipo de cuenta"
              info={
                <Form.Control
                  as="select"
                  value={newFrequency}
                  onChange={(e) => setNewFrequency(Number(e.target.value))}
                  placeholder={"Frecuencia de facturado"}
                >
                  <option value={0}>Por día</option>
                  <option value={1}>Por Viaje</option>
                  <option value={2}>Por Semana</option>
                  <option value={3}>Por Quincena</option>
                  <option value={4}>Por Mes</option>
                  <option value={5}>Manual</option>
                </Form.Control>
              }
              info2={
                <Button onClick={() => editData("Frecuencia")}>
                  Guardar cambios
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="Frecuencia de facturado"
              info={ChargeFrequency[artInfo.charge_frequency]}
              info2={
                <Button onClick={() => setEditFrequency(true)}>
                  Editar Frecuencia
                </Button>
              }
            />
          )}

          <InfoRow
            label="Tiempo de vencimiento de pagos"
            info={<>{artInfo.payment_expiracy_days} día/s</>}
          />
          {editCode ? (
            <InfoRow
              label="Código interno"
              info={
                <Form.Control
                  type="text"
                  onChange={(e) => setNewCode(e.target.value)}
                />
              }
              info2={
                <Button onClick={() => editData("code")}>
                  Guardar cambios
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="Código interno"
              info={<>{artInfo.internal_code}</>}
              info2={
                <Button onClick={() => setEditCode(true)}>Editar Código</Button>
              }
            />
          )}

          <Row>
            <Title>Mails Administrativos</Title>
          </Row>
          <ul>
            {artInfo.administration_mails.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
          <Row>
            <Title>Mails Cobranza</Title>
          </Row>
          <ul>
            {artInfo.billing_mails.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
          {editOperacionales ? (
            <>
              <Row>
                <Title>
                  Mails Operacionales{" "}
                  <Button onClick={editData("operacionales")}>
                    Guardar Cambios
                  </Button>
                </Title>
              </Row>
              <ul>
                {artInfo.operations_mails.map((item) => (
                  <li key={item}>
                    {item}
                    <Button
                      variant="danger"
                      onClick={() => {
                        console.log("Falta esto");
                      }}
                    >
                      Eliminar
                    </Button>
                  </li>
                ))}
              </ul>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      setTempMail(e.target.value);
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    variant="success"
                    onClick={() => {
                      const mails = artInfo!.operations_mails;
                    }}
                  >
                    Agregar Mail
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Title>
                  Mails Operacionales{" "}
                  <Button onClick={() => setEditOperacionales(true)}>
                    Editar
                  </Button>
                </Title>
              </Row>
              <ul>
                {artInfo.operations_mails.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </>
          )}
        </Col>
      </Row>
    </Padding>
  );
};

const InfoRow = ({
  label,
  info,
  info2,
}: {
  label: JSX.Element | string | undefined;
  info: JSX.Element | string | undefined;
  info2?: JSX.Element | string | undefined;
}) => (
  <Row style={{ lineHeight: "2.7rem" }}>
    <Col md={4} style={{ color: "#888" }}>
      {label}
    </Col>
    <Col style={{ color: "#333" }}>{info}</Col>
    <Col style={{ color: "#333" }}>{info2}</Col>
  </Row>
);
const InfoRow3 = ({
  label,
  info,
  info2,
  info3,
}: {
  label: JSX.Element | string | undefined;
  info: JSX.Element | string | undefined;
  info2?: JSX.Element | string | undefined;
  info3?: JSX.Element | string | undefined;
}) => (
  <Row style={{ lineHeight: "2.7rem" }}>
    <Col md={4} style={{ color: "#888" }}>
      {label}
    </Col>
    <Col style={{ color: "#333" }}>{info}</Col>
    <Col style={{ color: "#333" }}>{info2}</Col>
    <Col style={{ color: "#333" }}>{info3}</Col>
  </Row>
);
const Title = styled.h3`
  margin: 10px 5px;
  padding: 10px 5px;
  background-color: #f1f1f1;
  font-size: 1.17em;
  font-weight: 700;
  width: 100%;
`;

export default AdminViewArtProfile;
