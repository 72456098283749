import { List, Skeleton, Table } from "antd";
import { DatePickerInput } from "components/input/date-picker";
import { Field } from "components/input/field";
import {
  Button,
  ButtonWithConfirmation,
  ContainerPage,
  H3,
  NoData,
  PageTitle,
  QuestionTooltip,
  Spinner,
  SubmitButton,
} from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import ROUTES from "constants/routes";
import { useDbCollection } from "controllers/hooks";
import { required } from "controllers/validations";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import { reduxForm, SubmissionError } from "redux-form";
import {
  DefinitionsModuleTransferType,
  ModuleTransfer,
} from "types/module-transfer";
import { User, UserType } from "types/user-data";
import { usePaginator, useQueryParam } from "util/hooks";
import { useSaveSelection } from "../reducer";
import { PreviewBill } from "./PreviewBill";
import { getModuleTransferWithoutArtInvoice } from "./reducer";
import { FIRESTORE } from "constants/firestore";
import { calculateBonif } from "components/Admin/Module_errors/view/wrong_amount_km";

const GenerateBillArt = () => {
  const [artSelected, setArtSelected] = useState<string>(
    "GjP1gJT9T1eK0FGe2kfx6o4T80J2"
  );
  const [startDate, setStartDate] = useQueryParam(
    moment().subtract(14, "days").toDate(),
    "startDate",
    (v) => new Date(v),
    (v) => v.toISOString()
  );

  const [endDate, setEndDate] = useState<Date>(
    moment().subtract(1, "day").toDate()
  );

  const [keyword, setKeyword] = useState<string | undefined>(undefined);
  const [selected, setSelected] = useSaveSelection("generate-bill-art");
  const [data, loading, Pagination, setColl] = usePaginator<ModuleTransfer>(
    getModuleTransferWithoutArtInvoice(
      artSelected,
      startDate,
      endDate,
      keyword,
      undefined
    ),
    10
  );

  console.log(data, "data");
  console.log(selected, "selected");
  console.log(endDate, "endDate");

  const [billModal, setBillModal] = useState(false);
  useEffect(() => {
    setColl(
      getModuleTransferWithoutArtInvoice(
        artSelected,
        startDate,
        endDate,
        keyword,
        undefined
      )
    );
  }, [artSelected, startDate, endDate, keyword]);

  const forceBillModules = async () => {
    //Para facturar x cruce
    /* const crucesDb = (
      await FIRESTORE.COLLECTION_CROSS_EXPERTA().get()
    ).docs.map((d) => d.data());
    const data: { idModule: string; bonif: number }[] = [];
    console.log();

    const modules = [...selected];
    for (let i = 0; i < modules.length; i++) {
      const transfer = modules[i];
      const totales = await calculateBonif(transfer, crucesDb);
      const bonif = totales.bonif <= 0 ? 0 : totales.bonif;
      data.push({
        idModule: transfer,
        bonif,
      });
    }
    console.log(data, "DATA!!!");

    await CALLABLE_FUNCTIONS.FORCE_MULTIPLE_BILLS_BONIF({
      modules: data,
      artId: artSelected,
      manual: true,
    });
    setSelected(new Set()); */
    //Para facturar x KM
    const data: { idModule: string; bonif: number }[] = [];
    const modules = [...selected];
    const listOfPricesDataBase = (
      await FIRESTORE.COLLECTION_SALE_EXPERTA().get()
    ).docs.map((d) => ({ ...d.data(), id: d.id }));
    for (let i = 0; i < modules.length; i++) {
      const transfer = modules[i];
      const totales = await calculateBonif(transfer, listOfPricesDataBase);
      const bonif = totales.bonif <= 0 ? 0 : totales.bonif;
      data.push({
        idModule: transfer,
        bonif,
      });
    }
    console.log(data, "data");

    await CALLABLE_FUNCTIONS.FORCE_MULTIPLE_BILLS_BONIF({
      modules: data,
      artId: artSelected,
      manual: true,
    });
    setSelected(new Set());
  };

  const addAll = () => {
    const z = new Set(selected);
    data.map((item) => {
      z.add(item.id);
    });
    setSelected(z);
  };

  const removeAll = () => {
    const z = new Set();
    setSelected(z);
  };

  return (
    <ContainerPage>
      <PageTitle>Generar Facturas Venta Manual</PageTitle>

      <Row>
        <Col>
          <ArtSelector
            artSelected={artSelected}
            setArtSelected={setArtSelected}
            label="Seleccionar Cliente"
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Desde</Form.Label>
            <DatePickerInput value={startDate} onChange={setStartDate} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Hasta (Inclusive)</Form.Label>
            <DatePickerInput value={endDate} onChange={setEndDate} />
          </Form.Group>
        </Col>
        <Col>
          <Searcher
            keyword={keyword}
            setKeyword={setKeyword}
            legend={
              <>
                Nombre/Teléfono/DNI del paciente
                <br />
                Nombre del conductor
                <br />
                Nombre art
                <br />
                Siniestro
                <br />
                NPA
                <br />
                Dirección Origen/Destino
              </>
            }
          />
        </Col>
      </Row>

      {loading ? (
        <Skeleton paragraph={{ rows: 22 }} />
      ) : !data ? (
        <NoData />
      ) : (
        <>
          <Table
            pagination={false}
            columns={[
              {
                title: "Siniestro-Aut/NPA",
                dataIndex: "id",
                //ellipsis: true
              },
              {
                title: "SML ID",
                dataIndex: "sml_id",
                //ellipsis: true
              },
              {
                title: "Fecha",
                dataIndex: "date",
              },
              {
                title: "Tipo",
                dataIndex: "type",
              },
              {
                title: "Origen",
                dataIndex: "origin",
              },
              {
                title: "Destino",
                dataIndex: "destination",
              },
              {
                title: "Paciente",
                dataIndex: "passenger",
              },
              {
                title: "Agregar",
                dataIndex: "add",
              },
            ]}
            dataSource={data.map((m, index) => ({
              key: index,
              id:
                m.art.accident && m.art.authorization
                  ? m.art.accident + "-" + m.art.authorization
                  : m.art.accident
                  ? m.art.accident
                  : "NPA: " + m.art.npa,
              date: moment(m.destination.time.toDate()).format(
                "DD/MM/YY HH:mm"
              ),
              origin: m.origin.address.formatted_address,
              destination: m.destination.address.formatted_address,
              type: DefinitionsModuleTransferType[m.type],
              passenger: m.passenger.name,
              add: !selected.has(m.id) ? (
                <Button
                  onClick={() => {
                    const z = new Set(selected);
                    z.add(m.id);
                    setSelected(z);
                  }}
                >
                  Agregar
                </Button>
              ) : (
                <Button
                  variant="danger"
                  onClick={() => {
                    const z = new Set(selected);
                    z.delete(m.id);
                    setSelected(z);
                  }}
                >
                  Quitar
                </Button>
              ),
              sml_id: m.transfer_id ? m.transfer_id : "",
            }))}
          />

          <Pagination style={{ marginTop: 10 }} />
        </>
      )}
      <Row>
        <Col>
          <Button className="pull-right" onClick={addAll}>
            Agregar Todas
          </Button>
          <Button variant="danger" className="pull-right" onClick={removeAll}>
            Quitar Todas
          </Button>
        </Col>
      </Row>
      <H3>Vista de factura</H3>
      <PreviewBill selected={[...selected]} />
      <Row>
        <Col>
          <i>
            Nota: Los traslados seleccionados tienen que ser de la misma art
          </i>
        </Col>
        <Col>
          <ButtonWithConfirmation
            className="pull-right"
            variant="success"
            style={{ marginLeft: 5 }}
            onClick={forceBillModules}
            confirmText="¿Está seguro que quiere generar la factura de los traslados seleccionados?"
          >
            Generar Factura Manual
          </ButtonWithConfirmation>
          <Button
            className="pull-right"
            variant="danger"
            style={{ marginLeft: 5 }}
            onClick={() => setBillModal(true)}
          >
            Ingresar Factura Manual
          </Button>
        </Col>
      </Row>

      <ModalGenerateBill
        billModal={billModal}
        setBillModal={setBillModal}
        selected={selected}
      />
    </ContainerPage>
  );
};

export const Searcher = ({
  keyword,
  setKeyword,
  legend,
}: {
  keyword?: string;
  setKeyword: (v: string) => void;
  legend: string | JSX.Element;
}) => {
  return (
    <Form.Group>
      <Form.Label>
        Buscador <QuestionTooltip text={legend} />
      </Form.Label>
      <Form.Control
        value={keyword}
        onChange={(v) => setKeyword(_.toLower(v.target.value))}
      />
    </Form.Group>
  );
};

const ModalGenerateBill = ({
  billModal,
  setBillModal,
  selected,
}: {
  billModal: boolean;
  setBillModal: (a: boolean) => void;
  selected: Set<string>;
}) => {
  const onSubmit = async (values: any) => {
    return CALLABLE_FUNCTIONS.MANUAL_ART_BILL({
      idModules: [...selected],
      pdf: values.pdf,
      CAEFchVto: values.caefchvto.toISOString(),
      voucher_number: values.voucher_number,
      point_of_sale: values.point_of_sale,
    }).catch((e) => {
      throw new SubmissionError({ _error: e });
    });
  };

  return (
    <Modal show={billModal} onHide={() => setBillModal(false)} size="lg">
      <Modal.Header closeButton>Ingreso Factura Manual</Modal.Header>
      <Modal.Body>
        <FormGenerateBillRedux onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};

const FormGenerateBill = ({
  handleSubmit,
  submitting,
  error,
  submitSucceeded,
}: any) => {
  if (submitting) return <Spinner />;
  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error.message}</Alert>}</Row>
      <Row>
        {submitSucceeded && (
          <Alert variant="success" style={{ width: "100%" }}>
            Valores Guardados
          </Alert>
        )}
      </Row>
      <Row>
        <Col>
          <Field
            labelinput={<>Fecha Vencimiento</>}
            name="caefchvto"
            type="date-picker"
            validate={required}
          />
        </Col>
        <Col>
          <Field
            labelinput={<>Número Talonario / Pto de Venta</>}
            name="point_of_sale"
            type="text"
            validate={required}
          />
        </Col>
        <Col>
          <Field
            labelinput={<>Número Factura/Voucher</>}
            name="voucher_number"
            type="text"
            validate={required}
          />
        </Col>
        <Col>
          <Field
            labelinput={<>PDF Factura</>}
            name="pdf"
            type="file"
            validate={required}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <SubmitButton block submitting={submitting}>
            Enviar
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};

/*const FormDecorator = ({children}:any) => {
    
    return ({values}:any) => {
        if (values.submitting) return <Spinner />;
        return ( <>
            <Row>
                {error && <Alert variant="danger">{error.message}</Alert>}
            </Row>
            <Row>
                {values.submitSucceeded && <Alert variant="success" style={{width:'100%'}}>Valores Guardados</Alert>}
            </Row>
            {children}
            </>
        )
    
}}*/

const FormGenerateBillRedux = reduxForm({
  form: "bill-transfer",
})(FormGenerateBill);

export const ArtSelector = ({
  label,
  artSelected,
  setArtSelected,
}: {
  label?: string;
  artSelected: string | undefined;
  setArtSelected: (a: string) => void;
}) => {
  //const arts = useDbCollection<User>("user", [["type", "==", UserType.art]]);
  const arts = [
    { id: "GjP1gJT9T1eK0FGe2kfx6o4T80J2", name: "Feleval SRL" },
    { id: "MekXauxMVPd1ayCGpIwWjg7j6Z73", name: "Experta SML" },
  ];

  return (
    <Form.Group controlId="exampleForm.SelectCustom">
      <Form.Label>{label || "Seleccionar Art"}</Form.Label>
      <Form.Control
        as="select"
        value={artSelected}
        onChange={(e) => {
          setArtSelected(e.target.value);
        }}
      >
        <option value={-1}>Seleccionar</option>
        {arts?.map((v) => (
          <option value={v.id} key={v.id}>
            {v.name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default GenerateBillArt;
