import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Polygon,
} from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";

const libraries: Libraries = ["drawing", "places"];
const PolygonMap = ({
  value,
  onChange,
  disabled,
  isInvalid,
}: {
  value: { lat: number; lng: number }[] | undefined;
  onChange: any;
  disabled: boolean;
  isInvalid: boolean;
}) => {
  const [path, setPath] = [value, onChange];
  const [autocomplete, setAutocomplete] = useState<any>();
  const [firstPosition, setFirstPosition] = useState<any>({
    lat: -38.4160957,
    lng: -63.6166725,
  });
  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const listenersRef = useRef<any>([]);
  const mapRef = useRef<google.maps.Map | null>(null);

  useEffect(() => {
    if (mapRef.current !== null && polygonRef.current !== null) {
      if (disabled) {
        mapRef.current.setOptions({
          draggable: false,
          zoomControl: false,
        });
        polygonRef.current.setDraggable(false);
        polygonRef.current.setEditable(false);
      } else {
        mapRef.current.setOptions({
          draggable: true,
          zoomControl: true,
        });
        polygonRef.current.setDraggable(true);
        polygonRef.current.setEditable(true);
      }
    }
  }, [disabled]);

  const onEdit = useCallback(() => {
    if (polygonRef.current !== null) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng: any) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      onChange(nextPath);
    }
  }, []);

  const onLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },

    [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis: any) => lis.remove());
    polygonRef.current = null;
  }, []);

  const setInitialZone = () => {
    if (mapRef.current === null) return;

    const bounds = mapRef.current.getBounds();
    if (bounds === undefined || bounds === null) return;

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const widthInCoords = Math.abs(ne.lng() - sw.lng());
    const heightInCoords = Math.abs(ne.lat() - sw.lat());
    setPath([
      {
        lat: ne.lat() - heightInCoords / 4,
        lng: sw.lng() + widthInCoords / 4,
      },
      {
        lat: ne.lat() - heightInCoords / 4,
        lng: ne.lng() - widthInCoords / 4,
      },
      {
        lat: sw.lat() + heightInCoords / 4,
        lng: ne.lng() - widthInCoords / 4,
      },
      {
        lat: sw.lat() + heightInCoords / 4,
        lng: sw.lng() + widthInCoords / 4,
      },
    ]);
  };
  const onLoadPlace = (autocomplete: any) => {
    setAutocomplete(autocomplete);
  };
  const changePlace = () => {
    setFirstPosition({
      lat: autocomplete.getPlace().geometry.location.lat(),
      lng: autocomplete.getPlace().geometry.location.lng(),
    });
    if (mapRef.current !== null) mapRef.current.setZoom(10);
  };
  const resetPath = () => {
    if (polygonRef.current === null) return;

    onChange([]);
    setFirstPosition({
      lat: -38.4160957,
      lng: -63.6166725,
    });
    if (mapRef.current !== null) mapRef.current.setZoom(4);
  };

  let className = "";
  if (isInvalid) className = "is-invalid";

  return (
    <div className={className}>
      <Button
        className="pull-right"
        style={{ marginBottom: "15px" }}
        variant="info"
        onClick={setInitialZone}
        disabled={disabled}
      >
        Reset/Crear polígono
      </Button>
      <Button
        className="pull-right"
        style={{ marginBottom: "15px", marginRight: "15px" }}
        variant="warning"
        onClick={resetPath}
        disabled={disabled}
      >
        Reset Cámara y polígono
      </Button>
      <LoadScript
        id="script-loader"
        googleMapsApiKey="AIzaSyD9_LJRGAjiACJ0njksDgoK5m85obW02ng"
        language="es"
        libraries={libraries}
      >
        <GoogleMap
          onLoad={(m) => {
            mapRef.current = m;
            if (value !== undefined && value.length > 2) {
              const bounds = new google.maps.LatLngBounds();
              value.forEach((p) => bounds.extend({ lat: p.lat, lng: p.lng }));

              m.fitBounds(bounds);
            }
          }}
          center={firstPosition}
          zoom={4}
          mapContainerStyle={{ height: "400px", width: "100%" }}
          options={{
            streetViewControl: false,
            draggable: !disabled,
            zoomControl: !disabled,
          }}
        >
          <Polygon
            editable
            options={{
              strokeColor: "#164267",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "rgba(65, 158, 255,8",
              fillOpacity: 0.35,
              editable: !disabled,
            }}
            draggable={!disabled}
            path={path || []}
            onMouseUp={onEdit}
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
          <Autocomplete onLoad={onLoadPlace} onPlaceChanged={changePlace}>
            <input
              type="text"
              placeholder="Ingrese su Ciudad o Zona"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `280px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-140px",
                marginTop: "6px",
              }}
            />
          </Autocomplete>
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default PolygonMap;
