import { FbDate } from "./transfer";
import { dbEntry } from "util/meta";

@dbEntry
export class Sale {
  name!: string;
  price_km!: number;
  price_min_trip!: number;
  price_negative!: number;
  valid_from!: FbDate;
  km_minimun_trip!: number;
  value_records?: ValueRecord[];
}

export interface ValueRecord {
  price_km: number;
  price_min_trip: number;
  price_negative: number;
  km_minimun_trip: number;
  valid_from: FbDate;
  valid_to: FbDate;
}
