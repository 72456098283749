import { moduleTransferStage } from "components/Admin/Transfers/view/module/header";
import { DatePickerInput } from "components/input/date-picker";
import { AdminTable, Button, ContainerPage, H2, Link, NoData, QuestionTooltip, Spinner } from "components/styles";
import ROUTES from "constants/routes";
import { useDbCollection } from "controllers/hooks";
import { getFullAddress } from "controllers/map";
import moment from "moment";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Moment from "react-moment";
import { DefinitionsModuleTransferType, ModuleTransfer } from "types/module-transfer";
import { TransferStageDefinitions } from "types/transfer";
import { User, UserType } from "types/user-data";
import { useDidUpdateEffect, usePaginator } from "util/hooks";
import { getEnumKeys } from "util/typescript";
import { SelectDriverInput } from "../Purchases";
import { getModuleTransfersFiltered } from "./reducer";

export enum TypesTrip {
    "Viajes ingresados",
    "Viajes realizados",
    "Viajes no realizados",
    "Viajes devueltos",
}

const Management = () => {
    const arts = useDbCollection<User>("user", [["type", "==", UserType.art]]);
    const [typeTripSelected, setTypeTripSelected] = useState(0);
    const [artSelected, setArtSelected] = useState("");
    const [search, setSearch] = useState("");
    const [transferStage, setTransferStage] = useState(-1);
    const [driver, setDriver] = useState("");
    const [startDate, setStartDate] = useState<Date>(moment().subtract(14, "days").toDate());
    const [endDate, setEndDate] = useState<Date>(moment().add(14, "days").toDate());

    const [transfers, loading, Pagination, setCol] = usePaginator<ModuleTransfer>(
        getModuleTransfersFiltered(typeTripSelected, artSelected, search, transferStage, driver, startDate, endDate),
        10
    );
    /**
        ■ Fecha Desde - Fecha Hasta
        ■ Prestador
        ■ Siniestro + Aut + NPA ( número de primera asistencia )
        ■ ART
        ■ Cliente
        ■ Nombre del Paciente
        ■ DNI del Paciente
        ■ Tipo de Comprobante + # Comprobante
        ■ Localidad / Provincia Origen
        ■ Localidad / Provincia Destino
        ■ Estado de traslado ( FC, FV, Pag, Cob )

        Viajes Ingresados: cancelados por admin, realizados, negativos
        Viajes realizados: todos los que se terminaron
        Viajes No realizados: todos los que no se terminaron
        Viajes devueltos por el conductor: devueltos por conductor
     */
    useDidUpdateEffect(() => {
        setCol(getModuleTransfersFiltered(typeTripSelected, artSelected, search, transferStage, driver, startDate, endDate));
    }, [typeTripSelected, artSelected, search, transferStage, driver, startDate, endDate]);
    return (
        <ContainerPage>
            <Row style={{ marginBottom: 15 }}>
                <Col>
                    <H2>Gestión</H2>
                </Col>
            </Row>
            <Row>
                <Col xl={4}>
                    <Form.Group>
                        <Form.Label>Tipo</Form.Label>
                        <Form.Control as="select" value={typeTripSelected} onChange={(e) => setTypeTripSelected(Number(e.target.value))}>
                            <option value={-1}>Seleccionar</option>
                            {getEnumKeys(TypesTrip).map((v, index) => (
                                <option value={TypesTrip[v as any]} key={v}>
                                    {v}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col xl={4}>
                    <Form.Group>
                        <Form.Label>
                            Buscador{" "}
                            <QuestionTooltip
                                text={
                                    <>
                                        Nombre/Teléfono/DNI del paciente
                                        <br />
                                        Nombre del conductor
                                        <br />
                                        Nombre art
                                        <br />
                                        Siniestro
                                        <br />
                                        NPA
                                        <br />
                                        Dirección Origen/Destino
                                    </>
                                }
                            />
                        </Form.Label>
                        <Form.Control value={search} onChange={(e) => setSearch(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xl={4}>
                    <SelectDriverInput
                        label={
                            <>
                                Prestador <QuestionTooltip text="No se puede utilizar al mismo tiempo que el buscador" />
                            </>
                        }
                        onChange={setDriver}
                        value={driver}
                    />
                </Col>
                <Col xl={3}>
                    <Form.Group>
                        <Form.Label>Desde</Form.Label>
                        <DatePickerInput value={startDate} onChange={setStartDate} />
                    </Form.Group>
                </Col>
                <Col xl={3}>
                    <Form.Group>
                        <Form.Label>Hasta (Inclusive)</Form.Label>
                        <DatePickerInput value={endDate} onChange={setEndDate} />
                    </Form.Group>
                </Col>
                <Col xl={3}>
                    <Form.Group>
                        <Form.Label>Art</Form.Label>
                        <Form.Control as="select" value={artSelected} onChange={(e) => setArtSelected(e.target.value)}>
                            <option value={-1}>Seleccionar</option>
                            {!arts
                                ? []
                                : arts.map((v) => (
                                      <option value={v.id} key={v.id}>
                                          {v.name}
                                      </option>
                                  ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col xl={3}>
                    <Form.Group>
                        <Form.Label>Etapa traslado</Form.Label>
                        <Form.Control value={transferStage} as="select" onChange={(e) => setTransferStage(Number(e.target.value))}>
                            <option value={-1}>Seleccionar</option>
                            {getEnumKeys(TransferStageDefinitions).map((v) => (
                                <option value={TransferStageDefinitions[v as any]}>{v}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>

            {loading ? (
                <Spinner style={{ margin: 20 }} />
            ) : transfers.length === 0 ? (
                <NoData />
            ) : (
                <AdminTable hover responsive>
                    <thead>
                        <tr>
                            <th>Siniestro-Aut/NPA</th>
                            <th>Modalidad</th>
                            <th>Etapa</th>
                            <th>Fecha Hora Turno</th>
                            <th>Paciente</th>
                            <th>Origen</th>
                            <th>Destino</th>
                            <th>Retorno</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {transfers.map((item) => (
                            <tr key={item.id}>
                                <td style={{ wordBreak: "break-all" }}>
                                    {item.art.accident && item.art.authorization
                                        ? item.art.accident + "-" + item.art.authorization
                                        : item.art.accident
                                        ? item.art.accident
                                        : "NPA: " + item.art.npa}
                                </td>
                                <td>{DefinitionsModuleTransferType[item.type]}</td>
                                <td>{moduleTransferStage(item)}</td>
                                <td>
                                    <Moment date={item.destination.time.toDate()} format="DD/MM/YYYY HH:mm" />
                                </td>
                                <td>{item.passenger.name}</td>
                                <td>{getFullAddress(item.origin)}</td>
                                <td>{getFullAddress(item.destination)}</td>
                                <td>{item.backtrack && getFullAddress(item.backtrack)}</td>
                                <td>
                                    <Link to={ROUTES.ADMIN_MODULE_TRANSFER_VIEW(item.id)}>
                                        <Button variant="light">Ver</Button>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </AdminTable>
            )}
            <Pagination />
        </ContainerPage>
    );
};
/*
export const SelectEnum = ({setValue, value, options}:{setValue:()=>void, value, }) => {

}*/

export default Management;
