import { TransferStage, FbDate, FbGeoPoint } from "./transfer";

export interface Report {
  geopoint: FbGeoPoint;
  creation_date: FbDate;
  stage: TransferStage;
  motive: TransferEventMotive;
  canceled_by_driver?: boolean;
  art_response?: ArtResponse;
  date_end: FbDate;
  date_buy15?: FbDate;
  art_is_resolving?: FbDate;
  messages?: { date: FbDate; message: string }[];
  /**
   * Fecha al terminar el reporte después de haber tomado una acción
   */
  new_date_end?: FbDate;
}

export enum ArtResponse {
  "NEGATIVO",
  "CANCELADO",
  "REPUBLICAR VIAJE",
  "CAMBIAR PUNTO ENCUENTRO",
  "AUTORIZAR RETORNO DEL PACIENTE A ORIGEN",
}

export enum TransferEventMotive {
  "Climatológico",
  "Corte ruta o manifestación",
  "Descompostura de móvil",
  "Decisión Personal",
  "Cancelación de turno",
  "Solicitar cambio de punto de encuentro",
  "Pasajero se niega a viajar",
  "No se logra hacer contacto con el pasajero",
  "Dirección confusa",
  "Paciente informa que el turno médico es en otra dirección",
  "Conductor coordina horario con paciente",
  "Volver a estado agendado",
  "Paciente informa que no viaja",
}
