import { InfoRow, Title } from "components/Admin/Transfers/view/scheduled";
import { Timer } from "components/Art/Reports";
import { AsyncButton, Button } from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { useDbDocument } from "controllers/hooks";
import { getPersonalInformationColDoc } from "controllers/user/info/personal";
import React, { useState } from "react";
import { Col, FormControl, Modal, Row } from "react-bootstrap";
import { Field } from "redux-form";
import { ArtResponse, TransferEventMotive } from "types/report";
import { FbDocument, ScheduledTransfer, TransferStage } from "types/transfer";
import { PersonalInformation } from "types/user-data";
import { ChangeLocationActiveReportModal } from "./modals/change-location";

export const ReportSection = ({
  transfer,
  uidDriver,
  refTransfer,
}: {
  uidDriver: string;
  transfer: ScheduledTransfer;
  refTransfer: FbDocument;
}) => {
  const report = transfer.status.active_report!;
  const [changeLocationModal, setChangeLocationModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showCabify, setShowCabify] = useState(false);

  const driverData = useDbDocument<PersonalInformation>(
    ...getPersonalInformationColDoc(uidDriver)
  );
  console.log("holaaaaaaaaaa", driverData);
  const cabify =
    driverData?.name == "Cabify" || driverData?.surname == "Villasuso";

  const buy15 = async () =>
    await CALLABLE_FUNCTIONS.ADMIN_ART_REPORT_RESOLUTION({
      uidDriver,
      buy15: true,
    });

  const onResolution = async () =>
    await CALLABLE_FUNCTIONS.ADMIN_ART_REPORT_RESOLUTION({
      uidDriver,
      on_resolution: true,
    });

  const onResolutionCabify = async () =>
    await CALLABLE_FUNCTIONS.ADMIN_ART_REPORT_RESOLUTION_CABIFY({
      uidDriver: uidDriver,
      idTransfer: refTransfer!.id,
    });

  const reply = (r: ArtResponse) => async () =>
    await CALLABLE_FUNCTIONS.ADMIN_ART_REPORT_RESOLUTION({
      uidDriver,
      response: r,
      idTransfer: refTransfer.id,
    });

  // const can15BeBought = report.date_buy15 === undefined;
  // console.log(
  //   driverData?.name != "Cabify" || driverData?.surname != "Villasuso"
  // );
  if (report.art_response !== undefined)
    return (
      <Row>
        <Title style={{ backgroundColor: "#ff5e00" }}>
          INFORME ACTIVADO RESPONDIDO: {ArtResponse[report.art_response]}.
          ESPERANDO RESPUESTA DEL CONDUCTOR.
        </Title>
      </Row>
    );
  return (
    <>
      <ChangeLocationActiveReportModal
        refTransfer={refTransfer}
        transfer={transfer}
        uidDriver={uidDriver}
        visible={changeLocationModal}
        handleClose={() => setChangeLocationModal(false)}
      />
      <Row>
        <Title style={{ backgroundColor: "#ff5e00" }}>INFORME ACTIVO</Title>
      </Row>
      <Row>
        <Col>
          <InfoRow
            label="Tiempo restante"
            info={
              <Timer
                d={
                  report.new_date_end
                    ? report.new_date_end.toDate()
                    : report.date_end.toDate()
                }
              />
            }
          />
          <InfoRow label="Motivo" info={TransferEventMotive[report.motive]} />
        </Col>
        <Col>
          <Row style={{ marginBottom: 20 }}>
            {!report.art_is_resolving &&
              driverData?.name != "Cabify" &&
              driverData?.surname != "Villasuso" && (
                <Col xl={6} style={{ marginBottom: 10 }}>
                  <ActionButton text="En Resolución" onClick={onResolution} />{" "}
                </Col>
              )}
            {!report.art_is_resolving &&
              (driverData!.name == "Cabify" ||
                driverData!.surname == "Villasuso") && (
                <Col xl={6} style={{ marginBottom: 10 }}>
                  <ActionButton
                    text="En Resolución"
                    onClick={onResolutionCabify}
                  />{" "}
                </Col>
              )}
            {/* {can15BeBought &&
              report.stage !== TransferStage.scheduled &&
              !cabify && (
                <Col xl={6} style={{ marginBottom: 10 }}>
                  <ActionButton
                    text="Comprar 15 min adicionales"
                    onClick={buy15}
                  />
                </Col>
              )} */}

            {getReportResponses(report.stage, cabify).map((r) => (
              <Col xl={6} style={{ marginBottom: 10 }} key={r.toString()}>
                {r === ArtResponse["CAMBIAR PUNTO ENCUENTRO"] ? (
                  <Button
                    block
                    variant="warning"
                    onClick={() => setChangeLocationModal(true)}
                  >
                    Cambiar punto de encuentro
                  </Button>
                ) : (
                  <ActionButton text={ArtResponseText[r]} onClick={reply(r)} />
                )}
              </Col>
            ))}
            {driverData!.name == "Cabify" ||
            driverData!.surname == "Villasuso" ? null : (
              <Col xl={6} style={{ marginBottom: 10 }}>
                <Button style={{ width: "100%" }} onClick={() => setShow(true)}>
                  {" "}
                  Enviar Mensaje Personalizado
                </Button>
              </Col>
            )}
            {driverData!.name == "Cabify" ||
            driverData!.surname == "Villasuso" ? (
              <Col xl={6} style={{ marginBottom: 10 }}>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => setShowCabify(true)}
                >
                  {" "}
                  Enviar Mensaje Personalizado
                </Button>
              </Col>
            ) : null}
          </Row>
          <ModalResolutionModal
            uidDriver={uidDriver}
            show={show}
            close={() => setShow(false)}
          />
          <ModalResolutionCabifyModal
            uidDriver={uidDriver}
            idTransfer={refTransfer.id}
            show={showCabify}
            close={() => setShowCabify(false)}
          />
        </Col>
      </Row>
    </>
  );
};

const ModalResolutionModal = ({
  uidDriver,
  show,
  close,
}: {
  uidDriver: string;
  show: boolean;
  close: () => void;
}) => {
  const [message, setMessage] = useState<string | undefined>("");
  const onResolution = async () => {
    await CALLABLE_FUNCTIONS.ADMIN_ART_REPORT_RESOLUTION({
      uidDriver,
      on_resolution: true,
      customMessage: message,
    });
    close();
    setMessage("");
  };
  return (
    <Modal show={show} onHide={close} size="lg">
      <Modal.Header closeButton>
        Enviar mensaje personalizado (Opcional)
      </Modal.Header>
      <Modal.Body>
        <FormControl
          type="text"
          placeholder="Mensaje Personalizado"
          as="textarea"
          defaultValue={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Col xl={6} style={{ marginBottom: 10 }}>
          <ActionButton text="Enviar Mensaje" onClick={onResolution} />
        </Col>
      </Modal.Body>
    </Modal>
  );
};

const ModalResolutionCabifyModal = ({
  uidDriver,
  idTransfer,
  show,
  close,
}: {
  uidDriver: string;
  idTransfer: string;
  show: boolean;
  close: () => void;
}) => {
  const [message, setMessage] = useState<string | undefined>("");
  const onResolution = async () => {
    await CALLABLE_FUNCTIONS.ADMIN_ART_REPORT_RESOLUTION_CABIFY_CUSTOM({
      uidDriver,
      idTransfer,
      custom: message,
    });
    close();
    setMessage("");
  };
  return (
    <Modal show={show} onHide={close} size="lg">
      <Modal.Header closeButton>
        Enviar mensaje personalizado (Opcional)
      </Modal.Header>
      <Modal.Body>
        <FormControl
          type="text"
          placeholder="Mensaje Personalizado"
          as="textarea"
          defaultValue={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Col xl={6} style={{ marginBottom: 10 }}>
          <ActionButton text="Enviar Mensaje" onClick={onResolution} />
        </Col>
      </Modal.Body>
    </Modal>
  );
};

const ArtResponseText = {
  [ArtResponse["NEGATIVO"]]: "Confirmar NEGATIVO",
  [ArtResponse["CANCELADO"]]: "Confirmar Cancelación",
  [ArtResponse["REPUBLICAR VIAJE"]]: "Republicar Viaje",
  [ArtResponse["CAMBIAR PUNTO ENCUENTRO"]]: "Cambiar punto de encuentro",
  [ArtResponse["AUTORIZAR RETORNO DEL PACIENTE A ORIGEN"]]:
    "Autorizar Retorno del paciente a ORIGEN",
} as { [key: number]: string };

const ActionButton = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => (
  <AsyncButton block variant="warning" onClick={onClick}>
    {text}
  </AsyncButton>
);

const getReportResponses = (
  stage: TransferStage,
  cabify?: boolean
): ArtResponse[] => {
  if (stage === TransferStage.scheduled)
    return [ArtResponse.CANCELADO, ArtResponse["REPUBLICAR VIAJE"]];

  if (
    (stage === TransferStage.going_to_meeting_point ||
      stage === TransferStage.going_to_meeting_point_return) &&
    !cabify
  )
    return [ArtResponse["CAMBIAR PUNTO ENCUENTRO"], ArtResponse.NEGATIVO];

  if (
    (stage === TransferStage.going_to_meeting_point ||
      stage === TransferStage.going_to_meeting_point_return) &&
    cabify
  )
    return [ArtResponse.NEGATIVO];

  if (
    stage === TransferStage.waiting_on_meeting_point ||
    stage === TransferStage.waiting_on_meeting_point_return
  )
    return [ArtResponse.NEGATIVO];

  if (
    stage === TransferStage.origin_to_destination ||
    stage === TransferStage.destination_to_origin
  )
    return [ArtResponse["AUTORIZAR RETORNO DEL PACIENTE A ORIGEN"]];

  return [];
};
