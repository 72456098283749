import { ReduxDecorator } from "components/bootstrap-redux";
import { handleSubmit } from "components/Login/SignIn/reducer";
import {
  Button,
  Center,
  Container,
  FormControl,
  Link,
  Nav,
  Padding,
  Spinner,
} from "components/styles";
import ROUTES from "constants/routes";
import { UserType } from "controllers/user";
import { required } from "controllers/validations";
import React, { useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import * as session from "redux/session";

const FieldInputFormControl = ReduxDecorator(FormControl);

const SignInPage = (props: any) => {
  const { handleSubmit, error, submitting, pristine } = props;
  const [loadingAuth, setLoadingAuth] = useState(false);

  const userType: UserType = useSelector(
    (state: any) => state.firebase.profile.type
  );

  if (isLoaded(userType)) return <Redirect to={ROUTES.HOME(userType)} />;
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xl={5}>
          <Padding pad="20px">
            <Center>
              <img
                alt="logo"
                style={{ height: "150px" }}
                src="../assets/logo-070b76280dc0f019580a20151dd1e9515757bbfa7650b5d4955ec4eea815fab1.png"
              />
            </Center>
          </Padding>
          <Container>
            {props.location.state?.loggedOut && (
              <Alert variant="success">Sesión finalizada.</Alert>
            )}

            <Form
              onSubmit={(e: any) => {
                handleSubmit(e);
                setLoadingAuth(true);
              }}
            >
              <Form.Group controlId="formBasicEmail">
                <Field
                  component={FieldInputFormControl}
                  name="email"
                  type="email"
                  placeholder="Email"
                  validate={required}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Field
                  component={FieldInputFormControl}
                  name="password"
                  type="password"
                  placeholder="Contraseña"
                  validate={required}
                />
              </Form.Group>
              <Form.Group controlId="formBasicCheckbox">
                <label className="border p-2 col">
                  <Field
                    component={ReduxDecorator(Form.Check)}
                    name="recordarme"
                    type="checkbox"
                    label="Recordarme"
                  />
                </label>
              </Form.Group>
              {error && <Alert variant="danger">{error}</Alert>}

              <Button
                variant="primary"
                type="submit"
                disabled={pristine || submitting}
                block
              >
                {submitting ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 21,
                    }}
                  >
                    <Spinner style={{}} size="sm" />
                  </div>
                ) : (
                  "Entrar"
                )}
              </Button>

              <hr />
              <Link to={ROUTES.SIGN_UP}>
                <Button variant="info" block>
                  Registrate
                </Button>
              </Link>
            </Form>
          </Container>

          <hr />
          <Nav>
            <Nav.Item>
              <Link to={ROUTES.RESET_PASSWORD}>¿Olvidaste tu contraseña?</Link>
            </Nav.Item>
            <Nav.Item>
              <Link to={ROUTES.SEND_INSTRUCTIONS}>
                ¿No recibiste las instrucciones para confirmar?
              </Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
};

export default session.connect(
  reduxForm({
    form: "sign-in",
    onSubmit: handleSubmit,
  })(SignInPage)
);
