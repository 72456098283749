import styled from "styled-components"
import { pxToPt } from "constants/css"


const CustomSelect =  styled.select.attrs(({className, isInvalid, isValid, ...props} : any) => ({
    className: " custom-select form-control " + 
        (isInvalid? 'is-invalid ' : '') +
        (isValid? 'is-valid ' : ''),
    ...props
}))`
    &.input-group-prepend {
        display:flex;
        align-items:center;
        padding:.375rem .75rem;
        margin-bottom:0;
        font-size:1rem;
        font-weight:400;
        line-height:1.5;
        color:#495057;
        text-align:center;
        white-space:nowrap;
        background-color:#e9ecef;
        border:${pxToPt(1)} solid #ced4da;
        border-radius:.25rem
    }

    &.form-control-lg {
        height: calc(1.5em + 1rem + 2px);
        padding: .5rem 1rem;
        padding-right: 1rem;
        font-size: 1.25rem;
        line-height: 1.5;
        border-radius: .3rem;
    }
`

export default CustomSelect