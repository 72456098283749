import React, { useState, useEffect } from "react";
import { Container, Spinner, LinkWhatsApp } from "components/styles";
import styled from "styled-components";
import { Navigation } from "./pages/navigation";
import { reduxForm } from "redux-form";
import { useSelector } from "react-redux";
import FinishedScreen from "./finished";
import { Pages, StepsFormNewcomers } from "./pages";
import { Alert } from "react-bootstrap";
import { FinishEditing } from "./finished/FinishEditing";
import { uidSelector } from "redux/selectors";
import { useProfile } from "redux/app";
import WhatsappIconImg from "assets/whatsapp_icon.png";
import firebase from "firebase/app";
import "firebase/firestore";

const NewcomersWizzard = () => {
  const profile = useProfile();
  const [activePage, setPage] = useState<number>(
    profile.step_form_newcomers ? profile.step_form_newcomers : 0
  );
  const [lastPageCompleted, setLastPageCompleted] =
    useState<number>(activePage);
  const [initialValues, setInitialValues] = useState<any>(undefined);
  const [loadingInitialValues, setLoadingInitialValues] = useState(true);
  const last = activePage + 1 >= Pages.length;
  const first = activePage === 0;
  const uid = useSelector(uidSelector);

  const changePage = (p: number) => {
    setLoadingInitialValues(true);
    setPage(p);
    if (p > lastPageCompleted) setLastPageCompleted(p);
  };

  const loadInitialValues = async () => {
    if (activePage < Pages.length) {
      setLoadingInitialValues(true);
      Pages[activePage]
        .initialValues(uid)
        .then(setInitialValues)
        .then(() => setLoadingInitialValues(false));
    } else {
      setLoadingInitialValues(false);
    }
  };

  const onSubmit = async (values: any) => {
    await Pages[activePage].handleSubmit(uid, values);
    changePage(activePage + 1);

    if (
      profile.step_form_newcomers === undefined ||
      profile.step_form_newcomers !== StepsFormNewcomers.finished
    )
      updateLastPageDb(activePage + 1);
  };

  useEffect(() => {
    loadInitialValues();
  }, [activePage]);

  const updateLastPageDb = (p: number) =>
    firebase.firestore().doc(`user/${uid}`).update({ step_form_newcomers: p });

  const isFinishedScreen = activePage === StepsFormNewcomers.finished;
  const Page = !isFinishedScreen ? Pages[activePage].component : () => <></>;
  return (
    <ContainerEditWizzard>
      <Navigation
        activePage={activePage}
        lastPageCompleted={lastPageCompleted}
        setPage={setPage}
        first={first}
        last={last}
        title={isFinishedScreen ? "Terminado" : Pages[activePage].title}
      />
      <WhatsappBotton />
      {isFinishedScreen ? (
        <FinishedScreen />
      ) : loadingInitialValues ? (
        <Spinner />
      ) : (
        <PageContainer
          onSubmit={onSubmit}
          initialValues={initialValues}
          Page={Page}
        />
      )}
      <FinishEditing setPage={setPage} isFinishedScreen={isFinishedScreen} />
    </ContainerEditWizzard>
  );
};

let PageContainer = ({ submitting, handleSubmit, Page, error }: any) => {
  return (
    <form onSubmit={handleSubmit}>
      <Body>
        {error && <Alert variant="danger">{error.message}</Alert>}
        <Page submitting={submitting} />
      </Body>
    </form>
  );
};
export const WhatsappBotton = () => {
  return (
    <LinkWhatsApp>
      <a href="https://api.whatsapp.com/send?phone=5491151393966&text=Hola!+Satapp%21+Me+estoy+registrando%2C+necesito+ayuda+en+&type=phone_number&app_absent=0">
        <img src={WhatsappIconImg} className={"float my-float"} />
      </a>
    </LinkWhatsApp>
  );
};

PageContainer = reduxForm({
  form: "NewcomersForm",
  //validate
})(PageContainer) as any;

const ContainerEditWizzard = styled(Container)`
  margin-bottom: 50px;
  padding: 0px;
`;

export const Body = styled.div`
  background-color: #f4f4f4;
  padding: 10px 20px 50px 20px;
  font-size: 14px;
  .form-control {
    font-size: 14px;
  }
`;

export default NewcomersWizzard;
