import { LoadScript } from "@react-google-maps/api";
import { Tabs } from "antd";
import { H2, Spinner } from "components/styles";
import { useDatabaseDocref } from "controllers/hooks";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { useHistory, useParams } from "react-router";
import { InfoRow, Title } from "../../Transfers/view/scheduled";

import { FIRESTORE } from "constants/firestore";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { PassengerData } from "components/Admin/Transfers/view/unassigned";
import { Module } from "module";
import {
  ChangeLocationAndCreateModuleDestination,
  ChangeLocationAndCreateModuleOrigin,
} from "./change_location";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { QuizParams } from "types/module-transfer";
const { TabPane } = Tabs;

const libraries: Libraries = ["places", "drawing"];
const AdminModuleTransfersWrongViewPage = () => {
  const { idModule } = useParams<QuizParams>();
  const history = useHistory();
  const [origin, setOrigin] = useState();
  const [destination, setdestination] = useState();
  const [changeLocationOriginModal, setChangeLocationOriginModal] =
    useState(false);
  const [changeLocationDestinationModal, setChangeLocationDestinationModal] =
    useState(false);

  const module = useDatabaseDocref(
    FIRESTORE.DOCUMENT_MODULE_TRANSFER_WRONG(idModule)
  );
  if (!isLoaded(module)) return <Spinner />;

  const remake = async () => {
    const data = {
      module: module,
      new_origin: origin,
      new_destination: destination,
      id: idModule,
    };
    console.log(data);
    await CALLABLE_FUNCTIONS.ADMIN_WRONG_MODULE_REMAKE(data);
    console.log("se recreo el traslado");
  };

  return (
    <div>
      <LoadScript //Script que carga la librería de google maps para el mapa
        id="script-loader"
        googleMapsApiKey="AIzaSyD9_LJRGAjiACJ0njksDgoK5m85obW02ng"
        language="es"
        libraries={libraries}
      >
        <ChangeLocationAndCreateModuleOrigin
          saver={setOrigin}
          visible={changeLocationOriginModal}
          handleClose={() => setChangeLocationOriginModal(false)}
        />
        <ChangeLocationAndCreateModuleDestination
          saver={setdestination}
          visible={changeLocationDestinationModal}
          handleClose={() => setChangeLocationDestinationModal(false)}
        />
        <Row style={{ margin: "20px 0 5px 0" }}>
          <Col>
            <div className="pull-left">
              <H2>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  onClick={() => history.goBack()}
                />
              </H2>
            </div>
          </Col>
        </Row>
        <Row>
          <span style={{ margin: "auto", fontSize: "1.5em" }}>
            Siniestro {module!.art.accident} <br />
            Cod. Aut. {module!.art.authorization} <br />
          </span>
        </Row>
        <Row>
          <Col>
            <PassengerData passenger={module.passenger} />
          </Col>
        </Row>
        <Row>
          <Title>Datos del traslado</Title>
        </Row>
        <InfoRow label="Siniestro" info={module.art.accident} />
        <InfoRow label="AUT." info={module.art.authorization} />
        <InfoRow label="ART" info={module.art?.name} />
        <InfoRow
          label="Dirección de origen"
          info={
            module.origin
              ? module.origin.address
                ? module.origin.address.formatted_address
                : "dirección a corregir"
              : "dirección a corregir"
          }
        />
        <InfoRow
          label="Dirección de destino"
          info={
            module.destination
              ? module.destination.address
                ? module.destination.address.formatted_address
                : "dirección a corregir"
              : "dirección a corregir"
          }
        />
        <br />
        <br />
        {module.dir_er?.includes(0) ? (
          <Button
            className="pull-right"
            style={{ margin: "0 10px 5px" }}
            onClick={() => setChangeLocationOriginModal(true)}
          >
            Corregir <b>ORIGEN</b>
          </Button>
        ) : null}
        {module.dir_er?.includes(1) ? (
          <Button
            className="pull-right"
            style={{ margin: "0 10px 5px" }}
            onClick={() => setChangeLocationDestinationModal(true)}
          >
            Corregir <b>DESTINO</b>
          </Button>
        ) : null}
        {module.dir_er?.includes(0) &&
        module.dir_er?.includes(1) &&
        origin &&
        destination ? (
          <Button
            className="pull-right"
            style={{ margin: "0 10px 5px" }}
            onClick={() => remake()}
          >
            Crear traslado
          </Button>
        ) : null}
        {module.dir_er?.includes(0) && !module.dir_er?.includes(1) && origin ? (
          <Button
            className="pull-right"
            style={{ margin: "0 10px 5px" }}
            onClick={() => remake()}
          >
            Crear traslado
          </Button>
        ) : null}
        {!module.dir_er?.includes(0) &&
        module.dir_er?.includes(1) &&
        destination ? (
          <Button
            className="pull-right"
            style={{ margin: "0 10px 5px" }}
            onClick={() => remake()}
          >
            Crear traslado
          </Button>
        ) : null}
      </LoadScript>
    </div>
  );
};

export default AdminModuleTransfersWrongViewPage;
