import { codigoBuenosAires } from "constants/codigos-telefono";
import { Credential, signIn } from "controllers/auth";
import firebase from "firebase/app";
import "firebase/functions";
import { reduxForm, SubmissionError } from "redux-form";
import { User } from "types/user-data";
import { validate } from "./validations";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";

export const initialValues = {
    phone: {
        area_code: codigoBuenosAires,
        number: "",
    },
};

export const handleSubmit = async (values: User & Credential) => {
    await CALLABLE_FUNCTIONS.REGISTER_USER(values).catch((e: firebase.functions.HttpsError) => {
        throw new SubmissionError({ _error: e.message });
    });
    await signIn(values.email, values.password);
};

export const connect = (component: any) =>
    reduxForm({
        form: "signup-form",
        validate,
        onSubmit: handleSubmit,
        initialValues,
    })(component);
