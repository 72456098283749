import React, { useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import ROUTES from "constants/routes";

import { Container, Button, Nav, Center, Link, Padding, LinkWithoutHref, P } from "components/styles";
import RegisterForm from "./form";
import Success from "./success";
import styled from "styled-components";
import { useHistory } from "react-router";

function SignUpPage(props: any) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const history = useHistory();
    const [succeeded, setSucceeded] = useState(false);
    return (
        <Container>
            <Row className="justify-content-center">
                <Col xl={6}>
                    <Padding pad="20px">
                        <Center>
                            <img
                                alt="logo"
                                style={{ height: "150px" }}
                                src="../assets/logo-070b76280dc0f019580a20151dd1e9515757bbfa7650b5d4955ec4eea815fab1.png"
                            />
                        </Center>
                    </Padding>
                    <div>
                        <Container>
                            <RegisterForm onSubmitSuccess={() => history.push(ROUTES.USER_HOME)}></RegisterForm>
                        </Container>
                        <Nav>
                            <PrivacyContainer>
                                Al registrarte aceptas nuestra{" "}
                                <LinkWithoutHref onClick={handleShow}>política de privacidad</LinkWithoutHref>
                            </PrivacyContainer>
                            <Modal size="lg" show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Center>
                                        <Modal.Title>POLÍTICA DE PRIVACIDAD</Modal.Title>
                                    </Center>
                                </Modal.Header>
                                <Modal.Body>
                                    <Container>
                                        <Center>
                                            <h5>CUESTIONES PRELIMINARES</h5>
                                        </Center>
                                        <P>
                                            La presente Política de Privacidad (en adelante la “Política de Privacidad”) forma parte
                                            integrante e inseparable de los términos y condiciones de uso del usuario en el sitio web de
                                            titularidad de SATAPP S.R.L. (en adelante los "Términos y Condiciones") y la misma debe ser
                                            interpretada en forma armónica y concordante con esos Términos y Condiciones.
                                        </P>
                                        <P>
                                            La presente Política de Privacidad informa sobre la protección integral que SATAPP S.R.L. brinda
                                            a los datos personales y/o financieros asentados en sus archivos, registros, bancos de datos, u
                                            otros medios técnicos de tratamiento de datos que obren en poder de SATAPP S.R.L. para
                                            garantizar el derecho al honor y a la intimidad de las personas, así como también el acceso a la
                                            información que sobre las mismas se registre, de conformidad a lo establecido en el artículo 43,
                                            párrafo tercero de la Constitución Nacional y en un todo de acuerdo con las disposiciones de la
                                            ley nacional de protección de datos personales (Ley 25.326) y a los Tratados Internacionales de
                                            aplicación.
                                        </P>
                                        <P>
                                            Esta Política de Privacidad dispone una serie de pautas que regulan los mecanismos de
                                            tratamiento, almacenamiento, uso y protección de los datos –personales y financieros- que los
                                            usuarios del sitio web URL: https://app.satapp.com.ar de titularidad de SATAPP S.R.L. (en
                                            adelante, “Sitio Web”) y/o de aplicativos similares de su titularidad, como pueden ser
                                            aplicaciones para smartphones, ingresen en el sitio web de SATAPP S.R.L.
                                        </P>
                                        <P>
                                            Una de las prioridades de SATAPP S.R.L. es respetar la privacidad de los usuarios del website y
                                            procurar mantener siempre segura y protegida la información y los datos personales y/o
                                            financieros que SATAPP S.R.L. recolecta a raíz de la utilización del website por los usuarios
                                            (en adelante la "Información").
                                        </P>
                                        <P>
                                            La presente Política de Privacidad detalla e informa al usuario qué tipo de Información
                                            recolecta SATAPP SRL, cómo la almacena, cuál es la finalidad de su archivo, cómo la protege y
                                            cuáles son los derechos del usuario como titular de la Información.
                                        </P>
                                        <Center>
                                            <h5>INFORMACIÓN OBLIGATORIA</h5>
                                        </Center>
                                        <P>
                                            A fin de que el usuario pueda utilizar el website de SATAPP S.R.L. con la correspondiente
                                            obtención de una cuenta usuario, SATAPP S.R.L. necesita conocer la siguiente Información: E-mail
                                            del sujeto, nombre y apellido completo del sujeto que ingresa los datos, tipo documento
                                            identificatorio y número, profesión, género, domicilio, fecha de nacimiento, nacionalidad,
                                            estado civil, número de teléfono y de corresponder razón social de la sociedad, CUIT, Clave
                                            Bancaria Uniforme (CBU) y situación tributaria ante AFIP, los que constituyen datos mínimos,
                                            necesarios e indispensables, según corresponda para la obtención y validación de cada tipo de
                                            usuario a través del website de SATAPP S.R.L.
                                        </P>
                                        <P>
                                            Asimismo, en algunos casos, y dependiendo del tipo de usuario que desee obtener el sujeto, se
                                            solicitarán datos complementarios como: número de licencia de conducir, categoría, fecha de
                                            vencimiento, foto del dorso y frente de la licencia de conducir, nombre y apellido/ razón
                                            social, documento identificatorio y número del titular dominial del vehículo empleado por el
                                            usuario para brindar el servicio de transporte, datos de identificación del automotor como
                                            número de motor y dominio y datos de la póliza del seguro de responsabilidad civil profesional,
                                            lo que incluye foto de la misma.
                                        </P>
                                        <P>
                                            La Información precedente deberá ser veraz, exacta, completa y actualizada, suministrada bajo
                                            declaración jurada por el usuario. El usuario responderá -en cualquier caso- por la veracidad,
                                            exactitud, completitud y actualidad de la Información proporcionada y, en ningún caso SATAPP
                                            S.R.L. será responsable por ello ni por las faltas, vicios u omisiones imputables al usuario al
                                            tiempo del suministro de dicha Información.
                                        </P>
                                        <P>
                                            SATAPP S.R.L. se reserva el derecho de restringir el acceso al website a todo usuario que haya
                                            proporcionado datos falsos, imprecisos, engañosos, confusos, simulando ser otra persona, sin
                                            perjuicio de las demás acciones que procedan conforme a derecho.
                                        </P>
                                        <Center>
                                            <h5>ALMACENAMIENTO DE INFORMACIÓN</h5>
                                        </Center>
                                        Cuando el usuario ingresa sus datos personales y/o financieros al servidor a través del Sitio Web,
                                        éstos son transformados en códigos que viajan en forma encriptada desde su computadora al servidor
                                        que administra SATAPP S.R.L. Una vez recibidos los datos que SATAPP S.R.L. necesita conocer, los
                                        mismos son decodificados y procesados, con excepción de las medidas de seguridad (usuario, claves,
                                        etc.) que permanecen constantemente encriptadas en la base de datos.
                                        <Center>
                                            <h5>FINALIDAD DEL ARCHIVO</h5>
                                        </Center>
                                        <P>
                                            SATAPP S.R.L. usa la Información obtenida para identificar al usuario y procurar un servicio
                                            eficiente. SATAPP SRL no venderá, cederá ni transferirá la Información que obtenga del usuario,
                                            sin autorización previa por parte del usuario.
                                        </P>
                                        <P>
                                            El usuario autoriza a SATAPP S.R.L. a divulgar la Información ingresa en el Sitio Web con la
                                            finalidad de que SATAPP S.R.L. realice gestiones destinadas a la obtención de beneficios
                                            proporcionados por terceros, cuyo destinatario final será el usuario.
                                        </P>
                                        <Center>
                                            <h5>PROTECCIÓN DE LA INFORMACIÓN</h5>
                                        </Center>
                                        <P>
                                            SATAPP S.R.L. tiene incorporados mecanismos de protección de la Información, a fin de evitar
                                            –razonablemente- desviaciones y/o su adulteración, pérdida, consulta o tratamiento de datos no
                                            autorizado. SATAPP SRL trabaja con la más alta encriptación comercialmente disponible, para que
                                            la Información no pueda ser leída mientras circula a través de Internet. Sin embargo, SATAPP
                                            S.R.L. no puede garantizar la seguridad, confidencialidad o inalterabilidad absoluta de la
                                            Información, dado que Internet es una red electrónica abierta y masiva.
                                        </P>
                                        <P>
                                            SATAPP S.R.L. permite a sus empleados y/o a entidades controladas y/o vinculadas y/o bajo el
                                            mismo control común el acceso a la Información del usuario para procesar solicitudes vinculadas
                                            al servicio prestado por SATAPP S.R.L. Sin perjuicio de ello, los datos necesarios para realizar
                                            operaciones, como la clave personal del usuario, no se encuentran a disposición de ningún
                                            empleado, ejecutivo o asesor de SATAPP S.R.L., como así tampoco de las entidades controladas,
                                            vinculadas y/o bajo mismo control común, dado que los mismos son automáticamente encriptados por
                                            la base de datos de SATAPP S.R.L. al ser ingresados por el usuario.
                                        </P>
                                        <Center>
                                            <h5>DERECHOS DEL USUARIO TITULAR DE LA INFORMACIÓN</h5>
                                        </Center>
                                        <P>
                                            El usuario, en su carácter de titular de la Información suministrada a SATAPP S.R.L., tendrá
                                            derecho a acceder a sus datos personales y/o financieros incluidos en la base de datos de SATAPP
                                            S.R.L., y rectificar y/o actualizar y/o suprimir dicha Información, sea a través del mecanismo
                                            previsto en el mismo Sitio Web y/o a través de los canales de contacto que se encuentren
                                            habilitados para tales fines, y que pueden visualizarse en el Sitio Web. La solicitud de
                                            supresión de Información por parte de un usuario no procede cuando pudiese causar perjuicios a
                                            derechos o intereses legítimos de terceros o cuando existiera una obligación legal de SATAPP
                                            S.R.L. de conservar esos datos.
                                        </P>
                                        <P>
                                            El derecho del usuario consagrado en la presente cláusula podrá ser ejercido por el titular de
                                            la Información en forma gratuita y siempre que se trate de un ejercicio razonable, justificado y
                                            se acredite interés legítimo al efecto. El ejercicio de ese derecho les concierne también a los
                                            sucesores universales de las personas fallecidas respecto de la Información de su titularidad.
                                        </P>
                                        <Center>
                                            <h5>MODIFICACIONES EN LA POLÍTICA DE PRIVACIDAD</h5>
                                        </Center>
                                        <P>
                                            SATAPP S.R.L. se reserva el derecho de efectuar, en cualquier momento, modificaciones en la
                                            presente Política de Privacidad y adaptarla a novedades legislativas, jurisprudenciales, así
                                            como a prácticas razonables de mercado. Esas modificaciones serán comunicadas a los usuarios al
                                            email vinculado, con la finalidad de que este proceda a tomar conocimiento de las modificaciones
                                            introducidas por SATAPP S.R.L.
                                        </P>
                                    </Container>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Nav>
                    </div>
                    <hr />
                    <Link to={ROUTES.SIGN_IN}>
                        <Button variant="secondary" block>
                            Ingresar
                        </Button>
                    </Link>
                    <hr />
                    <Nav>
                        <Nav.Item>
                            <Link to={ROUTES.SEND_INSTRUCTIONS}>¿No recibiste las instrucciones para confirmar?</Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
        </Container>
    );
}

const PrivacyContainer = styled(Nav.Item)`
    margin: 10px;
    font-size: 16px;
`;

export default SignUpPage;
