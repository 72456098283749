import * as firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCK5NSBuD4qseCXZhE9IWvoIvCfvDDc09w",
  authDomain: "satapp-27658.firebaseapp.com",
  databaseURL: "https://satapp-27658.firebaseio.com",
  projectId: "satapp-27658",
  storageBucket: "satapp-27658.appspot.com",
  messagingSenderId: "957311587835",
  appId: "1:957311587835:web:a52eb20775de8a858ae75e",
  measurementId: "G-WMFDWJBQN6",
};

firebase.initializeApp(firebaseConfig);
//firebase.functions().useFunctionsEmulator("http://localhost:5001");
firebase.analytics();

export default firebase;
