import {
  Meeting,
  Passenger,
  ArtTransferInfo,
  FinishStatus,
  TransferStage,
  FbDocument,
  FbGeoPoint,
  FbDate,
  ScheduledTransfer,
  TypeAmountTransfer,
} from "./transfer";
import { dbEntry } from "util/meta";
import { SaleModuleTransfer } from "./billing";
import { Address, MapPoint } from "./map";

@dbEntry
export class ModuleTransfer {
  type!: ModuleTransferType;
  origin!: Meeting;
  origin_location?: LocationModule; //only for Experta
  origin_location_revised?: LocationModule; //only for Experta
  missing_new_negative?: boolean;

  /**
   * Destino
   */
  destination!: Meeting;
  destination_location?: LocationModule; //only for Experta
  destination_location_revised?: LocationModule; //only for Experta
  backtrack?: Meeting;

  transfers!: {
    searching_driver?: FbDocument<ScheduledTransfer>;
    active?: FbDocument<ScheduledTransfer>;
    past?: {
      going?: FbDocument<ScheduledTransfer>[];
      return?: FbDocument<ScheduledTransfer>[];
    };
  };

  passenger!: Passenger;
  alert?: boolean;
  status!: StatusModuleTransfer;
  reject?: ModuleReject; // modulo rechazado. Only for Experta
  art!: ArtTransferInfo;
  info!: ModuleTransferInformation;
  sale?: SaleModuleTransfer;
  transfer_id?: string;
  negative?: Negative | any; //TODO: DOCUMENTAR
  priority?: string;
  transfer_id_feleval?: number;
  drivers?: string[];
  api!: boolean;
  price?: any;
  priceCross?: any;
  espera_art?: boolean;
  informado?: {
    status: boolean;
    date: FbDate;
  };
  tracking?: boolean; //Informado via Mail
  searching_cabify?: boolean;
  keywords?: string[];
  pool?: PoolModule;
}
export interface ModuleReject {
  status: boolean;
  type: TypeModuleReject;
  expertaInvoice?: Array<string>;
}
export interface PoolModule {
  poolGoing: boolean;
  poolReturn: boolean;
}
export interface LocationModule {
  id: string;
  description: string;
  province?: {
    id: string;
    description: string;
  };
}

export interface Negative {
  id_module: string; // permite enlazar modulo viejo al nuevo.
  type: typeNegative;
}

export enum typeNegative {
  "completo",
  "parcial",
  "corte de ruta",
}
export enum TypeModuleReject {
  "Location not exist",
  "Id not exist",
  "Stretch amount is greater than control amount",
  "Module amount is greater than control amount",
  "Negative amount is greater than control amount",
  "Experta invoice",
  "transfer_id not exist",
  "No authorization wait",
  "Missing voucher",
  "Missing amount cabify",
  "feleval",
  "No transfer Data",
  "Wrong Cross",
}

export enum TypeModuleRejectSpanish {
  "Sin Localidad",
  "Sin id",
  "Monto incorrecto (TRAMO)",
  "Monto incorrecto (MODULO)",
  "Monto incorrecto (NEGATIVO)",
  "Factura Rechazada",
  "Sin SML ID",
  "No authorization wait",
  "Sin voucher",
  "Cargar $ Cabify",
  "Feleval",
  "Sin traslados",
  "Cruce Incorrecto",
}

export interface ModuleTransferWrong {
  /**
   * Creado a través de la api o no
   */
  api: boolean;

  /**
   * Tipo módulo traslado
   */
  type: ModuleTransferType;

  typeTransfer?: TypeAmountTransfer; //TODO: DOCUMENTAR
  transfer_id?: string; //TODO: DOCUMENTAR
  transfer_id_feleval?: number; //TODO: DOCUMENTAR
  negative?: boolean; //TODO: DOCUMENTAR
  category?: any;

  /**
   * Origen
   */
  origin: any;
  /**
   * Destino
   */
  destination: any;
  /**
   * Retorno
   */
  backtrack?: any;
  /**
   * Traslados del módulo
   */
  /**
   * Datos del pasajero
   */
  passenger: Passenger;
  /**
   * Estado del traslado
   */
  status: StatusModuleTransfer;
  /**
   * Información ART
   */
  art: ArtTransferInfo;
  /**
   * Información de facturación (undefined si no está terminado)
   */
  sale?: SaleModuleTransfer;
  /**
   * Palabras clave (para filtrar)
   */
  /**
   * Info sobre distancia/tiempo del traslado
   */ keywords?: string[];
  /**
   * Observaciones
   */
  obs?: string;
  /**
   * Conductores del módulo (para filtrar)
   */
  drivers?: string[];
  /**
   * Para terminar módulo cuando el informe se resuelve como 'volver a origen' en la ida
   */
  end_module_force?: boolean;
  wheelchair?: boolean | undefined;
  acom_aut?: boolean | undefined;
  force_now_transfer?: boolean;
  force_driver?: string;
  dir_er?: number[];
  /**
   * Estado del traslados que son Desafío
   */
  transfers_challenge?: TransfersWithChallenge;
}
export interface TransfersWithChallenge {
  /**
   * Ida
   */
  going?: {
    /**
     * Referencia del traslado de ida con desafío
     */
    ref: FbDocument<ScheduledTransfer>;
    /**
     * Estado de la facturación del traslado de ida
     */
    billed_from_driver: boolean;
  };
  /**
   * Vuelta
   */
  return?: {
    /**
     * Referencia del traslado de vuelta con desafío
     */
    ref: FbDocument<ScheduledTransfer>;
    /**
     * Estado de la facturación del traslado de vuelta
     */
    billed_from_driver: boolean;
  };
}
export interface ModuleTransferInformation {
  /** Distancia origen a destino en metros */
  distance_origin_destination: number;
  /** Distancia destino al retorno en metros */
  distance_destination_backtrack?: number;
  /** Tiempo de origen a destino en minutos */
  duration_origin_destination: number;
  /** Tiempo de destino al retorno en minutos */
  duration_destination_backtrack?: number;
  zona: string;
  price?: number;
}

export enum ModuleTransferType {
  SIMPLE_TRANSFER,
  TRIP_AND_RETURN,
  TRIP_AND_RETURN_WITH_DIFFERENT_DRIVER,
}
export enum DefinitionsModuleTransferType {
  "Tramo",
  "Módulo con espera",
  "Módulo",
}

export interface StatusModuleTransfer {
  waiting_action_admin_motive: WaitingActionsAdminMotives;
  stage: TransferStage;
  /** Necesitamos saber si estamos haciendo la ida o la vuelta
   * para mapear los estados del traslado al modulo del traslado */
  doing_return: boolean;
  finished_status?: FinishStatus;
  active_report?: boolean;
  history: {
    /**
     * Historial acciones
     */
    actions: ModuleTransferHistoryAction[];
    /**
     * Historial etapas traslado
     */
    stages: TransferHistoryRecord[];
  };
}
export enum WaitingActionsAdminMotives {
  "Devuelto por climatológico/manifestación",
  "Devuelto por dirección confusa",
  "Tramo Farmacia u Ortopedia",
  "Incumplimiento - No se pudo coordinar viaje",
  "Paciente informa que no viaja",
}
export interface TransferHistoryRecord {
  /**
   * Etapa que se empezó
   */
  stage: TransferStage;
  /**
   * Ubicación del suceso (no es necesario en todas las etapas)
   */
  geopoint?: FbGeoPoint;
  /**
   * Fecha del suceso
   */
  time: FbDate;

  description?: string;
}
export interface ModuleTransferHistoryAction {
  action: ModuleTransferAction;
  geopoint?: FbGeoPoint;
  time: FbDate;
}

export enum ModuleTransferAction {
  "Cancelado y devuelto a ART",
  "Traslado cancelado y republicado",
}

export type WithOptional<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

export type PartialMeeting = Location & { time: Date };

export declare type Location = MapPoint & {
  address: Address;
};

export type QuizParams = {
  id: string;
  uid: string;
  uidUser: string;
  idCar: string;
  idContract: string;
  idModule: string;
  idTransfer: string;
};
