import React, { useState, useEffect } from "react";
import { Container, H3, Button, Center, Spinner } from "components/styles";
import { Col, Row } from "react-bootstrap";
import FormVideo from "./FormVideo";
import Stepper from "./Stepper";
import { uidSelector } from "controllers/auth";
import { useSelector } from "react-redux";
import { getStepQuiz, setStepQuiz } from "./reducer";
import { quizData } from "./data";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";

const QuizForm = () => {
  const [questionNumber, setQuestionNumber] = useState(0);
  const [loading, setLoading] = useState(true);

  const uid = useSelector(uidSelector);
  useEffect(() => {
    //Obtengo la etapa del quiz donde dejó antes
    setLoading(true);
    getStepQuiz(uid).then((n) => {
      if (n !== undefined) setQuestionNumber(n.step);

      setLoading(false);
    });
  }, []);

  const handleStep = () => {
    setStepQuiz(uid, questionNumber + 1); //Guardo la etapa en la base de datos
    setQuestionNumber(questionNumber + 1);
  };
  //Activar con Gestorando
  const initGestorando = async (uid: string) => {
    if (
      uid === "bYtlc5cy09VwH8x90aqzXKaUWen1" ||
      uid === "DXLlR4lBALcfvVg63PwM2jSj7nX2"
    ) {
      console.log("No envio la info a Gestorando");
    } else {
      console.log("Envio info a Gestorando");
      await CALLABLE_FUNCTIONS.USER_REGISTER_GESTORANDO(uid);
    }
  };

  if (questionNumber > quizData.length - 1) {
    return (
      <Container>
        <Row>
          <Center>
            <H3>¡Felicidades, terminaste la capacitación!</H3>
            <br />
            <Button
              type="submit"
              variant="info"
              onClick={() => initGestorando(uid)}
            >
              Continuar con formulario
            </Button>
          </Center>
        </Row>
      </Container>
    );
  }
  if (loading) return <Spinner />;
  return (
    <Container>
      <Row>
        <Col sm={5} className="pl-0">
          <Stepper currentStepNumber={quizData[questionNumber].idVideo} />
        </Col>
        <Col sm={7}>
          <FormVideo
            currentStepNumber={quizData[questionNumber].idVideo}
            currentIndexQuestion={questionNumber}
            handleStep={handleStep}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default QuizForm;
