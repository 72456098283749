import React from "react";
import { Required } from "components/styles";
import { Col, Row, Form } from "react-bootstrap";
import { Field } from "components/input/field";
import { required, validarCBU } from "controllers/validations";
import { TaxableEntityType, CBU_CVU, AccountType } from "types/user-data";

const validateCuit = (v: string) =>
  v.length === 11 ? undefined : "Tiene que tener 11 caracteres";

const TaxableData = () => (
  <Row>
    <Col xl={3}>
      <Form.Group>
        <Form.Label>
          <Required />
          Tipo de inscripción
        </Form.Label>
        <Field
          name="tax_entity"
          type="select"
          placeholder="Tipo de inscripción"
          options={TaxableEntityType}
          validate={required}
        />
      </Form.Group>
    </Col>
    <Col xl={3}>
      <Form.Group>
        <Form.Label>
          <Required />
          CUIT/CUIL (El CUIT son 11 dígitos)
        </Form.Label>
        <Field
          parse={(v: string) => v.replace(/-|_/g, "")}
          name="cuit"
          type="text-mask"
          mask="99-99999999-9"
          validate={[required, validateCuit]}
        />
      </Form.Group>
    </Col>
    <Col xl={3}>
      <Form.Group>
        <Form.Label>
          <Required />
          CBU/CVU
        </Form.Label>
        <Field
          name="typeBank"
          type="select"
          placeholder="Elija una opción"
          options={CBU_CVU}
          validate={required}
        />
        <Field
          name="cbu"
          type="text"
          placeholder="Número de CBU/CVU"
          validate={[required, validarCBU]}
        />
      </Form.Group>
    </Col>
    <Col xl={3}>
      <Form.Group>
        <Form.Label>
          <Required />
          Tipo de Cuenta Bancaria
        </Form.Label>
        <Field
          name="typeAccount"
          type="select"
          placeholder="Elija una opción"
          options={AccountType}
          validate={required}
        />
      </Form.Group>
    </Col>
  </Row>
);

export default TaxableData;
