import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { uidSelector } from "redux/selectors";
import { FIRESTORE } from "constants/firestore";
import { FbDate, TransferStage } from "types/transfer";
import moment from "moment";
import { Row, Col, Button } from "react-bootstrap";
import React from "react";
import { H2, Spinner, NoData } from "components/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRecycle } from "@fortawesome/free-solid-svg-icons";
import ModulePriorityCard from "./ModulePriorityCard";

const UserPrioritySchedule = () => {
  const uid = useSelector(uidSelector);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [autorizedRequestReturn, setAutorizedRequestReturn] = useState(true);
  const today = new Date();
  console.log(today);

  const setTransfers = () => {
    setLoading(true);
    FIRESTORE.DOCUMENT_USER(uid)
      .get()
      .then((doc) => doc.data())
      .then((data) =>
        data?.authorizedRequestReturn === false
          ? setAutorizedRequestReturn(false)
          : setAutorizedRequestReturn(true)
      )
      .then(() =>
        FIRESTORE.COLLECTION_MODULES_TRANSFER()
          .where(
            "destination.time",
            ">=",
            FbDate.fromDate(moment().startOf("day").toDate())
          )
          .where(
            "destination.time",
            "<",
            FbDate.fromDate(
              moment(moment()).startOf("day").add(1, "day").toDate()
            )
          )
          .orderBy("destination.time")
          .get()
      )
      .then((data) =>
        data.docs
          .map((d) => ({ data: d.data(), id: d.id }))
          .filter(
            (module) =>
              module.data.priority === uid &&
              module.data.status.stage === TransferStage.waiting_return_request
          )
      )
      .then((filtered) => setItems(filtered))
      .then(() => setLoading(false));
  };
  console.log(items, items.length, "item");

  useEffect(() => {
    const interval = setInterval(() => {
      setTransfers();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTransfers();
  }, []);

  return (
    <div style={{ maxWidth: "100%" }}>
      <Row>
        <Col xs={12}>
          <H2>Retornos con Prioridad</H2>
        </Col>
      </Row>
      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : autorizedRequestReturn === false ? (
        <NoData />
      ) : (
        items.map((modul) => (
          <div key={modul.id}>
            <ModulePriorityCard
              data={modul.data}
              id={modul.id}
              reload={setTransfers}
            />
          </div>
        ))
      )}
      <div
        style={{
          position: "fixed",
          bottom: "3%",
          right: "3%",
        }}
      >
        <Button>
          <FontAwesomeIcon icon={faRecycle} />
        </Button>
      </div>
    </div>
  );
};

export default UserPrioritySchedule;
