import { ContainerPage, PageTitle, SubmitButton } from "components/styles";
import React from "react";
import { Alert } from "react-bootstrap";
import { reduxForm } from "redux-form";
import { FbDate } from "types/transfer";
import { Sale } from "types/sale";
import { ConfigFields } from "./view";
import { addZoneSaleConfig } from "./reducer";

const ConfigNewZoneSale = () => {
  const handleSubmit = (values: any) => {
    const data: Sale = {
      name: values.name,
      price_km: Number(values.price_km),
      price_min_trip: Number(values.price_min_trip),
      price_negative: Number(values.price_negative),
      km_minimun_trip: Number(values.km_minimun_trip),
      valid_from: FbDate.fromDate(new Date(values.valid_from)),
    };
    console.log(data);

    return addZoneSaleConfig(data);
  };
  return (
    <ContainerPage>
      <PageTitle>Nueva Zona de Venta</PageTitle>
      <FormWithRedux onSubmit={handleSubmit} />
    </ContainerPage>
  );
};

const Form = ({
  submitting,
  handleSubmit,
  submitSucceeded,
  disabled = false,
}: any) => {
  if (submitSucceeded)
    return <Alert variant="success">Zona agregada/modificada</Alert>;

  return (
    <form onSubmit={handleSubmit}>
      <ConfigFields disabled={disabled} />
      <SubmitButton submitting={submitting} disabled={disabled}>
        Guardar
      </SubmitButton>
    </form>
  );
};

export const FormWithRedux = reduxForm<{}, any>({
  form: "config-form",
})(Form);

export default ConfigNewZoneSale;
