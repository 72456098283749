import { Button, SubmitButton } from 'components/styles';
import React from 'react';
import { Modal, Row, Alert, Col } from 'react-bootstrap';
import _ from 'lodash';
import { CALLABLE_FUNCTIONS } from 'constants/cloud-functions';
import { Field } from 'components/input/field';
import { required } from 'controllers/validations';
import { reduxForm } from 'redux-form';
import { ScheduledTransfer, TransferStage, FbDocument } from 'types/transfer';

export enum TransferLocations {
    ORIGIN, DESTINATION, BACKTRACK
}

export const ChangeLocationActiveReportModal = ({uidDriver, refTransfer, transfer, visible, handleClose}:
    {uidDriver:string, refTransfer:FbDocument, transfer:ScheduledTransfer, visible:boolean, handleClose:any}) => {
    
    const changeLocation = async (values:any) => {
        await CALLABLE_FUNCTIONS.ADMIN_ART_REPORT_CHANGE_LOCATION({
            uidDriver, 
            address: values.address, 
            transferLocation: transfer.status.stage === TransferStage.going_to_meeting_point ? TransferLocations.ORIGIN 
                : transfer.status.stage === TransferStage.going_to_meeting_point_return ? TransferLocations.DESTINATION : undefined 
        })
    }
    
    return (
    <Modal show={visible} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Reporte - Cambiar Ubicación</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <FormWithRedux onSubmit={changeLocation}/>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
        </Modal.Footer>
    </Modal>
)}

const Form = ({submitting,handleSubmit,submitSucceeded}:any) => {
    if(submitSucceeded)
        return <Alert variant="success">Ubicación modificada</Alert>
    
    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Field 
                        labelinput={<>Dirección</>}
                        name="address" 
                        type="address-with-map"
                        validate={required}/>
                </Col>
            </Row>
            <SubmitButton block submitting={submitting} >Cambiar</SubmitButton>
        </form>
)}

export const FormWithRedux = reduxForm<{},any>({
    form: 'changeloc-form',
})(Form)