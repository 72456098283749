import { FIRESTORE } from "constants/firestore";
import firebase from "firebase";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  FbDocument,
  FinishStatus,
  ScheduledTransfer,
  TransferStage,
  TransferType,
} from "types/transfer";
import { getTotalModuleModal } from "components/Admin/Module_errors/view/wrong_amount";
import { calculateBonif } from "components/Admin/Module_errors/view/wrong_amount_km";

export const PreviewBill = ({ selected }: { selected: string[] }) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (selected) {
      loadData(selected).then(setData);
    }
  }, [selected]);

  const total = data
    .reduce((accum: any, entry: { total: any }) => accum + entry.total, 0)
    .toFixed(2);

  return (
    <div style={{ padding: 50 }}>
      <table style={{ width: "100%" }}>
        <tr style={{ borderBottom: "2px solid black" }}>
          <th>Fecha</th>
          <th>SML ID</th>
          <th>Viajes</th>
          <th>Precio</th>
          <th>Tiempo Comprado</th>
          <th>Comisión</th>
          <th>Peajes</th>
          <th>Bonificación</th>
          <th>Total</th>
        </tr>
        {data.map((v: any, index: any) => (
          <tr
            style={{
              borderBottom:
                index === data.length - 1 ? "2px solid black" : undefined,
            }}
          >
            <td>{v.time}</td>
            <td>{v.sml_id}</td>
            <td>
              {v.id_accident}-{v.auth}
            </td>
            <td>{v.base}</td>
            <td>{v.wait_time}</td>
            <td>{v.agency_commission}</td>
            <td>{v.tolls}</td>
            <td>{v.bonif}</td>
            <td>{v.total.toFixed(2)}</td>
          </tr>
        ))}
        <tr style={{ fontWeight: "bold" }}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <b>TOTAL</b>
          </td>
          <td>
            <b>{total}</b>
          </td>
        </tr>
      </table>
    </div>
  );
};

const loadData = async (selected: string[]) => {
  const selectedModules = await Promise.all(
    selected.map((v) =>
      FIRESTORE.DOCUMENT_MODULE_TRANSFER(v)
        .get()
        .then((v) => ({ ...v.data()!, id: v.id }))
    )
  );

  const listOfPricesDataBase = (
    await FIRESTORE.COLLECTION_SALE_EXPERTA().get()
  ).docs.map((d) => ({ ...d.data(), id: d.id }));

  const moduleEntries = await Promise.all(
    selectedModules.map(async (module) => {
      const amounts = await getTotalModuleModal(module.id);
      console.log(amounts, "amounts");

      const totales = await calculateBonif(module.id, listOfPricesDataBase);
      const bonif = totales.bonif <= 0 ? 0 : totales.bonif;
      const totalAmount = amounts.price + amounts.tolls + amounts.wait - bonif;
      const agency_commission = amounts.comission;
      return {
        time: moment(module.origin.time.toDate()).format("DD/MM/YY HH:mm"),
        auth: module.art.authorization,
        total: totalAmount,
        agency_commission,
        id_module: module.id,
        id_accident: module.art.accident,
        base: Number((amounts.price - agency_commission).toFixed(2)),
        tolls: amounts.tolls,
        wait_time: amounts.wait,
        bonif,
        sml_id: module.transfer_id ? module.transfer_id : "",
        // ImpIVA: amounts.iva, // sumatoria del iva del viaje + iva comision
      };
    })
  );

  return moduleEntries;
};
