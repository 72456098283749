import { Button, H2, NoData, Padding, Searcher, Spinner } from "components/styles";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import { WithId } from "controllers/database";
import { useDbCollection } from "controllers/hooks";
import { getFullAddress } from "controllers/map";
import firebase from "firebase";
import { isEmpty } from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Row, Table as TableB } from "react-bootstrap";
import Moment from "react-moment";
import { isLoaded } from "react-redux-firebase";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { DefinitionsModuleTransferType, ModuleTransfer } from "types/module-transfer";
import { TransferStage } from "types/transfer";
import { usePaginator } from "util/hooks";
import { getModulesByType } from "../Transfers/reducer";
import MiniNavigationErrors from "./MiniNavigation";

const AdminTransfersWrongPage = () => {
    return (
        <MiniNavigationErrors active={1}>
            <MainContainer viewTransfer={ROUTES.ADMIN_MODULE_TRANSFER_WRONG_VIEW} art={false} />;
        </MiniNavigationErrors>
    );
};

export const MainContainer = ({ viewTransfer, art }: { viewTransfer: (a: any) => string; art: boolean }) => {
    const pageLimit = 10;
    const [items, loading, Pagination, setCollection] = usePaginator(FIRESTORE.COLLECTION_MODULES_TRANSFER_WRONG(), pageLimit);
    console.log(items);
    return (
        <Padding pad="5px">
            <Row>
                <Col md={2}>
                    <H2>Traslados con Errores</H2>
                </Col>
            </Row>

            {loading ? (
                <Spinner style={{ margin: 20 }} />
            ) : items.length === 0 ? (
                <NoData />
            ) : (
                <Table hover responsive>
                    <thead>
                        <Tr>
                            <th>Siniestro-Aut/NPA</th>
                            <th>Modalidad</th>
                            <th>ART</th>
                            <th>Paciente</th>
                            <th>Origen</th>
                            <th>Destino</th>
                            <th>Retorno</th>
                            <th></th>
                        </Tr>
                    </thead>
                    <tbody>
                        {items.map((item) =>
                            !item.api ? (
                                <tr key={item.id}>
                                    <Td style={{ wordBreak: "break-all" }}>
                                        {item.art.accident && item.art.authorization
                                            ? item.art.accident + "-" + item.art.authorization
                                            : item.art.accident
                                            ? item.art.accident
                                            : "NPA: " + item.art.npa}
                                    </Td>
                                    <Td>{DefinitionsModuleTransferType[item.type]}</Td>
                                    <Td>{item.art.name}</Td>
                                    <Td>{item.passenger.name}</Td>
                                    <Td>
                                        {item.origin
                                            ? item.origin.address
                                                ? item.origin.address.formatted_address
                                                : "dirección a corregir"
                                            : "dirección a corregir"}
                                    </Td>
                                    <Td>
                                        {item.destination
                                            ? item.destination.address
                                                ? item.destination.address.formatted_address
                                                : "dirección a corregir"
                                            : "dirección a corregir"}
                                    </Td>
                                    <Td>{item.backtrack && item.backtrack.address.formatted_address}</Td>
                                    <Td>
                                        <Link to={viewTransfer(item.id)}>
                                            <Button variant="light">Ver</Button>
                                        </Link>
                                    </Td>
                                </tr>
                            ) : null
                        )}
                    </tbody>
                </Table>
            )}
            <Pagination />
        </Padding>
    );
};

const Tr = styled.tr`
    th {
        text-align: center;
        vertical-align: middle !important;
    }
`;

const Td = styled.td`
    word-wrap: break-word;
`;

const Table = styled(TableB)`
    background-color: #fff;
    /* table-layout: fixed; */
    font-size: 14px;
    thead th {
        color: #909399;
        border-bottom: 0px;
    }
`;

export default AdminTransfersWrongPage;
