import React, { useState } from "react";
import styled from "styled-components";
import BookingForm from "./form";
import EmailConfirmation from "./success";
import * as B from "react-bootstrap";
import { mediaBreakpointUp } from "constants/css";
import { useHistory } from "react-router";
import ROUTES from "constants/routes";

const RegistrationWidget = () => {
    const [succeeded, setSucceeded] = useState(false);
    const history = useHistory();
    return (
        <Container>
            <B.Container>
                <Widget className="col-lg-5">
                    <Ad>
                        Sé parte de la primer empresa de logística inteligente en el traslado de asegurados de ART.
                        <br /> <strong>Registrate ahora, es simple.</strong>
                    </Ad>
                    <BookingForm onSubmitSuccess={() => history.push(ROUTES.USER_HOME)} />
                </Widget>
            </B.Container>
        </Container>
    );
};

const Ad = styled.h3`
    margin: 0 -15px 25px;
    padding: 20px;
    font-size: 16px;
    font-weight: 500 !important;
    letter-spacing: 1px;
    color: #00426a;
    background-color: #fff;
    border-bottom: 1px solid #00426a;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
`;

const Container = styled.div`
    position: relative;

    ${mediaBreakpointUp.lg`
        position: absolute;
        top: 13%;
        left: 0;
        right: 0;
        bottom: 13%;
    `}
`;

const Widget = styled.div`
    padding-top: "10px";
    background-color: #fafafa;
    width: 100%;
    padding: 0 15px 20px 15px;
    border-radius: 20px;
    float: left;
`;

export default RegistrationWidget;
