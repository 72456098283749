import React from 'react';
import { Col, Row, Alert } from 'react-bootstrap';
import ROUTES from 'constants/routes';
import {Container, Button, Center, Nav, Padding, Link} from 'components/styles'


const MainContainer  = () => (
        <Container>
            <Row className="justify-content-center">
                <Col xl={5}>
                    <Padding pad="20px">
                        <Center>
                            <img alt="logo" style={{height:"150px"}} src="../assets/logo-070b76280dc0f019580a20151dd1e9515757bbfa7650b5d4955ec4eea815fab1.png" />
                        </Center>
                    </Padding>
                    <Container>
                        <Alert variant="success">¡Tu email ha sido verificado!</Alert>
                        
                        <hr/>
                        <Link to={ROUTES.SIGN_IN}>
                            <Button variant="primary" block>
                                Ingresar
                            </Button>
                        </Link>
                    </Container>
                </Col>
            </Row>
        </Container>
)

export default MainContainer