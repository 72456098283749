import { Uid } from "controllers/auth";
import database, { WithId, WithUid } from "controllers/database";
import geofirex from "controllers/geofirex";
import { getLocation, Location, Address } from "controllers/map";
import firebase from "firebase/app";
import "firebase/firestore";
import { dbEntry } from "util/meta";
import { User } from "types/user-data";

export const Radius = 8;

@dbEntry
export class Zone {
  //FIXME:
  location!: Location;
  enabled!: boolean;
}

export const getAddressZone = (z: Zone): string =>
  getAddress(z.location.address);

export const getAddress = (z: Address): string =>
  z.street +
  " " +
  z.number +
  ", " +
  z.postal_code +
  " " +
  z.district +
  " " +
  z.province;

export const getZones = (uid: Uid): Promise<(WithId & Zone)[]> =>
  database.collection("user").doc(uid).collection("zone").as(Zone);

export const newZone = (uid: Uid, zone: Zone): Promise<void> =>
  database
    .collection("user")
    .doc(uid)
    .collection("zone")
    .add(zone, Zone)
    .then();

export const toLocation = async (s: string): Promise<Location> => {
  const responses = (await getLocation(s)).results;
  if (responses.length === 0) throw new Error("Direccion no válida");

  const response = responses[0];
  let district = response.address_components.find((comp: any) =>
    comp.types.find((type: any) => type === "administrative_area_level_2")
  )?.long_name;
  const number = response.address_components.find((comp: any) =>
    comp.types.find((type: any) => type === "street_number")
  )?.long_name;
  const postal_code = response.address_components.find((comp: any) =>
    comp.types.find((type: any) => type === "postal_code")
  )?.long_name;
  const province = response.address_components.find((comp: any) =>
    comp.types.find((type: any) => type === "administrative_area_level_1")
  )?.long_name;
  const street = response.address_components.find((comp: any) =>
    comp.types.find((type: any) => type === "route")
  )?.long_name;
  const country = response.address_components.find((comp: any) =>
    comp.types.find((type: any) => type === "country")
  )?.long_name;
  const locality = response.address_components.find((comp: any) =>
    comp.types.find((type: any) => type === "locality")
  )?.long_name;
  if (!district) {
    district = "no district";
  }
  if (!district || !number || !province || !street || !country)
    throw new Error(
      `Dirección Inválida. DEBUG: district:${district}, number:${number}, postal_code:${postal_code}, province:${province}, street:${street}, country:${country}, locality:${locality}`
    );

  let data: Location = {
    ...geofirex.point(
      response.geometry.location.lat,
      response.geometry.location.lng
    ),
    address: {
      district: district,
      number: number,
      postal_code: postal_code,
      province: province,
      street: street,
      locality: locality,
      country: country,
      place_id: response.place_id,
      formatted_address: response.formatted_address,
    },
  };

  return data;
};

export const locToString = (loc: Location) =>
  loc.address.street +
  " " +
  loc.address.number +
  ", " +
  loc.address.district +
  ", " +
  loc.address.province +
  " " +
  loc.address.postal_code +
  ", " +
  loc.address.country;

export const updateZone = (uid: Uid, id: string, zone: Zone): Promise<void> =>
  database
    .collection("user")
    .doc(uid)
    .collection("zone")
    .doc(id)
    .set(zone, Zone);

export const deleteZone = (uid: Uid, idZone: string): Promise<void> => {
  if (idZone && idZone.length > 0)
    return firebase
      .firestore()
      .collection("user")
      .doc(uid)
      .collection("zone")
      .doc(idZone)
      .delete();
  //return database.collection('user').doc(uid).collection('zone').doc(idZone).delete()

  return new Promise(() => console.log("Error Borrar zone"));
  //LEVANTAR ERROR
};

export const getZonesAdmin = (
  enabled: Boolean | undefined = undefined
): Promise<(WithUid & WithId & Zone & User)[]> => {
  if (!enabled)
    return database
      .collectionGroup("zone")
      .as(Zone)
      .then((zones) =>
        Promise.all(
          zones.map((z) =>
            database
              .collection("user")
              .doc(z.uid)
              .as(User)
              .then(
                (u) =>
                  ({
                    ...u,
                    ...z,
                  } as WithUid & WithId & Zone & User)
              )
          )
        )
      );
  else
    return database
      .collectionGroup("zone")
      .where("enabled")
      .is(enabled)
      .as(Zone)
      .then((zones) =>
        Promise.all(
          zones.map((z) =>
            database
              .collection("user")
              .doc(z.uid)
              .as(User)
              .then(
                (u) =>
                  ({
                    ...u,
                    ...z,
                  } as WithUid & WithId & Zone & User)
              )
          )
        )
      );
};
