import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Navigation from "./Navigation";
import ROUTES from "constants/routes";
//import ContractViewAdminPage from "components/Admin/Home/Contracts/view";
//import AdminZonesPage from "components/Admin/Zones";
import AdminTransfersPage from "components/Admin/Transfers";
import AdminTollsPage from "components/Admin/Tolls";
//import ProfilePage from "components/Admin/Home/Profile";
//import PendingBankReviewsPage from "components/Admin/Home/PendingBankReviews";
//import ContractsPage from "components/Admin/Home/Contracts";
//import CarsPage from "components/Admin/Home/Cars";
import ConfigPage from "components/Admin/Config";
import CarViewAdminPage from "./Home/Cars/review";
import AdminNewTransfer from "./Transfers/new";
import ProfileRevision from "./Home/Profile/review";
import AdminViewUserProfile from "./Home/Profile/view-profile";
import AdminTaxableRevision from "./Home/PendingBankReviews/review";
import AdminSales from "./Sales";
import ConfigViewZone from "./Config/Zones/view";
import ConfigViewLocality from "./Config/Localities/view";
import ConfigViewProvince from "./Config/Provinces/view";
//import LicencesPage from "./Home/Licences";
import LicenceRevision from "./Home/Licences/review";
import ConfigNewZone from "./Config/Zones/new";
import AdminTollRevision from "./Tolls/review";
import ConfigNewProvince from "./Config/Provinces/new";
import ConfigNewLocality from "./Config/Localities/new";
import AdminModuleTransfersViewPage from "./Transfers/view/module";
import EditIibb from "./Config/iibb";
import MaxPrice from "./Config/MaxPrice";
import AdminNewTransfersExcel from "./Transfers/excel";
import AdminViewArtProfile from "./Config/Users/view-art";
//import PendingUsers from "./Home/PendingUsers";
import AdminTransfersWrongPage from "./Module_errors/index";
import AdminModuleTransfersWrongViewPage from "./Module_errors/view/module";
import AdminTransfersWrongIDPage from "./Module_errors/view/wrong_id";
import AdminTransfersWrongRejectedPage from "./Module_errors/view/rejected_invoice";
import AdminTransfersWrongAmountPage from "./Module_errors/view/wrong_amount";
import AdminTransfersWrongCrossPage from "./Module_errors/view/wrong_cross";
import AdminTransfersMissingInfo from "./Module_errors/view/missing_info";
import AdminTransfersMissingPDF from "./Module_errors/view/missing_bill_pdf";
import AdminTransfersMissingBillInfo from "./Module_errors/view/missing_bill_info";
import AdminModuleFixDestination from "./Module_errors/view/fix_destination";
import AdminModuleConfusingAddress from "./Module_errors/view/confusing_address";
import AdminDriverTransfersPage from "./Transfers/driverTransfers";
import AdminTransfersSatappInterno from "./Module_errors/view/satapp_interno";
//import AdminDuplicatedBills from "./Module_errors/view/duplicate_bills";
import AdminCrossPage from "./Config/Cross";
import AdminLocalities from "./Config/Cross/localities";
import AdminProfileReviewed from "./Zones/reviewed";
import AdminProfilePassed from "./Zones/passed";
import AdminProfileRejected from "./Zones/rejected";
import AdminProfileToReviewed from "./Zones/to-reviwed";
import AdminProfile from "./Zones/index";
import AdminTransfersWrongAmountPageKm from "./Module_errors/view/wrong_amount_km";
import ConfigViewZoneSale from "./Config/Sale/view";
import ConfigNewZoneSale from "./Config/Sale/new";
import AdminTransfersPool from "./Transfers/view/pool/poolTransfers";

const Admin = (props: any) => {
  return (
    <Navigation>
      <Switch>
        <Route
          exact
          path={ROUTES.ADMIN_HOME_PROFILES}
          component={AdminProfile}
        />
        <Route
          exact
          path={ROUTES.ADMIN_HOME_PROFILES_PENDING}
          component={AdminProfileToReviewed}
        />
        <Route
          path={ROUTES.ADMIN_HOME_PROFILES_REVIEWED}
          component={AdminProfileReviewed}
        />
        <Route
          exact
          path={ROUTES.ADMIN_HOME_PROFILES_PASSED}
          component={AdminProfilePassed}
        />
        <Route
          path={ROUTES.ADMIN_HOME_PROFILES_REJECTED}
          component={AdminProfileRejected}
        />
        <Route path={ROUTES.ADMIN_CAR_VIEW()} component={CarViewAdminPage} />

        {/* <Route
          exact
          path={ROUTES.ADMIN_HOME_PENDING_USERS}
          component={PendingUsers}
        /> */}
        {/* <Route
          exact
          path={ROUTES.ADMIN_HOME_CONTRACTS}
          component={ContractsPage}
        /> */}
        {/* <Route
          path={ROUTES.ADMIN_CONTRACT_VIEW()}
          component={ContractViewAdminPage}
        /> */}
        <Route exact path={ROUTES.ADMIN_CONFIGURATION} component={ConfigPage} />
        <Route
          path={ROUTES.ADMIN_CONFIGURATION_VIEW_ZONE()}
          component={ConfigViewZone}
        />
        <Route
          path={ROUTES.ADMIN_CONFIGURATION_NEW_ZONE}
          component={ConfigNewZone}
        />
        <Route
          path={ROUTES.ADMIN_CONFIGURATION_NEW_PROVINCE}
          component={ConfigNewProvince}
        />
        <Route
          path={ROUTES.ADMIN_CONFIGURATION_NEW_LOCALITY}
          component={ConfigNewLocality}
        />
        <Route
          path={ROUTES.ADMIN_CONFIGURATION_VIEW_LOCALITY()}
          component={ConfigViewLocality}
        />
        <Route
          path={ROUTES.ADMIN_CONFIGURATION_VIEW_PROVINCE()}
          component={ConfigViewProvince}
        />
        <Route
          path={ROUTES.ADMIN_CONFIGURATION_VIEW_SALE_ZONE()}
          component={ConfigViewZoneSale}
        />
        <Route
          path={ROUTES.ADMIN_CONFIGURATION_NEW_ZONE_SALE}
          component={ConfigNewZoneSale}
        />
        {/* <Route path={ROUTES.ADMIN_STATIONS} component={AdminZonesPage} /> */}
        <Route path={ROUTES.ADMIN_CROSS} component={AdminCrossPage} />
        <Route path={ROUTES.ADMIN_LOCALITIES} component={AdminLocalities} />
        <Route exact path={ROUTES.ADMIN_TOLLS} component={AdminTollsPage} />
        <Route path={ROUTES.ADMIN_VIEW_TOLLS()} component={AdminTollRevision} />
        <Route
          exact
          path={ROUTES.ADMIN_MODULES_TRANSFERS}
          component={AdminTransfersPage}
        />
        <Route
          exact
          path={ROUTES.ADMIN_MODULES_TRANSFERS_POOL}
          component={AdminTransfersPool}
        />

        <Route
          exact
          path={ROUTES.ADMIN_MODULES_TRANSFERS_CONDUCTOR}
          component={AdminDriverTransfersPage}
        />
        <Route
          path={ROUTES.ADMIN_MODULE_TRANSFER_VIEW()}
          component={AdminModuleTransfersViewPage}
        />
        <Route
          exact
          path={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG}
          component={AdminTransfersWrongPage}
        />
        <Route
          exact
          path={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_ID}
          component={AdminTransfersWrongIDPage}
        />
        <Route
          exact
          path={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_REJECTED}
          component={AdminTransfersWrongRejectedPage}
        />
        <Route
          exact
          path={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_MISSING_INFO}
          component={AdminTransfersMissingInfo}
        />
        <Route
          exact
          path={ROUTES.ADMIN_MODULES_TRANSFERS_MISSING_PDF}
          component={AdminTransfersMissingPDF}
        />
        <Route
          exact
          path={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_MISSING_BILL_INFO}
          component={AdminTransfersMissingBillInfo}
        />
        <Route
          exact
          path={ROUTES.ADMIN_MODULES_TRANSFERS_SATAPP_INTERNO}
          component={AdminTransfersSatappInterno}
        />
        {/* <Route
          exact
          path={ROUTES.ADMIN_DUPLICATED_BILLS}
          component={AdminDuplicatedBills}
        /> */}
        <Route
          exact
          path={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_AMOUNT}
          component={AdminTransfersWrongAmountPage}
        />
        <Route
          exact
          path={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_AMOUNT_KM}
          component={AdminTransfersWrongAmountPageKm}
        />
        <Route
          exact
          path={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_CROSS}
          component={AdminTransfersWrongCrossPage}
        />

        <Route
          path={ROUTES.ADMIN_MODULE_FIX_DIRECTION()}
          component={AdminModuleFixDestination}
        />
        <Route
          path={ROUTES.ADMIN_MODULE_CONFUSING_ADDRESS()}
          component={AdminModuleConfusingAddress}
        />
        <Route
          path={ROUTES.ADMIN_MODULE_TRANSFER_WRONG_VIEW()}
          component={AdminModuleTransfersWrongViewPage}
        />
        <Route
          exact
          path={ROUTES.ADMIN_NEW_TRANSFER}
          component={AdminNewTransfer}
        />
        <Route
          exact
          path={ROUTES.ADMIN_NEW_TRANSFER_EXCEL}
          component={AdminNewTransfersExcel}
        />
        <Route
          path={ROUTES.ADMIN_REVIEW_PROFILE()}
          component={ProfileRevision}
        />
        <Route
          path={ROUTES.ADMIN_REVIEW_LICENCE()}
          component={LicenceRevision}
        />
        <Route
          path={ROUTES.ADMIN_USER_PROFILE_VIEW()}
          component={AdminViewUserProfile}
        />
        <Route
          path={ROUTES.ADMIN_REVIEW_BANKDATA()}
          component={AdminTaxableRevision}
        />
        <Route path={ROUTES.ADMIN_SALES} component={AdminSales} />
        <Route path={ROUTES.ADMIN_CONFIGURATION_IIBB} component={EditIibb} />
        <Route
          path={ROUTES.ADMIN_CONFIGURATION_MAXPRICE_TRANSFER_ART}
          component={MaxPrice}
        />
        <Route
          path={ROUTES.ADMIN_ART_PROFILE_VIEW()}
          component={AdminViewArtProfile}
        />

        <Redirect from="*" to={ROUTES.ADMIN_MODULES_TRANSFERS} />
      </Switch>
    </Navigation>
  );
};

export default Admin;
