import { WithCopyConstructor } from 'util/meta';
import { CollectionGroup, WithId, WithUid } from '../controllers/database';
export class Paginator <T, F> {
    unorderedCollection : CollectionGroup;
    collection: CollectionGroup;
    fieldsOrder: string[];
    firstValue: any
    lastValue: any
    type: WithCopyConstructor<T>
    prevPage: boolean = false
    limit : number
    pageNumber : number = 1
    reverseOrder : boolean
    transform : ((arg0: (T & WithId & WithUid)[]) => Promise<F[]>) | ((arg0: (T & WithId)[]) => Promise<F[]>) | undefined
    
    firstPage : boolean = true
    lastPage : boolean = false

    constructor(type: WithCopyConstructor<T>, collection : CollectionGroup, fieldsOrder: string[], limit : number, transform : ((arg0: (T & WithId & WithUid)[]) => Promise<F[]>) | ((arg0: (T & WithId)[]) => Promise<F[]>) | undefined = undefined, reverseOrder : boolean = false) {    
        this.unorderedCollection = collection;
        this.collection = fieldsOrder.reduce( (acc : CollectionGroup, cv : string) =>  acc.orderBy(cv, reverseOrder ? 'asc' : undefined), collection);
        this.fieldsOrder = fieldsOrder;
        this.type = type
        this.limit = limit
        this.transform = transform
        this.reverseOrder = reverseOrder
    }
    _resetPage(){
        this.firstValue = undefined
        this.lastValue = undefined
        this.pageNumber = 1
    }
    changeCollection(collection : CollectionGroup) {
        this.unorderedCollection = collection;
        this.collection = this.fieldsOrder.reduce( (acc : CollectionGroup, cv : string) =>  acc.orderBy(cv, this.reverseOrder ? 'asc' : undefined), collection);
        this._resetPage()
    }
    changeOrderFields(fieldsOrder: string[]) {
        this.fieldsOrder = fieldsOrder
        this.collection = this.fieldsOrder.reduce( (acc : CollectionGroup, cv : string) =>  acc.orderBy(cv, this.reverseOrder ? 'asc' : undefined), this.collection);
        this._resetPage()
    }
    getPage() { return this.pageNumber }
    next() {        
        this.firstValue = this.lastValue
        this.prevPage = false
        this.pageNumber++
    }
    prev() {  
        this.prevPage = true
        this.pageNumber--
    }
  
    getFieldsOrder(v : any) { //Usamos dot notation para pasar las variables
        return this.fieldsOrder.map( f => f.split('.').reduce((o,i)=>o[i], v) )
    }

    __initData(){
        let data
        if(this.prevPage){
            const endBeforeFields = this.getFieldsOrder(this.firstValue)
            data = this.collection.endBefore( endBeforeFields )
            this.prevPage = false
        }
        else{
            if(this.lastValue !== undefined){
                const startAfterFields = this.getFieldsOrder(this.lastValue)
                data = this.collection.startAfter( startAfterFields )
            }
            else {
                data = this.collection
            }
        }
        data = data.limit(this.limit)
        return data
    }

    __finishData(data:any[]){
        if(data.length > 0){
            this.firstValue = data[0]
            this.lastValue = data[data.length-1]
        }

        if(data.length < this.limit)
            this.lastPage = true
        else 
            this.lastPage = false

        this.firstPage = this.pageNumber === 1
    }
    
    async getData() {
        const data = await this.__initData().as<T>(this.type)

        this.__finishData(data)
        return data 
    }

    async getDataWithoutUid() {
        const data = await this.__initData().asWithoutUid<T>(this.type)
        this.__finishData(data)
        return data 
    }

    async getDataTransformed() {
        const data = await this.__initData().as<T>(this.type).then(this.transform)

        this.__finishData(data)
        return data 
    }

    async getDataTransformedWithoutUid() {
        const data = await this.__initData().asWithoutUid<T>(this.type).then(this.transform as any)

        this.__finishData(data)
        return data 
    }
}
/*
const usePaginator = <T>(collection: FbCollection|FbQuery, limit:number) => {
    const [dataQuery, setDataQuery] = useState<firebase.firestore.QuerySnapshot>(undefined as any)
    const [data, setData] = useState<T[]>([])
    const [page, setPage] = useState(0)
    const [_collection, setCollection] = useState(collection)
    const [loading, setLoading] = useState(true)
    const [data] = useFb
    useEffect(() => {
        updateData()
    }, [])
    const updateData = () => {
        setLoading(true)
        _collection.limit(limit).get()
            .then(v => v.docs.map(d => ({...d.data, id:d.id} as T & WithId)))
            .then(setData).then(() => setLoading(false))
    }
    const prevPage = () => {
        if(page > 0){
            setPage(page-1)
            _collection.endBefore(dataQuery.docs[dataQuery.size-1]).limit(limit).get()
                .then(v => v.docs.map(d => ({...d.data, id:d.id} as T & WithId)))
                .then(setData).then(() => setLoading(false))
        }

    }   
    const nextPage = () => {
        if(page > 0){
            setPage(page-1)
            _collection.limit(limit).get()
            .then(v => v.docs.map(d => ({...d.data, id:d.id} as T & WithId)))
            .then(setData).then(() => setLoading(false))
        }
    }
    const changeCollection = (d : FbCollection|FbQuery) => {
        setCollection(d)
        updateData()
    }
    const _pagination = (
        <Pagination style={{justifyContent:'center'}}>
            {page > 0 && <Pagination.Prev onClick={prevPage}/>}
            <Pagination.Item active>{page}</Pagination.Item>
            {data.length < limit && <Pagination.Next onClick={nextPage}/>}
        </Pagination> 
    )
    return [data, loading, _pagination, changeCollection]
}*/