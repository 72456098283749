import { Center, Padding } from "components/styles";
import React, { useState } from "react";
import YouTube from "react-youtube";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const BeginningForm = (props: any) => {
  const [videoPlay, setVideoPlay] = useState(true);
  return (
    <Container>
      <GralContainer>
        <Padding pad={"30px"}>
          <Center>
            <h5 className="font-weight-bold my-4"> Bienvenido a Satapp!</h5>
          </Center>
          <p className="font-weight-bold mb-3">
            Somos una empresa dedicada a traslados de ART, tenemos una app donde
            los conductores se pueden registrar y agendar viajes para trasladar
            pacientes a centros médicos.
          </p>
          <p className="font-weight-bold mb-3">
            👉🏼 Podes programar tus viajes para toda la semana y realizar viajes
            en el momento! Desde la aplicación podes ver el valor con
            anticipación de cada viaje📱
          </p>
          <Center>
            <p className="font-weight-bold mb-3">
              ✅ Viajes 𝟏𝟎𝟎% seguros (Pacientes identificados).
            </p>
            <p className="font-weight-bold mb-3">
              ✅ No cobramos comisión, todo lo que trabajas es tuyo!
            </p>
            <p className="font-weight-bold mb-3">
              ✅ Autonomía para elegir la cantidad de viajes y horarios para
              brindar tus servicios.
            </p>
            <p className="font-weight-bold mb-3">✅ Sin límites de viajes.</p>
            <p className="font-weight-bold mb-3">
              ✅ Reintegro de peajes y km sin paciente a bordo.
            </p>
            <p className="font-weight-bold mb-3">Tenes alguna duda?</p>
            <p className="font-weight-bold mb-3">
              Ponete en contacto con nuestras asesoras vía whatsapp y mail!
            </p>
            <p className="font-weight-bold mb-3">+54 9 11 5140-4449</p>
            <p className="font-weight-bold mb-3">+54 9 11 5139-3966</p>
            <p className="font-weight-bold mb-3">conductores@satapp.com.ar</p>
          </Center>
        </Padding>
      </GralContainer>
    </Container>
  );
};
const GralContainer = styled.div`
  background-color: white;
  color: #00426a;
  font-family: Quicksand;
`;

export default BeginningForm;
