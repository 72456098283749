import {
  Button,
  ButtonWithConfirmation,
  H2,
  NoData,
  Padding,
  Searcher,
  Spinner,
  AsyncButton,
} from "components/styles";
import * as fs from "file-saver";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";

import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Modal, Row, Table as TableB } from "react-bootstrap";
import Moment from "react-moment";

import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  DefinitionsModuleTransferType,
  ModuleTransferType,
  TypeModuleReject,
  TypeModuleRejectSpanish,
} from "types/module-transfer";
import {
  FbDate,
  FinishStatus,
  ScheduledTransfer,
  TransferStage,
  TransferType,
} from "types/transfer";
import { usePaginator } from "util/hooks";
import MiniNavigationErrors from "../MiniNavigation";
import { InfoRow } from "components/Admin/Transfers/view/scheduled";
import Sales from "components/Admin/Sales/Sales";
import { BillStatus } from "types/billing";
import moment from "moment";

const AdminTransfersMissingInfo = () => {
  return (
    <MiniNavigationErrors active={5}>
      <MainContainer
        viewTransfer={ROUTES.ADMIN_MODULE_TRANSFER_VIEW}
        art={false}
      />
    </MiniNavigationErrors>
  );
};

export const MainContainer = ({
  viewTransfer,
  art,
}: {
  viewTransfer: (a: any) => string;
  art: boolean;
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const pageLimit = 10000;
  const [items, loading, Pagination, setCollection] = usePaginator(
    FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("art.name", "==", "Experta SML")
      .where("origin.time", "<", FbDate.fromDate(today))
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("sale.billed", "==", 0)
      .where("status.doing_return", "==", false)
      .where("type", "==", 2)
      //  .orderBy("sale.billed", "desc"),
      .orderBy("origin.time", "desc"),
    pageLimit
  );
  const [items2, loading2, Pagination2, setCollection2] = usePaginator(
    FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("art.name", "==", "Experta SML")
      .where("origin.time", "<", FbDate.fromDate(today))
      .orderBy("origin.time", "desc"),
    pageLimit
  );
  const [items3, loading3, Pagination3, setCollection3] = usePaginator(
    FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("art.name", "==", "Experta SML")
      .where("origin.time", "<=", moment(new Date("12/04/2022 23:59")).toDate())
      .where("status.stage", "==", TransferStage.finished)
      .where("sale.billed", "==", 0)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", true)
      .orderBy("origin.time", "desc"),
    pageLimit
  );
  const [items6, loading6, Pagination6, setCollection6] = usePaginator(
    FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("art.name", "==", "Experta SML")
      .where("origin.time", ">", moment(new Date("12/04/2022 23:59")).toDate())
      .where("origin.time", "<", FbDate.fromDate(today))
      .where("status.stage", "==", TransferStage.finished)
      .where("sale.billed", "==", 0)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", true)
      .orderBy("origin.time", "desc"),
    pageLimit
  );
  const [items4, loading4, Pagination4, setCollection4] = usePaginator(
    FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("status.stage", "==", 4)
      .where("art.name", "==", "Experta SML")
      .where("origin.time", "<", FbDate.fromDate(today))
      .orderBy("origin.time", "desc"),
    pageLimit
  );
  const [items5, loading5, Pagination5, setCollection5] = usePaginator(
    FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("status.stage", "==", 4)
      .where("origin.time", "<", FbDate.fromDate(today))
      .where("art.name", "==", "Feleval SRL")
      .orderBy("origin.time", "desc"),
    pageLimit
  );
  const [filter, setFilter] = useState("Negativos pendientes de solicitud");
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [transferModal, setTransferModal] = useState<any>("");
  const [showNegativeModal, setShowNegativeModal] = useState<boolean>(false);
  const [negativeModal, setNegativeModal] = useState<any>("");
  const [showNegativeReturnModal, setShowNegativeReturnModal] =
    useState<boolean>(false);
  const [negativeReturnModal, setNegativeReturnModal] = useState<any>("");
  const sendRequestMail = (id: string) =>
    CALLABLE_FUNCTIONS.SEND_REQUEST_MAIL(id);

  const sendRequestNegative = (id: string) =>
    CALLABLE_FUNCTIONS.SEND_REQUEST_NEGATIVE(id);

  const sendRequestSML = (id: string) =>
    CALLABLE_FUNCTIONS.SEND_REQUEST_SML(id);

  useEffect(() => {
    setFilteredItems(items2.filter((item) => item.transfer_id == undefined));
    console.log(filteredItems);
  }, [loading2]);
  const billNegativeReturns = async () => {
    await CALLABLE_FUNCTIONS.FORCE_BILL_NEGATIVE_RETURNS();
  };
  const billNegativeReturnsByKm = async () => {
    await CALLABLE_FUNCTIONS.FORCE_BILL_NEGATIVE_RETURNS_BYKM();
  };
  return (
    <Padding pad="5px">
      <H2>Traslados Incompletos</H2>
      {filter === "Retornos Negativos por Cruce" && (
        <AsyncButton variant="success" onClick={billNegativeReturns}>
          Facturar Todos
        </AsyncButton>
      )}
      {filter === "Retornos Negativos por Kilometros" && (
        <AsyncButton variant="success" onClick={billNegativeReturnsByKm}>
          Facturar Todos
        </AsyncButton>
      )}
      <Form.Control
        as="select"
        value={filter}
        onChange={(event) => setFilter(event.target.value)}
      >
        <option key={0} value={"Negativos pendientes de solicitud"}>
          Negativos pendientes de solicitud
        </option>
        <option key={1} value={"Traslados incompletos Feleval"}>
          Traslados incompletos Feleval
        </option>
        <option key={2} value={"Traslados sin SML ID"}>
          Traslados sin SML ID
        </option>
        <option key={3} value={"Retornos Negativos por Cruce"}>
          Retornos Negativos (Cruce)
        </option>
        <option key={4} value={"Retornos Negativos por Kilometros"}>
          Retornos Negativos (Kilometro)
        </option>
        <option key={5} value={"Traslados incompletos Experta"}>
          Traslados incompletos Experta
        </option>
      </Form.Control>

      {loading || loading2 || loading3 || loading4 || loading5 || loading6 ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        <Table hover responsive>
          <thead>
            <Tr>
              <th>Siniestro-Aut/NPA</th>
              <th>Fecha</th>
              <th>Modalidad</th>
              <th>ART</th>
              {filter === "Traslados incompletos Experta" ||
              filter === "Retornos Negativos por Cruce" ||
              filter === "Retornos Negativos por Kilometros" ? (
                <>
                  <th>Informado</th>
                  <th>Reject</th>
                </>
              ) : (
                ""
              )}
              <th>Ver</th>
              {filter == "Negativos pendientes de solicitud" ? (
                <>
                  <th>Informado</th>
                  <th>Negativizar</th>
                </>
              ) : (
                ""
              )}
            </Tr>
          </thead>
          <tbody>
            {filter == "Negativos pendientes de solicitud" ? (
              <>
                {items.map((item) => (
                  <tr key={item.id}>
                    <Td style={{ wordBreak: "break-all" }}>
                      {item.art.accident && item.art.authorization
                        ? item.art.accident + "-" + item.art.authorization
                        : item.art.accident
                        ? item.art.accident
                        : "NPA: " + item.art.npa}
                    </Td>
                    <Td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY HH:mm"
                      />
                    </Td>
                    <Td>{DefinitionsModuleTransferType[item.type]}</Td>
                    <Td>{item.art.name}</Td>
                    <Td>
                      <Link to={viewTransfer(item.id)}>
                        <Button variant="light">Ver</Button>
                      </Link>
                    </Td>
                    <Td>
                      {!item.informado?.status ? (
                        <Button
                          variant="success"
                          onClick={() => alert("Función en desarrollo")}
                          style={{ marginLeft: 10 }}
                          confirmText="¿Está seguro que quiere informar por mail a Experta con los datos del traslado Negativo?"
                        >
                          Informar
                        </Button>
                      ) : (
                        <Moment
                          date={item.informado.date.toDate()}
                          format="DD/MM/YYYY HH:mm"
                        />
                      )}
                    </Td>
                    <Td>
                      <Button
                        onClick={() => {
                          setNegativeModal(item.id);
                          setShowNegativeModal(true);
                        }}
                      >
                        Negativo
                      </Button>
                    </Td>
                  </tr>
                ))}
                <Pagination />
              </>
            ) : filter == "Retornos Negativos por Cruce" ? (
              <>
                {items3.map((item) => (
                  <tr key={item.id}>
                    <Td style={{ wordBreak: "break-all" }}>
                      {item.art.accident && item.art.authorization
                        ? item.art.accident + "-" + item.art.authorization
                        : item.art.accident
                        ? item.art.accident
                        : "NPA: " + item.art.npa}
                    </Td>
                    <Td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY HH:mm"
                      />
                    </Td>
                    <Td>{DefinitionsModuleTransferType[item.type]}</Td>
                    <Td>{item.art.name}</Td>
                    <Td>
                      {!item.informado?.status ? (
                        <ButtonWithConfirmation
                          variant="success"
                          onClick={() => sendRequestNegative(item.id)}
                          style={{ marginLeft: 10 }}
                          confirmText="¿Está seguro que quiere informar por mail a Experta con los datos del traslado Negativo?"
                        >
                          Informar
                        </ButtonWithConfirmation>
                      ) : (
                        <Moment
                          date={item.informado.date.toDate()}
                          format="DD/MM/YYYY HH:mm"
                        />
                      )}
                    </Td>
                    <Td>
                      {item.reject?.type &&
                        TypeModuleRejectSpanish[item.reject?.type]}
                    </Td>
                    <Td>
                      <Link to={viewTransfer(item.id)}>
                        <Button variant="light">Ver</Button>
                      </Link>
                    </Td>
                    <Td>
                      <Button
                        onClick={() => {
                          setNegativeReturnModal(item.id);
                          setShowNegativeReturnModal(true);
                        }}
                      >
                        Negativo
                      </Button>
                    </Td>
                  </tr>
                ))}
                <Pagination3 />
              </>
            ) : filter == "Retornos Negativos por Kilometros" ? (
              <>
                {items6.map((item) => (
                  <tr key={item.id}>
                    <Td style={{ wordBreak: "break-all" }}>
                      {item.art.accident && item.art.authorization
                        ? item.art.accident + "-" + item.art.authorization
                        : item.art.accident
                        ? item.art.accident
                        : "NPA: " + item.art.npa}
                    </Td>
                    <Td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY HH:mm"
                      />
                    </Td>
                    <Td>{DefinitionsModuleTransferType[item.type]}</Td>
                    <Td>{item.art.name}</Td>
                    <Td>
                      {!item.informado?.status ? (
                        <ButtonWithConfirmation
                          variant="success"
                          onClick={() => sendRequestNegative(item.id)}
                          style={{ marginLeft: 10 }}
                          confirmText="¿Está seguro que quiere informar por mail a Experta con los datos del traslado Negativo?"
                        >
                          Informar
                        </ButtonWithConfirmation>
                      ) : (
                        <Moment
                          date={item.informado.date.toDate()}
                          format="DD/MM/YYYY HH:mm"
                        />
                      )}
                    </Td>
                    <Td>
                      {item.reject?.type &&
                        TypeModuleRejectSpanish[item.reject?.type]}
                    </Td>
                    <Td>
                      <Link to={viewTransfer(item.id)}>
                        <Button variant="light">Ver</Button>
                      </Link>
                    </Td>
                    <Td>
                      <Button
                        onClick={() => {
                          setNegativeReturnModal(item.id);
                          setShowNegativeReturnModal(true);
                        }}
                      >
                        Negativo
                      </Button>
                    </Td>
                  </tr>
                ))}
                <Pagination6 />
              </>
            ) : filter === "Traslados incompletos Experta" ? (
              <>
                {items4.map((item) => (
                  <tr key={item.id}>
                    <Td style={{ wordBreak: "break-all" }}>
                      {item.art.accident && item.art.authorization
                        ? item.art.accident + "-" + item.art.authorization
                        : item.art.accident
                        ? item.art.accident
                        : "NPA: " + item.art.npa}
                    </Td>
                    <Td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY HH:mm"
                      />
                    </Td>
                    <Td>{DefinitionsModuleTransferType[item.type]}</Td>
                    <Td>{item.art.name}</Td>
                    <Td>
                      {!item.informado?.status ? (
                        <ButtonWithConfirmation
                          variant="success"
                          onClick={() => sendRequestMail(item.id)}
                          style={{ marginLeft: 10 }}
                          confirmText="¿Está seguro que quiere informar por mail a Experta?"
                        >
                          Informar
                        </ButtonWithConfirmation>
                      ) : (
                        <Moment
                          date={item.informado.date.toDate()}
                          format="DD/MM/YYYY HH:mm"
                        />
                      )}
                    </Td>
                    <Td>
                      <Link to={viewTransfer(item.id)}>
                        <Button variant="light">Ver</Button>
                      </Link>
                    </Td>
                  </tr>
                ))}
                <Pagination4 />
              </>
            ) : filter === "Traslados sin SML ID" ? (
              <>
                {filteredItems.map((item) => (
                  <tr key={item.id}>
                    <Td style={{ wordBreak: "break-all" }}>
                      {item.art.accident && item.art.authorization
                        ? item.art.accident + "-" + item.art.authorization
                        : item.art.accident
                        ? item.art.accident
                        : "NPA: " + item.art.npa}
                    </Td>
                    <Td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY HH:mm"
                      />
                    </Td>
                    <Td>{DefinitionsModuleTransferType[item.type]}</Td>
                    <Td>{item.art.name}</Td>
                    <Td>{item.passenger.name}</Td>
                    <Td>
                      {item.origin
                        ? item.origin.address
                          ? item.origin.address.formatted_address
                          : "dirección a corregir"
                        : "dirección a corregir"}
                    </Td>
                    <Td>
                      {item.destination
                        ? item.destination.address
                          ? item.destination.address.formatted_address
                          : "dirección a corregir"
                        : "dirección a corregir"}
                    </Td>
                    <Td>
                      <Button
                        onClick={() => {
                          setShowModal(true);
                          setTransferModal(item.id);
                        }}
                        variant="light"
                      >
                        Agregar SML ID
                      </Button>
                    </Td>
                    <Td>
                      {!item.informado?.status ? (
                        <ButtonWithConfirmation
                          variant="success"
                          onClick={() => sendRequestSML(item.id)}
                          style={{ marginLeft: 10 }}
                          confirmText="¿Está seguro que quiere informar por mail a Experta con los datos del traslado sin SML ID?"
                        >
                          Informar
                        </ButtonWithConfirmation>
                      ) : (
                        <Moment
                          date={item.informado.date.toDate()}
                          format="DD/MM/YYYY HH:mm"
                        />
                      )}
                    </Td>
                  </tr>
                ))}
                <Pagination4 />
              </>
            ) : filter === "Traslados incompletos Feleval" ? (
              <>
                {items5.map((item) => (
                  <tr key={item.id}>
                    <Td style={{ wordBreak: "break-all" }}>
                      {item.art.accident && item.art.authorization
                        ? item.art.accident + "-" + item.art.authorization
                        : item.art.accident
                        ? item.art.accident
                        : "NPA: " + item.art.npa}
                    </Td>
                    <Td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY HH:mm"
                      />
                    </Td>
                    <Td>{DefinitionsModuleTransferType[item.type]}</Td>
                    <Td>{item.art.name}</Td>
                    <Td>
                      <Link to={viewTransfer(item.id)}>
                        <Button variant="light">Ver</Button>
                      </Link>
                    </Td>
                  </tr>
                ))}
                <Pagination5 />
              </>
            ) : null}
          </tbody>
        </Table>
      )}
      <TransferIdModal
        module={transferModal}
        show={showModal}
        handleClose={() => {
          setCollection2(
            FIRESTORE.COLLECTION_MODULES_TRANSFER()
              .where("art.name", "==", "Experta SML")
              .where("origin.time", "<", FbDate.fromDate(today))
              .orderBy("origin.time", "desc")
          );
          setShowModal(false);
          setFilteredItems(
            items2.filter((item) => item.transfer_id == undefined)
          );
        }}
      />
      <NegativeModal
        module={negativeModal}
        show={showNegativeModal}
        handleClose={() => {
          setShowNegativeModal(false);
          setCollection(
            FIRESTORE.COLLECTION_MODULES_TRANSFER()
              .where("art.name", "==", "Experta SML")
              .where("origin.time", "<", FbDate.fromDate(today))
              .where("status.finished_status", "==", FinishStatus.Negativo)
              .where("sale.billed", "==", 0)
              .where("status.doing_return", "==", false)
              .where("type", "==", 2)
              .orderBy("origin.time", "desc")
          );
        }}
      />
      <NegativeReturnModal
        module={negativeReturnModal}
        show={showNegativeReturnModal}
        handleClose={() => {
          setShowNegativeReturnModal(false);
          setCollection3(
            FIRESTORE.COLLECTION_MODULES_TRANSFER()
              .where("art.name", "==", "Experta SML")
              .where(
                "origin.time",
                "<=",
                moment(new Date("12/04/2022 23:59")).toDate()
              )
              .where("status.stage", "==", TransferStage.finished)
              .where("sale.billed", "==", 0)
              .where("status.finished_status", "==", FinishStatus.Negativo)
              .where("status.doing_return", "==", true)
              .orderBy("origin.time", "desc")
          );
          setCollection6(
            FIRESTORE.COLLECTION_MODULES_TRANSFER()
              .where("art.name", "==", "Experta SML")
              .where(
                "origin.time",
                ">",
                moment(new Date("12/04/2022 23:59")).toDate()
              )
              .where("origin.time", "<", FbDate.fromDate(today))
              .where("status.stage", "==", TransferStage.finished)
              .where("sale.billed", "==", 0)
              .where("status.finished_status", "==", FinishStatus.Negativo)
              .where("status.doing_return", "==", true)
              .orderBy("origin.time", "desc")
          );
        }}
      />
    </Padding>
  );
};

export const NegativeModal = ({
  module,
  show,
  handleClose,
}: {
  module: string;
  show: boolean;
  handleClose: any;
}) => {
  const [auth, setAuth] = useState("");
  const [sml, setSml] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState(false);
  const crearNegativo = async (sml: string, auth: string) => {
    setLoading(true);
    try {
      const data = { smlNegativo: sml, authNegativo: auth, moduleId: module };
      await CALLABLE_FUNCTIONS.ADMIN_HANDLE_NEGATIVE_MODULE(data);
      setSuccess(true);
      setAuth("");
      setSml("");
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };
  const noFacturar = async () => {
    setLoading(true);
    try {
      const moduleRef = (await FIRESTORE.DOCUMENT_MODULE_TRANSFER(module).get())
        .ref;
      await moduleRef.update({
        "sale.billed": BillStatus["No facturar"],
      });
      setSuccess(true);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };
  const closeAndReset = () => {
    setSuccess(false);
    setError(undefined);
    setAuth("");
    setSml("");
    handleClose();
  };
  return (
    <Modal show={show} onHide={closeAndReset} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Ingrese negativo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <Alert variant="success">Realizado con éxito</Alert>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : loading ? (
          <Spinner />
        ) : (
          <>
            <InfoRow label="" info="" />
            <input
              value={sml}
              onChange={(e) => setSml(e.target.value)}
              placeholder="Nuevo SML ID"
            ></input>
            <br />
            <br />
            <input
              value={auth}
              onChange={(e) => setAuth(e.target.value)}
              placeholder="Nueva autorización"
            ></input>
          </>
        )}
      </Modal.Body>
      {!success && (
        <Modal.Footer>
          <Button
            disabled={auth === "" || sml === "" ? true : false}
            variant="success"
            onClick={() => crearNegativo(sml, auth)}
          >
            Continuar
          </Button>
          <Button
            disabled={auth === "" && sml === "" ? false : true}
            variant="danger"
            onClick={noFacturar}
          >
            NO FACTURAR VENTA
          </Button>
          <Button variant="secondary" onClick={closeAndReset}>
            Cancelar
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

const NegativeReturnModal = ({
  module,
  show,
  handleClose,
}: {
  module: string;
  show: boolean;
  handleClose: any;
}) => {
  const [auth, setAuth] = useState("");
  const [sml, setSml] = useState("");
  const [authExitoso, setAuthExitoso] = useState("");
  const [smlExitoso, setSmlExitoso] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState(false);
  const crearNegativo = async () => {
    setLoading(true);
    try {
      const data = {
        smlNegativo: sml,
        authNegativo: auth,
        moduleId: module,
        authExitoso,
        smlExitoso,
        negative: true,
        return: true,
      };
      await CALLABLE_FUNCTIONS.ADMIN_HANDLE_NEGATIVE_MODULE(data);
      setSuccess(true);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };
  const noFacturarNegativo = async () => {
    setLoading(true);
    try {
      const data = {
        moduleId: module,
        authExitoso,
        smlExitoso,
        negative: false,
        return: true,
      };
      await CALLABLE_FUNCTIONS.ADMIN_HANDLE_NEGATIVE_MODULE(data);
      setSuccess(true);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };
  const closeAndReset = () => {
    setSuccess(false);
    setError(undefined);
    setAuth("");
    setSml("");
    setSmlExitoso("");
    setAuthExitoso("");
    handleClose();
  };
  return (
    <Modal show={show} onHide={closeAndReset} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Ingrese negativo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <Alert variant="success">Realizado con éxito</Alert>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : loading ? (
          <Spinner />
        ) : (
          <>
            <InfoRow label="" info="" />
            <input
              value={smlExitoso}
              onChange={(e) => setSmlExitoso(e.target.value)}
              placeholder="SML ID (IDA EXITOSA)"
            ></input>
            <br />
            <br />
            <input
              value={authExitoso}
              onChange={(e) => setAuthExitoso(e.target.value)}
              placeholder="Autorización (IDA EXITOSA)"
            ></input>

            <br />
            <br />
            <input
              value={sml}
              onChange={(e) => setSml(e.target.value)}
              placeholder="SML ID (RET NEGATIVO)"
            ></input>
            <br />
            <br />
            <input
              value={auth}
              onChange={(e) => setAuth(e.target.value)}
              placeholder="Autorización (RET NEGATIVO)"
            ></input>
          </>
        )}
      </Modal.Body>
      {!success && (
        <Modal.Footer>
          <Button
            disabled={
              auth === "" ||
              sml === "" ||
              authExitoso === "" ||
              smlExitoso === ""
                ? true
                : false
            }
            variant="success"
            onClick={crearNegativo}
          >
            Crear IDA Exitosa y Retorno Negativo
          </Button>
          <Button
            disabled={
              /* auth === "" &&
              sml === "" && */
              authExitoso === "" && smlExitoso === "" ? true : false
            }
            variant="danger"
            onClick={noFacturarNegativo}
          >
            NO FACTURAR NEGATIVO
          </Button>
          <Button variant="secondary" onClick={closeAndReset}>
            Cancelar
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export const TransferIdModal = ({
  module,
  show,
  handleClose,
}: {
  module: string;
  show: boolean;
  handleClose: any;
}) => {
  const [transferId, setTranferId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState(false);
  const submit = async (module: string, transferId: string) => {
    setLoading(true);
    try {
      CALLABLE_FUNCTIONS.ADMIN_ADD_SML_ID(module, transferId);
      setSuccess(true);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Ingrese nuevo SML ID</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {success ? (
          <Alert variant="success">Realizado con éxito</Alert>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : loading ? (
          <Spinner />
        ) : (
          <>
            <input
              value={transferId}
              onChange={(e) => setTranferId(e.target.value)}
              placeholder="Ingrese nuevo SML ID"
            ></input>
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={() => {
                submit(module, transferId);
              }}
            >
              Agregar SML ID
            </Button>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Tr = styled.tr`
  th {
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  word-wrap: break-word;
`;

const Table = styled(TableB)`
  max-width: 100%;
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default AdminTransfersMissingInfo;
