import React from "react";
import {
  Container,
  Button,
  ButtonGroup,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ROUTES from "constants/routes";
//import 'index.css'
import { Link } from "components/styles";
import styled from "styled-components";
import { pxToPt } from "constants/css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router";

//class MiniNavigation extends React.Component<any, any> {
const MiniNavigationErrors = (props: any) => {
  let location = useLocation();

  return (
    <Container style={{ maxWidth: "100%" }}>
      <Container style={{ margin: 20, maxWidth: "100%" }}>
        <Row>
          <Col xl={11} md={11}>
            <LinkMiniNavigation to={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG}>
              <Button
                className="left"
                variant="info"
                active={
                  location.pathname === ROUTES.ADMIN_MODULES_TRANSFERS_WRONG
                }
              >
                Dirección Incorrecta
              </Button>
            </LinkMiniNavigation>
            <LinkMiniNavigation to={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_CROSS}>
              <Button
                variant="info"
                active={
                  location.pathname ===
                  ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_CROSS
                }
              >
                Traslados con Demora
              </Button>
            </LinkMiniNavigation>
            <LinkMiniNavigation
              to={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_AMOUNT}
            >
              <Button
                variant="info"
                active={
                  location.pathname ===
                  ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_AMOUNT
                }
              >
                Monto Incorrecto Cruce
              </Button>
            </LinkMiniNavigation>
            <LinkMiniNavigation
              to={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_AMOUNT_KM}
            >
              <Button
                variant="info"
                active={
                  location.pathname ===
                  ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_AMOUNT_KM
                }
              >
                Monto Incorrecto
              </Button>
            </LinkMiniNavigation>
            <LinkMiniNavigation
              to={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_REJECTED}
            >
              <Button
                variant="info"
                active={
                  location.pathname ===
                  ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_REJECTED
                }
              >
                Factura Rechazada
              </Button>
            </LinkMiniNavigation>
            <LinkMiniNavigation to={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_ID}>
              <Button
                variant="info"
                active={
                  location.pathname === ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_ID
                }
              >
                Cruce inexistente
              </Button>
            </LinkMiniNavigation>
            <LinkMiniNavigation
              to={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_MISSING_INFO}
            >
              <Button
                variant="info"
                active={
                  location.pathname ===
                  ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_MISSING_INFO
                }
              >
                Traslados Incompletos
              </Button>
            </LinkMiniNavigation>
            <LinkMiniNavigation to={ROUTES.ADMIN_MODULES_TRANSFERS_MISSING_PDF}>
              <Button
                variant="info"
                active={
                  location.pathname ===
                  ROUTES.ADMIN_MODULES_TRANSFERS_MISSING_PDF
                }
              >
                Facturado sin PDF
              </Button>
            </LinkMiniNavigation>
            <LinkMiniNavigation
              to={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_MISSING_BILL_INFO}
            >
              <Button
                variant="info"
                active={
                  location.pathname ===
                  ROUTES.ADMIN_MODULES_TRANSFERS_WRONG_MISSING_BILL_INFO
                }
              >
                Sin Facturar
              </Button>
            </LinkMiniNavigation>
            <LinkMiniNavigation
              to={ROUTES.ADMIN_MODULES_TRANSFERS_SATAPP_INTERNO}
            >
              <Button
                variant="info"
                active={
                  location.pathname ===
                  ROUTES.ADMIN_MODULES_TRANSFERS_SATAPP_INTERNO
                }
              >
                Satapp Interno
              </Button>
            </LinkMiniNavigation>
            {/* <LinkMiniNavigation to={ROUTES.ADMIN_DUPLICATED_BILLS}>
              <Button
                variant="info"
                active={location.pathname === ROUTES.ADMIN_DUPLICATED_BILLS}
              >
                Facturas duplicadas
              </Button>
            </LinkMiniNavigation> */}
          </Col>
        </Row>
      </Container>
      <Container style={{ maxWidth: "100%" }}>{props.children}</Container>
    </Container>
  );
};

const LinkMiniNavigation = styled(Link)`
  color: #fff !important;
  Button {
    font-size: ${pxToPt(14)};
  }
  &:hover {
    text-decoration: none;
  }
  .btn {
    border-radius: 0;
  }
  .left {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .right {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;

export default MiniNavigationErrors;
