import UserCarAddPage from "components/User/Cars/new-edit";
import UserCarViewPage from "components/User/Cars/view";
import UserContractsViewPage from "components/User/Contracts/view";
import UserMyProfilePage from "components/User/MyProfile";
import ROUTES from "constants/routes";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { ProfileStatus } from "types/user-data";
import UserCarsPage from "./Cars";
import UserContractsPage from "./Contracts";
import UserHomePage from "./Home";
import UserMyProfileLogsPage from "./MyProfile/Logs";
import Navigation from "./Navigation";
import NewcomersWizzard from "./Newcomers";
import { useProfile } from "redux/app";
import SignUserContract from "./Contracts/contract";
import UserPayments from "./Payments";
import UserScheduleTransfers from "./Schedule";
import UserSchedulePriorityTransfers from "./Schedule/schedulePriority";
import UserPrioritySchedule from "./Schedule/schedulePriorityUser";
import UserActive from "./Active";
import UserScheduleTransfersPool from "./SchedulePool";
import UserSchedulTransfersPool from "./SchedulPool";

const User = () => {
  const profile = useProfile();
  const { profile_user_status, contract_completed } = profile;

  return (
    <Navigation>
      {profile_user_status !== ProfileStatus.APROBADO ? (
        <NewcomersWizzard />
      ) : !contract_completed ? (
        <SignUserContract />
      ) : (
        <Switch>
          <Route path={ROUTES.USER_NEWCOMERS} component={NewcomersWizzard} />
          <Route path={ROUTES.USER_HOME} component={UserHomePage} />
          <Route
            exact
            path={ROUTES.USER_MY_PROFILE}
            component={UserMyProfilePage}
          />
          <Route
            path={ROUTES.USER_PROFILE_HISTORY}
            component={UserMyProfileLogsPage}
          />
          <Route exact path={ROUTES.USER_CARS} component={UserCarsPage} />
          <Route
            path={ROUTES.USER_CARS_ADD_OR_EDIT()}
            component={UserCarAddPage}
          />
          <Route path={ROUTES.USER_CARS_VIEW()} component={UserCarViewPage} />
          <Route
            exact
            path={ROUTES.USER_CONTRACTS}
            component={UserContractsPage}
          />
          <Route exact path={ROUTES.USER_PAYMENTS} component={UserPayments} />
          <Route
            exact
            path={ROUTES.USER_SCHEDULE_TRANFSERS}
            component={UserScheduleTransfers}
          />
          <Route
            exact
            path={ROUTES.USER_SCHEDULE_TRANFSERS_POOL}
            component={UserScheduleTransfersPool}
          />
          <Route
            exact
            path={ROUTES.USER_SCHEDUL_TRANFSERS_POOL}
            component={UserSchedulTransfersPool}
          />
          <Route
            exact
            path={ROUTES.USER_PRIORITY_TRANFSERS}
            component={UserSchedulePriorityTransfers}
          />
          <Route
            exact
            path={ROUTES.USER_PRIORITY}
            component={UserPrioritySchedule}
          />
          <Route exact path={ROUTES.USER_ACTIVE} component={UserActive} />
          <Route
            path={ROUTES.USER_CONTRACTS_VIEW()}
            component={UserContractsViewPage}
          />
        </Switch>
      )}
    </Navigation>
  );
};

export default User;
