import React from 'react';
import {Navbar, Container} from 'react-bootstrap'
import {Center,Padding,P, Link} from 'components/styles'
import LogoHorizontalImg from 'assets/logo-horizontal.png'
import ROUTES from 'constants/routes';

const PrivacyPage = () => {
    return (
    <>
    <HeaderLandingSubpages/>
    <Container style={{backgroundColor:"white"}}>
    <Padding pad={"20px"}>
        <Center><h4>POLÍTICA DE PRIVACIDAD</h4></Center>
        <Center><h5>CUESTIONES PRELIMINARES</h5></Center>
        <P>La presente Política de Privacidad (en adelante la “Política de Privacidad”)
        forma parte integrante e inseparable de los términos y condiciones de uso (en
        adelante los &quot;Términos y Condiciones&quot;) del sitio web de titularidad de SATAPP
        S.R.L. www.app.satapp.com.ar (el “Sitio Web”) y de su aplicación móvil (la
        “Aplicación”) y la misma debe ser interpretada en forma armónica y
        concordante con esos Términos y Condiciones.</P>
        <P>SATAPP S.R.L., CUIT 30-71620728-1, es una sociedad de responsabilidad
        limitada constituida de conformidad con la legislación de la República
        Argentina, con sede social en Remedios de Escalada de San Martín 3050,
        Ciudad de Buenos Aires, Argentina (en adelante, “SATAPP”), que facilita la
        intermediación entre terceros independientes proveedores de servicios de
        transporte privado (los “Conductores”) y sujetos ￼que requieren servicios de
        transporte de pasajeros y/o carga y/o cosas y/o mensajería (“Usuario
        Solicitante”) mediante el uso de una plataforma tecnológica plasmada en su
        Sitio Web y/o la Aplicación y/o los canales de comunicación puestos a
        disposición por SATAPP, lo cual permite conectar las necesidades del Usuario
        Solicitante con los servicios ofrecidos por los Conductores (el “Servicio”).La
        presente Política de Privacidad informa sobre la protección integral que
        SATAPP brinda a los datos personales y/o financieros asentados en sus
        archivos, registros, bancos de datos, u otros medios técnicos de tratamiento de
        datos que obren en poder de SATAPP para garantizar el derecho al honor y a
        la intimidad de las personas, así como también el acceso a la información que
        sobre las mismas se registre, de conformidad a lo establecido en el artículo 43,
        párrafo tercero de la Constitución Nacional y en un todo de acuerdo con las
        disposiciones de la ley nacional de protección de datos personales (Ley
        25.326) y a los Tratados Internacionales de aplicación.</P>
        <P>Esta Política de Privacidad dispone una serie de pautas que regulan los
        mecanismos de tratamiento, almacenamiento, uso y protección de los datos
        –personales y/o financieros y/o fiscales- que los usuarios del Sitio Web y/o de
        la Aplicación ingresen y proporcionen de sí mismos como respecto de terceros.</P>
        <P>Una de las prioridades de SATAPP es respetar la privacidad de los usuarios del
        Sitio Web y procurar mantener siempre segura y protegida la información y los
        datos personales y/o financieros que SATAPP recolecta a raíz de la utilización
        del Sitio Web y de la Aplicación (en adelante la &quot;Información&quot;).</P>
        <P>La presente Política de Privacidad detalla e informa al Conductor y el Usuario
        Solicitante qué tipo de Información recolecta SATAPP, cómo la almacena, cuál
        es la finalidad de su archivo, cómo la protege y cuáles son los derechos del
        usuario como titular de la Información.</P>
        <P>Al visitar, navegar, utilizar y/o crear un usuario en SATAPP por intermedio de
        su Sitio Web y/o la Aplicación, usted está aceptando expresamente los
        términos de la Política de Privacidad.</P>
        <P>A los fines del presente, por PLATAFORMA se entenderá al Sitio Web y/o a la
        Aplicación como a cualquier otro medio de comunicación puestos a disposición
        del CONDUCTOR y/o del USUARIO SOLICITANTE por parte de SATAPP.</P>

        <Center><h5>2. INFORMACIÓN OBLIGATORIA</h5></Center>

        <P>A fin de que el usuario pueda utilizar la PLATAFORMA. con la correspondiente
        obtención de una cuenta usuario, SATAPP necesita conocer la siguiente
        Información: dirección de e-mail, nombre y apellido completo del sujeto que
        ingresa los datos, tipo de documento identificatorio y número, profesión,
        género, domicilio, fecha de nacimiento, nacionalidad, estado civil, número de
        teléfono y, de corresponder, razón social y denominación de la sociedad, CUIT,
        Clave Bancaria Uniforme (CBU) y situación tributaria ante AFIP, los que
        constituyen datos mínimos, necesarios e indispensables, según corresponda
        para la obtención y validación de cada tipo de usuario a través de la
        PLATAFORMA. Asimismo, en algunos casos, y dependiendo del tipo de
        usuario que desee obtener el sujeto, se solicitarán datos complementarios
        como: número de licencia de conducir, categoría, fecha de vencimiento, foto del
        dorso y frente de la licencia de conducir, nombre y apellido/ razón y
        denominación social, documento identificatorio y número del titular dominial del
        vehículo empleado por el usuario para brindar el servicio de transporte, datos
        de identificación del automotor como número de motor y dominio y datos de la
        póliza del seguro de responsabilidad civil profesional, lo que incluye foto de la
        misma.</P>
        <P>Asimismo, SATAPP utiliza otra información sobre el usuario que llega a
        conocer gracias al uso de los Servicios: ubicación del usuario; su dirección IP;
        los viajes que se realizan; el código de identificación que SATAPP le asigna a
        cada usuario y viaje realizado; tu navegación por la PLATAFORMA (fechas y
        horas en las que se accede, clics realizados, entre otros.) y el hardware,
        software y navegador que utiliza el usuario. Asimismo, SATAPP recibe por
        parte del Usuario Solicitante datos personales como nombre, apellido, número
        de documento, lugar de origen y destino del transporte realizado por el
        Conductor, número de teléfono, entre otros, del sujeto que el Usuario
        Solicitante requiere trasladar. En consecuencia, la aceptación de la presente
        Política de Privacidad por parte del Usuario Solicitante implica el
        reconocimiento expreso de este último de contar con el consentimiento y
        autorización correspondiente del sujeto transportado para compartir los datos
        mencionados con SATAPP y el Conductor.</P>
        <P>En todos los casos. La Información deberá ser veraz, exacta, completa y
        actualizada, suministrada bajo declaración jurada por el usuario. El usuario
        responderá -en cualquier caso- por la veracidad, exactitud, completitud y
        actualidad de la Información proporcionada y, en ningún caso SATAPP será
        responsable por ello ni por las faltas, vicios u omisiones imputables al usuario
        al tiempo del suministro de dicha Información.</P>
        <P>El Usuario Solicitante será el único responsable ante SATAPP, el sujeto
        transportador y terceros por la divulgación, tratamiento, cesión, veracidad,
        exactitud y actualización de la Información del sujeto transportado que haya
        brindado y puesto a disposición de SATAPP y/o el Conductor.</P>

        <P>SATAPP se reserva el derecho de restringir el acceso a la PLATAFORMA n a
        todo usuario que haya proporcionado datos falsos, imprecisos, engañosos,
        confusos, simulando ser otra persona, sin perjuicio de las demás acciones que
        procedan conforme a derecho.</P>

        <Center><h5>3. ALMACENAMIENTO DE INFORMACIÓN</h5></Center>

        <P>Cuando el usuario ingresa la Información al servidor a través de la
        PLATAFORMA, éstos son transformados en códigos que viajan en forma
        encriptada desde su computadora al servidor que administra SATAPP. Una vez
        recibidos los datos que SATAPP necesita conocer para la prestación del
        Servicio, los mismos son decodificados y procesados, con excepción de las
        medidas de seguridad (usuario, claves, etc.) que permanecen constantemente
        encriptadas en la base de datos.</P>

        <Center><h5>4. FINALIDAD DEL ARCHIVO. USO DE LA INFORMACIÓN. CONSERVACIÓN DE LA INFORMACIÓN.</h5></Center>

        <P>SATAPP usa la Información obtenida para identificar al usuario y al sujeto
        transportado y de este modo procurar un servicio eficiente. SATAPP no
        venderá, cederá ni transferirá la Información que obtenga del usuario, sin
        autorización previa por parte del usuario.</P>
        <P>Al aceptar la Política de Privacidad, el usuario autoriza a SATAPP a:</P>
        <P>(i) Divulgar la Información ingresada en la PLATAFORMA con la
        finalidad de que SATAPP realice gestiones destinadas a la obtención
        de beneficios proporcionados por terceros, cuyo destinatario final
        será el usuario.</P>
        <P>(ii) Que el Conductor que brinda los servicios de transporte ubique en
        tiempo y espacio al sujeto transportado para poder prestar un
        servicio eficiente y rápido, y de este modo pueda conocer los puntos
        de traslado comprometidos en el transporte requerido.</P>

        <P>(iii) Utilizar la Información para prestar los servicios y reservar vehículos
        con el Conductor; realizar reservas de viajes; materializar los viajes
        realizados por los usuarios para que estos puedan controlar los
        trayectos y montos comprometidos en los viajes; permitir consultar el
        historial de viajes; permitir a SATAPP mejor la atención, asistencia y
        servicio brindados a los usuarios.</P>
        <P>(iv) Que pueda gestionar pagos y emisión de facturas fiscales. Esto
        implica que SATAPP conozca y tenga acceso a datos bancarios y/o
        tributarios de los usuarios, según corresponda en cada caso. Dicha
        información solo podrá ser utilizada para gestionar pagos y/o emisión
        de facturas fiscales.</P>
        <P>(v) Desarrollar estudios internos sobre los intereses, comportamientos y
        demografía de los titulares de los datos para comprender mejor sus
        necesidades e intereses y ofrecer mejores servicios o proveerles
        información relacionada. En todo momento el usuario podrá
        requerirle a SATAPP que se abstenga de continuar utilizando la
        Información proporcionada para los fines aquí dispuestos.</P>
        <P>(vi) Enviar información o mensajes por e-mail sobre publicidad o
        promociones, banners, de interés para los usuarios, noticias sobre
        SATAPP. Si el usuario así lo prefiere, puede solicitar que lo excluyan
        de las listas para el envío de información promocional o publicitaria,
        mediante el botón denominado [“Centro de Ayuda”] en la Aplicación,
        o bien, por sí mismo, a través de la opción disponible en el Menú
        principal de la misma.</P>
        <P>(vii) Prevenir el fraude contra los usuarios y/o SATAPP. En tal sentido,
        SATAPP implementa y desarrolla medidas de seguridad tendientes a
        prevenir el fraude. SATAPP podrá solicitar a sus usuarios validen su
        cuenta en SATAPP mediante la incorporación de un código de
        validación que el usuario recibirá por SMS o por email y/o datos de
        información análogos. Asimismo, en algunos supuestos SATAPP
        puede necesitar revelar Información de viajes y/o trayectos
        realizados a entidades bancarios y/o emisoras de medios de pago
        para resolver posibles conflictos.</P>

        <P>(viii) Confirmar la Información suministrada acudiendo a entidades
        públicas, compañías especializadas o centrales de riesgo, para lo
        cual el usuario autoriza expresamente a SATAPP mediante el
        presente. La Información que SATAPP obtenga de estas entidades
        será tratada en forma confidencial.</P>
        <P>(ix) Realizar encuestas a los usuarios para conocer la percepción de
        estos respecto del servicio brindado por SATAPP y elaborar
        estadísticas y análisis. La información recibida de este modo será
        sometida a técnicas que permitan disociar la Información de la
        identidad del titular, de modo tal que las respuestas brindadas por el
        usuario se encuentren asociada a un código. Esta información podrá
        ser remitida a terceros que ayudan a SATAPP a realizar estudios de
        mercados y análisis estadísticos respecto del Servicio. El usuario
        podrá negarse a realizar estas encuestas cliqueando el botón
        “cancelar” que se encuentra a la [derecha], parte inferior, de la
        encuesta remitida por SATAPP.</P>

        <P>La Información recopilada por SATAPP será conservada y almacenada en la
        base y registros de SATAPP de conformidad con los plazos dispuestos por la
        normativa aplicable en cada caso, lo que podrá ser consultado por los titulares
        de la Información en todo momento.</P>

        <Center><h5>5. PROTECCIÓN DE LA INFORMACIÓN</h5></Center>

        <P>SATAPP tiene incorporados mecanismos de protección de la Información, a fin
        de evitar –razonablemente- desviaciones y/o su adulteración, pérdida, consulta
        o tratamiento de datos no autorizado. SATAPP trabaja con la más alta
        encriptación comercialmente disponible, para que la Información no pueda ser
        leída mientras circula a través de Internet. Sin embargo, SATAPP no puede
        garantizar la seguridad, confidencialidad o inalterabilidad absoluta de la
        Información, dado que Internet es una red electrónica abierta y masiva.</P>

        <P>SATAPP permite a sus empleados y/o a entidades controladas y/o vinculadas
        y/o bajo el mismo control común el acceso a la Información del usuario para
        procesar solicitudes vinculadas al servicio prestado por SATAPP. Sin perjuicio
        de ello, los datos necesarios para realizar operaciones, como la clave personal
        del usuario, no se encuentran a disposición de ningún empleado, ejecutivo o
        asesor de SATAPP, como así tampoco de las entidades controladas,
        vinculadas y/o bajo mismo control común, dado que los mismos son
        automáticamente encriptados por la base de datos de SATAPP al ser
        ingresados por el usuario.</P>

        <Center><h5>6. DERECHOS DEL USUARIO TITULAR DE LA INFORMACIÓN</h5></Center>
        
        <P>El usuario, en su carácter de titular de la Información suministrada a SATAPP,
        tendrá derecho a acceder a sus datos personales y/o financieros incluidos en la
        base de datos de SATAPP (“Derecho de Acceso”), y/o rectificar (“Derecho de
        Rectificación”) y/o actualizar (“Derecho de Actualización”) y/o suprimir
        (“Derecho de Supresión”) dicha Información, sea a través del mecanismo
        previsto en el mismo Sitio Web y/o a través de los canales de contacto que se
        encuentren habilitados para tales fines, y que pueden visualizarse en el Sitio
        Web, a través del [centro de ayuda provisto a dichos fines].
        Para que el usuario pueda ejercer estos derechos, SATAPP verificará, en
        forma previa a rectificar y/o actualizar y/o suprimir la Información que el usuario
        solicite, la identidad del usuario solicitando que remita una foto del sujeto con el
        documento de identidad cercano a su rostro, visible y clara, de modo tal que
        SATAPP puede comprobar la identidad del solicitante.</P>
        <P>A continuación, un breve detalle del contenido de los derechos que le
        corresponden al titular de la Información proporcionada a SATAPP:</P>
        <P>1. Derecho de Acceso: puede requerirle a SATAPP información y acceso a
        sus datos personales incluidos en la base de datos de SATAPP; solicitar
        que se le proporcione el detalle de los datos personales de su titularidad
        que se encuentran en la base de datos de SATAPP; con qué
        proveedores y/o terceros han sido compartidos dichos datos en función
        de lo dispuesto en la presente Política de Privacidad. El Derecho de
        Acceso podrá ser ejercido en forma gratuita por el titular de la
        Información, siempre y cuando sea solicitado en intervalos no inferiores
        a los seis (6) meses, salvo que acredite interés legítimo al efecto, en
        cuyo caso será gratuito incluso cuando el pedido sea efectuado en
        intervalos menores de tiempo.</P>
        <P>2. Derecho de Actualización y Derecho de Rectificación: solicitar a
        SATAPP la actualización y/o rectificación de la Información. Todos los
        usuarios, al aceptar la Política de Privacidad, asumen el compromiso de
        mantener actualizados su Información, lo cual pueden realizar por
        intermedio de la PLATAFORMA, accediendo a su usuario. En caso de
        que no sea posible efectuarlo de este modo, el titular de la Información a
        rectificar y/o actualizar deberá ponerse en contacto con el centro de
        ayuda de SATAPP a dichos efectos.</P>
        <P>3. Derecho de Supresión: requerirle a SATAPP que proceda a suprimir
        datos personales del titular afectado. A dichos fines, SATAPP realizará
        todas operaciones necesarias dentro de los plazos prescriptos por la
        normativa aplicable. La solicitud de supresión de Información por parte
        de su titular no procede cuando pudiese causar perjuicios a derechos o
        intereses legítimos de terceros o cuando existiera una obligación legal
        de SATAPP de conservar esos datos.</P>
        <P>4. En todo momento, los titulares de la Información podrán solicitarle a
        SATAPP que restrinja la utilización de la Información proporciona que
        sea utilizada para algunas de las finalidades previstas en el punto 4,
        incisos (i), (v), (vi), (vii) y (ix).</P>
        <P>Asimismo, los derechos aquí consagrados también podrán ser ejercidos por
        parte del sujeto transportado como titular de la Información proporcionada por
        el Usuario Solicitante a SATAPP.</P>
        <P>El derecho del usuario consagrado en la presente cláusula podrá ser ejercido
        por el titular de la Información en forma gratuita y siempre que se trate de un
        ejercicio razonable, justificado y se acredite interés legítimo al efecto. El
        ejercicio de ese derecho les concierne también a los sucesores universales de
        las personas fallecidas respecto de la Información de su titularidad.</P>

        <Center><h5>7. MODIFICACIONES EN LA POLÍTICA DE PRIVACIDAD</h5></Center>

        <P>SATAPP se reserva el derecho de efectuar, en cualquier momento,
        modificaciones en la presente Política de Privacidad y adaptarla a novedades
        legislativas, jurisprudenciales, así como a prácticas razonables de mercado.
        Esas modificaciones serán comunicadas a los usuarios al email vinculado, con
        la finalidad de que este proceda a tomar conocimiento de las modificaciones
        introducidas por SATAPP. Luego de recibida la comunicación, el usuario
        deberá aceptar los cambios y/o actualizaciones introducidos a la Política de
        Privacidad en forma expresa, lo cual será realizado al momento en que el
        Conductor y/o el Usuario Solicitante intenten utilizar su usuario en SATAPP.
        Sin perjuicio de ello, se aconseja al usuario controlar periódicamente el
        contenido de la Política de Privacidad.</P>
        <P>Asimismo, ante cualquier consulta derivada de los términos de la Política de
        Privacidad, los canales de comunicación de SATAPP establecidos en su Sitio
        Web se encuentran a disposición de los usuarios y/o titulares de la Información.   </P>
    </Padding>
    </Container>
    </>
)}

export const HeaderLandingSubpages = () => (
    <Container>
    <Navbar bg="light" expand="lg" className="mainMenu">
        <Link to={ROUTES.LANDING}>
            <img
                src={LogoHorizontalImg}
                height="40"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"/>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
    </Navbar>
    <hr style={{marginTop:0}}/>
    </Container>
)

export default PrivacyPage;