import { ArtSelector } from "components/Admin/Sales/Sales/generate-bill";
import { GetProperty } from "components/Admin/Sales/Sales/view-bill";
import {
  Button,
  H2,
  NoData,
  Padding,
  Searcher,
  Spinner,
} from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import { WithId } from "controllers/database";
import { useDbCollection } from "controllers/hooks";
import { getFullAddress } from "controllers/map";
import firebase from "firebase";
import { isEmpty } from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Row, Table as TableB } from "react-bootstrap";
import Moment from "react-moment";
import { isLoaded } from "react-redux-firebase";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  DefinitionsModuleTransferType,
  ModuleTransfer,
  TypeModuleReject,
} from "types/module-transfer";
import { FinishStatus, TransferStage } from "types/transfer";
import { usePaginator } from "util/hooks";
import MiniNavigationErrors from "../MiniNavigation";

const AdminTransfersMissingPDF = () => {
  return (
    <MiniNavigationErrors active={2}>
      <MainContainer
        viewTransfer={ROUTES.ADMIN_MODULE_TRANSFER_VIEW}
        art={false}
      />
    </MiniNavigationErrors>
  );
};

export const MainContainer = ({
  viewTransfer,
  art,
}: {
  viewTransfer: (a: any) => string;
  art: boolean;
}) => {
  const pageLimit = 50;
  const [items, loading, Pagination, setCollection] = usePaginator(
    FIRESTORE.COLLECTION_TRANSFERS()
      .where("sale.bill_info.missing", "==", true)
      .orderBy("origin.time", "desc"),
    pageLimit
  );
  const [items2, loading2, Pagination2, setCollection2] = usePaginator(
    FIRESTORE.COLLECTION_MODULES_TRANSFER()
      //.where("sale.bill_info.missing", "!=", true)
      .where("sale.bill_info.pdf", "==", "")
      .orderBy("origin.time", "desc"),
    pageLimit
  );
  const [filter, setFilter] = useState("Compras");
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);

  const generatePdf = async () => {
    const data = { modules: selected };
    CALLABLE_FUNCTIONS.ADMIN_GENERATE_PDF(data);
  };

  useEffect(() => {
    setFilteredItems(items2.filter((item) => item.transfer_id == undefined));
    console.log(filteredItems);
  }, [loading2]);
  console.log(items);
  return (
    <Padding pad="5px">
      <Row>
        <Col xl={3}>
          <H2>Facturados sin PDF</H2>
        </Col>
      </Row>
      <Form.Control
        as="select"
        value={filter}
        onChange={(event) => setFilter(event.target.value)}
      >
        <option key={0} value={"Compras"}>
          Compras
        </option>
        <option key={1} value={"Ventas"}>
          Ventas
        </option>
      </Form.Control>
      {loading || loading2 ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        <Table hover responsive>
          <thead>
            <Tr>
              <th>ART</th>
              <th>Fecha</th>
              <th>Siniestro-Aut/NPA</th>
              <th>Tipo</th>
              <th>Ver</th>
              <th>Acción</th>
            </Tr>
          </thead>
          <tbody>
            {filter === "Compras" ? (
              <>
                {items.map((item) => (
                  <tr key={item.id}>
                    <Td>{item.art.name}</Td>

                    <Td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY HH:mm"
                      />
                    </Td>
                    <Td style={{ wordBreak: "break-all" }}>
                      {item.art.accident && item.art.authorization
                        ? item.art.accident + "-" + item.art.authorization
                        : item.art.accident
                        ? item.art.accident
                        : "NPA: " + item.art.npa}
                    </Td>
                    <Td>
                      {item.status.finished_status === FinishStatus.Negativo
                        ? "Negativo"
                        : DefinitionsModuleTransferType[item.type]}
                    </Td>
                    <Td>
                      <Link to={viewTransfer(item.module.id)}>
                        <Button variant="light">Ver</Button>
                      </Link>
                    </Td>
                  </tr>
                ))}
                <Pagination />
              </>
            ) : (
              <>
                {items2.map((item) => (
                  <tr key={item.id}>
                    <Td>{item.art.name}</Td>
                    <Td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY HH:mm"
                      />
                    </Td>
                    <Td style={{ wordBreak: "break-all" }}>
                      {item.art.accident && item.art.authorization
                        ? item.art.accident + "-" + item.art.authorization
                        : item.art.accident
                        ? item.art.accident
                        : "NPA: " + item.art.npa}
                    </Td>
                    <Td>
                      {item.status.finished_status === FinishStatus.Negativo
                        ? "Negativo"
                        : DefinitionsModuleTransferType[item.type]}
                    </Td>
                    <Td>
                      <Link to={viewTransfer(item.id)}>
                        <Button variant="light">Ver</Button>
                      </Link>
                    </Td>
                    <Td>
                      {!selected.some((select) => select.id == item.id) ? (
                        <Button
                          onClick={() => {
                            const z = [...selected];
                            z.push(item);
                            setSelected(z);
                          }}
                        >
                          Agregar
                        </Button>
                      ) : (
                        <Button
                          variant="danger"
                          onClick={() => {
                            const z = [...selected];
                            z.splice(
                              z.findIndex((element) => element.id == item.id),
                              1
                            );
                            setSelected(z);
                          }}
                        >
                          Quitar
                        </Button>
                      )}
                    </Td>
                  </tr>
                ))}
                <Pagination2 />
              </>
            )}
          </tbody>
        </Table>
      )}
    </Padding>
  );
};

const Tr = styled.tr`
  th {
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  word-wrap: break-word;
`;

const Table = styled(TableB)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default AdminTransfersMissingPDF;
