import React, { useState, useEffect, useRef } from "react";
import { FbCollection, FbQuery } from "types/transfer";
import { Pagination } from "react-bootstrap";
import { WithId, WithUid, WithRef } from "controllers/database";
import { useHistory } from "react-router";
import queryString from "query-string";
import { ModuleTransfer } from "types/module-transfer";

export function useInterval(callback: any, delay: number) {
  const savedCallback = useRef<any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const usePromise = <T extends unknown>(
  promise: Promise<T>
): [T | undefined, boolean] => {
  const [value, setValue] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    promise.then(setValue).then(() => setLoading(false));
  }, []);
  return [value, loading];
};

export const usePromiseArray = <T extends unknown>(
  promise: Promise<T>
): [T, boolean] => {
  const [value, setValue] = useState<T>([] as T);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    promise.then(setValue).then(() => setLoading(false));
  });
  return [value, loading];
};

export const useQueryParam = <_, T>(
  defaultValue: T,
  name: string,
  conv2type?: (v: string) => T,
  conv2string?: (v: T) => string
): [T, (v: T) => void] => {
  const history = useHistory();
  const [value, setValue] = useState(defaultValue);
  const parsed = queryString.parse(window.location.search);

  useEffect(() => {
    const newValue = parsed[name] as string | undefined;
    if (newValue !== undefined)
      setValue(
        conv2type !== undefined
          ? conv2type(newValue)
          : (newValue as unknown as T)
      );
  }, [parsed[name]]);

  const _setValue = (v: T) => {
    const newValue =
      conv2string !== undefined ? conv2string(v) : (v as unknown as string);
    parsed[name] = newValue;
    history.push(`?${queryString.stringify(parsed)}`);
  };

  return [value, _setValue];
};

/**
 * Helper para paginar (estático)
 * @param collection Colección
 * @param limit Límite de cada página
 */
export const usePaginator = <T extends object>(
  collection: FbCollection<T> | FbQuery<T> | undefined,
  limit: number = 10
): [
  (T & WithId)[],
  boolean,
  (v?: any) => JSX.Element,
  (x: FbCollection<T> | FbQuery<T>) => void
] => {
  const [lastDoc, setLastDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [firstDoc, setFirstDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [items, setItems] = useState<(T & WithId)[]>([]);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<{
    first?: firebase.firestore.QueryDocumentSnapshot;
    last?: firebase.firestore.QueryDocumentSnapshot;
    page: number;
  }>({ page: 0 });
  const [_collection, _changeCollection] = useState(collection);

  useEffect(() => {
    setConfig({ page: 0 });
  }, [_collection, limit]);

  useEffect(() => {
    query();
  }, [config]);

  const query = async () => {
    if (_collection === undefined) return;

    setLoading(true);
    let collectionWithBound = _collection;
    if (config.first)
      collectionWithBound = _collection.startAfter(config.first).limit(limit);
    else if (config.last)
      collectionWithBound = _collection
        .endBefore(config.last)
        .limitToLast(limit);
    else collectionWithBound = collectionWithBound.limit(limit);

    const snap = await collectionWithBound.get();
    setItems(snap.docs.map((s) => ({ ...s.data(), id: s.id })));
    setFirstDoc(snap.docs[0]);
    setLastDoc(snap.docs[snap.docs.length - 1]);
    setLoading(false);
  };

  const nextPage = () => {
    setConfig({ page: config.page + 1, first: lastDoc });
  };
  const prevPage = () => {
    setConfig({ page: config.page - 1, last: firstDoc });
  };
  const PaginationComponent = ({ style }: { style?: React.CSSProperties }) => (
    <Pagination style={{ justifyContent: "center", ...style }}>
      {config.page > 0 && <Pagination.Prev onClick={prevPage} />}
      <Pagination.Item active>{config.page + 1}</Pagination.Item>
      {items.length === limit && <Pagination.Next onClick={nextPage} />}
    </Pagination>
  );

  return [items, loading, PaginationComponent, _changeCollection];
};

export const usePaginatorWithRef = <T extends object>(
  collection: FbCollection<T> | FbQuery<T> | undefined,
  limit: number = 10
): [
  (T & WithRef)[],
  boolean,
  (v?: any) => JSX.Element,
  (x: FbCollection<T> | FbQuery<T>) => void
] => {
  const [lastDoc, setLastDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [firstDoc, setFirstDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [items, setItems] = useState<(T & WithRef)[]>([]);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<{
    first?: firebase.firestore.QueryDocumentSnapshot;
    last?: firebase.firestore.QueryDocumentSnapshot;
    page: number;
  }>({ page: 0 });
  const [_collection, _changeCollection] = useState(collection);

  useEffect(() => {
    setConfig({ page: 0 });
  }, [_collection, limit]);

  useEffect(() => {
    query();
  }, [config]);

  const query = async () => {
    if (_collection === undefined) return;

    setLoading(true);
    let collectionWithBound = _collection;
    if (config.first)
      collectionWithBound = _collection.startAfter(config.first).limit(limit);
    else if (config.last)
      collectionWithBound = _collection
        .endBefore(config.last)
        .limitToLast(limit);
    else collectionWithBound = collectionWithBound.limit(limit);

    const snap = await collectionWithBound.get();
    setItems(snap.docs.map((s) => ({ ...s.data(), ref: s.ref })));
    setFirstDoc(snap.docs[0]);
    setLastDoc(snap.docs[snap.docs.length - 1]);
    setLoading(false);
  };

  const nextPage = () => {
    setConfig({ page: config.page + 1, first: lastDoc });
  };
  const prevPage = () => {
    setConfig({ page: config.page - 1, last: firstDoc });
  };
  const PaginationComponent = ({ style }: { style?: React.CSSProperties }) => (
    <Pagination style={{ justifyContent: "center", ...style }}>
      {config.page > 0 && <Pagination.Prev onClick={prevPage} />}
      <Pagination.Item active>{config.page + 1}</Pagination.Item>
      {items.length === limit && <Pagination.Next onClick={nextPage} />}
    </Pagination>
  );

  return [items, loading, PaginationComponent, _changeCollection];
};

export const usePaginatorCustomSales = (
  collection:
    | FbCollection<ModuleTransfer>
    | FbQuery<ModuleTransfer>
    | undefined,
  limit: number = 10
): [
  (ModuleTransfer & WithId)[],
  boolean,
  (v?: any) => JSX.Element,
  (x: FbCollection<ModuleTransfer> | FbQuery<ModuleTransfer>) => void
] => {
  const [lastDoc, setLastDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [firstDoc, setFirstDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [items, setItems] = useState<(ModuleTransfer & WithId)[]>([]);
  const [filteredItems, setFilteredItems] = useState<
    (ModuleTransfer & WithId)[]
  >([]);

  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<{
    first?: firebase.firestore.QueryDocumentSnapshot;
    last?: firebase.firestore.QueryDocumentSnapshot;
    page: number;
  }>({ page: 0 });
  const [_collection, _changeCollection] = useState(collection);

  useEffect(() => {
    setConfig({ page: 0 });
  }, [_collection, limit]);

  useEffect(() => {
    query();
  }, [config]);

  useEffect(() => {
    let filtered = [...items];
    console.log("---------------------------------------");
    console.log(filtered);
    filtered.filter((item) => {
      if (item.status.finished_status != 1) {
        return true;
      } else {
        return false;
      }
    });
    console.log("---------------------------------------");
    console.log(filtered);
    filtered.filter((item) => {
      if (item.reject) {
        if (item.reject.status == false) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    console.log("---------------------------------------");
    console.log(filtered);
    setFilteredItems(filtered);
  }, [items]);

  const query = async () => {
    if (_collection === undefined) return;

    setLoading(true);
    let collectionWithBound = _collection;
    if (config.first)
      collectionWithBound = _collection.startAfter(config.first).limit(limit);
    else if (config.last)
      collectionWithBound = _collection
        .endBefore(config.last)
        .limitToLast(limit);
    else collectionWithBound = collectionWithBound.limit(limit);

    const snap = await collectionWithBound.get();
    setItems(snap.docs.map((s) => ({ ...s.data(), id: s.id })));
    setFirstDoc(snap.docs[0]);
    setLastDoc(snap.docs[snap.docs.length - 1]);
    setLoading(false);
  };

  const nextPage = () => {
    setConfig({ page: config.page + 1, first: lastDoc });
  };
  const prevPage = () => {
    setConfig({ page: config.page - 1, last: firstDoc });
  };
  const PaginationComponent = ({ style }: { style?: React.CSSProperties }) => (
    <Pagination style={{ justifyContent: "center", ...style }}>
      {config.page > 0 && <Pagination.Prev onClick={prevPage} />}
      <Pagination.Item active>{config.page + 1}</Pagination.Item>
      {filteredItems.length === limit && <Pagination.Next onClick={nextPage} />}
    </Pagination>
  );

  return [filteredItems, loading, PaginationComponent, _changeCollection];
};

/**
 * Helper para paginar (estático), devuelve uid de cada elemento también (id del padre del padre)
 * @param collection Colección
 * @param limit Límite de cada página
 */
export const usePaginatorWithUid = <T extends object>(
  collection: FbCollection<T> | FbQuery<T>,
  limit: number = 10
): [
  (T & WithId & WithUid)[],
  boolean,
  (v?: any) => JSX.Element,
  (x: FbCollection<T> | FbQuery<T>) => void
] => {
  const [lastDoc, setLastDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [firstDoc, setFirstDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [items, setItems] = useState<(T & WithId & WithUid)[]>([]);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<{
    first?: firebase.firestore.QueryDocumentSnapshot;
    last?: firebase.firestore.QueryDocumentSnapshot;
    page: number;
  }>({ page: 0 });
  const [_collection, _changeCollection] = useState(collection);

  useEffect(() => {
    setConfig({ page: 0 });
  }, [_collection, limit]);

  useEffect(() => {
    query();
  }, [config]);

  const query = async () => {
    setLoading(true);
    let collectionWithBound = _collection;
    if (config.first)
      collectionWithBound = _collection.startAfter(config.first).limit(limit);
    else if (config.last)
      collectionWithBound = _collection
        .endBefore(config.last)
        .limitToLast(limit);
    else collectionWithBound = collectionWithBound.limit(limit);

    const snap = await collectionWithBound.get();
    const data = snap.docs.map((s) => ({
      ...s.data(),
      id: s.id,
      uid: s.ref.parent.parent!.id,
    }));
    setItems(data);
    setFirstDoc(snap.docs[0]);
    setLastDoc(snap.docs[snap.docs.length - 1]);
    setLoading(false);
  };

  const nextPage = () => {
    setConfig({ page: config.page + 1, first: lastDoc });
  };
  const prevPage = () => {
    setConfig({ page: config.page - 1, last: firstDoc });
  };
  const PaginationComponent = ({ style }: { style?: React.CSSProperties }) => (
    <Pagination style={{ justifyContent: "center", ...style }}>
      {config.page > 0 && <Pagination.Prev onClick={prevPage} />}
      <Pagination.Item active>{config.page + 1}</Pagination.Item>
      {items.length === limit && <Pagination.Next onClick={nextPage} />}
    </Pagination>
  );

  return [items, loading, PaginationComponent, _changeCollection];
};

/**
 * Helper para paginar (estático) con función para transformar elementos
 * @param collection Colección
 * @param transform Función para transformar los datos a los queridos
 * @param limit Límite de cada página
 */
export const usePaginatorWithTransform = <F, T extends object>(
  collection: FbCollection<F> | FbQuery<F> | undefined,
  transform: (v: F & WithId) => Promise<T & WithId>,
  limit: number = 10
): [
  (T & WithId)[],
  boolean,
  (v?: any) => JSX.Element,
  (x: FbCollection<F> | FbQuery<F>) => void
] => {
  const [lastDoc, setLastDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [firstDoc, setFirstDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [items, setItems] = useState<(T & WithId)[]>([]);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<{
    first?: firebase.firestore.QueryDocumentSnapshot;
    last?: firebase.firestore.QueryDocumentSnapshot;
    page: number;
  }>({ page: 0 });
  const [_collection, _changeCollection] = useState(collection);

  useEffect(() => {
    setConfig({ page: 0 });
  }, [_collection, limit]);

  useEffect(() => {
    query();
  }, [config]);

  const query = async () => {
    if (_collection === undefined) return;

    setLoading(true);
    let collectionWithBound = _collection;
    if (config.first)
      collectionWithBound = _collection.startAfter(config.first).limit(limit);
    else if (config.last)
      collectionWithBound = _collection
        .endBefore(config.last)
        .limitToLast(limit);
    else collectionWithBound = collectionWithBound.limit(limit);

    const snap = await collectionWithBound.get();
    const newValues = await Promise.all(
      snap.docs.map(async (s) => transform({ ...s.data(), id: s.id }))
    );
    setItems(newValues);
    setFirstDoc(snap.docs[0]);
    setLastDoc(snap.docs[snap.docs.length - 1]);
    setLoading(false);
  };

  const nextPage = () => {
    setConfig({ page: config.page + 1, first: lastDoc });
  };
  const prevPage = () => {
    setConfig({ page: config.page - 1, last: firstDoc });
  };
  const PaginationComponent = ({ style }: { style?: React.CSSProperties }) => (
    <Pagination style={{ justifyContent: "center", ...style }}>
      {config.page > 0 && <Pagination.Prev onClick={prevPage} />}
      <Pagination.Item active>{config.page + 1}</Pagination.Item>
      {items.length === limit && <Pagination.Next onClick={nextPage} />}
    </Pagination>
  );

  return [items, loading, PaginationComponent, _changeCollection];
};

/**
 * Helper para paginar (estático) con función para transformar elementos que también devuelve uid de los elementos
 * @param collection Colección
 * @param transform Función para transformar los datos a los queridos
 * @param limit Límite de cada página
 */
export const usePaginatorWithUidWithTransform = <F, T extends object>(
  collection: FbCollection<F> | FbQuery<F> | undefined,
  transform: (v: F & WithId & WithUid) => Promise<T & WithId & WithUid>,
  limit: number = 10
): [
  (T & WithId & WithUid)[],
  boolean,
  (v?: any) => JSX.Element,
  (x: FbCollection<F> | FbQuery<F>) => void
] => {
  const [lastDoc, setLastDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [firstDoc, setFirstDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [items, setItems] = useState<(T & WithId & WithUid)[]>([]);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<{
    first?: firebase.firestore.QueryDocumentSnapshot;
    last?: firebase.firestore.QueryDocumentSnapshot;
    page: number;
  }>({ page: 0 });
  const [_collection, _changeCollection] = useState(collection);

  useEffect(() => {
    setConfig({ page: 0 });
  }, [_collection, limit]);

  useEffect(() => {
    query();
  }, [config]);

  const query = async () => {
    if (_collection === undefined) return;

    setLoading(true);
    let collectionWithBound = _collection;
    if (config.first)
      collectionWithBound = _collection.startAfter(config.first).limit(limit);
    else if (config.last)
      collectionWithBound = _collection
        .endBefore(config.last)
        .limitToLast(limit);
    else collectionWithBound = collectionWithBound.limit(limit);

    const snap = await collectionWithBound.get();
    const newValues = await Promise.all(
      snap.docs.map(async (s) =>
        transform({ ...s.data(), id: s.id, uid: s.ref.parent.parent!.id })
      )
    );
    setItems(newValues);
    setFirstDoc(snap.docs[0]);
    setLastDoc(snap.docs[snap.docs.length - 1]);
    setLoading(false);
  };

  const nextPage = () => {
    setConfig({ page: config.page + 1, first: lastDoc });
  };
  const prevPage = () => {
    setConfig({ page: config.page - 1, last: firstDoc });
  };
  const PaginationComponent = ({ style }: { style?: React.CSSProperties }) => (
    <Pagination style={{ justifyContent: "center", ...style }}>
      {config.page > 0 && <Pagination.Prev onClick={prevPage} />}
      <Pagination.Item active>{config.page + 1}</Pagination.Item>
      {items.length === limit && <Pagination.Next onClick={nextPage} />}
    </Pagination>
  );

  return [items, loading, PaginationComponent, _changeCollection];
};

/**
 * Helper para paginar (live)
 * @param collection Colección
 * @param limit Límite de cada página
 */
export const usePaginatorLive = <T extends object>(
  collection: FbCollection<T> | FbQuery<T> | undefined,
  limit: number = 10
): [
  (T & WithId)[],
  boolean,
  (v?: any) => JSX.Element,
  (x: FbCollection<T> | FbQuery<T>) => void
] => {
  const [lastDoc, setLastDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [firstDoc, setFirstDoc] = useState<
    firebase.firestore.QueryDocumentSnapshot | undefined
  >(undefined);
  const [items, setItems] = useState<(T & WithId)[]>([]);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<{
    first?: firebase.firestore.QueryDocumentSnapshot;
    last?: firebase.firestore.QueryDocumentSnapshot;
    page: number;
  }>({ page: 0 });
  const [_collection, _changeCollection] = useState(collection);

  useEffect(() => {
    setConfig({ page: 0 });
  }, [_collection, limit]);

  useEffect(() => {
    query();
  }, [config]);

  const query = async () => {
    if (_collection === undefined) return;

    setLoading(true);
    let collectionWithBound = _collection;
    if (config.first)
      collectionWithBound = _collection.startAfter(config.first).limit(limit);
    else if (config.last)
      collectionWithBound = _collection
        .endBefore(config.last)
        .limitToLast(limit);
    else collectionWithBound = collectionWithBound.limit(limit);

    const snap = await collectionWithBound.get();
    setItems(snap.docs.map((s) => ({ ...s.data(), id: s.id })));
    setFirstDoc(snap.docs[0]);
    setLastDoc(snap.docs[snap.docs.length - 1]);
    setLoading(false);
  };

  const nextPage = () => {
    setConfig({ page: config.page + 1, first: lastDoc });
  };
  const prevPage = () => {
    setConfig({ page: config.page - 1, last: firstDoc });
  };
  const PaginationComponent = ({ style }: { style?: React.CSSProperties }) => (
    <Pagination style={{ justifyContent: "center", ...style }}>
      {config.page > 0 && <Pagination.Prev onClick={prevPage} />}
      <Pagination.Item active>{config.page + 1}</Pagination.Item>
      {items.length === limit && <Pagination.Next onClick={nextPage} />}
    </Pagination>
  );

  return [items, loading, PaginationComponent, _changeCollection];
};

export const useDidUpdateEffect = (
  fn: React.EffectCallback,
  inputs?: React.DependencyList
) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, inputs);
};
