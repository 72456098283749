import { List } from "antd";
import { DatePickerInput } from "components/input/date-picker";
import { Field } from "components/input/field";
import {
  AdminTable,
  Button,
  ContainerPage,
  H3,
  Link,
  NoData,
  PageTitle,
  QuestionTooltip,
  Spinner,
  SubmitButton,
} from "components/styles";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import { required } from "controllers/validations";
import "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Col,
  Form,
  Row,
  Pagination as BPagination,
  Container,
} from "react-bootstrap";
import Moment from "react-moment";
import { reduxForm } from "redux-form";
import { PaymentOrderArt, TypePayment } from "types/billing";
import { FbDate } from "types/transfer";
import { usePaginator } from "util/hooks";
import { getEnumKeys } from "util/typescript";
import { useSaveSelection, useSaveSelectionCustom } from "../reducer";
import { ArtSelector } from "../Sales/generate-bill";
import {
  getArtInvoicesNotCanceled,
  getArtInvoicesWithoutPayment,
  getArtInvoicesWithoutPaymentNotCanceled,
} from "../Sales/reducer";
import { GetProperty } from "../Sales/view-bill";
import { DropdownMenu } from "./dropdowns";
const AddChargePayment = () => {
  const [artSelected, setArtSelected] = useState<string | undefined>(undefined);
  const [keyword, setKeyword] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(14, "days").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().add(14, "days").toDate()
  );

  const [items, loading, Pagination, setCollection] = usePaginator(
    getArtInvoicesNotCanceled(artSelected, startDate, endDate, keyword),
    30
  );
  const [selected, setSelected] = useSaveSelectionCustom("art-payment");
  // const [total, setTotal] = useSaveSelection("art-payment");
  // console.log(total, "<---TOTAL");
  ///console.log(selected, "<---SELECTED");
  const [newDocument, setNewDocument] = useState<string | undefined>(undefined);
  const [provincia, setProvincia] = useState<string>("");
  const [retenciones, setRetenciones] = useState<any[]>([]);
  const [valor, setValor] = useState<string>("");
  const [retGanancias, setRetGanancias] = useState<string>("");
  const [compRRGG, setCompRRGG] = useState<string>("");
  const [compIIBB, setCompIIBB] = useState<string>("");
  const [dateIIBB, setDateIIBB] = useState<string>("");
  const [dateRRGG, setDateRRGG] = useState<string>("");

  useEffect(() => {
    reloadQuery();
  }, [artSelected, startDate, endDate, keyword]);

  const reloadQuery = () =>
    setCollection(
      getArtInvoicesWithoutPaymentNotCanceled(
        artSelected,
        startDate,
        endDate,
        keyword
      )
    );

  const onSubmit = async (values: any) => {
    const bills = await Promise.all(
      [...selected].map(async (v) => {
        console.log(v);
        const v_1 = await FIRESTORE.DOCUMENT_ART_INVOICE(v.id).get();
        return { ...v_1.data()!, id: v_1.id };
      })
    );
    const totalContador: number = (
      await FIRESTORE.DOCUMENT_CONFIGURATION_CONTADOR().get()
    ).data()?.cobranzas!;
    const p: PaymentOrderArt = {
      art_bills: bills.map((v) => FIRESTORE.DOCUMENT_ART_INVOICE(v.id)),
      art: bills[0].art,
      bank_operation: values.op_bank,
      type: Number(values.typePayment),
      creation_date: FbDate.fromDate(new Date(`${values.op_date}T12:00`)),
      amount: bills.reduce((pv, cv) => pv + cv.ImpTotal, 0),
      receipt: values.receipt,
      //retenciones: retenciones,
      subtotal: subtotal(),
      total_ret_IIBB: totalRetenciones(),
      ret_ganancias: retGanancias ? Number(retGanancias) : null,
      comprabante_RRGG: compRRGG ? compRRGG : null,
      fch_RRGG: dateRRGG
        ? FbDate.fromDate(new Date(`${dateRRGG}T12:00`))
        : null,
      total: Number(
        (subtotal() - totalRetenciones() - Number(retGanancias)).toFixed(2)
      ),
      orden_pago_int: totalContador + 1,
    };

    const doc = await FIRESTORE.COLLECTION_PAYMENTS_ART().add(p);
    if (retenciones) {
      await doc.update({
        retenciones: retenciones,
      });
    }

    if (totalContador) {
      await FIRESTORE.DOCUMENT_CONFIGURATION_CONTADOR().update({
        cobranzas: totalContador + 1,
      });
    }

    await Promise.all(
      bills.map(async (v) => {
        await FIRESTORE.DOCUMENT_ART_INVOICE(v.id).update({
          payment_order: doc,
        });
        return true;
      })
    );
    setSelected([]);
    setCompRRGG("");
    setDateRRGG("");
    setCompIIBB("");
    setDateIIBB("");
    setValor("");
    setRetGanancias("");
    reloadQuery();
    setNewDocument(doc.id);
  };

  const addAll = () => {
    const z = [...selected];
    items.map((item) => {
      if (!selected.some((select) => select.id == item.id)) {
        z.push(item);
      }
    });
    setSelected(z);
  };

  const removeAll = () => {
    setSelected([]);
  };

  const handleChange = (e: any) => {
    switch (e.target.name) {
      case "IIBB":
        setValor(e.target.value);
        console.log(valor, "<-IIBB");
        break;
      case "ganancias":
        setRetGanancias(e.target.value);
        console.log(retGanancias, "<-retGancias");
        break;
      case "comprobanteRRGG":
        setCompRRGG(e.target.value);
        console.log(compRRGG, "<-compRRGG");
        break;
      case "comprobanteIIBB":
        setCompIIBB(e.target.value);
        console.log(compIIBB, "<-compIIBB");
        break;
      case "dateIIBB":
        setDateIIBB(e.target.value);
        console.log(dateIIBB, "<-dateIIBB");
        break;
      case "dateRRGG":
        setDateRRGG(e.target.value);
        console.log(dateRRGG, "<-dateRRGG");
        break;
    }
  };

  const addRetenciones = ({
    codigo,
    provincia,
    valor,
    comprobante,
    fecha,
  }: {
    codigo: string;
    provincia: string;
    valor: number;
    comprobante: string;
    fecha: string;
  }) => {
    const r = [...retenciones];
    r.push({
      codigo: codigo,
      provincia: provincia,
      valor: Number(valor),
      comprobante: comprobante,
      fecha: fecha,
    });
    setRetenciones(r);
    setProvincia("");
    setValor("");
    setCompIIBB("");
  };

  const subtotal = (): number => {
    let importeSubTotal: number = 0;
    for (let i = 0; i < selected.length; i++) {
      const importeFactura = selected[i].ImpTotal;
      importeSubTotal += importeFactura;
    }
    return parseFloat(importeSubTotal.toFixed(2));
  };

  const totalRetenciones = () => {
    let sumatoriaRetenciones = 0;
    for (let i = 0; i < retenciones.length; i++) {
      const retencion = retenciones[i];
      sumatoriaRetenciones += retencion.valor;
    }
    return sumatoriaRetenciones;
  };

  // const total = (): number => {
  //   return subtotal() - totalRetenciones();
  // };
  return (
    <ContainerPage>
      <Row>
        <Col>
          <PageTitle>Agregar Pago de Art</PageTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <ArtSelector
            setArtSelected={setArtSelected}
            artSelected={artSelected}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Desde</Form.Label>
            <DatePickerInput value={startDate} onChange={setStartDate} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Hasta (Inclusive)</Form.Label>
            <DatePickerInput value={endDate} onChange={setEndDate} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>
              Buscador <QuestionTooltip text="Número de factura, Siniestro/s" />
            </Form.Label>
            <Form.Control
              value={keyword}
              onChange={(v) => setKeyword(v.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>

      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        <AdminTable responsive>
          <thead>
            <tr>
              <th>Cliente</th>
              <th>Número de Factura</th>
              <th>Fecha</th>
              <th>Importe Total</th>
              <th>Ver</th>
              <th>Agregar</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id}>
                <td>
                  <GetProperty
                    doc={FIRESTORE.DOCUMENT_USER(item.art)}
                    property="name"
                  />
                </td>
                <td>{item.voucher_number}</td>
                <td>
                  <Moment
                    date={item.creation_date.toDate()}
                    format="DD/MM/YYYY"
                  />
                </td>
                <td>${item.ImpTotal.toFixed(2)}</td>
                <td>
                  <Link to={ROUTES.ADMIN_SALES_SALES_ART_BILL_INFO(item.id)}>
                    <Button variant="light">Ver</Button>
                  </Link>
                </td>
                <td>
                  {!selected.some((select) => select.id == item.id) ? (
                    <Button
                      onClick={() => {
                        const z = [...selected];
                        console.log(selected);
                        z.push(item);
                        setSelected(z);
                      }}
                    >
                      Agregar
                    </Button>
                  ) : (
                    <Button
                      variant="danger"
                      onClick={() => {
                        const z = [...selected];
                        z.splice(
                          z.findIndex((element) => element.id == item.id),
                          1
                        );
                        setSelected(z);
                      }}
                    >
                      Quitar
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </AdminTable>
      )}
      <Pagination />

      <Row>
        <Col>
          <Button className="pull-right" onClick={addAll}>
            Agregar Todas
          </Button>
          <Button className="pull-right" variant="danger" onClick={removeAll}>
            Remover Todas
          </Button>
        </Col>
      </Row>

      <Container style={{ maxWidth: "100%" }}>
        <Row lg={5} xl={5} md={5}>
          <Col>{selected != undefined ? <H3>{"Subtotal"}</H3> : ``}</Col>
          <Col>
            {selected != undefined ? (
              <H3>{`$${subtotal().toFixed(2)}`}</H3>
            ) : (
              ``
            )}
          </Col>
        </Row>
        <Row lg={5} xl={5} md={5}>
          <Col>{selected != undefined ? <H3>{`Ret ganancias`}</H3> : ``}</Col>
          <Col>
            {selected != undefined ? (
              <h5>
                $
                <input
                  name="ganancias"
                  value={retGanancias}
                  onChange={handleChange}
                  placeholder="Retenciones"
                ></input>
              </h5>
            ) : (
              ``
            )}
          </Col>
          <Col>
            <input
              name="comprobanteRRGG"
              type="text"
              placeholder="Nro comprobante"
              onChange={handleChange}
            ></input>
          </Col>
          <Col>
            <input name="dateRRGG" type="date" onChange={handleChange}></input>
          </Col>
        </Row>
        <Row lg={5} xl={5} md={5}>
          <Col> {selected != undefined ? <H3>{`Total Ret IIBB`}</H3> : ``}</Col>
          <Col>
            {selected != undefined ? <H3>{`$${totalRetenciones()}`}</H3> : ``}
          </Col>
        </Row>
        <Row lg={5} xl={5} md={5}>
          <Col>{selected != undefined ? <H3>Total</H3> : ``}</Col>
          <Col>
            {selected != undefined ? (
              <H3>
                $
                {(
                  subtotal() -
                  totalRetenciones() -
                  Number(retGanancias)
                ).toFixed(2)}
              </H3>
            ) : (
              ``
            )}
          </Col>
        </Row>
        <Row lg={5} xl={5} md={5}>
          <Col>
            <DropdownMenu
              state={provincia}
              setState={setProvincia}
            ></DropdownMenu>
          </Col>
          <Col>
            $
            <input
              name="IIBB"
              value={valor}
              onChange={handleChange}
              placeholder="Monto Ret IIBB"
            ></input>
          </Col>
          <Col>
            <input
              name="comprobanteIIBB"
              type="text"
              placeholder="Nro comprobante"
              onChange={handleChange}
            ></input>
          </Col>
          <Col>
            <input name="dateIIBB" type="date" onChange={handleChange}></input>
          </Col>
          <Col>
            <Button
              disabled={provincia ? false : true}
              onClick={() =>
                addRetenciones({
                  codigo: provincia.split("-")[0],
                  provincia: provincia.split("-")[1],
                  valor: Number(valor),
                  comprobante: compIIBB,
                  fecha: dateIIBB,
                })
              }
            >
              Agregar
            </Button>
          </Col>
        </Row>
      </Container>

      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={[...retenciones]}
        renderItem={(item) => (
          <List.Item
            actions={[
              <a
                key="list-loadmore-edit"
                onClick={() => {
                  const z = [...retenciones];
                  z.splice(
                    z.findIndex((element) => element.id == item.id),
                    1
                  );
                  setRetenciones(z);
                }}
              >
                ELIMINAR RETENCION
              </a>,
            ]}
          >
            <div>
              <h5>{`${item.provincia}  $${item.valor}`}</h5>
            </div>
          </List.Item>
        )}
      />
      <BPagination style={{ justifyContent: "center" }}>
        <BPagination.Item active>1</BPagination.Item>
      </BPagination>
      {newDocument && (
        <Alert variant="success" style={{ width: "100%" }}>
          Nuevo pago creado con id{" "}
          <Link to={ROUTES.ADMIN_SALES_ART_PAYMENT(newDocument)}>
            {newDocument}
          </Link>
        </Alert>
      )}
      <FormChargeRedux onSubmit={onSubmit} />
    </ContainerPage>
  );
};

const FormCharge = ({
  handleSubmit,
  submitting,
  error,
  submitSucceeded,
}: any) => {
  if (submitting) return <Spinner />;
  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error.message}</Alert>}</Row>
      <Row>
        <Col xl={3}>
          <Field
            name="op_date"
            type="date"
            labelinput="Fecha"
            validate={required}
          />
        </Col>
        <Col xl={3}>
          <Field
            name="typePayment"
            type="select"
            labelinput="Tipo de pago"
            validate={required}
          >
            {getEnumKeys(TypePayment).map((v) => (
              <option value={TypePayment[v as any]} key={v}>
                {v}
              </option>
            ))}
          </Field>
        </Col>
        <Col xl={3}>
          <Field
            name="op_bank"
            type="text"
            labelinput="Operación Bancaria / Número de cheque"
            validate={required}
          />
        </Col>
        <Col xl={3}>
          <Field
            name="receipt"
            type="text"
            labelinput="Número orden de pago art"
            validate={required}
          />
        </Col>
        <Col xl={12}>
          <SubmitButton
            block
            submitting={submitting}
            variant="warning"
            style={{ marginTop: 30 }}
          >
            Ingresar Pago
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};

const FormChargeRedux = reduxForm({
  form: "bill-transfer",
})(FormCharge);

export default AddChargePayment;
