import { Container, Link, Padding, RibbonContainer, RibbonTopLeft, Spinner } from 'components/styles';
import { minutesToString, monthToStringShort } from 'components/User/Contracts/reducer';
import ROUTES from 'constants/routes';
import { uidSelector } from 'controllers/auth';
import { useDbCollection } from 'controllers/hooks';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import styled from 'styled-components';
import { Contract } from 'types/user-data';

const UserContractsPage = (props:any) => {
    const uid = useSelector(uidSelector)
    const items = useDbCollection<Contract>(`user/${uid}/contract`)
    
    if(!isLoaded(items))
        return <Spinner />
    if(isEmpty(items))
        return <></>
    return(
    <Padding pad="5px">
    <Container>
        <Row>
        {items.map( (c, indice) => (
            <Col md={9} xl={4} key={indice}>
                <LinkWithoutDesign to={ROUTES.USER_CONTRACTS_VIEW(c.id)} key={indice}>
                    <RibbonContainerContracts>
                        {c.validated ?
                        <RibbonTopLeft color="#00426A"><span>APROBADA</span></RibbonTopLeft> :
                        <RibbonTopLeft color="grey"><span>NO APROBADA</span></RibbonTopLeft>}
                        <ContractDate>
                            <span>Carta generada el</span>
                            <div className="date">
                            {c.date.toDate().getDate()} de {monthToStringShort(c.date.toDate().getMonth())},<br/>
                            {c.date.toDate().getFullYear()}
                            </div> 
                            <span>{c.date.toDate().getHours()}:{minutesToString(c.date.toDate().getMinutes())} hs</span>
                        </ContractDate>
                    </RibbonContainerContracts>
                </LinkWithoutDesign>
            </Col>
        ))}
        </Row>
    </Container>
    </Padding>
    );
}

const LinkWithoutDesign = styled(Link)`
    color: #000;
    &:hover {
        color: inherit;
    }
`
const RibbonContainerContracts = styled(RibbonContainer)`
    padding: 20px;
    height: 16rem;
    align-items: center;
    display: flex;
    text-align: center;
`
const ContractDate = styled.div`
    margin: auto;
    span{
        font-size: 1rem;
    }
    .date{
        font-size: 2rem;
    }

`

export default UserContractsPage;