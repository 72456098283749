import { ReduxDecoratorNoStyle, ReduxValidationDecorator } from "components/bootstrap-redux";
import React from "react";
import { FormControl } from "react-bootstrap";
import { Field as ReduxField } from "redux-form";
import { AddressInput } from "./address";
import { BooleanInput } from "./boolean";
import { Checkbox } from "./checkbox";
import CustomSelect from "./custom-select";
import { DatePickerInput, DateTimePickerInput, TimePickerInput } from "./date-picker";
import { enumToOptions } from "./enums";
import { IProps, IPropsPickOptionsFromChildren, IPropsPickOptionsFromEnum } from "./field.d";
import FileInput from "./file";
import ImageInput from "./image";
import { NumberCounter } from "./number-counter";
import PolygonMap from "./polygon-map";
import { SwitchInput } from "./switch";
import { TextMaskInput } from "./text-mask";
import LatLng from "./lat-lng";
import LatLngCoords from "./lat-lng-coords";

const inputs = {
    address: ReduxValidationDecorator(AddressInput),
    boolean: ReduxValidationDecorator(BooleanInput),
    file: ReduxValidationDecorator(FileInput),
    select: ReduxValidationDecorator(CustomSelect),
    "select-text": ReduxValidationDecorator(CustomSelect),
    checkbox: ReduxDecoratorNoStyle(Checkbox),
    switch: ReduxValidationDecorator(SwitchInput),
    image: ReduxValidationDecorator(ImageInput),
    "date-picker": ReduxValidationDecorator(DatePickerInput),
    "datetime-picker": ReduxValidationDecorator(DateTimePickerInput),
    "time-picker": ReduxValidationDecorator(TimePickerInput),
    "text-mask": ReduxValidationDecorator(TextMaskInput),
    "number-counter": ReduxValidationDecorator(NumberCounter),
    "polygon-map": ReduxValidationDecorator(PolygonMap),
    "address-with-map": ReduxValidationDecorator(LatLng),
    "address-with-map-coords": ReduxValidationDecorator(LatLngCoords),
};

const defaultInput = ReduxValidationDecorator(FormControl);

const getInput = (type: IProps["type"]) => (Object.keys(inputs).includes(type) ? (inputs as any)[type] : defaultInput);

export const Field = (props: IProps) => {
    var props = Object.assign({}, props);

    if ((props as IPropsPickOptionsFromEnum).options) {
        var options = (props as IPropsPickOptionsFromEnum).options;
        props = props as IPropsPickOptionsFromChildren;
        props.children = enumToOptions(options);
    }
    if ((props as IPropsPickOptionsFromChildren).type === "select") {
        props = props as IPropsPickOptionsFromChildren;
        props.children = ([<option disabled key={-1} label="Seleccione una opción" />] as React.ReactNodeArray).concat(props.children);
    }
    const input = getInput(props.type);
    return <ReduxField component={input} {...props} type={props.type} />;
};
