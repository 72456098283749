import React from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { TransferRequest, Meeting } from "types/transfer";
import moment from "moment";
import { AsyncButton } from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";

const TransferRequestCard = ({
  data,
  id,
  reload,
}: {
  data: TransferRequest;
  id: string;
  reload: () => void;
}) => {
  const schedule = async () => {
    try {
      await CALLABLE_FUNCTIONS.USER_SCHEDULE_TRANSFER(id);
      alert("Traslado agendado");
      setTimeout(() => {
        reload();
      }, 1000);
    } catch (e) {
      setTimeout(() => {
        alert("Viaje no disponible");
        reload();
      }, 1000);
    }
  };
  return (
    <Card border="secondary" style={{ width: "auto", marginTop: "4%" }}>
      <Card.Header style={{ backgroundColor: "#d1d3d4" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              color: "#000",
              fontWeight: "bolder",
            }}
          >
            {dateToText(data.origin.time.toDate())}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexGrow: 1,
            }}
          >
            <span style={{ marginRight: "5%", color: "black" }}>{`${(
              data.info.distance_origin_destination / 1000
            ).toFixed(2)}km`}</span>
            <span style={{ color: "#1877F2", fontWeight: "bold" }}>
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    <p>Base ${data.amount}</p>
                    {totalAmount(data).tollsAmount > 0 ? (
                      <p>Peaje ${totalAmount(data).tollsAmount}</p>
                    ) : (
                      ""
                    )}
                  </Tooltip>
                }
              >
                <p>${totalAmount(data).total}</p>
              </OverlayTrigger>
            </span>
          </div>
        </div>
      </Card.Header>
      <Card.Body style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div style={{ fontWeight: "bold" }}>{`ORIGEN: ${moment(
            data.origin.time.toDate()
          ).format("HH:mm")}`}</div>

          <div style={{ fontWeight: "bold", color: "red" }}>
            {data.info.duration_origin_destination <= 30
              ? `MAXIMO ${moment(data.origin.time.toDate())
                  .add(30, "minutes")
                  .format("HH:mm")}`
              : data.info.duration_origin_destination > 30 &&
                data.info.duration_origin_destination <= 40
              ? `MAXIMO ${moment(data.origin.time.toDate())
                  .add(60, "minutes")
                  .subtract(data.info.duration_origin_destination, "minutes")
                  .format("HH:mm")}`
              : `MAXIMO ${moment(data.origin.time.toDate())
                  .add(20, "minutes")
                  .format("HH:mm")}`}
          </div>

          <div style={{ fontWeight: "bold", color: "#1877F2" }}>
            {data.info.duration_origin_destination <= 15
              ? `Destino ${moment(data.destination.time.toDate())
                  .subtract(15, "minutes")
                  .format("HH:mm")}`
              : `Destino ${moment(data.destination.time.toDate()).format(
                  "HH:mm"
                )}`}
          </div>
        </div>
        <div
          style={{ borderLeft: "4px solid rgb(6,57,112)", height: "auto" }}
        ></div>
        <div
          style={{
            marginLeft: "2%",
            fontWeight: "bold",
            flexShrink: 2,
          }}
        >
          <div>{getAddress(data.origin)}</div>
          <br />
          <br />
          <div>{getAddress(data.destination)}</div>
        </div>
      </Card.Body>
      <Card.Footer>
        <AsyncButton onClick={schedule} variant="success">
          AGENDAR TRASLADO
        </AsyncButton>
      </Card.Footer>
    </Card>
  );
};

const dayToString = (m: number) => {
  if (m == 1) return "Lunes";
  if (m == 2) return "Martes";
  if (m == 3) return "Miércoles";
  if (m == 4) return "Jueves";
  if (m == 5) return "Viernes";
  if (m == 6) return "Sábado";
  if (m == 0) return "Domingo";
  return "Error";
};

const monthToString = (m: number) => {
  if (m == 0) return "enero";
  if (m == 1) return "febrero";
  if (m == 2) return "marzo";
  if (m == 3) return "abril";
  if (m == 4) return "mayo";
  if (m == 5) return "junio";
  if (m == 6) return "julio";
  if (m == 7) return "agosto";
  if (m == 8) return "septiembre";
  if (m == 9) return "octubre";
  if (m == 10) return "noviembre";
  if (m == 11) return "diciembre";
  return "Error";
};

const totalAmount = (transfer: TransferRequest) => {
  let tollsAmount = 0;
  if (transfer.tolls !== undefined) {
    tollsAmount += transfer.tolls.data
      .map((t) => {
        if (t.status === 1)
          return t.amount_corrected !== undefined
            ? Number(t.amount_corrected)
            : Number(t.amount);
        return 0;
      })
      .reduce((a, b) => a + b, 0);
  }
  return { total: Math.floor(transfer.amount + tollsAmount), tollsAmount };
};

const dateToText = (d: Date) =>
  dayToString(d.getDay()) +
  " " +
  d.getDate() +
  " " +
  monthToString(d.getMonth());

const getAddress = (z: Meeting): string => {
  /* if (z.address.locality?.length == 3 && z.address.number) {
        return `${z.address.street}, ${z.address.number}, CABA, ${z.address.province}`;
      } else */
  const localityToShow =
    z.address.locality && z.address.locality.length <= 3
      ? z.address.district
      : z.address.locality;
  if (z.address.number) {
    return `${z.address.street}, ${z.address.number}, ${localityToShow}, ${z.address.province}`;
  } else if (!z.address.locality && z.address.number) {
    return `${z.address.street}, ${z.address.number}, CABA, ${z.address.province}`;
  } else if (!z.address.number) {
    return `${z.address.street}, S/N, ${localityToShow}, ${z.address.province}`;
  } else {
    return `${z.address.street}, S/N, CABA, ${z.address.province}`;
  }
};

export default TransferRequestCard;
