import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AsyncButton,
  Button,
  H2,
  Spinner,
  TextWithTooltip,
  SubmitButton,
} from "components/styles";
import { AdminDecision, CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { FIRESTORE } from "constants/firestore";
import { UserType, getActiveUsers } from "controllers/user";
import React, { useState, useEffect } from "react";
import { Col, Row, Modal, Alert } from "react-bootstrap";
import { useHistory } from "react-router";
import { useProfile } from "redux/app";
import { BillStatus } from "types/billing";
import {
  ModuleTransfer,
  ModuleTransferType,
  WaitingActionsAdminMotives,
} from "types/module-transfer";
import { FinishStatus, TransferStage } from "types/transfer";
import { InfoRow, Title, VoucherButton } from "../scheduled";
import { BillModal, CancelBillModal } from "./modals/bill";
import { CancelModal, CancelAndTakeAction } from "./modals/cancel";
import { EditModal } from "./modals/edit";
import { HistoryModal } from "./modals/history";
import { InfoModal } from "./modals/info";
import firebase from "firebase";
import { Field } from "components/input/field";
import { reduxForm, change } from "redux-form";
import _ from "lodash";
import { WithId } from "controllers/database";
import { User } from "types/user-data";

export const Actions = ({
  module,
  idModule,
}: {
  module: ModuleTransfer;
  idModule: string;
}) => {
  const isAdmin = useProfile().type === UserType.admin;
  const userAdmin = useProfile();
  const history = useHistory();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showBillModal, setShowBillModal] = useState(false);
  const [showCancelBillModal, setShowCancelBillModal] = useState(false);
  const forceReturn = () =>
    CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_FORCE_RETURN({
      idModule,
      userAdmin,
    });

  /*  const changeModuleType = async (type: number) => {
    const data = { idModule, newType: type };
    CALLABLE_FUNCTIONS.ADMIN_CHANGE_MODULE_TYPE(data);
  }; */

  const republishBreach = async () => {
    await CALLABLE_FUNCTIONS.ADMIN_MODULE_REPUBLISH_BREACH({ idModule });
  };

  return (
    <>
      <Row style={{ margin: "20px 0 5px 0" }}>
        <Col>
          <div className="pull-left">
            <H2>
              <FontAwesomeIcon
                icon={faChevronLeft}
                onClick={() => history.goBack()}
              />
            </H2>
          </div>
          {isAdmin &&
            module.status.stage === TransferStage.finished &&
            (module.status.finished_status === FinishStatus.Cancelado ||
              module.status.finished_status ===
                FinishStatus.Incumplimiento) && (
              <AsyncButton
                className="pull-right"
                variant="success"
                style={{ margin: "0 10px 5px" }}
                onClick={republishBreach}
              >
                Republicar Traslado
              </AsyncButton>
            )}

          {isAdmin &&
            module.status.stage === TransferStage.finished &&
            module.sale?.billed !== BillStatus.Si &&
            module.status.finished_status !== FinishStatus.Cancelado &&
            module.status.finished_status !== FinishStatus.Incumplimiento &&
            module.sale?.billed !== BillStatus["Error al facturar"] && (
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => setShowBillModal(true)}
              >
                Forzar Factura Venta
              </Button>
            )}
          {isAdmin &&
            module.status.stage === TransferStage.finished &&
            module.status.finished_status !== FinishStatus.Incumplimiento &&
            module.sale?.billed !== BillStatus["No facturar"] &&
            module.sale?.billed !== BillStatus["Si"] &&
            module.status.finished_status !== FinishStatus.Cancelado && (
              <Button
                variant="danger"
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => setShowCancelBillModal(true)}
              >
                No Facturar Venta
              </Button>
            )}
          {/* {isAdmin && module.status.stage === TransferStage.searching_driver && (
            <AsyncButton
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={async () => await sendFeleval(idModule)}
            >
              Enviar Feleval
            </AsyncButton>
          )} */}
          {/* {isAdmin &&
            module.status.stage === TransferStage.searching_driver_return && (
              <AsyncButton
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={async () => await sendReturnFeleval(idModule)}
              >
                Enviar Retorno Feleval
              </AsyncButton>
            )} */}
          {/* {isAdmin &&
            module.status.stage === TransferStage.recieved_by_feleval && (
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                onClick={() => cancelFeleval(idModule)}
              >
                Cancelar Feleval
              </Button>
            )} */}
          {/* {isAdmin && module.type === 2 ? (
            <AsyncButton
              variant="dark"
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={() => changeModuleType(1)}
            >
              Con espera
            </AsyncButton>
          ) : isAdmin && module.type === 1 ? (
            <AsyncButton
              variant="dark"
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={() => changeModuleType(2)}
            >
              Sin espera
            </AsyncButton>
          ) : (
            ""
          )} */}
          {/* {isAdmin && module.status.stage !== TransferStage.finished && (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              variant="secondary"
              onClick={() => setShowActionModal(true)}
            >
              Reasignar
            </Button>
          )} */}
          {isAdmin && module.status.stage !== TransferStage.finished && (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              variant="danger"
              onClick={() => setShowCancelModal(true)}
            >
              Cancelar
            </Button>
          )}
          {isAdmin && module.status.stage !== TransferStage.finished && (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={() => setShowEditModal(true)}
            >
              Editar
            </Button>
          )}
          <Button
            className="pull-right"
            style={{ margin: "0 10px 5px" }}
            variant="warning"
            onClick={() => setShowHistoryModal(true)}
          >
            Historial
          </Button>
          <Button
            className="pull-right"
            style={{ margin: "0 10px 5px" }}
            variant="light"
            onClick={() => setShowInfoModal(true)}
          >
            Info
          </Button>
          {(module.status.stage === TransferStage.waiting_return_request ||
            module.status.stage ===
              TransferStage.canceled_on_waiting_return_request) && (
            <AsyncButton
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              variant="warning"
              onClick={forceReturn}
            >
              Solicitar Retorno
            </AsyncButton>
          )}
          {isAdmin &&
            module.status.stage === TransferStage.searching_driver_return && (
              <AsyncButton
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                variant="warning"
                onClick={async () => {
                  const moduleTransfer =
                    await FIRESTORE.DOCUMENT_MODULE_TRANSFER(idModule).get();
                  await moduleTransfer
                    .data()
                    ?.transfers.searching_driver?.delete();
                  await moduleTransfer.ref.update({
                    "status.stage": TransferStage.waiting_return_request,
                    "transfers.searching_driver":
                      firebase.firestore.FieldValue.delete(),
                  });
                }}
              >
                Cancelar Solicitud Retorno
              </AsyncButton>
            )}
          {module!.sale ? (
            module!.sale!.voucher ? (
              <VoucherButton
                src={module!.sale!.voucher}
                key={module!.sale!.voucher}
                text={`Voucher`}
              />
            ) : null
          ) : null}

          {module.sale?.bill_info?.pdf && isAdmin && (
            <VoucherButton src={module.sale.bill_info.pdf} text="Factura" />
          )}

          {module.sale?.bill_info?.missing === true &&
          module.sale.bill_info.ref ? (
            <Button variant="secondary" disabled className="pull-right">
              FACTURADO SIN PDF
            </Button>
          ) : (
            ""
          )}
        </Col>
      </Row>

      <CancelModal
        module={module}
        show={showCancelModal}
        handleClose={() => setShowCancelModal(false)}
        idModule={idModule}
      />
      <CancelAndTakeAction
        module={module}
        show={showActionModal}
        handleClose={() => setShowActionModal(false)}
        idModule={idModule}
      />
      <HistoryModal
        module={module}
        show={showHistoryModal}
        handleClose={() => setShowHistoryModal(false)}
      />

      <InfoModal
        module={module}
        idModule={idModule}
        show={showInfoModal}
        handleClose={() => setShowInfoModal(false)}
      />
      <BillModal
        idModule={idModule}
        show={showBillModal}
        handleClose={() => setShowBillModal(false)}
      />
      <CancelBillModal
        idModule={idModule}
        show={showCancelBillModal}
        handleClose={() => setShowCancelBillModal(false)}
      />
      <EditModal
        idModule={idModule}
        show={showEditModal}
        hasBacktrack={
          module.type === ModuleTransferType.TRIP_AND_RETURN ||
          module.type ===
            ModuleTransferType.TRIP_AND_RETURN_WITH_DIFFERENT_DRIVER
        }
        data={module}
        handleClose={() => setShowEditModal(false)}
      />
    </>
  );
};

export const NeedAdminAction = ({
  module,
  idModule,
}: {
  module: ModuleTransfer;
  idModule: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const [showSchedule, setShowSchedule] = useState(false);
  const decision =
    (decision: AdminDecision, motive: WaitingActionsAdminMotives) => () => {
      setLoading(true);
      console.log(idModule, decision, motive);

      CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_INSTRUCTION_TO_CONTINUE({
        idModule,
        decision,
        motive,
      })
        .catch(setError)
        .then(() => setLoading(false));
    };
  const motive =
    module.status.waiting_action_admin_motive ===
    WaitingActionsAdminMotives["Devuelto por climatológico/manifestación"]
      ? "Devuelto por climatológico/manifestación"
      : module.status.waiting_action_admin_motive ===
        WaitingActionsAdminMotives["Devuelto por dirección confusa"]
      ? "Devuelto por dirección confusa"
      : module.status.waiting_action_admin_motive ===
        WaitingActionsAdminMotives["Tramo Farmacia u Ortopedia"]
      ? "Tramo Farmacia u Ortopedia"
      : module.status.waiting_action_admin_motive ===
        WaitingActionsAdminMotives["Paciente informa que no viaja"]
      ? "Paciente informa que no viaja"
      : "Incumplimiento - No se pudo coordinar viaje";
  if (loading) return <Spinner />;
  if (error) return <>{error}</>;
  return (
    <>
      <Row>
        <Title style={{ backgroundColor: "#ff5e00" }}>
          SE NECESITA TOMAR ACCIÓN POR PARTE DEL ADMIN
        </Title>
      </Row>
      <Row>
        <Col>
          <InfoRow label="Motivo" info={`${motive}`} />
        </Col>
        <Col>
          <Row>
            {module.status.waiting_action_admin_motive !==
            WaitingActionsAdminMotives[
              "Incumplimiento - No se pudo coordinar viaje"
            ] ? (
              <Col xl={6} style={{ marginBottom: 10 }}>
                <Button
                  block
                  variant="danger"
                  onClick={decision(
                    AdminDecision.cancel,
                    module.status.waiting_action_admin_motive
                  )}
                >
                  CANCELAR Y AVISAR A LA ART
                </Button>
              </Col>
            ) : (
              ""
            )}
            {module.status.waiting_action_admin_motive ===
            WaitingActionsAdminMotives[
              "Incumplimiento - No se pudo coordinar viaje"
            ] ? (
              <Col xl={6} style={{ marginBottom: 10 }}>
                <Button
                  block
                  variant="danger"
                  onClick={decision(
                    AdminDecision.breach,
                    module.status.waiting_action_admin_motive
                  )}
                >
                  INCUMPLIMIENTO - NO SE COORDINA EL VIAJE
                </Button>
              </Col>
            ) : (
              ""
            )}
            {module.status.waiting_action_admin_motive !==
              WaitingActionsAdminMotives["Tramo Farmacia u Ortopedia"] &&
            module.status.waiting_action_admin_motive !==
              WaitingActionsAdminMotives[
                "Incumplimiento - No se pudo coordinar viaje"
              ] ? (
              <Col xl={6} style={{ marginBottom: 10 }}>
                <Button
                  block
                  variant="warning"
                  onClick={decision(
                    AdminDecision.republish,
                    module.status.waiting_action_admin_motive
                  )}
                >
                  REPUBLICAR VIAJE
                </Button>
              </Col>
            ) : (
              ""
            )}
            {module.status.waiting_action_admin_motive ===
            WaitingActionsAdminMotives[
              "Incumplimiento - No se pudo coordinar viaje"
            ] ? (
              <Col xl={6} style={{ marginBottom: 10 }}>
                <Button
                  block
                  variant="warning"
                  onClick={decision(
                    AdminDecision.republishBreach,
                    module.status.waiting_action_admin_motive
                  )}
                >
                  REPUBLICAR VIAJE
                </Button>
              </Col>
            ) : (
              ""
            )}
            {module.status.waiting_action_admin_motive ===
            WaitingActionsAdminMotives["Tramo Farmacia u Ortopedia"] ? (
              <Col xl={6} style={{ marginBottom: 10 }}>
                <Button
                  block
                  variant="warning"
                  onClick={decision(
                    AdminDecision.publish,
                    module.status.waiting_action_admin_motive
                  )}
                >
                  PUBLICAR VIAJE
                </Button>
              </Col>
            ) : (
              ""
            )}
            {/* {module.status.waiting_action_admin_motive ===
            WaitingActionsAdminMotives["Tramo Farmacia u Ortopedia"] ? (
              <Col xl={6} style={{ marginBottom: 10 }}>
                <Button
                  block
                  variant="success"
                  onClick={() => setShowSchedule(true)}
                >
                  PUBLICAR VIAJE Y AGENDAR
                </Button>
              </Col>
            ) : (
              ""
            )} */}
            {/* {module.status.waiting_action_admin_motive ===
            WaitingActionsAdminMotives["Devuelto por dirección confusa"] ? (
              <Col xl={6} style={{ marginBottom: 10 }}>
                <Button
                  block
                  variant="info"
                  onClick={decision(
                    AdminDecision.inform,
                    module.status.waiting_action_admin_motive
                  )}
                >
                  INFORMAR A ART
                </Button>
              </Col>
            ) : (
              ""
            )} */}
          </Row>
        </Col>
      </Row>
      <InfoDriver
        show={showSchedule}
        handleClose={() => setShowSchedule(false)}
        moduleId={idModule}
      />
    </>
  );
};
export const InfoDriver = ({
  show,
  handleClose,
  moduleId,
}: {
  show: boolean;
  handleClose: any;
  moduleId: string;
}) => {
  console.log(moduleId, "transfer1");

  const handleSubmit = async (values: any) => {
    const data = {
      origin: {},
      destination: {},
      passenger: {},
      art: {},
      provider: values.provider,
    };
    console.log(data, "data");
    await CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_EDIT(moduleId, data);
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Informar viaje coordinado con paciente con demora
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{/* <FormDriver onSubmit={handleSubmit} /> */}</Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
const FormDriver = ({
  handleSubmit,
  submitSucceeded,
  submitting,
  error,
}: {
  handleSubmit: any;
  submitSucceeded: boolean;
  submitting: boolean;
  error: any;
}) => {
  const [userOptions, setUserOptions] = useState<(WithId & User)[]>([]);
  useEffect(() => {
    getActiveUsers().then((v) =>
      setUserOptions(
        _.sortBy(v, [
          (z) => _.toLower(`${z.surname} ${z.name} ${z.phone.number}`),
        ])
      )
    );
  }, []);
  useEffect(() => {
    getActiveUsers().then((v) =>
      setUserOptions(_.sortBy(v, (z) => z.surname.toLocaleLowerCase()))
    );
  }, []);
  if (submitSucceeded) {
    return <Alert variant="success">Traslado agendado</Alert>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error}</Alert>}</Row>

      <Row>
        <Col xl={6}>
          <Field
            labelinput={<>Asignar Prestador</>}
            name="provider"
            type="select-text"
          >
            <option label="Seleccione una opción"></option>
            {userOptions.map((e) => (
              <option key={e.id} value={e.id}>
                {e.surname +
                  " " +
                  e.name +
                  " - " +
                  e.phone.area_code +
                  e.phone.number}
              </option>
            ))}
          </Field>
        </Col>
      </Row>
      <p></p>
      <Row>
        <Col>
          <SubmitButton block submitting={submitting} change={change}>
            Agendar e informar Email
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};
const FormSendEmail = reduxForm({
  form: "unassigned",
})(FormDriver);
