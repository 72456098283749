import { Autocomplete, GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import React, { useRef, useState } from "react";
import { getAddressFromLatLng } from "controllers/map";

const LatLngCoords = ({ value, onChange, disabled, isInvalid }: { value: any; onChange: any; disabled: boolean; isInvalid: boolean }) => {
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | undefined>();
    const [position, setPosition] = useState<{ lat: number; lng: number } | undefined>();
    const mapRef = useRef<google.maps.Map | null>(null);

    const onLoadPlace = (autocomplete: google.maps.places.Autocomplete) => {
        console.log(autocomplete);
        setAutocomplete(autocomplete);
    };
    const changePlace = () => {
        console.log(autocomplete);
        console.log(autocomplete?.getPlace());
        if (autocomplete && autocomplete.getPlace().geometry) {
            const newValue = {
                lat: autocomplete!.getPlace().geometry!.location.lat(),
                lng: autocomplete!.getPlace().geometry!.location.lng(),
            };
            setPosition(newValue);
            getAddressFromLatLng(newValue).then((v) => onChange({ address: v.formatted_address, coords: newValue }));

            if (mapRef.current !== null) {
                mapRef.current.setCenter(newValue);
                mapRef.current.setZoom(14);
            }
        }
    };
    const onDragEnd = (e: google.maps.MouseEvent) => {
        const newValue = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setPosition(newValue);
        getAddressFromLatLng(newValue).then((v) => onChange({ address: v.formatted_address, coords: newValue }));
    };

    let className = "";
    if (isInvalid) className = "is-invalid";

    return (
        <div className={className}>
            <GoogleMap
                onLoad={(m) => {
                    mapRef.current = m;
                    m.setCenter({ lat: -38.4160957, lng: -63.6166725 });
                }}
                onRightClick={onDragEnd}
                zoom={4}
                mapContainerStyle={{ height: "400px", width: "100%" }}
                options={{
                    streetViewControl: false,
                    draggable: !disabled,
                    zoomControl: !disabled,
                }}
            >
                {position && <Marker onDragEnd={onDragEnd} position={position} draggable={true} />}
                <Autocomplete onLoad={onLoadPlace} onPlaceChanged={changePlace}>
                    <input
                        type="text"
                        placeholder="Ingrese su Ciudad o Zona"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `280px`,
                            height: `32px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: "absolute",
                            left: "50%",
                            marginLeft: "-140px",
                            marginTop: "6px",
                        }}
                    />
                </Autocomplete>
            </GoogleMap>
            <b>Dirección:</b> {value.address}
        </div>
    );
};

export default LatLngCoords;
