import { Field } from "components/input/field";
import { SubmitButton, TextWithTooltip } from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import React from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { reduxForm, SubmissionError } from "redux-form";
import { TransferEventMotive } from "types/report";
import { removeUndefined } from "util/meta";

export const DelayModal = ({
    show,
    handleClose,
    idTransfer,
    idDriver,
}: {
    show: boolean;
    handleClose: any;
    idTransfer: string;
    idDriver: string;
}) => {
    const handleSubmitReport = async (values: any) => {
        console.log(values);
        CALLABLE_FUNCTIONS.SEND_EMAIL_CABIFY(idTransfer, idDriver, (values.destination_date as Date).toJSON());
    };

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Editar Módulo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>SOLO COMPLETAR LOS CAMPOS QUE SE QUIERAN MODIFICAR</h4>
                <FormEditTransferRedux onSubmit={handleSubmitReport} />
            </Modal.Body>
        </Modal>
    );
};

const FormEditTransfer = ({
    handleSubmit,
    submitSucceeded,
    submitting,
    error,
}: {
    handleSubmit: any;
    submitSucceeded: boolean;
    submitting: boolean;
    error: any;
}) => {
    if (submitSucceeded) {
        return <Alert variant="success">Traslado modificado</Alert>;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Row>{error && <Alert variant="danger">{error.message}</Alert>}</Row>

            <Row>
                <Col>
                    <Col xl={6}>
                        <Field labelinput={<>Fecha y hora turno</>} name="destination_date" type="datetime-picker" />
                    </Col>
                    <SubmitButton block submitting={submitting}>
                        Enviar email
                    </SubmitButton>
                </Col>
            </Row>
        </form>
    );
};

const FormEditTransferRedux = reduxForm({
    form: "edit-sendEmail",
})(FormEditTransfer);
