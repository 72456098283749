import { AdminTableAlternative, H3, SubmitButton } from "components/styles"
import React, { useState } from "react"
import { Button, Col, Row, Alert, Modal } from "react-bootstrap"
import { MaxPriceTransfer } from "types/config"
import { required } from "controllers/validations"
import { Field } from "components/input/field"
import { reduxForm } from "redux-form"
import { FIRESTORE } from "constants/firestore"
import firebase from "firebase/app"
import "firebase/firestore"
import { ProvincesArgentina } from "constants/places-argentina"

export const ConfigMaxPriceProvinces = ({art, data}:{art:string, data:MaxPriceTransfer}) => {
    const _data = data?.provinces
    const [show, setShow] = useState(false)

    const eraseValue = async (v:string) => {
        await FIRESTORE.DOCUMENT_CONFIGURATION_MAXPRICE(art).update({
            [`provinces.${v}`]: firebase.firestore.FieldValue.delete()
        })
    }
    return (
    <>
        <H3>Provincias</H3>
        <AdminTableAlternative hover>
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Máximo Precio</th>
                    <th>Editar</th>
                </tr>
            </thead>
            <tbody>
                {_data && Object.keys(_data).map( (item, index) => 
                <tr key={index}>
                    <td>{item}</td>
                    <td>${_data[item]}</td>
                    <td>
                        {/*<Button variant="warning">Editar</Button>*/}
                        <Button variant="danger" onClick={() => eraseValue(item)}>Eliminar</Button>
                    </td>
                </tr>
                )}
            </tbody>
        </AdminTableAlternative>
        <Button className='pull-right' onClick={() => setShow(true)}>Agregar</Button>
        <ModalAddMaxPrizeProvince show={show} art={art} close={() => setShow(false)}/>
    </>
)}

const ModalAddMaxPrizeProvince = ({art, show, close}:{art:string, show:boolean, close:()=>void}) => {
    const newValue = async (v:any) => {
        await FIRESTORE.DOCUMENT_CONFIGURATION_MAXPRICE(art).update({
            [`provinces.${v.name}`]: Number(v.maxPrice),  
        })
    }
    return (
        <Modal show={show} onHide={close} size="lg">
            <Modal.Header closeButton>
                Añadir nueva provincia
            </Modal.Header>
            <Modal.Body >
                <FormWithRedux onSubmit={newValue}/>
            </Modal.Body>
        </Modal>
)}

const Form = ({submitting,handleSubmit,submitSucceeded,disabled=false}:any) => {
    if(submitSucceeded)
        return <Alert variant="success">Zona agregada/modificada</Alert>
    
    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col xl={6}>
                    <Field 
                        disabled={disabled} 
                        labelinput={<>Provincia</>}
                        name="name" 
                        type="select-text"
                        validate={required}>
                            <option disabled label='Seleccione una opción'></option>
                            {ProvincesArgentina.map( t => <option key={t} value={t} label={t}></option>)}
                    </Field>
                </Col>
                <Col xl={6}>
                    <Field 
                        disabled={disabled} 
                        labelinput={<>Precio Máximo</>}
                        name="maxPrice" 
                        type="number"
                        validate={required}/>
                </Col>
            </Row>
            <SubmitButton submitting={submitting} disabled={disabled}>Guardar</SubmitButton>
        </form>
)}
const FormWithRedux = reduxForm<{},any>({form: 'config-form',})(Form)