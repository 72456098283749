import "animate.css/animate.min.css";
import React from "react";
import Application from "./application";
import Contact from "./contact";
import Footer from "./footer";
import Header from "./header";
import "./loader.css";
import PhotoSlider from "./photo-slider";
import Services from "./services";
import WhatWeDo from "./what-we-do";
import Requirements from "./requirements";

function Landing(props: any) {
  return (
    <div className={props.className}>
      <MainContainer />
      <a id="top"></a>
    </div>
  );
}

const MainContainer = () => {
  return (
    <div className="main-container">
      <Header></Header>
      <PhotoSlider></PhotoSlider>
      <WhatWeDo></WhatWeDo>
      <Requirements></Requirements>
      <Services></Services>
      <Application></Application>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
};

export default Landing;
