import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import ROUTES from "constants/routes";
import {
  Container,
  FormControl,
  Button,
  Padding,
  Nav,
  Center,
  Link,
} from "components/styles";

const SendInstructionsPage = () => (
  <Container>
    <Row className="justify-content-center">
      <Col xl={5}>
        <Padding pad="20px">
          <Center>
            <img
              alt="logo"
              style={{ height: "150px" }}
              src="../assets/logo-070b76280dc0f019580a20151dd1e9515757bbfa7650b5d4955ec4eea815fab1.png"
            />
          </Center>
        </Padding>

        <Form>
          <Form.Group controlId="formBasicEmail">
            <FormControl type="email" placeholder="Email" required />
          </Form.Group>

          <Button variant="primary" type="submit" block>
            Reenviar Instrucciones
          </Button>
          <hr />
          <Row>
            <Col>
              <Link to={ROUTES.SIGN_IN}>
                <Button variant="secondary" block>
                  Ingresar
                </Button>
              </Link>
            </Col>
            <Col>
              <Link to={ROUTES.SIGN_UP}>
                <Button variant="info" block>
                  Registrate
                </Button>
              </Link>
            </Col>
          </Row>
        </Form>
        <hr />
        <Nav>
          <Nav.Item>
            <Link to={ROUTES.RESET_PASSWORD}>¿Olvidaste tu contraseña?</Link>
          </Nav.Item>
        </Nav>
      </Col>
    </Row>
  </Container>
);

export default SendInstructionsPage;
