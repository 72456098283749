import { Field } from "components/input/field";
import { PhoneField } from "components/input/sign-up/fields";
import {
  H3,
  Padding,
  PageTitle,
  Required,
  SubmitButton,
  Button,
  Link,
} from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { codigoBuenosAires } from "constants/codigos-telefono";
import { WithId } from "controllers/database";
import { required } from "controllers/validations";
import React, { useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { reduxForm, SubmissionError } from "redux-form";
import { TransferType } from "types/transfer";
import { removeUndefined } from "util/meta";
import { getActiveUsers, getArt } from "../../../controllers/user/index";
import { User } from "types/user-data";
import { DefinitionsModuleTransferType } from "types/module-transfer";
import ROUTES from "constants/routes";
import _ from "lodash";
import moment from "moment";

const AdminNewTransfer = () => {
  const handleSubmit = async (values: any) => {
    if (
      (values.art_id_accident === undefined || values.art_id_accident === "") &&
      (values.art_id_npa === undefined || values.art_id_npa === "")
    ) {
      throw new SubmissionError({
        art_id_accident: "El traslado debe contener siniestro o NPA",
        art_id_npa: "El traslado debe contener siniestro o NPA",
      });
    }

    const auxTime: Date = values.destination_time;
    const auxDate: Date = values.trip_date;
    const destination_date = new Date(
      auxDate.getFullYear(),
      auxDate.getMonth(),
      auxDate.getDate(),
      auxTime.getHours(),
      auxTime.getMinutes()
    );

    const auxTimeOrigin: Date | undefined = values.origin_time;
    const origin_date = auxTimeOrigin
      ? new Date(
          auxDate.getFullYear(),
          auxDate.getMonth(),
          auxDate.getDate(),
          auxTimeOrigin.getHours(),
          auxTimeOrigin.getMinutes()
        )
      : undefined;

    const auxTimeBacktrack: Date | undefined = values.backtrack_time;
    const backtrack_date = auxTimeBacktrack
      ? new Date(
          auxDate.getFullYear(),
          auxDate.getMonth(),
          auxDate.getDate(),
          auxTimeBacktrack.getHours(),
          auxTimeBacktrack.getMinutes()
        )
      : undefined;

    const data = {
      origin_date: origin_date ? origin_date.toISOString() : undefined,
      destination_date: destination_date.toISOString(),
      backtrack_date: backtrack_date ? backtrack_date.toISOString() : undefined,

      origin_address: values.origin_address,
      destination_address: values.destination_address,
      backtrack_address:
        Number(values.type) === TransferType.SIMPLE_TRIP
          ? undefined
          : values.origin_address,

      passenger: {
        dni: values.dni,
        name: values.name,
        tel: values.phone.area_code + values.phone.number,
      },
      type: Number(values.type),
      art_id_accident: values.art_id_accident,
      art_id_expert: values.art_id_expert,
      art_id_authorization: values.art_id_authorization,
      art_id_npa: values.art_id_npa,
      provider: values.provider,
      acom_aut: values.acom_aut,
      wheelchair: values.wheelchair,
      art: values.art,
      obs: values.obs,
      espera_art: values.esperaART === "Si" ? true : false,
      transfer_id: values.art_id_expert,
    };
    removeUndefined(data);

    console.log(data, "<---data");
    return CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_CREATE(data).catch((e) => {
      throw new SubmissionError({ _error: e });
    });
  };

  return (
    <Padding pad="5px">
      <Row>
        <Col>
          <PageTitle>Nuevo Traslado</PageTitle>
        </Col>
        <Col>
          <Link to={ROUTES.ADMIN_NEW_TRANSFER_EXCEL}>
            <Button className="pull-right">Cargar desde un excel</Button>
          </Link>
        </Col>
      </Row>

      <FormAdminNewTransfer
        onSubmit={handleSubmit as any}
        initialValues={{
          trip_date: new Date(),
          destination_time: new Date(),
          origin_time: undefined,
          return_time: undefined,
          phone: {
            area_code: codigoBuenosAires,
          },
        }}
      />
    </Padding>
  );
};

//const futureHour = (dateSelected:string) => (value, previousValue, allValues) => new Date() > allValues[dateSelected] ? value : previousValue
//const futureDate = (:string)

const FormAdminNewTransferView = ({
  handleSubmit,
  submitSucceeded,
  submitting,
  error,
}: {
  handleSubmit: any;
  submitSucceeded: boolean;
  submitting: boolean;
  error: any;
}) => {
  const [artOptions, setArtOptions] = useState<(WithId & User)[]>([]);
  const [userOptions, setUserOptions] = useState<(WithId & User)[]>([]);
  const [actualDay] = useState<any>(new Date());
  const [daySelected, setDaySelected] = useState<any>();
  const [flag, setFlag] = useState<any>(false);
  useEffect(() => {
    getArt().then(setArtOptions);
    getActiveUsers().then((v) =>
      setUserOptions(
        _.sortBy(v, [
          (z) => _.toLower(`${z.surname} ${z.name} ${z.phone.number}`),
        ])
      )
    );
  }, []);

  useEffect(() => {
    getArt().then(setArtOptions);
    getActiveUsers().then((v) =>
      setUserOptions(_.sortBy(v, (z) => z.surname.toLocaleLowerCase()))
    );
  }, []);

  if (submitSucceeded) {
    return <Alert variant="success">Traslado agregado</Alert>;
  }

  const requiredHour = (value: any) => {
    const horaSeleccionada: Date = value,
      horaIngreso = new Date(),
      horaLimite = new Date(),
      diaSelected: Date = daySelected;
    if (horaIngreso.getMinutes() < 90) {
      horaLimite.setHours(horaIngreso.getHours() - 1);
      horaLimite.setMinutes(horaIngreso.getMinutes() - 90 + 60);
    } else {
      horaLimite.setMinutes(horaIngreso.getMinutes() - 90);
    }
    if (value === null || value === "" || value === undefined) {
      return "No puede estar en blanco.";
    }
    if (
      horaSeleccionada.getTime() < horaLimite.getTime() &&
      diaSelected.getFullYear() <= horaIngreso.getFullYear() &&
      diaSelected.getMonth() <= horaIngreso.getMonth() &&
      diaSelected.getDate() <= horaIngreso.getDate() &&
      !flag
    ) {
      return `No puede cargar un traslado más viejo que 90 minutos en el pasado.`;
    }
  };

  const requireDay = (value: any) => {
    const diaSeleccionado: Date = value;
    setDaySelected(value);
    if (value === "" || value === undefined) return "No puede estar en blanco";

    if (actualDay.getTime() > diaSeleccionado.getTime() + 10000) {
      setFlag(true);
      return "No puede elegir dias pasados";
    }
    setFlag(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error.message}</Alert>}</Row>

      <Row>
        <Col xl={2}>
          <Field
            labelinput={<>Cod. Siniestro</>}
            name="art_id_accident"
            type="text"
          />
        </Col>
        <Col xl={2}>
          <Field
            labelinput={<>Cod. Autorización</>}
            name="art_id_authorization"
            type="text"
          />
        </Col>
        <Col xl={2}>
          <Field labelinput={<>NPA</>} name="art_id_npa" type="text" />
        </Col>
        <Col xl={2}>
          <Field
            labelinput={<>ID Experta</>}
            name="art_id_expert"
            type="text"
          />
        </Col>
        <Col xl={3}>
          <Field
            labelinput={
              <>
                <Required />
                ART
              </>
            }
            name="art"
            type="select-text"
            validate={required}
          >
            <option disabled label="Seleccione una opción"></option>
            {artOptions.map((e) => (
              <option key={e.id} value={e.id}>
                {e.name}
              </option>
            ))}
          </Field>
        </Col>
        <Col xl={3}>
          <Field
            labelinput={
              <>
                <Required />
                Modo
              </>
            }
            name="type"
            type="select"
            validate={required}
            options={DefinitionsModuleTransferType}
          />
        </Col>
        <Col xl={3}>
          <Field
            labelinput={
              <>
                <Required />
                Fecha Viaje
              </>
            }
            name="trip_date"
            type="date-picker"
            validate={requireDay}
          />
        </Col>
        <Col xl={3}>
          <Field
            labelinput={
              <>
                <Required />
                Hora Turno
              </>
            }
            name="destination_time"
            type="time-picker"
            validate={requiredHour}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={3}>
          <Field
            labelinput={<>Hora Origen (opcional)</>}
            name="origin_time"
            type="time-picker"
          />
        </Col>
        <Col xl={3}>
          <Field
            labelinput={<>Hora Retorno (opcional)</>}
            name="return_time"
            type="time-picker"
          />
        </Col>
      </Row>
      <Row>
        <Col xl={3}>
          <Field
            name="wheelchair"
            type="checkbox"
            label="Se requiere espacio para silla de ruedas."
          />
        </Col>
        <Col xl={3}>
          <Field
            name="acom_aut"
            type="checkbox"
            label="Se autoriza acompañante."
          />
        </Col>
      </Row>

      <H3>PACIENTE</H3>

      <Row>
        <Col xl={6}>
          <Field
            labelinput={
              <>
                <Required />
                Nombre Completo
              </>
            }
            name="name"
            type="text"
            validate={[required]}
          />
        </Col>
        <Col xl={3}>
          <PhoneField
            labelinput={
              <>
                <Required />
                Teléfono
              </>
            }
            placeholder="Número"
          />
        </Col>
        <Col xl={3}>
          <Field
            labelinput={
              <>
                <Required />
                DNI
              </>
            }
            name="dni"
            type="number"
            validate={[required]}
          />
        </Col>
      </Row>

      <Row>
        <Col xl={6}>
          <Row>
            <H3>DIRECCION ORIGEN</H3>
          </Row>
          <Row>
            <Col xl={12}>
              <Field
                labelinput={
                  <>
                    <Required />
                    Dirección completa (tiene que seleccionar una opción)
                  </>
                }
                name="origin_address"
                type="address"
                validate={[required]}
              />
            </Col>
          </Row>
        </Col>

        <Col xl={6}>
          <Row>
            <H3>DIRECCION DESTINO</H3>
          </Row>
          <Row>
            <Col xl={12}>
              <Field
                labelinput={
                  <>
                    <Required />
                    Dirección completa (tiene que seleccionar una opción)
                  </>
                }
                name="destination_address"
                type="address"
                validate={[required]}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xl={6}>
          <Row>
            <H3>ASIGNACION VIAJE</H3>

            <Col xl={12}>
              <Field
                labelinput={<>Prestador</>}
                name="provider"
                type="select-text"
              >
                <option label="Seleccione una opción"></option>
                {userOptions.map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.surname +
                      " " +
                      e.name +
                      " - " +
                      e.phone.area_code +
                      e.phone.number}
                  </option>
                ))}
              </Field>
            </Col>
          </Row>
        </Col>
        {/* { //////////////////"espera art" solo viual hasta el momento//////////////} */}
        <Col xl={6}>
          <Row>
            <H3>ESPERA ART</H3>
            <Col xl={12}>
              <Field
                labelinput={<>Seleccione una opción</>}
                name="esperaART"
                type="select-text"
              >
                <option label="No" value="No"></option>
                <option label="Si" value="Si"></option>
              </Field>
            </Col>
          </Row>
        </Col>
        {/* ////////////////////////////////////////////////////////////////////////// */}
      </Row>

      <Row>
        <Col xl={12}>
          <Field
            labelinput={<>Observaciones</>}
            name="obs"
            as="textarea"
            rows="4"
            type="textarea"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <SubmitButton block submitting={submitting}>
            Agregar
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};

const FormAdminNewTransfer = reduxForm({
  form: "new-transfer",
})(FormAdminNewTransferView);

export default AdminNewTransfer;
