import {
  Button,
  Container,
  H1,
  Padding,
  RibbonContainer,
  RibbonTopRight,
  Spinner,
} from "components/styles";
import { uidSelector } from "controllers/auth";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { compose } from "redux";
import { connect, SessionInjectedProps } from "redux/session";
import styled from "styled-components";
import { Contract } from "types/user-data";
import { getContract, monthToString, capitalize } from "./reducer";

const UserContractsViewPage = connect((props: any & SessionInjectedProps) => {
  const history = useHistory();
  const uid = useSelector(uidSelector);
  const { idContract } = props.match.params;
  const [contract, setContract] = useState<Contract | undefined>(undefined);
  const [showModal, setshowModal] = useState(false);
  const [invalidated, setInvalidated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (idContract !== null)
      getContract(uid, idContract)
        .then(setContract)
        .then(() => setLoading(false));
  }, []);

  const invalidateContract = () => {
    setInvalidated(true);
    //invalidateContract(uid, idContract)
  };

  if (loading) return <Spinner />;
  if (contract === undefined) return <>Error</>;
  return (
    <Padding pad="5px">
      <Container>
        <Header>
          <div style={{ float: "left" }}>
            <H1>Carta Oferta</H1>
          </div>
          <div style={{ float: "right" }}>
            {/* <Button variant="info" style={{margin:"10px"}} onClick={()=>setshowModal(true)}>
                Dar de baja esta carta oferta
            </Button>*/}
            <Button
              onClick={() => history.goBack()}
              variant="secondary"
              style={{ margin: "10px" }}
            >
              Volver
            </Button>
          </div>
        </Header>
        <hr style={{ marginTop: "0px" }} />
        <Container>
          <DateDiv>
            <span>
              {contract.date.toDate().getDate() +
                " de " +
                capitalize(monthToString(contract.date.toDate().getMonth())) +
                " de " +
                contract.date.toDate().getFullYear()}
            </span>
          </DateDiv>
          <RibbonContainer>
            {invalidated ? (
              <RibbonTopRight color="#6c757d">
                <span>EXPIRADA</span>
              </RibbonTopRight>
            ) : contract.validated ? (
              <RibbonTopRight color="#00426A">
                <span>APROBADO</span>
              </RibbonTopRight>
            ) : (
              <RibbonTopRight color="#6c757d">
                <span>EN REVISION</span>
              </RibbonTopRight>
            )}
            <ContainerContract>
              <Pre>{contract.text}</Pre>
            </ContainerContract>
          </RibbonContainer>
        </Container>
        <Modal show={showModal}>
          <Modal.Header closeButton>
            <Modal.Title>Atención</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro de dar de baja la Carta Oferta?</Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={() => setshowModal(false)}>
              No, cancelar
            </Button>
            <Button
              variant="info"
              onClick={compose(() => setshowModal(false), invalidateContract)}
            >
              Si, eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </Padding>
  );
});

const ContainerContract = styled(Container)`
  padding: 10px;
`;

const Pre = styled.pre`
  white-space: pre-wrap;
  font-family: inherit;
  font-size: 1rem;
`;

const Header = styled(Container)`
  display: inline-block;
  margin-bottom: 0px;
`;

const DateDiv = styled.div`
  padding: 10px 2px;
  margin-bottom: 10px;
`;

export default UserContractsViewPage;
