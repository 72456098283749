import { Button, Spinner, ButtonWithTooltip } from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { useAsyncFunction } from "controllers/hooks";
import React, { useState } from "react";
import { Modal, Col, Alert } from "react-bootstrap";
import { FIRESTORE } from "constants/firestore";
import { FinishStatus } from "types/transfer";

export const BillModal = ({
  show,
  idModule,
  handleClose,
}: {
  idModule: string;
  show: boolean;
  handleClose: any;
}) => {
  const [func, loading, error] = useAsyncFunction(
    () =>
      CALLABLE_FUNCTIONS.ADMIN_FORCE_MULTIPLE_ART_BILLS({
        idModules: [idModule],
      }),
    undefined,
    handleClose
  );

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Factura Venta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner />
        ) : error ? (
          error
        ) : (
          <h6>
            ¿Está seguro que desea facturar el módulo traslado? La facturación
            de un módulo de traslado sucede cada 24hs. Este método fuerza a
            facturarse ahora. El traslado solo se facturará si ya se facturaros
            exitosamente todos los traslados contenidos en el módulo.
          </h6>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={func}>
          Facturar
        </Button>
        <Button
          variant="secondary"
          style={{ textAlign: "center", width: "4rem" }}
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const CancelBillModal = ({
  show,
  idModule,
  handleClose,
}: {
  idModule: string;
  show: boolean;
  handleClose: any;
}) => {
  const [func, loading, error] = useAsyncFunction(
    () =>
      FIRESTORE.DOCUMENT_MODULE_TRANSFER(idModule).update({
        "sale.billed": 2,
      }),
    undefined,
    handleClose
  );
  const [func2, loading2, error2] = useAsyncFunction(
    () =>
      FIRESTORE.DOCUMENT_MODULE_TRANSFER(idModule).update({
        "sale.billed": 2,
        "status.finished_status": FinishStatus["Incumplimiento"],
      }),
    undefined,
    handleClose
  );

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Motivo de la NO facturación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading || loading2 ? (
          <Spinner />
        ) : error ? (
          error
        ) : error2 ? (
          error2
        ) : (
          <h6>
            ¿Está seguro que desea cancelar la facturación del módulo traslado?
            Esta acción pondrá el módulo traslado como "VENTA NO FACTURABLE". A
            continuación, por favor elija el motivo de la cancelación.
          </h6>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={func}>
          NO Facturar (CONTABLE)
        </Button>
        <Button variant="danger" onClick={func2}>
          NO Facturar (INCUMPLIMIENTO)
        </Button>
        <Button
          variant="secondary"
          style={{ textAlign: "center", width: "4rem" }}
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const CancelBillArtModal = ({
  show,
  handleClose,
  idArtBill,
}: {
  show: boolean;
  handleClose: any;
  idArtBill: string;
}) => {
  const [func, loading, error] = useAsyncFunction(
    () => CALLABLE_FUNCTIONS.DELETE_ART_BILL({ idArtBill }),
    undefined,
    handleClose
  );
  const [func2, loading2, error2] = useAsyncFunction(
    () => CALLABLE_FUNCTIONS.DELETE_ART_BILL({ idArtBill, justAFIP: true }),
    undefined,
    handleClose
  );
  const deleteBill = () => {
    func().then(() => setSuccess(true));
  };
  const deleteBillAFIP = () => {
    func2().then(() => setSuccess(true));
  };
  const [success, setSuccess] = useState(false);
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Crear Nota de crédito</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <Alert variant="success">Realizado con éxito</Alert>
        ) : loading || loading2 ? (
          <Spinner />
        ) : error ? (
          error
        ) : error2 ? (
          error2
        ) : (
          <h6>¿Desea crear una nota de crédito?</h6>
        )}
      </Modal.Body>
      {!success && (
        <Modal.Footer>
          <ButtonWithTooltip
            variant="danger"
            style={{ textAlign: "center" }}
            textTooltip="Hace una nota de crédito que impacta en AFIP y pone los traslados para facturarse nuevamente."
            onClick={deleteBill}
          >
            Nota de crédito
          </ButtonWithTooltip>

          <ButtonWithTooltip
            variant="danger"
            style={{ textAlign: "center" }}
            textTooltip="Hace una nota de crédito que impacta solo en AFIP sin afectar los traslados"
            onClick={deleteBillAFIP}
          >
            Solo AFIP
          </ButtonWithTooltip>
          <Button
            variant="secondary"
            style={{ textAlign: "center", width: "4rem" }}
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
