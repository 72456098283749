import { Uid } from "controllers/auth";
import database, { WithId, WithUid } from "controllers/database";
import { Car } from "types/car";
import { Revision } from "types/user-data";

export const getCarsWithRevision = (
  uid: Uid
): Promise<(WithId & Car & Revision)[]> =>
  database
    .collection("user")
    .doc(uid)
    .collection("car")
    .as(Car)
    .then((cim) =>
      Promise.all(
        cim.map((ci) =>
          database
            .collection("user")
            .doc(uid)
            .collection("car_revision")
            .doc(ci.id)
            .as(Revision)
            .then(
              (r) =>
                ({
                  ...r,
                  ...ci,
                } as WithId & Car & Revision)
            )
        )
      )
    );

export const getCars = (uid: Uid): Promise<(WithId & Car)[]> =>
  database.collection("user").doc(uid).collection("car").as(Car);

export const newCar = (uid: Uid, car: Car): Promise<void> =>
  database.collection("user").doc(uid).collection("car").add(car, Car).then();

export const updateCar = (uid: Uid, idCar: string, car: Car): Promise<void> =>
  database
    .collection("user")
    .doc(uid)
    .collection("car")
    .doc(idCar)
    .set(car, Car);

export const getCar = (uid: Uid, idCar: string): Promise<Car> =>
  database.collection("user").doc(uid).collection("car").doc(idCar).as(Car);
export const getCarWithDoc = (
  doc: firebase.firestore.DocumentReference
): Promise<Car> => database.doc(doc.path).as(Car);

export const getCarsAdmin = (): Promise<
  (WithUid & WithId & Car & Revision)[]
> =>
  database
    .collectionGroup("car_revision")
    .as(Revision)
    .then((revisions) =>
      Promise.all(
        revisions.map((revision) =>
          database
            .collection("user")
            .doc(revision.uid)
            .collection("car")
            .doc(revision.id)
            .as(Car)
            .then(
              (r) =>
                ({
                  ...revision,
                  ...r,
                } as WithUid & WithId & Car & Revision)
            )
        )
      )
    );
