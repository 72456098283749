import database, { WithId } from "controllers/database";
import { Sale } from "types/sale";

export const getZonesSaleConfig = (): Promise<(WithId & Sale)[]> =>
  database.collection("art").doc("sale").collection("Experta").as(Sale);

export const getZoneSaleConfig = (id: string) =>
  database.collection("art").doc("sale").collection("Experta").doc(id).as(Sale);

export const setZoneSaleConfig = (c: WithId & Sale) =>
  database
    .collection("art")
    .doc("sale")
    .collection("Experta")
    .doc(c.id)
    .set(c, Sale);

export const addZoneSaleConfig = (cz: Sale) =>
  database.collection("art").doc("sale").collection("Experta").add(cz, Sale);
