import { Link, Padding, Spinner } from "components/styles";
import ROUTES from "constants/routes";
import { WithId } from "controllers/database";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { getProvincesConfig } from '../Global/reducer';
import { TableConfig } from "../Localities";
import { CustomConfig } from "types/config";

const Provinces = () => {
	const [items, setItems] = useState([] as (CustomConfig & WithId)[]);
	const [loading, setLoading] = useState(true);

	const updateData = () => {
		setLoading(true);
		getProvincesConfig()
			.then(setItems)
			.then(() => setLoading(false));
	};

	useEffect(() => {
		updateData();
	}, []);

	if (loading) return <Spinner />;
	return (
		<Padding pad='15px'>
			<Row style={{ marginBottom: 10 }}>
				<Col>
					<Link to={ROUTES.ADMIN_CONFIGURATION_NEW_PROVINCE}>
						<Button className="pull-right" variant='dark'>
							Nueva Provincia
						</Button>
					</Link>
				</Col>
			</Row>
			<TableConfig items={items} link={ROUTES.ADMIN_CONFIGURATION_VIEW_PROVINCE}/>
		</Padding>
	);
};

export default Provinces;
