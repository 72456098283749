
import React, { FunctionComponent, ComponentClass } from 'react'
import {  Form } from 'react-bootstrap';

export const ReduxDecoratorNoStyle = ((component : FunctionComponent<any> | ComponentClass<any> | 'input') => 
    (
        ({ input, meta: {touched, valid, invalid, error}, ...props } : any): JSX.Element => {
        return React.createElement(           
                    component,
                    {
                        onBlur: () => input.onBlur(input.value),
                        ...input,
                        ...props}
                )
        }
    )
)

export const ReduxDecorator = ((component : FunctionComponent<any> | ComponentClass<any> | 'input') => 
    (
        ({ input, meta: {touched, valid, invalid, error}, ...props } : any): JSX.Element => {
        return (
            <Form.Group>
                {React.createElement(           
                    component,
                    {
                        onBlur: () => input.onBlur(input.value),
                        ...input,
                        ...props}
                ) }
            </Form.Group>)
        }
    )
)


export const ReduxValidationDecorator = ((component : FunctionComponent<any> | ComponentClass<any>) => 
    (
        ({ input, inputClassName, className, meta: {touched, valid, invalid, error}, labelinput, ...props } : any): JSX.Element => {
        return ( 
            <Form.Group className={className}>
                
                {labelinput && <Form.Label>{labelinput}</Form.Label>}

                {
                    React.createElement(           
                    component,
                    {
                        onBlur: () => input.onBlur(input.value),
                        isInvalid: touched && invalid,
                        isValid: touched && valid,
                        className: inputClassName,
                        ...input,
                        ...props,
                        //error
                    }
                )}
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            
            </Form.Group>);
} ))