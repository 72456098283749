import { ScheduledTransfer, FbDocument } from "types/transfer";
import { getDistanceFromLatLonInKm } from "components/Admin/Transfers/view/pool/pool_reducer";
import moment from "moment";

export const filterSchedulePool = (
  scheduled: {
    ref: FbDocument;
    data: ScheduledTransfer;
  }[]
) => {
  const comparation: {
    ref: FbDocument;
    data: ScheduledTransfer;
  }[] = scheduled.slice();
  const combination: any[] = [];
  scheduled.map((t, index) => {
    const travel1 = t;
    console.log(index, "i");
    console.log(travel1.data.pool![0].refTransfer.id, "?");
    console.log(comparation, "comparation");

    for (let i = index + 1; i < comparation.length; i++) {
      let travel2 = comparation[i];
      console.log(travel2.ref.id, "1");

      if (travel1.data.pool![0].refTransfer.id === travel2.ref.id) {
        console.log("ENtra");

        //tiempo max de llegada tarde (min)
        const afterTimeMax = 15;
        //tiempo max de llegada antes de hora en origen
        const beforeTimeMax = 45;
        const linealDistanceD1D2: number = getDistanceFromLatLonInKm(
          travel1.data.destination.geopoint.latitude,
          travel1.data.destination.geopoint.longitude,
          travel2.data.destination.geopoint.latitude,
          travel2.data.destination.geopoint.longitude
        );
        const durationD1D2: number = Math.round(
          (linealDistanceD1D2 * 1.4 * 60) / 30
        );
        //tiempo entre O1 y D1
        const durationO1D1 = Math.round(
          travel1.data.info.duration_origin_destination
        );
        //Tiempo entre O2 y D2
        const durationO2D2 = Math.round(
          travel2.data.info.duration_origin_destination
        );
        //Calculo tiempo estimado entre O2 y D1
        const linealDistanceO2D1: number = getDistanceFromLatLonInKm(
          travel1.data.destination.geopoint.latitude,
          travel1.data.destination.geopoint.longitude,
          travel2.data.origin.geopoint.latitude,
          travel2.data.origin.geopoint.longitude
        );
        const durationO2D1: number = Math.round(
          (linealDistanceO2D1 * 1.4 * 60) / 30
        );

        //Calculo tiempo estimado entre O1 y O2
        const linealDistanceO1O2: number = getDistanceFromLatLonInKm(
          travel1.data.origin.geopoint.latitude,
          travel1.data.origin.geopoint.longitude,
          travel2.data.origin.geopoint.latitude,
          travel2.data.origin.geopoint.longitude
        );
        const durationO1O2: number = Math.round(
          (linealDistanceO1O2 * 1.4 * 60) / 30
        );
        //Le agrego 15 min al segundo turno turno
        const destination2Max = moment(
          travel2.data.destination.time.toDate()
        ).add(afterTimeMax, "minutes");
        //la hora max a la q puede llegar el 1er paciente
        const destination1Max = moment(
          travel1.data.destination.time.toDate()
        ).add(afterTimeMax, "minutes");
        //resto 45 min al primer turno
        const destination1Min = moment(
          travel1.data.destination.time.toDate()
        ).subtract(beforeTimeMax, "minutes");
        //Lo mas temprano que puede llegar el 2do paciente
        const destination2Min = moment(
          travel2.data.destination.time.toDate()
        ).subtract(beforeTimeMax, "minutes");
        //Como el destino es el mismo, comprobamos cual es la ruta mas corta
        //O1 -> O2 -> D
        const durationO1O2D = durationO1O2 + durationO2D2;
        //O2 -> O1 -> D
        const durationO2O1D = durationO1O2 + durationO1D1;
        //O2 -> D1 -> D2
        const durationO2D1D2 = durationO2D1 + durationD1D2;
        //O1 -> O2 -> D1
        const durationO1O2D1 = durationO1O2 + durationO2D1;
        //O1 -> O2 -> D2 - D1
        const durationO1O2D2D1 = durationO1O2 + durationO2D2 + durationD1D2;
        const durationO1O2D1D2 = durationD1D2 + durationO2D1 + durationD1D2;
        //tiempo max de viaje del pasajero 1
        let durationMaxP1: number = durationO1D1 * 1.5;
        //tiempo max de viaje del pasajero 2
        let durationMaxP2: number = durationO2D2 * 1.5;

        if (durationMaxP1 <= 30) durationMaxP1 = 30;
        if (durationMaxP2 <= 30) durationMaxP2 = 30;

        const timeMax = moment(destination1Min).add(durationD1D2, "minutes");
        const timeMin = moment(destination2Min).subtract(
          durationD1D2,
          "minutes"
        );
        if (durationD1D2 === 0) {
          console.log("entra mismo destino", durationO1O2D, durationO2O1D);
          //Primero busca al paciente 2
          if (durationO1O2D > durationO2O1D) {
            //EL primer paciente puede llegar 45 min antes + tiempo de viaje entre destinos tiene q ser mayor q hora de turno2 - 45 min y menor q hora de turno2 +15 min

            console.log(
              moment(timeMax.toDate()) <= moment(destination2Max.toDate()),
              moment(timeMin.toDate()) <= moment(destination1Max.toDate()),
              "verificamos"
            );

            if (
              moment(timeMax.toDate()) <= moment(destination2Max.toDate()) &&
              moment(timeMin.toDate()) <= moment(destination1Max.toDate())
            ) {
              console.log(
                durationMaxP2 >= durationO2O1D,
                durationMaxP1 >= durationO1D1,
                durationMaxP2,
                durationO2O1D,
                durationMaxP1,
                durationO1D1,
                "verificamos 2"
              );

              //Verificamos que los pacientes no viajen mas tiempo del permitido
              if (
                durationMaxP2 >= durationO2O1D &&
                durationMaxP1 >= durationO1D1
              ) {
                combination.push([
                  travel1,
                  travel2,
                  "O2",
                  true,
                  durationO1O2,
                  durationO2D1,
                  0,
                ]);
              }
            }
          } else {
            if (
              moment(timeMax.toDate()) <= moment(destination2Max.toDate()) &&
              moment(timeMin.toDate()) <= moment(destination1Max.toDate())
            ) {
              //Verificamos que los pacientes no viajen mas tiempo del permitido
              if (
                durationMaxP2 >= durationO2D2 &&
                durationMaxP1 >= durationO1O2D
              ) {
                combination.push([
                  travel1,
                  travel2,
                  "O1",
                  true,
                  durationO1O2,
                  durationO2D1,
                  0,
                ]);
              }
            }
          }
        } else if (durationD1D2 !== 0) {
          //EL primer paciente puede llegar 45 min antes + tiempo de viaje entre destinos tiene q ser mayor q hora de turno2 - 45 min y menor q hora de turno2 +15 min
          if (
            moment(timeMax.toDate()) <= moment(destination2Max.toDate()) &&
            moment(timeMin.toDate()) <= moment(destination1Max.toDate())
          ) {
            //Verificamos que los pacientes no viajen mas tiempo del permitido
            if (
              durationMaxP2 >= durationO2D1D2 &&
              durationMaxP1 >= durationO1O2D1 &&
              durationMaxP2 >= durationO2D2 &&
              durationMaxP1 >= durationO1O2D2D1
            ) {
              console.log("2 destinos posibles");
              //verificamos el camino mas corto
              if (durationO1O2D2D1 > durationO1O2D1D2) {
                console.log("camino mas corto por d2");
                combination.push([
                  travel1,
                  travel2,
                  "D2",
                  true,
                  durationO1O2,
                  durationO2D2,
                  durationD1D2,
                ]);
              } else {
                console.log("camio mas corto por D1");
                combination.push([
                  travel1,
                  travel2,
                  "D1",
                  true,
                  durationO1O2,
                  durationO2D1,
                  durationD1D2,
                ]);
              }
            } else if (
              durationMaxP2 >= durationO2D1D2 &&
              durationMaxP1 >= durationO1O2D1
            ) {
              console.log("Camino por D1");
              combination.push([
                travel1,
                travel2,
                "D1",
                true,
                durationO1O2,
                durationO2D1,
                durationD1D2,
              ]);
            } else if (
              durationMaxP2 >= durationO2D2 &&
              durationMaxP1 >= durationO1O2D2D1
            ) {
              combination.push([
                travel1,
                travel2,
                "D2",
                true,
                durationO1O2,
                durationO2D2,
                durationD1D2,
              ]);
            }
          }
        }
      }
    }
  });
  return combination;
};
