import React from "react";
import ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { NavButton as NavButton } from "components/input/button";
import { HashLink } from "react-router-hash-link";
import logoHorizontalImg from "./assets/logo-horizontal.png";
const Header = () => (
  <header className="header-main">
    <MiddleHeader className="middle-header container-fluid">
      <div className="container">
        <div className="row">
          <LogoBlock
            className="col-lg-4 col-md-4 logo-block"
            style={{ textAlign: "left", height: "50px", lineHeight: "50px" }}
          >
            <Link to="#">
              {/*<img src="assets/logo-horizontal-8f080cd55b3cef5bd70c1ac637134a2ab93e65ec05ee333583686c13d7e9d250.png" alt="Logo" style={{"height": "50px"}} className="d-none d-md-block" />*/}

              <img
                src={logoHorizontalImg}
                alt="Logo"
                style={{ height: "50px" }}
                className="d-none d-md-block"
              />
              <img
                src="assets/logo-070b76280dc0f019580a20151dd1e9515757bbfa7650b5d4955ec4eea815fab1.png"
                alt="Logo"
                height="100"
                className="d-md-none"
              />
            </Link>
          </LogoBlock>
          <div
            className="col-lg-3 col-md-4 col-sm-12"
            style={{ height: "50px", lineHeight: "50px" }}
          ></div>
          <div
            className="pull-right"
            style={{ height: "50px", lineHeight: "50px" }}
          >
            <NavButton
              title="iniciar sesion"
              className="nav-button"
              a
              to={ROUTES.SIGN_IN}
              style={{ fontSize: "13px" }}
            >
              INICIAR SESIÓN
            </NavButton>
            <NavButton
              a
              to={ROUTES.FAQ}
              title="PREGUNTAS FRECUENTES"
              className="nav-button smoothscroll"
              style={{ fontSize: "13px" }}
              linkComponent={HashLink}
            >
              PREGUNTAS FRECUENTES
            </NavButton>
            <NavButton
              to="#what-we-do"
              title="REQUISITOS"
              className="nav-button smoothscroll"
              style={{ fontSize: "13px" }}
              linkComponent={HashLink}
            >
              REQUISITOS
            </NavButton>
          </div>
        </div>
      </div>
    </MiddleHeader>
  </header>
);

const LogoBlock = styled.div`
  @media only screen and (max-width: 767px) and (min-width: 319px) {
    margin-bottom: 33px;
  }
`;

const MiddleHeader = styled.div`
  padding-bottom: 33px;
  padding-top: 33px;
  background-color: #fff;
`;

export default Header;
