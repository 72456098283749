import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AdminTableAlternative,
  Link,
  Padding,
  Spinner,
  NoData,
} from "components/styles";
import ROUTES from "constants/routes";
import database, { WithId } from "controllers/database";
import { UserType } from "controllers/user";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Pagination,
  Row,
} from "react-bootstrap";
import { User } from "types/user-data";
import { Paginator } from "util/paginator";
import { ButtonSearch } from "../../Zones";

const getCollectionByType = (t: number) => {
  if (t === 0) return database.collectionGroup("user");
  if (t === 1)
    //REGULAR
    return database.collection("user").where("type").is(UserType.regular);
  if (t === 2)
    //ADMIN
    return database.collection("user").where("type").is(UserType.admin);
  if (t === 3)
    //ART
    return database.collection("user").where("type").is(UserType.art);

  throw Error("bad type");
};

const getCollectionByEmail = (s: string) =>
  database.collection("user").where("email").isGreaterOrEqualThan(s);

const Usuarios = () => {
  const [items, setItems] = useState([] as (WithId & User)[]);
  const [paginator, setPaginator] = useState(
    new Paginator(User, getCollectionByType(3), ["email"], 30, undefined, true)
  );

  const [loading, setLoading] = useState(true);

  const updateData = () => {
    setLoading(true);
    paginator
      .getDataWithoutUid()
      .then(setItems)
      .then(() => setLoading(false));
  };

  useEffect(() => {
    updateData();
  }, []);

  const prevPage = () => {
    paginator.prev();
    updateData();
  };
  const nextPage = () => {
    paginator.next();
    updateData();
  };

  return (
    <Padding pad="15px">
      <Row>
        <Col>
          <Row></Row>
        </Col>
      </Row>
      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length > 0 ? (
        <React.Fragment>
          <AdminTableAlternative hover>
            <thead>
              <tr>
                <th>Email</th>
                <th>Nombre</th>
                <th>Rol</th>
                <th>Teléfono</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>{item.email}</td>
                  <td>{item.name + " " + item.surname}</td>
                  <td>
                    {item.type !== undefined &&
                      UserType[item.type].toUpperCase()}
                  </td>
                  <td>{item.phone.area_code + " " + item.phone.number}</td>
                  <td>
                    {item.type === UserType.regular ? (
                      <Link to={ROUTES.ADMIN_USER_PROFILE_VIEW(item.id)}>
                        <Button variant="light">Ver</Button>
                      </Link>
                    ) : (
                      item.type === UserType.art && (
                        <Link to={ROUTES.ADMIN_ART_PROFILE_VIEW(item.id)}>
                          <Button variant="light">Ver</Button>
                        </Link>
                      )
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </AdminTableAlternative>
        </React.Fragment>
      ) : (
        <NoData />
      )}
      <Pagination style={{ justifyContent: "center" }}>
        {!paginator.firstPage && <Pagination.Prev onClick={prevPage} />}
        <Pagination.Item active>{paginator.pageNumber}</Pagination.Item>
        {!paginator.lastPage && <Pagination.Next onClick={nextPage} />}
      </Pagination>
    </Padding>
  );
};

export default Usuarios;
