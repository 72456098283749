import React from "react"
import InputMask from "react-input-mask";

//Acordarse de usar con un PARSER para remover mascara porque react-input-mask nos guarda el valor con la máscara puesta
export const TextMaskInput = ({mask, value, isValid, isInvalid, onChange, onBlur, disabled, ...props} : any) => {
    let className = ""
    if(isInvalid)
        className = "is-invalid"
    else if(isValid)
        className = "is-valid"
    return (
        <div className={className}>
            <InputMask mask={mask} onChange={onChange} onBlur={onBlur} value={value} className={`form-control ${className}`}/>
        </div>
)}
