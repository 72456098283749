import { WithId } from "controllers/database";
import { Map } from "immutable";
import _ from "lodash";

export declare type WithCopyConstructor<T> = {
  new (toCopy: T): T;
};

export declare type WithOneParamConstructor<T> = {
  new (param: any): T;
};

export declare type date = Date | WithCopyConstructor<Date>;

export const dbEntry = <T extends { new (...args: any[]): {} }>(
  constructor: T
) => {
  //FIXME: Arreglar inheritance
  return class extends constructor {
    constructor(...toCopy: any[]) {
      super();
      toCopy = toCopy[0];
      clearUndefinedProps(toCopy, this);
      //removeUndefined(this,0)
      //console.log(toCopy)
      //removeUndefined(toCopy)
      //removeUndefined(this)
      for (const key in this) {
        let constructors: Map<
          string,
          WithOneParamConstructor<any>
        > = constructor.prototype.__constructors;
        if (constructors?.has(key))
          this[key] = new (constructors.get(
            key
          ) as WithOneParamConstructor<any>)((toCopy as any)[key]);
        else this[key] = (toCopy as any)[key];
      }
    }
  };
};

export const removeUndefined = (obj: any) => {
  removeUndefinedLevel(obj, 0); //Bug estructura ciclica
};
export const removeUndefinedFromBack = <T extends { [key: string]: any }>(
  obj: T
) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") removeUndefined(obj[key]);
    else if (obj[key] === undefined) delete obj[key];
  });
  return obj;
};
const removeUndefinedLevel = (obj: any, level: number) => {
  if (level < 5)
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object")
        removeUndefinedLevel(obj[key], level + 1);
      else if (obj[key] === undefined) delete obj[key];
    });
  return obj;
};

const clearUndefinedProps = (a: any, b: any) => {
  Object.keys(b).map((key) => {
    if (a[key] === undefined || a[key] === null) {
      delete a[key];
      delete b[key];
    }
  });
  removeUndefined(a);
  /*var dfs = [a]
    var iteration = 1
    while(dfs.length > 0) {
        console.log(iteration)
        iteration++
        var next : any = dfs.pop()
        if(next && typeof next === 'object') {
            Object.keys(next).map(key => {
                if(next[key] === undefined || next[key] === null) {
                    delete next[key]
                }
                else
                    dfs.push(next[key])
            })
        }
    }*/
};

export const constructor =
  (constr: WithOneParamConstructor<any>) =>
  (target: any, propertyKey: string) => {
    let propConstructors: Map<
      string,
      WithOneParamConstructor<any>
    > = target.__constructors || Map<string, any>();
    target.__constructors = propConstructors.set(propertyKey, constr);
  };

export const arrayNode = <T extends Object>(
  constructor: WithOneParamConstructor<T>
): WithOneParamConstructor<Array<WithId & T>> => {
  class ArrayNodeDecorator extends Array<WithId & T> {
    constructor(toCopy: T) {
      super(
        ...Object.keys(toCopy).map((key: string) => {
          var res: WithId & T = new constructor(
            (toCopy as any)[key] as T
          ) as WithId & T;
          res.id = key;
          return res;
        })
      );
    }
  }
  return ArrayNodeDecorator;
};

export const objectMap =
  <T, S extends any>(
    f: (key: string, value: any) => S
  ): ((a: T) => { [key in keyof T]: S }) =>
  (a: T) =>
    _.reduce(
      {} as any,
      (accum: any, key: string) => (accum[key] = f(key, (a as any)[key]))
    )(Object.keys(a as {}));
