export enum ErrorMessages {
	"auth/invalid-email" = "Email inválido.",
	"auth/invalid-password" = "Contraseña inválida.",
	"auth/user-not-found" = "No existe el usuario.",
	"auth/internal-error" = "Error del servidor.",
	"auth/wrong-password" = "La contraseña es incorrecta.",
	"auth/credential-already-in-use" = "El usuario ya se encuentra loggeado.",
	"auth/email-already-in-use" = "El email ya se encuentra registrado.",
	"auth/email-already-exists" = "El email ya se encuentra registrado.",
}

export const getMessage = (error: KnownFirebaseError | AnyFirebaseError) => {
	if (error as KnownFirebaseError) {
		//if(Object.values(ErrorMessages).includes(error.message as ErrorMessages) ) {
		return ErrorMessages[(error as KnownFirebaseError).code];
	} else {
		return error.message;
	}
};

export declare type KnownFirebaseErrorCode = keyof typeof ErrorMessages;

export declare type AnyFirebaseError = {
	code: string;
	message: string;
};

export declare type KnownFirebaseError = {
	code: KnownFirebaseErrorCode;
	message: string;
};
