/*
    ACLARACION: MEJOR USAR MOMENT.JS EN VEZ DE ESTAS FUNCIONES
*/

import { FbDate } from "types/transfer";
import Moment from "react-moment";
import React from "react";

export const addMinutes = (d : Date, m : number) : Date => (
    new Date(d.getTime()+(m*60*1000))
)

export const diffInSeconds = (d1 : Date, d2 : Date) : number => (
    ~~((d1.getTime()-d2.getTime())/1000)
) 

export const diffInMinutes = (d1 : Date, d2 : Date) : number => (
    ~~((d1.getTime()-d2.getTime())/60/1000)
) 

export const diffInYears = (d1 : Date, d2 : Date) : number => {
    var diffYear =(d2.getTime() - d1.getTime()) / 1000;
    diffYear /= (60 * 60 * 24);
    return Math.abs(Math.round(diffYear/365.25));  
}

export const showFbDate = (z:FbDate) => <Moment date={z.toDate()} format="DD/MM/YYYY" />