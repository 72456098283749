import { Timeline } from "antd";
import { Button } from "components/styles";
import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { ArtResponse, TransferEventMotive } from "types/report";
import {
  FbDate,
  FbGeoPoint,
  ScheduledTransfer,
  TransferActionType,
  TransferStageDefinitions,
  TransferStage,
  FinishStatus,
} from "types/transfer";
import ROUTES from "constants/routes";

export const HistoryModal = ({
  transfer,
  show,
  handleClose,
}: {
  transfer: ScheduledTransfer;
  show: boolean;
  handleClose: any;
}) => (
  <Modal show={show} onHide={handleClose} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Historial Traslado</Modal.Title>
    </Modal.Header>

    <Modal.Body style={{ paddingRight: "8rem" }}>
      <HistoryScheduledTransfer transfer={transfer} />
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>
);

export const HistoryScheduledTransfer = ({
  transfer,
}: {
  transfer: ScheduledTransfer;
}) => {
  const history = transfer.status.history;
  let allHistory: Array<{
    date: FbDate;
    text: string;
    position?: FbGeoPoint;
    description?: string;
  }> = [];

  allHistory.push(
    ...history.stages.map((s) => ({
      position: s.geopoint,
      date: s.time,
      text:
        typeof s.stage === "number"
          ? TransferStageDefinitions[s.stage as any] +
            (s.stage === TransferStage.finished
              ? " " + FinishStatus[transfer.status.finished_status!]
              : "")
          : s.stage,
      description: s.description,
    }))
  );

  if (history.actions)
    allHistory.push(
      ...history.actions.map((s) => ({
        position: s.geopoint,
        date: s.time,
        text: TransferActionType[s.type] + " " + TransferEventMotive[s.action],
      }))
    );
  allHistory.sort((a, b) => a.date.seconds - b.date.seconds);
  if (transfer.status.active_report !== undefined) {
    const arrActions = [];
    if (transfer.status.active_report.date_buy15 !== undefined)
      arrActions.push({
        date: transfer.status.active_report.date_buy15,
        text: `Se agregó 15 minutos`,
      });

    if (
      transfer.status.active_report.messages !== undefined &&
      transfer.status.active_report.messages.length > 0
    ) {
      transfer.status.active_report.messages.map((mes) => {
        arrActions.push({ date: mes.date, text: `Mensaje: ${mes.message}` });
      });
    }

    if (transfer.status.active_report.art_is_resolving !== undefined)
      arrActions.push({
        date: transfer.status.active_report.art_is_resolving,
        text: `En resolución`,
      });

    allHistory = allHistory
      .concat(arrActions)
      .sort((a, b) => a.date.seconds - b.date.seconds);
  }

  if (transfer.status.history.reports !== undefined)
    allHistory = allHistory
      .concat(
        ...transfer.status.history.reports.map((x) => {
          const arrActions = [];

          if (x.date_buy15 !== undefined)
            arrActions.push({
              date: x.date_buy15,
              text: `Se agregó 15 minutos`,
            });

          if (x.art_is_resolving !== undefined)
            arrActions.push({
              date: x.art_is_resolving,
              text: `En resolución`,
            });

          if (x.messages !== undefined && x.messages.length > 0) {
            x.messages.map((mes) => {
              arrActions.push({
                date: mes.date,
                text: `Mensaje: ${mes.message}`,
              });
            });
          }

          if (x.date_end.toDate() < new Date())
            //Termino informe
            arrActions.push({
              date: x.date_end,
              text: `Termino Informe: ${
                x.art_response !== undefined
                  ? ArtResponse[x.art_response]
                  : "El conductor continúa el viaje"
              }`,
            });

          return arrActions;
        })
      )
      .sort((a, b) => a.date.seconds - b.date.seconds);

  return (
    <Timeline
      mode="left"
      pending={
        transfer.status.stage !== TransferStage.finished && "Traslado en curso"
      }
    >
      {allHistory.map((h) => (
        <Timeline.Item
          key={JSON.stringify(h.date)}
          label={
            <div>
              {h.position && (
                <a
                  target="_blank"
                  href={ROUTES.GOOGLE_MAPS_SEARCH_COORDINATES(h.position)}
                >
                  <Button variant="warning">Ubicación</Button>
                </a>
              )}
              <div style={{ marginLeft: 10, float: "right" }}>
                {moment(h.date.toDate()).format("HH:mm")}
              </div>
              <div style={{ marginLeft: 10, float: "right" }}>
                {moment(h.date.toDate()).toJSON().slice(0, 10).slice(8, 10) +
                  "/" +
                  moment(h.date.toDate()).toJSON().slice(0, 10).slice(5, 7)}
              </div>
              {h.description && (
                <div style={{ marginLeft: 10, float: "right" }}>
                  {h.description}
                </div>
              )}
            </div>
          }
        >
          {h.text === "Buscando conductor para retorno" ||
          h.text === "Retorno solicitado"
            ? "(" + h.description?.toUpperCase() + ") " + h.text
            : h.text}
        </Timeline.Item>
      ))}
    </Timeline>
  );
};
