import { Spinner, NoData, H2 } from "components/styles";
import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { FbDate, TransferStage } from "types/transfer";
import moment from "moment";

import { DatePickerInput } from "components/input/date-picker";
import TransferRequestCard from "./transferRequestCard";
import { FIRESTORE } from "constants/firestore";
import { useSelector } from "react-redux";
import { uidSelector } from "redux/selectors";
import { doesNotOverlap } from "./reducer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRecycle } from "@fortawesome/free-solid-svg-icons";
import { User } from "types/user-data";

const UserScheduleTransfers = () => {
  const uid = useSelector(uidSelector);
  //const uid = "ajg7SEcjhbcVq46hTT96Y1yuRBL2";
  const [inputDate, setInputDate] = useState<Date>(
    moment().startOf("day").add(1, "day").toDate()
  );
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [selectedZone, setSelectedZone] = useState<string>("Todas");
  const [userInfo, setUserInfo] = useState<User>();

  //TODO CAMBIAR EL SISTEMA DE ZONAS PARA QUE NO ESTE HARDCODEADO
  const zones = [
    "CABA",
    "LA PLATA",
    "GBA NORTE",
    "GBA SUR",
    "GBA OESTE",
    "Zona Norte Plus",
  ];
  //TODO CAMBIAR EL SISTEMA DE ZONAS PARA QUE NO ESTE HARDCODEADO

  const setTransfers = () => {
    setLoading(true);
    FIRESTORE.DOCUMENT_USER(uid)
      .get()
      .then((doc) => doc.data())
      .then((data) => setUserInfo(data));
    FIRESTORE.COLLECTION_TRANSFER_REQUEST()
      .where("destination.time", ">=", FbDate.fromDate(inputDate))
      .where(
        "destination.time",
        "<",
        FbDate.fromDate(moment(inputDate).endOf("day").toDate())
      )
      .orderBy("destination.time")
      .get()
      .then((data) =>
        data.docs
          .map((d) => ({ data: d.data(), id: d.id }))
          .filter(
            (transfer) =>
              (transfer.data.art.id === "MekXauxMVPd1ayCGpIwWjg7j6Z73" ||
                transfer.data.art.id === "GjP1gJT9T1eK0FGe2kfx6o4T80J2") &&
              transfer.data.keywords!.includes("Verificado por API")
          )
          .filter(
            (transfer) =>
              transfer.data.origin.address.locality !== "Zárate" &&
              transfer.data.origin.address.locality !== "Campana"
          )
          .filter((transfer) => {
            if (
              selectedZone !== "Todas" &&
              transfer.data.info.amount?.custom_zone !== selectedZone
            )
              return false;
            else return true;
          })
      )
      .then((filtered) => {
        FIRESTORE.COLLECTION_USER_TRANSFERS(uid)
          .where("status.stage", "==", TransferStage.scheduled)
          .where("origin.time", ">=", FbDate.fromDate(inputDate))
          .where(
            "origin.time",
            "<",
            FbDate.fromDate(moment(inputDate).endOf("day").toDate())
          )
          .get()
          .then((data) => data.docs.map((doc) => doc.data()))
          .then((scheduled) => doesNotOverlap(filtered, scheduled))
          .then((filtered) => setItems(filtered));
      })
      .then(() => setLoading(false));
  };
  console.log(items);

  useEffect(() => {
    const interval = setInterval(() => {
      setTransfers();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTransfers();
  }, [inputDate, selectedZone]);

  return (
    <div style={{ maxWidth: "100%" }}>
      <Row>
        <Col xs={12}>
          <H2>TRASLADOS DISPONIBLES</H2>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Label>Buscar por fecha</Form.Label>
          <DatePickerInput
            value={inputDate}
            onChange={setInputDate}
            minDate={moment().startOf("day").add(1, "day").toDate()}
            maxDate={moment().endOf("day").add(28, "days").toDate()}
          ></DatePickerInput>
        </Col>
        <Col xs={6}>
          <Form.Label>Buscar por zona</Form.Label>
          <Form.Control
            as="select"
            value={selectedZone}
            onChange={(e) => {
              setSelectedZone(e.target.value);
            }}
          >
            <option value="Todas">TODAS LAS ZONAS</option>
            {zones.map((z) => (
              <option key={z} value={z}>
                {z}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        items.map((transfer) => (
          <div key={transfer.id}>
            <TransferRequestCard
              data={transfer.data}
              id={transfer.id}
              reload={setTransfers}
            />
          </div>
        ))
      )}
      <div
        style={{
          position: "fixed",
          bottom: "3%",
          right: "3%",
        }}
      >
        <Button>
          <FontAwesomeIcon icon={faRecycle} onClick={setTransfers} />
        </Button>
      </div>
    </div>
  );
};

export default UserScheduleTransfers;
