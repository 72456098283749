import ROUTES from 'constants/routes';
import React from 'react';
import * as B from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

const Footer = (props : any) => (
    <Main>
        <B.Container fluid={true}  style={{padding:"0px"}}>
            <Container className="pt-4 pb-4">
                <Nav as="ul" className="col-md-4 m-0">
                    <li><a href={ROUTES.FAQ}>FAQ</a></li>
                    <li><a href={ROUTES.PRIVACY}>Politica de privacidad</a></li>
                    <li><a href={ROUTES.TERMS_AND_CONDITIONS}>Términos y condiciones</a></li>
                </Nav>
                <Col md={7}>
                    <p className="copyright m-0">copyright &copy; 2019 SatAPP. Todos los derechos reservados</p>
                </Col>
            </Container>
        </B.Container>
    </Main>
)

const Main = styled.footer`
    background-color: #00426A;
    background-repeat: no-repeat;
    background-size: cover;
`

const Container = styled(B.Container)`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0;
    p {
        color: #fff;
        text-align: right;
        text-transform: uppercase;
        font-size: 12px;
    }
`
const Nav = styled(Col)`
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    height: max-content;
    li {
        padding: 0;
        margin: 0;
        display: block;
        flex-grow: 0;
        height: max-content;
        flex-grow: 1;
        &:not(:first-child) {
            border-left: 1pt solid #fff;
        }

        a {
            text-align: center !important;
            font-size: 12px;
            margin-bottom: 0;
            color: #fff;
            text-align: right;
            text-transform: uppercase;
            display: block;
        }
    }
`


export default styled(Footer)`

`;