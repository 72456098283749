import { Field } from "components/input/field";
import {
  ContainerPage,
  Spinner,
  PageTitle,
  SubmitButton,
} from "components/styles";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Alert } from "react-bootstrap";
import { reduxForm } from "redux-form";
import { getLocalityConfig, setLocalityConfig } from "../Global/reducer";
import { required } from "controllers/validations";

import { useParams } from "react-router";
import { QuizParams } from "types/module-transfer";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { getZoneSaleConfig, setZoneSaleConfig } from "./reducer";
import { Sale } from "types/sale";
import { FbDate } from "types/transfer";
import moment from "moment";

const ConfigViewZoneSale = () => {
  const { id } = useParams<QuizParams>();
  const [initialValues, setInitialValues] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const toggleEdit = () => setDisabled(!disabled);

  const updateData = () => {
    setLoading(true);
    getZoneSaleConfig(id)
      .then((values) => {
        setInitialValues({
          name: values.name,
          price_km: values.price_km,
          price_min_trip: values.price_min_trip,
          price_negative: values.price_negative,
          km_minimun_trip: values.km_minimun_trip,
          valid_from: values.valid_from.toDate(),
          value_records: values.value_records,
        });
      })
      .then(() => setLoading(false));
  };
  console.log(initialValues, "initialValues");

  useEffect(() => {
    updateData();
  }, []);

  const handleSubmit = async (values: any) => {
    const value_records = initialValues.value_records;
    const index = value_records.length;
    const valid_from = FbDate.fromDate(initialValues.valid_from);
    const valid_to = FbDate.fromDate(
      moment(values.valid_from).subtract(1, "day").endOf("day").toDate()
    );
    const newValueRecord = {
      km_minimun_trip: initialValues.km_minimun_trip,
      price_km: initialValues.price_km,
      price_min_trip: initialValues.price_min_trip,
      price_negative: initialValues.price_negative,
      valid_from: valid_from,
      valid_to: valid_to,
    };
    value_records.splice(index, 0, newValueRecord);
    console.log(value_records);
    const data: any = {
      name: initialValues.name,
      price_km: Number(values.price_km),
      price_min_trip: Number(values.price_min_trip),
      price_negative: Number(values.price_negative),
      km_minimun_trip: Number(values.km_minimun_trip),
      valid_from: FbDate.fromDate(new Date(values.valid_from)),
      id: id,
      value_records: value_records,
    };
    console.log(data);

    await setZoneSaleConfig(data);
    //return setLocalityConfig(data, id);
  };

  if (loading) return <Spinner />;
  return (
    <ContainerPage>
      <PageTitle>Ver/Editar Configuración Venta {initialValues.name}</PageTitle>
      <Row>
        <Col>
          {disabled ? (
            <Button
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              variant="info"
              onClick={toggleEdit}
            >
              Editar
            </Button>
          ) : null}
        </Col>
      </Row>
      <FormWithRedux initialValues={initialValues} onSubmit={handleSubmit} />
    </ContainerPage>
  );
};

const Form = ({
  submitting,
  handleSubmit,
  submitSucceeded,
  disabled = false,
}: any) => {
  if (submitSucceeded)
    return <Alert variant="success">Zona agregada/modificada</Alert>;
  return (
    <form onSubmit={handleSubmit}>
      {/*  <NameField disabled={disabled} /> */}
      <ConfigFields disabled={disabled} />
      <SubmitButton submitting={submitting} disabled={disabled}>
        Guardar
      </SubmitButton>
    </form>
  );
};

export const ConfigFields = ({ disabled = false }: { disabled?: boolean }) => (
  <>
    <Row>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Precio por Km</>}
          name="price_km"
          type="number-counter"
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Precio Viaje Mín</>}
          name="price_min_trip"
          type="number-counter"
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Precio Negativo</>}
          name="price_negative"
          type="number-counter"
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          decimals={2}
          increment={0.1}
          labelinput={<>Km viaje mínimo (m)</>}
          name="km_minimun_trip"
          type="number-counter"
        />
      </Col>
      <Col xl={2}>
        <Field
          disabled={disabled}
          labelinput={<>Valido desde:</>}
          name="valid_from"
          type="date-picker"
        />
      </Col>
    </Row>
  </>
);

export const FormWithRedux = reduxForm<{}, any>({
  form: "config-form",
})(Form);

export default ConfigViewZoneSale;
