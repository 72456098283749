import React, { useState, useEffect } from "react";
import { ContainerPage, H2, AsyncButton } from "components/styles";
import { Row, Col, Form, Button } from "react-bootstrap";
import { DatePickerInput } from "components/input/date-picker";
import moment from "moment";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";

const Finances = () => {
  const [soyDani, setSoyDani] = useState(false);
  const [validate, setValidate] = useState("");
  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(1, "day").endOf("day").toDate()
  );
  const forceExpertaBill = async () => {
    startDate.setHours(23, 59, 59);
    console.log(startDate.valueOf(), "antes de enviarse al back");
    await CALLABLE_FUNCTIONS.ADMIN_FORCE_EXPERTA_BILL({
      startDate: startDate.valueOf(),
    });
  };
  useEffect(() => {
    if (validate === "soy dani") setSoyDani(true);
  }, [validate]);
  return (
    <ContainerPage>
      <Row style={{ marginBottom: 15 }}>
        <Col>
          <H2></H2>
        </Col>
      </Row>
      <Row>
        <Col>
          {soyDani ? (
            <Form.Group>
              <Form.Label>Hasta</Form.Label>
              <DatePickerInput value={startDate} onChange={setStartDate} />
              <AsyncButton onClick={forceExpertaBill}>
                Forzar ventas 1
              </AsyncButton>
              <AsyncButton onClick={forceExpertaBill}>
                Forzar ventas 2
              </AsyncButton>
              <Button onClick={() => console.log(startDate)}>
                Consultar Fecha
              </Button>
            </Form.Group>
          ) : (
            <Form.Control
              type="text"
              placeholder="?"
              onChange={(e) => setValidate(e.target.value)}
            />
          )}
        </Col>
      </Row>
    </ContainerPage>
  );
};

export default Finances;
