import { FIRESTORE } from "constants/firestore";
import { uidSelector } from "controllers/auth";
import { useDbDocument } from "controllers/hooks";
import { useSelector } from "react-redux";
import { BillInfoTransfer, BillStatus, ChallengeBilling } from "types/billing";
import {
  FbCollection,
  FbDate,
  FbQuery,
  ScheduledTransfer,
} from "types/transfer";

export const useSaveSelection = (
  save: any
): [Set<any>, (a: Set<any>) => void] => {
  const uid = useSelector(uidSelector);
  const value = new Set(
    useDbDocument<{ values: any[] }>(`user/${uid}/selection`, save)?.values
  );

  const setValue = (v: Set<any>) => {
    FIRESTORE.DOCUMENT_SAVE_ADMIN_SELECTIONS(uid, save).set({ values: [...v] });
  };
  return [value, setValue];
};

export const useSaveSelectionCustom = (
  save: any
): [any[], (a: any[]) => void] => {
  const uid = useSelector(uidSelector);
  const value = useDbDocument<{ values: any[] }>(`user/${uid}/selection`, save)
    ?.values!;
  const setValue = (v: any[]) => {
    FIRESTORE.DOCUMENT_SAVE_ADMIN_SELECTIONS(uid, save).set({ values: [...v] });
  };
  return [value, setValue];
};

export const useSaveSelectionCustomNumber = (
  save: any
): [any[], (a: any[]) => void] => {
  const uid = useSelector(uidSelector);
  const value = useDbDocument<{ values: any[] }>(`user/${uid}/selection`, save)
    ?.values!;
  const setValue = (v: any[]) => {
    FIRESTORE.DOCUMENT_SAVE_ADMIN_SELECTIONS(uid, save).set({ values: [...v] });
  };
  return [value, setValue];
};

export const getInvoices = (
  type: number,
  startDate: Date,
  endDate: Date,
  driver: string | undefined,
  keyword: string | undefined
) => {
  let coll: FbCollection<ScheduledTransfer> | FbQuery<ScheduledTransfer>;
  //const today = new Date();
  //today.setHours(0, 0, 0, 0);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 0);
  if (driver) coll = FIRESTORE.COLLECTION_USER_TRANSFERS(driver);
  else coll = FIRESTORE.COLLECTION_TRANSFERS();

  if (type === 0) {
    console.log("tipo " + type, startDate, endDate);
    return coll
      .where("origin.time", ">=", startDate)
      .where("origin.time", "<", endDate)
      .where("sale.billed_from_driver", "==", BillStatus.No)
      .orderBy("origin.time", "desc");
  }

  if (type === 1)
    coll = coll.where("sale.billed_from_driver", "==", BillStatus.Si);

  if (type === 2)
    coll = coll.where(
      "sale.billed_from_driver",
      "==",
      BillStatus["Error al facturar"]
    );
  if (type === 3) coll = coll.where("sale.bill_info.missing", "==", true);
  if (type === 4)
    coll = coll.where(
      "sale.billed_from_driver",
      "==",
      BillStatus["Sin factura Cabify"]
    );
  if (type === 5) {
    coll = FIRESTORE.COLLECTION_USER_TRANSFERS("zdrEGB4N9dQuGBItxAqWlmTcVtn2");
    return coll
      .where("destination.time", ">=", startDate)
      .where("destination.time", "<=", endDate)
      .orderBy("destination.time", "desc");
  }
  if (type === 6) {
    return coll
      .where("destination.time", ">=", startDate)
      .where("destination.time", "<", endDate)
      .where("sale.billed_from_driver", "==", BillStatus["No facturar"])
      .orderBy("destination.time", "desc");
  }
  if (type === 7) {
    return coll
      .where("destination.time", ">=", startDate)
      .where("destination.time", "<", endDate)
      .orderBy("destination.time", "desc");
  }
  if (type === 9) {
    return coll
      .where("destination.time", ">=", startDate)
      .where("destination.time", "<", endDate)
      .where(
        "challenge.to_be_billed",
        "==",
        ChallengeBilling["Facturar como Desafío"]
      )
      .orderBy("destination.time", "desc");
  }
  if (type === 10) {
    return coll
      .where("destination.time", ">=", startDate)
      .where("destination.time", "<", endDate)
      .where(
        "challenge.to_be_billed",
        "==",
        ChallengeBilling["Desafío facturado"]
      )
      .orderBy("destination.time", "desc");
  }
  if (type === 12) {
    return coll
      .where("destination.time", ">=", startDate)
      .where("destination.time", "<", endDate)
      .where("sale.billed_from_driver", "==", BillStatus["Facturando"])
      .orderBy("destination.time", "desc");
  }

  coll = coll
    .where("destination.time", ">=", startDate)
    .where("destination.time", "<=", endDate);

  if (keyword) coll = coll.where("keywords", "array-contains", keyword);

  return coll
    .orderBy("destination.time", "desc")
    .orderBy("origin.time", "desc")
    .orderBy("destination.geohash", "desc");
};
export const wrongAmountInovices = () => {
  let coll;
  coll = FIRESTORE.COLLECTION_MODULES_TRANSFER()
    .where("reject.type", ">=", 2)
    .where("reject.type", "<=", 4);

  return coll.orderBy("reject.type", "desc");
};

export const getInvoicesForBas = (startDate: Date, endDate: Date) => {
  let coll: FbCollection<BillInfoTransfer> | FbQuery<BillInfoTransfer>;
  coll = FIRESTORE.COLLECTION_DRIVER_INVOICES();
  return coll

    .where("creation_date", ">=", startDate)
    .where("creation_date", "<=", endDate)
    .orderBy("creation_date", "asc");
};
