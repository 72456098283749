import { TaxableEntityType } from "./user-data";
import { FbDate, FbDocument, ScheduledTransfer } from "./transfer";
import { ModuleTransfer } from "./module-transfer";

// ----------------------- TRASLADO -----------------------
export interface SaleTransfer {
  /** Si está facturado de parte del conductor*/
  billed_from_driver?: BillStatus;
  /** Comentario (para registrar error) */
  billed_from_driver_comment?: string;
  /** Url en storage del pdf del voucher (descripción del viaje) */
  voucher?: string[];

  bill_info?: {
    missing?: boolean;
    pdf: string;
    creation_date: FbDate;
    ref: FbDocument<BillInfoTransfer>;
  };
  history?: {
    pdf: string;
    creation_date: FbDate;
    ref: FbDocument<BillInfoTransfer>;
  }[];
}

export interface BillInfoTransfer {
  canceled: boolean;
  expiration_date: FbDate;
  creation_date: FbDate;
  canceled_date?: FbDate;
  cancelation_cae?: string;
  /**
   * Traslados involucrados
   */
  transfers: FbDocument<ScheduledTransfer>[];
  /**
   * Número de voucher de nota de crédito si se cancelo la factura con una nota de crédito
   */
  deleted_vouche_number?: string;
  pdf: string;
  type: TIPOS_COMPROBANTES;
  /**
   * Punto de venta
   */
  point_of_sale: string;

  cuit: string;
  bill_code: string;
  tax_entity?: TaxableEntityType;
  ImpTotal: number;
  ImpTotConc: number;
  ImpNeto: number;
  ImpOpEx: number;
  ImpIVA: number;
  ImpTrib: number;
  voucher_number: string;
  amounts?: {
    transfer: number;
    tolls: number;
    wait_request: number;
    wait_reports: number;
    additional_R?: number;
    additional_M?: number;
    additional_G?: number;
    additional_K?: number;
    additional_E?: number;
    additional_C?: number;
    additional_5M?: number;
  };
  /**
   * Orden de pago
   */
  payment_order: FbDocument | null;
  /** último dígito del dni y uid del conductor (solo usado para filtros) */
  keywords: string[];
  /**
   * Uid conductor (solo usado para filtros)
   */
  driver: string;
  cae?: string;
  downloaded?: boolean;
  downloaded_NC?: boolean;
}

export interface PaymentOrderDriver {
  /**
   * Facturas involucradas
   */
  bills: FbDocument<BillInfoTransfer>[];
  /**
   * Conductor de los traslados
   */
  driver: string;
  bank_operation?: string;
  bank: SupportedBanks;
  sucursal: string;
  concept: string;
  concept2: string;
  type: TypePayment;
  keywords: string[];
  amount: number;
  creation_date: FbDate;
  orden_pago_int?: number;
}

// ----------------------- MODULO -----------------------
export interface SaleModuleTransfer {
  billed: BillStatus;
  voucher?: string;
  billed_comment?: string;
  billed_error_stack?: string;
  billed_from_drivers: BillStatus;
  bill_info?: {
    pdf: string;
    missing: boolean;
    creation_date: FbDate;
    ref: FbDocument<ArtBill>;
  };
  history?: {
    pdf: string;
    creation_date: FbDate;
    ref: FbDocument<ArtBill>;
  }[];
}

export class ArtBill {
  art!: string;
  creation_date!: FbDate;
  downloaded_NC?: boolean;
  downloaded_FV?: boolean;
  expiration_date!: FbDate;
  pdf!: string;
  voucher_number!: number;
  deleted_vouche_number?: string;
  payment_order!: FbDocument | null;
  type!: TIPOS_COMPROBANTES;
  point_of_sale!: string;
  cuit!: string;
  tax_entity?: TaxableEntityType;
  ImpTotal!: number;
  ImpTotConc!: number;
  ImpNeto!: number;
  ImpOpEx!: number;
  ImpIVA!: number;
  ImpTrib!: number;
  canceled?: boolean;
  canceled_date?: FbDate;
  modules!: {
    id_accident: string;
    id_module: string;
    vat_base: number;
    agency_commission: number;
    vat_commission: number;
    total: number;
    ImpTotConc: number;
    ImpNeto: number;
    ImpOpEx: number;
    ImpIVA: number;
    ImpTrib: number;
    base: number;
    tolls: number;
    additionalE?: number;
    ref: FbDocument<ModuleTransfer>;
    wait_time: number;
    extra_wait_time: number;
    Iva: AlicuotaIva[];
    bonif: number;
  }[];
  keywords!: string[];
  bonif?: number;
  cae?: string | undefined;
  cancelation_cae?: string;
  respuesta_api?: {
    status: number;
    texto: string;
  };
  debit_voucher_number?: number;
  debit_date?: FbDate;
  expiration_debit_date?: FbDate;
  debit_cae?: string;
}

export interface PaymentOrderArt {
  art_bills: FbDocument<ArtBill>[];
  art: string;
  bank_operation?: string;
  type: TypePayment;
  receipt?: string;
  creation_date: FbDate;
  amount: number;
  subtotal?: number;
  total?: number;
  retenciones?: any[];
  total_ret_IIBB?: number;
  ret_ganancias?: number | null;
  comprabante_RRGG?: string | null;
  fch_RRGG?: FbDate | null;
  orden_pago_int: number;
}

export enum TypePayment {
  "Transferencia Bancaria",
  "Cheque",
  "Efectivo",
}

export enum SupportedBanks {
  "Credicoop",
}

export enum TIPOS_COMPROBANTES {
  "FACTURA A" = 1,
  "FACTURA B" = 6,
  "FACTURA C" = 11,
  "Factura M" = 51,
  "Nota de Débito A" = 2,
  "Nota de Crédito A" = 3,
  "Nota de Débito B" = 7,
  "Nota de Crédito B" = 8,
  "Recibos A" = 4,
  "Recibos B" = 9,
  "Recibo C" = 15,
  "Notas de Venta al contado A" = 5,
  "Nota de Débito C" = 12,
  "Notas de Venta al contado B" = 10,
  "Liquidacion A" = 63,
  "Liquidacion B" = 64,
  "Cbtes. A del Anexo I, Apartado A,inc.f),R.G.Nro. 1415" = 34,
  "Cbtes. B del Anexo I,Apartado A,inc. f),R.G. Nro. 1415" = 35,
  "Otros comprobantes A que cumplan con R.G.Nro. 1415" = 39,
  "Otros comprobantes B que cumplan con R.G.Nro. 1415" = 40,
  "Cta de Vta y Liquido prod. A" = 60,
  "Cta de Vta y Liquido prod. B" = 61,
  "Nota de Crédito C" = 13,
  "Comprobante de Compra de Bienes Usados a Consumidor Final" = 49,
  "Nota de Débito M" = 52,
  "Nota de Crédito M" = 53,
  "Recibo M" = 54,
  "Factura de Crédito electrónica MiPyMEs (FCE) A" = 201,
  "Nota de Débito electrónica MiPyMEs (FCE) A" = 202,
  "Nota de Crédito electrónica MiPyMEs (FCE) A" = 203,
  "Factura de Crédito electrónica MiPyMEs (FCE) B" = 206,
  "Nota de Débito electrónica MiPyMEs (FCE) B" = 207,
  "Nota de Crédito electrónica MiPyMEs (FCE) B" = 208,
  "Factura de Crédito electrónica MiPyMEs (FCE) C" = 211,
  "Nota de Débito electrónica MiPyMEs (FCE) C" = 212,
  "Nota de Crédito electrónica MiPyMEs (FCE) C" = 213,
}

export enum BillStatus {
  /** Todavía no fue facturado */
  "No",
  /** Fue facturado */
  "Si",
  /** No fue facturado y no tiene que ser facturado */
  "No facturar",
  /** Hubo algún error al facturar */
  "Error al facturar",
  "Sin factura Cabify",
  /**
   * Facturación en espera de aprobación por ADMIN
   */
  "En revisión",
  "Facturando",
}

export enum ChallengeBilling {
  /**
   * Esperando a finalizar el traslado y decidir
   */
  "En espera",
  /**
   * En espera para ser facturado como desafío
   */
  "Facturar como Desafío",
  /**
   * En espera para ser facturado sin reglas del desafío
   */
  "Facturar normal",
  /**
   * Se facturó de acuerdo a las reglas del desafío
   */
  "Desafío facturado",
  /**
   * Se facturó normalmente sin reglas del desafío
   */
  "Facturado sin desafío",
}
export interface AlicuotaIva {
  /** Id del tipo de IVA (5 para 21%)(ver tipos disponibles) */
  Id: number;
  /** Base Imponible */
  BaseImp: number;
  Importe: number;
}
