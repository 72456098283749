import React, { useState, useEffect } from "react";
import { Modal, DropdownButton, Dropdown, Form } from "react-bootstrap";
import { Button, AsyncButton } from "components/styles";
import { LocalitySelector } from "components/Admin/Module_errors/view/wrong_cross";
import { InfoRow } from "components/Admin/Transfers/view/scheduled";
import moment from "moment";
import { DatePickerInput } from "components/input/date-picker";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { FIRESTORE } from "constants/firestore";
import { ValueRecord } from "types/cross";
import { FbDate } from "types/transfer";

export const EditCrossModal = ({
  idCross,
  show,
  handleClose,
  amounts,
}: {
  idCross: string;
  show: boolean;
  handleClose: () => void;
  amounts: {
    modulo: number;
    tramo: number;
    negativo: number;
    kmGoogle: number;
  };
}) => {
  const [currentDate, setCurrentDate] = useState<FbDate>();
  const [selected, setSelected] = useState<FbDate>();
  const [options, setOptions] = useState<ValueRecord[]>();
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf("month").subtract(1, "month").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().endOf("month").subtract(1, "month").toDate()
  );
  const [amountsModule, setAmountsModule] = useState<number>(0);
  const [amountsStretch, setAmountsStretch] = useState<number>(0);
  const [amountsNegative, setAmountsNegative] = useState<number>(0);
  const [amountsKmGoogle, setAmountsKmGoogle] = useState<number>(0);
  const [editPastPrice, setEditPastPrice] = useState<number>(0);

  useEffect(() => {
    setAmountsModule(amounts.modulo);
    setAmountsStretch(amounts.tramo);
    setAmountsNegative(amounts.negativo);
    setAmountsKmGoogle(amounts.kmGoogle);
  }, [amounts]);

  useEffect(() => {
    idCross &&
      FIRESTORE.DOCUMENT_CROSS_EXPERTA(idCross)
        .get()
        .then((doc) => doc.data())
        .then((data) => {
          setCurrentDate(data!.valid_from);
          setSelected(data!.valid_from);
          setOptions(data!.value_records);
        });
  }, [idCross]);

  const editingPastPrices = () => {
    if (editPastPrice === 0) {
      setEditPastPrice(1);
      setAmountsModule(0);
      setAmountsStretch(0);
      setAmountsNegative(0);
      setAmountsKmGoogle(0);
    } else {
      setEditPastPrice(0);
      setSelected(currentDate);
      setAmountsModule(amounts.modulo);
      setAmountsStretch(amounts.tramo);
      setAmountsNegative(amounts.negativo);
      setAmountsKmGoogle(amounts.kmGoogle);
    }
  };

  const submitChanges = async () => {
    console.log("LLego a 1");

    await CALLABLE_FUNCTIONS.ADMIN_EDIT_CROSS_DATABASE({
      modulo: amountsModule,
      negativo: amountsNegative,
      tramo: amountsStretch,
      id: idCross,
      date: selected,
      kmGoogle: amountsKmGoogle,
      editingPastPrices: editPastPrice === 0 ? false : true,
      raw_valid_from: startDate.valueOf(),
      raw_valid_to: endDate.valueOf(),
    });
    console.log("Llego a 2");

    handleClose();
  };

  const deleteSelectedDate = async () => {
    await CALLABLE_FUNCTIONS.ADMIN_EDIT_CROSS_DATABASE({
      id: idCross,
      date: selected,
    });
  };

  return currentDate ? (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{`Editar Cruce ${idCross}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {editPastPrice === 0 ? (
            <>
              <InfoRow
                label="Fecha"
                info={
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={
                      selected
                        ? moment(selected.toDate()).format("DD-MM-YY")
                        : moment(currentDate.toDate()).format("DD-MM-YY")
                    }
                  >
                    <Dropdown.Item
                      name={moment(currentDate.toDate()).format("DD-MM-YY")}
                      onClick={() => {
                        setSelected(currentDate);
                        setAmountsModule(amounts.modulo);
                        setAmountsStretch(amounts.tramo);
                        setAmountsNegative(amounts.negativo);
                        setAmountsKmGoogle(amounts.kmGoogle);
                      }}
                    >
                      {"Desde " +
                        moment(currentDate.toDate()).format("DD-MM-YY")}
                    </Dropdown.Item>
                    {options?.map((option) => (
                      <Dropdown.Item
                        onClick={() => {
                          setSelected(option.valid_from);
                          setAmountsModule(option.modulo);
                          setAmountsStretch(option.tramo);
                          setAmountsNegative(option.negativo);
                        }}
                        name={moment(option.valid_from.toDate()).format(
                          "DD-MM-YY"
                        )}
                      >
                        {moment(option.valid_from.toDate()).format("DD-MM-YY") +
                          " al " +
                          moment(option.valid_to.toDate()).format("DD-MM-YY")}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                }
              />
              <br />
            </>
          ) : (
            <>
              <InfoRow
                label="Válido desde"
                info={
                  <DatePickerInput
                    onChange={(e: any) => {
                      setStartDate(moment(e).toDate());
                    }}
                    value={startDate}
                  />
                }
              />
              <br />
              <InfoRow
                label="Válido hasta"
                info={
                  <DatePickerInput
                    onChange={(e: any) => {
                      setEndDate(moment(e).toDate());
                    }}
                    value={endDate}
                  />
                }
              />
              <br />
            </>
          )}
          <InfoRow
            label="Monto Módulo"
            info={
              <input
                value={amountsModule}
                placeholder="Monto Módulo"
                onChange={(e) => setAmountsModule(Number(e.target.value))}
                type="number"
              />
            }
          />
          <br />
          <InfoRow
            label="Monto Tramo"
            info={
              <input
                value={amountsStretch}
                placeholder="Monto Tramo"
                onChange={(e) => setAmountsStretch(Number(e.target.value))}
                type="number"
              />
            }
          />
          <br />
          <InfoRow
            label="Monto Negativo"
            info={
              <input
                value={amountsNegative}
                placeholder="Monto Negativo"
                onChange={(e) => setAmountsNegative(Number(e.target.value))}
                type="number"
              />
            }
          />
          <br />
          {editPastPrice === 0 && (
            <>
              <InfoRow
                label="Km Google"
                info={
                  <input
                    value={amountsKmGoogle}
                    placeholder="Km Google"
                    onChange={(e) => setAmountsKmGoogle(Number(e.target.value))}
                    type="number"
                  />
                }
              />
              <br />
            </>
          )}
          <InfoRow
            label="Agregar Precios pasado"
            info={
              <input
                value={editPastPrice}
                onChange={editingPastPrices}
                type="checkbox"
              />
            }
          />
        </>
      </Modal.Body>
      <Modal.Footer>
        <AsyncButton
          disabled={selected === currentDate || editPastPrice === 1}
          variant="danger"
          onClick={deleteSelectedDate}
        >
          Eliminar fecha
        </AsyncButton>
        <AsyncButton variant="success" onClick={submitChanges}>
          Confirmar cambios
        </AsyncButton>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <></>
  );
};

export const AddNewCrossModal = ({
  show,
  handleClose,
}: {
  show: boolean;
  handleClose: () => void;
}) => {
  const [amountsModule, setAmountsModule] = useState<number>(0);
  const [amountsStretch, setAmountsStretch] = useState<number>(0);
  const [amountsNegative, setAmountsNegative] = useState<number>(0);
  const [kmGoogle, setKmGoogle] = useState<number>(0);

  const [startDate, setStartDate] = useState<Date>(
    moment().startOf("month").toDate()
  );
  const [originSelected, setOriginSelected] = useState<string | undefined>(
    undefined
  );
  const [destinationSelected, setDestinationSelected] = useState<
    string | undefined
  >(undefined);
  const [dataOrigin, setDataOrigin] = useState<
    | {
        description: string;
        id: string;
        province: {
          description: string;
          id: string;
        };
      }
    | undefined
  >(undefined);
  const [dataDestination, setDataDestination] = useState<
    | {
        description: string;
        id: string;
        province: {
          description: string;
          id: string;
        };
      }
    | undefined
  >(undefined);
  const closeAndReset = () => {
    setOriginSelected(undefined);
    setDestinationSelected(undefined);
    setDataOrigin(undefined);
    setDataDestination(undefined);
    setAmountsModule(0);
    setAmountsStretch(0);
    setAmountsNegative(0);
    setKmGoogle(0);
    handleClose();
  };

  const submitCross = async () => {
    const data = {
      "ID ciudad origen": dataOrigin!.id,
      "ID ciudad destino": dataDestination!.id,
      "ciudad origen": dataOrigin!.description,
      "ciudad destino": dataDestination!.description,
      "ID provincia destino": dataDestination!.province.id,
      "ID provincia origen": dataOrigin!.province.id,
      "provincia destino": dataDestination!.province.description.toLowerCase(),
      "provincia origen": dataOrigin!.province.description.toLowerCase(),
      index: [
        `${dataOrigin!.id}-${dataDestination!.id}`,
        `${dataDestination!.id}-${dataOrigin!.id}`,
      ],
      keywords: [],
      valid_from: startDate.valueOf(),
      modulo: amountsModule,
      negativo: amountsNegative,
      tramo: amountsStretch,
      "Km Google": kmGoogle,
    };

    console.log(data);
    await CALLABLE_FUNCTIONS.ADMIN_ADD_CROSS_DATABASE(data);
    closeAndReset();
  };

  return (
    <Modal show={show} onHide={closeAndReset} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Agregar Cruce</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <InfoRow
            label="Nuevo Cruce Origen"
            info={
              <LocalitySelector
                localitySelected={originSelected}
                setLocalitySelected={setOriginSelected}
                setDataSelected={setDataOrigin}
              />
            }
          />

          <InfoRow
            label="Nuevo Cruce Destino"
            info={
              <LocalitySelector
                localitySelected={destinationSelected}
                setLocalitySelected={setDestinationSelected}
                setDataSelected={setDataDestination}
              />
            }
          />
          <InfoRow
            label="Válido desde"
            info={
              <DatePickerInput
                onChange={(e: any) => {
                  setStartDate(moment(e).toDate());
                }}
                value={startDate}
              />
            }
          />
          <br />
          <InfoRow
            label="Monto Módulo"
            info={
              <input
                placeholder="Monto Módulo"
                onChange={(e) => setAmountsModule(Number(e.target.value))}
                type="number"
              />
            }
          />
          <br />
          <InfoRow
            label="Monto Tramo"
            info={
              <input
                placeholder="Monto Tramo"
                onChange={(e) => setAmountsStretch(Number(e.target.value))}
                type="number"
              />
            }
          />
          <br />
          <InfoRow
            label="Monto Negativo"
            info={
              <input
                placeholder="Monto Negativo"
                onChange={(e) => setAmountsNegative(Number(e.target.value))}
                type="number"
              />
            }
          />
          <br />
          <InfoRow
            label="Km Google"
            info={
              <input
                placeholder="Km Google"
                onChange={(e) => setKmGoogle(Number(e.target.value))}
                type="number"
              />
            }
          />
        </>
      </Modal.Body>
      <Modal.Footer>
        <AsyncButton
          onClick={submitCross}
          disabled={!(originSelected && destinationSelected)}
        >
          Agregar nuevo cruce
        </AsyncButton>
        <Button variant="secondary" onClick={closeAndReset}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const AdjustPriceModal = ({
  idCross,
  show,
  handleClose,
}: {
  idCross: string;
  show: boolean;
  handleClose: () => void;
}) => {
  const [percentage, setPercentage] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf("day").toDate()
  );
  const adjustPrices = async () => {
    const endDate = FbDate.fromDate(
      moment(startDate).subtract(1, "day").endOf("day").toDate()
    );
    await CALLABLE_FUNCTIONS.ADMIN_ADJUST_CROSS_PRICES({
      percentage,
      id: idCross,
      date: FbDate.fromDate(startDate),
      endDate,
    });
  };
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Ajustar Precios FUTUROS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <InfoRow
            label="Porcentaje (%)"
            info={
              <Form.Control
                type="number"
                onChange={(e) => setPercentage(Number(e.target.value))}
              />
            }
          />

          <InfoRow
            label="Válido desde"
            info={
              <DatePickerInput
                onChange={(e: any) => {
                  setStartDate(moment(e).toDate());
                }}
                value={startDate}
              />
            }
          />
          <br />
        </>
      </Modal.Body>
      <Modal.Footer>
        <AsyncButton
          onClick={async () => {
            await adjustPrices();
            handleClose();
          }}
        >
          Confirmar Cambio
        </AsyncButton>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
