import { faBan, faCheck, faCheckCircle, faWheelchair } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Center, Container, H1, Padding, Spinner } from 'components/styles';
import ROUTES from 'constants/routes';
import { uidSelector } from 'controllers/auth';
import { useDbDocument } from 'controllers/hooks';
import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Car } from 'types/car';
import { Revision, RevisionStatus } from 'types/user-data';

const UserCarsViewPage = (props:any) => {
    const uid = useSelector(uidSelector)
    const { idCar } = props.match.params
    const car = useDbDocument<Car>(`user/${uid}/car`, idCar)
    const revision = useDbDocument<Revision>(`user/${uid}/car_revision`, idCar)

    if(!isLoaded(revision, car))
        return <Spinner />
    if(!car || !revision)
        return <></>
    return(
    <Padding pad="5px">
        {revision?.status === RevisionStatus.Desaprobado && <Alert variant='danger'>{revision?.comment_revised}</Alert>}
        <Container>
            <Row style={{alignItems:"center"}}>
                <Col>
                    <SpanLeft>
                        <H1>
                            {<FontAwesomeIcon color="green" icon={faCheckCircle}/>}
                            <span> {car.brand + " " + car.model}</span>
                        </H1>
                    </SpanLeft>
                </Col>
                <Col>
                    <H1>
                    <Center>
                        {car.wheelchair ? <FontAwesomeIcon icon={faWheelchair}/> :
                        <div style={{display: "inline-block",
                            position: "relative",
                            height: "2em",
                            width: "2em",
                            lineHeight: "2em"}}>
                            <FontAwesomeIcon style={{position:"absolute", fontSize:"2em", width:"100%"}} color="rgb(189, 70, 48)" icon={faBan}/>
                            <FontAwesomeIcon style={{position:"absolute", top:"16px", width:"100%"}} icon={faWheelchair}/>
                        </div>
                        }
                    </Center>
                    </H1>
                </Col>
                <Col>
                    <SpanRight>
                        <Row>
                            <Col>
                                <Plate country="Argentina" licence={car.licence}/>
                            </Col>
                            <Col>
                                <Link to={ROUTES.USER_CARS_ADD_OR_EDIT(idCar)}>
                                    <Button variant='info'>Editar</Button>
                                </Link>
                            </Col>
                        </Row>
                    </SpanRight>
                </Col>
            </Row>
            <hr style={{marginTop:"5px"}}/>
            <div style={{padding: "5px 2px", margin: "5px 0px"}}>
                <small>Año: <b>{car.year}</b> - Color: <b>{car.color}</b> - Motor: <b>{car.engine}</b></small>
            </div>
            <DataContainer>
                <Row>
                <Col md={4}>
                    <HeaderCard>
                        <span>Datos del seguro</span>
                    </HeaderCard>
                    <BodyCard>
                        <Row>
                            <Col>
                                <Label>Fecha de vencimiento</Label>
                                <div><Moment date={car.insurance_expiration_date.toDate()} format="DD/MM/YYYY" /></div>
                            </Col>
                            <Col>
                                <Label>Aseguradora</Label>
                                <div>{car.insurance_company}</div>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col>
                                <Label>Número Poliza Seguro</Label>
                                <div>{car.policy_number}</div>
                            </Col>
                            <Col>
                                <Label>Foto Poliza de Seguro</Label>
                                <div><FontAwesomeIcon icon={faCheck} /></div>
                            </Col>
                        </Row>
                    </BodyCard>
                </Col>
                <Col md={4}>
                    <HeaderCard>
                        <span>Verificación Técnica Vehicular</span>
                    </HeaderCard>
                    <BodyCard>
                        <Row>
                            <Col>
                                <Label>Fecha Vencimiento</Label>
                                {car.vtv_expiration_date ? <div><Moment date={car.vtv_expiration_date.toDate()} format="DD/MM/YYYY" /></div> : <div>Aún no se ha subido la fecha de vencimiento.</div>}
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col>
                                <Label>Foto VTV</Label>
                                <div><FontAwesomeIcon icon={faCheck} /></div>
                            </Col>
                        </Row>
                    </BodyCard>
                </Col>
                <Col>
                    <HeaderCard>
                        <span>Cédula Verde</span>
                    </HeaderCard>
                    <BodyCard>
                        <Row>
                            <Label>Foto Cedula verde (frente)</Label>
                            <div><FontAwesomeIcon icon={faCheck} /></div>
                        </Row>
                        <Row>   
                            <Label>Foto Cedula verde (dorso)</Label>
                            <div><FontAwesomeIcon icon={faCheck} /></div>
                        </Row>
                    </BodyCard>
                </Col>
                <Col>
                    <HeaderCard>
                        <span>Cédula Azul</span>
                    </HeaderCard>
                    <BodyCard>
                        <Row>
                            <Label>Foto Cedula verde (frente)</Label>
                            <div><FontAwesomeIcon icon={faCheck} /></div>
                        </Row>
                        <Row>
                            <Label>Foto Cedula verde (dorso)</Label>
                            <div><FontAwesomeIcon icon={faCheck} /></div>
                        </Row>
                    </BodyCard>
                </Col>
                </Row>
            </DataContainer>
        </Container>
    </Padding>
)}

const Label = styled.label`
    color: #888;
    font-size: 12px !important;
`
const HeaderCard = styled.div`
    padding: 18px 20px;
    border-bottom: 1px solid #ebeef5;

`
const BodyCard = styled.div`
    padding: 20px;
`
const DataContainer = styled(Container)`
    box-shadow: 2px 12px 0 rgba(0,0,0,0.1);
    -webkit-box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
    background-color: #fff;
    color: #303133;
`
const SpanMiddle = styled.div`
    float:left;
    
`
const SpanLeft = styled.div`
    float:left;
    margin-right: 10px;
`
const SpanRight = styled.div`
    float:right;
    margin-left: 30px;
`

const Plate = ({licence, country}:{licence : string, country : string}) => {
    if(licence.length === 6)
        licence = licence.substring(0,3).toUpperCase()+' '+licence.substring(3)
    else //ES LICENCIA NUEVA (AA 123 AA)
        licence = licence.substring(0,2).toUpperCase()+' '+licence.substring(2,5)+' '+licence.substring(5).toUpperCase()

    return(
    <div style={{width:"8rem"}}>
        <TitlePlate>{country}</TitlePlate>
        <BodyPlate>{licence}</BodyPlate>
    </div>
)}

const BodyPlate = styled.div`
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    align-items: center;
    border: solid #ccc 1px;
    background: repeating-linear-gradient(-45deg, #fff, #fff 5px, #ECECEC 5px, #ECECEC 10px);
    padding: 0px 5px 0px 5px;
`
const TitlePlate = styled.div`
    background-color: #174D9B;
    border: solid #174D9B 1px;
    border-bottom: none;
    border-radius: 3px 3px 0px 0px;
    color: #f1f1f1;
    font-size: 9px;
    text-align: center;
    padding: 2px 0px;
`

export default UserCarsViewPage;