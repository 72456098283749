//import { Monoid } from './Monoid'

export interface Monoid<T> {
    id : T,
    prod : (a : T, b : T) => T
}


declare type monoidPromise = Monoid<Promise<void>>

export const monoidPromise : monoidPromise = {
    id: new Promise(()=>{}),
    prod: (a : Promise<void>, b : Promise<void>) => {
        return Promise.all([a, b]).then()
    }
}


export class Maybe<T> {
    constructor(_val : T | undefined | null | void = undefined) {
        this.val = _val
    }

    private val : T | undefined | null | void
    
    static just = <S extends unknown>(val : S | undefined | null | void) : Maybe<S> => {
        return new Maybe(val)
    }

    map = <S extends unknown>(f : (val: T) => S | undefined | null | void): Maybe<S> => (
        this.isNothing()?
            Maybe.nothing()
        :   Maybe.just(f(this.val as T))
    )

    chain = <S extends unknown>(f : (val: T) => Maybe<S>): Maybe<S> => (
        Maybe.join(this.map(f))
    )

    static nothing = <S extends unknown> () : Maybe<S> => new Maybe()

    isNothing = () : boolean => {
        return this.val === undefined || this.val === null
    }

    getOrElse = (onNothing : T) : T => (
        this.isNothing()?
            onNothing
        :   this.val as T
    )

    getPropOrElse = <S extends keyof T>(key : S, onNothing : T[S]) : T[S] => (
        this.map((value : T) => value[key])
            .getOrElse(onNothing)
    )

    getProp = <S extends keyof T>(key : S) : Maybe<T[S]> => (
        this
            .map((value : T) => value[key])
    )

    foldMap = <S extends unknown> (f: (a : T) => S) : ((b : Monoid<S>) => S) => 
    (b : Monoid<S>) => (
        this.map(f).getOrElse(b.id)
    )

    static commutePromise = <T extends unknown>(maybePromise: Maybe<Promise<T>>): Promise<Maybe<T>> => (
        maybePromise
        .map(promise => promise.then(Maybe.just))
        .getOrElse(Promise.resolve(Maybe.nothing()))
    )

    static commuteArray = <T extends unknown>(maybeArray: Maybe<Array<T>>) : Array<Maybe<T>> => (
        maybeArray
        .map(array => array.map(Maybe.just))
        .getOrElse([])
    ) 

    static join = <T extends unknown>(MMa : Maybe<Maybe<T>>) : Maybe<T> => (
        MMa.isNothing()?
            Maybe.nothing()
        :   MMa.val as Maybe<T>
    )

    toArray = () : Array<T> => (
        this.map(a => [a]).getOrElse([])
    )
}
