import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { Center, H1, Spinner, Button } from "components/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { faChevronRight, faCircle, faChevronLeft, faCheck } from "@fortawesome/free-solid-svg-icons"
import { Pages, StepsFormNewcomers } from "../pages"
import { faCircle as faCircleRegular } from '@fortawesome/free-regular-svg-icons'
import { useSelector, useDispatch } from "react-redux"
import { isSubmitting, submit } from "redux-form"

export const Navigation = ({title, setPage, lastPageCompleted, activePage, first, last} : any) => {
    const pagesName = Pages.map(v => v.title)
    return (
    <>
        <Header className='d-none d-lg-block'> 
            <Row>
                {pagesName.map( (v,index) => 
                    <PageIndicator 
                        setPage={setPage}
                        lastPageCompleted={lastPageCompleted}
                        name={v} 
                        index={index}
                        activeIndex={activePage}
                        last={index+1===Pages.length}
                        key={v}/>
                )}
            </Row>  
        </Header>
        <ActionsRow 
            title={title} 
            first={first} 
            last={last}
            activePage={activePage}
            setPage={setPage}/>
    </>
)}

const ActionsRow = ({title, first, last, activePage, setPage}:any) => {
    const dispatch = useDispatch()
    const _isSubmitting = useSelector(isSubmitting("NewcomersForm"))
    const hasNextButton = !last && activePage !== StepsFormNewcomers.terms_conditions && activePage !== StepsFormNewcomers.quiz 

    return (
    <div style={{padding: "10px 20px 10px 20px", backgroundColor: "#f4f4f4"}}>
        <Row style={{marginBottom:1}}>
            <Col lg={{span:4, order:1}} xs={{span:6, order:2}}>
                {!first && 
                <Button className="pull-left" variant="secondary" onClick={() => setPage(activePage-1)} style={{minWidth:120, minHeight:40}}>
                    <FontAwesomeIcon icon={faChevronLeft}/> ANTERIOR
                </Button>}
            </Col>
            <Col lg={{span:4, order:2}} xs={{span:12,order:1}}>
                <Center><H1>{title}</H1></Center>
            </Col>
            <Col lg={{span:4, order:3}} xs={{span:6,order:3}}>
                {hasNextButton &&
                    <Button onClick={() => dispatch(submit("NewcomersForm"))} className="pull-right" variant="info" style={{minWidth:120, minHeight:40}}>
                        {_isSubmitting ? <Spinner style={{margin:0}} size="sm"/>
                        : <>SIGUIENTE <FontAwesomeIcon icon={faChevronRight}/></>}
                    </Button>}
            </Col>
        </Row>
        <hr style={{margin:"0px"}}/>
    </div>
)}

const PageIndicator = ({setPage, lastPageCompleted, name, index, activeIndex, last} : 
        {setPage:(a:number)=>void, lastPageCompleted:number, name:string, index:number, activeIndex:number, last:Boolean}) => (
    <Col md={3}>
        <Row style={{paddingBottom: "10px"}}>
            <Col xs={10}>
                <ButtonPage block style={{}} disabled={lastPageCompleted < index} onClick={() => setPage(index)}>
                    <Row>
                    <Col md={1}>
                        <FontAwesomeIcon 
                            style={{height:'100%'}}
                            icon={activeIndex === index ? faCircle : index > lastPageCompleted ? faCircleRegular : faCheck}/>
                    </Col>
                    <Col md={10} style={{textAlign:"center"}}>
                        <b>{name}</b>
                    </Col>
                    </Row>
                
                </ButtonPage>
            </Col>
            {!last && 
            <Col xs={1}>
                <FontAwesomeIcon style={{height:'100%'}} icon={faChevronRight}/>
            </Col>
            }
        </Row>
    </Col>
)

const ButtonPage = styled(Button)`
    -webkit-box-shadow: -1px 2px 11px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 2px 11px -8px rgba(0,0,0,0.75);
    box-shadow: -1px 2px 11px -8px rgba(0,0,0,0.75);
    min-height:65px; 
    color:white;
`

const Header = styled(Container)`
    background-color: #eee;
    padding: 15px 60px 10px 60px;
    border-radius: 4px 4px 0px 0px;
`
