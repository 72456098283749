import firebase from "firebase/app";
import "firebase/firestore";
import _ from "lodash";
import { FinishStatus, TransferStage } from "types/transfer";
import { FIRESTORE } from "constants/firestore";

export const getModulesByType = (type: number, asc?: boolean) =>
  !asc
    ? _getModulesByType(type)
        .orderBy("destination.time", "desc")
        .orderBy("origin.time", "desc")
        .orderBy("destination.geohash", "desc")
    : _getModulesByType(type)
        .orderBy("destination.time", "asc")
        .orderBy("origin.time", "asc")
        .orderBy("destination.geohash", "asc");

const _getModulesByType = (type: number) => {
  const collection = FIRESTORE.COLLECTION_MODULES_TRANSFER();
  console.log(collection, "Colection");

  console.log("getModulesByType");
  if (type === 0) return collection;

  if (type === 1)
    return collection.where("status.stage", "in", [
      TransferStage.searching_driver,
      TransferStage.searching_driver_return,
      TransferStage.searching_driver_incident,
    ]); //FIXME:

  if (type === 2)
    //"Asignado": tiene conductor designado
    return collection.where("status.stage", "in", [
      TransferStage.scheduled,
      TransferStage.going_to_meeting_point,
      TransferStage.origin_to_destination,
      TransferStage.return_requested,
      TransferStage.waiting_return_request,
      TransferStage.waiting_on_meeting_point,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.destination_to_origin,
    ]);

  if (type === 3)
    //"Un tramo finalizado": la ida fue realizada o Negativo, queda pendiente el Retorno ( caso Modulo con o sin espera)
    return collection.where("status.stage", "in", [
      TransferStage.waiting_return_request,
      TransferStage.return_requested,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.searching_driver_return,
      TransferStage.destination_to_origin,
      TransferStage.canceled_on_waiting_return_request,
    ]);

  if (type === 4)
    //"Iniciado": el conductor está en camino al punto de origen
    return collection.where(
      "status.stage",
      "==",
      TransferStage.going_to_meeting_point
    );

  if (type === 5)
    //"En Origen"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_on_meeting_point
    );

  if (type === 6)
    //"En Viaje"
    return collection.where("status.stage", "in", [
      TransferStage.origin_to_destination,
      TransferStage.destination_to_origin,
    ]);

  if (type === 7)
    //"En Espera"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_return_request
    );

  if (type === 8)
    //"Retorno solicitado": para módulos Con Espera
    return collection.where(
      "status.stage",
      "==",
      TransferStage.return_requested
    );

  if (type === 9)
    //"Informado": durante los 15 minutos de espera de respuesta de la ART
    return collection
      .where("status.active_report", "==", true)
      .where("status.stage", "<=", TransferStage.finished)
      .orderBy("status.stage");

  if (type === 10)
    //"Finalizado": se cumplieron los tramos de ida y/o retorno normalmente
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Exitoso);

  if (type === 11)
    //"Negativo": finalizados negativos
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", false);

  if (type === 12)
    // "Finalizado, Retorno Negativo"
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", true);

  if (type === 13)
    //"Cancelado": la ART lo canceló o Satapp informa a la ART que no lo va a poder realizar
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Cancelado);

  if (type === 14)
    //"Esperando accion": Esperando que el admin ACCIONE
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_admin_action
    );

  throw new Error("Mal transfer type");
};

export const getCollectionSearchByKeyWords = (word: string, asc?: boolean) =>
  !asc
    ? FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where("keywords", "array-contains", word)
        .orderBy("destination.time", "desc")
    : FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where("keywords", "array-contains", word)
        .orderBy("destination.time", "asc");

export const getCollectionByDate = (d: Date, d2: Date, asc?: boolean) =>
  asc
    ? FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where(
          "destination.time",
          "<=",
          firebase.firestore.Timestamp.fromDate(d)
        )
        .where(
          "destination.time",
          ">=",
          firebase.firestore.Timestamp.fromDate(d2)
        )
        .orderBy("destination.time", "asc")
    : FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where(
          "destination.time",
          "<=",
          firebase.firestore.Timestamp.fromDate(d)
        )
        .where(
          "destination.time",
          ">=",
          firebase.firestore.Timestamp.fromDate(d2)
        )
        .orderBy("destination.time", "desc");

export const getCollectionByDateKeyWords = (
  d: Date,
  d2: Date,
  word: string,
  asc?: boolean
) => {
  console.log("getCollectionByDateKeyWords");
  if (asc) {
    return FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("destination.time", "<=", firebase.firestore.Timestamp.fromDate(d))
      .where(
        "destination.time",
        ">=",
        firebase.firestore.Timestamp.fromDate(d2)
      )
      .where("keywords", "array-contains", word)
      .orderBy("destination.time", "asc");
  } else {
    return FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("destination.time", "<=", firebase.firestore.Timestamp.fromDate(d))
      .where(
        "destination.time",
        ">=",
        firebase.firestore.Timestamp.fromDate(d2)
      )
      .where("keywords", "array-contains", word)
      .orderBy("destination.time", "desc");
  }
};

export const getCollectionByDateKeyWordsFilter = (
  d: Date,
  d2: Date,
  word: string,
  type: number,
  zone: string,
  asc?: boolean
) => {
  let collection = FIRESTORE.COLLECTION_MODULES_TRANSFER()
    .where("destination.time", "<=", firebase.firestore.Timestamp.fromDate(d))
    .where("destination.time", ">=", firebase.firestore.Timestamp.fromDate(d2))
    .where("keywords", "array-contains", word)
    .where("info.zona", "==", zone);
  collection = !asc
    ? collection.orderBy("destination.time", "desc")
    : collection.orderBy("destination.time", "asc");

  console.log("getCollectionByDateKeyWordsFilter!");
  if (type === 0) return collection;

  if (type === 1)
    return collection.where("status.stage", "in", [
      TransferStage.searching_driver,
      TransferStage.searching_driver_return,
      TransferStage.searching_driver_incident,
    ]); //FIXME:

  if (type === 2)
    //"Asignado": tiene conductor designado
    return collection.where("status.stage", "in", [
      TransferStage.scheduled,
      TransferStage.going_to_meeting_point,
      TransferStage.origin_to_destination,
      TransferStage.return_requested,
      TransferStage.waiting_return_request,
      TransferStage.waiting_on_meeting_point,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.destination_to_origin,
    ]);

  if (type === 3)
    //"Un tramo finalizado": la ida fue realizada o Negativo, queda pendiente el Retorno ( caso Modulo con o sin espera)
    return collection.where("status.stage", "in", [
      TransferStage.waiting_return_request,
      TransferStage.return_requested,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.searching_driver_return,
      TransferStage.destination_to_origin,
      TransferStage.canceled_on_waiting_return_request,
    ]);

  if (type === 4)
    //"Iniciado": el conductor está en camino al punto de origen
    return collection.where(
      "status.stage",
      "==",
      TransferStage.going_to_meeting_point
    );

  if (type === 5)
    //"En Origen"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_on_meeting_point
    );

  if (type === 6)
    //"En Viaje"
    return collection.where("status.stage", "in", [
      TransferStage.origin_to_destination,
      TransferStage.destination_to_origin,
    ]);

  console.log("ESTOY ACÁ!");

  if (type === 7)
    //"En Espera"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_return_request
    );

  if (type === 8)
    //"Retorno solicitado": para módulos Con Espera
    return collection.where(
      "status.stage",
      "==",
      TransferStage.return_requested
    );

  if (type === 9)
    //"Informado": durante los 15 minutos de espera de respuesta de la ART
    return collection
      .where("status.active_report", "==", true)
      .where("status.stage", "<=", TransferStage.finished)
      .orderBy("status.stage");

  if (type === 10)
    //"Finalizado": se cumplieron los tramos de ida y/o retorno normalmente
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Exitoso);

  if (type === 11)
    //"Negativo": finalizados negativos
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", false);

  if (type === 12)
    // "Finalizado, Retorno Negativo"
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", true);

  if (type === 13)
    //"Cancelado": la ART lo canceló o Satapp informa a la ART que no lo va a poder realizar
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Cancelado);

  if (type === 14)
    //"Esperando accion": Esperando que el admin ACCIONE
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_admin_action
    );

  throw new Error("Mal transfer type");
};

export const getCollectionByDateFilter = (
  d: Date,
  d2: Date,
  type: number,
  asc?: boolean
) => {
  let collection = FIRESTORE.COLLECTION_MODULES_TRANSFER()
    .where("destination.time", "<=", firebase.firestore.Timestamp.fromDate(d))
    .where("destination.time", ">=", firebase.firestore.Timestamp.fromDate(d2));
  asc
    ? (collection = collection.orderBy("destination.time", "asc"))
    : (collection = collection.orderBy("destination.time", "desc"));

  if (type === 0) return collection;

  if (type === 1)
    return collection.where("status.stage", "in", [
      TransferStage.searching_driver,
      TransferStage.searching_driver_return,
      TransferStage.searching_driver_incident,
    ]); //FIXME:

  if (type === 2)
    //"Asignado": tiene conductor designado
    return collection.where("status.stage", "in", [
      TransferStage.scheduled,
      TransferStage.going_to_meeting_point,
      TransferStage.origin_to_destination,
      TransferStage.return_requested,
      TransferStage.waiting_return_request,
      TransferStage.waiting_on_meeting_point,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.destination_to_origin,
    ]);

  if (type === 3)
    //"Un tramo finalizado": la ida fue realizada o Negativo, queda pendiente el Retorno ( caso Modulo con o sin espera)
    return collection.where("status.stage", "in", [
      TransferStage.waiting_return_request,
      TransferStage.return_requested,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.searching_driver_return,
      TransferStage.destination_to_origin,
      TransferStage.canceled_on_waiting_return_request,
    ]);

  if (type === 4)
    //"Iniciado": el conductor está en camino al punto de origen
    return collection.where(
      "status.stage",
      "==",
      TransferStage.going_to_meeting_point
    );

  if (type === 5)
    //"En Origen"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_on_meeting_point
    );

  if (type === 6)
    //"En Viaje"
    return collection.where("status.stage", "in", [
      TransferStage.origin_to_destination,
      TransferStage.destination_to_origin,
    ]);

  if (type === 7)
    //"En Espera"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_return_request
    );

  if (type === 8)
    //"Retorno solicitado": para módulos Con Espera
    return collection.where(
      "status.stage",
      "==",
      TransferStage.return_requested
    );

  if (type === 9)
    //"Informado": durante los 15 minutos de espera de respuesta de la ART
    return collection
      .where("status.active_report", "==", true)
      .where("status.stage", "==", TransferStage.finished);

  if (type === 10)
    //"Finalizado": se cumplieron los tramos de ida y/o retorno normalmente
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Exitoso);

  if (type === 11)
    //"Negativo": finalizados negativos
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", false);

  if (type === 12)
    // "Finalizado, Retorno Negativo"
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", true);

  if (type === 13)
    //"Cancelado": la ART lo canceló o Satapp informa a la ART que no lo va a poder realizar
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Cancelado);

  if (type === 14)
    //"Esperando accion": Esperando que el admin ACCIONE
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_admin_action
    );

  throw new Error("Mal transfer type");
};

export const getCollectionByKeyWordsFilter = (
  word: string,
  type: number,
  asc?: boolean
) => {
  console.log("getCollectionByKeyWordsFilter");
  let collection = FIRESTORE.COLLECTION_MODULES_TRANSFER().where(
    "keywords",
    "array-contains",
    word.trim()
  );
  asc
    ? (collection = collection.orderBy("destination.time", "asc"))
    : (collection = collection.orderBy("destination.time", "desc"));

  if (type === 0) return collection;

  if (type === 1)
    return collection.where("status.stage", "in", [
      TransferStage.searching_driver,
      TransferStage.searching_driver_return,
      TransferStage.searching_driver_incident,
    ]); //FIXME:

  if (type === 2)
    //"Asignado": tiene conductor designado
    return collection.where("status.stage", "in", [
      TransferStage.scheduled,
      TransferStage.going_to_meeting_point,
      TransferStage.origin_to_destination,
      TransferStage.return_requested,
      TransferStage.waiting_return_request,
      TransferStage.waiting_on_meeting_point,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.destination_to_origin,
    ]);

  if (type === 3)
    //"Un tramo finalizado": la ida fue realizada o Negativo, queda pendiente el Retorno ( caso Modulo con o sin espera)
    return collection.where("status.stage", "in", [
      TransferStage.waiting_return_request,
      TransferStage.return_requested,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.searching_driver_return,
      TransferStage.destination_to_origin,
      TransferStage.canceled_on_waiting_return_request,
    ]);

  if (type === 4)
    //"Iniciado": el conductor está en camino al punto de origen
    return collection.where(
      "status.stage",
      "==",
      TransferStage.going_to_meeting_point
    );

  if (type === 5)
    //"En Origen"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_on_meeting_point
    );

  if (type === 6)
    //"En Viaje"
    return collection.where("status.stage", "in", [
      TransferStage.origin_to_destination,
      TransferStage.destination_to_origin,
    ]);

  if (type === 7)
    //"En Espera"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_return_request
    );

  if (type === 8)
    //"Retorno solicitado": para módulos Con Espera
    return collection.where(
      "status.stage",
      "==",
      TransferStage.return_requested
    );

  if (type === 9)
    //"Informado": durante los 15 minutos de espera de respuesta de la ART
    return collection
      .where("status.active_report", "==", true)
      .where("status.stage", "<=", TransferStage.finished)
      .orderBy("status.stage");

  if (type === 10)
    //"Finalizado": se cumplieron los tramos de ida y/o retorno normalmente
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Exitoso);

  if (type === 11)
    //"Negativo": finalizados negativos
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", false);

  if (type === 12)
    // "Finalizado, Retorno Negativo"
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", true);

  if (type === 13)
    //"Cancelado": la ART lo canceló o Satapp informa a la ART que no lo va a poder realizar
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Cancelado);

  if (type === 14)
    //"Esperando accion": Esperando que el admin ACCIONE
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_admin_action
    );

  throw new Error("Mal transfer type");
};

export const getCollectionByDateKeyZoneFilter = (
  d: Date,
  d2: Date,
  type: number,
  zone: string,
  asc?: boolean
) => {
  let collection = FIRESTORE.COLLECTION_MODULES_TRANSFER()
    .where("destination.time", "<=", firebase.firestore.Timestamp.fromDate(d))
    .where("destination.time", ">=", firebase.firestore.Timestamp.fromDate(d2))
    .where("info.zona", "==", zone);
  collection = !asc
    ? collection.orderBy("destination.time", "desc")
    : collection.orderBy("destination.time", "asc");

  console.log("getCollectionByDateKeyZoneFilter");
  if (type === 0) return collection;

  if (type === 1)
    return collection.where("status.stage", "in", [
      TransferStage.searching_driver,
      TransferStage.searching_driver_return,
      TransferStage.searching_driver_incident,
    ]); //FIXME:

  if (type === 2)
    //"Asignado": tiene conductor designado
    return collection.where("status.stage", "in", [
      TransferStage.scheduled,
      TransferStage.going_to_meeting_point,
      TransferStage.origin_to_destination,
      TransferStage.return_requested,
      TransferStage.waiting_return_request,
      TransferStage.waiting_on_meeting_point,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.destination_to_origin,
    ]);

  if (type === 3)
    //"Un tramo finalizado": la ida fue realizada o Negativo, queda pendiente el Retorno ( caso Modulo con o sin espera)
    return collection.where("status.stage", "in", [
      TransferStage.waiting_return_request,
      TransferStage.return_requested,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.searching_driver_return,
      TransferStage.destination_to_origin,
      TransferStage.canceled_on_waiting_return_request,
    ]);

  if (type === 4)
    //"Iniciado": el conductor está en camino al punto de origen
    return collection.where(
      "status.stage",
      "==",
      TransferStage.going_to_meeting_point
    );

  if (type === 5)
    //"En Origen"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_on_meeting_point
    );

  if (type === 6)
    //"En Viaje"
    return collection.where("status.stage", "in", [
      TransferStage.origin_to_destination,
      TransferStage.destination_to_origin,
    ]);

  console.log("ESTOY ACÁ!");

  if (type === 7)
    //"En Espera"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_return_request
    );

  if (type === 8)
    //"Retorno solicitado": para módulos Con Espera
    return collection.where(
      "status.stage",
      "==",
      TransferStage.return_requested
    );

  if (type === 9)
    //"Informado": durante los 15 minutos de espera de respuesta de la ART
    return collection
      .where("status.active_report", "==", true)
      .where("status.stage", "<=", TransferStage.finished)
      .orderBy("status.stage");

  if (type === 10)
    //"Finalizado": se cumplieron los tramos de ida y/o retorno normalmente
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Exitoso);

  if (type === 11)
    //"Negativo": finalizados negativos
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", false);

  if (type === 12)
    // "Finalizado, Retorno Negativo"
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", true);

  if (type === 13)
    //"Cancelado": la ART lo canceló o Satapp informa a la ART que no lo va a poder realizar
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Cancelado);

  if (type === 14)
    //"Esperando accion": Esperando que el admin ACCIONE
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_admin_action
    );

  throw new Error("Mal transfer type");
};
export const getCollectionByKeyWordsFilterZone = (
  word: string,
  type: number,
  zone: string,
  asc?: boolean
) => {
  console.log("getCollectionByKeyWordsFilterZone");
  let collection = FIRESTORE.COLLECTION_MODULES_TRANSFER()
    .where("keywords", "array-contains", word.trim())
    .where("info.zona", "==", zone);
  asc
    ? (collection = collection.orderBy("destination.time", "asc"))
    : (collection = collection.orderBy("destination.time", "desc"));

  if (type === 0) return collection;

  if (type === 1)
    return collection.where("status.stage", "in", [
      TransferStage.searching_driver,
      TransferStage.searching_driver_return,
      TransferStage.searching_driver_incident,
    ]); //FIXME:

  if (type === 2)
    //"Asignado": tiene conductor designado
    return collection.where("status.stage", "in", [
      TransferStage.scheduled,
      TransferStage.going_to_meeting_point,
      TransferStage.origin_to_destination,
      TransferStage.return_requested,
      TransferStage.waiting_return_request,
      TransferStage.waiting_on_meeting_point,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.destination_to_origin,
    ]);

  if (type === 3)
    //"Un tramo finalizado": la ida fue realizada o Negativo, queda pendiente el Retorno ( caso Modulo con o sin espera)
    return collection.where("status.stage", "in", [
      TransferStage.waiting_return_request,
      TransferStage.return_requested,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.searching_driver_return,
      TransferStage.destination_to_origin,
      TransferStage.canceled_on_waiting_return_request,
    ]);

  if (type === 4)
    //"Iniciado": el conductor está en camino al punto de origen
    return collection.where(
      "status.stage",
      "==",
      TransferStage.going_to_meeting_point
    );

  if (type === 5)
    //"En Origen"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_on_meeting_point
    );

  if (type === 6)
    //"En Viaje"
    return collection.where("status.stage", "in", [
      TransferStage.origin_to_destination,
      TransferStage.destination_to_origin,
    ]);

  if (type === 7)
    //"En Espera"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_return_request
    );

  if (type === 8)
    //"Retorno solicitado": para módulos Con Espera
    return collection.where(
      "status.stage",
      "==",
      TransferStage.return_requested
    );

  if (type === 9)
    //"Informado": durante los 15 minutos de espera de respuesta de la ART
    return collection
      .where("status.active_report", "==", true)
      .where("status.stage", "<=", TransferStage.finished)
      .orderBy("status.stage");

  if (type === 10)
    //"Finalizado": se cumplieron los tramos de ida y/o retorno normalmente
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Exitoso);

  if (type === 11)
    //"Negativo": finalizados negativos
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", false);

  if (type === 12)
    // "Finalizado, Retorno Negativo"
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", true);

  if (type === 13)
    //"Cancelado": la ART lo canceló o Satapp informa a la ART que no lo va a poder realizar
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Cancelado);

  if (type === 14)
    //"Esperando accion": Esperando que el admin ACCIONE
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_admin_action
    );

  throw new Error("Mal transfer type");
};
export const getCollectionByDateKeyWordsZone = (
  d: Date,
  d2: Date,
  word: string,
  zone: string,
  asc?: boolean
) => {
  console.log("getCollectionByDateKeyWordsZone");
  if (asc) {
    return FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("destination.time", "<=", firebase.firestore.Timestamp.fromDate(d))
      .where(
        "destination.time",
        ">=",
        firebase.firestore.Timestamp.fromDate(d2)
      )
      .where("keywords", "array-contains", word)
      .where("info.zona", "==", zone)
      .orderBy("destination.time", "asc");
  } else {
    return FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("destination.time", "<=", firebase.firestore.Timestamp.fromDate(d))
      .where(
        "destination.time",
        ">=",
        firebase.firestore.Timestamp.fromDate(d2)
      )
      .where("keywords", "array-contains", word)
      .where("info.zona", "==", zone)
      .orderBy("destination.time", "desc");
  }
};
export const getCollectionByDateFilterZone = (
  d: Date,
  d2: Date,
  zone: string,
  asc?: boolean
) => {
  console.log("getCollectionByDateFilterZone");
  if (asc) {
    return FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("destination.time", "<=", firebase.firestore.Timestamp.fromDate(d))
      .where(
        "destination.time",
        ">=",
        firebase.firestore.Timestamp.fromDate(d2)
      )
      .where("info.zona", "==", zone)
      .orderBy("destination.time", "asc");
  } else {
    return FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("destination.time", "<=", firebase.firestore.Timestamp.fromDate(d))
      .where(
        "destination.time",
        ">=",
        firebase.firestore.Timestamp.fromDate(d2)
      )
      .where("info.zona", "==", zone)
      .orderBy("destination.time", "desc");
  }
};
export const getCollectionSearchByKeyWordsZone = (
  word: string,
  zone: string,
  asc?: boolean
) =>
  !asc
    ? FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where("keywords", "array-contains", word)
        .where("info.zona", "==", zone)
        .orderBy("destination.time", "desc")
    : FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where("keywords", "array-contains", word)
        .where("info.zona", "==", zone)
        .orderBy("destination.time", "asc");

export const getCollectionByFilterZone = (
  type: number,
  zone: string,
  asc?: boolean
) => {
  console.log("getCollectionByFilterZone");
  console.log(type, zone, asc, "lalallaa");

  let collection = FIRESTORE.COLLECTION_MODULES_TRANSFER().where(
    "info.zona",
    "==",
    zone
  );
  asc
    ? (collection = collection.orderBy("destination.time", "asc"))
    : (collection = collection.orderBy("destination.time", "desc"));

  if (type === 0) return collection;

  if (type === 1)
    return collection.where("status.stage", "in", [
      TransferStage.searching_driver,
      TransferStage.searching_driver_return,
      TransferStage.searching_driver_incident,
    ]); //FIXME:

  if (type === 2)
    //"Asignado": tiene conductor designado
    return collection.where("status.stage", "in", [
      TransferStage.scheduled,
      TransferStage.going_to_meeting_point,
      TransferStage.origin_to_destination,
      TransferStage.return_requested,
      TransferStage.waiting_return_request,
      TransferStage.waiting_on_meeting_point,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.destination_to_origin,
    ]);

  if (type === 3)
    //"Un tramo finalizado": la ida fue realizada o Negativo, queda pendiente el Retorno ( caso Modulo con o sin espera)
    return collection.where("status.stage", "in", [
      TransferStage.waiting_return_request,
      TransferStage.return_requested,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.searching_driver_return,
      TransferStage.destination_to_origin,
      TransferStage.canceled_on_waiting_return_request,
    ]);

  if (type === 4)
    //"Iniciado": el conductor está en camino al punto de origen
    return collection.where(
      "status.stage",
      "==",
      TransferStage.going_to_meeting_point
    );

  if (type === 5)
    //"En Origen"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_on_meeting_point
    );

  if (type === 6)
    //"En Viaje"
    return collection.where("status.stage", "in", [
      TransferStage.origin_to_destination,
      TransferStage.destination_to_origin,
    ]);

  if (type === 7)
    //"En Espera"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_return_request
    );

  if (type === 8)
    //"Retorno solicitado": para módulos Con Espera
    return collection.where(
      "status.stage",
      "==",
      TransferStage.return_requested
    );

  if (type === 9)
    //"Informado": durante los 15 minutos de espera de respuesta de la ART
    return collection
      .where("status.active_report", "==", true)
      .where("status.stage", "<=", TransferStage.finished)
      .orderBy("status.stage");

  if (type === 10)
    //"Finalizado": se cumplieron los tramos de ida y/o retorno normalmente
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Exitoso);

  if (type === 11)
    //"Negativo": finalizados negativos
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", false);

  if (type === 12)
    // "Finalizado, Retorno Negativo"
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo)
      .where("status.doing_return", "==", true);

  if (type === 13)
    //"Cancelado": la ART lo canceló o Satapp informa a la ART que no lo va a poder realizar
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Cancelado);

  if (type === 14)
    //"Esperando accion": Esperando que el admin ACCIONE
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_admin_action
    );

  throw new Error("Mal transfer type");
};
export const getCollectionSearchByZone = (zone: string, asc?: boolean) =>
  !asc
    ? FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where("info.zona", "==", zone)
        .orderBy("destination.time", "desc")
    : FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where("info.zona", "==", zone)
        .orderBy("destination.time", "asc");
