import React, { useState, useEffect } from "react";
import { Container, Center } from "components/styles";
import { Card, ListGroup, Modal, Button, Col } from "react-bootstrap";
import styled from "styled-components";
import YouTube from "react-youtube";
import { videosArray, quizData } from "./data";

const FormVideo = ({
  currentStepNumber,
  handleStep,
  currentIndexQuestion,
}: {
  currentStepNumber: number;
  handleStep: (v: number) => void;
  currentIndexQuestion: number;
}) => {
  const [videoEnd, setVideoEnd] = useState<boolean>(false);
  const [trueAnswer, setTrueAnswer] = useState<string>("");
  const [currentIndex, setCurrentIndex] =
    useState<number>(currentIndexQuestion);
  const [options, setOptions] = useState<any[]>([]);
  const [question, setQuestion] = useState<string | undefined>(undefined);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const flagVideo = (flag: boolean) => setVideoEnd(flag);

  const loadQuiz = () => {
    setQuestion(quizData[currentIndex].question);
    setOptions(quizData[currentIndex].options);
    setTrueAnswer(quizData[currentIndex].answer);
  };
  const checkAnswer = (option: string) => {
    if (option === trueAnswer) {
      if (currentIndex + 1 < 34) {
        if (
          quizData[currentIndex].idVideo !== quizData[currentIndex + 1].idVideo
        ) {
          flagVideo(false);
        }
        setCurrentIndex(currentIndex + 1);
      }
      handleStep(currentIndex + 1);
    } else {
      setShow(true);
    }
  };
  useEffect(() => {
    loadQuiz();
    return () => {
      setQuestion(quizData[currentIndex].question);
      setOptions(quizData[currentIndex].options);
      setTrueAnswer(quizData[currentIndex].answer);
    };
  }, [currentIndex]);

  const Quiz = () => (
    <Col>
      <CardQuiz>
        <CardHeader>{question}</CardHeader>
        <ListAnswers>
          {options.map((option, index) => (
            <Option key={index} onClick={() => checkAnswer(option)}>
              {option}
            </Option>
          ))}
          <Option onClick={() => flagVideo(false)}>
            No lo se, volver al video
          </Option>
        </ListAnswers>
      </CardQuiz>
    </Col>
  );

  const ModalAnswer = () => (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="modal-30w"
    >
      <Modal.Header>
        <div className="d-flex mx-auto">
          <h6>La respuesta no es correcta</h6>
        </div>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-between">
        <Button variant="secondary" onClick={handleClose} size="sm">
          Intentar nuevamente
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            flagVideo(false);
            handleClose();
          }}
          size="sm"
        >
          Volver a ver el video
        </Button>
      </Modal.Body>
    </Modal>
  );

  const video = videosArray.map((video: any, index: number) => {
    console.log(video, "VIDEO");

    return (
      <Center key={index}>
        {index === currentStepNumber && videoEnd === false ? (
          <YouTube
            opts={{ width: "100%", height: "339", playerVars: { autoplay: 1 } }}
            onEnd={() => flagVideo(true)}
            videoId={video}
          />
        ) : null}
      </Center>
    );
  });

  return (
    <>
      {videoEnd ? <Quiz /> : video}
      <ModalAnswer />
    </>
  );
};

const Option = styled.div`
  border: 1px solid #00426a;
  padding: 12px 7px;
  margin: 5px 0px;
  cursor: pointer;
  color: #00426a;
`;
const CardQuiz = styled(Card)`
  margin: auto;
  /*width: 25rem;*/
  text-align: center;
  width: 100%;
  -webkit-box-shadow: 0px 0px 9px -7px #000000;
  box-shadow: 0px 0px 9px -7px #000000;
`;
const ListAnswers = styled(ListGroup)`
  margin: 22px;
`;
const CardHeader = styled(Card.Header)`
  padding: 12px 7px;
  margin: 5px 20px;
  font-size: 20px;
  color: #303133;
  font-weight: bold;
  background-color: white;
`;

export default FormVideo;
