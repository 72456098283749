import { ScheduledTransfer, FbDocument } from "types/transfer";
import { Padding, AsyncButton } from "components/styles";
import { Card, Button } from "react-bootstrap";
import React from "react";
import moment from "moment";
import { getAddress } from "../Zones/reducer";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { FIRESTORE } from "constants/firestore";
import firebase from "firebase/app";

const TransferCard = ({
  transfer,
  uidDriver,
  reload,
}: {
  transfer: { data: ScheduledTransfer; ref: FbDocument<ScheduledTransfer> };
  uidDriver: string;
  reload: () => void;
}) => {
  console.log(transfer, "data");

  const devolverActive = async () => {
    if (transfer.data.status.stage === 0) {
      console.log("borra el active");

      await FIRESTORE.DOCUMENT_USER(uidDriver).update({
        active_transfer: firebase.firestore.FieldValue.delete(),
      });
      reload();
    } else {
      const data = {
        refTransfer: transfer.ref.id,
        uidDriver: uidDriver,
        isDriver: true,
      };
      console.log(data, "aqui");
      await CALLABLE_FUNCTIONS.ADMIN_RETURN_ACTIVE(data);
      reload();
    }
  };
  return (
    <Padding pad="5px">
      <Card border="secondary" style={{ width: "auto", marginTop: "4%" }}>
        <Card.Header style={{ backgroundColor: "#52489C" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                color: "#D3D0CB",
                fontWeight: "bolder",
              }}
            >
              {`Nombre: ${transfer.data.passenger.name}`}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <span style={{ color: "#D3D0CB", fontWeight: "bolder" }}>
              {defineTelephone(transfer.data)}
            </span>
          </div>
        </Card.Header>
        <Card.Body style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              marginLeft: "2%",
              fontWeight: "bold",
              flexShrink: 2,
            }}
          >
            <div style={{ fontWeight: "bold" }}>{`Hora en Origen: ${moment(
              transfer.data.origin.time.toDate()
            ).format("HH:mm")}`}</div>
            <br />
          </div>
          <div>
            <div style={{ fontWeight: "bold" }}>
              {getAddress(transfer.data.origin.address)}
            </div>{" "}
            <br />
            <div style={{ fontWeight: "bold" }}>
              {getAddress(transfer.data.destination.address)}
            </div>
            <br />
          </div>
        </Card.Body>
        <Card.Footer>
          <AsyncButton onClick={() => devolverActive()} variant="warning">
            Activar traslado
          </AsyncButton>
        </Card.Footer>
      </Card>
    </Padding>
  );
};

export const defineTelephone = (transfer: ScheduledTransfer) => {
  if (transfer) {
    if (typeof transfer!.passenger.tel === "string") {
      return transfer!.passenger.tel;
    } else {
      for (let i = 0; i < transfer!.passenger.tel.length; i++) {
        if (
          transfer!.passenger.tel[i].number != "" &&
          transfer!.passenger.tel[i].number != "-"
        ) {
          const phone = transfer.passenger.tel[i].number.replace("-", "");
          return phone;
        }
      }
    }
  }
  return "";
};

export default TransferCard;
