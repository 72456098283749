import firebase from "firebase/app";
import "firebase/firestore";
import {
  ArtBill,
  BillInfoTransfer,
  PaymentOrderArt,
  PaymentOrderDriver,
} from "types/billing";
import { ModuleTransfer, ModuleTransferWrong } from "types/module-transfer";
import {
  FbCollection,
  FbDocument,
  FbQuery,
  ScheduledTransfer,
  TransferRequest,
} from "types/transfer";
import {
  User,
  TaxableEntity,
  ArtInformation,
  PersonalInformation,
  GpsStatus,
  Challenge,
  Revision,
} from "types/user-data";
import {
  Config,
  Contadores,
  MaxPriceTransfer,
  GlobalConfig,
} from "types/config";
import { Cross, Localities } from "types/cross";
import { Sale } from "types/sale";
export const FIRESTORE = {
  COLLECTION_CROSS_EXPERTA: () =>
    firebase
      .firestore()
      .collection("art")
      .doc("cross")
      .collection("Experta") as FbCollection<Cross>,
  COLLECTION_LOCALITIES_SATAPP: () =>
    firebase
      .firestore()
      .collection("art")
      .doc("localities")
      .collection("Satapp") as FbCollection<Localities>,
  DOCUMENT_LOCALITIES_SATAPP: (id: string) =>
    firebase
      .firestore()
      .collection("art")
      .doc("localities")
      .collection("Satapp")
      .doc(id) as FbDocument<Localities>,
  COLLECTION_CHALLENGES: () =>
    firebase.firestore().collection("challenges") as FbCollection<Challenge>,
  DOCUMENT_CHALLENGE: (challengeId: string) =>
    firebase
      .firestore()
      .collection("challenges")
      .doc(challengeId) as FbDocument<Challenge>,
  DOCUMENT_CROSS_EXPERTA: (id: string) =>
    firebase
      .firestore()
      .collection("art")
      .doc("cross")
      .collection("Experta")
      .doc(id) as FbDocument<Cross>,
  COLLECTION_PAYMENTS_DRIVERS: () =>
    firebase
      .firestore()
      .collection("billing")
      .doc("payment")
      .collection("driver") as FbCollection<PaymentOrderDriver>,
  DOCUMENT_PAYMENT_DRIVER: (id: string) =>
    firebase
      .firestore()
      .collection("billing")
      .doc("payment")
      .collection("driver")
      .doc(id) as FbDocument<PaymentOrderDriver>,
  DOCUMENT_PAYMENT_ART: (id: string) =>
    firebase
      .firestore()
      .collection("billing")
      .doc("payment")
      .collection("art")
      .doc(id) as FbDocument<PaymentOrderArt>,
  COLLECTION_PAYMENTS_ART: () =>
    firebase
      .firestore()
      .collection("billing")
      .doc("payment")
      .collection("art") as FbCollection<PaymentOrderArt>,

  DOCUMENT_DRIVER_BILL: (idBill: string) =>
    firebase
      .firestore()
      .collection("billing")
      .doc("invoice")
      .collection("driver")
      .doc(idBill) as FbDocument<BillInfoTransfer>,
  DOCUMENT_ART_INVOICE: (id: string) =>
    firebase
      .firestore()
      .collection("billing")
      .doc("invoice")
      .collection("art")
      .doc(id) as FbDocument<ArtBill>,
  COLLECTION_ARTS_INVOICES: () =>
    firebase
      .firestore()
      .collection("billing")
      .doc("invoice")
      .collection("art") as FbCollection<ArtBill>,
  COLLECTION_DRIVER_INVOICES: () =>
    firebase
      .firestore()
      .collection("billing")
      .doc("invoice")
      .collection("driver") as FbCollection<BillInfoTransfer>,

  DOCUMENT_SAVE_ADMIN_SELECTIONS: <_, T>(uid: string, type: string) =>
    firebase
      .firestore()
      .collection("user")
      .doc(uid)
      .collection("selection")
      .doc(type) as FbDocument<T>,
  COLLECTION_USER_TRANSFERS: (uid: string) =>
    firebase
      .firestore()
      .collection("user")
      .doc(uid)
      .collection("transfer") as FbCollection<ScheduledTransfer>,
  COLLECTION_TRANSFERS: () =>
    firebase
      .firestore()
      .collectionGroup("transfer") as FbQuery<ScheduledTransfer>,
  COLLECTION_TRANSFER_REQUEST: () =>
    firebase
      .firestore()
      .collection("transfer_request") as FbCollection<TransferRequest>,
  DOCUMENT_SCHEDULED_TRANSFER: (uid: string, id: string) =>
    firebase
      .firestore()
      .collection("user")
      .doc(uid)
      .collection("transfer")
      .doc(id) as FbDocument<ScheduledTransfer>,
  COLLECTION_USERS: () =>
    firebase.firestore().collection("user") as FbCollection<User>,
  DOCUMENT_USER: (uid: string) =>
    firebase.firestore().collection("user").doc(uid) as FbDocument<User>,
  DOCUMENT_USER_PERSONAL_INFO: (uid: string) =>
    firebase
      .firestore()
      .collection("user")
      .doc(uid)
      .collection("personal")
      .doc(uid) as FbDocument<PersonalInformation>,
  DOCUMENT_USER_TAXABLE_INFO: (uid: string) =>
    firebase
      .firestore()
      .collection("user")
      .doc(uid)
      .collection("taxable")
      .doc(uid) as FbDocument<TaxableEntity>,

  DOCUMENT_MODULE_TRANSFER: (idModule: string) =>
    firebase
      .firestore()
      .collection("module_transfer")
      .doc(idModule) as FbDocument<ModuleTransfer>,
  DOCUMENT_MODULE_TRANSFER_WRONG: (idModule: string) =>
    firebase
      .firestore()
      .collection("module_transfer_er")
      .doc(idModule) as FbDocument<ModuleTransferWrong>,
  COLLECTION_MODULES_TRANSFER: () =>
    firebase
      .firestore()
      .collection("module_transfer") as FbCollection<ModuleTransfer>,
  COLLECTION_MODULES_TRANSFER_WRONG: () =>
    firebase
      .firestore()
      .collection("module_transfer_er") as FbCollection<ModuleTransferWrong>,

  DOCUMENT_CONFIGURATION_ALL: () =>
    firebase
      .firestore()
      .collection("configuration")
      .doc("all") as FbDocument<GlobalConfig>,
  DOCUMENT_CONFIGURATION_IIBB: () =>
    firebase.firestore().doc("configuration/iibb") as FbDocument<{
      [key: string]: number;
    }>,
  DOCUMENT_PERSONAL_DATA: (uid: string) =>
    firebase
      .firestore()
      .collection("user")
      .doc(uid)
      .collection("personal")
      .doc(uid) as FbDocument<PersonalInformation>,
  DOCUMENT_PERSONAL_REVISION_DATA: (uid: string) =>
    firebase
      .firestore()
      .collection("user")
      .doc(uid)
      .collection("personal_revision")
      .doc(uid) as FbDocument<Revision>,
  DOCUMENT_CONFIGURATION_MAXPRICE: (art: string) =>
    firebase
      .firestore()
      .collection("user")
      .doc(art)
      .collection("max_price")
      .doc(art) as FbDocument<MaxPriceTransfer>,
  DOCUMENT_ART_INFO: (art: string) =>
    firebase
      .firestore()
      .collection("user")
      .doc(art)
      .collection("art_info")
      .doc(art) as FbDocument<ArtInformation>,
  COLLECTION_USER_GPS: (uid: string) =>
    firebase
      .firestore()
      .collection("user")
      .doc(uid)
      .collection("gps") as FbCollection<GpsStatus>,
  DOCUMENT_CONFIGURATION_CONTADOR: () =>
    firebase
      .firestore()
      .collection("configuration")
      .doc("contadores") as FbDocument<Contadores>,
  DOCUMENT_SALE_EXPERTA: (id: string) =>
    firebase
      .firestore()
      .collection("art")
      .doc("sale")
      .collection("Experta")
      .doc(id) as FbDocument<Sale>,
  COLLECTION_SALE_EXPERTA: () =>
    firebase
      .firestore()
      .collection("art")
      .doc("sale")
      .collection("Experta") as FbCollection<Sale>,
};
