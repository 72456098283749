import database, { WithId } from "controllers/database"
import { TransferStage, ScheduledTransfer } from "types/transfer"
import { ModuleTransfer } from "types/module-transfer"

export const collectionFromType = (type : number) => {
    const collection = database.collection(`module_transfer`).where('status.active_report').is(true)

    if(type === 0) //TODOS
        return collection
    if(type === 1) //INICIADO
        return collection.where('status.stage').is(TransferStage.going_to_meeting_point)
    if(type === 2) //EN ORIGEN
        return collection.where('status.stage').is(TransferStage.waiting_on_meeting_point)
    if(type === 3) //COMENZADO
        return collection.where('status.stage').in(
            [TransferStage.origin_to_destination, TransferStage.return_requested, 
            TransferStage.waiting_return_request, TransferStage.destination_to_origin])
    
    throw Error('Bad type index')
}

export const transform = (data:(WithId & ModuleTransfer)[]) => Promise.all(
    data.map( module => 
        database.doc(module.transfers.active!.path).as(ScheduledTransfer)
        .then( r => ({ ...module, transfer: r}) )  
    )
)