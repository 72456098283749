import { InfoRow } from "components/Admin/Transfers/view/scheduled";
import { DatePickerInput } from "components/input/date-picker";
import FbFile, { getStorageFileUrl } from "components/Primitives/File";
import {
  AdminTable,
  AsyncButton,
  Button,
  ContainerPage,
  H3,
  Link,
  PageTitle,
  Spinner,
  ButtonWithConfirmation,
} from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import { useDatabaseDocref } from "controllers/hooks";
import React, { useState, useEffect } from "react";
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import Moment from "react-moment";
import { isLoaded } from "react-redux-firebase";
import { useParams } from "react-router";
import { useProfile } from "redux/app";
import { ArtBill, TIPOS_COMPROBANTES, BillStatus } from "types/billing";
import {
  FbDate,
  FbDocument,
  ScheduledTransfer,
  FinishStatus,
} from "types/transfer";
import { UserType } from "types/user-data";
import ExcelJS from "exceljs";
import * as fs from "file-saver";
import {
  QuizParams,
  DefinitionsModuleTransferType,
  LocationModule,
  ModuleTransferType,
} from "types/module-transfer";
import moment from "moment";
import * as crossExperta from "./cruces_experta.json";
import { CancelBillArtModal } from "components/Admin/Transfers/view/module/modals/bill";
import firebase from "firebase";

const BillingInfo = () => {
  const { id } = useParams<QuizParams>();
  const bill = useDatabaseDocref<ArtBill>(FIRESTORE.DOCUMENT_ART_INVOICE(id));
  const [expirationDate, setExpirationDate] = useState(new Date());
  const user = useProfile();
  const [show, setShow] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [billLiquidationStatus, setBillLiquidationStatus] =
    useState<string>("");
  const [listOfPricesCrossDataBase, setListOfPricesCrossDataBase] = useState<
    any[]
  >([]);
  useEffect(() => {
    FIRESTORE.COLLECTION_CROSS_EXPERTA()
      .get()
      .then((data) => data.docs)
      .then((docs) => docs.map((doc) => doc.data()))
      .then((crossArray) => setListOfPricesCrossDataBase(crossArray));
  }, []);
  const sendEmailBill = (
    arr: {
      id_accident: string;
      id_module: string;
      vat_base: number;
      base: number;
      agency_commission: number;
      vat_commission: number;
      total: number;
      ImpTotConc: number;
      ImpNeto: number;
      ImpOpEx: number;
      ImpIVA: number;
      ImpTrib: number;
      tolls: number;
      wait_time: number;
      extra_wait_time: number;
    }[],
    id: string,
    fcNo: any,
    email: boolean,
    bonif?: number
  ) =>
    CALLABLE_FUNCTIONS.SEND_EMAIL_ART_BILL({
      idBill: id,
    });

  const updateExpirationDate = () =>
    FIRESTORE.DOCUMENT_ART_INVOICE(id).update({
      expiration_date: FbDate.fromDate(expirationDate),
    });

  const createBillPDF = () =>
    CALLABLE_FUNCTIONS.CREATE_BILL_PDF({ idArtBill: id });

  const debit = () => CALLABLE_FUNCTIONS.CREATE_DEBIT_NOTE({ idArtBill: id });

  const resendBill = () => CALLABLE_FUNCTIONS.RESEND_BILL({ idArtBill: id });

  const consultLiquidation = async () => {
    const status = await CALLABLE_FUNCTIONS.CONSULT_BILL_LIQUIDATION({
      voucher_number: bill!.voucher_number,
    });
    const data: { otorgado: number; liquidada: string; id: number } =
      status.data;
    console.log(data.liquidada);
    if (
      data.liquidada === "SI" &&
      (bill?.respuesta_api === undefined || bill.respuesta_api.status !== 200)
    ) {
      const refBill = (await FIRESTORE.DOCUMENT_ART_INVOICE(id).get()).ref;
      bill!.modules.map(
        async (infoModule) =>
          await infoModule.ref.update({
            "sale.billed": BillStatus.Si,
          })
      );
      FIRESTORE.DOCUMENT_ART_INVOICE(id).update({
        respuesta_api: {
          status: 200,
          texto: "TODO BIEN",
        },
      });
    }

    setBillLiquidationStatus(data.liquidada);
  };

  const createVouchers = () =>
    CALLABLE_FUNCTIONS.CREATE_VOUCHERS({ idArtBill: id });

  const totalLoader = (arr: any): string => {
    let contador = 0;
    arr.map((elem: any) => {
      contador = contador + elem.total;
    });
    return "$" + contador.toString().split(".").join(",");
  };
  console.log(bill?.modules);

  if (!isLoaded(bill)) return <Spinner />;

  if (bill === undefined) return <>Error</>;

  return (
    <ContainerPage>
      <Row>
        <Col xl={5}>
          <PageTitle>
            Factura - {bill.point_of_sale}-{bill.voucher_number} -{" "}
            <Moment format="DD/MM/YYYY" date={bill.creation_date.toDate()} />
          </PageTitle>
        </Col>
        <Col>
          {user.type === UserType.admin ? (
            <ButtonWithConfirmation
              className="pull-right"
              onClick={() =>
                sendEmailBill(
                  bill.modules,
                  id,
                  bill.voucher_number,
                  true,
                  bill.bonif
                )
              }
              style={{ marginLeft: 10 }}
              confirmText="¿Está seguro que quiere enviar la factura por mail al prestador?"
            >
              Enviar factura por mail
            </ButtonWithConfirmation>
          ) : null}
          {user.type === UserType.admin && bill.canceled === false ? (
            <Button
              className="pull-right"
              onClick={() => setShowCancel(true)}
              style={{ marginLeft: 10 }}
              variant="danger"
            >
              Hacer nota de crédito
            </Button>
          ) : user.type === UserType.admin && bill.canceled === true ? (
            <ButtonWithConfirmation
              className="pull-right"
              onClick={debit}
              style={{ marginLeft: 10 }}
              variant="danger"
              confirmText="¿Está seguro que quiere crear una nota de débito?"
            >
              Hacer nota de débito
            </ButtonWithConfirmation>
          ) : null}
          {user.type === UserType.admin &&
            bill.art === "MekXauxMVPd1ayCGpIwWjg7j6Z73" && (
              <ButtonWithConfirmation
                className="pull-right"
                variant="danger"
                onClick={resendBill}
                style={{ marginLeft: 10 }}
                confirmText="¿Está seguro que quiere reenviar la factura a Experta?"
              >
                Reenviar factura
              </ButtonWithConfirmation>
            )}
          {bill.pdf ? (
            <FbFile src={bill.pdf}>
              <Button className="pull-right" variant="info">
                Ver PDF
              </Button>
            </FbFile>
          ) : (
            <ButtonWithConfirmation
              className="pull-right"
              variant="danger"
              onClick={createBillPDF}
              style={{ marginLeft: 10 }}
              confirmText="¿Está seguro que quiere generar el pdf correspondiente a la factura?"
            >
              Generar PDF
            </ButtonWithConfirmation>
          )}
          {user.type === UserType.admin &&
            bill.art === "MekXauxMVPd1ayCGpIwWjg7j6Z73" && (
              <AsyncButton
                className="pull-right"
                variant="primary"
                onClick={consultLiquidation}
                style={{ marginLeft: 10 }}
              >
                Consultar Liquidación
              </AsyncButton>
            )}
          {bill.art == "GjP1gJT9T1eK0FGe2kfx6o4T80J2" ? (
            <Button
              className="pull-right"
              variant="info"
              onClick={() => {
                generateExcelFeleval(
                  bill.modules,
                  id,
                  bill.voucher_number,
                  false,
                  bill.bonif
                );
              }}
            >
              Descargar Excel Feleval
            </Button>
          ) : (
            <Button
              className="pull-right"
              variant="info"
              onClick={() => {
                generateExcel(bill, listOfPricesCrossDataBase);
              }}
            >
              Descargar Soporte Experta
            </Button>
          )}
          {bill.art === "GjP1gJT9T1eK0FGe2kfx6o4T80J2" ? (
            <ButtonWithConfirmation
              className="pull-right"
              variant="info"
              onClick={createVouchers}
              style={{ marginLeft: 10 }}
              confirmText="Esta acción creará los vouchers faltantes"
            >
              Crear vouchers
            </ButtonWithConfirmation>
          ) : null}
          <ModalBillDetails
            show={show}
            modules={bill.modules}
            close={() => setShow(false)}
          />
          <CancelBillArtModal
            show={showCancel}
            handleClose={() => setShowCancel(false)}
            idArtBill={id}
          />
        </Col>
      </Row>

      {bill.debit_voucher_number && (
        <Alert variant="warning">
          FACTURA CON NOTA DE DEBITO {bill.debit_voucher_number}
        </Alert>
      )}
      {billLiquidationStatus && (
        <Alert
          variant={
            billLiquidationStatus === "NO"
              ? "danger"
              : billLiquidationStatus === "SI"
              ? "success"
              : "info"
          }
        >
          {`RECIBIDA POR EXPERTA: ${billLiquidationStatus}`}
        </Alert>
      )}
      {bill.deleted_vouche_number && bill.canceled && (
        <Alert variant="danger">
          FACTURA CANCELADA CON VOUCHER DE NOTA DE CRÉDITO{" "}
          {bill.deleted_vouche_number}
        </Alert>
      )}
      <InfoRow label="Cliente" info={<UserName uid={bill.art} />} />
      <InfoRow
        label="Fecha de vencimiento"
        info={<Moment date={bill.expiration_date.toDate()} format="DD/MM/YY" />}
      />
      <InfoRow label="Importe" info={totalLoader(bill.modules)} />
      {bill.respuesta_api ? (
        <InfoRow
          label="Respuesta Api"
          info={`${bill.respuesta_api.status} - ${bill.respuesta_api.texto}`}
        />
      ) : null}

      <H3>Traslados</H3>
      <AdminTable responsive>
        <thead>
          <tr>
            <th>ID Siniestro</th>
            <th>Ver</th>
            <th>IVA</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {bill.modules.map((mod) => (
            <tr>
              <td>{mod.id_accident}</td>
              <td>
                <Link to={ROUTES.ADMIN_MODULE_TRANSFER_VIEW(mod.id_module)}>
                  Ver
                </Link>
              </td>
              <td>{"$" + mod.ImpIVA.toString().split(".").join(",")}</td>
              <td>{"$" + mod.total.toString().split(".").join(",")}</td>
            </tr>
          ))}
        </tbody>
      </AdminTable>
      {user.type === UserType.admin ? (
        <Row>
          <Col xl={3}>
            <Form.Group>
              <Form.Label>Actualizar fecha de vencimiento</Form.Label>
              <DatePickerInput
                value={expirationDate}
                onChange={setExpirationDate}
              />
            </Form.Group>
          </Col>
          <Col xl={2}>
            <AsyncButton
              onClick={updateExpirationDate}
              style={{ marginTop: "20%" }}
            >
              ACTUALIZAR
            </AsyncButton>
          </Col>
        </Row>
      ) : null}
    </ContainerPage>
  );
};

const generateExcel = async (bill: ArtBill, crossDataBase: any[]) => {
  let workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet(`Soporte FC-${bill.voucher_number}`);
  //arr.sort((a, b) => a.origin.time.seconds - b.origin.time.seconds)
  worksheet.columns = [
    { header: "Tipo Documento", key: "doc", width: 10 },
    { header: "Tipo Comprobante", key: "comprobante", width: 10 },
    { header: "Sucursal", key: "suc", width: 10 },
    { header: "Numero", key: "fcNro", width: 10 },
    { header: "Fecha Emision", key: "billDate", width: 10 },
    { header: "Fecha Vencimiento", key: "fchV", width: 10 },
    { header: "Tipo Prestacion", key: "pres", width: 10 },
    { header: "Cuit Pago Tipo", key: "cuitType", width: 10 },
    { header: "Cuit Pago", key: "cuit", width: 10 },
    { header: "Cuit autorizacion", key: "cuit", width: 10 },
    { header: "Razon Social Pago", key: "razon", width: 10 },
    { header: "Orden Cheque Pago", key: "razon", width: 10 },
    { header: "Prestador", key: "prestador", width: 10 },
    { header: "Importe Comprobante", key: "billTotal", width: 10 },
    { header: "Fecha Creacion Comprobante", key: "fchC", width: 10 },
    { header: "Fecha Proceso Comprobante", key: "fchP", width: 10 },
    { header: "Fecha envio Comprobante", key: "fchE", width: 10 },
    { header: "Siniestro-Cod.Aut.", key: "sin", width: 10 },
    { header: "Modulo", key: "moduleType", width: 10 },
    { header: "Codigo Autorizacion", key: "auth", width: 10 },
    { header: "Zona Desde", key: "originLocation", width: 80 },
    { header: "Zona Hasta", key: "destinationLocation", width: 80 },
    { header: "Cantidad", key: "cant", width: 10 },
    { header: "Importe", key: "total", width: 25 },
    { header: "Importe Total", key: "impTotal", width: 25 },
    { header: "Observacion", key: "obs", width: 25 },
    { header: "Fecha del Viaje", key: "date", width: 25 },
    { header: "Km Tramo", key: "KmTramo", width: 12 },
  ];

  await Promise.all(
    bill.modules.map(async (mod) => {
      const moduleData = (
        await FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module).get()
      ).data();

      let [lastTransferIda]: any = moduleData?.transfers.past?.going
        ? moduleData.transfers.past.going.slice(-1)
        : [null];
      let [lastTransferVuelta]: any = moduleData?.transfers.past?.return
        ? moduleData.transfers.past.return.slice(-1)
        : [null];

      if (lastTransferIda) {
        lastTransferIda = (await lastTransferIda.get()).data();
      }
      if (lastTransferVuelta) {
        lastTransferVuelta = (await lastTransferVuelta.get()).data();
      }

      let originLocation;
      let destinationLocation;

      if (moduleData?.origin_location_revised !== undefined) {
        originLocation = `${moduleData.origin_location_revised.province?.description}, ${moduleData.origin_location_revised.description}, ${moduleData.origin.address.street} ${moduleData.origin.address.number}`;
      } else {
        originLocation = `${moduleData?.origin_location?.province?.description}, ${moduleData?.origin_location?.description}, ${moduleData?.origin.address.street} ${moduleData?.origin.address.number}`;
      }

      if (moduleData?.destination_location_revised !== undefined) {
        destinationLocation = `${moduleData.destination_location_revised.province?.description}, ${moduleData.destination_location_revised.description}, ${moduleData.destination.address.street} ${moduleData.destination.address.number}`;
      } else {
        destinationLocation = `${moduleData?.destination_location?.province?.description}, ${moduleData?.destination_location?.description}, ${moduleData?.destination.address.street} ${moduleData?.destination.address.number}`;
      }

      const creationDate = moment(bill.creation_date.toDate()).format(
        "DD/MM/YYYY"
      );

      worksheet.addRow({
        doc: "Factura",
        comprobante: "B",
        suc: bill.point_of_sale,
        fcNro: bill.voucher_number,
        billDate: creationDate,
        fchV: creationDate,
        fchC: creationDate,
        fchP: creationDate,
        fchE: creationDate,
        pres: "gastos",
        cuitType: "G",
        cuit: "T30716207281",
        razon: "SATAPP SRL",
        prestador: "TRANS", // 7u7
        billTotal: bill.ImpTotal,
        sin: mod.id_accident,
        moduleType:
          moduleData?.status.finished_status === FinishStatus["Negativo"]
            ? "NEG"
            : moduleData?.type ===
              ModuleTransferType["TRIP_AND_RETURN_WITH_DIFFERENT_DRIVER"]
            ? 1
            : moduleData?.type === ModuleTransferType["SIMPLE_TRANSFER"]
            ? 0.75
            : "Error",
        auth: moduleData?.art.authorization,
        originLocation,
        destinationLocation,
        cant: 1,
        impTotal: mod.ImpTotConc,
        total: mod.ImpTotConc,
        obs: mod.tolls > 0 ? `Peaje $ ${mod.tolls}` : "",
        date: moment(moduleData?.origin.time.toDate()).format("DD/MM/YYYY"),
        KmTramo: moduleData?.info.distance_origin_destination,
      });
    })
  );

  await workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, `Soporte Factura ${bill.voucher_number}.xlsx`);
  });
};

const generateExcelFeleval = async (
  arr: {
    id_accident: string;
    id_module: string;
    vat_base: number;
    base: number;
    agency_commission: number;
    vat_commission: number;
    total: number;
    ImpTotConc: number;
    ImpNeto: number;
    ImpOpEx: number;
    ImpIVA: number;
    ImpTrib: number;
    tolls: number;
    wait_time: number;
    extra_wait_time: number;
  }[],
  id: string,
  fcNo: any,
  email: boolean,
  bonif?: number
) => {
  let workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet(`Factura Feleval ${fcNo}`);
  console.log(arr);

  worksheet.columns = [
    { header: "Fecha", key: "fecha", width: 17 },
    { header: "Siniestro auth", key: "sinAuth", width: 17 },
    { header: "SML ID", key: "smlId", width: 10 },
    { header: "Paciente", key: "paciente", width: 30 },
    { header: "DNI", key: "dni", width: 10 },
    { header: "Modo", key: "mode", width: 10 },
    { header: "Domicilio Origen", key: "domicilio_or", width: 10 },
    { header: "Localidad Origen", key: "localidad_or", width: 10 },
    { header: "Provincia Origen", key: "provincia_or", width: 10 },
    { header: "Domicilio Destino", key: "domicilio_de", width: 10 },
    { header: "Localidad Destino", key: "localidad_de", width: 10 },
    { header: "Provincia Destino", key: "provincia_de", width: 10 },
    { header: "Tiempo Comprado", key: "wait_time", width: 20 },
    { header: "Precio por km", key: "km_price", width: 20 },
    { header: "Km viaje ida", key: "km_ida", width: 20 },
    { header: "Km viaje vuelta", key: "km_vuelta", width: 20 },
    { header: "Valor Espera", key: "extra_wait_time", width: 20 },
    { header: "Valor Viaje", key: "base", width: 20 },
    { header: "Comisión Agencia", key: "agency", width: 20 },
    { header: "Valor total del viaje", key: "base_commission", width: 20 },
    { header: "Peajes", key: "tolls", width: 20 },
    { header: "Bonificación", key: "bonif", width: 20 },
    { header: "Iva Comisión Agencia", key: "ivaCom", width: 20 },
    { header: "Iva Viajes", key: "ivaViajes", width: 20 },
    { header: "IVA", key: "iva", width: 20 },
    { header: "Total", key: "total", width: 20 },
    { header: "Total+IVA", key: "total_iva", width: 20 },
    {
      header: "Voucher",
      key: "voucher",
      width: 25,
      style: { font: { color: { argb: "FF0062FF" }, underline: true } },
    },
  ];

  worksheet.getCell("T1").font = {
    underline: false,
    color: { argb: "FF000000" },
  };
  worksheet.getCell("U1").font = {
    underline: false,
    color: { argb: "FF000000" },
  };
  const fact = (await FIRESTORE.DOCUMENT_ART_INVOICE(id).get()).data();

  let fname = `Resumen-Factura-Venta-${
    (fact?.point_of_sale ? fact?.point_of_sale : "") +
    "-" +
    (fact?.voucher_number ? fact?.voucher_number : "")
  }`;

  await Promise.all(
    arr.map(async (mod) => {
      const data = (
        await FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module).get()
      ).data();
      let voucher = data?.sale?.voucher
        ? await getStorageFileUrl(data.sale.voucher)
        : null;
      const [lastTransferIda] = data?.transfers.past?.going
        ? data.transfers.past.going.slice(-1)
        : [null];
      const [lastTransferVuelta] = data?.transfers.past?.return
        ? data.transfers.past.return.slice(-1)
        : [null];
      let kmIda = null;
      let kmVuelta = null;
      let precio = null;
      if (lastTransferIda) {
        const dataIda = (await lastTransferIda.get()).data();
        if (dataIda) {
          kmIda = dataIda.info.distance_origin_destination / 1000;
          precio = dataIda.info.amount?.price_km_trip;
        }
      }
      if (lastTransferVuelta) {
        const dataVuelta = (await lastTransferVuelta.get()).data();
        if (dataVuelta) {
          kmVuelta = dataVuelta.info.distance_origin_destination / 1000;
          precio = dataVuelta.info.amount?.price_km_trip;
        }
      }
      worksheet.addRow({
        sinAuth: `${mod.id_accident} ${data?.art.authorization}`,
        smlId: data?.transfer_id,
        fecha: data?.origin.time
          ? moment(data.origin.time.toDate()).format("DD/MM/YYYY HH:mm")
          : "no hay fecha de origen",
        mode:
          data?.type === 0 ? "tramo" : data?.type === 1 ? "espera" : "modulo",
        paciente: data?.passenger.name,
        domicilio_or: `${data?.origin.address.street} ${data?.origin.address.number}`,
        dni: data?.passenger.dni,
        localidad_or: data?.origin.address.district,
        provincia_or: data?.origin.address.province,
        domicilio_de: `${data?.destination.address.street} ${data?.destination.address.number}`,
        localidad_de: data?.destination.address.district,
        provincia_de: data?.destination.address.province,
        return: data?.type === 0 ? "No" : "Si",
        km_ida: lastTransferIda ? kmIda : null,
        base_commission: mod.base + mod.agency_commission,
        km_vuelta: lastTransferVuelta ? kmVuelta : null,
        km_price: precio ? precio : "Negativo",
        negativo: data?.status.finished_status == 2 ? "Si" : "No",
        wait_time: mod.wait_time,
        extra_wait_time: mod.extra_wait_time,
        ivaViajes: mod.vat_base,
        ivaCom: mod.vat_commission,
        base: mod.base,
        agency: mod.agency_commission,
        tolls: mod.tolls,
        bonif: bonif,
        iva: mod.ImpIVA,
        total: mod.ImpIVA > 0 ? mod.ImpNeto : mod.ImpTotConc,
        total_iva: mod.ImpIVA + mod.total,
        voucher: voucher
          ? {
              text: "Link voucher",
              hyperlink: voucher,
              tooltip: "Link al pdf del voucher",
            }
          : "",
      });
    })
  );
  const buffer = await workbook.xlsx.writeBuffer();

  await workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, fname + ".xlsx");
  });
};

const ModalBillDetails = ({
  modules,
  show,
  close,
}: {
  modules: {
    id_accident: string;
    id_module: string;
    vat_base: number;
    base: number;
    agency_commission: number;
    vat_commission: number;
    total: number;
    ImpTotConc: number;
    ImpNeto: number;
    ImpOpEx: number;
    ImpIVA: number;
    ImpTrib: number;
    tolls: number;
    wait_time: number;
  }[];
  show: boolean;
  close: () => void;
}) => {
  return (
    <Modal show={show} onHide={close} size="lg">
      <Modal.Header closeButton>Detalles de la factura</Modal.Header>
      <Modal.Body>
        <AdminTable responsive>
          <thead>
            <tr>
              <th>Siniestro-Cod.Aut.</th>
              <th>Fecha</th>
              <th>Paciente</th>
              <th>Domicilio Origen</th>
              <th>Localidad Origen</th>
              <th>Provincia Origen</th>
              <th>Domicilio Destino</th>
              <th>Localidad Destino</th>
              <th>Provincia Destino</th>
              <th>Retorno</th>
              <th>Negativo</th>
              <th>Espera Paciente</th>
              <th>Valor Espera</th>
              <th>Valor Viaje</th>
              <th>Comisión Agencia</th>
              <th>Peajes</th>
              <th>IVA</th>
              <th>Total</th>
              <th>Total+IVA</th>
            </tr>
          </thead>
          <tbody>
            {modules.map((mod) => (
              <tr>
                <td>
                  {mod.id_accident + "-"}{" "}
                  <GetProperty
                    doc={FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module)}
                    property="art.authorization"
                  />
                </td>
                <td>
                  <Moment
                    date={FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module)}
                    format="DD/MM/YYYY HH:mm"
                  />
                </td>
                <td>
                  <GetProperty
                    doc={FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module)}
                    property="passenger.name"
                  />
                </td>
                <td>
                  <GetProperty
                    doc={FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module)}
                    property="origin.address.street"
                  />{" "}
                  <GetProperty
                    doc={FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module)}
                    property="origin.address.number"
                  />
                </td>
                <td>
                  <GetProperty
                    doc={FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module)}
                    property="origin.address.district"
                  />
                </td>
                <td>
                  <GetProperty
                    doc={FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module)}
                    property="origin.address.province"
                  />
                </td>
                <td>
                  <GetProperty
                    doc={FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module)}
                    property="destination.address.street"
                  />{" "}
                  <GetProperty
                    doc={FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module)}
                    property="destination.address.number"
                  />
                </td>
                <td>
                  <GetProperty
                    doc={FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module)}
                    property="destination.address.district"
                  />
                </td>
                <td>
                  <GetProperty
                    doc={FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module)}
                    property="destination.address.province"
                  />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>{mod.wait_time}</td>
                <td>{mod.base}</td>
                <td>{mod.agency_commission}</td>
                <td>{mod.tolls}</td>
                <td>{mod.ImpIVA}</td>
                <td>{mod.total}</td>
                <td>{mod.total + mod.ImpIVA}</td>
              </tr>
            ))}
          </tbody>
        </AdminTable>
      </Modal.Body>
    </Modal>
  );
};

export const UserName = ({
  uid,
  surname,
}: {
  uid: string;
  surname?: boolean;
}) => {
  const v = useDatabaseDocref(FIRESTORE.DOCUMENT_USER(uid));
  if (!isLoaded(v)) return <Spinner />;
  if (!v) return <>ERROR</>;
  return (
    <>
      {v.name}
      {surname && ` ${v.surname}`}
    </>
  );
};

export const GetProperty = ({
  doc,
  property,
}: {
  doc: FbDocument;
  property: string;
}) => {
  const v = useDatabaseDocref(doc);
  if (!isLoaded(v)) return <>Cargando...</>;
  if (v === undefined) return <>ERROR</>;

  return <>{dotToProperty(v, property)}</>;
};
/*
export const GetNewDrivers = ({
  doc,
  
}: {
  doc: FbDocument;
  
}) => {
  const v = useDatabaseDocref(doc);
  if (!isLoaded(v)) return <Spinner />;
  if (!v) return <>ERROR</>;
  if (v.length === 0){
  return (
    <>
      (<H3>New</H3>)
      
    </>
  )}else return ("")
};

*/
export const GetPropertyDate = ({
  doc,
  property,
}: {
  doc: FbDocument;
  property: string;
}) => {
  const v = useDatabaseDocref(doc);
  if (!isLoaded(v)) return <>Cargando...</>;
  if (v === undefined) return <>ERROR</>;
  return (
    <Moment date={dotToProperty(v, property).toDate()} format="DD/MM/YYYY" />
  );
};

const dotToProperty = (v: any, p: string) => dotToPropertyAux(v, p.split("."));
const dotToPropertyAux = (v: Object, ps: string[]): any => {
  if (ps.length === 0 || v === undefined) return v;

  const prop = ps.shift();
  // @ts-ignore: Unreachable code error
  return dotToPropertyAux(v[prop], ps);
};

const getCrossExperta = (
  origin: LocationModule | undefined,
  destination: LocationModule | undefined,
  type: number,
  origin_time?: FbDate,
  crossDataBase?: any[]
) => {
  console.log(type, "soy tipo");
  let multiply = 1;
  if (
    origin_time!.toDate() >= new Date("2021/04/01") &&
    origin_time!.toDate() < new Date("2021/06/01")
  ) {
    multiply = 1.14;
  } else if (
    origin_time!.toDate() >= new Date("2021/06/01") &&
    origin_time!.toDate() < new Date("2021/07/01")
  ) {
    multiply = 1.14 * 1.13;
  } else if (
    origin_time!.toDate() >= new Date("2021/07/01") &&
    origin_time!.toDate() < new Date("2021/10/01")
  ) {
    multiply = 1.14 * 1.13 * 1.08;
  } else if (
    origin_time!.toDate() >= new Date("2021/10/01") &&
    origin_time!.toDate() < new Date("2022/01/01")
  ) {
    multiply = 1.14 * 1.13 * 1.08 * 1.06;
  } else if (
    origin_time!.toDate() >= new Date("2022/01/01") &&
    origin_time!.toDate() < new Date("2022/04/01")
  ) {
    multiply = 1.14 * 1.13 * 1.08 * 1.06 * 1.075;
  }
  let price: any;
  const cruces = crossExperta as any;
  if (
    origin_time !== undefined &&
    origin_time.toDate() >= new Date("2022/04/01")
  ) {
    const indexer = `${origin!.id}-${destination!.id}`;
    const cruce = crossDataBase?.find((cross: { index: string[] }) =>
      cross.index.includes(indexer)
    );
    console.log(crossDataBase, "soy cruce");
    if (cruce !== undefined) {
      if (type == 0) {
        price = cruce.tramo;
        console.log(price, 0);
      }

      if (type == 1) {
        price = cruce.modulo;
        console.log(price, 1);
      }

      if (type == 2) {
        price = cruce.negativo;
        console.log(price, 2);
      }
    }
    return (price * multiply).toFixed();
  } else if (origin && destination) {
    cruces.default.cruces.forEach((item: any) => {
      if (
        origin.id == destination.id &&
        destination.province?.id == origin.province?.id
      ) {
        if (
          item["ID ciudad origen"] === destination.id &&
          item["ID provincia origen"] === destination.province?.id &&
          item["ID ciudad destino"] === destination.id &&
          item["ID provincia destino"] === destination.province?.id
        ) {
          if (type == 0) {
            price = item.tramo;
            console.log(price, "0b");
          }

          if (type == 1) {
            price = item.modulo;
            console.log(price, "1b");
          }

          if (type == 2) {
            price = item.negativo;
            console.log(price, "2b");
          }
        }
      } else {
        if (
          (item["ID ciudad origen"] == origin.id &&
            item["ID provincia origen"] == origin.province?.id) ||
          (item["ID ciudad destino"] == origin.id &&
            item["ID provincia destino"] == origin.province?.id)
        ) {
          if (
            (item["ID ciudad origen"] === destination.id &&
              item["ID provincia origen"] === destination.province?.id) ||
            (item["ID ciudad destino"] === destination.id &&
              item["ID provincia destino"] === destination.province?.id)
          ) {
            if (type == 0) {
              price = item.tramo;
              console.log(price, "0b");
            }

            if (type == 1) {
              price = item.modulo;
              console.log(price, "1b");
            }

            if (type == 2) {
              price = item.negativo;
              console.log(price, "2b");
            }
          }
        }
      }
    });
    return (price * multiply).toFixed();
  }
};

export default BillingInfo;
