import { Field } from "components/input/field";
import { Button, H3, SubmitButton } from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { WithId } from "controllers/database";
import { getArt, getActiveUsers } from "controllers/user";
import React, { useEffect, useState } from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { reduxForm, SubmissionError } from "redux-form";
import { ArtTransferInfo, Passenger, TransferType } from "types/transfer";
import { User } from "types/user-data";
import { removeUndefined } from "util/meta";
import _ from "lodash";
import moment from "moment";
import { FIRESTORE } from "constants/firestore";
import { ModuleTransfer } from "types/module-transfer";

type Meet = {
  address?: string;
  time?: string;
};
export interface EditModuleTransfer {
  type?: TransferType;
  amount?: number;
  origin?: Meet;
  destination?: Meet;
  backtrack?: Meet;
  passenger?: Partial<Passenger>;
  art?: Partial<ArtTransferInfo>;
  obs?: string;
  provider?: string;
  transfer_id?: string;
}

export const EditModal = ({
  show,
  handleClose,
  idModule,
  hasBacktrack,
}: {
  show: boolean;
  handleClose: any;
  idModule: string;
  hasBacktrack: boolean;
  data: ModuleTransfer;
}) => {
  const handleSubmit = async (values: any) => {
    console.log(
      values.destination_date && (values.destination_date as Date).toJSON()
    );
    const data: EditModuleTransfer = {
      origin: {
        address: values.origin_address,
        time: values.origin_date && (values.origin_date as Date).toJSON(),
      },
      destination: {
        address: values.destination_address,
        time:
          values.destination_date && (values.destination_date as Date).toJSON(),
      },
      passenger: {
        dni: values.dni,
        name: values.name,
        tel: values.tel,
      },
      art: {
        accident: values.art_id_accident,
        expert: values.art_id_expert,
        authorization: values.art_id_authorization,
        id: values.art,
      },
      obs: values.obs,
      amount: values.amount,
      provider: values.provider,
      transfer_id: values.art_id_expert,
    };
    removeUndefined(data);
    try {
      return CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_EDIT(idModule, data);
    } catch (e) {
      console.log(e);
      throw new SubmissionError({ _error: e.message });
    }
  };
  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Editar Módulo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>SOLO COMPLETAR LOS CAMPOS QUE SE QUIERAN MODIFICAR</h4>
        <FormEditTransferRedux onSubmit={handleSubmit} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={handleClose}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const FormEditTransfer = ({
  handleSubmit,
  submitSucceeded,
  submitting,
  error,
}: {
  handleSubmit: any;
  submitSucceeded: boolean;
  submitting: boolean;
  error: any;
}) => {
  const [artOptions, setArtOptions] = useState<(WithId & User)[]>([]);
  const [userOptions, setUserOptions] = useState<(WithId & User)[]>([]);

  useEffect(() => {
    getArt().then(setArtOptions);
    getActiveUsers().then((v) =>
      setUserOptions(
        _.sortBy(v, [
          (z) => _.toLower(`${z.surname} ${z.name} ${z.phone.number}`),
        ])
      )
    );
  }, []);

  useEffect(() => {
    getArt().then(setArtOptions);
    getActiveUsers().then((v) =>
      setUserOptions(_.sortBy(v, (z) => z.surname.toLocaleLowerCase()))
    );
  }, []);

  if (submitSucceeded) {
    return <Alert variant="success">Módulo modificado</Alert>;
  }
  console.log(
    moment(new Date()).format("MM/DD/YY") <
      moment(new Date()).add(31, "days").format("MM/DD/YY"),
    moment(new Date()).format("MM/DD/YY"),
    moment(new Date()).add(31, "days").format("MM/DD/YY")
  );

  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error.message}</Alert>}</Row>

      <Row>
        <Col xl={2}>
          <Field
            labelinput={<>Cod. Siniestro</>}
            name="art_id_accident"
            type="text"
          />
        </Col>
        <Col xl={2}>
          <Field
            labelinput={<>Cod. Autorización</>}
            name="art_id_authorization"
            type="text"
          />
        </Col>
        <Col xl={2}>
          <Field
            labelinput={<>ID Experta</>}
            name="art_id_expert"
            type="text"
          />
        </Col>
        <Col xl={6}>
          <Field labelinput={<>ART</>} name="art" type="select-text">
            <option disabled label="Seleccione una opción"></option>
            {artOptions.map((e) => (
              <option key={e.id} value={e.id}>
                {e.name}
              </option>
            ))}
          </Field>
        </Col>
        <Col xl={6}>
          <Field
            labelinput={<>Fecha y hora turno</>}
            name="destination_date"
            type="datetime-picker"
          />
        </Col>
      </Row>

      <H3>PACIENTE</H3>

      <Row>
        <Col xl={6}>
          <Field labelinput={<>Nombre Completo</>} name="name" type="text" />
        </Col>
        <Col xl={3}>
          <Field labelinput={<>Teléfono</>} name="tel" type="number" />
        </Col>
        <Col xl={3}>
          <Field labelinput={<>DNI</>} name="dni" type="number" />
        </Col>
      </Row>

      <Row>
        <Col xl={6}>
          <Row>
            <Col>
              <H3>DIRECCION ORIGEN</H3>
            </Col>
            <Col xl={12}>
              <Field
                labelinput={
                  <>Dirección completa (tiene que seleccionar una opción)</>
                }
                name="origin_address"
                type="address"
              />
            </Col>
          </Row>
        </Col>

        <Col xl={6}>
          <Row>
            <Col>
              <H3>DIRECCION DESTINO</H3>
            </Col>
            <Col xl={12}>
              <Field
                labelinput={
                  <>Dirección completa (tiene que seleccionar una opción)</>
                }
                name="destination_address"
                type="address"
              />
            </Col>
          </Row>
        </Col>
        <Col xl={6}>
          <Field
            labelinput={<>Observaciones</>}
            name="obs"
            as="textarea"
            rows="4"
            type="textarea"
          />
        </Col>
        <Col xl={6}>
          <Field
            labelinput={
              <>Asignar Prestador (solo si no tiene asignado conductor)</>
            }
            name="provider"
            type="select-text"
          >
            <option label="Seleccione una opción"></option>
            {userOptions.map((e) => (
              <option key={e.id} value={e.id}>
                {e.surname +
                  " " +
                  e.name +
                  " - " +
                  e.phone.area_code +
                  e.phone.number}
              </option>
            ))}
          </Field>
        </Col>
      </Row>

      <Row>
        <Col>
          <SubmitButton block submitting={submitting}>
            Actualizar
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};

const FormEditTransferRedux = reduxForm({
  form: "new-transfer",
})(FormEditTransfer);
