import { moduleTransferStage } from "components/Admin/Transfers/view/module/header";
import {
  Button,
  H2,
  NoData,
  Padding,
  Searcher,
  Spinner,
} from "components/styles";
import ROUTES from "constants/routes";
import { WithId } from "controllers/database";
import { useDbCollection } from "controllers/hooks";
import { getFullAddress } from "controllers/map";
import { isEmpty } from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Row, Table as TableB } from "react-bootstrap";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { Link, useHistory } from "react-router-dom";
import { uidSelector } from "redux/selectors";
import styled from "styled-components";
import {
  DefinitionsModuleTransferType,
  ModuleTransfer,
} from "types/module-transfer";
import { TransferStage } from "types/transfer";
import { usePaginator } from "util/hooks";
import {
  getCollectionByDate,
  getCollectionSearchByKeyWords,
  getModulesByType,
  getCollectionByDateKeyWordsFilter,
  getCollectionByDateFilter,
  getCollectionByDateKeyWords,
  getCollectionByKeyWordsFilter,
} from "./reducer";

export const ArtHomePage = () => {
  const pageLimit = 30;
  const art = useSelector(uidSelector);
  const [items, loading, Pagination, setCollection] = usePaginator(
    getModulesByType(0, art),
    pageLimit
  );
  const history = useHistory();
  const parsed = queryString.parse(window.location.search);
  const [searchDate, setSearchDate] = useState<string>("");
  const [searchKeyWord, setSearchKeyWord] = useState<string>("");
  useEffect(() => {
    if (Object.keys(parsed).length === 0) {
      setCollection(getModulesByType(Number(0), art));
    }
    if (
      parsed.date !== undefined &&
      parsed.filterType !== undefined &&
      parsed.keyword !== undefined
    ) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setSearchKeyWord(parsed.keyword as string);
        setCollection(
          getCollectionByDateKeyWordsFilter(
            dateSearch.toDate(),
            dateSearch2.toDate(),
            parsed.keyword as string,
            Number(parsed.filterType),
            art
          )
        );
      }
    } else if (parsed.date !== undefined && parsed.filterType !== undefined) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setCollection(
          getCollectionByDateFilter(
            dateSearch.toDate(),
            dateSearch2.toDate(),
            Number(parsed.filterType),
            art
          )
        );
      }
    } else if (parsed.date !== undefined && parsed.keyword !== undefined) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setSearchKeyWord(parsed.keyword as string);
        setCollection(
          getCollectionByDateKeyWords(
            dateSearch.toDate(),
            dateSearch2.toDate(),
            parsed.keyword as string,
            art
          )
        );
      }
    } else if (
      parsed.filterType !== undefined &&
      parsed.keyword !== undefined
    ) {
      setSearchKeyWord(parsed.keyword as string);
      setCollection(
        getCollectionByKeyWordsFilter(
          parsed.keyword as string,
          Number(parsed.filterType),
          art
        )
      );
    } else if (parsed.filterType !== undefined) {
      if (parsed.filterType !== undefined)
        setCollection(getModulesByType(Number(parsed.filterType), art));
    } else if (parsed.keyword !== undefined) {
      setSearchKeyWord(parsed.keyword as string);
      setCollection(
        getCollectionSearchByKeyWords(parsed.keyword as string, art)
      );
    } else if (parsed.date !== undefined) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setCollection(
          getCollectionByDate(dateSearch.toDate(), dateSearch2.toDate(), art)
        );
      }
    }
  }, [parsed.filterType, parsed.keyword, parsed.date]);

  const changeType = (type: number) => history.push(`?filterType=${type}`);

  const searchByKeyWord = (s: string) => {
    if (s === "") return changeType(0);

    history.push(`?keyword=${s}`);
  };

  const searchByDate = (s: string) => {
    if (s === "") return changeType(0);

    history.push(`?date=${s}`);
  };

  const optionsFilter = [
    "Elegir Etapa (Todas)",
    "Pendiente",
    "Asignados",
    "Un tramo finalizado",
    "Iniciado",
    "En Origen",
    "En Viaje",
    "En Espera",
    "Retorno solicitado",
    "Informado",
    "Finalizado",
    "Negativo",
    "Finalizado, Retorno Negativo",
    "Cancelado",
    "Esperando Acción",
  ];

  return (
    <Padding pad="5px">
      <Row>
        <Col md={2}>
          <H2>Traslados</H2>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Form.Control
            as="select"
            value={parsed.filterType !== undefined ? parsed.filterType! : "0"}
            onChange={(event) =>
              changeType(parseInt((event.target as HTMLTextAreaElement).value))
            }
          >
            {optionsFilter.map((op, index) => (
              <option key={index} value={index}>
                {op}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col md={4}>
          <Searcher
            onChange={setSearchDate}
            onSearch={searchByDate}
            placeHolder="Buscar por fecha (24/10/2019)"
            value={searchDate}
          />
        </Col>
        <Col md={4}>
          <Searcher
            onChange={setSearchKeyWord}
            onSearch={searchByKeyWord}
            placeHolder="Buscar por palabras clave"
            value={searchKeyWord}
            tooltip={
              <>
                Nombre/teléfono/dni paciente
                <br />
                Nombre del conductor
                <br />
                Nombre art
                <br />
                Siniestro
                <br />
                Cod. Aut
                <br />
                Cod. Experta
                <br />
                NPA
                <br />
                Dirección origen/destino
              </>
            }
          />
        </Col>

        {!art && (
          <Col md={1}>
            <Link to={ROUTES.ADMIN_NEW_TRANSFER}>
              <Button variant="dark">Nuevo</Button>
            </Link>
          </Col>
        )}
      </Row>

      {!art && <NotificationsGivebackTransfers optionsFilter={optionsFilter} />}

      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        <Table hover responsive>
          <thead>
            <Tr>
              <th>Siniestro-Aut/NPA</th>
              <th>Modalidad</th>
              <th>Etapa</th>
              <th>ART</th>
              <th>Fecha Hora Turno</th>
              <th>Paciente</th>
              <th>Origen</th>
              <th>Destino</th>
              <th>Retorno</th>
              <th></th>
            </Tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id}>
                <Td style={{ wordBreak: "break-all" }}>
                  {item.art.accident && item.art.authorization
                    ? item.art.accident + "-" + item.art.authorization
                    : item.art.accident
                    ? item.art.accident
                    : "NPA: " + item.art.npa}
                </Td>
                <Td>{DefinitionsModuleTransferType[item.type]}</Td>
                <Td>{moduleTransferStage(item)}</Td>
                <Td>{item.art.name}</Td>
                <Td>
                  <Moment
                    date={item.destination.time.toDate()}
                    format="DD/MM/YYYY HH:mm"
                  />
                </Td>
                <Td>{item.passenger.name}</Td>
                <Td>{getFullAddress(item.origin)}</Td>
                <Td>{getFullAddress(item.destination)}</Td>
                <Td>{item.backtrack && getFullAddress(item.backtrack)}</Td>
                <Td>
                  <Link to={ROUTES.ART_TRANSFER_VIEW(item.id)}>
                    <Button variant="light">Ver</Button>
                  </Link>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Pagination />
    </Padding>
  );
};

const NotificationsGivebackTransfers = ({
  optionsFilter,
}: {
  optionsFilter: string[];
}) => {
  const items = useDbCollection<ModuleTransfer & WithId>("module_transfer", [
    ["status.stage", "==", TransferStage.waiting_admin_action],
  ]);
  if (isLoaded(items) && !isEmpty(items))
    return (
      <Alert variant="danger">
        <Link
          to={`?filterType=${optionsFilter.findIndex(
            (v) => v === "Esperando Acción"
          )}`}
        >
          Hay traslados esperando acción del admin
        </Link>
      </Alert>
    );
  else return <></>;
};

const Tr = styled.tr`
  th {
    text-align: center;
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  word-wrap: break-word;
`;

const Table = styled(TableB)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default ArtHomePage;
