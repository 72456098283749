import { Uid } from "controllers/auth";
import {
  Licence,
  PersonalInformation,
  ProfileStatus,
  TaxableEntity,
  User,
  GpsStatus,
} from "types/user-data";
import database, { WithId } from "../database";

export enum UserType {
  regular,
  admin,
  art,
}

export const getUsers = (s: ProfileStatus): Promise<(WithId & User)[]> => {
  return database.collection("user").as(User);
};
export const getAllUsers = (): Promise<(WithId & User)[]> => {
  return database.collection("user").as(User);
};

export interface CartaOfertaData {
  personal: PersonalInformation;
  licence: Licence;
  taxable: TaxableEntity;
  email: string;
  promo_code?: string;
}

export const createUser = (uid: Uid, data: User | (User & Credential)) => {
  database.collection("user").doc(uid).set(data, User);
};

export const getUserType = async (uid: Uid): Promise<UserType> => {
  if (!uid) return UserType.regular;
  return database
    .collection("user")
    .doc(uid)
    .as(User)
    .then((user) => user?.type || UserType.regular)
    .catch(() => UserType.regular);
};

export const getUser = (uid: Uid): Promise<User> =>
  database.collection("user").doc(uid).as(User);

export const getUserDocRef = (uid: string) => [`user`, uid];

export const getPositionUserPath = (uid: string) => `user/${uid}/gps`;

export const getPositionsUser = (uid: string, limit: number) =>
  database
    .collection(`user/${uid}/gps`)
    .orderBy("date")
    .limit(limit)
    .asWithoutUid(GpsStatus);

export const getPositionUser = (uid: string) =>
  database
    .doc(`user/${uid}`)
    .as(User)
    .then((u) => u.gps);

export const getUserAdmin = (uid: Uid): Promise<(WithId & User)[]> =>
  database.collection("user").as(User);

export const getArt = (): Promise<(WithId & User)[]> =>
  database
    .collection("user")
    .where("type")
    .is(UserType.art)
    .orderBy("name", "asc")
    .asWithoutUid(User);

export const getActiveUsers = (): Promise<(WithId & User)[]> =>
  database
    .collection("user")
    .where("profile_user_status")
    .is(ProfileStatus.APROBADO)
    .asWithoutUid(User);

export const getFailedUsers = (): Promise<(WithId & User)[]> =>
  database
    .collection("user")
    .where("profile_user_status")
    .is(ProfileStatus.DESAPROBADO)
    .asWithoutUid(User);

export const getInRevisionUsers = (): Promise<(WithId & User)[]> =>
  database
    .collection("user")
    .where("profile_user_status")
    .is(ProfileStatus["A REVISAR"])
    .asWithoutUid(User);
