import { Table } from "antd";
import { Link, Padding, Spinner } from "components/styles";
import ROUTES from "constants/routes";
import { WithId } from "controllers/database";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { getZonesSaleConfig } from "./reducer";
import { Sale } from "types/sale";

const Venta = () => {
  const [items, setItems] = useState([] as (Sale & WithId)[]);
  const [loading, setLoading] = useState(true);

  const updateData = () => {
    setLoading(true);
    getZonesSaleConfig()
      .then(setItems)
      .then(() => setLoading(false));
  };
  console.log(items);

  useEffect(() => {
    updateData();
  }, []);

  if (loading) return <Spinner />;
  return (
    <Padding pad="10px">
      <Row style={{ marginBottom: 10 }}>
        <Col md={{ span: 2, offset: 10 }}>
          <Link to={ROUTES.ADMIN_CONFIGURATION_NEW_ZONE_SALE}>
            <Button variant="dark" block>
              Nueva Zona
            </Button>
          </Link>
        </Col>
      </Row>
      <TableConfig
        items={items}
        link={ROUTES.ADMIN_CONFIGURATION_VIEW_SALE_ZONE}
      />
    </Padding>
  );
};

export const TableConfig = ({
  items,
  link,
}: {
  items: (Sale & WithId)[];
  link: (id: string) => string;
}) => {
  const columns: any = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      width: 80,
      fixed: "left",
    },
    {
      title: "Precio",
      children: [
        {
          title: "Por Km",
          dataIndex: "precioxKm",
          key: "precioxKm",
          width: 70,
        },
        {
          title: "Viaje Mínimo",
          dataIndex: "precioViajeMin",
          key: "precioViajeMin",
          width: 70,
        },
        {
          title: "Viaje Negativo",
          dataIndex: "precioViajeNegativo",
          key: "precioViajeNegativo",
          width: 85,
        },
      ],
    },
    {
      title: "Km",
      children: [
        {
          title: "Km Viaje Mínimo",
          dataIndex: "kmMin",
          key: "kmMin",
          width: 70,
        },
      ],
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 110,
      fixed: "right",
      render: (_: any, record: WithId & Sale) => (
        <Link to={link(record.id)}>
          <Button variant="light">Ver/Editar</Button>
        </Link>
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < items.length; i++) {
    data.push({
      key: i,
      nombre: items[i].name,
      precioxKm: items[i].price_km,
      precioViajeMin: items[i].price_min_trip,
      precioViajeNegativo: items[i].price_negative,
      kmMin: items[i].km_minimun_trip,
      id: items[i].id,
    });
  }
  console.log(data, "data");

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      scroll={{ x: 1500, y: 700 }}
    />
  );
};

export default Venta;
