import {
  Button,
  H2,
  NoData,
  Padding,
  Spinner,
  AsyncButton,
} from "components/styles";
import { FIRESTORE } from "constants/firestore";
import firebase from "firebase/app";
import ROUTES from "constants/routes";
import React, { useEffect, useState } from "react";
import { Form, Table as TableB, Col, Row } from "react-bootstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  DefinitionsModuleTransferType,
  TypeModuleRejectSpanish,
  ModuleTransfer,
} from "types/module-transfer";
import { FinishStatus } from "types/transfer";
import MiniNavigationErrors from "../MiniNavigation";
import { moduleTransferStage } from "components/Admin/Transfers/view/module/header";
import { usePaginator } from "util/hooks";
import { DatePickerInput } from "components/input/date-picker";
import moment from "moment";
import { getCollectionByArt } from "./reducer_missing_bill_info";

const AdminTransfersMissingBillIU = () => {
  return (
    <MiniNavigationErrors active={3}>
      <MainContainer
        viewTransfer={ROUTES.ADMIN_MODULE_TRANSFER_VIEW}
        art={false}
      />
    </MiniNavigationErrors>
  );
};

export const MainContainer = ({
  viewTransfer,
  art,
}: {
  viewTransfer: (a: any) => string;
  art: boolean;
}) => {
  const pageLimit = 500;
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(5, "months").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().subtract(1, "day").toDate()
  );
  const [filter, setFilter] = useState("Experta SML");
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  /* const [items, setItems] = useState<
    {
      data: ModuleTransfer;
      ref: firebase.firestore.DocumentReference<ModuleTransfer>;
    }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false); */
  const [items, loading, Pagination, setCollection] =
    usePaginator<ModuleTransfer>(
      getCollectionByArt(filter, startDate, endDate),
      pageLimit
    );

  const [items2, loading2, Pagination2, setCollection2] = usePaginator(
    getCollectionByArt(filter, startDate, endDate),
    pageLimit
  );

  const [items3, loading3, Pagination3, setCollection3] = usePaginator(
    getCollectionByArt(filter, startDate, endDate),
    pageLimit
  );

  /*   const search = async (art: string) => {
     setLoading(true);
    if (filter !== "No finalizados") {
      let modules = (
        await FIRESTORE.COLLECTION_MODULES_TRANSFER()
          .where("art.name", "==", art)
          .where("status.finished_status", "==", 0)
          .orderBy("origin.time", "desc")
          .get()
      ).docs.map((m) => ({ data: m.data(), ref: m.ref }));
      modules = modules.filter((m) => m.data.status.finished_status !== 1);
      modules = modules.filter(
        (m) => m.data.sale?.billed !== 1 && m.data.sale?.billed !== 2
      );
      setItems(modules);
    } else {
      let modules = (
        await FIRESTORE.COLLECTION_MODULES_TRANSFER()
          .where("origin.time", "<=", today)
          .get()
      ).docs.map((m) => ({ data: m.data(), ref: m.ref }));
      modules = modules.filter((m) => m.data.status.stage !== 9);
      modules = modules.filter(
        (m) => m.data.sale?.billed !== 1 && m.data.sale?.billed !== 2
      );
      modules = modules.filter(
        (m) =>
          m.data.art.name === "Experta SML" || m.data.art.name === "Feleval SRL"
      );
      setItems(modules);
    }
     
    setLoading(false);
  }; */
  useEffect(() => {
    if (filter === "Experta SML") {
      setCollection(
        FIRESTORE.COLLECTION_MODULES_TRANSFER()
          .where("art.name", "==", filter)
          .where("status.finished_status", "==", 0)
          .where("origin.time", ">=", startDate)
          .where("origin.time", "<", endDate)
          .orderBy("origin.time", "desc")
      );
    } else if (filter === "Feleval SRL") {
      setCollection2(
        FIRESTORE.COLLECTION_MODULES_TRANSFER()
          .where("art.name", "==", filter)
          .where("status.finished_status", "==", 0)
          .where("origin.time", ">=", startDate)
          .where("origin.time", "<", endDate)
          .orderBy("origin.time", "desc")
      );
    } else if (filter === "No finalizados") {
      setCollection3(
        FIRESTORE.COLLECTION_MODULES_TRANSFER()
          .where("origin.time", ">=", startDate)
          .where("origin.time", "<", endDate)
          .orderBy("origin.time", "desc")
      );
    }
  }, [filter, startDate, endDate]);

  const addBilled = async () => {
    console.log("UNO");

    /* const faltantes = selected.filter(
      (i) => i.reject?.status !== true && !i.sale?.billed
    );
    console.log(faltantes);
 */
    if (filter === "Experta SML") {
      for (let i = 0; i < items.length; i++) {
        if (items[i].reject?.status !== true && !items[i].sale?.billed) {
          const ref = (
            await FIRESTORE.DOCUMENT_MODULE_TRANSFER(items[i].id).get()
          ).ref;
          console.log(ref);

          await ref.update({
            "sale.billed": 0,
          });
        }
      }
    } else if (filter === "Feleval SRL") {
      for (let i = 0; i < items2.length; i++) {
        if (items2[i].reject?.status !== true && !items2[i].sale?.billed) {
          const ref = (
            await FIRESTORE.DOCUMENT_MODULE_TRANSFER(items2[i].id).get()
          ).ref;
          console.log(ref);

          await ref.update({
            "sale.billed": 0,
          });
        }
      }
    }
  };

  const removeReject = async () => {
    for (let i = 0; i < selected.length; i++) {
      const ref = (
        await FIRESTORE.DOCUMENT_MODULE_TRANSFER(selected[i].id).get()
      ).ref;
      await ref.update({
        reject: firebase.firestore.FieldValue.delete(),
      });
    }
    setSelected([]);
  };
  console.log(items3);

  return (
    <Padding pad="5px">
      <Row>
        <Col md={2}>
          <H2 style={{ marginTop: 33 }}>Sin Facturar</H2>
        </Col>

        <Col md={2}>
          <Form.Control
            style={{ marginTop: 33 }}
            as="select"
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
          >
            <option key={0} value={"Experta SML"}>
              Experta SML
            </option>
            <option key={1} value={"Feleval SRL"}>
              Feleval SRL
            </option>
            <option key={2} value={"No finalizados"}>
              No finalizados
            </option>
          </Form.Control>
        </Col>
        <Col xl={2}>
          <Form.Group>
            <Form.Label>Desde</Form.Label>
            <DatePickerInput value={startDate} onChange={setStartDate} />
          </Form.Group>
        </Col>
        <Col xl={2}>
          <Form.Group>
            <Form.Label>Hasta (Inclusive)</Form.Label>
            <DatePickerInput value={endDate} onChange={setEndDate} />
          </Form.Group>
        </Col>
        <Col md={2} style={{ marginTop: 30 }}>
          {filter !== "No finalizados" ? (
            <AsyncButton onClick={addBilled}>
              Agregar Billed Faltantes
            </AsyncButton>
          ) : (
            ""
          )}
        </Col>

        <Col md={2} style={{ marginTop: 30 }}>
          {filter !== "No finalizados" ? (
            <AsyncButton onClick={removeReject}>
              Quitar rejects seleccionados
            </AsyncButton>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <br></br>

      {loading || loading2 || loading3 ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        <Table hover responsive>
          <thead>
            <Tr>
              <th>ART</th>
              <th>Fecha</th>
              <th>Siniestro-Aut/NPA</th>
              <th>SML ID</th>
              <th>Id satapp</th>
              <th>Tipo</th>
              <th>Reject</th>
              <th>Finalizado</th>
              <th>Billed</th>
              <th>Ver</th>
              <th>Acción</th>
            </Tr>
          </thead>
          <tbody>
            {filter === "Experta SML" ? (
              <>
                {items.map((item, index) =>
                  item.sale?.billed !== 1 && item.sale?.billed !== 2 ? (
                    <tr key={index}>
                      <Td>{item.art.name}</Td>
                      <Td>
                        <Moment
                          date={item.origin.time.toDate()}
                          format="DD/MM/YYYY HH:mm"
                        />
                      </Td>
                      <Td style={{ wordBreak: "break-all" }}>
                        {item.art.accident && item.art.authorization
                          ? item.art.accident + "-" + item.art.authorization
                          : item.art.accident
                          ? item.art.accident
                          : "NPA: " + item.art.npa}
                      </Td>
                      <Td>
                        {item.transfer_id ? item.transfer_id : "Sin SML ID"}
                      </Td>
                      <Td>{item.id}</Td>
                      <Td>
                        {item.status.finished_status === FinishStatus.Negativo
                          ? "Negativo"
                          : DefinitionsModuleTransferType[item.type]}
                      </Td>
                      <Td>
                        {item.reject?.type
                          ? TypeModuleRejectSpanish[item.reject?.type]
                          : ""}
                      </Td>
                      <Td>{moduleTransferStage(item)}</Td>
                      <Td>{item.sale?.billed}</Td>
                      <Td>
                        <Link to={viewTransfer(item.id)}>
                          <Button variant="light">Ver</Button>
                        </Link>
                      </Td>
                      <Td>
                        {item.reject?.status === true ? (
                          !selected.some((select) => select.id == item.id) ? (
                            <Button
                              onClick={() => {
                                const z = [...selected];
                                z.push(item);
                                setSelected(z);
                              }}
                            >
                              Agregar
                            </Button>
                          ) : (
                            <Button
                              variant="danger"
                              onClick={() => {
                                const z = [...selected];
                                z.splice(
                                  z.findIndex(
                                    (element) => element.id == item.id
                                  ),
                                  1
                                );
                                setSelected(z);
                              }}
                            >
                              Quitar
                            </Button>
                          )
                        ) : (
                          ""
                        )}
                      </Td>
                    </tr>
                  ) : (
                    ""
                  )
                )}
                <Pagination />
              </>
            ) : filter === "Feleval SRL" ? (
              <>
                {items2.map((item) =>
                  item.sale?.billed !== 1 && item.sale?.billed !== 2 ? (
                    <tr key={item.id}>
                      <Td>{item.art.name}</Td>
                      <Td>
                        <Moment
                          date={item.origin.time.toDate()}
                          format="DD/MM/YYYY HH:mm"
                        />
                      </Td>
                      <Td style={{ wordBreak: "break-all" }}>
                        {item.art.accident && item.art.authorization
                          ? item.art.accident + "-" + item.art.authorization
                          : item.art.accident
                          ? item.art.accident
                          : "NPA: " + item.art.npa}
                      </Td>
                      <Td>
                        {item.transfer_id ? item.transfer_id : "Sin SML ID"}
                      </Td>
                      <Td>{item.id}</Td>
                      <Td>
                        {item.status.finished_status === FinishStatus.Negativo
                          ? "Negativo"
                          : DefinitionsModuleTransferType[item.type]}
                      </Td>
                      <Td>
                        {item.reject?.type
                          ? TypeModuleRejectSpanish[item.reject?.type]
                          : ""}
                      </Td>
                      <Td>{moduleTransferStage(item)}</Td>
                      <Td>{item.sale?.billed}</Td>
                      <Td>
                        <Link to={viewTransfer(item.id)}>
                          <Button variant="light">Ver</Button>
                        </Link>
                      </Td>
                      <Td>
                        {item.reject?.status === true ? (
                          !selected.some((select) => select.id == item.id) ? (
                            <Button
                              onClick={() => {
                                const z = [...selected];
                                z.push(item);
                                setSelected(z);
                              }}
                            >
                              Agregar
                            </Button>
                          ) : (
                            <Button
                              variant="danger"
                              onClick={() => {
                                const z = [...selected];
                                z.splice(
                                  z.findIndex(
                                    (element) => element.id == item.id
                                  ),
                                  1
                                );
                                setSelected(z);
                              }}
                            >
                              Quitar
                            </Button>
                          )
                        ) : (
                          ""
                        )}
                      </Td>
                    </tr>
                  ) : (
                    ""
                  )
                )}
                <Pagination2 />
              </>
            ) : filter === "No finalizados" ? (
              <>
                {items3.map((item) =>
                  item.status.stage !== 9 ? (
                    item.sale?.billed !== 1 && item.sale?.billed !== 2 ? (
                      <tr key={item.id}>
                        <Td>{item.art.name}</Td>
                        <Td>
                          <Moment
                            date={item.origin.time.toDate()}
                            format="DD/MM/YYYY HH:mm"
                          />
                        </Td>
                        <Td style={{ wordBreak: "break-all" }}>
                          {item.art.accident && item.art.authorization
                            ? item.art.accident + "-" + item.art.authorization
                            : item.art.accident
                            ? item.art.accident
                            : "NPA: " + item.art.npa}
                        </Td>
                        <Td>
                          {item.transfer_id ? item.transfer_id : "Sin SML ID"}
                        </Td>
                        <Td>{item.id}</Td>
                        <Td>
                          {item.status.finished_status === FinishStatus.Negativo
                            ? "Negativo"
                            : DefinitionsModuleTransferType[item.type]}
                        </Td>
                        <Td>
                          {item.reject?.type
                            ? TypeModuleRejectSpanish[item.reject?.type]
                            : ""}
                        </Td>
                        <Td>{moduleTransferStage(item)}</Td>
                        <Td>{item.sale?.billed}</Td>
                        <Td>
                          <Link to={viewTransfer(item.id)}>
                            <Button variant="light">Ver</Button>
                          </Link>
                        </Td>
                        <Td>{""}</Td>
                      </tr>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )
                )}
                <Pagination3 />
              </>
            ) : null}
          </tbody>
        </Table>
      )}
    </Padding>
  );
};

const Tr = styled.tr`
  th {
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  word-wrap: break-word;
`;

const Table = styled(TableB)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default AdminTransfersMissingBillIU;
