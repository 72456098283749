import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DatePickerInput = ({
  disabled,
  name,
  value,
  onBlur,
  onChange,
  isInvalid,
  isValid,
  ...props
}: any) => {
  let className = "form-control";
  let validClassName;
  if (isInvalid) validClassName = "is-invalid";
  if (isValid) validClassName = "is-valid";
  return (
    <div
      style={{ ...props.style, display: "block" }}
      className={validClassName}
    >
      <DatePicker
        value={value}
        selected={value}
        onChange={(date: Date) => onChange(date)}
        disabled={disabled}
        wrapperClassName="w-100"
        showYearDropdown
        className={className + " " + validClassName}
        dateFormat="d/MM/yyyy"
        placeholderText="Seleccione"
        minDate={props.minDate !== undefined ? props.minDate : undefined}
        maxDate={props.maxDate !== undefined ? props.maxDate : undefined}
      />
    </div>
  );
};

export const DateTimePickerInput = ({
  disabled,
  name,
  value,
  onBlur,
  onChange,
  isInvalid,
  isValid,
  ...props
}: any) => {
  let className = "form-control";
  let validClassName;
  if (isInvalid) validClassName = "is-invalid";
  if (isValid) validClassName = "is-valid";
  return (
    <div
      style={{ ...props.style, display: "block" }}
      className={validClassName}
    >
      <DatePicker
        selected={value}
        onChange={(date: Date) => onChange(date)}
        disabled={disabled}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Hora"
        wrapperClassName="w-100"
        showYearDropdown
        className={className + " " + validClassName}
        dateFormat="d/MM/yyyy HH:mm"
        placeholderText="Seleccione"
        //onBlur={onBlur}
      />
    </div>
  );
};

export const TimePickerInput = ({
  disabled,
  name,
  value,
  onBlur,
  onChange,
  isInvalid,
  isValid,
  ...props
}: any) => {
  let className = "form-control";
  let validClassName;
  if (isInvalid) validClassName = "is-invalid";
  if (isValid) validClassName = "is-valid";
  return (
    <div
      style={{ ...props.style, display: "block" }}
      className={validClassName}
    >
      <DatePicker
        selected={value}
        onChange={(date: Date) => onChange(date)}
        disabled={disabled}
        showTimeSelect
        showTimeSelectOnly
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Hora"
        wrapperClassName="w-100"
        showYearDropdown
        className={className + " " + validClassName}
        dateFormat="HH:mm"
        placeholderText="Seleccione"
        //onBlur={onBlur}
      />
    </div>
  );
};
