import { Uid } from "controllers/auth"
import database, { WithId } from "controllers/database"
import firebase from "firebase/app"
import "firebase/firestore"
import { Car } from "types/car"
import { ScheduledTransfer, TransferRequest, TransferType } from "types/transfer"
import { BillStatus } from "types/billing"
import { FIRESTORE } from "constants/firestore"

export declare type WithCar = {carObj : Car}

export const getTransfers = (uid : Uid) : Promise<(ScheduledTransfer & WithId)[]> => (
    database.collection('user').doc(uid).collection('transfer').as(ScheduledTransfer)
)

export type WithUidOptional = { uid?: string }
   
export const getModuleInvoicesFromArt = (type : number, art: string) => 
    FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where('sale.billed_from_drivers','==', type === 0 ? BillStatus.No : BillStatus.Si ).where('art.id', '==', art)
        .orderBy('destination.time').orderBy('origin.time').orderBy('destination.geohash')

export const getTransfer = (uid : Uid, idTransfer : string) : Promise<ScheduledTransfer> => 
    database.collection('user').doc(uid).collection('transfer').doc(idTransfer).as(ScheduledTransfer)


export const getStartedTransfer = (uid : Uid, idTransfer : string) : Promise<ScheduledTransfer> => (
    database.collection("user").doc(uid).collection('transfer').doc(idTransfer).as(ScheduledTransfer)
) 

export const getUnasignedTransfer = (idTransfer : string) : Promise<TransferRequest> => (
    database.collection('transfer_request').doc(idTransfer).as(TransferRequest)
)