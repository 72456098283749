import React, { useState } from "react";
import { Button, Spinner, ButtonWithTooltip } from "components/styles";
import { Modal, Dropdown } from "react-bootstrap";
import { useAsyncFunction } from "controllers/hooks";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { ModuleTransfer } from "types/module-transfer";
import { TransferStage } from "types/transfer";

export const DropdownMenu = ({
  state,
  setState,
}: {
  state?: string;
  setState?: any;
}) => {
  const handleClick = (e: any) => {
    setState(e.target.name);
    console.log(state);
  };
  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic">
        {state ? state : "Seleccionar provincia"}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.ItemText>Seleccionar provincia</Dropdown.ItemText>
        <Dropdown.Item name="901-Capital Federal" onClick={handleClick}>
          901-Capital Federal
        </Dropdown.Item>
        <Dropdown.Item name="902-Buenos Aires" onClick={handleClick}>
          902-Buenos Aires
        </Dropdown.Item>
        <Dropdown.Item name="903-Catamarca" onClick={handleClick}>
          903-Catamarca
        </Dropdown.Item>
        <Dropdown.Item name="904-Cordoba" onClick={handleClick}>
          904-Cordoba
        </Dropdown.Item>
        <Dropdown.Item name="905-Corrientes" onClick={handleClick}>
          905-Corrientes
        </Dropdown.Item>
        <Dropdown.Item name="906-Chaco" onClick={handleClick}>
          906-Chaco
        </Dropdown.Item>
        <Dropdown.Item name="907-Chubut" onClick={handleClick}>
          907-Chubut
        </Dropdown.Item>
        <Dropdown.Item name="908-Entre Rios" onClick={handleClick}>
          908-Entre Rios
        </Dropdown.Item>
        <Dropdown.Item name="909-Formosa" onClick={handleClick}>
          909-Formosa
        </Dropdown.Item>
        <Dropdown.Item name="910-Jujuy" onClick={handleClick}>
          910-Jujuy
        </Dropdown.Item>
        <Dropdown.Item name="911-La Pampa" onClick={handleClick}>
          911-La Pampa
        </Dropdown.Item>
        <Dropdown.Item name="912-La Rioja" onClick={handleClick}>
          912-La Rioja
        </Dropdown.Item>
        <Dropdown.Item name="913-Mendoza" onClick={handleClick}>
          913-Mendoza
        </Dropdown.Item>
        <Dropdown.Item name="914-Misiones" onClick={handleClick}>
          914-Misiones
        </Dropdown.Item>
        <Dropdown.Item name="915-Neuquen" onClick={handleClick}>
          915-Neuquen
        </Dropdown.Item>
        <Dropdown.Item name="916-Rio Negro" onClick={handleClick}>
          916-Rio Negro
        </Dropdown.Item>
        <Dropdown.Item name="917-Salta" onClick={handleClick}>
          917-Salta
        </Dropdown.Item>
        <Dropdown.Item name="918-San Juan" onClick={handleClick}>
          918-San Juan
        </Dropdown.Item>
        <Dropdown.Item name="919-San Luis" onClick={handleClick}>
          919-San Luis
        </Dropdown.Item>
        <Dropdown.Item name="920-Santa Cruz" onClick={handleClick}>
          920-Santa Cruz
        </Dropdown.Item>
        <Dropdown.Item name="921-Santa Fe" onClick={handleClick}>
          921-Santa Fe
        </Dropdown.Item>
        <Dropdown.Item name="922-Santiago del Estero" onClick={handleClick}>
          922-Santiago del Estero
        </Dropdown.Item>
        <Dropdown.Item name="923-Tierra del Fuego" onClick={handleClick}>
          923-Tierra del Fuego
        </Dropdown.Item>
        <Dropdown.Item name="924-Tucuman" onClick={handleClick}>
          924-Tucuman
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
