import { FIRESTORE } from "constants/firestore";
import 'firebase/firestore';
import { FinishStatus } from "types/transfer";
import { TypesTrip } from ".";

export const getModuleTransfersFiltered = (typeTripSelected:number|undefined, artSelected:string, search:string, 
    transferStage:number, driver:string, startDate:Date, endDate:Date) => {
    
    let coll
    coll = FIRESTORE.COLLECTION_MODULES_TRANSFER()

    if(typeTripSelected !== -1 && typeTripSelected !== undefined){
        switch(typeTripSelected){
            /**
             * Viajes Ingresados: cancelados por admin, realizados, negativos
             * Viajes realizados: todos los que se terminaron
             * Viajes No realizados: todos los que no se terminaron
             * Viajes devueltos por el conductor: devueltos por conductor
             */
            case TypesTrip["Viajes devueltos"]:
                coll = coll.where('status.finished_status','==',FinishStatus.Cancelado)
            break
            case TypesTrip["Viajes ingresados"]:

            break
            case TypesTrip["Viajes no realizados"]:
                coll = coll.where('status.finished_status','in',[FinishStatus.Cancelado, FinishStatus.Negativo])
            break
            case TypesTrip["Viajes realizados"]:
                coll = coll.where('status.finished_status','in',[FinishStatus.Exitoso])
            break
        }
    }

    if(transferStage !== -1)
        coll = coll.where('status.stage','==',transferStage)

    if(artSelected)
        coll = coll.where('art.id','==',artSelected)

    if(search)
        coll = coll.where('keywords','array-contains',search.toLowerCase())
    else
        if(driver)
            coll = coll.where('keywords', 'array-contains', driver.toLowerCase())
        
    return coll.where('destination.time','>=',startDate).where('destination.time','<=',endDate)
        .orderBy('destination.time','desc').orderBy('origin.time','desc').orderBy('destination.geohash','desc')
}  