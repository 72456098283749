import {
  faChevronLeft,
  faTrash,
  faEdit,
  faHandPointUp,
  faCheckCircle,
  faSearch,
  faUserEdit,
  faUserCheck,
  faTimesCircle,
  faExclamationCircle,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  H3,
  Padding,
  Spinner,
  Link,
  AsyncButton,
  ButtonWithConfirmation,
  ButtonWithTooltip,
} from "components/styles";
import { getCarsWithRevision } from "components/User/Cars/reducer";
import {
  getRevisionLicence,
  getRevisionPersonal,
  getRevisionTaxable,
} from "components/User/Newcomers/finished/reducer";
import {
  getAddress,
  getAddressZone,
  getZones,
  Zone,
} from "components/User/Zones/reducer";
import { getUser } from "controllers/user";
import { getLicence } from "controllers/user/info/licence";
import { getPersonalInformation } from "controllers/user/info/personal";
import {
  getTaxableEntity,
  setTaxableEntity,
} from "controllers/user/info/taxable";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import * as dbZones from "components/User/Zones/reducer";
import styled from "styled-components";
import { Car } from "types/car";
import {
  CivilStatus,
  Licence,
  PersonalInformation,
  TaxableEntity,
  TaxableEntityType,
  Revision,
  RevisionStatus,
  User,
  UserType,
  Contract,
  ProfileStatus,
} from "types/user-data";
import { getContracts } from "components/User/Contracts/reducer";
import moment from "moment";
import ROUTES from "constants/routes";
import { FIRESTORE } from "constants/firestore";
import { Field } from "components/input/field";
import { reduxForm, SubmissionError } from "redux-form";
import { QuizParams } from "types/module-transfer";
import firebase from "firebase";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { WithId } from "controllers/database";

const AdminViewUserProfile = () => {
  const { uidUser } = useParams<QuizParams>();
  const [perdonalData, setPerdonalData] = useState<
    PersonalInformation | undefined
  >(undefined);
  const [licenceData, setLicenceData] = useState<Licence | undefined>(
    undefined
  );
  const [taxableData, setTaxableData] = useState<TaxableEntity | undefined>(
    undefined
  );
  const [userData, setUserData] = useState<User | undefined>(undefined);
  const [carsData, setCarsData] = useState<(WithId & Car & Revision)[]>([]);
  const [contracts, setContracts] = useState<(WithId & Contract)[]>([]);
  const [zonesData, setZonesData] = useState<Zone[]>([]);
  const [editName, setEditName] = useState<boolean>(false);
  const [editSurname, setEditSurname] = useState<boolean>(false);
  const [editCBU, setEditCBU] = useState<boolean>(false);
  const [editCVU, setEditCVU] = useState<boolean>(false);
  const [editCuit, setEditCuit] = useState<boolean>(false);
  const [editBankEntry, setEditBankEntry] = useState<boolean>(false);
  const [editDelegation, setEditDelegation] = useState<boolean>(false);
  const [editAuthorizationPool, setEditAuthorizationPool] =
    useState<boolean>(false);
  const [editAuthorizationRequestReturn, setEditAuthorizationRequestReturn] =
    useState<boolean>(false);
  const [editAccountType, setEditAccountType] = useState<boolean>(false);
  const [editPhone, setEditPhone] = useState<boolean>(false);
  const [editPointOfSale, setEditPointOfSale] = useState<boolean>(false);
  const [editAccountNumber, setEditAccountNumber] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>("");
  const [newSurName, setNewSurName] = useState<string>("");
  const [newCBU, setNewCBU] = useState<string>("");
  const [newCVU, setNewCVU] = useState<string>("");
  const [newCuit, setNewCuit] = useState<string>("");
  const [newBankEntry, setNewBankEntry] = useState<string>("No");
  const [newDelegation, setNewDelegation] = useState<string>("No");
  const [authorizedPool, setAuthorizedPool] = useState<string>("No");
  const [authorizedRequestReturn, setAuthorizedRequestReturn] =
    useState<string>("No");
  const [newAccountType, setNewAccountType] = useState<string>("");
  const [newPhone, setNewPhone] = useState<string>("");
  const [newArea, setNewArea] = useState<string>("");
  const [newPointOfSale, setNewPointOfSale] = useState<string>("");
  const [newAccountNumber, setNewAccountNumber] = useState<string>("");
  const [personalRevision, setPersonalRevision] = useState<
    Revision | undefined
  >(undefined);
  const [licenceRevision, setLicenceRevision] = useState<Revision | undefined>(
    undefined
  );
  const [taxableRevision, setTaxableRevision] = useState<Revision | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const republishAllScheduledTransfers = async () => {
    await CALLABLE_FUNCTIONS.ADMIN_FORCE_REPUBLISH_SCHEDULED(uidUser);
  };
  const editData = async (campo: string, profileStatus?: ProfileStatus) => {
    switch (campo) {
      case "nombre":
        {
          (await FIRESTORE.DOCUMENT_PERSONAL_DATA(uidUser).get()).ref.update({
            name: newName,
          });
          (await FIRESTORE.DOCUMENT_USER(uidUser).get()).ref.update({
            name: newName,
          });
          setEditName(false);
          setLoading(true);
        }
        break;
      case "apellido":
        {
          (await FIRESTORE.DOCUMENT_PERSONAL_DATA(uidUser).get()).ref.update({
            surname: newSurName,
          });
          (await FIRESTORE.DOCUMENT_USER(uidUser).get()).ref.update({
            surname: newSurName,
          });
          setEditSurname(false);
          setLoading(true);
        }
        break;
      case "cuit":
        {
          const gestorandoSend: boolean =
            (await FIRESTORE.DOCUMENT_USER(uidUser).get()).data()
              ?.delegationGestorando !== undefined
              ? true
              : false;

          if (gestorandoSend === true) {
            console.log("Envio la info nueva");

            const d = { id: uidUser, cuit: newCuit };
            await CALLABLE_FUNCTIONS.USER_RESEND_INFO_GESTORANDO(d);
          } else {
            (
              await FIRESTORE.DOCUMENT_USER_TAXABLE_INFO(uidUser).get()
            ).ref.update({
              cuit: newCuit,
            });
          }
          setEditCuit(false);
          setLoading(true);
        }
        break;
      case "cbu":
        {
          (
            await FIRESTORE.DOCUMENT_USER_TAXABLE_INFO(uidUser).get()
          ).ref.update({
            cbu: newCBU,
          });
          setEditCBU(false);
          setLoading(true);
        }
        break;
      case "cvu":
        {
          (
            await FIRESTORE.DOCUMENT_USER_TAXABLE_INFO(uidUser).get()
          ).ref.update({
            cvu: newCVU,
          });
          setEditCVU(false);
          setLoading(true);
        }
        break;
      case "tipoCuenta":
        {
          (
            await FIRESTORE.DOCUMENT_USER_TAXABLE_INFO(uidUser).get()
          ).ref.update({
            typeAccount: newAccountType,
          });
          setEditAccountType(false);
          setLoading(true);
        }
        break;
      case "phone":
        {
          (await FIRESTORE.DOCUMENT_USER(uidUser).get()).ref.update({
            phone: {
              area_code: newArea,
              number: newPhone,
            },
          });
          (
            await FIRESTORE.DOCUMENT_USER(uidUser)
              .collection("personal")
              .doc(uidUser)
              .get()
          ).ref.update({
            phone: {
              area_code: newArea,
              number: newPhone,
            },
          });
          setEditPhone(false);
          setLoading(true);
        }
        break;
      case "Punto de Venta":
        {
          console.log(typeof Number(newPointOfSale));
          (
            await FIRESTORE.DOCUMENT_USER_TAXABLE_INFO(uidUser).get()
          ).ref.update({
            point_of_sale: Number(newPointOfSale),
          });
          setEditPointOfSale(false);
          setLoading(true);
        }
        break;
      case "contrato":
        {
          const userToChange = await FIRESTORE.DOCUMENT_USER(uidUser).get();
          const estadoContrato = userToChange.data()?.contract_completed;
          await userToChange.ref.update({
            contract_completed: !estadoContrato,
          });
          setLoading(true);
        }
        break;
      case "account_number":
        {
          (await FIRESTORE.DOCUMENT_USER(uidUser).get()).ref.update({
            account_number: newAccountNumber,
          });
          setEditAccountNumber(false);
          setLoading(true);
        }
        break;

      case "perfil":
        {
          await FIRESTORE.DOCUMENT_USER(uidUser).update({
            profile_user_status: profileStatus,
          });
          setLoading(true);
        }
        break;

      case "validaciones":
        {
          await FIRESTORE.DOCUMENT_USER(uidUser).update({
            profile_user_status: profileStatus,
          });
          setLoading(true);
        }
        break;

      case "bankEntry":
        {
          await FIRESTORE.DOCUMENT_USER(uidUser).update({
            bank_entry: newBankEntry === "Si" ? true : false,
          });
          setEditBankEntry(false);
          setLoading(true);
        }
        break;
      case "delegation":
        {
          await FIRESTORE.DOCUMENT_USER(uidUser).update({
            delegation: newDelegation === "Si" ? true : false,
          });
          setEditDelegation(false);
          setLoading(true);
        }
        break;
      case "pool":
        {
          await FIRESTORE.DOCUMENT_USER(uidUser).update({
            authorizedPool: authorizedPool === "Si" ? true : false,
          });
          setEditAuthorizationPool(false);
          setLoading(true);
        }
        break;
      case "requestReturn":
        {
          await FIRESTORE.DOCUMENT_USER(uidUser).update({
            authorizedRequestReturn:
              authorizedRequestReturn === "Si" ? true : false,
          });
          setEditAuthorizationRequestReturn(false);
          setLoading(true);
        }
        break;
    }
  };
  const deleteBase = async (item: any) => {
    await FIRESTORE.DOCUMENT_USER(uidUser)
      .collection("zone")
      .doc(item.id)
      .delete();
  };

  useEffect(() => {
    (async () => {
      try {
        const personalData = await getPersonalInformation(uidUser);
        if (personalData) {
          setPerdonalData(personalData);
        }
      } catch (error) {
        console.error(error);
      }

      try {
        const personalDataRevisions = await getRevisionPersonal(uidUser);
        if (personalDataRevisions) {
          setPersonalRevision(personalDataRevisions);
        }
      } catch (error) {
        console.error(error);
      }

      const usersData = await getUser(uidUser);
      if (usersData) {
        setUserData(usersData);
      }
      try {
        const licencesData = await getLicence(uidUser);
        if (licencesData) {
          setLicenceData(licencesData);
        }
      } catch (error) {
        console.error(error);
      }
      try {
        const licencesRevisionData = await getRevisionLicence(uidUser);
        if (licencesRevisionData) {
          setLicenceRevision(licencesRevisionData);
        }
      } catch (error) {
        console.error(error);
      }
      try {
        const taxableData = await getTaxableEntity(uidUser);
        if (taxableData) {
          setTaxableData(taxableData);
        }
      } catch (error) {
        console.error(error);
      }
      try {
        const taxableRevision = await getRevisionTaxable(uidUser);
        if (taxableRevision) {
          setTaxableRevision(taxableRevision);
        }
      } catch (error) {
        console.error(error);
      }
      try {
        const carData = await getCarsWithRevision(uidUser);
        if (carData) {
          setCarsData(carData);
        }
      } catch (error) {
        console.error(error);
      }
      try {
        const zoneData = await getZones(uidUser);
        if (zoneData) {
          setZonesData(zoneData);
        }
      } catch (error) {
        console.error(error);
      }
      try {
        const contract = await getContracts(uidUser);
        if (contract) {
          setContracts(contract);
        }
      } catch (error) {
        console.error(error);
      }
    })();

    setLoading(false);
  }, [loading]);
  const handleSubmit = async (values: any) => {
    const location = await dbZones.toLocation(values.address).catch((error) => {
      throw new SubmissionError({ _error: error });
    });

    const newZone = {
      location: location,
      enabled: true,
    } as dbZones.Zone;
    await dbZones.newZone(uidUser, newZone);
    setLoading(true);
  };
  if (loading) return <Spinner />;

  return (
    <Padding pad="5px">
      <Row>
        <Col md={2}>
          <H3 onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </H3>
        </Col>
        <Col md={8}>
          <Row>
            {userData !== undefined ? (
              <span style={{ margin: "auto", fontSize: "1.5em" }}>
                {userData.name + " " + userData.surname} -{" "}
                <b>
                  {userData.type !== undefined &&
                    UserType[userData.type].toUpperCase()}
                </b>
              </span>
            ) : (
              ""
            )}
            {userData !== undefined &&
            userData.active_transfer !== undefined ? (
              <AsyncButton
                onClick={async () =>
                  await FIRESTORE.DOCUMENT_USER(uidUser).update({
                    active_transfer: firebase.firestore.FieldValue.delete(),
                  })
                }
              >
                Quitar active
              </AsyncButton>
            ) : (
              ""
            )}
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <Title>Datos del Usuario</Title>
          </Row>
          {userData !== undefined ? (
            <InfoRow label="Email" info={userData.email} />
          ) : (
            ""
          )}
          {perdonalData !== undefined ? (
            <InfoRow
              label="Documento"
              info={perdonalData.idType + " " + perdonalData.id}
            />
          ) : (
            ""
          )}
          {editPhone ? (
            <InfoRow3
              label="Teléfono"
              info={
                <Form.Control
                  type="text"
                  placeholder="cód. área Ej: 11"
                  onChange={(e) => setNewArea(e.target.value)}
                />
              }
              info2={
                <Form.Control
                  type="text"
                  placeholder="num Tel. Ej: 54888867"
                  onChange={(e) => setNewPhone(e.target.value)}
                />
              }
              info3={
                <ButtonWithTooltip
                  variant="success"
                  style={{ float: "right" }}
                  textTooltip="Guardar"
                  onClick={() => editData("phone")}
                >
                  <FontAwesomeIcon icon={faSave} />
                </ButtonWithTooltip>
              }
            />
          ) : userData !== undefined ? (
            <InfoRow
              label="Teléfono"
              info={userData.phone.area_code + " " + userData.phone.number}
              info2={
                <ButtonWithTooltip
                  style={{ float: "right" }}
                  textTooltip="Editar N° de telefono"
                  onClick={() => setEditPhone(true)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </ButtonWithTooltip>
              }
            />
          ) : (
            ""
          )}
          {perdonalData !== undefined ? (
            <InfoRow
              label="Dirección"
              info={getAddress(perdonalData.address.address)}
            />
          ) : (
            ""
          )}

          <Row>
            <Col>
              <Title>Estado de validaciones</Title>
              {userData !== undefined &&
              userData.profile_user_status === ProfileStatus["A REVISAR"] ? (
                <ButtonWithTooltip
                  textTooltip="Revisado"
                  style={{ float: "right" }}
                  onClick={() =>
                    editData("validaciones", ProfileStatus["REVISADO"])
                  }
                  variant="secondary"
                >
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </ButtonWithTooltip>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <InfoRow
            label="Estado del perfil"
            info={
              <Row>
                <Col>
                  {personalRevision !== undefined &&
                  personalRevision.status === RevisionStatus["No revisado"] ? (
                    <Button disabled variant="warning">
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  ) : personalRevision !== undefined &&
                    personalRevision.status === RevisionStatus["Aprobado"] ? (
                    <Button disabled variant="success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </Button>
                  ) : personalRevision !== undefined &&
                    personalRevision.status ===
                      RevisionStatus["Desaprobado"] ? (
                    <Button disabled variant="danger">
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                  ) : (
                    <Button disabled variant="info">
                      SIN DATOS
                    </Button>
                  )}
                </Col>
                <Col>
                  {personalRevision !== undefined ? (
                    <Link to={ROUTES.ADMIN_REVIEW_PROFILE(uidUser)}>
                      <ButtonWithTooltip
                        textTooltip="Revisar"
                        variant="warning"
                      >
                        <FontAwesomeIcon icon={faUserEdit} />
                      </ButtonWithTooltip>
                    </Link>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            }
          />

          <InfoRow
            label="Datos bancarios/afip"
            info={
              <Row>
                <Col>
                  {taxableRevision !== undefined &&
                  taxableRevision.status === RevisionStatus["No revisado"] ? (
                    <Button disabled variant="warning">
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  ) : taxableRevision !== undefined &&
                    taxableRevision.status === RevisionStatus["Aprobado"] ? (
                    <Button disabled variant="success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </Button>
                  ) : taxableRevision !== undefined &&
                    taxableRevision.status === RevisionStatus["Desaprobado"] ? (
                    <Button disabled variant="danger">
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                  ) : (
                    <Button disabled variant="info">
                      SIN DATOS
                    </Button>
                  )}
                </Col>
                <Col>
                  {taxableRevision !== undefined ? (
                    <Link to={ROUTES.ADMIN_REVIEW_BANKDATA(uidUser)}>
                      <ButtonWithTooltip
                        textTooltip="Revisar"
                        variant="warning"
                      >
                        <FontAwesomeIcon icon={faUserEdit} />
                      </ButtonWithTooltip>
                    </Link>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            }
          />
          <InfoRow
            label="Datos licencia"
            info={
              <Row>
                <Col>
                  {licenceRevision !== undefined &&
                  licenceRevision.status === RevisionStatus["No revisado"] ? (
                    <Button disabled variant="warning">
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  ) : licenceRevision !== undefined &&
                    licenceRevision.status === RevisionStatus["Aprobado"] ? (
                    <Button disabled variant="success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </Button>
                  ) : licenceRevision !== undefined &&
                    licenceRevision.status === RevisionStatus["Desaprobado"] ? (
                    <Button disabled variant="danger">
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                  ) : (
                    <Button disabled variant="info">
                      SIN DATOS
                    </Button>
                  )}
                </Col>
                <Col>
                  {licenceRevision !== undefined ? (
                    <Link to={ROUTES.ADMIN_REVIEW_LICENCE(uidUser)}>
                      <ButtonWithTooltip
                        textTooltip="Revisar"
                        variant="warning"
                      >
                        <FontAwesomeIcon icon={faUserEdit} />
                      </ButtonWithTooltip>
                    </Link>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            }
          />
          <InfoRow
            label="Estado General del perfil"
            info={
              <Row>
                <Col>
                  {userData !== undefined &&
                  userData.profile_user_status === ProfileStatus["APROBADO"] ? (
                    <Button disabled variant="success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </Button>
                  ) : userData !== undefined &&
                    userData.profile_user_status ===
                      ProfileStatus["A REVISAR"] ? (
                    <Button disabled variant="warning">
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  ) : userData !== undefined &&
                    userData.profile_user_status ===
                      ProfileStatus["DESAPROBADO"] ? (
                    <Button disabled variant="danger">
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                  ) : userData !== undefined &&
                    userData.profile_user_status ===
                      ProfileStatus["REVISADO"] ? (
                    <Button disabled variant="secondary">
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
                {(userData !== undefined &&
                  userData.profile_user_status ===
                    ProfileStatus["DESAPROBADO"]) ||
                (userData !== undefined &&
                  userData.profile_user_status ===
                    ProfileStatus["A REVISAR"]) ? (
                  <Col>
                    <ButtonWithTooltip
                      textTooltip="Aprobar"
                      onClick={() =>
                        editData("perfil", ProfileStatus["APROBADO"])
                      }
                      variant="success"
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </ButtonWithTooltip>
                  </Col>
                ) : (
                  <Col>
                    <ButtonWithTooltip
                      textTooltip="Desaprobar"
                      onClick={() =>
                        editData("perfil", ProfileStatus["DESAPROBADO"])
                      }
                      variant="danger"
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </ButtonWithTooltip>
                  </Col>
                )}
              </Row>
            }
          />
          <InfoRow
            label="Estado Carta Oferta"
            info={
              <Row>
                <Col>
                  {userData !== undefined && userData.contract_completed ? (
                    <Button disabled variant="success">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </Button>
                  ) : (
                    <Button disabled variant="danger">
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </Button>
                  )}
                </Col>
                {userData !== undefined && userData.contract_completed ? (
                  <Col>
                    <ButtonWithTooltip
                      textTooltip="Rechazar"
                      onClick={() => editData("contrato")}
                      variant="danger"
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </ButtonWithTooltip>
                  </Col>
                ) : (
                  <Col>
                    <ButtonWithTooltip
                      textTooltip="Aprobar"
                      onClick={() => editData("contrato")}
                      variant="success"
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </ButtonWithTooltip>
                  </Col>
                )}
              </Row>
            }
          />
          <Row>
            <Title>Autos</Title>
          </Row>
          <Row>
            {carsData.map((item) => (
              <Col key={item.licence}>
                {item.brand +
                  " " +
                  item.model +
                  " " +
                  item.color +
                  " " +
                  item.licence}
              </Col>
            ))}
            {carsData.map((item) => (
              <Col key={item.licence}>
                <Link to={ROUTES.ADMIN_CAR_VIEW(item.id, uidUser)}>
                  <ButtonWithTooltip textTooltip="Revisar" variant="warning">
                    <FontAwesomeIcon icon={faUserEdit} />
                  </ButtonWithTooltip>
                </Link>
              </Col>
            ))}
          </Row>
          <Row>
            <Title>Cartas Oferta</Title>
          </Row>
          <ul>
            {contracts.map((item, index) => (
              <Row key={index}>
                <Col>
                  <li key={item.date.toString()}>
                    {moment(item.date.toDate()).format("DD/MM/YY")}{" "}
                  </li>
                </Col>
                <Col lg="auto">
                  <Link to={ROUTES.ADMIN_CONTRACT_VIEW(item.id, uidUser)}>
                    <ButtonWithTooltip textTooltip="Ver" variant="light">
                      <FontAwesomeIcon icon={faSearch} />
                    </ButtonWithTooltip>
                  </Link>
                </Col>
              </Row>
            ))}
          </ul>
          <Row>
            <Title>Desagendar traslados</Title>
          </Row>
          {perdonalData !== undefined &&
          userData !== undefined &&
          userData.profile_user_status === ProfileStatus["APROBADO"] ? (
            <Row>
              <ButtonWithConfirmation
                variant="danger"
                className="pull-right"
                onClick={republishAllScheduledTransfers}
                confirmText={`Esta acción CANCELARA Y REPUBLICARA todos los traslados agendados correspondientes a ${perdonalData.surname} ${perdonalData.name}. Esta acción es IRREVERSIBLE. ¿Desea continuar de todas formas?`}
                style={{ marginLeft: 10 }}
              >
                Cancelar y republicar traslados agendados
              </ButtonWithConfirmation>
            </Row>
          ) : (
            <h6>Sin traslados para desagendar</h6>
          )}
        </Col>
        <Col>
          <Row>
            <Title>Datos del Perfil</Title>
          </Row>
          {editSurname ? (
            <InfoRow
              label="Apellido"
              info={
                <Form.Control
                  type="text"
                  onChange={(e) => setNewSurName(e.target.value)}
                />
              }
              info2={
                <Button onClick={() => editData("apellido")}>
                  <FontAwesomeIcon icon={faSave} />
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="Apellido"
              info={
                perdonalData !== undefined ? perdonalData.surname : "Sin cargar"
              }
              info2={
                <Button onClick={() => setEditSurname(true)}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              }
            />
          )}

          {editName ? (
            <InfoRow
              label="Nombre"
              info={
                <Form.Control
                  type="text"
                  onChange={(e) => setNewName(e.target.value)}
                />
              }
              info2={
                <Button onClick={() => editData("nombre")}>
                  <FontAwesomeIcon icon={faSave} />
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="Nombre"
              info={
                perdonalData !== undefined ? perdonalData.name : "Sin cargar"
              }
              info2={
                <Button onClick={() => setEditName(true)}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              }
            />
          )}

          <InfoRow
            label="Nacimiento"
            info={
              perdonalData !== undefined
                ? perdonalData.birthday.toDate().toLocaleDateString()
                : "Sin cargar"
            }
          />

          <InfoRow
            label="Nacionalidad"
            info={
              perdonalData !== undefined ? perdonalData.country : "Sin cargar"
            }
          />

          <InfoRow
            label="Estado civil"
            info={
              perdonalData !== undefined
                ? CivilStatus[perdonalData.civil_status]
                : "Sin cargar"
            }
          />

          <InfoRow
            label="Ocupacion"
            info={perdonalData !== undefined ? perdonalData.job : "Sin cargar"}
          />

          <InfoRow
            label="Licencia conducir"
            info={!licenceData ? "Sin cargar" : licenceData.number}
          />

          <InfoRow
            label="Razon Social Agencia"
            info={
              taxableData !== undefined
                ? taxableData.agency_relation
                : "Sin cargar"
            }
          />

          <InfoRow
            label="Tipo inscripción"
            info={
              taxableData !== undefined
                ? TaxableEntityType[taxableData.tax_entity]
                : "Sin cargar"
            }
          />

          {editAccountNumber ? (
            <InfoRow
              label="Número de cuenta bancaria"
              info={
                <Form.Control
                  type="text"
                  onChange={(e) => setNewAccountNumber(e.target.value)}
                />
              }
              info2={
                <Button onClick={() => editData("account_number")}>
                  <FontAwesomeIcon icon={faSave} />
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="Número de cuenta bancaria"
              info={
                userData !== undefined ? userData.account_number : "Sin cargar"
              }
              info2={
                <Button onClick={() => setEditAccountNumber(true)}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              }
            />
          )}
          {taxableData !== undefined && taxableData.cbu ? (
            editCBU ? (
              <InfoRow
                label="CBU"
                info={
                  <Form.Control
                    type="text"
                    onChange={(e) => setNewCBU(e.target.value)}
                  />
                }
                info2={
                  <Button onClick={() => editData("cbu")}>
                    <FontAwesomeIcon icon={faSave} />
                  </Button>
                }
              />
            ) : (
              <InfoRow
                label="CBU"
                info={taxableData.cbu}
                info2={
                  <Button onClick={() => setEditCBU(true)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                }
              />
            )
          ) : (
            ""
          )}
          {taxableData !== undefined && taxableData.cvu ? (
            editCVU ? (
              <InfoRow
                label="CVU"
                info={
                  <Form.Control
                    type="text"
                    onChange={(e) => setNewCVU(e.target.value)}
                  />
                }
                info2={
                  <Button onClick={() => editData("cvu")}>
                    <FontAwesomeIcon icon={faSave} />
                  </Button>
                }
              />
            ) : (
              <InfoRow
                label="CVU"
                info={taxableData.cvu}
                info2={
                  <Button onClick={() => setEditCVU(true)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                }
              />
            )
          ) : (
            ""
          )}

          {editBankEntry ? (
            <InfoRow
              label="Ingresó Banco"
              info={
                <Form.Control
                  as="select"
                  value={newBankEntry}
                  onChange={(e) => setNewBankEntry(e.target.value)}
                  placeholder={"Nuevo tipo de cuenta"}
                >
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Form.Control>
              }
              info2={
                <Button onClick={() => editData("bankEntry")}>
                  <FontAwesomeIcon icon={faSave} />
                </Button>
              }
            />
          ) : userData !== undefined ? (
            <InfoRow
              label="Ingresó Banco"
              info={userData.bank_entry === true ? "Si" : "No"}
              info2={
                <Button onClick={() => setEditBankEntry(true)}>
                  <FontAwesomeIcon icon={faHandPointUp} />
                </Button>
              }
            />
          ) : (
            ""
          )}

          {editCuit ? (
            <InfoRow
              label="CUIT"
              info={
                <Form.Control
                  type="text"
                  onChange={(e) => setNewCuit(e.target.value)}
                />
              }
              info2={
                <Button onClick={() => editData("cuit")}>
                  <FontAwesomeIcon icon={faSave} />
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="CUIT"
              info={taxableData !== undefined ? taxableData.cuit : ""}
              info2={
                <Button onClick={() => setEditCuit(true)}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              }
            />
          )}

          {editAccountType ? (
            <InfoRow
              label="Tipo de cuenta"
              info={
                <Form.Control
                  as="select"
                  value={newAccountType}
                  onChange={(e) => setNewAccountType(e.target.value)}
                  placeholder={"Nuevo tipo de cuenta"}
                >
                  <option value="Sin especificar">Sin especificar</option>
                  <option value="Cuenta Corriente en pesos">
                    Cuenta Corriente en pesos
                  </option>
                  <option value="Caja de ahorro en pesos">
                    Caja de ahorro en pesos
                  </option>
                </Form.Control>
              }
              info2={
                <Button onClick={() => editData("tipoCuenta")}>
                  <FontAwesomeIcon icon={faSave} />
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="Tipo de cuenta"
              info={
                taxableData !== undefined && taxableData.typeAccount
                  ? taxableData.typeAccount
                  : "Sin especificar"
              }
              info2={
                <Button onClick={() => setEditAccountType(true)}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              }
            />
          )}
          {editPointOfSale ? (
            <InfoRow
              label="Punto de Venta"
              info={
                <Form.Control
                  type="number"
                  onChange={(e) => setNewPointOfSale(e.target.value)}
                />
              }
              info2={
                <Button onClick={() => editData("Punto de Venta")}>
                  <FontAwesomeIcon icon={faSave} />
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="Punto de Venta"
              info={taxableData !== undefined ? taxableData.point_of_sale : ""}
              info2={
                <Button onClick={() => setEditPointOfSale(true)}>
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              }
            />
          )}
          {editDelegation ? (
            <InfoRow
              label="Delegó Facturación"
              info={
                <Form.Control
                  as="select"
                  value={newDelegation}
                  onChange={(e) => setNewDelegation(e.target.value)}
                  placeholder={"Nuevo tipo de cuenta"}
                >
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Form.Control>
              }
              info2={
                <Button onClick={() => editData("delegation")}>
                  <FontAwesomeIcon icon={faSave} />
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="Delegó Facturación"
              info={
                userData !== undefined && userData.delegation === true
                  ? "Si"
                  : "No"
              }
              info2={
                <Button onClick={() => setEditDelegation(true)}>
                  <FontAwesomeIcon icon={faHandPointUp} />
                </Button>
              }
            />
          )}
          {
            <InfoRow
              label="Delegó Facturación Gestorando"
              info={
                userData !== undefined && userData.delegationGestorando === true
                  ? "Si"
                  : "No"
              }
            />
          }
          {editAuthorizationPool ? (
            <InfoRow
              label="Autorizado para pool"
              info={
                <Form.Control
                  as="select"
                  value={authorizedPool}
                  onChange={(e) => setAuthorizedPool(e.target.value)}
                  placeholder={"Autorización para hacer pool"}
                >
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Form.Control>
              }
              info2={
                <Button onClick={() => editData("pool")}>
                  <FontAwesomeIcon icon={faSave} />
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="Autorizado para pool"
              info={
                userData !== undefined && userData.authorizedPool === true
                  ? "Si"
                  : "No"
              }
              info2={
                <Button onClick={() => setEditAuthorizationPool(true)}>
                  <FontAwesomeIcon icon={faHandPointUp} />
                </Button>
              }
            />
          )}
          {editAuthorizationRequestReturn ? (
            <InfoRow
              label="Autorizado para solicitar retorno"
              info={
                <Form.Control
                  as="select"
                  value={authorizedRequestReturn}
                  onChange={(e) => setAuthorizedRequestReturn(e.target.value)}
                  placeholder={"Autorización para solicitar retorno"}
                >
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Form.Control>
              }
              info2={
                <Button onClick={() => editData("requestReturn")}>
                  <FontAwesomeIcon icon={faSave} />
                </Button>
              }
            />
          ) : (
            <InfoRow
              label="Autorizado para solicitar retorno"
              info={
                userData !== undefined &&
                (userData.authorizedRequestReturn === true ||
                  userData.authorizedRequestReturn === undefined)
                  ? "Si"
                  : "No"
              }
              info2={
                <Button onClick={() => setEditAuthorizationRequestReturn(true)}>
                  <FontAwesomeIcon icon={faHandPointUp} />
                </Button>
              }
            />
          )}
        </Col>
      </Row>
    </Padding>
  );
};
/* const FormZone = ({
  handleSubmit,
  error,
  submitting,
  submitSucceeded,
}: any) => (
  <Form onSubmit={handleSubmit}>
    <Row>
      <Col xs={12} md={8}>
        <Form.Group>
          <Field type="address" name="address" />
        </Form.Group>
      </Col>
      <Col xs={12} md={4}>
        <Button type="submit" variant="info" block disabled={submitting}>
          {submitting ? (
            <Spinner size="sm" style={{}} />
          ) : (
            <FontAwesomeIcon icon={faSave} />
          )}
        </Button>
      </Col>
    </Row>
  </Form>
); */
/* const ReduxFormZone = reduxForm({
  form: "add-zone",
})(FormZone); */

const InfoRow = ({
  label,
  info,
  info2,
}: {
  label: JSX.Element | string | undefined;
  info: JSX.Element | string | undefined;
  info2?: JSX.Element | string | undefined;
}) => (
  <Row style={{ lineHeight: "2.7rem" }}>
    <Col md={4} style={{ color: "#888" }}>
      {label}
    </Col>
    <Col style={{ color: "#333" }}>{info}</Col>
    <Col style={{ color: "#333" }}>{info2}</Col>
  </Row>
);
const InfoRow3 = ({
  label,
  info,
  info2,
  info3,
}: {
  label: JSX.Element | string | undefined;
  info: JSX.Element | string | undefined;
  info2?: JSX.Element | string | undefined;
  info3?: JSX.Element | string | undefined;
}) => (
  <Row style={{ lineHeight: "2.7rem" }}>
    <Col md={4} style={{ color: "#888" }}>
      {label}
    </Col>
    <Col style={{ color: "#333" }}>{info}</Col>
    <Col style={{ color: "#333" }}>{info2}</Col>
    <Col style={{ color: "#333" }}>{info3}</Col>
  </Row>
);

const Title = styled.h3`
  margin: 10px 5px;
  padding: 10px 5px;
  background-color: #f1f1f1;
  font-size: 1.17em;
  font-weight: 700;
  width: 100%;
`;

export default AdminViewUserProfile;
