import React from "react";
import * as B from "react-bootstrap";
import styled from "styled-components";
import gpsImg from "./assets/gps.png";
import businessmanImg from "./assets/businessman.png";
import stopwatchImg from "./assets/stopwatch.png";
import { pxToPt } from "constants/css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Requirements = () => {
  return (
    <Container fluid={true} className="container-fluid border-bottom">
      <div className="container">
        <RemovePaddingOnMobile id="requirements" className="col-md-12 row">
          <div className="col-md-3 col-sm-6">
            <About className="about">
              <h2>REQUISITOS</h2>
            </About>
          </div>
          <div className="col-md-9 col-sm-6">
            <div>
              <FontAwesomeIcon icon={faCheck} /> Tener +18 años
            </div>
            <div>
              <FontAwesomeIcon icon={faCheck} /> Disponer de vehículo 2010 en
              adelante (zona sur y oeste a partir de 2006)
            </div>
            <div>
              <FontAwesomeIcon icon={faCheck} /> Licencia profesional de
              conducir (D1;D2; Profesional)
            </div>
            <div>
              <FontAwesomeIcon icon={faCheck} /> Cédula verde o azul
            </div>
            <div>
              <FontAwesomeIcon icon={faCheck} /> Seguro al día
            </div>
            <div>
              <FontAwesomeIcon icon={faCheck} /> Monotributo (Personal)
            </div>
            <div>
              <FontAwesomeIcon icon={faCheck} /> Cuenta bancaria que coincida
              con el titular del monotributo (para poder cobrar)
            </div>
          </div>
        </RemovePaddingOnMobile>
      </div>
    </Container>
  );
};

const About = styled.div`
  h5 {
    color: #d1d1d1;
    text-transform: uppercase;
    font-weight: 600;
  }
  h2 {
    font-weight: 600;
    color: #00426d;
  }
`;

const RemovePaddingOnMobile = styled.div`
  padding-top: 0.2em;
  @media only screen and (min-device-width: 800px) {
    padding-top: 3em;
  }
`;

const Container = styled(B.Container)`
  img {
    height: ${pxToPt(64)};
    width: ${pxToPt(64)};
  }
`;

export default Requirements;
