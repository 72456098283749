import { DatePickerInput } from "components/input/date-picker";
import { Button, ContainerPage, H2, Spinner } from "components/styles";
import moment from "moment";
import React, { useState } from "react";
import { Col, Form, Row, Modal, Alert } from "react-bootstrap";
import { FIRESTORE } from "constants/firestore";
import { getArtInformation } from "components/Admin/Config/NewArt/reducer";
import * as fs from "file-saver";
import { FbDate } from "types/transfer";
import { InfoRow } from "components/Admin/Transfers/view/scheduled";
import { BillInfoTransfer } from "types/billing";
const Bas = () => {
  //Buenos Aires Software
  const [showTXTModal, setShowTXTModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().endOf("month").toDate()
  );
  const consultarFecha = () => {
    alert(`Esto exportará facturas desde ${startDate} hasta ${endDate}`);
    console.log(startDate, "START DATE");
    console.log(endDate, "END DATE");
  };
  return (
    <ContainerPage>
      <Row>
        <Col>
          <H2>Buenos Aires Software</H2>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group>
            <Form.Label>Desde</Form.Label>
            <DatePickerInput value={startDate} onChange={setStartDate} />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group>
            <Form.Label>Hasta (Inclusive)</Form.Label>
            <DatePickerInput value={endDate} onChange={setEndDate} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <Button
            variant="danger"
            block
            onClick={() => generateTxtVenta(startDate, endDate, false)}
          >
            Exportar facturas de ventas
          </Button>
          <Button
            variant="success"
            block
            onClick={() => generateTxtVenta(startDate, endDate, true)}
          >
            Exportar facturas de ventas PRUEBA
          </Button>
          <Button
            variant="warning"
            block
            onClick={() => generateTxtNC(startDate, endDate, false)}
          >
            Exportar NOTAS DE CREDITO VENTAS
          </Button>
          <Button
            variant="info"
            block
            onClick={() => generateTxtNC(startDate, endDate, true)}
          >
            Exportar NOTAS DE CREDITO VENTAS PRUEBA
          </Button>
          <Button
            variant="danger"
            block
            onClick={() => generateTxtND(startDate, endDate, false)}
          >
            Exportar NOTAS DE DEBITO
          </Button>
          <Button
            variant="success"
            block
            onClick={() => generateTxtND(startDate, endDate, true)}
          >
            Exportar NOTAS DE DEBITO PRUEBA
          </Button>
        </Col>
        <Col xl={4}>
          <Button
            variant="danger"
            block
            onClick={() => generateTxtCompra(startDate, endDate, false)}
          >
            Exportar facturas de compras
          </Button>
          <Button
            variant="success"
            block
            onClick={() => generateTxtCompra(startDate, endDate, true)}
          >
            Exportar facturas de compras PRUEBA
          </Button>
          <Button
            variant="danger"
            block
            onClick={() => generateTxtNcCompra(startDate, endDate, false)}
          >
            Exportar Notas de crédito de compras
          </Button>
          <Button
            variant="success"
            block
            onClick={() => generateTxtNcCompra(startDate, endDate, true)}
          >
            Exportar Notas de crédito de compras PRUEBA
          </Button>
        </Col>
        <Col xl={4}>
          <Button block onClick={() => setShowTXTModal(true)}>
            Generador TXT Manual
          </Button>
        </Col>
        <Col xl={4}>
          <Button block onClick={() => consultarFecha()}>
            consultar fecha
          </Button>
        </Col>
      </Row>
      <TXTModal
        show={showTXTModal}
        handleClose={() => setShowTXTModal(false)}
      />
    </ContainerPage>
  );
};

const TXTModal = ({ show, handleClose }: { show: any; handleClose: any }) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [importe, setImporte] = useState<string>("");
  const [tipo, setTipo] = useState<string>("Nota crédito B");
  const [cae, setCae] = useState<string>("");
  const [puntovta, setPuntovta] = useState<string>("");
  const [fecha, setFecha] = useState<string>("");
  const [voucher, setVoucher] = useState<string>("");
  const [added, setAdded] = useState<any[]>([]);
  const closeAndReset = () => {
    setSuccess(false);
    setError(undefined);
    setFecha("");
    setPuntovta("");
    setVoucher("");
    setImporte("");
    setCae("");
    setAdded([]);
    handleClose();
  };
  return (
    <Modal show={show} onHide={closeAndReset} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Generador Manual de TXT para BAS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success ? (
          <Alert variant="success">Realizado con éxito</Alert>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : loading ? (
          <Spinner />
        ) : (
          <>
            <Row>
              <Col>
                <Form.Control
                  as="select"
                  placeholder="TIPO"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                >
                  <option value="Nota crédito B">Nota crédito B</option>
                  <option value="Nota débito B">Nota débito B</option>
                </Form.Control>
              </Col>
              <Col>
                <Form.Control
                  as="input"
                  placeholder="Importe ej: 1043.19"
                  value={importe}
                  onChange={(e) => setImporte(e.target.value)}
                ></Form.Control>
              </Col>
              <Col>
                <Form.Control
                  as="input"
                  placeholder="Fecha ej: 21/05/21"
                  value={fecha}
                  onChange={(e) => setFecha(e.target.value)}
                ></Form.Control>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Control
                  as="input"
                  placeholder="CAE"
                  value={cae}
                  onChange={(e) => setCae(e.target.value)}
                ></Form.Control>
              </Col>
              <Col>
                <Form.Control
                  as="input"
                  placeholder="Punto de venta"
                  value={puntovta}
                  onChange={(e) => setPuntovta(e.target.value)}
                ></Form.Control>
              </Col>
              <Col>
                <Form.Control
                  as="input"
                  placeholder={
                    tipo == "Nota crédito B"
                      ? "Número de nota de Crédito"
                      : "Número de nota de Débito"
                  }
                  value={voucher}
                  onChange={(e) => setVoucher(e.target.value)}
                ></Form.Control>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    console.log(
                      `${fecha}-${tipo}-${puntovta}-${voucher}-${importe}-${cae}`
                    );
                    const z = added;
                    z.push(
                      `${fecha}-${tipo}-${puntovta}-${voucher}-${importe}-${cae}`
                    );
                    setAdded(z);
                    setFecha("");
                    setPuntovta("");
                    setVoucher("");
                    setImporte("");
                    setCae("");
                  }}
                >
                  Agregar Factura
                </Button>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                {added.length == 0 ? (
                  <h5>No hay facturas agregadas</h5>
                ) : (
                  <h5>Facturas a generar en el TXT: {added.length}</h5>
                )}
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      {!success && (
        <Modal.Footer>
          {added.length > 0 ? (
            <Button onClick={() => generateManualTXT(added)}>
              Generar TXT
            </Button>
          ) : (
            <Button disabled>Generar TXT</Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

const generateTxtVenta = async (
  startDate: any,
  endDate: any,
  test: boolean
) => {
  let bills = (
    await FIRESTORE.COLLECTION_ARTS_INVOICES()
      .where("creation_date", ">=", startDate)
      .where("creation_date", "<=", endDate)
      .orderBy("creation_date", "asc")
      .get()
  ).docs;
  bills = bills.filter((bill) => {
    let billData = bill.data();
    return (
      billData.art === "MekXauxMVPd1ayCGpIwWjg7j6Z73" ||
      billData.art === "GjP1gJT9T1eK0FGe2kfx6o4T80J2"
    );
  });
  bills = bills.filter((bill) => {
    let billData = bill.data();
    return billData.downloaded_FV !== true;
  });
  let fechaInicio: any = FbDate.fromDate(startDate);
  let fechaFin: any = FbDate.fromDate(endDate);
  fechaInicio = moment(fechaInicio.toDate()).format("DD/MM/YYYY");
  fechaFin = moment(fechaFin.toDate()).format("DD/MM/YYYY");
  let data = "";
  await Promise.all(
    bills.map(async (factura) => {
      let comisionNegativa = false;
      let resto = 0;
      const bill = factura.data();
      const art = (await FIRESTORE.DOCUMENT_USER(bill.art).get()).data();
      const artInfo = await getArtInformation(bill.art);
      const viajes = bill.modules.reduce((acc, viaje) => {
        let t = viaje.base;
        return t + acc;
      }, 0);
      let comision = bill.modules.reduce((acc, viaje) => {
        let t = viaje.agency_commission;
        return t + acc;
      }, 0);
      const peajes = bill.modules.reduce((acc, viaje) => {
        let t = viaje.tolls;
        return t + acc;
      }, 0);
      const tiempoComprado = bill.modules.reduce((acc, viaje) => {
        let t = viaje.wait_time;
        if (viaje.additionalE) {
          t += viaje.additionalE;
        }
        return t + acc;
      }, 0);
      const vat_base = bill.modules.reduce((acc, viaje) => {
        let t = viaje.vat_base;
        return t + acc;
      }, 0);
      const vat_commission = bill.modules.reduce((acc, viaje) => {
        let t = viaje.vat_commission;
        return t + acc;
      }, 0);
      if (bill.bonif) {
        comision -= bill.bonif;
        if (comision < 0) {
          comisionNegativa = true;
        }
      }
      if (comisionNegativa) {
        resto = comision;
      }
      if (!test) {
        console.log("NO ES UN TEST");
      }
      data =
        data +
        `\n[REGISTRO]\nFactura venta\n${moment(
          bill?.creation_date.toDate()
        ).format("DD/MM/YYYY")}|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? "FA"
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "FB"
            : bill!.type === TIPOS_COMPROBANTES["FACTURA C"]
            ? "FC"
            : "FE"
        }|${String(bill?.point_of_sale).padStart(5, "0")}|${String(
          bill?.voucher_number
        ).padStart(8, "0")}|${
          art?.name === "Feleval SRL"
            ? "0001"
            : artInfo.internal_code
            ? "ART" + String(artInfo.internal_code).padStart(5, "0")
            : "no tiene"
        }|${art?.name}||C|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? "001"
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "009"
            : "ERROR TIPO DE FACTURA"
        }|${
          artInfo.vat === 0
            ? "E"
            : artInfo.vat === 1
            ? "I"
            : "FALTA ESPECIFICAR CONDICION DE IVA"
        }|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? String(bill?.ImpTotal.toFixed(2)).padStart(10, "0") //CORREGIR AQUI IMPORTE NETO
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? String(bill?.ImpTotal.toFixed(2)).padStart(10, "0")
            : "ERROR TIPO DE FACTURA"
        }|${String(bill?.ImpIVA.toFixed(2)).padStart(
          10,
          "0"
        )}|0000000.00|0000000.00|0000000.00|${String(
          bill?.ImpTotal.toFixed(2)
        ).padStart(10, "0")}||L|L|||||||${
          artInfo.vat === 0
            ? "009"
            : artInfo.vat === 1
            ? "001"
            : "FALTA ESPECIFICAR CONDICION DE IVA"
        }|N||11000||||||||||||||||||${bill?.cae ? bill.cae : 0}|${moment(
          bill?.expiration_date.toDate()
        ).format("DD/MM/YYYY")}||${moment(bill?.creation_date.toDate()).format(
          "DD/MM/YYYY"
        )}|${
          artInfo.vat === 0
            ? "009"
            : artInfo.vat === 1
            ? "001"
            : "FALTA ESPECIFICAR CONDICION DE IVA"
        }||\nITEM|PRECIO VIAJE|||N|||${String(
          (Number(viajes.toFixed(2)) + resto).toFixed(2)
        ).padStart(10, "0")}|0000000.00|${String(
          (Number(viajes.toFixed(2)) + resto).toFixed(2)
        ).padStart(10, "0")}|${String(
          (Number(viajes.toFixed(2)) + resto).toFixed(2)
        ).padStart(10, "0")}|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? String(vat_base)
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "0000000.00"
            : "ERROR TIPO DE FACTURA"
        }|0000000.00|0000000.00|0000000.00|0000000.00|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? bill!.ImpIVA > 0
              ? "10.50"
              : "00.00"
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "00.00"
            : "ERROR TIPO DE FACTURA"
        }|00.00||||\n${
          peajes > 0
            ? `ITEM|PEAJES|||N|||${String(peajes.toFixed(2)).padStart(
                10,
                "0"
              )}|0000000.00|${String(peajes.toFixed(2)).padStart(
                10,
                "0"
              )}|${String(peajes.toFixed(2)).padStart(
                10,
                "0"
              )}|0000000.00|0000000.00|0000000.00|0000000.00|0000000.00|00.00|00.00||||\n`
            : ""
        }${
          comision > 0
            ? `ITEM|COMISION|||N|||${String(comision.toFixed(2)).padStart(
                10,
                "0"
              )}|0000000.00|${String(comision.toFixed(2)).padStart(
                10,
                "0"
              )}|${String(comision.toFixed(2)).padStart(10, "0")}|${
                bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
                  ? String(vat_commission)
                  : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
                  ? "0000000.00"
                  : "ERROR TIPO DE FACTURA"
              }|0000000.00|0000000.00|0000000.00|0000000.00|${
                bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
                  ? bill!.ImpIVA > 0
                    ? "21.00"
                    : "00.00"
                  : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
                  ? "00.00"
                  : "ERROR TIPO DE FACTURA"
              }|00.00||||\n`
            : ""
        }${
          tiempoComprado > 0
            ? `ITEM|ESPERA COMPRADA|||N|||${String(
                tiempoComprado.toFixed(2)
              ).padStart(10, "0")}|0000000.00|${String(
                tiempoComprado.toFixed(2)
              ).padStart(10, "0")}|${String(tiempoComprado.toFixed(2)).padStart(
                10,
                "0"
              )}|0000000.00|0000000.00|0000000.00|0000000.00|0000000.00|${
                bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
                  ? bill!.ImpIVA > 0
                    ? "21.00"
                    : "00.00"
                  : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
                  ? "00.00"
                  : "ERROR TIPO DE FACTURA"
              }|00.00||||\n`
            : ""
        }
      `;
    })
  );

  // preguntar por codigo de caja, BULTOS, PrefijoRendicion
  let blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  fs.saveAs(
    blob,
    test
      ? `VENTAS-PRUEBA-${fechaInicio}/${fechaFin}.txt`
      : `VENTAS-${fechaInicio}/${fechaFin}.txt`
  );
};

const generateTxtNC = async (startDate: any, endDate: any, test: boolean) => {
  let bills = (
    await FIRESTORE.COLLECTION_ARTS_INVOICES()
      .where("canceled_date", ">=", startDate)
      .where("canceled_date", "<=", endDate)
      .orderBy("canceled_date", "asc")
      .get()
  ).docs;
  bills = bills.filter((bill) => {
    let billData = bill.data();
    return (
      billData.art === "MekXauxMVPd1ayCGpIwWjg7j6Z73" ||
      billData.art === "GjP1gJT9T1eK0FGe2kfx6o4T80J2"
    );
  });
  bills = bills.filter((bill) => {
    let billData = bill.data();
    return billData.downloaded_NC !== true;
  });
  let fechaInicio: any = FbDate.fromDate(startDate);
  let fechaFin: any = FbDate.fromDate(endDate);
  fechaInicio = moment(fechaInicio.toDate()).format("DD/MM/YYYY");
  fechaFin = moment(fechaFin.toDate()).format("DD/MM/YYYY");
  let data = "";
  await Promise.all(
    bills.map(async (factura) => {
      let comisionNegativa = false;
      let resto = 0;
      const bill = factura.data();
      const art = (await FIRESTORE.DOCUMENT_USER(bill.art).get()).data();
      const artInfo = await getArtInformation(bill.art);
      const viajes = bill.modules.reduce((acc, viaje) => {
        let t = viaje.base;
        return t + acc;
      }, 0);
      let comision = bill.modules.reduce((acc, viaje) => {
        let t = viaje.agency_commission;
        return t + acc;
      }, 0);
      const peajes = bill.modules.reduce((acc, viaje) => {
        let t = viaje.tolls;
        return t + acc;
      }, 0);
      const tiempoComprado = bill.modules.reduce((acc, viaje) => {
        let t = viaje.wait_time;
        if (viaje.additionalE) {
          t += viaje.additionalE;
        }
        return t + acc;
      }, 0);
      const vat_base = bill.modules.reduce((acc, viaje) => {
        let t = viaje.vat_base;
        return t + acc;
      }, 0);
      const vat_commission = bill.modules.reduce((acc, viaje) => {
        let t = viaje.vat_commission;
        return t + acc;
      }, 0);
      if (bill.bonif) {
        comision -= bill.bonif;
        if (comision < 0) {
          comisionNegativa = true;
        }
      }
      if (comisionNegativa) {
        resto = comision;
      }
      if (!test) console.log("NO ES UN TEST");
      data =
        data +
        `\n[REGISTRO]\nFactura venta\n${moment(
          bill?.canceled_date!.toDate()
        ).format("DD/MM/YYYY")}|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? "CA"
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "CB"
            : bill!.type === TIPOS_COMPROBANTES["FACTURA C"]
            ? "CC"
            : "FACTURA INCORRECTA"
        }|${String(bill?.point_of_sale).padStart(5, "0")}|${String(
          bill?.deleted_vouche_number
        ).padStart(8, "0")}|${
          art?.name === "Feleval SRL"
            ? "Feleval SRL"
            : artInfo.internal_code
            ? "ART" + String(artInfo.internal_code).padStart(5, "0")
            : "no tiene"
        }|${art?.name}||C|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? "001"
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "009"
            : "ERROR TIPO DE FACTURA"
        }|${
          artInfo.vat === 0
            ? "E"
            : artInfo.vat === 1
            ? "I"
            : "FALTA ESPECIFICAR CONDICION DE IVA"
        }|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? String(bill?.ImpTotal.toFixed(2)).padStart(10, "0") //CORREGIR AQUI IMPORTE NETO
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? String(bill?.ImpTotal.toFixed(2)).padStart(10, "0")
            : "ERROR TIPO DE FACTURA"
        }|${String(bill?.ImpIVA.toFixed(2)).padStart(
          10,
          "0"
        )}|0000000.00|0000000.00|0000000.00|${String(
          bill?.ImpTotal.toFixed(2)
        ).padStart(10, "0")}||L|L|||||||${
          artInfo.vat === 0
            ? "009"
            : artInfo.vat === 1
            ? "001"
            : "FALTA ESPECIFICAR CONDICION DE IVA"
        }|N||11000||||||||||||||||||${
          bill?.cancelation_cae ? bill.cancelation_cae : "CAE NO ENCONTRADO"
        }|${moment(bill?.canceled_date?.toDate())
          .add(10, "days")
          .format("DD/MM/YYYY")}||${moment(
          bill?.canceled_date?.toDate()
        ).format("DD/MM/YYYY")}|${
          artInfo.vat === 0
            ? "009"
            : artInfo.vat === 1
            ? "001"
            : "FALTA ESPECIFICAR CONDICION DE IVA"
        }||\nITEM|PRECIO VIAJE|||N|||${String(
          (Number(viajes.toFixed(2)) + resto).toFixed(2)
        ).padStart(10, "0")}|0000000.00|${String(
          (Number(viajes.toFixed(2)) + resto).toFixed(2)
        ).padStart(10, "0")}|${String(
          (Number(viajes.toFixed(2)) + resto).toFixed(2)
        ).padStart(10, "0")}|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? String(vat_base)
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "0000000.00"
            : "ERROR TIPO DE FACTURA"
        }|0000000.00|0000000.00|0000000.00|0000000.00|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? "10.50"
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "00.00"
            : "ERROR TIPO DE FACTURA"
        }|00.00||||\n${
          peajes > 0
            ? `ITEM|PEAJES|||N|||${String(peajes.toFixed(2)).padStart(
                10,
                "0"
              )}|0000000.00|${String(peajes.toFixed(2)).padStart(
                10,
                "0"
              )}|${String(peajes.toFixed(2)).padStart(
                10,
                "0"
              )}|0000000.00|0000000.00|0000000.00|0000000.00|0000000.00|00.00|00.00||||\n`
            : ""
        }${
          comision > 0
            ? `ITEM|COMISION|||N|||${String(comision.toFixed(2)).padStart(
                10,
                "0"
              )}|0000000.00|${String(comision.toFixed(2)).padStart(
                10,
                "0"
              )}|${String(comision.toFixed(2)).padStart(10, "0")}|${
                bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
                  ? String(vat_commission)
                  : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
                  ? "0000000.00"
                  : "ERROR TIPO DE FACTURA"
              }|0000000.00|0000000.00|0000000.00|0000000.00|${
                bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
                  ? "21.00"
                  : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
                  ? "00.00"
                  : "ERROR TIPO DE FACTURA"
              }|00.00||||\n`
            : ""
        }${
          tiempoComprado > 0
            ? `ITEM|ESPERA COMPRADA|||N|||${String(
                tiempoComprado.toFixed(2)
              ).padStart(10, "0")}|0000000.00|${String(
                tiempoComprado.toFixed(2)
              ).padStart(10, "0")}|${String(tiempoComprado.toFixed(2)).padStart(
                10,
                "0"
              )}|0000000.00|0000000.00|0000000.00|0000000.00|0000000.00|${
                bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
                  ? "10.5"
                  : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
                  ? "00.00"
                  : "ERROR TIPO DE FACTURA"
              }|00.00||||\n`
            : ""
        }
      `;
    })
  );

  // preguntar por codigo de caja, BULTOS, PrefijoRendicion
  let blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  fs.saveAs(
    blob,
    test
      ? `Notas de crédito-PRUEBA-${fechaInicio}/${fechaFin}.txt`
      : `Notas de crédito-${fechaInicio}/${fechaFin}.txt`
  );
};

const generateTxtND = async (startDate: any, endDate: any, test: boolean) => {
  let bills = (
    await FIRESTORE.COLLECTION_ARTS_INVOICES()
      .where("debit_date", ">=", startDate)
      .where("debit_date", "<=", endDate)
      .orderBy("debit_date", "asc")
      .get()
  ).docs;
  bills = bills.filter((bill) => {
    let billData = bill.data();
    return (
      billData.art === "MekXauxMVPd1ayCGpIwWjg7j6Z73" ||
      billData.art === "GjP1gJT9T1eK0FGe2kfx6o4T80J2"
    );
  });
  bills = bills.filter((bill) => {
    let billData = bill.data();
    return billData.downloaded_NC !== true;
  });
  let fechaInicio: any = FbDate.fromDate(startDate);
  let fechaFin: any = FbDate.fromDate(endDate);
  fechaInicio = moment(fechaInicio.toDate()).format("DD/MM/YYYY");
  fechaFin = moment(fechaFin.toDate()).format("DD/MM/YYYY");
  let data = "";
  await Promise.all(
    bills.map(async (factura) => {
      let comisionNegativa = false;
      let resto = 0;
      const bill = factura.data();
      const art = (await FIRESTORE.DOCUMENT_USER(bill.art).get()).data();
      const artInfo = await getArtInformation(bill.art);
      const viajes = bill.modules.reduce((acc, viaje) => {
        let t = viaje.base;
        return t + acc;
      }, 0);
      let comision = bill.modules.reduce((acc, viaje) => {
        let t = viaje.agency_commission;
        return t + acc;
      }, 0);
      const peajes = bill.modules.reduce((acc, viaje) => {
        let t = viaje.tolls;
        return t + acc;
      }, 0);
      const tiempoComprado = bill.modules.reduce((acc, viaje) => {
        let t = viaje.wait_time;
        if (viaje.additionalE) {
          t += viaje.additionalE;
        }
        return t + acc;
      }, 0);
      const vat_base = bill.modules.reduce((acc, viaje) => {
        let t = viaje.vat_base;
        return t + acc;
      }, 0);
      const vat_commission = bill.modules.reduce((acc, viaje) => {
        let t = viaje.vat_commission;
        return t + acc;
      }, 0);
      if (bill.bonif) {
        comision -= bill.bonif;
        if (comision < 0) {
          comisionNegativa = true;
        }
      }
      if (comisionNegativa) {
        resto = comision;
      }
      if (!test) console.log("NO ES UN TEST");
      data =
        data +
        `\n[REGISTRO]\nFactura venta\n${moment(
          bill?.debit_date!.toDate()
        ).format("DD/MM/YYYY")}|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? "DA"
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "DB"
            : bill!.type === TIPOS_COMPROBANTES["FACTURA C"]
            ? "DC"
            : "FACTURA INCORRECTA"
        }|${String(bill?.point_of_sale).padStart(5, "0")}|${String(
          bill?.debit_voucher_number
        ).padStart(8, "0")}|${
          art?.name === "Feleval SRL"
            ? "Feleval SRL"
            : artInfo.internal_code
            ? "ART" + String(artInfo.internal_code).padStart(5, "0")
            : "no tiene"
        }|${art?.name}||C|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? "001"
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "009"
            : "ERROR TIPO DE FACTURA"
        }|${
          artInfo.vat === 0
            ? "E"
            : artInfo.vat === 1
            ? "I"
            : "FALTA ESPECIFICAR CONDICION DE IVA"
        }|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? String(bill?.ImpNeto.toFixed(2)).padStart(10, "0")
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? String(bill?.ImpTotal.toFixed(2)).padStart(10, "0")
            : "ERROR TIPO DE FACTURA"
        }|${String(bill?.ImpIVA.toFixed(2)).padStart(
          10,
          "0"
        )}|0000000.00|0000000.00|0000000.00|${String(
          bill?.ImpTotal.toFixed(2)
        ).padStart(10, "0")}||L|L|||||||${
          artInfo.vat === 0
            ? "009"
            : artInfo.vat === 1
            ? "001"
            : "FALTA ESPECIFICAR CONDICION DE IVA"
        }|N||11000||||||||||||||||||${
          bill?.debit_cae ? bill.debit_cae : "CAE NO ENCONTRADO"
        }|${moment(bill?.debit_date?.toDate())
          .add(10, "days")
          .format("DD/MM/YYYY")}||${moment(bill?.debit_date?.toDate()).format(
          "DD/MM/YYYY"
        )}|${
          artInfo.vat === 0
            ? "009"
            : artInfo.vat === 1
            ? "001"
            : "FALTA ESPECIFICAR CONDICION DE IVA"
        }||\nITEM|PRECIO VIAJE|||N|||${String(
          (Number(viajes.toFixed(2)) + resto).toFixed(2)
        ).padStart(10, "0")}|0000000.00|${String(
          (Number(viajes.toFixed(2)) + resto).toFixed(2)
        ).padStart(10, "0")}|${String(
          (Number(viajes.toFixed(2)) + resto).toFixed(2)
        ).padStart(10, "0")}|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? String(vat_base)
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "0000000.00"
            : "ERROR TIPO DE FACTURA"
        }|0000000.00|0000000.00|0000000.00|0000000.00|${
          bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? "10.50"
            : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "00.00"
            : "ERROR TIPO DE FACTURA"
        }|00.00||||\n${
          peajes > 0
            ? `ITEM|PEAJES|||N|||${String(peajes.toFixed(2)).padStart(
                10,
                "0"
              )}|0000000.00|${String(peajes.toFixed(2)).padStart(
                10,
                "0"
              )}|${String(peajes.toFixed(2)).padStart(
                10,
                "0"
              )}|0000000.00|0000000.00|0000000.00|0000000.00|0000000.00|00.00|00.00||||\n`
            : ""
        }${
          comision > 0
            ? `ITEM|COMISION|||N|||${String(comision.toFixed(2)).padStart(
                10,
                "0"
              )}|0000000.00|${String(comision.toFixed(2)).padStart(
                10,
                "0"
              )}|${String(comision.toFixed(2)).padStart(10, "0")}|${
                bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
                  ? String(vat_commission)
                  : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
                  ? "0000000.00"
                  : "ERROR TIPO DE FACTURA"
              }|0000000.00|0000000.00|0000000.00|0000000.00|${
                bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
                  ? "21.00"
                  : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
                  ? "00.00"
                  : "ERROR TIPO DE FACTURA"
              }|00.00||||\n`
            : ""
        }${
          tiempoComprado > 0
            ? `ITEM|ESPERA COMPRADA|||N|||${String(
                tiempoComprado.toFixed(2)
              ).padStart(10, "0")}|0000000.00|${String(
                tiempoComprado.toFixed(2)
              ).padStart(10, "0")}|${String(tiempoComprado.toFixed(2)).padStart(
                10,
                "0"
              )}|0000000.00|0000000.00|0000000.00|0000000.00|0000000.00|${
                bill!.type === TIPOS_COMPROBANTES["FACTURA A"]
                  ? "10.5"
                  : bill!.type === TIPOS_COMPROBANTES["FACTURA B"]
                  ? "00.00"
                  : "ERROR TIPO DE FACTURA"
              }|00.00||||\n`
            : ""
        }
      `;
    })
  );

  // preguntar por codigo de caja, BULTOS, PrefijoRendicion
  let blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  fs.saveAs(
    blob,
    test
      ? `Notas de débito-PRUEBA-${fechaInicio}/${fechaFin}.txt`
      : `Notas de débito-${fechaInicio}/${fechaFin}.txt`
  );
};

const generateTxtCompra = async (
  startDate: any,
  endDate: any,
  test: boolean
) => {
  let bills = (
    await FIRESTORE.COLLECTION_DRIVER_INVOICES()
      .where("creation_date", ">=", startDate)
      .where("creation_date", "<=", endDate)
      .orderBy("creation_date", "asc")
      .get()
  ).docs;
  bills = bills.filter((bill) => {
    const billData = bill.data();
    return billData.voucher_number != "0012"; //&& billData.downloaded !== true;
  });
  let fechaInicio: any = FbDate.fromDate(startDate);
  let fechaFin: any = FbDate.fromDate(endDate);
  fechaInicio = moment(fechaInicio.toDate()).format("DD/MM/YYYY");
  fechaFin = moment(fechaFin.toDate()).format("DD/MM/YYYY");
  let data = "";
  await Promise.all(
    bills.map(async (factura) => {
      const trans = factura.data();
      console.log(
        "data",
        trans.amounts!.additional_5M,
        trans!.amounts!.additional_5M && trans!.amounts!.additional_5M > 0,
        String(trans.amounts!.additional_5M!.toFixed(2)),
        test
      );

      if (!test) {
        console.log("ESTO NO ES UN TEST");
        const invoiceRef = await FIRESTORE.DOCUMENT_DRIVER_BILL(
          factura.id
        ).get();
        await invoiceRef.ref.update({ downloaded: true });
      }
      const driver = (await FIRESTORE.DOCUMENT_USER(trans.driver).get()).data();
      data =
        data +
        `[REGISTRO]\nFactura compra\n${moment(
          trans?.creation_date.toDate()
        ).format("DD/MM/YY")}|${
          trans!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? "MA"
            : trans!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "MB"
            : trans!.type === TIPOS_COMPROBANTES["FACTURA C"]
            ? "MC"
            : "MM"
        }|${String(trans?.point_of_sale).padStart(5, "0")}|${String(
          trans?.voucher_number
        ).padStart(8, "0")}|${
          driver?.user_code
            ? String(driver?.user_code).padStart(5, "0")
            : "no tiene"
        }|${driver?.name} ${driver?.surname}||C|001|${
          trans?.tax_entity === 0 ? "I" : "M"
        }|${String(trans?.ImpNeto.toFixed(2))}|||||${String(
          trans?.ImpTotal.toFixed(2)
        )}||L|L|||||||006|N||20000|||||||||||||||||||||||\nITEM|PRECIO VIAJE|||N|||${String(
          trans?.amounts?.transfer.toFixed(2)
        )}||${String(trans?.amounts?.transfer.toFixed(2))}|${String(
          trans?.amounts?.transfer.toFixed(2)
        )}||||||||||||||||||||||||||||||||||||\n${
          trans?.amounts?.tolls
            ? `ITEM|PEAJES|||N|||${String(
                trans?.amounts?.tolls.toFixed(2)
              )}||${String(trans?.amounts?.tolls.toFixed(2))}|${String(
                trans?.amounts?.tolls.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }${
          trans?.amounts?.wait_request
            ? `ITEM|ESPERA|||N|||${String(
                trans?.amounts?.wait_request.toFixed(2)
              )}||${String(trans?.amounts?.wait_request.toFixed(2))}|${String(
                trans?.amounts?.wait_request.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }${
          trans?.amounts?.additional_G && trans?.amounts?.additional_G > 0
            ? `ITEM|ADICIONAL G|||N|||${String(
                trans?.amounts?.additional_G.toFixed(2)
              )}||${String(trans?.amounts?.additional_G.toFixed(2))}|${String(
                trans?.amounts?.additional_G.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : trans?.amounts?.additional_R && trans?.amounts?.additional_R > 0
            ? `ITEM|ADICIONAL R|||N|||${String(
                trans?.amounts?.additional_R.toFixed(2)
              )}||${String(trans?.amounts?.additional_R.toFixed(2))}|${String(
                trans?.amounts?.additional_R.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }${
          trans?.amounts?.additional_M && trans?.amounts?.additional_M > 0
            ? `ITEM|ADICIONAL M|||N|||${String(
                trans?.amounts?.additional_M.toFixed(2)
              )}||${String(trans?.amounts?.additional_M.toFixed(2))}|${String(
                trans?.amounts?.additional_M.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }${
          trans.amounts!.additional_5M && trans.amounts!.additional_5M > 0
            ? `ITEM|ADICIONAL 5M|||N|||${String(
                trans.amounts!.additional_5M!.toFixed(2)
              )}||${String(trans.amounts!.additional_5M!.toFixed(2))}|${String(
                trans.amounts!.additional_5M!.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }${
          trans?.amounts?.additional_K && trans?.amounts?.additional_K > 0
            ? `ITEM|ADICIONAL K|||N|||${String(
                trans?.amounts?.additional_K.toFixed(2)
              )}||${String(trans?.amounts?.additional_K.toFixed(2))}|${String(
                trans?.amounts?.additional_K.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }${
          trans?.amounts?.additional_E && trans?.amounts?.additional_E > 0
            ? `ITEM|ADICIONAL E|||N|||${String(
                trans?.amounts?.additional_E.toFixed(2)
              )}||${String(trans?.amounts?.additional_E.toFixed(2))}|${String(
                trans?.amounts?.additional_E.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }${
          trans?.amounts?.additional_C && trans?.amounts?.additional_C > 0
            ? `ITEM|ADICIONAL C|||N|||${String(
                trans?.amounts?.additional_C.toFixed(2)
              )}||${String(trans?.amounts?.additional_C.toFixed(2))}|${String(
                trans?.amounts?.additional_C.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }${
          trans?.amounts?.wait_reports! > 0 &&
          trans?.amounts?.wait_reports != undefined
            ? `ITEM|ESPERA COMPRADA|||N|||${String(
                trans?.amounts?.wait_reports.toFixed(2)
              )}||${String(trans?.amounts?.wait_reports.toFixed(2))}|${String(
                trans?.amounts?.wait_reports.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }VENCIMIENTO|${moment(trans?.expiration_date.toDate()).format(
          "DD/MM/YY"
        )}|${String(trans?.ImpTotal.toFixed(2))}|
                    \n`;
    })
  );

  let blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  fs.saveAs(
    blob,
    test
      ? `COMPRAS-PRUEBA-${fechaInicio}/${fechaFin}.txt`
      : `COMPRAS-${fechaInicio}/${fechaFin}.txt`
  );
};

const generateTxtNcCompra = async (
  startDate: any,
  endDate: any,
  test: boolean
) => {
  let bills = (
    await FIRESTORE.COLLECTION_DRIVER_INVOICES()
      .where("canceled_date", ">=", startDate)
      .where("canceled_date", "<=", endDate)
      .orderBy("canceled_date", "asc")
      .get()
  ).docs;
  /* bills = bills.filter((bill) => {
    const billData = bill.data();
    console.log(billData);

    return billData.voucher_number != "0012" && billData.downloaded_NC !== true;
  }); */
  let fechaInicio: any = FbDate.fromDate(startDate);
  let fechaFin: any = FbDate.fromDate(endDate);
  fechaInicio = moment(fechaInicio.toDate()).format("DD/MM/YYYY");
  fechaFin = moment(fechaFin.toDate()).format("DD/MM/YYYY");
  let data = "";
  await Promise.all(
    bills.map(async (factura) => {
      const trans: BillInfoTransfer = factura.data();
      if (!test) {
        console.log("ESTO NO ES UN TEST");
        const invoiceRef = await FIRESTORE.DOCUMENT_DRIVER_BILL(
          factura.id
        ).get();
        await invoiceRef.ref.update({ downloaded_NC: true });
      }
      const driver = (await FIRESTORE.DOCUMENT_USER(trans.driver).get()).data();
      data =
        data +
        `[REGISTRO]\nFactura compra\n${moment(
          trans?.canceled_date!.toDate()
        ).format("DD/MM/YY")}|${
          trans!.type === TIPOS_COMPROBANTES["FACTURA A"]
            ? "TA"
            : trans!.type === TIPOS_COMPROBANTES["FACTURA B"]
            ? "TB"
            : trans!.type === TIPOS_COMPROBANTES["FACTURA C"]
            ? "TC"
            : "TM"
        }|${String(trans?.point_of_sale).padStart(5, "0")}|${String(
          trans?.deleted_vouche_number
        ).padStart(8, "0")}|${
          driver?.user_code
            ? String(driver?.user_code).padStart(5, "0")
            : "no tiene"
        }|${driver?.name} ${driver?.surname}||C|001|${
          trans?.tax_entity === 0 ? "I" : "M"
        }|${String(trans?.ImpNeto.toFixed(2))}|||||${String(
          trans?.ImpTotal.toFixed(2)
        )}||L|L|||||||006|N||20000|||||||||||||||||||||||\nITEM|PRECIO VIAJE|||N|||${String(
          trans?.amounts?.transfer.toFixed(2)
        )}||${String(trans?.amounts?.transfer.toFixed(2))}|${String(
          trans?.amounts?.transfer.toFixed(2)
        )}||||||||||||||||||||||||||||||||||||\n${
          trans?.amounts?.tolls
            ? `ITEM|PEAJES|||N|||${String(
                trans?.amounts?.tolls.toFixed(2)
              )}||${String(trans?.amounts?.tolls.toFixed(2))}|${String(
                trans?.amounts?.tolls.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }${
          trans?.amounts?.wait_request
            ? `ITEM|ESPERA|||N|||${String(
                trans?.amounts?.wait_request.toFixed(2)
              )}||${String(trans?.amounts?.wait_request.toFixed(2))}|${String(
                trans?.amounts?.wait_request.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }${
          trans?.amounts?.additional_G && trans?.amounts?.additional_G > 0
            ? `ITEM|ADICIONAL G|||N|||${String(
                trans?.amounts?.additional_G.toFixed(2)
              )}||${String(trans?.amounts?.additional_G.toFixed(2))}|${String(
                trans?.amounts?.additional_G.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : trans?.amounts?.additional_R && trans?.amounts?.additional_R > 0
            ? `ITEM|ADICIONAL R|||N|||${String(
                trans?.amounts?.additional_R.toFixed(2)
              )}||${String(trans?.amounts?.additional_R.toFixed(2))}|${String(
                trans?.amounts?.additional_R.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : trans?.amounts?.additional_M && trans?.amounts?.additional_M > 0
            ? `ITEM|ADICIONAL M|||N|||${String(
                trans?.amounts?.additional_M.toFixed(2)
              )}||${String(trans?.amounts?.additional_M.toFixed(2))}|${String(
                trans?.amounts?.additional_M.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }${
          trans.amounts?.additional_K && trans.amounts.additional_K > 0
            ? `ITEM|ADICIONAL M|||N|||${String(
                trans?.amounts?.additional_K.toFixed(2)
              )}||${String(trans?.amounts?.additional_K.toFixed(2))}|${String(
                trans?.amounts?.additional_K.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }${
          trans.amounts?.additional_E && trans.amounts.additional_E > 0
            ? `ITEM|ADICIONAL M|||N|||${String(
                trans?.amounts?.additional_E.toFixed(2)
              )}||${String(trans?.amounts?.additional_E.toFixed(2))}|${String(
                trans?.amounts?.additional_E.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }${
          trans?.amounts?.wait_reports! > 0 &&
          trans?.amounts?.wait_reports != undefined
            ? `ITEM|ESPERA COMPRADA|||N|||${String(
                trans?.amounts?.wait_reports.toFixed(2)
              )}||${String(trans?.amounts?.wait_reports.toFixed(2))}|${String(
                trans?.amounts?.wait_reports.toFixed(2)
              )}||||||||||||||||||||||||||||||||||||\n`
            : ""
        }VENCIMIENTO|${moment(trans?.canceled_date!.toDate())
          .add(10, "days")
          .format("DD/MM/YY")}|${String(trans?.ImpTotal.toFixed(2))}|
                    \n`;
    })
  );

  // preguntar por codigo de caja, BULTOS, PrefijoRendicion
  let blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  fs.saveAs(
    blob,
    test
      ? `Notas de crédito-PRUEBA-${fechaInicio}/${fechaFin}.txt`
      : `Notas de crédito-${fechaInicio}/${fechaFin}.txt`
  );
};

const generateManualTXT = (facturas: string[]) => {
  let data = "";
  facturas.map((f) => {
    const date = f.split("-")[0];
    const splittedDate = date.split("/");
    const dateForMoment = `${splittedDate[1]}-${splittedDate[0]}-${splittedDate[2]}`;
    const tipo = f.split("-")[1];
    const ptoVta = f.split("-")[2];
    const nroFc = f.split("-")[3];
    const importe = f.split("-")[4];
    const cae = f.split("-")[5];
    data =
      data +
      `\n[REGISTRO]\nFactura venta\n${date}|${
        tipo === "Nota crédito B"
          ? "CB"
          : tipo === "Nota débito B"
          ? "DB"
          : "FACTURA INCORRECTA"
      }|${ptoVta.padStart(5, "0")}|${nroFc.padStart(8, "0")}|${
        "ART" + String(123).padStart(5, "0")
      }|Experta SML||C|009|E|${importe}|${"0".padStart(
        10,
        "0"
      )}|0000000.00|0000000.00|0000000.00|${importe.padStart(
        10,
        "0"
      )}||L|L|||||||009|N||11000||||||||||||||||||${cae}|${moment(dateForMoment)
        .add(10, "days")
        .format("DD/MM/YYYY")}||${moment(dateForMoment).format(
        "DD/MM/YYYY"
      )}|009||\nITEM|PRECIO VIAJE|||N|||${importe.padStart(
        10,
        "0"
      )}|0000000.00|${importe.padStart(10, "0")}|${importe.padStart(
        10,
        "0"
      )}|0000000.00|0000000.00|0000000.00|0000000.00|0000000.00|00.00|00.00||||\n
  `;
  });
  let blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  fs.saveAs(blob, "Generado Manualmente.txt");
};

export enum TIPOS_COMPROBANTES {
  "FACTURA A" = 1,
  "FACTURA B" = 6,
  "FACTURA C" = 11,
  "Factura M" = 51,
  "Nota de Débito A" = 2,
  "Nota de Crédito A" = 3,
  "Nota de Débito B" = 7,
  "Nota de Crédito B" = 8,
  "Recibos A" = 4,
  "Recibos B" = 9,
  "Recibo C" = 15,
  "Notas de Venta al contado A" = 5,
  "Nota de Débito C" = 12,
  "Notas de Venta al contado B" = 10,
  "Liquidacion A" = 63,
  "Liquidacion B" = 64,
  "Cbtes. A del Anexo I, Apartado A,inc.f),R.G.Nro. 1415" = 34,
  "Cbtes. B del Anexo I,Apartado A,inc. f),R.G. Nro. 1415" = 35,
  "Otros comprobantes A que cumplan con R.G.Nro. 1415" = 39,
  "Otros comprobantes B que cumplan con R.G.Nro. 1415" = 40,
  "Cta de Vta y Liquido prod. A" = 60,
  "Cta de Vta y Liquido prod. B" = 61,
  "Nota de Crédito C" = 13,
  "Comprobante de Compra de Bienes Usados a Consumidor Final" = 49,
  "Nota de Débito M" = 52,
  "Nota de Crédito M" = 53,
  "Recibo M" = 54,
  "Factura de Crédito electrónica MiPyMEs (FCE) A" = 201,
  "Nota de Débito electrónica MiPyMEs (FCE) A" = 202,
  "Nota de Crédito electrónica MiPyMEs (FCE) A" = 203,
  "Factura de Crédito electrónica MiPyMEs (FCE) B" = 206,
  "Nota de Débito electrónica MiPyMEs (FCE) B" = 207,
  "Nota de Crédito electrónica MiPyMEs (FCE) B" = 208,
  "Factura de Crédito electrónica MiPyMEs (FCE) C" = 211,
  "Nota de Débito electrónica MiPyMEs (FCE) C" = 212,
  "Nota de Crédito electrónica MiPyMEs (FCE) C" = 213,
}

export const TIPOS_COMPROBANTES_BAS = {
  [TIPOS_COMPROBANTES["FACTURA A"]]: "MA",
  [TIPOS_COMPROBANTES["FACTURA B"]]: "MB",
  [TIPOS_COMPROBANTES["FACTURA C"]]: "MC",
  [TIPOS_COMPROBANTES["Factura M"]]: "MM",
  [TIPOS_COMPROBANTES["Nota de Débito A"]]: "BA",
  [TIPOS_COMPROBANTES["Nota de Crédito A"]]: "TA",
  [TIPOS_COMPROBANTES["Nota de Débito B"]]: "BB",
  [TIPOS_COMPROBANTES["Nota de Crédito B"]]: "TB",
  [TIPOS_COMPROBANTES["Nota de Débito C"]]: "BC",
  [TIPOS_COMPROBANTES["Liquidacion A"]]: "QA",
  [TIPOS_COMPROBANTES["Liquidacion B"]]: "QB",
  [TIPOS_COMPROBANTES["Nota de Crédito C"]]: "TC",
  [TIPOS_COMPROBANTES["Nota de Débito M"]]: "BM",
  [TIPOS_COMPROBANTES["Nota de Crédito M"]]: "TM",
} as { [key: number]: string };

export default Bas;
