import { H3, Spinner } from "components/styles";
import { useDbDocument } from "controllers/hooks";
import React from "react";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { uidSelector } from "redux/selectors";
import { Revision, RevisionStatus } from "types/user-data";
import { Body } from '../index';

const NewComersFinished = () => {
    const uid = useSelector(uidSelector)
    const revisionPersonal = useDbDocument<Revision>(...[`user/${uid}/personal_revision`,uid])
    const revisionLicence = useDbDocument<Revision>(...[`user/${uid}/licence_revision`,uid])
    const revisionTaxable = useDbDocument<Revision>(...[`user/${uid}/taxable_revision`,uid])

    if(!isLoaded(revisionPersonal,revisionLicence,revisionTaxable))
        return <Spinner />
    if(!revisionPersonal || !revisionLicence || !revisionTaxable)
        return <>Error</>
    return (
        <Body style={{padding:20}}>
            { (revisionPersonal.status === RevisionStatus["No revisado"] || 
            revisionLicence.status === RevisionStatus["No revisado"] || 
            revisionTaxable.status === RevisionStatus["No revisado"] ) &&
            <>
                <H3>Estamos revisando tus datos...</H3>
                <span>En 48hs tus datos van a estar revisados. Te mandaremos un mail para avisarte.<br/></span>
            </>
            }
            <br/>
            <h3>Estado de datos</h3>
            <span>Datos de Perfil: {textStatus(revisionPersonal.status)}</span> 
            <br/>
            <span>Datos de Licencia: {textStatus(revisionLicence.status)}</span> 
            <br/>
            <span>Datos Impositivos: {textStatus(revisionTaxable.status)}</span> 
        </Body>  
    )
}

const textStatus = (status : RevisionStatus) => {
    if(status === RevisionStatus["No revisado"])
        return <span style={{color:"#CCCC00"}}><b>EN REVISIÓN</b></span>
    if(status === RevisionStatus.Desaprobado)
        return <span style={{color:"#8b0000"}}><b>DESAPROBADO</b></span>
    if(status === RevisionStatus.Aprobado)
        return <span style={{color:"#006400	"}}><b>APROBADO</b></span>
}

export default NewComersFinished