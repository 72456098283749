import { wrongAmountInovices } from "components/Admin/Sales/reducer";
import { InfoRow } from "components/Admin/Transfers/view/scheduled";
import {
  Button,
  H2,
  NoData,
  Padding,
  Spinner,
  AsyncButton,
} from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { FIRESTORE } from "constants/firestore";
import firebase from "firebase";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Table as TableB, FormControl } from "react-bootstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  DefinitionsModuleTransferType,
  ModuleTransferType,
} from "types/module-transfer";
import {
  FinishStatus,
  ScheduledTransfer,
  TransferStage,
  TransferType,
  FbDate,
} from "types/transfer";
import MiniNavigationErrors from "../MiniNavigation";
import ROUTES from "constants/routes";
import { Sale } from "types/sale";

const AdminTransfersWrongAmountPageKm = () => {
  return (
    <MiniNavigationErrors active={3}>
      <MainContainer viewTransfer={ROUTES.ADMIN_MODULE_TRANSFER_VIEW} />
    </MiniNavigationErrors>
  );
};

export const MainContainer = ({
  viewTransfer,
}: {
  viewTransfer: (a: any) => string;
}) => {
  const [bonus, setBonus] = useState<any[]>([]);
  const [listOfPrices, setListOfPrices] = useState<any[]>([]);
  const [listOfPricesCross, setListOfPricesCross] = useState<any[]>([]);
  const [newItems, setNewItems] = useState<any[]>([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [showSolutionModal, setShowSolutionModal] = useState(false);
  const [solutionModal, setSolutionModal] = useState<any>("");
  const [listOfPricesDataBase, setListOfPricesDataBase] = useState<Sale[]>([]);
  const [amountMax, setAmountMax] = useState("");
  const initialSet = () => {
    setLoading(true);
    FIRESTORE.COLLECTION_SALE_EXPERTA()
      .get()
      .then((d) => d.docs.map((d) => ({ ...d.data(), id: d.id })))
      .then((data) => setListOfPricesDataBase(data));
    wrongAmountInovices()
      .get()
      .then((d) =>
        d.docs
          .filter(
            (i) =>
              i.data().status.finished_status !== 1 &&
              i.data().sale?.billed !== 1 &&
              i.data().sale?.billed !== 2 &&
              i.data().origin.time >= FbDate.fromDate(new Date("12/05/2022"))
          )
          .map((s) => ({ ...s.data(), id: s.id }))
          .sort((a, b) => {
            if (
              a.origin_location?.id === b.origin_location?.id &&
              a.destination_location?.id === b.destination_location?.id
            ) {
              return (
                b.destination.time.toMillis() - a.destination.time.toMillis()
              );
            } else {
              return (
                Number(a.origin_location?.id) - Number(b.origin_location?.id)
              );
            }
          })
      )
      .then((fI) => {
        setNewItems(fI);
        setLoading(false);
      });
  };
  const getListOfPrices = async (items: any[]) => {
    const arr: any[] = [];
    if (items.length > 0) {
      await Promise.all(
        items.map(async (item) => {
          const price = await getTotalModule(item.id);
          arr.push({ price: price, id: item.id });
        })
      ).then(() => {
        setListOfPrices(arr);
        setLoading2(false);
      });
    } else {
      setLoading2(false);
    }
  };

  const getListOfPricesCross = async (items: any[]) => {
    const arr: any[] = [];
    if (items.length > 0) {
      await Promise.all(
        items.map(async (item) => {
          const price = await getCrossExperta(
            item.id,
            item.info.distance_origin_destination,
            listOfPricesDataBase
          );
          console.log(price);

          arr.push({ price: Math.floor(price!), id: item.id });
        })
      ).then(() => {
        setListOfPricesCross(arr);
      });
    } else setLoading3(false);
  };

  const resetReject = async (array: any[]) => {
    for (let i = 0; i < array.length; i++) {
      const transfer = array[i];
      const ref = (await FIRESTORE.DOCUMENT_MODULE_TRANSFER(transfer.id).get())
        .ref;
      const result = listOfPrices.filter((price) => {
        return price.id == transfer.id;
      });
      const resultC = listOfPricesCross.filter((price) => {
        return price.id == transfer.id;
      });

      if (result[0].price < resultC[0].price) {
        await ref.update({
          reject: firebase.firestore.FieldValue.delete(),
        });
      }
    }
  };

  const billMultipleBonif = async (array: any[]) => {
    const data: { idModule: string; bonif: number }[] = [];
    await Promise.all(
      array.map(async (transfer) => {
        const result = listOfPrices.filter((price) => {
          return price.id == transfer.id;
        });
        const resultC = listOfPricesCross.filter((price) => {
          return price.id == transfer.id;
        });

        if (!isNaN(resultC[0].price) && result[0].price > resultC[0].price) {
          if (result[0].price - resultC[0].price <= Number(amountMax)) {
            console.log("entra");

            const totales = await calculateBonif(
              transfer.id,
              listOfPricesDataBase
            );
            console.log(totales, "totales");

            data.push({
              idModule: transfer.id,
              bonif: totales.bonif,
            });
          }
        }
      })
    );

    await CALLABLE_FUNCTIONS.FORCE_MULTIPLE_BILLS_BONIF({
      modules: data,
      artId: "MekXauxMVPd1ayCGpIwWjg7j6Z73",
    });
  };

  const billselective = async () => {
    const data: { idModule: string; bonif: number }[] = await Promise.all(
      bonus.map(async (transfer) => {
        const totales = await calculateBonif(transfer.id, listOfPricesDataBase);
        return {
          idModule: transfer.id,
          bonif: totales.bonif,
        };
      })
    );
    await CALLABLE_FUNCTIONS.FORCE_MULTIPLE_BILLS_BONIF({
      modules: data,
      artId: "MekXauxMVPd1ayCGpIwWjg7j6Z73",
    });

    setReload(!reload);
  };
  console.log(reload, "reload");

  useEffect(() => {
    initialSet();
  }, [reload]);
  useEffect(() => {
    getListOfPrices(newItems);
    getListOfPricesCross(newItems);
  }, [newItems]);

  return (
    <Padding pad="5px">
      <Row>
        <Col xl={12}>
          <H2>Monto Incorrecto</H2>
          <Button
            onClick={async () => {
              await resetReject(newItems);
              setReload(!reload);
            }}
          >
            Corregir
          </Button>
          <AsyncButton
            disabled={bonus.length > 0 ? false : true}
            onClick={async () => {
              await billselective();
              setBonus([]);
              setReload(!reload);
            }}
          >
            Facturar Conjunto
          </AsyncButton>
        </Col>
        <Col style={{ display: "flex" }}>
          <AsyncButton
            disabled={
              Number(amountMax) <= 10000 && Number(amountMax) > 0 ? false : true
            }
            variant="success"
            onClick={async () => {
              await billMultipleBonif(newItems);
              setReload(!reload);
            }}
          >
            Bonif menor a $
          </AsyncButton>
          <FormControl
            style={{ maxWidth: "20%" }}
            placeholder="Ingrese monto"
            aria-describedby="basic-addon2"
            value={amountMax}
            onChange={(e) => setAmountMax(e.target.value)}
            type="number"
            max="10000"
            min="1"
          />
        </Col>
      </Row>
      {loading && loading2 && loading3 ? (
        <Spinner style={{ margin: 20 }} />
      ) : newItems.length === 0 ? (
        <NoData />
      ) : (
        <Table hover responsive>
          <thead>
            <Tr>
              <th>ID ART</th>
              <th>ART</th>
              <th>Fecha</th>
              <th>Siniestro-Aut/NPA</th>
              <th>Cruce Experta</th>
              <th>Cruce Satapp</th>
              <th>Tipo</th>
              <th>Monto sin Peajes</th>
              <th>Valor ART Pactado</th>
              <th>Rechazos</th>
              <th>Ver</th>
              <th>Bonificación conjunta</th>
              <th>Acciones</th>
            </Tr>
          </thead>
          <tbody>
            {newItems.map((item) => {
              const result = listOfPrices.filter((price) => {
                return price.id == item.id;
              });

              const resultC = listOfPricesCross.filter((price) => {
                return price.id == item.id;
              });
              return (
                <tr
                  style={
                    result.length > 0 &&
                    Number(result[0].price) > Number(resultC[0].price)
                      ? { color: "green" }
                      : { color: "red" }
                  }
                  key={item.id}
                >
                  <Td>{item.transfer_id}</Td>
                  <Td>{item.art.name}</Td>
                  <Td>
                    <Moment
                      date={item.origin.time.toDate()}
                      format="DD/MM/YYYY HH:mm"
                    />
                  </Td>
                  <Td style={{ wordBreak: "break-all" }}>
                    {item.art.accident && item.art.authorization
                      ? item.art.accident + "-" + item.art.authorization
                      : item.art.accident
                      ? item.art.accident
                      : "NPA: " + item.art.npa}
                  </Td>
                  <Td>
                    {item.origin_location.id +
                      " , " +
                      item.destination_location.id +
                      " " +
                      item.origin_location.description +
                      " - " +
                      item.destination_location.description}
                  </Td>
                  <Td>
                    {(item.origin.address.district.includes("Comuna")
                      ? "CABA"
                      : item.origin.address.locality.length === 3
                      ? item.origin.address.district
                      : item.origin.address.locality) +
                      " - " +
                      (item.destination.address.district.includes("Comuna")
                        ? "CABA"
                        : item.destination.address.locality.length === 3
                        ? item.destination.address.district
                        : item.destination.address.locality)}
                  </Td>
                  <Td>
                    {item.status.finished_status === FinishStatus.Negativo
                      ? "Negativo"
                      : DefinitionsModuleTransferType[item.type]}
                  </Td>
                  <Td>
                    {!loading2
                      ? result.length > 0
                        ? Number(result[0].price).toFixed(2)
                        : 0
                      : 0}
                  </Td>
                  <Td>
                    {!loading3
                      ? resultC.length > 0
                        ? Number(resultC[0].price).toFixed(2)
                        : 0
                      : 0}
                  </Td>
                  <Td>
                    {item?.sale?.history?.length! > 1
                      ? `Factura rechazada ${item?.sale?.history?.length} veces`
                      : item.sale?.history?.length == 1
                      ? `Factura rechazada ${item.sale.history.length} vez`
                      : "Sin rechazos"}
                  </Td>
                  <Td>
                    <Link to={viewTransfer(item.id)}>
                      <Button variant="light">Ver</Button>
                    </Link>
                  </Td>
                  <Td>
                    {result.length > 0 &&
                    !bonus.some((select) => select.id == item.id) &&
                    Number(result[0].price) > Number(resultC[0].price) ? (
                      <Button
                        variant="warning"
                        onClick={() => {
                          const z = [...bonus];
                          z.push(item);
                          setBonus(z);
                        }}
                      >
                        Agregar
                      </Button>
                    ) : bonus.some((select) => select.id == item.id) ? (
                      <Button
                        variant="danger"
                        onClick={() => {
                          const z = [...bonus];
                          z.splice(
                            z.findIndex((element) => element.id == item.id),
                            1
                          );
                          setBonus(z);
                        }}
                      >
                        Quitar
                      </Button>
                    ) : (
                      ""
                    )}
                  </Td>
                  <Td>
                    <Button
                      variant="light"
                      disabled={
                        result.length > 0 &&
                        Number(result[0].price) > Number(resultC[0].price)
                          ? false
                          : true
                      }
                      onClick={() => {
                        setSolutionModal(item.id);
                        setShowSolutionModal(true);
                      }}
                    >
                      Generar factura con monto cambiado
                    </Button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <SolutionAmountModal
        module={solutionModal}
        show={showSolutionModal}
        listOfPricesDataBase={listOfPricesDataBase}
        handleClose={() => setShowSolutionModal(false)}
        setCollection={() => {
          setReload(!reload);
        }}
      />
    </Padding>
  );
};

export const SolutionAmountModal = ({
  module,
  show,
  handleClose,
  setCollection,
  listOfPricesDataBase,
}: {
  module: string;
  show: boolean;
  handleClose: any;
  setCollection: any;
  listOfPricesDataBase: Sale[];
}) => {
  const [loadingModal, setLoadingModal] = useState(false);
  const [bonif, setBonif] = useState(0);
  const [totalNew, setTotalNew] = useState(0);
  const [totalOld, setTotalOld] = useState(0);
  const [success, setSuccess] = useState(false);
  console.log(
    listOfPricesDataBase,
    "listOfPricesDataBase",
    totalOld,
    bonif,
    totalNew
  );

  const setTotales = async () => {
    const totales = await calculateBonif(module, listOfPricesDataBase);
    console.log(totales, "totales");

    setBonif(totales.bonif);
    setTotalNew(totales.precioFinal);
    setTotalOld(totales.precioViejo);
    setLoadingModal(true);
  };

  const generateBillWithBonification = async (
    idModule: string,
    bonif: number
  ) => {
    await CALLABLE_FUNCTIONS.ADMIN_FORCE_ART_BILL_WITH_BONIF({
      idModule: module,
      bonif: bonif,
    });
    setSuccess(true);
  };

  if (module) {
    setTotales();
  }
  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        if (success) {
          setCollection();
          setSuccess(false);
        }
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Cambio de precios</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {success ? (
          <p>Se modificó exitosamente el traslado!</p>
        ) : (
          <>
            <InfoRow
              label="Precio Original s/Peaje"
              info={loadingModal ? totalOld.toFixed(2) : 0}
            />
            <InfoRow
              label="Bonificación"
              info={loadingModal ? bonif.toFixed(2) : 0}
            />
            <InfoRow
              label="Nuevo Total"
              info={loadingModal ? totalNew.toFixed(2) : 0}
            />
            <AsyncButton
              className="pull-right"
              style={{ margin: "0 10px 5px" }}
              onClick={() => generateBillWithBonification(module, bonif)}
            >
              Generar factura con nuevo monto
            </AsyncButton>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            handleClose();
            if (success) {
              setCollection();
              setSuccess(false);
            }
          }}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const calculateBonif = async (
  idModule: string,
  listOfPricesDataBase: Sale[]
) => {
  const module = (
    await FIRESTORE.DOCUMENT_MODULE_TRANSFER(idModule).get()
  ).data();
  const crossExperta = await getCrossExperta(
    idModule,
    module!.info.distance_origin_destination,
    listOfPricesDataBase
  );

  const price = await getTotalModuleModal(idModule);

  const crossMultiply = Math.floor(crossExperta!);
  const bonif = Number((price.price - crossMultiply + 0.01).toFixed(2));
  const precioFinal = price.price + price.wait + price.tolls - bonif;
  const precioViejo = price.price + price.wait;

  const amounts = {
    bonif,
    precioFinal,
    precioViejo,
  };
  return amounts;
};

export const getCrossExperta = async (
  module: string,
  distance: number | undefined,
  listOfPricesDataBase: Sale[]
) => {
  let price: any;
  const dataModule = (
    await FIRESTORE.DOCUMENT_MODULE_TRANSFER(module).get()
  ).data();
  const zone = dataModule!.info.zona === "CABA" ? "CABA" : "AMBA";
  const priceZone: Sale[] = listOfPricesDataBase.filter((d) => d.name === zone);
  const distance_km = distance! / 1000;
  const oldPrices = priceZone[0].value_records?.find(
    (record: any) =>
      dataModule!.destination.time.toDate() >= record.valid_from.toDate() &&
      dataModule!.destination.time.toDate() <= record.valid_to.toDate()
  );
  console.log(zone, priceZone, distance_km, oldPrices, module);

  if (dataModule!.status.finished_status === FinishStatus.Negativo) {
    if (
      dataModule!.destination.time.toDate() >= priceZone[0].valid_from.toDate()
    ) {
      price = priceZone[0].price_negative;
    } else {
      price = oldPrices!.price_negative;
    }
  } else if (dataModule!.status.finished_status === FinishStatus.Exitoso) {
    if (dataModule!.transfers.past) {
      if (dataModule!.transfers.past!.going) {
        for (let i = 0; i < dataModule!.transfers.past!.going.length; i++) {
          const transferData = (
            await dataModule!.transfers.past!.going[i].get()
          ).data();

          if (dataModule?.type === ModuleTransferType.SIMPLE_TRANSFER) {
            if (
              dataModule!.destination.time.toDate() >=
              priceZone[0].valid_from.toDate()
            ) {
              if (
                transferData!.info.distance_origin_destination <=
                priceZone[0].km_minimun_trip
              ) {
                price = priceZone[0].price_min_trip;
              } else {
                price = priceZone[0].price_km * distance_km;
              }
            } else if (
              dataModule!.destination.time.toDate() <
              priceZone[0].valid_from.toDate()
            ) {
              if (
                transferData!.info.distance_origin_destination <=
                priceZone[0].km_minimun_trip
              ) {
                price = oldPrices!.price_min_trip;
              } else {
                const oldPrices = priceZone[0].value_records?.find(
                  (record: any) =>
                    dataModule!.destination.time.toDate() >=
                      record.valid_from.toDate() &&
                    dataModule!.destination.time.toDate() <=
                      record.valid_to.toDate()
                );
                price = oldPrices!.price_km * distance_km;
              }
            }
          } else if (
            dataModule?.type ===
            ModuleTransferType.TRIP_AND_RETURN_WITH_DIFFERENT_DRIVER
          ) {
            if (
              // modulo exitoso, transfer negativo
              dataModule.status.finished_status === FinishStatus.Exitoso &&
              dataModule.status.stage === TransferStage.finished &&
              transferData?.status.finished_status === FinishStatus.Negativo &&
              transferData.status.stage === TransferStage.finished
            ) {
              if (
                //facturar el negativo como minimo
                transferData.info.amount!.base_value! <=
                transferData.info.amount!.maximum_price_negative_transfer!
              ) {
                if (
                  dataModule!.destination.time.toDate() >=
                  priceZone[0].valid_from.toDate()
                ) {
                  price = priceZone[0].price_min_trip;
                } else if (
                  dataModule!.destination.time.toDate() <
                  priceZone[0].valid_from.toDate()
                ) {
                  price = oldPrices!.price_min_trip;
                }
              } else {
                //Facturar por KM
                if (
                  dataModule!.destination.time.toDate() >=
                  priceZone[0].valid_from.toDate()
                ) {
                  price = priceZone[0].price_km * distance_km;
                } else if (
                  dataModule!.destination.time.toDate() <
                  priceZone[0].valid_from.toDate()
                ) {
                  const oldPrices = priceZone[0].value_records?.find(
                    (record: any) =>
                      dataModule!.destination.time.toDate() >=
                        record.valid_from.toDate() &&
                      dataModule!.destination.time.toDate() <=
                        record.valid_to.toDate()
                  );
                  price = oldPrices!.price_min_trip;
                }
              }
            } else {
              if (
                dataModule!.destination.time.toDate() >=
                priceZone[0].valid_from.toDate()
              ) {
                if (
                  dataModule!.info.distance_origin_destination <=
                  priceZone[0].km_minimun_trip
                ) {
                  price = priceZone[0].price_min_trip * 2;
                } else {
                  price = priceZone[0].price_km * distance_km * 2;
                }
              } else if (
                dataModule!.destination.time.toDate() <
                priceZone[0].valid_from.toDate()
              ) {
                if (
                  dataModule!.info.distance_origin_destination <=
                  oldPrices!.km_minimun_trip
                ) {
                  price = oldPrices!.price_min_trip * 2;
                } else {
                  price = oldPrices!.price_km * distance_km * 2;
                }
              }
            }
          }
        }
      }
    }
  }
  console.log(price, "price");

  return price;
};

export const getTotalModule = async (module: string) => {
  const dataModule = (
    await FIRESTORE.DOCUMENT_MODULE_TRANSFER(module).get()
  ).data();
  let authorized_wait = 0;
  let priceGoingExitoso = 0;
  let priceGoingNegativo = 0;
  let priceReturnExitoso = 0;
  let priceReturnNegative = 0;
  let globalInfo: number;
  const globalData = (
    await FIRESTORE.DOCUMENT_CONFIGURATION_ALL().get()
  ).data();
  const oldCommission = globalData!.value_records?.find(
    (record: any) =>
      dataModule!.origin.time.toDate() >= record.valid_from.toDate() &&
      dataModule!.origin.time.toDate() <= record.valid_to.toDate()
  );
  if (dataModule!.origin.time.toDate() >= globalData!.valid_from.toDate()) {
    globalInfo = Number(globalData!.markup_global!);
  } else {
    globalInfo = Number(oldCommission!.markup_global!);
  }

  if (dataModule?.status.finished_status === FinishStatus.Exitoso) {
    if (dataModule!.transfers.past) {
      if (dataModule!.transfers.past!.going) {
        for (let i = 0; i < dataModule!.transfers.past!.going.length; i++) {
          const transferData = (
            await dataModule!.transfers.past!.going[i].get()
          ).data();

          if (transferData?.status.finished_status === FinishStatus.Exitoso) {
            priceGoingExitoso = Number(transferData.amount);
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.going[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.going[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      authorized_wait =
                        authorized_wait +
                        authData.aunthorized_min *
                          transferData.info.amount?.price_minute_wait!;
                    })
                  );
                }
              }
            }
            const extra =
              calculateExtraAwaitTimeRequest(transferData) *
              transferData.info.amount?.price_minute_wait!;

            let additionals = calculateAdditionals(transferData);

            priceGoingExitoso = priceGoingExitoso + additionals;
          }
          if (transferData?.status.finished_status === FinishStatus.Negativo) {
            priceGoingNegativo = Number(transferData.amount);
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.going[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.going[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      authorized_wait =
                        authorized_wait +
                        Number(authData.aunthorized_min) *
                          transferData.info.amount?.price_minute_wait!;
                    })
                  );
                }
              }
            }
            const extra =
              Number(calculateExtraAwaitTimeRequest(transferData)) *
              transferData.info.amount?.price_minute_wait!;

            let additionals = calculateAdditionals(transferData);

            priceGoingNegativo = priceGoingNegativo + extra + additionals;
          }
        }
      }
      if (dataModule!.transfers.past!.return) {
        for (let i = 0; i < dataModule!.transfers.past!.return.length; i++) {
          const transferData = (
            await dataModule!.transfers.past!.return[i].get()
          ).data();

          if (transferData?.status.finished_status === FinishStatus.Exitoso) {
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.return[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.return[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      authorized_wait =
                        authorized_wait +
                        authData.aunthorized_min *
                          transferData.info.amount?.price_minute_wait!;
                    })
                  );
                }
              }
            }
            priceReturnExitoso = Number(transferData.amount);
            let extra;
            if (transferData.info.amount?.price_minute_wait!) {
              extra =
                calculateExtraAwaitTimeRequest(transferData) *
                transferData.info.amount?.price_minute_wait!;
            } else {
              extra = 0;
            }

            let additionals = calculateAdditionals(transferData);

            priceReturnExitoso = priceReturnExitoso + extra + additionals;
          }
          if (transferData?.status.finished_status === FinishStatus.Negativo) {
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.return[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.return[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      authorized_wait =
                        authorized_wait +
                        Number(
                          authData.aunthorized_min *
                            transferData.info.amount?.price_minute_wait!
                        );
                    })
                  );
                }
              }
            }
            priceReturnNegative = Number(transferData.amount);
            const extra =
              Number(calculateExtraAwaitTimeRequest(transferData)) *
              transferData.info.amount?.price_minute_wait!;

            let additionals = calculateAdditionals(transferData);

            priceReturnNegative = priceReturnNegative + extra + additionals;
            if (additionals > 0) {
            }
          }
        }
      }
    }

    return Number(
      (priceGoingExitoso +
        priceGoingNegativo +
        priceReturnExitoso +
        priceReturnNegative) *
        globalInfo
    );
  } else {
    if (dataModule!.transfers.past) {
      if (dataModule!.transfers.past!.going) {
        for (let i = 0; i < dataModule!.transfers.past!.going.length; i++) {
          const transferData = (
            await dataModule!.transfers.past!.going[i].get()
          ).data();
          if (transferData?.status.finished_status === FinishStatus.Exitoso) {
            priceGoingExitoso = Number(transferData.amount);
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.going[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.going[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      authorized_wait =
                        authorized_wait +
                        authData.aunthorized_min *
                          transferData.info.amount?.price_minute_wait!;
                    })
                  );
                }
              }
            }
            const extra =
              calculateExtraAwaitTimeRequest(transferData) *
              transferData.info.amount?.price_minute_wait!;

            let additionals = calculateAdditionals(transferData);

            priceGoingExitoso = priceGoingExitoso + extra + additionals;
          }
          if (transferData?.status.finished_status === FinishStatus.Negativo) {
            priceGoingNegativo = +Number(transferData.amount);
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.going[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.going[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      authorized_wait =
                        authorized_wait +
                        Number(authData.aunthorized_min) *
                          transferData.info.amount?.price_minute_wait!;
                    })
                  );
                }
              }
            }
            const extra =
              Number(calculateExtraAwaitTimeRequest(transferData)) *
              transferData.info.amount?.price_minute_wait!;

            let additionals = calculateAdditionals(transferData);

            priceGoingNegativo = priceGoingNegativo + extra + additionals;
          }
        }
      }
      if (dataModule!.transfers.past!.return) {
        for (let i = 0; i < dataModule!.transfers.past!.return.length; i++) {
          const transferData = (
            await dataModule!.transfers.past!.return[i].get()
          ).data();
          if (transferData?.status.finished_status === FinishStatus.Exitoso) {
            priceReturnExitoso = Number(transferData.amount);
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.return[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.return[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      authorized_wait =
                        authorized_wait +
                        authData.aunthorized_min *
                          transferData.info.amount?.price_minute_wait!;
                    })
                  );
                }
              }
            }
            const extra =
              calculateExtraAwaitTimeRequest(transferData) *
              transferData.info.amount?.price_minute_wait!;

            let additionals = calculateAdditionals(transferData);

            priceReturnExitoso = priceReturnExitoso + extra + additionals;
          }
          if (transferData?.status.finished_status === FinishStatus.Negativo) {
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.return[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.return[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      authorized_wait =
                        authorized_wait +
                        Number(
                          authData.aunthorized_min *
                            transferData.info.amount?.price_minute_wait!
                        );
                    })
                  );
                }
              }
            }
            priceReturnNegative = Number(transferData.amount);
            const extra =
              Number(calculateExtraAwaitTimeRequest(transferData)) *
              transferData.info.amount?.price_minute_wait!;

            let additionals = calculateAdditionals(transferData);

            priceReturnNegative = priceReturnNegative + extra + additionals;
          }
        }
      }
    }
    return Number(
      (priceGoingExitoso +
        priceGoingNegativo +
        priceReturnExitoso +
        priceReturnNegative) *
        globalInfo
    );
  }
};

export const getTotalModuleModal = async (module: string) => {
  const dataModule = (
    await FIRESTORE.DOCUMENT_MODULE_TRANSFER(module).get()
  ).data();
  let totalTolls = 0;
  let waitGoing = 0;
  let waitReturn = 0;
  let priceGoingExitoso = 0;
  let priceGoingNegativo = 0;
  let priceReturnExitoso = 0;
  let priceReturnNegative = 0;

  let globalInfo: number;
  const globalData = (
    await FIRESTORE.DOCUMENT_CONFIGURATION_ALL().get()
  ).data();
  const oldCommission = globalData!.value_records?.find(
    (record: any) =>
      dataModule!.origin.time.toDate() >= record.valid_from.toDate() &&
      dataModule!.origin.time.toDate() <= record.valid_to.toDate()
  );
  if (dataModule!.origin.time.toDate() >= globalData!.valid_from.toDate()) {
    globalInfo = Number(globalData!.markup_global!);
  } else {
    globalInfo = Number(oldCommission!.markup_global!);
  }
  if (dataModule?.status.finished_status === FinishStatus.Exitoso) {
    if (dataModule!.transfers.past) {
      if (dataModule!.transfers.past!.going) {
        for (let i = 0; i < dataModule!.transfers.past!.going.length; i++) {
          const transferData = (
            await dataModule!.transfers.past!.going[i].get()
          ).data();
          if (transferData?.status.finished_status === FinishStatus.Exitoso) {
            priceGoingExitoso = Number(transferData.amount);
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.going[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.going[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      waitGoing =
                        waitGoing +
                        authData.aunthorized_min *
                          transferData.info.amount?.price_minute_wait!;
                    })
                  );
                }
              }
            }
            let extra;
            if (transferData.info.amount?.price_minute_wait!) {
              extra =
                calculateExtraAwaitTimeRequest(transferData) *
                transferData.info.amount?.price_minute_wait!;
            } else {
              extra = 0;
            }

            let additionals = calculateAdditionals(transferData);

            priceGoingExitoso = priceGoingExitoso + extra + additionals;
            if (transferData.tolls) {
              for (let j = 0; j < transferData.tolls.data.length; j++) {
                const tollAmount = transferData!.tolls!.data[j].amount_corrected
                  ? Number(transferData.tolls.data[j].amount_corrected)
                  : Number(transferData.tolls.data[j].amount);
                totalTolls = totalTolls + tollAmount;
              }
            }
          }

          if (transferData?.status.finished_status === FinishStatus.Negativo) {
            priceGoingNegativo = Number(transferData.amount);
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.going[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.going[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      waitGoing =
                        waitGoing +
                        Number(authData.aunthorized_min) *
                          transferData.info.amount?.price_minute_wait!;
                    })
                  );
                }
              }
            }
            const extra =
              Number(calculateExtraAwaitTimeRequest(transferData)) *
              transferData.info.amount?.price_minute_wait!;

            let additionals = calculateAdditionals(transferData);

            priceGoingNegativo = priceGoingNegativo + extra + additionals;

            if (transferData.tolls) {
              for (let j = 0; j < transferData.tolls.data.length; j++) {
                const tollAmount = transferData!.tolls!.data[j].amount_corrected
                  ? Number(transferData.tolls.data[j].amount_corrected)
                  : Number(transferData.tolls.data[j].amount);
                totalTolls = totalTolls + tollAmount;
              }
            }
          }
        }
      }
      if (dataModule!.transfers.past!.return) {
        for (let i = 0; i < dataModule!.transfers.past!.return.length; i++) {
          const transferData = (
            await dataModule!.transfers.past!.return[i].get()
          ).data();
          if (transferData?.status.finished_status === FinishStatus.Exitoso) {
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.return[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.return[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      waitReturn =
                        waitReturn +
                        authData.aunthorized_min *
                          transferData.info.amount?.price_minute_wait!;
                    })
                  );
                }
              }
            }
            priceReturnExitoso = Number(transferData.amount);
            let extra;
            if (transferData.info.amount?.price_minute_wait!) {
              extra =
                calculateExtraAwaitTimeRequest(transferData) *
                transferData.info.amount?.price_minute_wait!;
            } else {
              extra = 0;
            }

            let additionals = calculateAdditionals(transferData);

            priceReturnExitoso = priceReturnExitoso + extra + additionals;

            if (transferData.tolls) {
              for (let j = 0; j < transferData.tolls.data.length; j++) {
                const tollAmount = transferData!.tolls!.data[j].amount_corrected
                  ? Number(transferData.tolls.data[j].amount_corrected)
                  : Number(transferData.tolls.data[j].amount);
                totalTolls = totalTolls + tollAmount;
              }
            }
          }
          if (transferData?.status.finished_status === FinishStatus.Negativo) {
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.return[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.return[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      waitReturn =
                        waitReturn +
                        Number(
                          authData.aunthorized_min *
                            transferData.info.amount?.price_minute_wait!
                        );
                    })
                  );
                }
              }
            }
            priceReturnNegative = Number(transferData.amount);
            const extra =
              Number(calculateExtraAwaitTimeRequest(transferData)) *
              transferData.info.amount?.price_minute_wait!;

            let additionals = calculateAdditionals(transferData);

            priceReturnNegative = priceReturnNegative + extra + additionals;

            if (transferData.tolls) {
              for (let j = 0; j < transferData.tolls.data.length; j++) {
                const tollAmount = transferData!.tolls!.data[j].amount_corrected
                  ? Number(transferData.tolls.data[j].amount_corrected)
                  : Number(transferData.tolls.data[j].amount);
                totalTolls = totalTolls + tollAmount;
              }
            }
          }
        }
      }
    }

    const amounts = {
      comission: Number(
        (
          (priceGoingExitoso +
            priceGoingNegativo +
            priceReturnExitoso +
            priceReturnNegative) *
          (globalInfo - 1)
        ).toFixed(2)
      ),
      price: Number(
        (
          (priceGoingExitoso +
            priceGoingNegativo +
            priceReturnExitoso +
            priceReturnNegative) *
          globalInfo
        ).toFixed(2)
      ),
      tolls: Number(totalTolls.toFixed(2)),
      wait: Number((waitReturn + waitGoing).toFixed(2)),
      //agency_comision: (priceGoing + priceReturn) * (globalInfo!.markup_global -1)
    };

    return amounts;
  } else {
    if (dataModule!.transfers.past) {
      if (dataModule!.transfers.past!.going) {
        for (let i = 0; i < dataModule!.transfers.past!.going.length; i++) {
          const transferData = (
            await dataModule!.transfers.past!.going[i].get()
          ).data();
          if (transferData?.status.finished_status === FinishStatus.Exitoso) {
            priceGoingExitoso = Number(transferData.amount);
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.going[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.going[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      waitGoing =
                        waitGoing +
                        authData.aunthorized_min *
                          transferData.info.amount?.price_minute_wait!;
                    })
                  );
                }
              }
            }
            const extra =
              calculateExtraAwaitTimeRequest(transferData) *
              transferData.info.amount?.price_minute_wait!;

            let additionals = calculateAdditionals(transferData);
            priceGoingExitoso = priceGoingExitoso + extra + additionals;
          }
          if (transferData?.status.finished_status === FinishStatus.Negativo) {
            priceGoingNegativo = Number(transferData.amount);
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.going[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.going[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      waitGoing =
                        waitGoing +
                        authData.aunthorized_min *
                          transferData.info.amount?.price_minute_wait!;
                    })
                  );
                }
              }
            }
            const extra =
              calculateExtraAwaitTimeRequest(transferData) *
              transferData.info.amount?.price_minute_wait!;

            let additionals = calculateAdditionals(transferData);

            priceGoingNegativo = priceGoingNegativo + extra + additionals;

            if (transferData.tolls) {
              for (let j = 0; j < transferData.tolls.data.length; j++) {
                const tollAmount = transferData!.tolls!.data[j].amount_corrected
                  ? Number(transferData.tolls.data[j].amount_corrected)
                  : Number(transferData.tolls.data[j].amount);
                totalTolls = totalTolls + tollAmount;
              }
            }
          }
        }
      }
      if (dataModule!.transfers.past!.return) {
        for (let i = 0; i < dataModule!.transfers.past!.return.length; i++) {
          const transferData = (
            await dataModule!.transfers.past!.return[i].get()
          ).data();
          if (transferData?.status.finished_status === FinishStatus.Exitoso) {
            priceReturnExitoso = Number(transferData.amount);
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.return[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.return[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      waitGoing =
                        waitGoing +
                        authData.aunthorized_min *
                          transferData.info.amount?.price_minute_wait!;
                    })
                  );
                }
              }
            }
            const extra =
              calculateExtraAwaitTimeRequest(transferData) *
              transferData.info.amount?.price_minute_wait!;

            let additionals = calculateAdditionals(transferData);

            priceReturnExitoso = priceReturnExitoso + extra + additionals;
          }
          if (transferData?.status.finished_status === FinishStatus.Negativo) {
            if (transferData?.status.history.reports) {
              for (
                let j = 0;
                j < transferData.status.history.reports.length;
                j++
              ) {
                if (transferData.status.history.reports[j].date_buy15) {
                  const auths = await firebase
                    .firestore()
                    .collection(
                      `user/${
                        dataModule!.transfers.past!.return[i].parent.parent?.id
                      }/transfer`
                    )
                    .doc(dataModule!.transfers.past!.return[i].id)
                    .collection("authorization_wait")
                    .get();
                  await Promise.all(
                    auths.docs.map(async (auth) => {
                      const authData = auth.data();
                      waitReturn =
                        waitReturn +
                        authData.aunthorized_min *
                          transferData.info.amount?.price_minute_wait!;
                    })
                  );
                }
              }
            }
            priceReturnNegative = Number(transferData.amount);
            const extra =
              calculateExtraAwaitTimeRequest(transferData) *
              transferData.info.amount?.price_minute_wait!;

            let additionals = calculateAdditionals(transferData);

            priceReturnNegative = priceReturnNegative + extra + additionals;

            if (transferData.tolls) {
              for (let j = 0; j < transferData.tolls.data.length; j++) {
                const tollAmount = transferData!.tolls!.data[j].amount_corrected
                  ? Number(transferData.tolls.data[j].amount_corrected)
                  : Number(transferData.tolls.data[j].amount);
                totalTolls = totalTolls + tollAmount;
              }
            }
          }
        }
      }
    }
    const amounts = {
      comission: Number(
        (
          (priceGoingExitoso +
            priceGoingNegativo +
            priceReturnExitoso +
            priceReturnNegative) *
          (globalInfo - 1)
        ).toFixed(2)
      ),
      price: Number(
        (
          (priceGoingExitoso +
            priceGoingNegativo +
            priceReturnExitoso +
            priceReturnNegative) *
          globalInfo
        ).toFixed(2)
      ),
      tolls: Number(totalTolls.toFixed(2)),
      wait: Number((waitReturn + waitGoing).toFixed(2)),
    };
    return amounts;
  }
};

const calculateExtraAwaitTimeRequest = (dataTransfer: ScheduledTransfer) => {
  let amountTime = 0;

  if (dataTransfer.type === TransferType.TRIP_AND_RETURN) {
    const recordWaitStarted = dataTransfer.status.history.stages.find(
      (v) => v.stage === TransferStage.waiting_return_request
    );
    let recordReturnRequested = dataTransfer.status.history.stages.find(
      (v) => v.stage === TransferStage.return_requested
    );

    //Si no nos pidieron vuelta, entonces igual cobramos como tiempo de espera hasta que finalizo el traslado
    //No nos pidieron vuelta y se finalizó automáticamente
    if (recordReturnRequested === undefined) {
      recordReturnRequested = dataTransfer.status.history.stages.find(
        (v) => v.stage === TransferStage.finished
      );
    }

    if (
      recordWaitStarted !== undefined &&
      recordReturnRequested !== undefined
    ) {
      const amountTimeWaitingRequest = moment(
        recordReturnRequested.time.toDate()
      ).diff(recordWaitStarted.time.toDate(), "minutes");
      if (amountTimeWaitingRequest > 60)
        amountTime += amountTimeWaitingRequest - 60;
    }
  }
  return amountTime;
};
const calculateAdditionals = (dataTransfer: ScheduledTransfer) => {
  let totalAdditons = 0;
  if (dataTransfer.info.amount?.addition) {
    totalAdditons += dataTransfer.info.amount.addition;
  }
  if (dataTransfer.info.amount?.additional_K) {
    totalAdditons += dataTransfer.info.amount.additional_K;
  }
  if (dataTransfer.info.amount?.additional_5M) {
    totalAdditons += dataTransfer.info.amount.additional_5M;
  }
  if (dataTransfer.info.amount?.additional_C) {
    totalAdditons += dataTransfer.info.amount.additional_C;
  }
  if (dataTransfer.info.amount?.additional_E) {
    totalAdditons += dataTransfer.info.amount.additional_E;
  }
  if (dataTransfer.info.amount?.additional_G) {
    totalAdditons += dataTransfer.info.amount.additional_G;
  }
  return totalAdditons;
};

const Tr = styled.tr`
  th {
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  word-wrap: break-word;
`;

const Table = styled(TableB)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default AdminTransfersWrongAmountPageKm;
