import { Button, H2, Padding, Spinner, NoData } from "components/styles";
import ROUTES from "constants/routes";
import database, { WithId, WithUid } from "controllers/database";
import { getFullAddress } from "controllers/map";
import React, { useEffect, useState, useMemo } from "react";
import { Col, Form, Pagination, Row, Table as TableB } from "react-bootstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ScheduledTransfer } from "types/transfer";
import { Paginator } from "util/paginator";
import { is } from "ramda";

//TODO: CAMBIAR PAGINADOR A PAGINADOR HOOK
const AdminTollsPage = () => {
  const pageLimit = 20;
  const paginator = useMemo(
    () =>
      new Paginator(
        ScheduledTransfer,
        database.collectionGroup("transfer"),
        ["origin.time", "tolls"],
        pageLimit
      ),
    []
  );

  const [items, setItems] = useState(
    [] as (WithUid & WithId & ScheduledTransfer)[]
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    setLoading(true);
    paginator
      .getData()
      .then((data) => data.filter((d) => d.status.finished_status !== 1))
      .then(setItems)
      .then(() => setLoading(false));
  };

  const prevPage = () => {
    paginator.prev();
    updateData();
  };
  const nextPage = () => {
    paginator.next();
    updateData();
  };
  const changeFilterStatus = (value: number) => {
    if (value === -1)
      paginator.changeCollection(database.collectionGroup("transfer"));
    else if (value === 0)
      paginator.changeCollection(
        database.collectionGroup("transfer").where("tolls.revised").is(true)
      );
    else
      paginator.changeCollection(
        database.collectionGroup("transfer").where("tolls.revised").is(false)
      );
    updateData();
  };

  return (
    <Padding pad="5px">
      <Row style={{ marginBottom: 20 }}>
        <Col md={3}>
          <H2>Peajes</H2>
        </Col>

        <Col md={{ offset: 6, span: 3 }}>
          <Form.Control
            as="select"
            onChange={(event) =>
              changeFilterStatus(
                parseInt((event.target as HTMLTextAreaElement).value)
              )
            }
          >
            <option value="-1">TODOS</option>
            <option value={"0"}>REVISADOS</option>
            <option value={"1"}>NO REVISADOS</option>
          </Form.Control>
        </Col>
      </Row>
      {loading ? (
        <Spinner />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        <React.Fragment>
          <Table hover responsive>
            <thead>
              <tr>
                <th>Traslado</th>
                <th>Fecha/Hora</th>
                <th>Pasajero</th>
                <th>Origen</th>
                <th>Destino</th>
                <th>Monto/s</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Link
                      to={ROUTES.ADMIN_MODULE_TRANSFER_VIEW(item.module.id)}
                    >
                      <Button variant="secondary">Ver Traslado</Button>
                    </Link>
                  </td>
                  <td>
                    <Moment
                      date={item.destination.time.toDate()}
                      format="DD/MM/YYYY HH:mm"
                    />
                  </td>
                  <td>{item.passenger.name}</td>
                  <td>{getFullAddress(item.origin)}</td>
                  <td>{getFullAddress(item.destination)}</td>
                  <td>
                    {item.tolls?.data
                      .map((item) =>
                        item.amount_corrected !== undefined
                          ? `CORREGIDO $${item.amount_corrected}`
                          : `$${item.amount}`
                      )
                      .join(",")}
                  </td>
                  <td>
                    {item.tolls?.revised
                      ? "REVISADO/S"
                      : item.tolls?.revised === false
                      ? "NO REVISADO/S"
                      : "Error"}
                  </td>
                  <td>
                    <Link to={ROUTES.ADMIN_VIEW_TOLLS(item.id, item.uid)}>
                      <Button variant="light">Ver</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </React.Fragment>
      )}
      <Pagination style={{ justifyContent: "center" }}>
        {!paginator.firstPage && <Pagination.Prev onClick={prevPage} />}
        <Pagination.Item active>{paginator.pageNumber}</Pagination.Item>
        {!paginator.lastPage && <Pagination.Next onClick={nextPage} />}
      </Pagination>
    </Padding>
  );
};

const Table = styled(TableB)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default AdminTollsPage;
