import { getAddressFromPartial } from "controllers/map"
import React, { useState } from "react"
import { ValueType } from "react-select"
import AsyncSelect from 'react-select/async'
import { from, Subject } from 'rxjs'

import { debounceTime, map, switchMap, first } from 'rxjs/operators'

declare type OptionType = 
{
    label : any,
    value : any
}

export const AddressInput = ({value, isValid, isInvalid, onChange, onBlur, disabled, ...props} : any) => {
    const [inputValue, setInputValue] = useState(value)
    const onInputChange = (option : any, { action } : any) => {
        //console.log(option, action);
        if (action === "input-change") {
            //const optionLength = option.length;
            //const inputValueLength = inputValue.length;
            //const newInputValue = optionLength < inputValueLength ? option : inputValue + option[option.length - 1];
            setInputValue(option)
            if(value !== undefined){ //Borramos el valor anterior
                onChange(null)
            }
        }
      };

    return (
        <div className={isInvalid ? "is-invalid" : ""}>
            <AsyncSelect 
                styles={{
                    input: (provided) => ({
                        ...provided,
                        input: {
                          opacity: "1 !important",
                        },
                      }),
                    control: styles => ({
                        ...styles,
                        borderColor: isInvalid ? 'red' : isValid ? 'green' : styles.borderColor
                    })
                }} 
                cacheOptions
                loadOptions={loadOptions}
                onChange={ (option : any) => {setInputValue(option.value); onChange(option ? option.value : undefined)} }
                //onBlur={(option : ValueType<any>) => onBlur(option? option.value : undefined)}
                defaultInputValue={value}
                placeholder='Ingrese una dirección...'
                isDisabled={disabled}
                onInputChange={onInputChange}
                inputValue={inputValue}
                value={value}
            />
        </div>
)}


const sub = new Subject<string>()
const debounced = sub.pipe(
    debounceTime(1000),
    switchMap(address => {
        return from(getAddressFromPartial(address))
    }),

    map(results => results.map(s=> ({value: s, label: s}))),
)


const loadOptions = (inputValue: any, callback : any) => {
    debounced.pipe(
        first()
    ).subscribe((value) => {
      callback(value);
    })
    sub.next(inputValue)
  }