import React from 'react'
import * as B from 'react-bootstrap'
import styled from 'styled-components'

const Contact = () => (
    <Container fluid={true}  id="email" className="no-padding">
        <div className="container">
        <div>
            <h3 style={{"marginBottom": "20px", fontSize: "0.9em"}}>Contacto: <a href="mailto:conductores@satapp.com.ar">conductores@satapp.com.ar</a></h3>
        </div>
        </div>
    </Container>
)

const Container = styled(B.Container)`
    text-align: center;
    h3 {
        font-weight: 600;
        a {
            text-decoration: none;
            color: #337ab7;
        }
    }
`

export default Contact