import { LoadScript } from "@react-google-maps/api";
import { Tabs } from "antd";
import { Spinner } from "components/styles";
import { useDatabaseDocref } from "controllers/hooks";
import React from "react";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { useParams } from "react-router";
import { TransferStage } from "types/transfer";
import { UserType } from "types/user-data";
import { ScheduledTransferView } from "../scheduled";
import { UnassignedTransferView } from "../unassigned";
import { Actions, NeedAdminAction } from "./actions";
import { HeaderModule } from "./header";
import { SubHeaderModule } from "./subheader";
import { FIRESTORE } from "constants/firestore";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { QuizParams } from "types/module-transfer";
const { TabPane } = Tabs;

const libraries: Libraries = ["places", "drawing"];
const AdminModuleTransfersViewPage = () => {
  const userType: UserType = useSelector(
    (state: any) => state.firebase.profile.type
  );
  const { idModule } = useParams<QuizParams>();
  const module = useDatabaseDocref(
    FIRESTORE.DOCUMENT_MODULE_TRANSFER(idModule)
  );

  if (!isLoaded(module)) return <Spinner />;

  if (module === undefined) return <>Error</>;

  const moduleIsActive =
    module.transfers.active !== undefined ||
    module.transfers.searching_driver !== undefined;
  let cont = 0;
  return (
    <LoadScript //Script que carga la librería de google maps para el mapa
      id="script-loader"
      googleMapsApiKey="AIzaSyD9_LJRGAjiACJ0njksDgoK5m85obW02ng"
      language="es"
      libraries={libraries}
    >
      <Actions module={module} idModule={idModule} />
      <HeaderModule module={module} id={idModule} />

      {userType === UserType.admin &&
        module.status.stage === TransferStage.waiting_admin_action && (
          <NeedAdminAction module={module} idModule={idModule} />
        )}

      {userType === UserType.admin && (
        <SubHeaderModule
          module={module}
          doc={FIRESTORE.DOCUMENT_MODULE_TRANSFER(idModule)}
        />
      )}

      <Tabs defaultActiveKey={moduleIsActive ? idModule : "ida-0"}>
        {module.transfers.past?.going &&
          module.transfers.past.going.map((r) => {
            cont += 1;
            return (
              <TabPane tab={`Traslado ${cont}`} key={cont.toString()}>
                <ScheduledTransferView idModule={idModule} refTransfer={r} />
              </TabPane>
            );
          })}
        {module.transfers.past?.return &&
          module.transfers.past.return.map((r) => {
            cont += 1;
            return (
              <TabPane tab={`Traslado ${cont}`} key={`Traslado ${cont}`}>
                <ScheduledTransferView idModule={idModule} refTransfer={r} />
              </TabPane>
            );
          })}
        {moduleIsActive && (
          <TabPane tab={`Traslado ${cont + 1}`} key={idModule}>
            {module.transfers.active ? (
              <ScheduledTransferView
                idModule={idModule}
                refTransfer={module.transfers.active}
              />
            ) : (
              module.transfers.searching_driver && (
                <UnassignedTransferView
                  refTransfer={module.transfers.searching_driver}
                />
              )
            )}
          </TabPane>
        )}
      </Tabs>
    </LoadScript>
  );
};

export default AdminModuleTransfersViewPage;
