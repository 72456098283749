import { ContainerPage } from "components/styles";
import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import ConfigTab from "./Global";
import Localities from "./Localities";
import Provinces from "./Provinces";
import Usuarios from "./Users";
import Zones from "./Zones";
import Arts from "./NewArt";
import Clients from "./Clients";
import MaxPrice from "./MaxPrice";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import EditIibb from "./iibb";
import Venta from "./Sale";

const ConfigPage = () => {
  const history = useHistory();
  const parsed = queryString.parse(window.location.search);

  if (parsed.t === undefined) history.push("?t=config");
  return (
    <ContainerPage>
      <Tabs
        id="1"
        activeKey={parsed.t}
        mountOnEnter
        onSelect={(k: string | null) => history.push(`?t=${k}`)}
      >
        <Tab eventKey="config" title="Configuración">
          <ConfigTab />
        </Tab>
        <Tab eventKey="usuarios" title="Usuarios">
          <Usuarios />
        </Tab>
        <Tab eventKey="zonas" title="Zonas">
          <Zones />
        </Tab>
        <Tab eventKey="localidades" title="Localidades">
          <Localities />
        </Tab>
        <Tab eventKey="provincias" title="Provincias">
          <Provinces />
        </Tab>
        <Tab eventKey="clientes" title="Clientes">
          <Clients />
        </Tab>
        <Tab eventKey="newClient" title="Alta ARTs">
          <Arts />
        </Tab>
        <Tab eventKey="iibb" title="IIBB">
          <EditIibb />
        </Tab>
        <Tab eventKey="ventas" title="Venta">
          <Venta />
        </Tab>
      </Tabs>
    </ContainerPage>
  );
};

export default ConfigPage;
