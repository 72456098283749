import ROUTES from "constants/routes";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ChargeOrders from "./ArtsPayments";
import Finances from "./Finances";
import Management from "./Management";
import MiniNavigation from './MiniNavigation/index';
import ProviderPayments from './DriversPayments/index';
import Purchases from "./Purchases";
import Sales from "./Sales";
import BillingInfo from "./Sales/view-bill";
import GenerateBill from "./Purchases/generate-bill";
import GenerateBillArt from "./Sales/generate-bill";
import Iibb from "./Sales/iibb";
import GeneratePaymentOrder from "./DriversPayments/generate-payment";
import AddChargePayment from "./ArtsPayments/generate-payment";
import ViewDriverPaymentOrder from "./DriversPayments/view-payment-order";
import ViewArtPayment from "./ArtsPayments/view-payment";
import ViewDriverBill from "./Purchases/view-bill";
import Bas from "./bas";

const AdminSales = () => {
	return (
        <>
		    <MiniNavigation/>
			<Switch>
				<Route 
					exact path={ROUTES.ADMIN_SALES_CHARGE}
					component={ChargeOrders} />
                <Route 
					path={ROUTES.ADMIN_SALES_CHARGE_ADD}
					component={AddChargePayment} />
                <Route 
					path={ROUTES.ADMIN_SALES_ART_PAYMENT()}
					component={ViewArtPayment} />
                <Route 
					path={ROUTES.ADMIN_SALES_SALES_DRIVER_BILL_INFO()}
					component={ViewDriverBill} />
                    
                <Route 
					path={ROUTES.ADMIN_SALES_FINANCES}
					component={Finances} />
                <Route 
					path={ROUTES.ADMIN_SALES_MANAGEMENT}
					component={Management} />
                <Route 
					exact path={ROUTES.ADMIN_SALES_DRIVERS_PAYMENTS}
					component={ProviderPayments} />
                <Route 
					path={ROUTES.ADMIN_SALES_DRIVERS_PAYMENTS_GENERATE_PAYMENT}
					component={GeneratePaymentOrder} />
                <Route 
					path={ROUTES.ADMIN_SALES_DRIVER_PAYMENT()}
					component={ViewDriverPaymentOrder} />
                <Route 
					exact path={ROUTES.ADMIN_SALES_PURCHASES}
					component={Purchases} />
                <Route 
					exact path={ROUTES.ADMIN_SALES_PURCHASES_GENERATE_BILL}
					component={GenerateBill} />
                <Route 
					exact path={ROUTES.ADMIN_SALES_SALES}
					component={Sales} />
                <Route 
					exact path={ROUTES.ADMIN_SALES_SALES_GENERATE_BILL}
					component={GenerateBillArt} />
                <Route 
                    path={ROUTES.ADMIN_SALES_SALES_ART_BILL_INFO()} 
                    component={BillingInfo} />
                <Route 
                    path={ROUTES.ADMIN_SALES_SALES_IIBB} 
                    component={Iibb} />
                <Route 
                    path={ROUTES.ADMIN_SALES_BAS} 
                    component={Bas} />
                <Redirect 
                    from='*' 
                    to={ROUTES.ADMIN_SALES_PURCHASES} />
			</Switch>
        </>
	);
}

export default AdminSales;
