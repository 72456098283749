import { Button, H2, Padding, Spinner, NoData } from "components/styles";
import ROUTES from "constants/routes";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table as TableB } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SelectDriverInput } from "../Sales/Purchases";
import { Redirect } from "react-router-dom";
import { usePaginator } from "util/hooks";
import { FIRESTORE } from "constants/firestore";
import { StepsFormNewcomers } from "components/User/Newcomers/pages";
import MiniNavigationErrors from "./MiniNavigation/index";

const AdminProfileToReviewed = () => {
  return (
    <MiniNavigationErrors active={2}>
      <MainContainer />
    </MiniNavigationErrors>
  );
};

export const MainContainer = () => {
  const [filter, setFilter] = useState("A REVISAR");
  const [driver, setDriver] = useState<string | undefined>(undefined);
  const [r, setR] = useState<boolean>(false);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const pageLimit = 100;

  const [items3, loading3, Pagination3, setCollection3] = usePaginator(
    FIRESTORE.COLLECTION_USERS()
      .where("profile_user_status", "==", 2)
      .orderBy("user_code", "desc"),
    pageLimit
  );
  useEffect(() => {
    setFilteredItems(items3.filter((item) => item.profile_user_status == 2));
    console.log(filteredItems);
  }, [loading3]);

  return (
    <>
      <Padding pad="5px">
        <Row>
          <Col md={2}>
            <H2>Usuarios a Revisar</H2>
          </Col>
        </Row>
        <br />
        {items3 !== undefined && items3.length === 0 ? (
          <NoData />
        ) : (
          <Table hover responsive>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Email</th>
                <th>Teléfono</th>
                <th>Estado de Registro</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items3.map((item, index) => (
                <tr key={index}>
                  <td style={{ wordBreak: "break-word" }}>
                    {item.name + " " + item.surname}
                  </td>
                  <td>{item.email}</td>
                  <td>{item.phone.area_code + " " + item.phone.number}</td>
                  <td>
                    {item.step_form_newcomers ===
                    StepsFormNewcomers["beginning"]
                      ? "Bienvenida"
                      : item.step_form_newcomers ===
                        StepsFormNewcomers["profile"]
                      ? " Perfil"
                      : item.step_form_newcomers ===
                        StepsFormNewcomers["licence"]
                      ? "Licencia"
                      : item.step_form_newcomers ===
                        StepsFormNewcomers["taxable"]
                      ? "Facturación"
                      : item.step_form_newcomers === StepsFormNewcomers["car"]
                      ? "Auto"
                      : item.step_form_newcomers ===
                        StepsFormNewcomers["terms_conditions"]
                      ? "Terminos y condiciones"
                      : item.step_form_newcomers === StepsFormNewcomers["quiz"]
                      ? " Quiz"
                      : item.step_form_newcomers === StepsFormNewcomers["afip"]
                      ? "Afip"
                      : item.step_form_newcomers ===
                        StepsFormNewcomers["finished"]
                      ? "Finalizado"
                      : null}
                  </td>
                  <td>
                    <Link to={ROUTES.ADMIN_USER_PROFILE_VIEW(item.id)}>
                      <Button variant="light">Ver Usuario</Button>
                    </Link>
                  </td>
                </tr>
              ))}
              <Pagination3 />
            </tbody>
          </Table>
        )}
      </Padding>
    </>
  );
};

const Table = styled(TableB)`
  background-color: #fff;
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export const ButtonSearch = styled(Button)`
  background-color: transparent !important;
  color: grey !important;
`;

export default AdminProfileToReviewed;
