export const ProvincesArgentina = [
  "Ciudad de Buenos Aires",
  "Buenos Aires",
  "Catamarca",
  "Chaco",
  "Chubut",
  "Córdoba",
  "Corrientes",
  "Entre Ríos",
  "Formosa",
  "Jujuy",
  "La Pampa",
  "La Rioja",
  "Mendoza",
  "Misiones",
  "Neuquén",
  "Río Negro",
  "Salta",
  "San Juan",
  "San Luis",
  "Santa Cruz",
  "Santa Fe",
  "Santiago del Estero",
  "Tierra del Fuego",
  "Tucumán",
];

export const ProvincesArgentinaEscaped = [
  "ciudad_de_buenos_aires",
  "buenos_aires",
  "catamarca",
  "chaco",
  "chubut",
  "cordoba",
  "corrientes",
  "entre_rios",
  "formosa",
  "jujuy",
  "la_pampa",
  "la_rioja",
  "mendoza",
  "misiones",
  "neuquen",
  "rio_negro",
  "salta",
  "san_juan",
  "san_luis",
  "santa_cruz",
  "santa_fe",
  "santiago_del_estero",
  "tierra_del_fuego",
  "tucuman",
];

export const ProvincesArgentinaMaps = [
  "Buenos Aires",
  "Provincia de Buenos Aires",
  "Catamarca",
  "Chaco",
  "Chubut",
  "Córdoba",
  "Corrientes",
  "Entre Ríos",
  "Formosa",
  "Jujuy",
  "La Pampa",
  "La Rioja",
  "Mendoza",
  "Misiones",
  "Neuquén",
  "Río Negro",
  "Salta",
  "San Juan",
  "San Luis",
  "Santa Cruz",
  "Santa Fe",
  "Santiago del Estero",
  "Tierra del Fuego",
  "Tucumán",
];

export const LocalitiesArgentina = [
  "9 DE JULIO (MISIONES)",
  "25 DE MAYO (MISIONES)",
  "28 DE JULIO",
  "ABDON CASTRO TOLAY",
  "ABRA PAMPA",
  "ABRALAITE",
  "ACASSUSO",
  "ACEBAL",
  "ACHERAL",
  "ACONQUIJA",
  "ADOLFO E. CARRANZA",
  "ADOLFO GONZALES CHAVES",
  "ADROGUE",
  "AGUAS CALIENTES (JUJUY)",
  "AGUILARES (TUCUMAN)",
  "ALBA POSSE",
  "ALBERTI",
  "ALCORTA",
  "ALDEA APELEG",
  "ALDEA ASUNCION",
  "ALDEA BELEIRO",
  "ALDEA EPULEF",
  "ALDEA ESCOLAR",
  "ALDEA PROTESTANTE",
  "ALDEA SALTO",
  "ALDEA SAN ANTONIO (GUALEGUAYCHU)",
  "ALDEA SAN FRANCISCO",
  "ALDEA SAN JUAN",
  "ALDEA SPATZENKUTTER",
  "ALDEA VALLE MARIA",
  "ALDERETES",
  "ALDO BONZI",
  "ALIJILAN",
  "ALMAFUERTE (MISIONES)",
  "ALTA GRACIA",
  "ALTO DE LAS JUNTAS",
  "ALTO RIO SENGUER",
  "ALVEAR (CORRIENTES)",
  "AMADORES",
  "AMAICHA DEL VALLE",
  "AMANAO",
  "AMERICA",
  "ANCA JULI",
  "ANCASTI",
  "ANDALGALA",
  "ANDALHUALA",
  "ANILLACO (CATAMARCA)",
  "ANQUINCILA",
  "ANTARTIDA ARGENTINA",
  "ANTELO",
  "ANTINACO (CATAMARCA)",
  "ANTOFALLA (CATAMARCA)",
  "APOSTOLES ",
  "APOYACO",
  "ARANGUREN ",
  "ARCADIA (TUCUMAN)",
  "AREA DE PROMOCION EL TRIANGULO",
  "AREA RESERVA CINTURON ECOLOGICO",
  "AREQUITO",
  "ARIAS (CORDOBA)",
  "ARISTOBULO DEL VALLE (MISIONES)",
  "ARMSTRONG (SANTA FE)",
  "ARRECIFES ",
  "ARROYITO",
  "ARROYO BARU",
  "ARROYO SECO",
  "ASTRA (CHUBUT)",
  "AVELLANEDA",
  "AVIA TERAI",
  "AZARA (MISIONES)",
  "AZUL ",
  "AÑATUYA",
  "BAHIA BLANCA",
  "BAHIA BUSTAMANTE",
  "BAJO CARACOLES",
  "BALCARCE",
  "BALDE DE LA PUNTA",
  "BANDA DE LUCERO",
  "BANFIELD",
  "BARADERO",
  "BARRANCA LARGA",
  "BARRANQUERAS",
  "BARRIO BANCARIO",
  "BARRIO EJERCITO DE LOS ANDES",
  "BARRIO SAN PEDRO PESCADOR",
  "BARRIOS (JUJUY)",
  "BASAIL",
  "BASAVILBASO",
  "BASE MARAMBIO",
  "BAÑADO DE OVANTA",
  "BECCAR",
  "BELEN (CATAMARCA)",
  "BELEN DE ESCOBAR",
  "BELL VILLE",
  "BELLA VISTA (CORRIENTES)",
  "BELLA VISTA (TUCUMAN)",
  "BELLA VISTA",
  "BENITO JUAREZ",
  "BERAZATEGUI",
  "BERISSO",
  "BERNAL",
  "BERNARDO DE IRIGOYEN (MISIONES)",
  "BERON DE ASTRADA (CORRIENTES)",
  "BETBEDER",
  "BILLINGHURST",
  "BLANCUNTRE",
  "BOULOGNE SUR MER ",
  "BOUQUET (SANTA FE)",
  "BOVRIL ",
  "BRAGADO",
  "BRANDSEN",
  "BRAZO LARGO",
  "BUEN PASTO",
  "BUENA VISTA (CATAMARCA)",
  "Buenos Aires",
  "BUENOS AIRES CHICO",
  "BURRUYACU",
  "BURZACO",
  "CAA CATI",
  "CAA YARI",
  "CAIMANCITO",
  "CALETA OLIVIA",
  "CALILEGUA (JUJUY)",
  "CAMARONES ",
  "CAMPANA ",
  "CAMPO DE MAYO",
  "CAMPO GRANDE (MISIONES)",
  "CAMPO LARGO",
  "CAMPO RAMON",
  "CAMPO VIERA",
  "CANDELARIA (MISIONES)",
  "CANGREJILLOS",
  "CAPAYAN (CATAMARCA)",
  "CAPILLA DEL MONTE",
  "CAPILLA DEL SEÑOR",
  "CAPIOVI",
  "CAPITAN BERMUDEZ",
  "CAPITAN SARMIENTO",
  "CAPITAN SOLARI (CHACO)",
  "CARAGUATAY ",
  "CARAPACHAY",
  "CARCARAÑA (SANTA FE)",
  "CARHUE",
  "CARILO",
  "CARLOS CASARES",
  "CARLOS TEJEDOR",
  "CARMEN DE ARECO",
  "CARMEN DE PATAGONES",
  "CARRENLEUFU",
  "CASA DE PIEDRA (LA PAZ)",
  "CASA DE PIEDRA (SANTA MARIA)",
  "CASABINDO",
  "CASEROS (ENTRE RIOS)",
  "CASEROS",
  "CASILDA",
  "CASPICHANGO",
  "CASTELAR",
  "CASTELLI",
  "CATUA",
  "CAUCETE",
  "CAÑADA DE GOMEZ",
  "CAÑADA ROSQUIN",
  "CAÑADON SECO",
  "CAÑUELAS",
  "CEIBAS",
  "CERES (SANTA FE)",
  "CERRITO (ENTRE RIOS)",
  "CERRO AZUL (MISIONES)",
  "CERRO CENTINELA",
  "CERRO CONDOR",
  "CERRO CORA (MISIONES)",
  "CEVIL REDONDO",
  "CHACABUCO (CIUDAD)",
  "CHAJARI",
  "CHALICAN",
  "CHAQUIAGO",
  "CHARADAI",
  "CHARATA",
  "CHASCOMUS (LOCALIDAD)",
  "CHAÑAR PUNCO",
  "CHILCAS",
  "CHIVILCOY",
  "CHOYA (CATAMARCA)",
  "CHUCHUCARUANA",
  "CHUMBICHA",
  "CHURRUCA",
  "CIERVO PETISO (CHACO)",
  "CITY BELL",
  "CIUDAD DE ALEJANDRO KORN",
  "CIUDAD DE CORRIENTES",
  "CIUDAD DE HURLINGHAM",
  "CIUDAD DE MENDOZA",
  "CIUDAD DE RESISTENCIA",
  "CIUDAD DE RIO CUARTO",
  "CIUDAD DE RIO TERCERO",
  "CIUDAD DE SAN JUAN",
  "CIUDAD DE SAN VICENTE",
  "CIUDAD DE SANTA FE ",
  "CIUDAD DE SANTIAGO DEL ESTERO",
  "CIUDAD EVITA",
  "CIUDAD JARDIN EL LIBERTADOR",
  "CIUDAD JARDIN LOMAS DEL PALOMAR",
  "CIUDADELA",
  "CLAYPOLE",
  "CLODOMIRO LEDESMA",
  "COLALAO DEL VALLE",
  "COLAN CONHUE",
  "COLANA",
  "COLLAGASTA",
  "COLOMBRES (TUCUMAN)",
  "COLON (ENTRE RIOS)",
  "COLON",
  "COLONIA ABORIGEN CHACO",
  "COLONIA ABORIGEN EL PASTORIL",
  "COLONIA ALBERDI",
  "COLONIA AURORA",
  "COLONIA AVELLANEDA",
  "COLONIA AVIGDOR",
  "COLONIA AYUI",
  "COLONIA BARANDA",
  "COLONIA BAYLINA",
  "COLONIA BENITEZ",
  "COLONIA DEL VALLE (CATAMARCA)",
  "COLONIA DELICIA",
  "COLONIA ELISA (CHACO)",
  "COLONIA HOCKER",
  "COLONIA NUEVA CONETA",
  "COLONIA OFICIAL Nº 5",
  "COLONIA POLANA",
  "COLONIA POPULAR",
  "COLONIA VICTORIA",
  "COLONIAS UNIDAS (CHACO)",
  "COLPES (AMBATO)",
  "COLPES (POMAN)",
  "COMANDANCIA FRIAS",
  "COMANDANTE ANDRESITO",
  "COMODORO RIVADAVIA",
  "CONCEPCION (CATAMARCA)",
  "CONCEPCION (CORRIENTES)",
  "CONCEPCION (TUCUMAN)",
  "CONCEPCION DE LA SIERRA",
  "CONCEPCION DEL BERMEJO (CHACO)",
  "CONCEPCION DEL URUGUAY",
  "CONCORDIA ",
  "CONDOR HUASI",
  "CONETA",
  "COPACABANA (CATAMARCA)",
  "COQUIMBITO",
  "CORANZULI",
  "CORCOVADO (CHUBUT)",
  "CORDOBITA",
  "CORONDA",
  "CORONEL ARNOLD",
  "CORONEL DORREGO",
  "CORONEL DU GRATY",
  "CORONEL MARTINEZ DE HOZ",
  "CORONEL PRINGLES",
  "CORONEL SUAREZ",
  "CORONEL VIDAL",
  "CORPUS (MISIONES)",
  "CORRAL QUEMADO ",
  "CORZUELA (CHACO)",
  "COSQUIN",
  "COSTA DE REYES",
  "COTE LAI",
  "CRESPO",
  "CRUCECITA",
  "CRUZ DEL EJE",
  "CURUZU CUATIA",
  "CUSHAMEN",
  "DAIREAUX",
  "DEAN FUNES (CIUDAD)",
  "DELFIN GALLO (TUCUMAN)",
  "DEPARTAMENTO AGUIRRE",
  "DEPARTAMENTO BELGRANO (SANTA FE)",
  "DEPARTAMENTO BIEDMA",
  "DEPARTAMENTO CASEROS",
  "DEPARTAMENTO CASTELLANOS",
  "DEPARTAMENTO COLON (ENTRE RIOS)",
  "DEPARTAMENTO CONSTITUCION",
  "DEPARTAMENTO CORPEN AIKE",
  "DEPARTAMENTO CUSHAMEN",
  "DEPARTAMENTO DESEADO",
  "DEPARTAMENTO ESCALANTE",
  "DEPARTAMENTO FLORENTINO AMEGHINO",
  "DEPARTAMENTO FUTALEUFU",
  "DEPARTAMENTO GARAY",
  "DEPARTAMENTO GENERAL LOPEZ",
  "DEPARTAMENTO GENERAL OBLIGADO",
  "DEPARTAMENTO GUALEGUAYCHU",
  "DEPARTAMENTO GUASAYAN",
  "DEPARTAMENTO GÜER AIKE",
  "DEPARTAMENTO IRIONDO",
  "DEPARTAMENTO JUAN BAUTISTA ALBERDI",
  "DEPARTAMENTO LA CAPITAL (SANTA FE)",
  "DEPARTAMENTO LA COCHA",
  "DEPARTAMENTO LAGO ARGENTINO",
  "DEPARTAMENTO LAGO BUENOS AIRES",
  "DEPARTAMENTO LANGUIÑEO",
  "DEPARTAMENTO LAS COLONIAS",
  "DEPARTAMENTO LEALES",
  "DEPARTAMENTO LULES",
  "DEPARTAMENTO MAGALLANES",
  "DEPARTAMENTO MONTEROS",
  "DEPARTAMENTO MORENO",
  "DEPARTAMENTO NUEVE DE JULIO (SANTA FE)",
  "DEPARTAMENTO RAWSON (CHUBUT)",
  "DEPARTAMENTO RIO CHICO (SANTA CRUZ)",
  "DEPARTAMENTO RIO CHICO (TUCUMAN)",
  "DEPARTAMENTO ROSARIO",
  "DEPARTAMENTO SAN CRISTOBAL",
  "DEPARTAMENTO SAN JAVIER (SANTA FE)",
  "DEPARTAMENTO SAN JERONIMO",
  "DEPARTAMENTO SAN JUSTO (SANTA FE)",
  "DEPARTAMENTO SAN LORENZO (SANTA FE)",
  "DEPARTAMENTO SAN MARTIN (SANTA FE)",
  "DEPARTAMENTO SAN SALVADOR",
  "DEPARTAMENTO SIMOCA",
  "DEPARTAMENTO TAFI DEL VALLE",
  "DEPARTAMENTO VERA",
  "DIADEMA ARGENTINA",
  "DIAMANTE ",
  "DOCK SUD",
  "DOCTOR ATILIO OSCAR VIGLIONE",
  "DOCTOR RICARDO ROJAS",
  "DOLAVON",
  "DOLORES",
  "DOMSELAAR",
  "DON BOSCO",
  "DON CRISTOBAL PRIMERO",
  "DON TORCUATO",
  "DOS ARROYOS",
  "DOS DE MAYO (MISIONES)",
  "EL AGUILAR",
  "EL ALAMITO",
  "EL ALCAZAR (MISIONES)",
  "EL ALTO (CATAMARCA)",
  "EL AYBAL",
  "EL BAÑADO (CAPAYAN)",
  "EL BAÑADO (LA PAZ)",
  "EL BAÑADO (VALLE VIEJO)",
  "EL BOLSON (CATAMARCA)",
  "EL CAJON (CATAMARCA)",
  "EL CALAFATE",
  "EL CARMEN (JUJUY)",
  "EL CERRITO (CATAMARCA)",
  "EL CHAÑAR",
  "EL CONDOR (JUJUY)",
  "EL DESMONTE",
  "EL DIVISADERO (CATAMARCA)",
  "EL DURAZNO",
  "EL EJE (CATAMARCA)",
  "EL ESCORIAL (CHUBUT)",
  "EL ESPINILLO (CHACO)",
  "EL HOYO ",
  "EL HUECO",
  "EL LIBERTADOR",
  "EL LINDERO",
  "EL MAITEN",
  "EL MIRASOL",
  "EL MOLLAR",
  "EL PAJONAL",
  "EL PALOMAR ",
  "EL PANTANILLO (CATAMARCA)",
  "EL PARANACITO",
  "EL PELIGRO (LA PLATA)",
  "EL PEÑON (CATAMARCA)",
  "EL PINGO",
  "EL PORTEZUELO",
  "EL POTRERO (CATAMARCA)",
  "EL PUEBLITO (CATAMARCA)",
  "EL PUESTO (SANTA MARIA)",
  "EL PUESTO (TINOGASTA)",
  "EL QUIMILO",
  "EL ROSARIO (CATAMARCA)",
  "EL SALADO (CATAMARCA)",
  "EL SAUZAL (CHACO)",
  "EL SAUZALITO",
  "EL SOBERBIO",
  "EL TACURUZAL",
  "EL TALAR (JUJUY)",
  "EL TORO (JUJUY)",
  "EL TREBOL",
  "EL TURBIO VIEJO",
  "EL TURBIO",
  "ELDORADO",
  "EMBALSE (CORDOBA)",
  "EMPALME VILLA CONSTITUCION",
  "EMPEDRADO (CORRIENTES)",
  "ENRIQUE CARBO (LOCALIDAD)",
  "ENRIQUE URIEN",
  "ENSENADA",
  "EPUYEN",
  "ESPERANZA (SANTA FE)",
  "ESQUEL",
  "ESQUINA (CORRIENTES)",
  "ESQUIU",
  "ESTACION CAMPS",
  "ESTACION ESCRIÑA",
  "ESTACION GENERAL OBLIGADO",
  "ESTACION LAZO",
  "ESTANCIA GRANDE (ENTRE RIOS)",
  "EZEIZA",
  "FACHINAL (MISIONES)",
  "FACUNDO (CHUBUT)",
  "FAMAILLA",
  "FAMATANCA",
  "FARALLON NEGRO",
  "FAUSTINO M. PARERA",
  "FEDERACION ",
  "FEDERAL (ENTRE RIOS)",
  "FIAMBALA",
  "FIRMAT",
  "FITZ ROY (SANTA CRUZ)",
  "FLORENCIO VARELA",
  "FLORENTINO AMEGHINO (MISIONES)",
  "FLORENTINO AMEGHINO",
  "FLORIDA ",
  "FONTANA (CHACO)",
  "FORTIN BELGRANO",
  "FORTIN LAS CHUÑAS",
  "FORTIN LAVALLE",
  "FRAILE PINTADO",
  "FUENTES (SANTA FE)",
  "FUERTE ESPERANZA",
  "FUERTE QUEMADO",
  "FUNES (SANTA FE)",
  "GAIMAN",
  "GALVEZ (SANTA FE)",
  "GAN GAN",
  "GANCEDO",
  "GARAYALDE",
  "GARIN",
  "GARUHAPE",
  "GARUHAPE-MI",
  "GARUPA",
  "GASTRE",
  "GENERAL ALMADA",
  "GENERAL ALVEAR (MENDOZA)",
  "GENERAL ALVEAR (MISIONES)",
  "GENERAL ALVEAR",
  "GENERAL ARENALES",
  "GENERAL BALDISSERA",
  "GENERAL BELGRANO",
  "GENERAL CAMPOS",
  "GENERAL CAPDEVILA",
  "GENERAL CONESA",
  "GENERAL GALARZA",
  "GENERAL GUIDO",
  "GENERAL JOSE DE SAN MARTIN",
  "GENERAL JUAN MADARIAGA",
  "GENERAL LA MADRID",
  "GENERAL LAS HERAS",
  "GENERAL LAVALLE",
  "GENERAL PACHECO",
  "GENERAL PINEDO",
  "GENERAL PINTO",
  "GENERAL RAMIREZ",
  "GENERAL URQUIZA",
  "GENERAL VEDIA",
  "GENERAL VILLEGAS",
  "GERLI",
  "GILBERT ",
  "GLEW",
  "GOBERNADOR COSTA (CHUBUT)",
  "GOBERNADOR LOPEZ",
  "GOBERNADOR MANSILLA",
  "GOBERNADOR ROCA",
  "GOBERNADOR VIRASORO",
  "GODOY CRUZ",
  "GOMEZ DE LA VEGA",
  "GOMEZ",
  "GONZALEZ CATAN",
  "GOYA ",
  "GRANADERO BAIGORRIA (SANTA FE)",
  "GRAND BOURG",
  "GRANEROS (TUCUMAN)",
  "GREGORIO DE LAFERRERE",
  "GUALEGUAY",
  "GUALEGUAYCHU",
  "GUALJAINA",
  "GUAMINI",
  "GUARANI (MISIONES)",
  "GUAYAMBA",
  "GUERNICA ",
  "GUERRERO (JUJUY)",
  "HAEDO",
  "HASENKAMP",
  "HAUMONIA",
  "HENDERSON",
  "HERMOSO CAMPO",
  "HERNANDO (CORDOBA)",
  "HERRERA (ENTRE RIOS)",
  "HIPOLITO YRIGOYEN (MISIONES)",
  "HIPOLITO YRIGOYEN (SANTA CRUZ)",
  "HORQUILLA (CHACO)",
  "HUACALERA",
  "HUALFIN",
  "HUANCAR",
  "HUAYCAMA (AMBATO)",
  "HUAYCAMA (VALLE VIEJO)",
  "HUILLAPIMA",
  "HUMAHUACA",
  "IBARLUCEA",
  "INFANZON",
  "INGENIERO ADOLFO SOURDEAUX",
  "INGENIERO BARBET",
  "INGENIERO MASCHWITZ",
  "INGENIERO PABLO NOGUES",
  "INGENIO LA TRINIDAD (TUCUMAN)",
  "IRAZUSTA",
  "ISIDRO CASANOVA",
  "ISLA DEL CERRITO (LOCALIDAD)",
  "ITACARUARE",
  "ITATI ",
  "ITIN (CHACO)",
  "ITUZAINGO (CORRIENTES)",
  "ITUZAINGO",
  "JACIPUNCO",
  "JARAMILLO",
  "JARDIN AMERICA",
  "JEPPENER",
  "JESUS MARIA ",
  "JOSE C. PAZ",
  "JOSE DE SAN MARTIN (CHUBUT)",
  "JOSE INGENIEROS",
  "JOYANGO",
  "JUAN BAUTISTA ALBERDI (TUCUMAN)",
  "JUAN JOSE CASTELLI (CHACO)",
  "JUBILEO (ENTRE RIOS)",
  "JULIA DUFOUR",
  "JUNIN (MENDOZA)",
  "JUNIN",
  "KOLUEL KAYKE",
  "LA ALMONA",
  "LA BANDA",
  "LA CANDELARIA (CATAMARCA)",
  "LA CARLOTA ",
  "LA CARRERA (CATAMARCA)",
  "LA CHIQUITA",
  "LA CLARITA",
  "LA CLOTILDE (CHACO)",
  "LA COCHA",
  "LA CRUZ (CORRIENTES)",
  "LA CUMBRECITA",
  "LA DORADA ",
  "LA EDUVIGIS",
  "LA EMILIA",
  "LA ESCONDIDA ",
  "LA ESPERANZA (JUJUY)",
  "LA FALDA DE SAN ANTONIO",
  "LA FALDA",
  "LA GUARDIA (CATAMARCA)",
  "LA HIGUERA (CATAMARCA)",
  "LA HOYA ",
  "LA HOYADA",
  "LA INTERMEDIA",
  "LA LEONESA (CHACO)",
  "LA LIGURIA",
  "LA LOMA (CATAMARCA)",
  "LA LUCILA",
  "LA MAJADA (CATAMARCA)",
  "LA MENDIETA",
  "LA MERCED (CATAMARCA)",
  "LA PAZ (CORDOBA)",
  "LA PAZ (ENTRE RIOS)",
  "LA PAZ (MENDOZA)",
  "LA PUNTILLA (BELEN)",
  "LA PUNTILLA (SANTA MARIA)",
  "LA PUNTILLA (TINOGASTA)",
  "LA QUIACA",
  "LA RAMADITA",
  "LA SABANA",
  "LA TABLADA",
  "LA TERCENA",
  "LA VERDE (CHACO)",
  "LA VICUÑA",
  "LA VIÑA (CATAMARCA)",
  "LABOULAYE",
  "LAGO BLANCO ",
  "LAGO ESCONDIDO (LOCALIDAD)",
  "LAGO PUELO (LOCALIDAD)",
  "LAGO ROSARIO",
  "LAGUNA BLANCA (CHACO)",
  "LAGUNA LIMPIA",
  "LAGUNILLAS DEL FARALLON",
  "LAGUNITA SALADA",
  "LAMPACITO",
  "LANUS",
  "LAPACHITO",
  "LAPRIDA",
  "LARROQUE ",
  "LAS BREÑAS",
  "LAS CAÑAS (CATAMARCA)",
  "LAS CEJAS",
  "LAS CHACRITAS (CATAMARCA)",
  "LAS CUEVAS (ENTRE RIOS)",
  "LAS ESQUINAS",
  "LAS FLORES",
  "LAS GARCITAS (CHACO)",
  "LAS GAVIOTAS",
  "LAS HERAS (SANTA CRUZ)",
  "LAS JUNTAS (AMBATO)",
  "LAS JUNTAS (BELEN)",
  "LAS MOJARRAS",
  "LAS PALMAS (CHACO)",
  "LAS PALMITAS",
  "LAS PAREJAS",
  "LAS PLUMAS",
  "LAS ROSAS (SANTA FE)",
  "LAS TEJAS",
  "LAS TONINAS",
  "LAVALLE (CORRIENTES)",
  "LELEQUE",
  "LIBERTAD",
  "LIBERTADOR SAN MARTIN",
  "LIMA",
  "LINCOLN ",
  "LIVIARA",
  "LLAVALLOL",
  "LLULLUCHAYOC",
  "LOBERIA",
  "LOBOS",
  "LOMA HERMOSA",
  "LOMAS DE ZAMORA",
  "LOMAS DEL MIRADOR",
  "LONDRES ",
  "LONGCHAMPS",
  "LORETO (MISIONES)",
  "LORETO (SANTIAGO DEL ESTERO)",
  "LORO HUASI",
  "LOS ALTARES",
  "LOS ANGELES (CATAMARCA)",
  "LOS BALVERDI",
  "LOS CHARRUAS",
  "LOS CIPRESES",
  "LOS FRENTONES",
  "LOS HELECHOS",
  "LOS HORNOS",
  "LOS NACIMIENTOS (BELEN)",
  "LOS POLVORINES",
  "LOS QUIRQUINCHOS",
  "LOS RALOS (TUCUMAN)",
  "LOS TALAS (CATAMARCA)",
  "LOS TOLDOS",
  "LOS VARELA",
  "LOZANO (JUJUY)",
  "LUJAN DE CUYO",
  "LUJAN",
  "LULES",
  "LUNLUNTA",
  "MACHAGAI",
  "MACIA",
  "MAGDALENA ",
  "MAIMARA",
  "MAIPU (MENDOZA)",
  "MAIPU",
  "MAKALLE",
  "MALARGÜE",
  "MALBRAN",
  "MANANTIALES ",
  "MANUEL B. GONNET",
  "MAR AZUL",
  "MAR DE AJO",
  "MAR DEL PLATA",
  "MAR DEL TUYU",
  "MARCOS JUAREZ",
  "MARCOS PAZ",
  "MARGARITA BELEN",
  "MARIA GRANDE",
  "MARIANO ACOSTA",
  "MARTIN CORONADO",
  "MARTINEZ",
  "MARTIRES (MISIONES)",
  "MATHEU",
  "MAYOR BURATOVICH",
  "MBURUCUYA (CORRIENTES)",
  "MEDANITOS (SANTA MARIA)",
  "MEDANITOS (TINOGASTA)",
  "MEDANOS (ENTRE RIOS)",
  "MEDANOS",
  "MEDIA AGUA",
  "MELINCUE",
  "MERCEDES (CORRIENTES)",
  "MERCEDES",
  "MERLO",
  "MESON DE FIERRO",
  "MINA 3",
  "MINA PIRQUITAS",
  "MINA PROVIDENCIA",
  "MIRAFLORES (CATAMARCA)",
  "MIRAFLORES (CHACO)",
  "MIRAMAR (CORDOBA)",
  "MIRAMAR",
  "MISION NUEVA POMPEYA",
  "MOJON GRANDE",
  "MONTE BUEY",
  "MONTE CASEROS (CORRIENTES)",
  "MONTE CHINGOLO",
  "MONTE GRANDE",
  "MONTE HERMOSO",
  "MONTE POTRERO",
  "MONTE",
  "MONTECARLO (MISIONES)",
  "MONTEROS",
  "MONTERRICO",
  "MORENO",
  "MORON",
  "MORTEROS",
  "MUNRO",
  "MURPHY (SANTA FE)",
  "MUTQUIN",
  "MUÑIZ",
  "NAPALPI (LOCALIDAD)",
  "NAPENAY",
  "NAVARRO ",
  "NECOCHEA",
  "NOGOYA",
  "NORDELTA",
  "NUEVE DE JULIO (CORRIENTES)",
  "NUEVE DE JULIO (SAN JUAN)",
  "NUEVE DE JULIO",
  "OBERA",
  "OCLOYAS (JUJUY)",
  "OLAROZ CHICO",
  "OLAVARRIA",
  "OLEGARIO VICTOR ANDRADE (MISIONES)",
  "OLIDEN",
  "OLIVA (CORDOBA)",
  "OLIVOS",
  "ONCATIVO",
  "ONCE DE SEPTIEMBRE",
  "OPEN DOOR",
  "ORO VERDE ",
  "PABLO PODESTA",
  "PALO LABRADO",
  "PALO SECO",
  "PAMPA ALMIRON",
  "PAMPA ALTA",
  "PAMPA DEL INDIO",
  "PAMPA DEL INFIERNO",
  "PAMPA LANDRIEL",
  "PAMPA MANGRULLO",
  "PANAMBI",
  "PARAJE SAN FERNANDO (CHACO)",
  "PARANA ",
  "PARQUE SAN MARTIN",
  "PARTIDO DE BERISSO",
  "PASO DE INDIOS",
  "PASO DE LOS LIBRES",
  "PASO DEL REY",
  "PASO DEL SAPO",
  "PASTOR BRITOS",
  "PASTOS CHICOS",
  "PAVON (SANTA FE)",
  "PEHUAJO",
  "PELLEGRINI",
  "PEREZ ",
  "PERGAMINO",
  "PERICO (JUJUY)",
  "PICO TRUNCADO",
  "PIGÜE",
  "PILA",
  "PILAR",
  "PINAMAR",
  "PIÑEYRO",
  "PLAYA MAGAGNA",
  "POLCOS",
  "POMANCILLO ESTE",
  "POMANCILLO OESTE",
  "PONTEVEDRA",
  "POSADAS",
  "POZO DE LA PIEDRA",
  "POZO DEL MISTOL",
  "PRESIDENCIA DE LA PLAZA",
  "PRESIDENCIA ROCA",
  "PRESIDENCIA ROQUE SAENZ PEÑA",
  "PRIMERO DE MAYO (ENTRE RIOS)",
  "PROFUNDIDAD (MISIONES)",
  "PRONUNCIAMIENTO (ENTRE RIOS)",
  "PUAN",
  "PUEBLO BELLOCQ",
  "PUEBLO BRUGO",
  "PUEBLO CAZES",
  "PUEBLO CLODOMIRO DIAZ",
  "PUEBLO GENERAL BELGRANO",
  "PUEBLO LIEBIG",
  "PUEBLO URANGA",
  "PUERTA DE CORRAL QUEMADO",
  "PUERTA DE SAN JOSE",
  "PUERTO BANDERA",
  "PUERTO BASTIANI",
  "PUERTO BERMEJO",
  "PUERTO DE PUNTA QUILLA",
  "PUERTO DESEADO",
  "PUERTO ESPERANZA ",
  "PUERTO EVA PERON (CHACO)",
  "PUERTO GENERAL SAN MARTIN",
  "PUERTO IBICUY",
  "PUERTO LAVALLE",
  "PUERTO LEONI",
  "PUERTO LIBERTAD",
  "PUERTO MADRYN",
  "PUERTO PIRAY",
  "PUERTO RAWSON",
  "PUERTO RICO (MISIONES)",
  "PUERTO SAN JULIAN",
  "PUERTO SANTA CRUZ",
  "PUERTO TIROL",
  "PUERTO VICENTINI",
  "PUERTO VILELAS",
  "PUESTO DEL MARQUES",
  "PUESTO SEY",
  "PUESTO VIEJO",
  "PUMAHUASI",
  "PUNTA ALTA",
  "PUNTA DE BALASTO",
  "PUNTA DE VACAS",
  "PUNTA DEL AGUA (CATAMARCA)",
  "PUNTA DELGADA",
  "PUNTA LOYOLA",
  "PUNTAS DEL GUALEGUAYCHU",
  "PURMAMARCA",
  "QUILMES Y LOS SUELDOS",
  "QUILMES",
  "QUIMILI",
  "QUINTA EL MIRADOR",
  "QUIROS (CATAMARCA)",
  "QUITILIPI",
  "RADA TILLY",
  "RAFAEL CALZADA",
  "RAFAEL CASTILLO",
  "RAFAELA",
  "RAMALLO",
  "RAMBLONES",
  "RAMOS MEJIA",
  "RANCHILLOS Y SAN MIGUEL",
  "RANCHOS",
  "RANELAGH",
  "RAUCH",
  "RAWSON",
  "RECONQUISTA (SANTA FE)",
  "RECREO (CATAMARCA)",
  "REMEDIOS DE ESCALADA (LANUS)",
  "REMEDIOS DE ESCALADA (TRES DE FEBRERO)",
  "RINCON DEL CINTO",
  "RINCON DEL GATO",
  "RINCONADILLAS",
  "RIO CEBALLOS",
  "RIO GALLEGOS",
  "RIO GRANDE (TIERRA DEL FUEGO)",
  "RIO MUERTO",
  "RIO OLNIE",
  "RIO SEGUNDO (CIUDAD)",
  "RIVERA",
  "ROJAS ",
  "ROLDAN (SANTA FE)",
  "ROQUE PEREZ",
  "ROSARIO DEL TALA",
  "ROSPENTEK",
  "RUFINO ",
  "RUIZ DE MONTOYA",
  "SAENZ PEÑA",
  "SALADAS (CORRIENTES)",
  "SALADILLO",
  "SALLIQUELO",
  "SALSACATE",
  "SALTO ",
  "SAMBOROMBON",
  "SAMUHU (CHACO)",
  "SAN ANDRES DE GILES",
  "SAN ANTONIO (FRAY MAMERTO ESQUIU)",
  "SAN ANTONIO (LA PAZ)",
  "SAN ANTONIO (PACLIN)",
  "SAN ANTONIO DE ARECO",
  "SAN ANTONIO DE PADUA",
  "SAN BERNARDO (CHACO)",
  "SAN BERNARDO DEL TUYU",
  "SAN CARLOS CENTRO",
  "SAN CARLOS DE BOLIVAR",
  "SAN CARLOS MINAS",
  "SAN CAYETANO",
  "SAN CLEMENTE DEL TUYU",
  "SAN COSME (CORRIENTES)",
  "SAN CRISTOBAL (SANTA FE)",
  "SAN FERNANDO DEL VALLE DE CATAMARCA",
  "SAN FERNANDO",
  "SAN FRANCISCO (CORDOBA)",
  "SAN FRANCISCO DE ALFARCITO",
  "SAN FRANCISCO DE LA NUEVA PROVINCIA DE ALAVA",
  "SAN FRANCISCO DEL CHAÑAR",
  "SAN FRANCISCO SOLANO",
  "SAN ISIDRO (CATAMARCA)",
  "SAN ISIDRO",
  "SAN JAIME DE LA FRONTERA",
  "SAN JOSE (ENTRE RIOS)",
  "SAN JOSE (FRAY MAMERTO ESQUIU)",
  "SAN JOSE (SANTA MARIA)",
  "SAN JOSE BANDA",
  "SAN JOSE DE FELICIANO",
  "SAN JOSE DE JACHAL",
  "SAN JOSE NORTE",
  "SAN JOSE VILLA",
  "SAN JUAN DE QUILLAQUES",
  "SAN JUSTO",
  "SAN LORENZO (SANTA FE)",
  "SAN LUIS DEL PALMAR",
  "SAN MARTIN (CATAMARCA)",
  "SAN MARTIN (MENDOZA)",
  "SAN MARTIN (MISIONES)",
  "SAN MARTIN",
  "SAN MIGUEL (CATAMARCA)",
  "SAN MIGUEL (CORRIENTES)",
  "SAN MIGUEL (PARAGUAY)",
  "SAN MIGUEL",
  "SAN NICOLAS DE LOS ARROYOS",
  "SAN PABLO (CATAMARCA)",
  "SAN PEDRO (CAPAYAN)",
  "SAN PEDRO (MISIONES)",
  "SAN PEDRO DE COLALAO",
  "SAN PEDRO DE JUJUY",
  "SAN PEDRO",
  "SAN RAFAEL (MENDOZA)",
  "SAN SALVADOR (ENTRE RIOS)",
  "SAN SALVADOR DE JUJUY",
  "SAN VICENTE (MISIONES)",
  "SANTA ANA (ENTRE RIOS)",
  "SANTA ANA (RINCONADA)",
  "SANTA ANA (TUCUMAN)",
  "SANTA ANA (VALLE GRANDE)",
  "SANTA CATALINA (JUJUY)",
  "SANTA CLARA (JUJUY)",
  "SANTA CRUZ (CATAMARCA)",
  "SANTA ELENA (ENTRE RIOS)",
  "SANTA LUCIA (CORRIENTES)",
  "SANTA LUCIA (TUCUMAN)",
  "SANTA MARIA (CATAMARCA)",
  "SANTA MARIA (MISIONES)",
  "SANTA ROSA (TINOGASTA)",
  "SANTA ROSA (VALLE VIEJO)",
  "SANTA ROSA DE CALAMUCHITA",
  "SANTA ROSA DE CALCHINES",
  "SANTA ROSA DE LEALES",
  "SANTA ROSA DE RIO PRIMERO",
  "SANTA ROSA Y LOS ROJO",
  "SANTA SYLVINA",
  "SANTA TERESITA",
  "SANTIAGO DE LINIERS (MISIONES)",
  "SANTO PIPO",
  "SANTO TOME (CORRIENTES)",
  "SANTO TOME (SANTA FE)",
  "SANTOS LUGARES (TRES DE FEBRERO)",
  "SANTUARIO DE TRES POZOS",
  "SARANDI",
  "SARMIENTO (CHUBUT)",
  "SAUCE (CORRIENTES)",
  "SAUCE DE LUNA",
  "SAUCE VIEJO",
  "SAUJIL (TINOGASTA)",
  "SAUJIL",
  "SEGUI",
  "SELVAS DEL RIO DE ORO",
  "SERODINO",
  "SIERRA DE LA VENTANA",
  "SIJAN",
  "SIMOCA",
  "SINGUIL",
  "SUIPACHA",
  "SUMALAO",
  "SUNCHALES",
  "SUSQUES",
  "TACO POZO",
  "TAFI DEL VALLE",
  "TAFI VIEJO",
  "TANDIL",
  "TANTI",
  "TAPALQUE",
  "TAPIALES",
  "TARTAGAL (CHACO)",
  "TATON",
  "TECKA",
  "TELSEN",
  "TEMPERLEY",
  "TERMAS DE RIO HONDO",
  "TEZANOS PINTO",
  "TIGRE",
  "TILCARA",
  "TIMBUES (SANTA FE)",
  "TINOGASTA",
  "TOLHUIN",
  "TORNQUIST",
  "TORTUGUITAS",
  "TRELEW",
  "TRENQUE LAUQUEN",
  "TRES ALGARROBOS",
  "TRES ARROYOS",
  "TRES CAPONES",
  "TRES CRUCES ",
  "TRES ISLETAS (CHACO)",
  "TRES LAGOS",
  "TRES LOMAS",
  "TRES POZOS",
  "TRES SARGENTOS",
  "TREVELIN",
  "TRISTAN SUAREZ",
  "TUCUMANAO",
  "TUMBAYA",
  "TUNUYAN",
  "TURDERA",
  "TUSAQUILLAS",
  "UBAJAY (LOCALIDAD)",
  "UNQUILLO",
  "UQUIA",
  "URDINARRAIN",
  "USHUAIA",
  "USPALLATA",
  "VALENTIN ALSINA",
  "VALLE HERMOSO (CORDOBA)",
  "VEDIA",
  "VEINTE DE JUNIO",
  "VEINTICINCO DE MAYO",
  "VENADO TUERTO",
  "VENADOS GRANDES",
  "VERA (SANTA FE)",
  "VERONICA (PUNTA INDIO)",
  "VIALE",
  "VICENTE CASARES",
  "VICENTE LOPEZ",
  "VICTORIA (ENTRE RIOS)",
  "VICTORIA",
  "VICUÑA MACKENNA ",
  "VILISMAN",
  "VILLA ABERASTAIN",
  "VILLA ADELINA",
  "VILLA ALCARAZ",
  "VILLA ANGELA",
  "VILLA BALLESTER",
  "VILLA BERTHET",
  "VILLA BOSCH",
  "VILLA CARLOS PAZ",
  "VILLA CAÑAS",
  "VILLA CENTENARIO",
  "VILLA CLARA ",
  "VILLA CONSTITUCION",
  "VILLA CURA BROCHERO",
  "VILLA DE BALCOZNA",
  "VILLA DE LEALES",
  "VILLA DE MARIA",
  "VILLA DE MAYO",
  "VILLA DE POMAN",
  "VILLA DEL ROSARIO (CORDOBA)",
  "VILLA DEL ROSARIO (ENTRE RIOS)",
  "VILLA DEL TOTORAL",
  "VILLA DIQUE FLORENTINO AMEGHINO",
  "VILLA DOLORES (CATAMARCA)",
  "VILLA DOLORES (CORDOBA)",
  "VILLA DOMINGUEZ",
  "VILLA DOMINICO",
  "VILLA EDUARDO MADERO",
  "VILLA ELISA (ENTRE RIOS)",
  "VILLA FIORITO",
  "VILLA FONTANA (ENTRE RIOS)",
  "VILLA FUTALAUFQUEN",
  "VILLA GENERAL BELGRANO",
  "VILLA GESELL",
  "VILLA GIARDINO",
  "VILLA GOBERNADOR GALVEZ",
  "VILLA HERNANDARIAS",
  "VILLA HUIDOBRO",
  "VILLA JALON",
  "VILLA KRAUSE",
  "VILLA LA FLORIDA",
  "VILLA LAS PIRQUITAS",
  "VILLA MAIPU",
  "VILLA MANTERO",
  "VILLA MARIA",
  "VILLA MARTELLI",
  "VILLA NOUGUES",
  "VILLA NUEVA (MENDOZA)",
  "VILLA PALPALA",
  "VILLA PARANACITO",
  "VILLA RAFFO",
  "VILLA RIO BERMEJITO",
  "VILLA RURAL EL PALMAR",
  "VILLA SALTO ENCANTADO",
  "VILLA SAN CARLOS (MENDOZA)",
  "VILLA SAN MARCIAL",
  "VILLA SARMIENTO",
  "VILLA TESEI",
  "VILLA TULUMAYA",
  "VILLA TULUMBA",
  "VILLA UDAONDO",
  "VILLA URQUIZA (ENTRE RIOS)",
  "VILLA VIL",
  "VILLAGUAY",
  "VIRREYES",
  "VOLCAN (JUJUY)",
  "WALTER MOSS",
  "WANDA",
  "WICHI - EL PINTADO",
  "WILDE",
  "WILLIAM C. MORRIS",
  "XX DE SETIEMBRE",
  "YABEBYRY",
  "YALA LAUBAT",
  "YAPES",
  "YAPEYU",
  "YAVI CHICO",
  "YAVI",
  "YERBA BUENA (TUCUMAN)",
  "ZAPARINQUI",
  "ZARATE",
  "ZAVALLA",
  "ZUBERBÜHLER",
];
