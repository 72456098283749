import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  Marker,
  Polyline,
  TrafficLayer,
  Autocomplete,
} from "@react-google-maps/api";
import CarIcon from "assets/car-icon.png";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  FbGeoPoint,
  FbDocument,
  TransferStage,
  ScheduledTransfer,
} from "types/transfer";
import { GpsStatus } from "types/user-data";
import { Title } from ".";
import { usePaginatorLive } from "util/hooks";
import { FIRESTORE } from "constants/firestore";
import { Spinner } from "components/styles";

enum TravelMode {
  BICYCLING = "BICYCLING",
  DRIVING = "DRIVING",
  TRANSIT = "TRANSIT",
  TWO_WHEELER = "TWO_WHEELER",
  WALKING = "WALKING",
}

export const MapDriver = ({
  transfer,
  uid,
  ...props
}: {
  transfer: ScheduledTransfer;
  uid: string;
  origin: FbGeoPoint;
  destination: FbGeoPoint;
  backtrack?: FbGeoPoint;
}) => {
  // const startHistory = transfer.status.history.stages.filter(
  //   (v) => v.stage === TransferStage.going_to_meeting_point
  // );
  // const startTime =
  //   startHistory.length !== 1
  //     ? transfer.origin.time.toDate()
  //     : startHistory[0].time.toDate();
  // const endHistory = transfer.status.history.stages.filter(
  //   (v) => v.stage === TransferStage.finished
  // );
  // const endTime =
  //   endHistory.length !== 1 ? new Date() : endHistory[0].time.toDate();
  // const [positions, loading] = usePaginatorLive(
  //   FIRESTORE.COLLECTION_USER_GPS(uid)
  //     .where("date", ">=", startTime)
  //     .where("date", "<=", endTime),
  //   300
  // );

  //if (loading) return <Spinner />;

  return <MainContainer {...props} positions={transfer.polylines} uid={uid} />;
};

export const MainContainer = ({
  origin,
  destination,
  positions,
  uid,
}: {
  origin: FbGeoPoint;
  destination: FbGeoPoint;
  uid: string;
  backtrack?: FbGeoPoint;
  positions?: FbGeoPoint[];
}) => {
  const [driverPosition, setDriverPosition] = useState<FbGeoPoint | undefined>(
    undefined
  );
  FIRESTORE.DOCUMENT_USER(uid)
    .get()
    .then((ref) => ref.data()?.gps)
    .then((gps) => {
      setDriverPosition(gps?.geopoint);
    });
  //   const [directionResponse, setDirectionResponse] = useState<any>(undefined);
  //   const [mapaLeido, setMapaLeido] = useState<boolean>(false);
  //   const directionsCallback = (response: any) => {
  //     if (response !== null) {
  //       if (response.status === "OK") {
  //         console.log(response, "response");
  //         if (mapaLeido === false) {
  //           setMapaLeido(true);
  //           setDirectionResponse(response);
  //         }
  //       }
  //     }
  //   };

  return (
    <Row>
      <Col>
        <Row>
          <Title>Ruta calculada</Title>
        </Row>
        <GoogleMap
          mapContainerStyle={{ height: "400px", width: "100%" }}
          onLoad={(map) => {
            if (map !== undefined) {
              const bounds = new google.maps.LatLngBounds();
              bounds.extend(fromGeoPointToLatLng(origin));
              bounds.extend(fromGeoPointToLatLng(destination));
              map.fitBounds(bounds, 100);
              //map.panToBounds(bounds, 100)
            }
          }}
        >
          {positions !== undefined && (
            <Polyline
              options={{
                clickable: false,
                strokeColor: "black",
                strokeOpacity: 0.8,
                strokeWeight: 4,
              }}
              path={positions.map((p) => ({
                lat: p.latitude,
                lng: p.longitude,
              }))}
            />
          )}

          {driverPosition !== undefined && (
            <Marker
              icon={{ url: CarIcon }}
              label={{
                fontWeight: "bold",
                fontSize: "16px",
                text: "Conductor",
              }}
              position={{
                lat: driverPosition.latitude,
                lng: driverPosition.longitude,
              }}
            />
          )}

          <Marker
            label={{
              fontWeight: "bold",
              fontSize: "16px",
              text: "Origen",
            }}
            position={{ lat: origin.latitude, lng: origin.longitude }}
          />
          <Marker
            label={{
              fontWeight: "bold",
              fontSize: "16px",
              text: "Destino",
            }}
            position={{ lat: destination.latitude, lng: destination.longitude }}
          />
          {/*<TrafficLayer />
           <DirectionsService
            options={{
              travelMode: TravelMode.DRIVING,
              destination: {
                lat: destination.latitude,
                lng: destination.longitude,
              },
              origin: { lat: origin.latitude, lng: origin.longitude },
            }}
            callback={directionsCallback}
          />
          {directionResponse && (
            <DirectionsRenderer
              options={{
                directions: directionResponse,
                suppressMarkers: true,
                preserveViewport: true,
              }}
            />
          )} */}
        </GoogleMap>
      </Col>
    </Row>
  );
};

const fromGeoPointToLatLng = (p: FbGeoPoint) => ({
  lat: p.latitude,
  lng: p.longitude,
});
