import { LoadScript } from "@react-google-maps/api";
import { Tabs } from "antd";
import { H2, Spinner } from "components/styles";
import { useDatabaseDocref } from "controllers/hooks";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { useHistory, useParams } from "react-router";
import { InfoRow, Title } from "../../Transfers/view/scheduled";

import { FIRESTORE } from "constants/firestore";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { PassengerData } from "components/Admin/Transfers/view/unassigned";
import { ChangeLocationAndCreateModuleDestination } from "./change_location";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { removeUndefined } from "util/meta";
import { Field, SubmissionError } from "redux-form";
import { EditModuleTransfer } from "components/Admin/Transfers/view/module/modals/edit";
import { QuizParams } from "types/module-transfer";

const libraries: Libraries = ["places", "drawing"];
const AdminModuleFixDestination = () => {
  const { idModule } = useParams<QuizParams>();
  const history = useHistory();
  const [newDestination, setNewDestination]: any = useState();
  const [observaciones, setObservaciones] = useState();
  //const [destinationFixed, setDestinationFixed] = useState()
  const [changeLocationDestinationModal, setChangeLocationDestinationModal] =
    useState(false);
  const handleChange = (e: any) => {
    setObservaciones(e.target.value);
    console.log(observaciones);
  };
  const module = useDatabaseDocref(
    FIRESTORE.DOCUMENT_MODULE_TRANSFER(idModule)
  );
  if (!isLoaded(module)) return <Spinner />;

  /* const remake = async () => {
    const data = {
      module: module,
      new_origin: origin,
      new_destination: destination,
      id: idModule,
    };
    console.log(data);
    await CALLABLE_FUNCTIONS.ADMIN_WRONG_MODULE_REMAKE(data);
    console.log("se recreo el traslado");
  }; */
  const fixDestination = async () => {
    //setDestinationFixed(destina)
    ///console.log(newDestination!.address.address, "<---ND");
    const data = {
      destination: {
        address: newDestination!.address.address,
      },
      obs: observaciones,
      authorized: true,
      fix: true,
    };
    removeUndefined(data);
    try {
      console.log(data.destination!.address!, "<---data.desatination.address");
      return CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_EDIT(idModule, data);
    } catch (e) {
      console.log(e);
      throw new SubmissionError({ _error: e.message });
    }
  };

  return (
    <div>
      <LoadScript //Script que carga la librería de google maps para el mapa
        id="script-loader"
        googleMapsApiKey="AIzaSyD9_LJRGAjiACJ0njksDgoK5m85obW02ng"
        language="es"
        libraries={libraries}
      >
        <ChangeLocationAndCreateModuleDestination
          saver={setNewDestination}
          visible={changeLocationDestinationModal}
          handleClose={() => setChangeLocationDestinationModal(false)}
        />
        <Row style={{ margin: "20px 0 5px 0" }}>
          <Col>
            <div className="pull-left">
              <H2>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  onClick={() => history.goBack()}
                />
              </H2>
            </div>
          </Col>
        </Row>
        <Row>
          <span style={{ margin: "auto", fontSize: "1.5em" }}>
            Siniestro {module!.art.accident} <br />
            Cod. Aut. {module!.art.authorization} <br />
          </span>
        </Row>
        <Row>
          <Col>
            <PassengerData passenger={module.passenger} />
          </Col>
        </Row>
        <Row>
          <Title>Datos del traslado</Title>
        </Row>
        <InfoRow label="Siniestro" info={module.art.accident} />
        <InfoRow label="AUT." info={module.art.authorization} />
        <InfoRow label="ART" info={module.art?.name} />
        <InfoRow
          label="Dirección de destino orginal"
          info={
            module.destination
              ? module.destination.address
                ? module.destination.address.formatted_address
                : "dirección a corregir"
              : "dirección a corregir"
          }
        />
        <InfoRow
          label="Nuevo Destino"
          info={
            newDestination
              ? newDestination.address.address
              : "POR FAVOR INTRODUZCA UN NUEVO DESTINO"
          }
        />
        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Text>Observaciones</InputGroup.Text>
              <FormControl
                onChange={handleChange}
                as="textarea"
                aria-label="With textarea"
              />
            </InputGroup>
          </Col>
        </Row>
        <br />
        <br />
        <Button
          className="pull-right"
          style={{ margin: "0 10px 5px" }}
          onClick={() => setChangeLocationDestinationModal(true)}
        >
          Corregir <b>DESTINO</b>
        </Button>
        <Button
          disabled={newDestination ? false : true}
          variant="success"
          className="pull-right"
          style={{ margin: "0 10px 5px" }}
          onClick={() => fixDestination()}
        >
          Confirmar Cambio
        </Button>
      </LoadScript>
    </div>
  );
};

export default AdminModuleFixDestination;
