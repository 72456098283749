import { connect as reduxConnect } from 'react-redux'
import { Uid } from 'controllers/auth'
import { Record } from 'immutable'
import { Maybe } from 'util/Maybe'

export const namespace = 'session'

export interface SessionStateProps {
    uid : Maybe<string>
}



interface StateProps {
    session : Record<SessionStateProps>
}

const defaultState  = {
    uid: Maybe.nothing()
}

export const reducer = (state : Record<SessionStateProps> = Record(defaultState as SessionStateProps)(), 
    action : 
        {
            type: 'SIGN_IN', 
            uid: string
        }
        | {
            type: 'SIGN_OUT'
        }
    ) => { 
    return (
        action.type === 'SIGN_IN' ? 
            state.set('uid', Maybe.just(action.uid))
        : action.type === 'SIGN_OUT' ?
            state.remove('uid')
        : state
    )}

const mapStateToProps = (state : StateProps, ownProps : any) => {
    return {...ownProps, 
        uid: state[namespace].get('uid')}
}
  
const mapDispatchToProps = (dispatch : any, ownProps: any) => { 
    return {
        ...ownProps,
        session: {
            onSignIn: (uid : Uid) => {dispatch({type: 'SIGN_IN', uid})},
            onSignOut: () => {dispatch({type: 'SIGN_OUT'})}
        }
    }
}

  export interface SessionInjectedProps {
    uid : Maybe<string>,
    session : {
        onSignIn : (uid : Uid) => void,
        onSignOut : () => void
    }
}
  
  export const connect = reduxConnect(mapStateToProps, mapDispatchToProps);