import { Button, Spinner } from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { useAsyncFunction } from "controllers/hooks";
import React from "react";
import { Modal } from "react-bootstrap";
import { FIRESTORE } from "constants/firestore";
import { FinishStatus, FbDate } from "types/transfer";
import { BillStatus } from "types/billing";
import firebase from "firebase/app";

export const BillModal = ({
  show,
  idTransfer,
  uidDriver,
  handleClose,
}: {
  idTransfer: string;
  uidDriver: string;
  show: boolean;
  handleClose: any;
}) => {
  const [func, loading, error] = useAsyncFunction(
    () =>
      CALLABLE_FUNCTIONS.ADMIN_FORCE_MULTIPLE_DRIVER_BILLS({
        transfers: [{ idTransfer, uidDriver }],
        allInOneBill: false,
        forced: true,
      }),
    undefined,
    handleClose
  );

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Facturar Compra</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner />
        ) : error ? (
          error
        ) : (
          <h6>
            ¿Está seguro que desea facturar el traslado? La facturación de un
            traslado sucede cada 24hs. Este método fuerza a facturarse ahora. El
            traslado solo se facturará si no se facturó exitosamente antes y si
            todos los peajes están corregidos.
          </h6>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={func}>
          Facturar
        </Button>
        <Button
          variant="secondary"
          style={{ textAlign: "center", width: "4rem" }}
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const BillModalRevision = ({
  show,
  idTransfer,
  uidDriver,
  handleClose,
}: {
  idTransfer: string;
  uidDriver: string;
  show: boolean;
  handleClose: any;
}) => {
  const [func, loading, error] = useAsyncFunction(
    () =>
      CALLABLE_FUNCTIONS.ADMIN_FORCE_MULTIPLE_DRIVER_BILLS({
        transfers: [{ idTransfer, uidDriver }],
        allInOneBill: false,
        forced: true,
      }),
    undefined,
    handleClose
  );

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Revisar Facturación Compra</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner />
        ) : error ? (
          error
        ) : (
          <h6>Este traslado se encontró alejado del punto de origen/destino</h6>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={func}>
          Facturar
        </Button>
        <Button
          variant="danger"
          onClick={async () => {
            await FIRESTORE.DOCUMENT_SCHEDULED_TRANSFER(
              uidDriver,
              idTransfer
            ).update({
              "sale.billed_from_driver": BillStatus["No facturar"],
              "status.finished_status": FinishStatus.Cancelado,
              "status.history.stages": firebase.firestore.FieldValue.arrayUnion(
                {
                  stage: "CANCELADO POR ADMIN",
                  time: FbDate.fromDate(new Date()),
                }
              ),
            });
            handleClose();
          }}
        >
          NO FACTURAR
        </Button>
        <Button
          variant="secondary"
          style={{ textAlign: "center", width: "4rem" }}
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const CancelBillModal = ({
  show,
  idTransfer,
  uidDriver,
  handleClose,
}: {
  idTransfer: string;
  uidDriver: string;
  show: boolean;
  handleClose: any;
}) => {
  const [func, loading, error] = useAsyncFunction(
    () =>
      FIRESTORE.DOCUMENT_SCHEDULED_TRANSFER(uidDriver, idTransfer).update({
        "sale.billed_from_driver": BillStatus["No facturar"],
        "status.finished_status": FinishStatus.Cancelado,
        challenge: firebase.firestore.FieldValue.delete(),
      }),
    undefined,
    handleClose
  );

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Facturar Compra</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner />
        ) : error ? (
          error
        ) : (
          <h6>¿Está seguro que desea CANCELAR la facturación del traslado?</h6>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={async () => {
            await func();
            await CALLABLE_FUNCTIONS.SEND_DRIVER_CANCELATION_MAIL({
              idTransfer,
              uidDriver,
            });
          }}
        >
          NO Facturar
        </Button>
        <Button
          variant="secondary"
          style={{ textAlign: "center", width: "4rem" }}
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
