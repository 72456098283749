import { Link, Padding, Spinner } from "components/styles";
import ROUTES from "constants/routes";
import { WithId } from "controllers/database";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { getZonesConfig } from "../Global/reducer";
import { TableConfig } from "../Localities";
import { ZoneConfig } from "types/config";

const Zones = () => {
  const [items, setItems] = useState([] as (ZoneConfig & WithId)[]);
  const [loading, setLoading] = useState(true);

  const updateData = () => {
    setLoading(true);
    getZonesConfig()
      .then(setItems)
      .then(() => setLoading(false));
  };

  useEffect(() => {
    updateData();
  }, []);

  if (loading) return <Spinner />;
  return (
    <Padding pad="10px">
      <Row style={{ marginBottom: 10 }}>
        <Col md={{ span: 2, offset: 10 }}>
          <Link to={ROUTES.ADMIN_CONFIGURATION_NEW_ZONE}>
            <Button variant="dark" block>
              Nueva Zona
            </Button>
          </Link>
        </Col>
      </Row>
      <TableConfig items={items} link={ROUTES.ADMIN_CONFIGURATION_VIEW_ZONE} />
    </Padding>
  );
};

export default Zones;
