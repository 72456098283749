import React from "react";
import { Navbar, Nav, Form, Button } from "react-bootstrap";
import ROUTES from "constants/routes";
import { NavLink, Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Container } from "components/styles";
import { pxToPt } from "constants/css";
import LogoHorizontalImg from "assets/logo-horizontal.png";
//import LogoHorizontalImg from "assets/image.png";
class Navigation extends React.Component {
  render() {
    return (
      <Container>
        <Navbar bg="light" expand="lg" className="mainMenu">
          <Navbar.Brand href="#home">
            <img
              src={LogoHorizontalImg}
              height="40"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="mr-auto"></Nav>
            <Nav style={{ marginRight: 8 }}>
              {/* <NavLinks
                to={ROUTES.ADMIN_HOME_PROFILES}
                activeStyle={{ color: "#419EFF", borderColor: "#419EFF" }}
              >
                INICIO
              </NavLinks> */}
              <NavLinks
                to={ROUTES.ADMIN_MODULES_TRANSFERS}
                activeStyle={{ color: "#419EFF", borderColor: "#419EFF" }}
              >
                TRASLADOS
              </NavLinks>
              <NavLinks
                to={ROUTES.ADMIN_MODULES_TRANSFERS_POOL}
                activeStyle={{ color: "#419EFF", borderColor: "#419EFF" }}
              >
                TRASLADOS COMBINABLES
              </NavLinks>
              <NavLinks
                to={ROUTES.ADMIN_MODULES_TRANSFERS_CONDUCTOR}
                activeStyle={{ color: "#419EFF", borderColor: "#419EFF" }}
              >
                POR CONDUCTOR
              </NavLinks>
              <NavLinks
                to={ROUTES.ADMIN_MODULES_TRANSFERS_WRONG}
                activeStyle={{ color: "#419EFF", borderColor: "#419EFF" }}
              >
                REVISIÓN
              </NavLinks>
              <NavLinks
                to={ROUTES.ADMIN_TOLLS}
                activeStyle={{ color: "#419EFF", borderColor: "#419EFF" }}
              >
                PEAJES
              </NavLinks>
              <NavLinks
                to={ROUTES.ADMIN_CROSS}
                activeStyle={{ color: "#419EFF", borderColor: "#419EFF" }}
              >
                CRUCES/LOCALIDADES
              </NavLinks>
              <NavLinks
                to={ROUTES.ADMIN_HOME_PROFILES}
                activeStyle={{ color: "#419EFF", borderColor: "#419EFF" }}
              >
                PERFILES
              </NavLinks>
              {/* <NavLinks
                to={ROUTES.ADMIN_HOME_CARS}
                activeStyle={{ color: "#419EFF", borderColor: "#419EFF" }}
              >
                REVISION PERFILES/AUTOS
              </NavLinks> */}
              <NavLinks
                to={ROUTES.ADMIN_SALES}
                activeStyle={{ color: "#419EFF", borderColor: "#419EFF" }}
              >
                ADMIN
              </NavLinks>
              <NavLinks
                to={ROUTES.ADMIN_CONFIGURATION}
                activeStyle={{ color: "#419EFF", borderColor: "#419EFF" }}
              >
                CONFIGURACION
              </NavLinks>
            </Nav>
            <Form inline>
              <Link to={ROUTES.SIGN_OUT}>
                <Button variant="dark">Salir</Button>
              </Link>
            </Form>
          </Navbar.Collapse>
        </Navbar>
        <hr style={{ marginTop: 0 }} />

        {this.props.children}
      </Container>
    );
  }
}

const NavLinks = styled(NavLink)`
  padding: ${pxToPt(10)} ${pxToPt(15)};

  color: #a4a4a5;
  background-color: white;
  font-size: ${pxToPt(11.4)} !important;
  font-weight: bold;
  border: ${pxToPt(1.3)} solid #d2d3d4;
  border-radius: ${pxToPt(5)};
  margin: ${pxToPt(0)} ${pxToPt(3)};
  padding: ${pxToPt(6)};
  text-decoration: none;
  &:hover {
    color: #419eff;
    background-color: white;
    border: ${pxToPt(1.3)} solid #419eff;
    border-radius: ${pxToPt(5)};
    text-decoration: none;
  }
`;

export default Navigation;
