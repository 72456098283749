import * as R from "ramda";
import { useSelector } from "react-redux";
import { useFirestore, useFirestoreConnect, isLoaded, ReduxFirestoreQuerySetting, OrderByOptions, WhereOptions } from "react-redux-firebase";
import { useState, useEffect } from "react";
import { WithId } from "./database";

export type fbCollection<T> = { [key: string]: T };

export const useDatabaseDocref = <T extends Object> (ref? : firebase.firestore.DocumentReference<T>,
    storeAs: string|undefined = ref?.path) => {
    return useDbDocument<T>(ref?.parent.path, ref?.id)
}

export const useDbDocument = <T extends Object> (collection? : string, doc?: string) : T|undefined|null => {

    const {queryConfig, querySelector} = setupFirestoreQueryDocument<T>(collection, doc)
    useFirestoreConnect(queryConfig.collection ? queryConfig : [])
    const firestore = useFirestore()
    const setter = (data : Partial<T>) => firestore.update(queryConfig, data)
    return useSelector(querySelector)
}

export const useDbCollection = <T extends Object> (collection?: string, where?: WhereOptions[], orderBy?:OrderByOptions[], limit?:number) :
    (WithId & T)[] | undefined => {

    const {queryConfig, querySelector } = collectionToArray(setupFirestoreQueryCollection<T>(collection, where, orderBy, limit))
    useFirestoreConnect(queryConfig.collection ? queryConfig : [])
    const data = useSelector(querySelector)
    return data
}

export const useDbCollectionGroup = <T extends Object> (collection?: string, where?: WhereOptions[], orderBy?:OrderByOptions[], limit?:number) :
    (WithId & T)[] | undefined => {

    const {queryConfig, querySelector } = collectionToArray(setupFirestoreQueryCollectionGroup<T>(collection, where, orderBy, limit))
    useFirestoreConnect(queryConfig.collectionGroup ? queryConfig : [])
    const data = useSelector(querySelector)
    return data
}

export const setupFirestoreQueryDocument = <T extends Object>(collection?: string, doc?: string,) => ({
    queryConfig: {
        collection: collection,
        doc: doc,
        storeAs: `${collection}/${doc}`
    },
    querySelector: collection ? (store: any) => store.firestore.data[`${collection}/${doc}`] as T 
                              : (store: any) => undefined
});

export const setupFirestoreQueryCollection = <T extends Object>(
    path?: string, where?: WhereOptions[], orderBy?:OrderByOptions[], limit?:number) => (   
    {
        queryConfig: {
            collection: path,
            storeAs: path,
            where,
            limit,
            orderBy
        } as ReduxFirestoreQuerySetting,
        querySelector: path ? (store: any) => store.firestore.data[path] as fbCollection<T> : (store:any) => ({}) as fbCollection<T>
    });

export const setupFirestoreQueryCollectionGroup = <T extends Object>(
    path?: string, where?: WhereOptions[], orderBy?:OrderByOptions[], limit?:number) => (   
    {
        queryConfig: {
            collectionGroup: path,
            storeAs: path,
            where,
            limit,
            orderBy
        } as ReduxFirestoreQuerySetting,
        querySelector: path ? (store: any) => store.firestore.data[path] as fbCollection<T> : (store:any) => ({}) as fbCollection<T>
    });

export const collectionToArray = <T, S>(query: { queryConfig: S, querySelector: (store: any) => fbCollection<T> }, withUid?:boolean) : 
    { queryConfig: S; querySelector: (store: any) => (WithId & T)[] } => ({

    queryConfig: query.queryConfig,
    querySelector: R.compose(
        (fbArray: fbCollection<T>) =>
            fbArray && Object.keys(fbArray).map(docid => fbArray[docid] ? //Preguntar a franco
                ({
                    ...(fbArray[docid]),
                    id: docid
                }) as (WithId & T) : undefined).filter(s => s !== undefined) as (WithId & T)[], //Preguntar a franco
        query.querySelector
    )
});

export const useAsyncFunction = <T = void>(func : (c:T) => Promise<any>, condition?:boolean, successCallback?:() => void) 
: [(c:T)=>Promise<any>, boolean, string|undefined] => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string|undefined>(undefined)
    const call = async (c:T) => {
        if(condition === undefined || condition === true){
            setLoading(true)
            await func(c).then( () => {
                if(successCallback) successCallback()
            }).catch( (e:firebase.functions.HttpsError) => setError(e.message))
            .then( () => setLoading(false) )
        }
    }
    return [call, loading, error]
}

export const useAsyncValue = <T = void>(func : () => Promise<T>) : [T|undefined, boolean, string|undefined] => {

    const [loading, setLoading] = useState(true)
    const [value, setValue] = useState<T|undefined>(undefined)
    const [error, setError] = useState<string|undefined>(undefined)

    useEffect(() => {
        func().then(setValue).catch(setError).then(() => setLoading(false))
    }, [])
   
    return [value, loading, error]
}