import { Field } from "components/input/field";
import {
  NameField,
  PhoneField,
  SurnameField,
} from "components/input/sign-up/fields";
import { Required } from "components/styles";
import { fileRequired, olderThan, required } from "controllers/validations";
import { reduce } from "lodash";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { CivilStatus, Genre, IdType } from "types/user-data";

const olderThan18 = olderThan("Tienes que ser mayor de 18", 18);

const PersonalData = () => (
  <>
    <Row>
      <Col xl={6}>
        <Form.Group>
          <Form.Label>
            <Required />
            Nombre
          </Form.Label>
          <NameField placeholder="Nombre" />
        </Form.Group>
      </Col>
      <Col xl={6}>
        <Form.Group>
          <Form.Label>
            <Required />
            Apellido
          </Form.Label>
          <SurnameField placeholder="Apellido" />
        </Form.Group>
      </Col>
      <Col xl={6}>
        <Form.Group>
          <Form.Label>
            <Required />
            Usuario/Email
          </Form.Label>
          <Field
            name="email"
            type="text"
            placeholder={"email"}
            validate={required}
          />
        </Form.Group>
      </Col>
      <Col md={3}>
        <Form.Group>
          <Form.Label>
            <Required />
            Tipo Documento
          </Form.Label>
          <Field
            name="idType"
            type="select"
            validate={required}
            options={IdType}
          />
        </Form.Group>
      </Col>
      <Col md={3}>
        <Form.Group>
          <Form.Label>
            <Required />
            Número Documento
          </Form.Label>
          <Field
            name="id"
            type="number"
            placeholder="Documento"
            validate={required}
          />
        </Form.Group>
      </Col>
      <Col md={3}>
        <Field
          labelinput={
            <>
              <Required />
              Fecha Nacimiento
            </>
          }
          style={{ display: "block" }}
          name="birthday"
          type="date-picker"
          validate={[required, olderThan18]}
        />
      </Col>
      <Col md={3}>
        <Form.Group>
          <Form.Label>
            <Required />
            Nacionalidad
          </Form.Label>
          <Field
            name="country"
            type="text"
            placeholder="Nacionalidad"
            validate={required}
          />
        </Form.Group>
      </Col>

      <Col md={6}>
        <Form.Group>
          <Form.Label>
            <Required />
            Domicilio Dirección completa{" "}
            <b>((Tiene que seleccionar una opción))</b>
          </Form.Label>
          <Field
            name="address"
            type="address"
            placeholder="Escribí la dirección"
            validate={required}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>Datos Adicionales</Form.Label>
          <Field
            name="address2"
            type="text"
            placeholder="Piso, departamento, otra información"
          />
        </Form.Group>
      </Col>
    </Row>
    <Row></Row>
    <Row>
      <Col xl={3}>
        <Form.Group>
          <Form.Label>
            <Required />
            Número Teléfono
          </Form.Label>
          <PhoneField placeholder="Número" validate={required} />
        </Form.Group>
      </Col>
      <Col xl={3}>
        <Form.Group>
          <Form.Label>
            <Required />
            Estado Civil
          </Form.Label>
          <Field
            name="civil_status"
            type="select"
            validate={required}
            options={CivilStatus}
          />
        </Form.Group>
      </Col>
      <Col xl={3}>
        <Form.Group>
          <Form.Label>
            <Required />
            Ocupación
          </Form.Label>
          <Field
            name="job"
            type="text"
            placeholder="Ocupación"
            validate={required}
          />
        </Form.Group>
      </Col>
      <Col xl={3}>
        <Form.Group>
          <Form.Label>
            <Required />
            Género
          </Form.Label>
          <Field
            name="genre"
            type="select"
            validate={required}
            options={Genre}
          />
        </Form.Group>
      </Col>
    </Row>
    <h2>Antecedentes Penales (Opcional)</h2>

    <Field type="file" name="criminal_records" />
  </>
);

export default PersonalData;
