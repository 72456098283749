import { Timer } from "components/Art/Reports";
import { Spinner } from "components/styles";
import { getStartedTransfer } from "components/User/Home/reducer";
import { getPositionUser } from "controllers/user";
import { getPersonalInformation } from "controllers/user/info/personal";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { geolocated, GeolocatedProps } from "react-geolocated";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { useParams } from "react-router";
import styled from "styled-components";
import { QuizParams } from "types/module-transfer";
import { ScheduledTransfer } from "types/transfer";
import { GpsStatusUser, PersonalInformation } from "types/user-data";
import { addMinutes } from "util/date";
import "./index.css";
//const libraries = ['drawing']
//https://medium.com/@hilalalhakani/3d-marker-in-google-maps-2c122f444995

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
/*enum ControlPosition {
    BOTTOM_CENTER = 11,
    BOTTOM_LEFT = 10,
    BOTTOM_RIGHT = 12,
    LEFT_BOTTOM = 6,
    LEFT_CENTER = 4,
    LEFT_TOP = 5,
    RIGHT_BOTTOM = 9,
    RIGHT_CENTER = 8,
    RIGHT_TOP = 7,
    TOP_CENTER = 2,
    TOP_LEFT = 1,
    TOP_RIGHT = 3,
}*/
/*enum TravelMode {
    BICYCLING = 'BICYCLING',
    DRIVING = 'DRIVING',
    TRANSIT = 'TRANSIT',
    TWO_WHEELER = 'TWO_WHEELER',
    WALKING = 'WALKING',
}*/
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Microlanding = (props: GeolocatedProps) => {
  const auth = useSelector((state: any) => state.firebase.auth);

  //const polylineRef = useRef<google.maps.Polyline | null>(null);
  //const markerRef = useRef<google.maps.Marker | null>(null);
  //const [initialPath, setInitialPath] = useState([] as GpsStatus[]);
  const { height, width } = useWindowDimensions();
  const { uidUser, idTransfer } = useParams<QuizParams>();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ScheduledTransfer | undefined>(undefined);
  const [driverData, setDriverData] = useState<PersonalInformation | undefined>(
    undefined
  );
  const [position, setPosition] = useState<GpsStatusUser | undefined>(
    undefined
  );

  useEffect(() => {
    setLoading(true);
    if (uidUser && idTransfer && isLoaded(auth)) {
      setLoading(true);
      Promise.all([
        getPersonalInformation(uidUser).then(setDriverData),
        getStartedTransfer(uidUser, idTransfer).then(setData),
        getPositionUser(uidUser).then(setPosition),
      ]).then(() => setLoading(false));
    }
  }, [auth]);

  const driverLat = position?.geopoint.latitude;
  const driverLng = position?.geopoint.longitude;
  const userLat = props.coords?.latitude;
  const userLng = props.coords?.longitude;

  let cameraLat: number;
  let cameraLng: number;
  let zoom = 11;
  if (userLat && driverLat) {
    cameraLat = (driverLat + userLat) / 2;
  } else {
    if (userLat) {
      cameraLat = userLat;
    } else {
      if (driverLat) {
        cameraLat = driverLat;
      } else {
        cameraLat = -34.609597;
        zoom = 11;
      }
    }
  }
  if (userLng && driverLng) {
    cameraLng = (driverLng + userLng) / 2;
  } else {
    if (userLng) {
      cameraLng = userLng;
    } else {
      if (driverLng) {
        cameraLng = driverLng;
      } else {
        cameraLng = -58.441831;
        zoom = 11;
      }
    }
  }

  if (loading) return <Spinner />;
  if (data === undefined || driverData === undefined) return <>Error</>;
  return (
    <>
      <div>
        <div style={{ height: height, width: width }}>
          {/*<LoadScript
                    id='script-loader'
                    googleMapsApiKey='AIzaSyCK5NSBuD4qseCXZhE9IWvoIvCfvDDc09w'
                    language='es'
                    libraries={libraries}>
                    <GoogleMap
                        center={{lat: cameraLat, lng: cameraLng}}
                        zoom={zoom}
                        mapContainerStyle={{ height: height, width: width}}
                        options={{
                            streetViewControl: false,
                            fullscreenControl:true,
                            zoomControlOptions: {position: ControlPosition.RIGHT_CENTER}
                        }}>
                        <Polyline
                            onLoad={p => { polylineRef.current = p }}
                            options={{
                                clickable: false,
                                strokeColor: "red",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                            }}
                            path={initialPath.map(p => ({ lat: p.gps.geopoint.latitude, lng: p.gps.geopoint.longitude }))}
                        />
                        {initialPath.length > 0 &&
                            <Marker
                                label={{
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    text: "Conductor",
                                }}
                                onLoad={m => { markerRef.current = m }}
                                position={{ lat: initialPath[0].gps.geopoint.latitude, lng: initialPath[0].gps.geopoint.longitude }}
                            />
                        }
                         {userLng && userLat && 
                        <Marker
                            label={{
                                fontWeight: 'bold',
                                fontSize: '16px',
                                text: "Usuario",
                            }}
                            position={{ lat: userLat, lng: userLng}}
                        />
}
                        {driverLng && driverLat && 
                        <Marker
                            label={{
                                fontWeight: 'bold',
                                fontSize: '16px',
                                text: "Conductor",
                            }}
                            position={{ lat: driverLat, lng: driverLng }}
                        />}
                    {driverLng && driverLat && userLng && userLat && 
                         <DirectionsService
                            options={{
                                travelMode: TravelMode.DRIVING,
                                destination: { lat: driverLat, lng: driverLng },
                                origin: { lat: userLat, lng: userLng }
                            }}
                            callback={directionsCallback}
                        />}
                        <DirectionsRenderer
                            options={{ directions: directionResponse, suppressMarkers: true }}
                        /> 
                    </GoogleMap>
                        </LoadScript>*/}
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyD9_LJRGAjiACJ0njksDgoK5m85obW02ng",
            }}
            defaultCenter={{ lat: cameraLat, lng: cameraLng }}
            defaultZoom={zoom}
          >
            {userLng && userLat && (
              <Markers lat={userLat} lng={userLng} text="Usuario" />
            )}

            {driverLng && driverLat && (
              <Markers lat={driverLat} lng={driverLng} text="Conductor" />
            )}
          </GoogleMapReact>
        </div>
      </div>
      <InfoContainer>
        <Title>Datos del conductor</Title>
        <Row>
          <Col>
            <InfoRow
              label="Nombre:"
              info={driverData.name + " " + driverData.surname}
            />
          </Col>
          <Col>
            <InfoRow
              label="Teléfono:"
              info={driverData.phone.area_code + driverData.phone.number}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoRow
              label="Auto:"
              info={
                data.car!.brand + " " + data.car!.model + " " + data.car!.color
              }
            />
          </Col>
          <Col>
            <InfoRow label="Patente:" info={data.car!.licence} />
          </Col>
        </Row>
        <hr style={{ marginBottom: 0 }} />
        <div style={{ textAlign: "center" }}>
          <span>Tiempo de llegada:</span>
          <span style={{ fontSize: 24, fontWeight: "bold" }}>
            {" "}
            <Timer d={addMinutes(new Date(), 5)} />
          </span>
        </div>
      </InfoContainer>
    </>
  );
};

const InfoRow = ({ label, info }: any) => (
  <div style={{ marginTop: 5, marginLeft: 10 }}>
    <Row style={{ color: "#888" }}>{label}</Row>
    <Row style={{ color: "#333", fontWeight: "bold" }}>{info}</Row>
  </div>
);

const Title = styled.span`
  font-size: 20px;
  margin-top: 10px;
  font-weight: bold;
`;

const InfoContainer = styled.div`
  position: absolute;
  left: 2%;
  bottom: 1%;
  width: 94%;
  opacity: 0.9;
  background-color: white;
  padding: 10px 20px 10px 20px;
`;

const Markers = (props: any) => {
  return (
    <>
      <div className="pin"></div>
      <div className="pulse"></div>
    </>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  watchPosition: true,
  userDecisionTimeout: 5000,
})(Microlanding);
