import { Uid } from "controllers/auth";
import database, { WithId, WithUid } from "controllers/database";
import firebase from "firebase/app";
import "firebase/firestore";
import { update } from "lodash";
import { CBU_CVU, TaxableEntity, User, AccountType } from "types/user-data";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";

export const getTaxableEntity = (uid: Uid): Promise<TaxableEntity> =>
  database
    .collection("user")
    .doc(uid)
    .collection("taxable")
    .doc(uid)
    .as(TaxableEntity);

export const setTaxableEntity = async (
  uid: Uid,
  taxableEntity: TaxableEntity
) => {
  let newTax: TaxableEntity;
  const doc = firebase
    .firestore()
    .collection("user")
    .doc(uid)
    .collection("taxable")
    .doc(uid);
  await doc.get().then(async (v) => {
    if (taxableEntity.typeBank == CBU_CVU["CBU"]) {
      newTax = {
        tax_entity: taxableEntity.tax_entity,
        cuit: taxableEntity.cuit,
        cbu: taxableEntity.cbu,
        typeAccount:
          taxableEntity.typeAccount === "0"
            ? "Cuenta Corriente en pesos"
            : "Caja de ahorro en pesos",
      };
    } else if (taxableEntity.typeBank == CBU_CVU["CVU"]) {
      newTax = {
        tax_entity: taxableEntity.tax_entity,
        cuit: taxableEntity.cuit,
        cvu: taxableEntity.cbu,
        typeAccount:
          taxableEntity.typeAccount === "0"
            ? "Cuenta Corriente en pesos"
            : "Caja de ahorro en pesos",
      };
    }
    if (v.exists) {
      const d = { id: uid, cuit: taxableEntity.cuit };
      await CALLABLE_FUNCTIONS.USER_RESEND_INFO_GESTORANDO(d);
      await doc.update(newTax);
    } else {
      await doc.set(newTax);
      console.log("2");
    }
  });
};

export const add_point_of_sale = (uid: Uid, p: number): Promise<void> =>
  firebase
    .firestore()
    .collection("user")
    .doc(uid)
    .collection("taxable")
    .doc(uid)
    .update({ point_of_sale: p });

export const getTaxablesAdmin = (): Promise<
  (WithUid & WithId & User & TaxableEntity)[]
> =>
  database
    .collectionGroup("taxable")
    .as(TaxableEntity)
    .then((contracts) =>
      Promise.all(
        contracts.map((contract) =>
          database
            .collection("user")
            .doc(contract.uid)
            .as(User)
            .then(
              (user) =>
                ({
                  ...contract,
                  ...user,
                } as any)
            )
        )
      )
    );
