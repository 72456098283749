import database from "controllers/database";
import { ArtInformation } from "types/user-data";

export const getArtInformation = (uid : string) => 
    database.collection('user').doc(uid).collection('art_info').doc(uid).as(ArtInformation)

export const setArtInformation = (uid : string, d : ArtInformation) => 
    database.collection('user').doc(uid).collection('art_info').doc(uid).set(d, ArtInformation)

export const validate = (values:any) => {
    const errors = {} as any
    if(!values.operations_mails){
        errors.operations_mails = 'Tiene que tener almenos un mail'
    }
    if(!values.administration_mails){
        errors.administration_mails = 'Tiene que tener almenos un mail'
    }
    if(!values.billing_mails){
        errors.billing_mails = 'Tiene que tener almenos un mail'
    }
    return errors
}