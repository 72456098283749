import React from "react";
import styled from "styled-components";

export const NumberCounter = ({ value, onBlur, onChange, isInvalid, isValid, decimals, increment, disabled, ...props }: any) => {
    const interDecimals = decimals !== undefined ? decimals : 2
    let className = ""
    if(isInvalid)
        className = "is-invalid"
 
    const incrementFunc = () => !disabled ? onChange((Number(value)+increment).toFixed(interDecimals)) : null
    const decrementFunc = () => !disabled ? onChange((Number(value)-increment).toFixed(interDecimals)) : null
    return (
        <DivInputNumber className={className} disabled={disabled}>
            <span className="buttonDecrease" onClick={incrementFunc}>+</span>
            <input className={`inputNumber ${className}`} disabled={disabled} type="number" step={increment} style={ { ...props.style, display: 'block', textAlign:'center' } } value={value} onChange={onChange} />     
            <span className="buttonIncrease" onClick={decrementFunc}>-</span>
        </DivInputNumber>
    )
}

const DivInputNumber = styled.div<{disabled:Boolean}>`
    position: relative;
    width: 180px;
    line-height: 38px;
    .buttonDecrease, .buttonIncrease {
        position: absolute;
        z-index:1;
        top: 1px;
        width:40px;
        height:auto;
        text-align:center;
        background: #f5f7fa;
        color: #606266;
        cursor: ${props => props.disabled ? 'default' : 'pointer'};
        font-size: 13px;
    }
    .buttonDecrease {
        left: 1px;
        border-radius: 4px 0 0 4px;
        border-right: 1px solid #dcdfe6;
    }
    .buttonIncrease {
        right: 1px;
        border-radius: 0 4px 4px 0;
        border-left: 1px solid #dcdfe6;
    }
    .inputNumber {
        color: #606266;
        border: 1px solid #dcdfe6;
        height: 40px;
        font-size: inherit;
        padding-left: 60px;
        padding-right: 50px;
        text-align: center;
        width: 100%;
    }
`