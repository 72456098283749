import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/storage"

//import ModalImage from "react-modal-image";
const ModalImage : any = require("react-modal-image").default;

const FbImageModal = ({src}:{src:string|undefined}) => {
    if(src === undefined || src === "")
        return <>No hay archivo cargado</>
    return <MainContainer src={src} />
}

const MainContainer = ({src}:{src:string}) => {
    
    const [downloadURL, setDownloadURL] = useState<string|undefined>()

    useEffect(() => {
        firebase.storage().ref(src).getDownloadURL().then( setDownloadURL )
    }, [])
        
    if(!downloadURL)
        return <></>

    return <ModalImage small={downloadURL} medium={downloadURL} large={downloadURL} hideDownload/>
}

export default FbImageModal