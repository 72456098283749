import { Field } from "components/input/field";
import { PhoneField } from "components/input/sign-up/fields";
import { H3, Padding, Spinner, Link, SubmitButton } from "components/styles";
import { Pages } from "components/User/Newcomers/pages";
import { codigoBuenosAires } from "constants/codigos-telefono";
import { UserType } from "controllers/user";
import { required } from "controllers/validations";
import firebase from "firebase/app";
import "firebase/functions";
import React, { useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { FieldArray, reduxForm, SubmissionError } from "redux-form";
import { validate } from "./reducer";
import {
  User,
  ArtInformation,
  ChargeFrequency,
  ProfileStatus,
  VatOptions,
} from "types/user-data";
import ROUTES from "constants/routes";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
var generator = require("generate-password");

const Arts = () => {
  const [result, setResult] = useState<
    { uid: string; name: string; email: string; password: string } | undefined
  >(undefined);
  const onSubmit = async (values: any) => {
    const password = generator.generate({
      length: 10,
      numbers: true,
    });
    const data: User &
      Omit<ArtInformation, "billing_address"> & {
        billing_address: string;
        password: string;
      } = {
      name: values.name,
      surname: "",
      phone: values.phone,
      email: values.email,
      type: UserType.art,
      cuit: values.cuit,
      sms_enabled: values.sms_enabled === "Si" ? true : false,
      step_form_newcomers: Pages.length,
      profile_user_status: ProfileStatus.APROBADO,
      charge_frequency: Number(values.charge_frequency),
      payment_expiracy_days: Number(values.payment_expiracy_days),
      internal_code: values.internal_code,
      operations_mails: values.operations_mails.map((v: any) => v.email),
      administration_mails: values.administration_mails.map(
        (v: any) => v.email
      ),
      billing_mails: values.billing_mails.map((v: any) => v.email),
      billing_address: values.billing_address,
      vat: Number(values.vat),
      password,
    };

    return CALLABLE_FUNCTIONS.ADMIN_NEW_ART(data)
      .then((r) =>
        setResult({
          uid: r.data,
          name: values.name,
          email: values.email,
          password: password,
        })
      )
      .catch((ex: firebase.functions.HttpsError) => {
        throw new SubmissionError({ _error: ex.message });
      });
  };

  return (
    <Padding pad="15px">
      <Row>
        <Col>
          <H3>Dar de alta ARTs</H3>
        </Col>
        <Col>
          <Link to={ROUTES.ADMIN_CONFIGURATION_MAXPRICE_TRANSFER_ART}>
            <Button className="pull-right">
              Configurar precio Máximo Traslado
            </Button>
          </Link>
        </Col>
      </Row>
      {result ? (
        <>
          <p>Datos de la cuenta:</p>
          <p>
            <b>Uid:</b> {result.uid}
          </p>
          <p>
            <b>Nombre:</b> {result.name}
          </p>
          <p>
            <b>Email:</b> {result.email}
          </p>
          <p>
            <b>Contraseña:</b> {result.password}
          </p>
          <Button onClick={() => setResult(undefined)} variant="info">
            Dar de alta otra ART
          </Button>
        </>
      ) : (
        <ReduxFormNewArt
          onSubmit={onSubmit}
          initialValues={{ phone: { area_code: codigoBuenosAires } }}
        />
      )}
    </Padding>
  );
};

const FormNewArt = ({ handleSubmit, submitting, error, pristine }: any) => {
  return (
    <form onSubmit={handleSubmit}>
      <Form.Row>
        <Col xl={6}>
          <Field
            labelinput={<>Nombre art</>}
            name="name"
            type="text"
            placeholder="Nombre ART"
            validate={required}
          />
        </Col>
        <Col xl={6}>
          <Field
            labelinput={<>Cuit</>}
            name="cuit"
            type="text"
            placeholder="CUIT"
            validate={required}
          />
        </Col>
        <Col xl={6}>
          <Field
            name="email"
            type="email"
            validate={required}
            labelinput={<>Email para ingresar al sistema</>}
          />
        </Col>
        <Col xl={6}>
          <PhoneField
            name="phone"
            labelinput={<>Número de Teléfono</>}
            placeholder="Número de teléfono"
          />
        </Col>
        <Col xl={4}>
          <Field
            labelinput={<>Frecuencia de facturación</>}
            name="charge_frequency"
            type="select"
            placeholder="CUIT"
            validate={required}
            options={ChargeFrequency}
          />
        </Col>
        <Col xl={4}>
          <Field
            name="payment_expiracy_days"
            type="number"
            validate={required}
            labelinput={<>Tiempo de vencimiento de pago (en días)</>}
          />
        </Col>
        <Col xl={4}>
          <Field
            name="internal_code"
            type="text"
            validate={required}
            labelinput={<>Código Interno</>}
          />
        </Col>
        <Col xl={4}>
          <Field
            name="sms_enabled"
            type="select"
            validate={required}
            labelinput={<>Habilitar SMS con cargo</>}
          >
            <option value="Si">Si</option>
            <option value="No">No</option>
          </Field>
        </Col>
        <Col xl={4}>
          <Field
            name="vat"
            type="select"
            validate={required}
            labelinput={<>IVA</>}
            options={VatOptions}
          />
        </Col>
        <Col xl={4}>
          <Field
            name="billing_address"
            type="address"
            validate={required}
            labelinput={<>Domicilio de facturación</>}
          />
        </Col>
      </Form.Row>
      <hr />
      <FieldArray
        validate={required}
        name="operations_mails"
        title="Operacionales"
        component={renderEmails as any}
      />
      <hr />
      <FieldArray
        validate={required}
        name="administration_mails"
        title="Administrativos"
        component={renderEmails as any}
      />
      <hr />
      <FieldArray
        validate={required}
        name="billing_mails"
        title="Cobranza"
        component={renderEmails as any}
      />
      <hr />
      <Row className="justify-content-end">
        <Col xl={3}>
          <SubmitButton
            className="pull-right"
            variant="info"
            type="submit"
            submitting={submitting}
            block
          >
            Dar de alta
          </SubmitButton>
        </Col>
      </Row>
      <Form.Row style={{ marginTop: 10 }}>
        <Col>{error && <Alert variant="danger">{error}</Alert>}</Col>
      </Form.Row>
    </form>
  );
};

const renderEmails = ({
  fields,
  meta: { error, submitFailed },
  title,
}: any) => {
  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <Row style={{ marginBottom: 20 }}>
        <Col>
          <H3>Mails {title}</H3>
        </Col>
        <Col>
          <Button block onClick={() => fields.push({})}>
            Agregar
          </Button>
        </Col>
      </Row>
      {submitFailed && error && (
        <span style={{ color: "#dc3545" }}>{error}</span>
      )}
      {fields.map((member: any, index: number) => (
        <Row key={index} style={{ marginTop: 20 }}>
          <Col xl={9}>
            <Field
              labelinput={<>Email #{index + 1}</>}
              validate={required}
              name={`${member}.email`}
              type="email"
            />
          </Col>
          <Col xl={3}>
            <Button block variant="danger" onClick={() => fields.remove(index)}>
              Remover
            </Button>
          </Col>
        </Row>
      ))}
    </div>
  );
};

const ReduxFormNewArt = reduxForm({
  form: "art-form",
  validate,
})(FormNewArt);

export default Arts;
