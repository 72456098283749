import FbImage from "components/Primitives/Image/with-modal";
import { Center, ContainerPage, Spinner, PageTitle } from "components/styles";
import {
  getRevisionLicence,
  setRevisionLicence,
} from "components/User/Newcomers/finished/reducer";
import { getLicence } from "controllers/user/info/licence";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { FormReview, InfoRow, textStatus } from "../Profile/review";
import { LicenceCategory, Licence, Revision } from "types/user-data";
import { QuizParams } from "types/module-transfer";

const LicenceRevision = () => {
  let { uidUser } = useParams<QuizParams>();

  if (!uidUser) return <>Error uid</>;
  return (
    <ContainerPage>
      <MainContainer uidUser={uidUser} />
    </ContainerPage>
  );
};

const MainContainer = ({ uidUser }: { uidUser: string }) => {
  const [item, setItem] = useState<Licence | undefined>(undefined);
  const [revision, setRevision] = useState<Revision | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [labelsWrong, setLabelsWrong] = useState<Set<string>>(
    new Set<string>()
  );
  const add = (s: string) =>
    setLabelsWrong(new Set<string>(labelsWrong).add(s));
  const rm = (s: string) => {
    let cp = new Set(labelsWrong);
    cp.delete(s);
    setLabelsWrong(cp);
  };
  useEffect(() => {
    setLoading(true);
    getLicence(uidUser)
      .then(setItem)
      .then(() => setLoading(false));

    getRevisionLicence(uidUser).then(setRevision);
  }, []);

  if (loading) return <Spinner />;
  if (item === undefined) return <>Error cargando los datos</>;
  return (
    <>
      <PageTitle>Datos de Licencia</PageTitle>
      <Row>
        <Col lg="6">
          <InfoRow
            add={add}
            rm={rm}
            label={"Número de licencia"}
            info={item.number}
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Expiración"}
            info={item.expire_date.toDate().toLocaleDateString()}
          />
          <InfoRow
            add={add}
            rm={rm}
            label={"Categoría"}
            info={LicenceCategory[item.category]}
          />
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col lg="6">
          <InfoRow
            add={add}
            rm={rm}
            label={"Licencia Delante"}
            info={<FbImage src={item.photo_front} />}
          />
        </Col>
        <Col lg="6">
          <InfoRow
            add={add}
            rm={rm}
            label={"Licencia dorso"}
            info={<FbImage src={item.photo_back} />}
          />
        </Col>
      </Row>

      <br />
      <Center>ESTADO ACTUAL: {revision && textStatus(revision.status)}</Center>
      <FormReview
        uidUser={uidUser}
        setRevision={setRevisionLicence}
        wrong_fields={labelsWrong}
      />
    </>
  );
};

export default LicenceRevision;
