import * as firebase from "firebase/app";
import "firebase/auth";

export interface Credential {
  email: string;
  password: string;
}

export declare type Uid = string;

export const ifLogged = (callback: (uid: Uid) => void): void => {
  firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
    if (user !== null) {
      callback(user.uid);
    }
  });
};

export const getUid = (): Uid | null =>
  (firebase.auth().currentUser as firebase.User).uid;

export const createCredential = (
  email: string,
  password: string
): Promise<Uid> => {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((credential) => {
      return (credential.user as firebase.User).uid;
    });
};

export const signIn = (email: string, password: string): Promise<Uid> => {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((credential) => {
      return (credential.user as firebase.User).uid;
    });
};

export const signOut = (): Promise<void> => {
  return firebase.auth().signOut();
};

export const uidSelector = (state: any): Uid => state.firebase.auth.uid;
