import ROUTES from 'constants/routes'
import { signIn } from 'controllers/auth'
import { AnyFirebaseError, getMessage } from 'controllers/error'
import { getUserType } from 'controllers/user'
import { SubmissionError } from 'redux-form'

export const handleSubmit = (values : any, dispatch : Function, props : any) => {
    return signIn(
        values.email, 
        values.password)
        .catch((error : AnyFirebaseError) => {
            throw new SubmissionError({_error: getMessage(error)})
        })
        .then(async (uid) => {
            props.session.onSignIn(uid)
            var type = await getUserType(uid)
            props.history.push(ROUTES.HOME(type))
        })
}