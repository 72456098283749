import { InfoRow, Title } from "components/Admin/Transfers/view/scheduled";
import FbFile from "components/Primitives/File";
import {
  AdminTable,
  Button,
  ButtonWithConfirmation,
  ContainerPage,
  ErrorPage,
  Link,
  PageTitle,
  Spinner,
  AsyncButton,
} from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import { useDatabaseDocref, useDbDocument } from "controllers/hooks";
import React, { useState, useEffect } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import Moment from "react-moment";
import { isLoaded } from "react-redux-firebase";
import { useParams } from "react-router";
import { BillInfoTransfer } from "types/billing";
import { QuizParams } from "types/module-transfer";
import { FbDocument, ScheduledTransfer } from "types/transfer";
import { showFbDate } from "util/date";
import { UserName } from "../Sales/view-bill";
import * as fs from "file-saver";

const ViewDriverBill = () => {
  const { id } = useParams<QuizParams>();
  const bill = useDatabaseDocref<BillInfoTransfer>(
    FIRESTORE.DOCUMENT_DRIVER_BILL(id)
  );
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [filename, setFilename] = useState<string>("");
  const [file_base64, setfile_base64] = useState<string>("");
  const [typePdf, setTypePdf] = useState<boolean>(false);
  if (!bill) return <ErrorPage />;
  const routPdf = bill!.pdf;
  console.log(routPdf);

  console.log(typePdf, "typePdf");

  const sendEmailBill = () =>
    CALLABLE_FUNCTIONS.SEND_EMAIL_DRIVER_BILL({ idBill: id });
  const deleteBill = () =>
    CALLABLE_FUNCTIONS.DELETE_TRANSFER_BILL({ idBill: id });
  //const updateExpirationDate = () => FIRESTORE.DOCUMENT_DRIVER_BILL(id).update({expiration_date: FbDate.fromDate(expirationDate) })

  console.log(filename, file_base64);
  if (filename !== "" && file_base64 !== "") {
    const linkSource = `data:application/pdf;base64,${file_base64}`;
    const downloadLink = document.createElement("a");
    const fileName = filename;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const downloadPDF = async () => {
    await CALLABLE_FUNCTIONS.DOWLOAD_PDF_GESTORANDO({ idBill: id }).then(
      (d) => {
        setFilename(d.data.filename);
        setfile_base64(d.data.file_base64);
      }
    );
  };
  return (
    <ContainerPage>
      <Row>
        <Col>
          <PageTitle>
            Factura número -{" "}
            {bill.bill_code ? bill.bill_code : bill.voucher_number} -{" "}
            <Moment format="DD/MM/YYYY" date={bill.creation_date.toDate()} />
          </PageTitle>
        </Col>

        <Col>
          {bill.payment_order && (
            <Link to={ROUTES.ADMIN_SALES_DRIVER_PAYMENT(bill.payment_order.id)}>
              <Button style={{ marginLeft: 10 }} className="pull-right">
                Ver orden de pago
              </Button>
            </Link>
          )}
          <ButtonWithConfirmation
            className="pull-right"
            onClick={sendEmailBill}
            style={{ marginLeft: 10 }}
            confirmText="¿Está seguro que quiere enviar la factura por mail al prestador?"
          >
            Enviar factura por mail
          </ButtonWithConfirmation>
          <ButtonWithConfirmation
            className="pull-right"
            onClick={deleteBill}
            style={{ marginLeft: 10 }}
            variant="danger"
            confirmText="¿Está seguro que quiere realizar una nota de crédito para cancelar la factura?"
          >
            Eliminar
          </ButtonWithConfirmation>
          {routPdf.includes("invoices") ? (
            <FbFile src={bill.pdf}>
              <Button className="pull-right" variant="info">
                Ver PDF
              </Button>
            </FbFile>
          ) : (
            <AsyncButton
              className="pull-right"
              variant="info"
              onClick={downloadPDF}
            >
              Descargar PDF
            </AsyncButton>
          )}
        </Col>
      </Row>

      {bill.deleted_vouche_number && (
        <Alert variant="danger">
          FACTURA CANCELADA CON VOUCHER DE NOTA DE CRÉDITO{" "}
          {bill.deleted_vouche_number}
        </Alert>
      )}

      <Row>
        <Title>Detalles</Title>
      </Row>
      <InfoRow
        label="Prestador"
        info={<UserName uid={bill.driver} surname />}
      />
      <InfoRow
        label="Fecha de vencimiento"
        info={showFbDate(bill.expiration_date)}
      />
      <InfoRow label="Importe Total" info={`$${bill.ImpTotal}`} />

      <Row>
        <Title>Traslados</Title>
      </Row>
      <AdminTable responsive>
        <thead>
          <tr>
            <th>ID Siniestro-Aut/NPA</th>
            <th>Fecha Traslado</th>
            <th>Origen</th>
            <th>Destino</th>
            <th>Paciente</th>
            <th>Ver Traslado</th>
          </tr>
        </thead>
        <tbody>
          {bill.transfers.map((item) => (
            <ViewSimpleTransfer refDoc={item} />
          ))}
        </tbody>
      </AdminTable>
    </ContainerPage>
  );
};

const ViewSimpleTransfer = ({
  refDoc,
}: {
  refDoc: FbDocument<ScheduledTransfer>;
}) => {
  const item = useDbDocument<ScheduledTransfer>(refDoc.parent.path, refDoc.id);
  const id = refDoc.id;
  if (!isLoaded(item)) return <Spinner />;
  if (!item) return <ErrorPage />;
  return (
    <tr key={id}>
      <td>
        {item.art.accident && item.art.authorization
          ? item.art.accident + "-" + item.art.authorization
          : item.art.accident
          ? item.art.accident
          : "NPA: " + item.art.npa}
      </td>
      <td>
        <Moment date={item.origin.time.toDate()} format="DD/MM/YYYY" />
      </td>

      <td>{item.origin.address.formatted_address}</td>
      <td>{item.destination.address.formatted_address}</td>
      <td>{item.passenger.name}</td>
      <td>
        <Link to={ROUTES.ADMIN_MODULE_TRANSFER_VIEW(item.module.id)}>
          <Button variant="light">Ver Traslado</Button>
        </Link>
      </td>
    </tr>
  );
};

export default ViewDriverBill;
