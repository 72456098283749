import { enumToOptions } from "components/input/enums";
import { Field } from "components/input/field";
import { Container, Required } from "components/styles";
import { futureDate, required } from "controllers/validations";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { LicenceCategory } from "types/user-data";
import { imageRequired } from "../../../../controllers/validations";

const futureDateLicence = futureDate(
  "La licencia no puede estar vencida por más de un año"
);

const LicenseData = () => (
  <Container>
    <Row>
      <Col md={4}>
        <Field
          labelinput={
            <>
              <Required />
              Número Licencia
            </>
          }
          name="number"
          type="text"
          placeholder="Número de Licencia"
          validate={required}
        />
      </Col>
      <Col md={4}>
        <Field
          labelinput={
            <>
              <Required />
              <b>Válido hasta el</b>
              <p>
                (Si tu licencia fue otorgada antes del 17 de marzo del 2020 y
                vence entre el 14 de febrero del 2020 y el 31 de diciembre de
                2021, tenés una Prórroga de dos años desde su vencimiento.)
              </p>
            </>
          }
          name="expire_date"
          type="date-picker"
          validate={[required, futureDateLicence]}
        />
      </Col>
      <Col md={4}>
        <Field
          labelinput={
            <>
              <Required />
              Categoría
            </>
          }
          name="category"
          type="select"
          validate={required}
        >
          {enumToOptions(LicenceCategory)}
        </Field>
      </Col>
    </Row>
    <Row>
      <Col xs={6}>
        <h3>Frente licencia</h3>
        <Field type="image" name="photo_front" validate={imageRequired} />
      </Col>
      <Col xs={6}>
        <h3>Dorso licencia</h3>
        <Field type="image" name="photo_back" validate={imageRequired} />
      </Col>
    </Row>
  </Container>
);

export default LicenseData;
