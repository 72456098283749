import React from "react"

export const enumToOptions = (E: any) => (
   getKeys(E).map((key : any) => 
        <option value={E[key]} label={key} key={key}></option>)
)

const getKeys = (E: any) =>  Object.keys(E).filter(k => typeof E[k as any] === "number")

export const selectYears = (quantity : number) => (
        [...Array(quantity).keys()].map( x => 
                <option key={x}>{new Date().getFullYear()-quantity+x+1}</option>
        )
)

// export const enumToRadio = (E: any) => {
//     getKeys(E).map((key : any) => {

//     }
// }