import {
  Button,
  H2,
  NoData,
  Padding,
  Searcher,
  Spinner,
} from "components/styles";
import ROUTES from "constants/routes";
import { WithId } from "controllers/database";
import { useDbCollection } from "controllers/hooks";
import { getFullAddress } from "controllers/map";
import { isEmpty } from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Col,
  Form,
  Row,
  Table as TableB,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Moment from "react-moment";
import { isLoaded } from "react-redux-firebase";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  DefinitionsModuleTransferType,
  ModuleTransfer,
} from "types/module-transfer";
import { TransferStage } from "types/transfer";
import { usePaginator } from "util/hooks";
import {
  getCollectionByDate,
  getCollectionByDateFilter,
  getCollectionByDateKeyWords,
  getCollectionByDateKeyWordsFilter,
  getCollectionByKeyWordsFilter,
  getCollectionSearchByKeyWords,
  getModulesByType,
  getCollectionByDateKeyZoneFilter,
  getCollectionByKeyWordsFilterZone,
  getCollectionByDateKeyWordsZone,
  getCollectionByDateFilterZone,
  getCollectionSearchByKeyWordsZone,
  getCollectionByFilterZone,
  getCollectionSearchByZone,
} from "./reducer";
import { moduleTransferStage } from "./view/module/header";
import {
  faBell,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faEye,
  faEyeSlash,
  faUserClock,
  faTaxi,
  faBan,
  faDirections,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FIRESTORE } from "constants/firestore";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { GetProperty } from "../Sales/Sales/view-bill";
import { Timer, TimerMinutes } from "components/Art/Reports";
import { getZonesConfig } from "../Config/Global/reducer";
import { ZoneConfig } from "types/config";
const AdminTransfersPage = () => (
  <MainContainer viewTransfer={ROUTES.ADMIN_MODULE_TRANSFER_VIEW} art={false} />
);

export const MainContainer = ({
  viewTransfer,
  art,
}: {
  viewTransfer: (a: any) => string;
  art: boolean;
}) => {
  const [asc, setAsc] = useState<boolean>(false);
  const [items, loading, Pagination, setCollection] = usePaginator(
    getModulesByType(0, asc),
    50
  );

  const history = useHistory();
  const parsed = queryString.parse(window.location.search);
  const [searchDate, setSearchDate] = useState<string>("");
  const [searchKeyWord, setSearchKeyWord] = useState<string>("");
  const [tipitoClicked, setTipitoClicked] = useState<boolean>(false);
  const [showCanceled, setShowCanceled] = useState<boolean>(false);
  const [filteredModule, setFilteredModules] = useState<
    (ModuleTransfer & WithId)[]
  >([]);
  const [selectedZone, setSelectedZone] = useState<string>("");
  const [zones, setZones] = useState([] as (ZoneConfig & WithId)[]);
  const zone = () => {
    getZonesConfig().then(setZones);
  };

  useEffect(() => {
    zone();
    setShowCanceled(false);
    setTipitoClicked(false);
    if (Object.keys(parsed).length === 0) {
      setCollection(getModulesByType(Number(0), asc));
    }
    if (
      parsed.date !== undefined &&
      parsed.filterType !== undefined &&
      parsed.keyword !== undefined &&
      parsed.filterZone !== undefined
    ) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setSearchKeyWord(parsed.keyword as string);
        setCollection(
          getCollectionByDateKeyWordsFilter(
            dateSearch.toDate(),
            dateSearch2.toDate(),
            parsed.keyword as string,
            Number(parsed.filterType),
            parsed.filterZone as string,
            asc
          )
        );
      }
    } else if (
      parsed.date !== undefined &&
      parsed.filterType !== undefined &&
      parsed.filterZone !== undefined
    ) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setCollection(
          getCollectionByDateKeyZoneFilter(
            dateSearch.toDate(),
            dateSearch2.toDate(),
            Number(parsed.filterType),
            parsed.filterZone as string,
            asc
          )
        );
      }
    } else if (
      parsed.filterType !== undefined &&
      parsed.keyword !== undefined &&
      parsed.filterZone !== undefined
    ) {
      setSearchKeyWord(parsed.keyword as string);
      setCollection(
        getCollectionByKeyWordsFilterZone(
          parsed.keyword as string,
          Number(parsed.filterType),
          parsed.filterZone as string,
          asc
        )
      );
    } else if (
      parsed.date !== undefined &&
      parsed.keyword !== undefined &&
      parsed.filterZone !== undefined
    ) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setSearchKeyWord(parsed.keyword as string);
        setCollection(
          getCollectionByDateKeyWordsZone(
            dateSearch.toDate(),
            dateSearch2.toDate(),
            parsed.keyword as string,
            parsed.filterZone as string,
            asc
          )
        );
      }
    } else if (parsed.date !== undefined && parsed.filterType !== undefined) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setCollection(
          getCollectionByDateFilter(
            dateSearch.toDate(),
            dateSearch2.toDate(),
            Number(parsed.filterType),
            asc
          )
        );
      }
    } else if (parsed.date !== undefined && parsed.keyword !== undefined) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setSearchKeyWord(parsed.keyword as string);
        setCollection(
          getCollectionByDateKeyWords(
            dateSearch.toDate(),
            dateSearch2.toDate(),
            parsed.keyword as string,
            asc
          )
        );
      }
    } else if (
      parsed.filterType !== undefined &&
      parsed.keyword !== undefined
    ) {
      setSearchKeyWord(parsed.keyword as string);
      setCollection(
        getCollectionByKeyWordsFilter(
          parsed.keyword as string,
          Number(parsed.filterType),
          asc
        )
      );
    } else if (parsed.date !== undefined && parsed.filterZone !== undefined) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setCollection(
          getCollectionByDateFilterZone(
            dateSearch.toDate(),
            dateSearch2.toDate(),
            parsed.filterZone as string,
            asc
          )
        );
      }
    } else if (
      parsed.keyword !== undefined &&
      parsed.filterZone !== undefined
    ) {
      setSearchKeyWord(parsed.keyword as string);
      setCollection(
        getCollectionSearchByKeyWordsZone(
          parsed.keyword as string,
          parsed.filterZone as string,
          asc
        )
      );
    } else if (
      parsed.filterType !== undefined &&
      parsed.filterZone !== undefined
    ) {
      setCollection(
        getCollectionByFilterZone(
          Number(parsed.filterType),
          parsed.filterZone as string,
          asc
        )
      );
    } else if (parsed.filterType !== undefined) {
      if (parsed.filterType !== undefined)
        setCollection(getModulesByType(Number(parsed.filterType), asc));
    } else if (parsed.keyword !== undefined) {
      setSearchKeyWord(parsed.keyword as string);
      setCollection(
        getCollectionSearchByKeyWords(parsed.keyword as string, asc)
      );
    } else if (parsed.date !== undefined) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setCollection(
          getCollectionByDate(dateSearch.toDate(), dateSearch2.toDate(), asc)
        );
      }
    } else if (parsed.filterZone !== undefined) {
      if (parsed.filterZone !== undefined)
        setCollection(
          getCollectionSearchByZone(parsed.filterZone as string, asc)
        );
    }
  }, [parsed.filterType, parsed.keyword, parsed.date, parsed.filterZone, asc]);

  const bellClick = () => {
    setShowCanceled(false);
    setTipitoClicked(false);
    setSearchDate("");
    setSearchKeyWord("");
    parsed.filterType = "";
    parsed.filterZone = "";
    setCollection(
      FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where("alert", "==", true)
        .orderBy("destination.time", "asc")
    );
  };

  const canceledClick = () => {
    setTipitoClicked(false);
    setShowCanceled(true);
    setSearchDate("");
    setSearchKeyWord("");
    parsed.filterType = "";
    parsed.filterZone = "";
    //setCollection(
    FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("destination.time", ">=", moment().startOf("day").toDate())
      .where("destination.time", "<=", moment().endOf("day").toDate())
      //.where("status.finished_status", "==", 1)
      //.where("missing_new_negative", "!=", true)
      .orderBy("destination.time", asc === true ? "asc" : "desc")
      .get()
      .then((d) => d.docs.map((s) => ({ ...s.data(), id: s.id })))
      .then((data) => {
        return data.filter((module) => {
          const cancelStage = module.status.history.stages.find(
            (stage) => stage.stage === 9
          );
          const hour = cancelStage?.time;
          return (
            module.missing_new_negative !== true &&
            module.status.finished_status === 1 &&
            moment(hour?.toDate()).toDate() >=
              moment().startOf("day").toDate() &&
            moment(hour?.toDate()).toDate() <= moment().endOf("day").toDate()
          );
        });
      })
      .then((filtered) => setFilteredModules(filtered));
    //);
  };

  const userClick = () => {
    setShowCanceled(false);
    setSearchDate("");
    setSearchKeyWord("");
    parsed.filterType = "";
    parsed.filterZone = "";
    setTipitoClicked(true);
    setCollection(
      FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where("status.stage", "==", TransferStage["searching_driver"])
        .where(
          "destination.time",
          ">=",
          moment(new Date()).subtract(3, "hour").toDate()
        )
        .where(
          "destination.time",
          "<=",
          moment(new Date()).add(2, "hours").toDate()
        )
        .orderBy("destination.time", "asc")
    );
  };

  const cabifyClick = () => {
    setShowCanceled(false);
    setSearchDate("");
    setSearchKeyWord("");
    parsed.filterType = "";
    parsed.filterZone = "Todos";
    setTipitoClicked(false);
    setCollection(
      FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where("searching_cabify", "==", true)
        .orderBy("origin.time", "asc")
    );
  };

  const removeAlert = async (idModule: string) => {
    console.log(idModule);
    await CALLABLE_FUNCTIONS.ADMIN_REMOVE_ALERT(idModule);
    setCollection(
      FIRESTORE.COLLECTION_MODULES_TRANSFER()
        .where("alert", "==", true)
        .orderBy("destination.time", "asc")
    );
  };

  const changeType = (type: number) => {
    if (type === 0 && searchDate && searchKeyWord) {
      history.push(
        `?filterType=${type}&keyword=${searchKeyWord}&date=${searchDate}`
      );
    } else if (type === 0 && searchKeyWord && parsed.filterType) {
      history.push(`?filterType=${type}&keyword=${searchKeyWord}`);
    } else if (type === 0 && parsed.filterType) {
      history.push(`?filterType=${type}`);
    } else if (type === 0 && searchDate && parsed.filterType) {
      history.push(`?filterType=${type}&date=${searchDate}`);
    } else if (searchDate && searchKeyWord && parsed.filterZone) {
      history.push(
        `?filterType=${type}&keyword=${searchKeyWord}&date=${searchDate}&filterZone=${parsed.filterZone}`
      );
    } else if (searchDate && searchKeyWord) {
      history.push(
        `?filterType=${type}&keyword=${searchKeyWord}&date=${searchDate}`
      );
    } else if (searchDate && parsed.filterZone) {
      history.push(
        `?filterType=${type}&date=${searchDate}&filterZone=${parsed.filterZone}`
      );
    } else if (searchKeyWord && parsed.filterZone) {
      history.push(
        `?filterType=${type}&keyword=${searchKeyWord}&filterZone=${parsed.filterZone}`
      );
    } else if (searchDate) {
      history.push(`?filterType=${type}&date=${searchDate}`);
    } else if (searchKeyWord) {
      history.push(`?filterType=${type}&keyword=${searchKeyWord}`);
    } else if (parsed.filterZone) {
      history.push(`?filterType=${type}&filterZone=${parsed.filterZone}`);
    } else {
      history.push(`?filterType=${type}`);
    }
  };

  const changeTypeZone = (type: string) => {
    if (type === "0" && searchDate && searchKeyWord && parsed.filterType) {
      history.push(
        `?filterType=${parsed.filterType}&keyword=${searchKeyWord}&date=${searchDate}`
      );
    } else if (type === "0" && searchKeyWord && parsed.filterType) {
      history.push(`?filterType=${parsed.filterType}&keyword=${searchKeyWord}`);
    } else if (type === "0" && parsed.filterType) {
      history.push(`?filterType=${parsed.filterType}`);
    } else if (type === "0" && searchDate && parsed.filterType) {
      history.push(`?filterType=${parsed.filterType}&date=${searchDate}`);
    } else if (type === "0" && searchDate) {
      history.push(`?date=${searchDate}`);
    } else if (type === "0" && searchDate && searchKeyWord) {
      history.push(`?keyword=${searchKeyWord}&date=${searchDate}`);
    } else if (type === "0" && searchKeyWord) {
      history.push(`?keyword=${searchKeyWord}`);
    } else if (searchDate && searchKeyWord && parsed.filterType) {
      history.push(
        `?filterType=${parsed.filterType}&keyword=${searchKeyWord}&date=${searchDate}&filterZone=${type}`
      );
    } else if (searchDate && searchKeyWord) {
      history.push(
        `?&keyword=${searchKeyWord}&date=${searchDate}&filterZone=${type}`
      );
    } else if (parsed.filterType && searchKeyWord) {
      history.push(
        `?filterType=${parsed.filterType}&keyword=${searchKeyWord}&filterZone=${type}`
      );
    } else if (parsed.filterType && searchDate) {
      history.push(
        `?filterType=${parsed.filterType}&date=${searchDate}&filterZone=${type}`
      );
    } else if (searchDate) {
      history.push(`?date=${searchDate}&filterZone=${type}`);
    } else if (searchKeyWord) {
      history.push(`?keyword=${searchKeyWord}&filterZone=${type}`);
    } else if (parsed.filterType) {
      history.push(`?filterType=${parsed.filterType}&filterZone=${type}`);
    } else {
      history.push(`?filterZone=${type}`);
    }
  };

  const searchByKeyWord = (s: string) => {
    if (s === "") return changeType(0);
    if (searchDate && parsed.filterType && parsed.filterZone) {
      history.push(
        `?filterType=${parsed.filterType}&keyword=${s}&date=${searchDate}&filterZone=${parsed.filterZone}`
      );
    } else if (searchDate && parsed.filterType) {
      history.push(
        `?filterType=${parsed.filterType}&keyword=${s}&date=${searchDate}`
      );
    } else if (searchDate && parsed.filterZone) {
      history.push(
        `?keyword=${s}&date=${searchDate}&filterZone=${parsed.filterZone}`
      );
    } else if (parsed.filterType && parsed.filterZone) {
      history.push(
        `?filterType=${parsed.filterType}&keyword=${s}&filterZone=${parsed.filterZone}`
      );
    } else if (searchDate) {
      history.push(`?keyword=${s}&date=${searchDate}`);
    } else if (parsed.filterType) {
      history.push(`?filterType=${parsed.filterType}&keyword=${s}`);
    } else if (parsed.filterZone) {
      history.push(`?keyword=${s}&filterZone=${parsed.filterZone}`);
    } else {
      history.push(`?keyword=${s}`);
    }
  };

  const searchByDate = (s: string) => {
    if (s === "") return changeType(0);

    if (searchKeyWord && parsed.filterType && parsed.filterZone) {
      history.push(
        `?filterType=${parsed.filterType}&keyword=${searchKeyWord}&date=${s}&filterZone=${parsed.filterZone}`
      );
    } else if (searchKeyWord && parsed.filterType) {
      history.push(
        `?filterType=${parsed.filterType}&keyword=${searchKeyWord}&date=${s}`
      );
    } else if (searchKeyWord && parsed.filterZone) {
      history.push(
        `?&keyword=${searchKeyWord}&date=${s}&filterZone=${parsed.filterZone}`
      );
    } else if (parsed.filterType && parsed.filterZone) {
      history.push(
        `?filterType=${parsed.filterType}&date=${s}&filterZone=${parsed.filterZone}`
      );
    } else if (searchKeyWord) {
      history.push(`?keyword=${searchKeyWord}&date=${s}`);
    } else if (parsed.filterType) {
      history.push(`?filterType=${parsed.filterType}&date=${s}`);
    } else if (parsed.filterZone) {
      history.push(`?date=${s}&filterZone=${parsed.filterZone}`);
    } else {
      history.push(`?&date=${s}`);
    }
  };

  const contador = (item: ModuleTransfer & WithId) => {
    if (tipitoClicked) {
      let maxOrigin;
      if (item.info.duration_origin_destination <= 30) {
        maxOrigin = moment(item.origin.time.toDate())
          .add(30, "minutes")
          .toDate();
      } else if (
        item.info.duration_origin_destination > 30 &&
        item.info.duration_origin_destination <= 40
      ) {
        maxOrigin = moment(item.origin.time.toDate())
          .add(60, "minutes")
          .subtract(item.info.duration_origin_destination, "minutes")
          .toDate();
      } else {
        maxOrigin = moment(item.origin.time.toDate())
          .add(20, "minutes")
          .toDate();
      }

      const diferencia = moment(maxOrigin).diff(moment(), "minutes");
      console.log(diferencia, `diferencia en ${item.id}`);
      if (diferencia <= 60 && diferencia >= -20)
        return <TimerMinutes d={maxOrigin} />;
    } else {
      const retorno = item.status.history.stages.find(
        (element: any) =>
          element.stage === TransferStage.searching_driver_return ||
          element.description === "Conductor"
      );
      const inicio = item.status.history.stages.find(
        (element: any) => element.stage === TransferStage.searching_driver
      );
      if (retorno)
        return (
          <TimerMinutes
            d={moment(retorno.time.toDate()).add(1, "hour").toDate()}
          />
        );
      else if (inicio)
        return (
          <TimerMinutes
            d={moment(inicio.time.toDate()).add(1, "hour").toDate()}
          />
        );
    }
  };
  const optionsFilter = [
    "Elegir Etapa (Todas)",
    "Pendiente",
    "Asignados",
    "Un tramo finalizado",
    "Iniciado",
    "En Origen",
    "En Viaje",
    "En Espera",
    "Retorno solicitado",
    "Informado",
    "Finalizado",
    "Negativo",
    "Finalizado, Retorno Negativo",
    "Cancelado",
    "Esperando Acción",
  ];
  console.log(parsed.filterType);
  console.log(items, "items");

  return (
    <Padding pad="5px">
      <Row>
        <Col md={1}>
          <H2>Traslados</H2>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <Form.Control
            as="select"
            value={parsed.filterType !== undefined ? parsed.filterType! : "0"}
            onChange={(event) =>
              changeType(parseInt((event.target as HTMLTextAreaElement).value))
            }
          >
            {optionsFilter.map((op, index) => (
              <option key={index} value={index}>
                {op}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col md={2}>
          <Searcher
            onChange={setSearchDate}
            onSearch={searchByDate}
            placeHolder="Buscar por fecha (21/05/21)"
            value={searchDate}
          />
        </Col>
        <Col md={3}>
          <Searcher
            onChange={setSearchKeyWord}
            onSearch={searchByKeyWord}
            placeHolder="Buscar por palabras clave"
            value={searchKeyWord}
            tooltip={
              <>
                Nombre/teléfono/dni paciente
                <br />
                Nombre del conductor
                <br />
                Nombre art
                <br />
                Siniestro
                <br />
                Cod. Aut
                <br />
                Cod. Experta
                <br />
                NPA
                <br />
                Dirección origen/destino
              </>
            }
          />
        </Col>
        <Col md={2}>
          <Form.Control
            as="select"
            disabled={
              parsed.filterType === undefined || parsed.filterType === "0"
            }
            value={parsed.filterZone !== undefined ? parsed.filterZone! : ""}
            onChange={(e) => {
              changeTypeZone((e.target as HTMLTextAreaElement).value);
            }}
          >
            <option value={"0"}>Todas las ZONAS</option>
            {zones.map((z) => (
              <option key={z.id} value={z.name}>
                {z.name}
              </option>
            ))}
          </Form.Control>
        </Col>
        {!art && (
          <Col md={1}>
            <Link to={ROUTES.ADMIN_NEW_TRANSFER}>
              <Button variant="dark">Nuevo</Button>
            </Link>
          </Col>
        )}
        {!art && (
          <Col md={1.75}>
            <Link
              to={
                ROUTES.ADMIN_MODULES_TRANSFERS +
                "?&date=" +
                moment().format("DD/MM/YY")
              }
            >
              <Button variant="dark">Traslados HOY</Button>
            </Link>
          </Col>
        )}

        <Col md={0.5}>
          <Col>
            <OverlayTrigger
              overlay={<Tooltip id={`tooltip-top`}>Traslados YA.</Tooltip>}
            >
              <FontAwesomeIcon
                icon={faBell}
                style={{ cursor: "pointer" }}
                onClick={bellClick}
                color="#b30000"
              />
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-top`}>Buscando conductores.</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faUserClock}
                style={{ cursor: "pointer" }}
                onClick={userClick}
                color="#0000ff"
              />
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              overlay={<Tooltip id={`tooltip-top`}>Buscando CABIFY.</Tooltip>}
            >
              <FontAwesomeIcon
                icon={faTaxi}
                style={{ cursor: "pointer" }}
                onClick={cabifyClick}
                color="#8A2BE2"
              />
            </OverlayTrigger>
          </Col>
          <Col>
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-top`}>Cancelados el día de HOY.</Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={faBan}
                style={{ cursor: "pointer" }}
                onClick={canceledClick}
                color="red"
              />
            </OverlayTrigger>
          </Col>
        </Col>
      </Row>
      <br />

      {!art && <NotificationsGivebackTransfers optionsFilter={optionsFilter} />}

      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        <Table hover responsive>
          <thead>
            <Tr>
              <th>Siniestro-Aut/NPA</th>
              <th>Modo</th>
              <th>Etapa</th>
              <th>ART</th>
              <th>Conductor</th>
              <th style={{ padding: ` 0 30px` }}>
                Fecha Hora{" "}
                {asc ? (
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faArrowAltCircleUp}
                    color="black"
                    onClick={() => {
                      setAsc(false);
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faArrowAltCircleDown}
                    color="black"
                    onClick={() => {
                      setAsc(true);
                    }}
                  />
                )}
              </th>
              <th>Paciente</th>
              <th>Origen</th>
              <th>Destino</th>
              {/* <th>Retorno</th> */}
              <th></th>
              <th></th>
              <th></th>
            </Tr>
          </thead>
          <tbody>
            {showCanceled !== true
              ? items.map((item) => (
                  <tr key={item.id}>
                    {item.tracking ? (
                      <Td
                        style={{
                          wordBreak: "break-all",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {item.art.accident && item.art.authorization
                          ? item.art.accident + "-" + item.art.authorization
                          : item.art.accident
                          ? item.art.accident
                          : "NPA: " + item.art.npa}
                      </Td>
                    ) : (
                      <Td style={{ wordBreak: "break-all" }}>
                        {item.art.accident && item.art.authorization
                          ? item.art.accident + "-" + item.art.authorization
                          : item.art.accident
                          ? item.art.accident
                          : "NPA: " + item.art.npa}
                      </Td>
                    )}
                    <Td>
                      {DefinitionsModuleTransferType[item.type] === "Módulo"
                        ? "M"
                        : ""}
                      {DefinitionsModuleTransferType[item.type] ===
                      "Módulo con espera"
                        ? "E"
                        : ""}
                      {DefinitionsModuleTransferType[item.type] === "Tramo"
                        ? "T"
                        : ""}
                    </Td>
                    <Td>
                      {(item.status.stage === TransferStage.searching_driver ||
                        item.status.stage ===
                          TransferStage.searching_driver_return) &&
                      item.searching_cabify ? (
                        <b style={{ color: "#0000ff" }}>Buscando Cabify</b>
                      ) : item.status.stage === TransferStage.scheduled &&
                        item.searching_cabify ? (
                        <b style={{ color: "#0000ff" }}>Agendado</b>
                      ) : (
                        moduleTransferStage(item)
                      )}
                    </Td>
                    <Td>{item.art.name}</Td>
                    <Td>
                      {item.status.stage === 12 ? (
                        ""
                      ) : item.transfers ? (
                        <GetFullName item={item} />
                      ) : (
                        ""
                      )}
                    </Td>
                    <Td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY"
                      />{" "}
                      {"\n"}
                      <Moment date={item.origin.time.toDate()} format="HH:mm" />
                      {"-"}
                      <Moment
                        style={{ fontWeight: "bold" }}
                        date={item.destination.time.toDate()}
                        format="HH:mm"
                      />
                    </Td>
                    <Td>{item.passenger.name}</Td>
                    <Td>{getFullAddress(item.origin)}</Td>
                    <Td>{getFullAddress(item.destination)}</Td>
                    {/* <Td>{item.backtrack && getFullAddress(item.backtrack)}</Td> */}
                    <Td>
                      <Link to={viewTransfer(item.id)}>
                        <Button variant="light">Ver</Button>
                      </Link>
                    </Td>
                    <Td>
                      {item.alert ? (
                        <Col md={2}>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              removeAlert(item.id);
                            }}
                          >
                            {contador(item)}
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Td>
                    <Td>{tipitoClicked ? contador(item) : ""}</Td>
                    <Td>
                      {item.tracking ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Quitar seguimiento.
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              await tracking("des", item);
                              window.location.reload(false);
                            }}
                            icon={faEyeSlash}
                            color="red"
                          />
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Poner en seguimiento.
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              await tracking("act", item);
                              window.location.reload(false);
                            }}
                            icon={faEye}
                            color="grey"
                          />
                        </OverlayTrigger>
                      )}
                    </Td>
                    <Td>
                      {(item.status.stage === TransferStage.searching_driver ||
                        item.status.stage ===
                          TransferStage.searching_driver_return) &&
                      item.searching_cabify !== true ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Buscar Cabify.</Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              await searchCabify(item.id, true);
                              window.location.reload(false);
                            }}
                            icon={faTaxi}
                            color="green"
                          />
                        </OverlayTrigger>
                      ) : item.searching_cabify === true ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Cancelar búsqueda de cabify.
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              await searchCabify(item.id, false);
                              window.location.reload(false);
                            }}
                            icon={faTaxi}
                            color="red"
                          />
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}
                    </Td>
                    <Td>
                      {item.status.history.stages.find(
                        (s) => s.description === "Devolución por API"
                      ) ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Devolución por API.
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faDirections}
                            color="green"
                          />
                        </OverlayTrigger>
                      ) : item.status.history.stages.find(
                          (s) => s.description === "ADMIN Devolución por API"
                        ) ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              ADMIN Devolución por API.
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faDirections}
                            color="red"
                          />
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}
                    </Td>
                  </tr>
                ))
              : filteredModule.map((item) => (
                  <tr key={item.id}>
                    {item.tracking ? (
                      <Td
                        style={{
                          wordBreak: "break-all",
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {item.art.accident && item.art.authorization
                          ? item.art.accident + "-" + item.art.authorization
                          : item.art.accident
                          ? item.art.accident
                          : "NPA: " + item.art.npa}
                      </Td>
                    ) : (
                      <Td style={{ wordBreak: "break-all" }}>
                        {item.art.accident && item.art.authorization
                          ? item.art.accident + "-" + item.art.authorization
                          : item.art.accident
                          ? item.art.accident
                          : "NPA: " + item.art.npa}
                      </Td>
                    )}
                    <Td>
                      {DefinitionsModuleTransferType[item.type] === "Módulo"
                        ? "M"
                        : ""}
                      {DefinitionsModuleTransferType[item.type] ===
                      "Módulo con espera"
                        ? "E"
                        : ""}
                      {DefinitionsModuleTransferType[item.type] === "Tramo"
                        ? "T"
                        : ""}
                    </Td>
                    <Td>
                      {(item.status.stage === TransferStage.searching_driver ||
                        item.status.stage ===
                          TransferStage.searching_driver_return) &&
                      item.searching_cabify ? (
                        <b style={{ color: "#0000ff" }}>Buscando Cabify</b>
                      ) : item.status.stage === TransferStage.scheduled &&
                        item.searching_cabify ? (
                        <b style={{ color: "#0000ff" }}>Agendado</b>
                      ) : (
                        moduleTransferStage(item)
                      )}
                    </Td>
                    <Td>{item.art.name}</Td>
                    <Td>
                      {item.status.stage === 12 ? (
                        ""
                      ) : item.transfers ? (
                        <GetFullName item={item} />
                      ) : (
                        ""
                      )}
                    </Td>
                    <Td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY"
                      />{" "}
                      {"\n"}
                      <Moment date={item.origin.time.toDate()} format="HH:mm" />
                      {"-"}
                      <Moment
                        style={{ fontWeight: "bold" }}
                        date={item.destination.time.toDate()}
                        format="HH:mm"
                      />
                    </Td>
                    <Td>{item.passenger.name}</Td>
                    <Td>{getFullAddress(item.origin)}</Td>
                    <Td>{getFullAddress(item.destination)}</Td>
                    {/* <Td>{item.backtrack && getFullAddress(item.backtrack)}</Td> */}
                    <Td>
                      <Link to={viewTransfer(item.id)}>
                        <Button variant="light">Ver</Button>
                      </Link>
                    </Td>
                    <Td>
                      {item.alert ? (
                        <Col md={2}>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              removeAlert(item.id);
                            }}
                          >
                            {contador(item)}
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Td>
                    <Td>{tipitoClicked ? contador(item) : ""}</Td>
                    <Td>
                      {item.tracking ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Quitar seguimiento.
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              await tracking("des", item);
                              window.location.reload(false);
                            }}
                            icon={faEyeSlash}
                            color="red"
                          />
                        </OverlayTrigger>
                      ) : (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Poner en seguimiento.
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              await tracking("act", item);
                              window.location.reload(false);
                            }}
                            icon={faEye}
                            color="grey"
                          />
                        </OverlayTrigger>
                      )}
                    </Td>
                    <Td>
                      {item.status.history.stages.find(
                        (s) => s.description === "Devolución por API"
                      ) ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Devolución por API.
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faDirections}
                            color="green"
                          />
                        </OverlayTrigger>
                      ) : item.status.history.stages.find(
                          (s) => s.description === "ADMIN Devolución por API"
                        ) ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              ADMIN Devolución por API.
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faDirections}
                            color="red"
                          />
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}
                    </Td>
                    <Td>
                      {(item.status.stage === TransferStage.searching_driver ||
                        item.status.stage ===
                          TransferStage.searching_driver_return) &&
                      item.searching_cabify !== true ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Buscar Cabify.</Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              await searchCabify(item.id, true);
                              window.location.reload(false);
                            }}
                            icon={faTaxi}
                            color="green"
                          />
                        </OverlayTrigger>
                      ) : item.searching_cabify === true ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>
                              Cancelar búsqueda de cabify.
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              await searchCabify(item.id, false);
                              window.location.reload(false);
                            }}
                            icon={faTaxi}
                            color="red"
                          />
                        </OverlayTrigger>
                      ) : (
                        ""
                      )}
                    </Td>
                  </tr>
                ))}
          </tbody>
        </Table>
      )}
      <Pagination />
    </Padding>
  );
};

export const GetFullName = ({ item }: { item: ModuleTransfer & WithId }) => {
  if (item.transfers.active && item.transfers.past?.going) {
    if (item.status.doing_return) {
      return (
        <>
          IDA: {""}
          <GetProperty
            doc={FIRESTORE.DOCUMENT_USER(
              item!.transfers!.past!.going![
                item!.transfers!.past!.going!.length - 1
              ].parent.parent!.id
            )}
            property="name"
          />{" "}
          <GetProperty
            doc={FIRESTORE.DOCUMENT_USER(
              item!.transfers!.past!.going![
                item!.transfers!.past!.going!.length - 1
              ].parent.parent!.id
            )}
            property="surname"
          />
          {"\n"}
          VTA:{" "}
          <GetProperty
            doc={FIRESTORE.DOCUMENT_USER(
              item.transfers!.active!.parent!.parent!.id
            )}
            property="name"
          />{" "}
          <GetProperty
            doc={FIRESTORE.DOCUMENT_USER(
              item.transfers!.active!.parent!.parent!.id
            )}
            property="surname"
          />
        </>
      );
    } else {
      return (
        <>
          IDA:{" "}
          <GetProperty
            doc={FIRESTORE.DOCUMENT_USER(
              item.transfers!.active!.parent!.parent!.id
            )}
            property="name"
          />{" "}
          <GetProperty
            doc={FIRESTORE.DOCUMENT_USER(
              item.transfers!.active!.parent!.parent!.id
            )}
            property="surname"
          />
        </>
      );
    }
  }
  if (!item.transfers.active && item.transfers.past?.return) {
    if (item.transfers.past?.going) {
      return (
        <>
          {console.log(item.transfers.past?.going, "ITEM")}; IDA:{" "}
          <GetProperty
            doc={FIRESTORE.DOCUMENT_USER(
              item!.transfers!.past!.going![
                item!.transfers!.past!.going!.length - 1
              ].parent.parent!.id
            )}
            property="name"
          />{" "}
          <GetProperty
            doc={FIRESTORE.DOCUMENT_USER(
              item!.transfers!.past!.going![
                item!.transfers!.past!.going!.length - 1
              ].parent.parent!.id
            )}
            property="surname"
          />
          <br />
          VTA:{""}
          <GetProperty
            doc={FIRESTORE.DOCUMENT_USER(
              item!.transfers!.past!.return![
                item!.transfers!.past!.return!.length - 1
              ].parent.parent!.id
            )}
            property="name"
          />{" "}
          <GetProperty
            doc={FIRESTORE.DOCUMENT_USER(
              item!.transfers!.past!.return![
                item!.transfers!.past!.return!.length - 1
              ].parent.parent!.id
            )}
            property="surname"
          />
        </>
      );
    } else {
      return (
        <>
          MCE: {""}
          <GetProperty
            doc={FIRESTORE.DOCUMENT_USER(
              item!.transfers!.past!.return![
                item!.transfers!.past!.return!.length - 1
              ].parent.parent!.id
            )}
            property="name"
          />{" "}
          <GetProperty
            doc={FIRESTORE.DOCUMENT_USER(
              item!.transfers!.past!.return![
                item!.transfers!.past!.return!.length - 1
              ].parent.parent!.id
            )}
            property="surname"
          />
        </>
      );
    }
  }
  if (!item.transfers.active && item.transfers.past?.going) {
    return (
      <>
        IDA:{" "}
        <GetProperty
          doc={FIRESTORE.DOCUMENT_USER(
            item!.transfers!.past!.going![
              item!.transfers!.past!.going!.length - 1
            ].parent.parent!.id
          )}
          property="name"
        />{" "}
        <GetProperty
          doc={FIRESTORE.DOCUMENT_USER(
            item!.transfers!.past!.going![
              item!.transfers!.past!.going!.length - 1
            ].parent.parent!.id
          )}
          property="surname"
        />
      </>
    );
  }
  if (item.transfers.active && !item.transfers.past) {
    return (
      <>
        IDA:{" "}
        <GetProperty
          doc={FIRESTORE.DOCUMENT_USER(
            item.transfers!.active!.parent!.parent!.id
          )}
          property="name"
        />{" "}
        <GetProperty
          doc={FIRESTORE.DOCUMENT_USER(
            item.transfers!.active!.parent!.parent!.id
          )}
          property="surname"
        />
      </>
    );
  }
  return null;
};

const NotificationsGivebackTransfers = ({
  optionsFilter,
}: {
  optionsFilter: string[];
}) => {
  const items = useDbCollection<ModuleTransfer & WithId>("module_transfer", [
    ["status.stage", "==", TransferStage.waiting_admin_action],
  ]);
  if (isLoaded(items) && !isEmpty(items))
    return (
      <Alert variant="danger">
        <Link
          to={`?filterType=${optionsFilter.findIndex(
            (v) => v === "Esperando Acción"
          )}`}
        >
          Hay traslados esperando acción del admin
        </Link>
      </Alert>
    );
  else return <></>;
};
const tracking = async (orden: string, module: ModuleTransfer & WithId) => {
  const modulesToTrack = (
    await FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where("art.id", "==", module.art.id)
      .where("art.accident", "==", module.art.accident)
      .where("origin.time", ">=", module.origin.time)
      .get()
  ).docs.map((t) => ({ data: t.data(), ref: t.ref }));
  if (orden === "act") {
    const faltantes = modulesToTrack.filter((m) => m.data.tracking !== true);
    await Promise.all(
      faltantes.map(async (f) => {
        await f.ref.update({
          tracking: true,
        });
      })
    );
  } else if (orden === "des") {
    const faltantes = modulesToTrack.filter((m) => m.data.tracking !== false);
    await Promise.all(
      faltantes.map(async (f) => {
        await f.ref.update({
          tracking: false,
        });
      })
    );
  }
};
const searchCabify = async (moduleId: string, status: boolean) => {
  await FIRESTORE.DOCUMENT_MODULE_TRANSFER(moduleId).update({
    searching_cabify: status,
  });
};
const Tr = styled.tr`
  th {
    text-align: center;
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  height: 10px;
`;

const Table = styled(TableB)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default AdminTransfersPage;
