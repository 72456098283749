import {
  AdminTable,
  Button,
  ContainerPage,
  H2,
  NoData,
  Spinner,
} from "components/styles";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import "firebase/firestore";
import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PaymentOrderArt } from "types/billing";
import { usePaginator } from "util/hooks";
import { ArtSelector } from "../Sales/generate-bill";
import { showFbDate } from "util/date";
import moment from "moment";
import { DatePickerInput } from "components/input/date-picker";

const ChargeOrders = () => {
  const [items, loading, Pagination, setColl] = usePaginator<PaymentOrderArt>(
    FIRESTORE.COLLECTION_PAYMENTS_ART(),
    20
  );
  const [artSelected, setArtSelected] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(6, "months").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().add(14, "days").toDate()
  );

  useEffect(() => {
    let col;
    col = FIRESTORE.COLLECTION_PAYMENTS_ART();
    if (artSelected) col = col.where("art", "==", artSelected);

    col = col
      .where("creation_date", ">=", startDate)
      .where("creation_date", "<=", endDate)
      .orderBy("creation_date", "desc");

    setColl(col);
  }, [artSelected, startDate, endDate]);
  console.log(items, "items");

  return (
    <ContainerPage>
      <Row style={{ marginBottom: 15 }}>
        <Col>
          <H2>Pagos de ART Recibidos</H2>
        </Col>
        <Col>
          <Link to={ROUTES.ADMIN_SALES_CHARGE_ADD}>
            <Button className="pull-right">Agregar Cobranza</Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <ArtSelector
            artSelected={artSelected}
            setArtSelected={setArtSelected}
          />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Desde</Form.Label>
            <DatePickerInput value={startDate} onChange={setStartDate} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Hasta (Inclusive)</Form.Label>
            <DatePickerInput value={endDate} onChange={setEndDate} />
          </Form.Group>
        </Col>
      </Row>

      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        <AdminTable responsive>
          <thead>
            <tr>
              <th>Id de cobranza</th>
              <th>Importe</th>
              <th>Fecha</th>
              <th>Op Bancaria</th>
              <th>Número de recibo / cheque</th>
              <th>Ver Info</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>${item.amount.toFixed(2)}</td>
                <td>{showFbDate(item.creation_date)}</td>
                <td>{item.bank_operation}</td>
                <td>{item.receipt}</td>
                <td>
                  <Link to={ROUTES.ADMIN_SALES_ART_PAYMENT(item.id)}>
                    <Button variant="light">Ver</Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </AdminTable>
      )}

      <Pagination />
    </ContainerPage>
  );
};

export default ChargeOrders;
