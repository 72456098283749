import { compose } from "redux";
import { diffInYears } from "util/date";

export const minkLength = (k: number) => (value: any) => {
  if (!RegExp(".{" + k + "}.*").test(value) || !value) {
    return "El campo tiene que tener al menos " + k + " caracteres";
  }
  return undefined;
};
export const forzarPrecioMinYMax = (v: number) => {
  if (v <= 0 || v > 200000) {
    return "El monto debe ser positivo y menor a $200000";
  }
  return undefined;
};
export const forzarPeajeMinYmax = (v: number) => {
  if (v < 0 || v > 10000) {
    return "El monto debe ser positivo y menor a $10000";
  }
  return undefined;
};

export const requireLenght = (k: number) => (values: string | undefined) =>
  values
    ? values.length === k
      ? undefined
      : "Tiene que tener " + k + " caracteres"
    : "No puede estar en blanco";

export const minkLengthNumbers = (k: number) => (value: any) => {
  if (!RegExp("^[0-9]{" + k + ",}$").test(value) || !value) {
    return "El campo tiene que tener al menos " + k + " números";
  }
  return undefined;
};

export const min3Length = minkLength(3);

export const required = (value: any) =>
  value === "" || value === undefined ? "No puede estar en blanco." : undefined;
export const requiredCustom = (text: string) => (value: any) =>
  value === "" || value === undefined ? text : undefined;
export const fileRequired = (value: any) =>
  value ? undefined : "Tiene que subir un archivo.";
export const imageRequired = (value: any) =>
  value ? undefined : "Tiene que subir una foto.";

export const onlyNumbersOrAlphabet = (value: any) => {
  if (!RegExp("^[a-zA-Z0-9]+$").test(value) || !value) {
    return "El campo solo acepta números o letras";
  }
  return undefined;
};

export const futureDate = (errorText: string) => (value: any) => {
  const venDate = new Date(value);
  venDate.setFullYear(venDate.getFullYear() + 1);
  if (venDate < new Date()) {
    return errorText;
  }
  return undefined;
};

export const olderThan = (errorText: string, age: number) => (value: any) =>
  diffInYears(new Date(value), new Date()) < age ? errorText : undefined;

const validarLargoCBU = compose(
  (err: string) => err === undefined,
  minkLength(22)
);

const validarCodigoBanco = (codigo: string) => {
  if (codigo.length !== 8) {
    return false;
  }
  var banco = codigo.substr(0, 3);
  var digitoVerificador1 = codigo[3];
  var sucursal = codigo.substr(4, 3);
  var digitoVerificador2 = codigo[7];

  var suma =
    parseInt(banco[0]) * 7 +
    parseInt(banco[1]) * 1 +
    parseInt(banco[2]) * 3 +
    parseInt(digitoVerificador1) * 9 +
    parseInt(sucursal[0]) * 7 +
    parseInt(sucursal[1]) * 1 +
    parseInt(sucursal[2]) * 3;

  var diferencia = 10 - (suma % 10);
  if (diferencia === 10) diferencia = 0;

  return diferencia === parseInt(digitoVerificador2);
};

const validarCuenta = (cuenta: string) => {
  var digitoVerificador = cuenta[13];
  var suma =
    parseInt(cuenta[0]) * 3 +
    parseInt(cuenta[1]) * 9 +
    parseInt(cuenta[2]) * 7 +
    parseInt(cuenta[3]) * 1 +
    parseInt(cuenta[4]) * 3 +
    parseInt(cuenta[5]) * 9 +
    parseInt(cuenta[6]) * 7 +
    parseInt(cuenta[7]) * 1 +
    parseInt(cuenta[8]) * 3 +
    parseInt(cuenta[9]) * 9 +
    parseInt(cuenta[10]) * 7 +
    parseInt(cuenta[11]) * 1 +
    parseInt(cuenta[12]) * 3;
  var diferencia = 10 - (suma % 10);
  if (diferencia === 10) diferencia = 0;
  return diferencia === parseInt(digitoVerificador);
};

export const validarCBU = (cbu: string) => {
  return validarLargoCBU(cbu) &&
    validarCodigoBanco(cbu.substr(0, 8)) &&
    validarCuenta(cbu.substr(8, 14))
    ? undefined
    : "El cbu/cvu es inválido.";
};
