import React, { useState } from "react";
import {
  ContainerPage,
  PageTitle,
  AsyncButton,
  Link,
  Button,
} from "components/styles";
import { ModuleTransfer } from "types/module-transfer";
import moment from "moment";
import { DatePickerInput } from "components/input/date-picker";
import { Form, Row, Col } from "react-bootstrap";
import {
  ProvincesArgentina,
  ProvincesArgentinaMaps,
  ProvincesArgentinaEscaped,
} from "constants/places-argentina";
import XLSX from "xlsx";
import ROUTES from "constants/routes";
import { ArtBill, BillStatus } from "types/billing";
import { WithId } from "controllers/database";
import { FIRESTORE } from "constants/firestore";
import ExcelJS from "exceljs";
import * as fs from "file-saver";
import { FbDate } from "types/transfer";

const Iibb = () => {
  const [startDate, setStartDate] = useState<Date>(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().endOf("month").toDate()
  );

  return (
    <ContainerPage>
      <Row>
        <Col>
          <PageTitle>Liquidación IIBB</PageTitle>
        </Col>
        <Col>
          <Link to={ROUTES.ADMIN_CONFIGURATION_IIBB}>
            <Button className="pull-right">Cambiar Porcentajes</Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          <Form.Group>
            <Form.Label>Desde</Form.Label>
            <DatePickerInput value={startDate} onChange={setStartDate} />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group>
            <Form.Label>Hasta (Inclusive)</Form.Label>
            <DatePickerInput value={endDate} onChange={setEndDate} />
          </Form.Group>
        </Col>
      </Row>
      <AsyncButton onClick={() => generateXLSXSofIIBB(startDate, endDate)}>
        Generar xlsx
      </AsyncButton>
    </ContainerPage>
  );
};

const generateXLSXSofIIBB = async (startDate: Date, endDate: Date) => {
  // const endDateNextDay = moment(endDate).add(1, "day").toDate();

  // //Obtengo los datos
  // const snap = await FIRESTORE.COLLECTION_MODULES_TRANSFER()
  //     .where("origin.time", ">", startDate)
  //     .where("origin.time", "<", endDateNextDay)
  //     .where("sale.billed", "==", BillStatus.Si)
  //     .orderBy("origin.time")
  //     .orderBy("sale.bill_info")
  //     .get();

  // const iibbData = (await FIRESTORE.DOCUMENT_CONFIGURATION_IIBB().get()).data()!;

  // let data = await Promise.all(
  //     snap.docs
  //         .map((v) => ({ ...v.data(), id: v.id } as ModuleTransfer & WithId))
  //         .map(async (v) => {
  //             if (v.sale?.bill_info?.ref === undefined) throw Error("No tiene bill_info:" + v.id);

  //             const number_province = ProvincesArgentinaMaps.findIndex((v2) => v2 === v.origin.address.province);
  //             if (number_province === -1) throw Error("Provincia no encontrada");

  //             const dataBill = (await v.sale.bill_info.ref.get()).data() as ArtBill;
  //             const dataBillModule = dataBill.modules.find((r) => r.id_module === v.id)!;
  //             const total = Math.ceil(dataBillModule.total);
  //             const alic = iibbData[ProvincesArgentinaEscaped[number_province]];
  //             return {
  //                 Provincia: ProvincesArgentina[number_province],
  //                 "Importe Venta S/IVA": total, //monto traslado + comision + precio tiempo espera
  //                 Alicuota: alic,
  //                 "A Pagar": null, //Asignamos después con una fórmula
  //             };
  //         })
  // );

  // //Junto los valores que tienen misma provincia
  // data = [
  //     ...data
  //         .reduce((r, o) => {
  //             const key = o.Provincia;

  //             const item =
  //                 r.get(key) ||
  //                 Object.assign({}, o, {
  //                     "A Pagar": null, //Asignamos después con una fórmula
  //                     "Importe Venta S/IVA": 0,
  //                     Alicuota: o.Alicuota,
  //                 });

  //             item["Importe Venta S/IVA"] += o["Importe Venta S/IVA"];

  //             return r.set(key, item);
  //         }, new Map())
  //         .values(),
  // ];

  // //Genero XLSX
  // const sheet = XLSX.utils.aoa_to_sheet([
  //     [moment(startDate).format("DD/MM/YY"), moment(endDateNextDay).subtract(1, "days").format("DD/MM/YY")],
  // ]);
  // XLSX.utils.sheet_add_json(sheet, data, { origin: "A3" });
  // XLSX.utils.sheet_add_aoa(sheet, [[undefined, undefined, undefined, undefined]], { origin: -1 }); //Creamos una fila de más para la suma
  // var range = XLSX.utils.decode_range(sheet["!ref"]!);
  // var num_rows = range.e.r - range.s.r + 1;

  // //Generamos la columna A PAGAR
  // for (let x = 4; x < num_rows; x++) {
  //     sheet[`D${x}`] = { t: "n", f: `B${x}*C${x}`, z: "0.00" };
  // }

  // //Realizamos la suma
  // if (data.length > 0) {
  //     sheet[`D${num_rows}`] = { t: "n", f: `sum(D4:D${num_rows - 1})`, z: "0.00" };
  // }

  // var wscols = [{ wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 10 }];
  // sheet["!cols"] = wscols;

  // const book = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(book, sheet);
  // XLSX.writeFile(book, `IIBB_${moment(startDate).format("DD-MM-YY")}_${moment(endDate).format("DD-MM-YY")}.xlsx`);

  const bills = (
    await FIRESTORE.COLLECTION_ARTS_INVOICES()
      .where("creation_date", ">=", FbDate.fromDate(startDate))
      .where("creation_date", "<=", FbDate.fromDate(endDate))
      .get()
  ).docs;
  let workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet("Tutorials");

  worksheet.columns = [
    { header: "Siniestro-Cod.Aut.", key: "sin", width: 10 },
    { header: "Fecha Traslado", key: "fecha", width: 10 },
    { header: "Domicilio Origen", key: "domicilio_or", width: 10 },
    { header: "Localidad Origen", key: "localidad_or", width: 10 },
    { header: "Provincia Origen", key: "provincia_or", width: 10 },
    { header: "Domicilio Destino", key: "domicilio_de", width: 10 },
    { header: "Localidad Destino", key: "localidad_de", width: 10 },
    { header: "Provincia Destino", key: "provincia_de", width: 10 },
    { header: "Nro Factura", key: "nro", width: 10 },
    { header: "Valor Espera", key: "extra_wait_time", width: 25 },
    { header: "Valor Viaje", key: "base", width: 25 },
    { header: "Comisión Agencia", key: "agency", width: 25 },
    { header: "Peajes", key: "tolls", width: 25 },
    { header: "Bonificación", key: "bonif", width: 25 },
    { header: "Total Modulo", key: "total", width: 25 },
    { header: "Total Factura", key: "total_bill", width: 25 },
  ];

  worksheet.getCell("T1").font = {
    underline: false,
    color: { argb: "FF000000" },
  };
  worksheet.getCell("U1").font = {
    underline: false,
    color: { argb: "FF000000" },
  };

  await Promise.all(
    bills.map(async (bill) => {
      const billData = bill.data();
      if (!billData.canceled) {
        await Promise.all(
          billData.modules.map(async (mod) => {
            const module = await FIRESTORE.DOCUMENT_MODULE_TRANSFER(
              mod.id_module
            );
            const data = (
              await FIRESTORE.DOCUMENT_MODULE_TRANSFER(mod.id_module).get()
            ).data();

            worksheet.addRow({
              sin: mod.id_accident + "-" + data?.art.authorization,
              fecha: data?.origin.time
                ? (data?.origin.time).toDate().toISOString()
                : "no hay fecha de origen",
              domicilio_or: `${data?.origin.address.street} ${data?.origin.address.number}`,
              localidad_or: data?.origin.address.district,
              provincia_or: data?.origin.address.province,
              domicilio_de: `${data?.destination.address.street} ${data?.destination.address.number}`,
              localidad_de: data?.destination.address.district,
              provincia_de: data?.destination.address.province,
              nro: billData.point_of_sale + "-" + billData.voucher_number,
              extra_wait_time: mod.extra_wait_time,
              base: mod.base,
              agency: mod.agency_commission,
              tolls: mod.tolls,
              bonif: billData.bonif ? billData.bonif : 0,
              total: mod.total,
              total_bill: billData.ImpTotal,
            });
          })
        );
      }
    })
  );

  const buffer = await workbook.xlsx.writeBuffer();

  console.log(buffer.toString());

  await workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, "resumen-facturas" + ".xlsx");
  });
};

export default Iibb;
