import React from "react";
import { Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { Padding } from "components/styles";

export const SwitchInput = ({ name, value, onBlur, onChange, labelLeft, labelRight, ...props }: any) => (
	<RowSwitch>
		<Padding pad="10px" id="no" style={{color: value ? 'inherit' : '#1286ff'}}>
			{labelLeft}
		</Padding>
		<div>
			<Switch>
				<input
					type="checkbox"
                    id="wheelchair"
                    onClick={(e:any) => onChange(e.target.checked)}
                    defaultChecked={value}
				/>
				<span className="slider round"></span>
			</Switch>
		</div>
		<Padding pad="10px" id="si" style={{color: value ? '#1286ff' : 'inherit' }}>
			{labelRight}
		</Padding>
	</RowSwitch>
);


const RowSwitch = styled(Row)`
	align-items: center;
`;

const Switch = styled.label`
	position: relative;
	display: inline-block;
	width: 40px;
	height: 24px;
	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 16px;
		width: 16px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	input:checked + .slider {
		background-color: #2196f3;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #2196f3;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(16px);
		-ms-transform: translateX(16px);
		transform: translateX(16px);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}
`;