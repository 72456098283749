import firebase from "firebase/app";
import "firebase/firestore"

export interface QuizInfo {
    step : number
}

export const getStepQuiz = (uid : string) : Promise<QuizInfo|undefined> => 
    firebase
        .firestore()
        .collection('user')
        .doc(uid)
        .collection('quiz')
        .doc(uid)
        .get()
        .then((s) => s.data() as QuizInfo|undefined );


export const setStepQuiz = (uid : string, n : number) => {
    firebase
        .firestore()
        .collection('user')
        .doc(uid)
        .collection('quiz')
        .doc(uid)
        .set({step : n})
};