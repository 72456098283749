import "firebase/firestore";
import _ from "lodash";
import { FIRESTORE } from "constants/firestore";

export const getCollectionByType = (type: number) => {
  return FIRESTORE.COLLECTION_USERS()
    .where("step_form_newcomers", "==", type)
    .orderBy("user_code", "desc");
};

export const getCollectionSearchByKeyWords = async (word: string) => {
  const result = (await FIRESTORE.COLLECTION_USERS().get()).docs
    .map((data) => ({ data: data.data(), ref: data.ref }))
    .filter((key) =>
      key.data.surname.toLocaleLowerCase().includes(word.toLocaleLowerCase())
    );
  return result;
};
export const getCollectionUser = () => {
  return FIRESTORE.COLLECTION_USERS().orderBy("user_code", "desc");
};
