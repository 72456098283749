import React from "react";
import { Navbar, Nav, Form, Button } from "react-bootstrap";
import ROUTES from "constants/routes";
import * as R from "react-router-dom";
import styled from "styled-components";
import { Container } from "components/styles";
import { pxToPt } from "constants/css";
import LogoHorizontalImg from "assets/logo-horizontal.png";

class Navigation extends React.Component {
    render() {
        return (
            <Container>
                <Navbar bg="light" expand="lg" className="mainMenu">
                    <Navbar.Brand href="#home">
                        <img src={LogoHorizontalImg} height="40" className="d-inline-block align-top" alt="React Bootstrap logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav className="mr-auto"></Nav>
                        <Nav style={{ marginRight: 8 }}>
                            <NavLink to={ROUTES.ART_HOME}>Inicio</NavLink>
                            <NavLink to={ROUTES.ART_REPORTS}>Informes</NavLink>
                        </Nav>
                        <Form inline>
                            <R.Link to={ROUTES.SIGN_OUT}>
                                <Button variant="dark">Salir</Button>
                            </R.Link>
                        </Form>
                    </Navbar.Collapse>
                </Navbar>
                <hr style={{ marginTop: 0 }} />

                {this.props.children}
            </Container>
        );
    }
}

const NavLink = styled(R.NavLink)`
    padding: ${pxToPt(10)} ${pxToPt(15)};
    color: #777 !important;
    font-size: ${pxToPt(16)} !important;
    &:hover {
        color: #000 !important;
        background-color: #f3f3f3;
        border-radius: ${pxToPt(5)};
        text-decoration: none;
    }
`;

export default Navigation;
