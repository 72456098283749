import firebase from "firebase/app";
import "firebase/firestore";
import { dbEntry } from "util/meta";
import { Location, MapPoint } from "./map";
import { Car } from "./car";
import { Report, TransferEventMotive } from "./report";
import { RevisionStatus, Challenge } from "./user-data";
import { SaleTransfer, ChallengeBilling } from "./billing";

type FbCollection<T = firebase.firestore.DocumentData> =
  firebase.firestore.CollectionReference<T>;
const FbCollection = firebase.firestore.CollectionReference;
type FbQuery<T = firebase.firestore.DocumentData> = firebase.firestore.Query<T>;
const FbQuery = firebase.firestore.Query;
type FbDocument<T = firebase.firestore.DocumentData> =
  firebase.firestore.DocumentReference<T>;
const FbDocument = firebase.firestore.DocumentReference;
type FbDate = firebase.firestore.Timestamp;
const FbDate = firebase.firestore.Timestamp;
type FbGeoPoint = firebase.firestore.GeoPoint;
const FbGeoPoint = firebase.firestore.GeoPoint;
export { FbDocument, FbDate, FbGeoPoint, FbCollection, FbQuery };

@dbEntry
export class TransferRequest {
  type!: TransferType;
  destination!: Meeting;
  origin!: Meeting;
  backtrack?: Meeting;
  amount!: number;
  passenger!: Passenger;
  info!: Omit<TransferInformation, "id">;
  isUrgent?: boolean;
  requireDriver?: boolean;
  obs?: string;
  obs_satapp?: string;
  alert?: boolean;
  module!: FbDocument;
  art!: ArtTransferInfo;
  api?: boolean;
  tolls?: Tolls;
  wheelchair?: boolean;
  coordinated_schedule_admin?: {
    status: boolean;
    minutes: number;
  };
  keywords?: string[];
}

export interface ArtTransferInfo {
  id: string;
  name: string;
  accident?: string;
  expert?: string;
  authorization?: string;
  npa?: string;
}

@dbEntry
export class ScheduledTransfer {
  // extends TransferRequest {
  /**
   * Tiene demora
   */
  isLate?: {
    /**
     * Estado de la demora
     */
    status: boolean;
    /**
     * Minutos demorado
     */
    minutes?: number;
    /**
     * motivo
     */
    motive?: string;
  };
  /**
   * Información sobre el desafío
   */
  challenge?: ChallengeInfo;
  /**
   * Informe Alejado del Origen
   */
  farFromOrigin?: boolean;
  type!: TransferType;
  destination!: Meeting;
  origin!: Meeting;
  backtrack?: Meeting;
  amount!: number;
  passenger!: Passenger;
  info!: TransferInformation;
  link_cabify?: string;
  obs?: string;
  obs_satapp?: string;
  module!: FbDocument;
  requireDriver?: boolean;
  //FIXME: VER COMO HACER QUE ESTA CLASE SE EXTIENDA DEL TRANSFER REQUEST
  //SIN QUE SE ROMPA EL CODIGO DE OBTENER DESDE LA BASE DE DATOS (con el metodo 'database')
  isUrgent?: boolean;
  art!: ArtTransferInfo;
  car?: Car & { ref: FbDocument };
  status!: TransferStatus;
  tolls?: Tolls;
  sale?: SaleTransfer;
  api?: boolean;
  id?: string;
  polylines?: FbGeoPoint[];
  /**
   * Coordinó horario con el pasajero
   */
  coordinated_schedule?: boolean;
  /* Coordino horario con paciente Admin*/
  coordinated_schedule_admin?: {
    status: boolean;
    minutes: number;
  };
  wheelchair?: boolean;
  pool?: Pool[];
}
export interface ChallengeInfo {
  /**
   * Referencia del Desafío
   */
  ref: FbDocument<Challenge>;
  /**
   * Id del desafío
   */
  challenge_id: string;
  /**
   * Multiplicador para facturar a la art
   */
  markup?: number;
  /**
   * Multiplicador al valor base del traslado
   */
  multiplier?: number;
  /**
   * Estado de la facturación del traslado con Desafío
   */
  to_be_billed: ChallengeBilling;
  /**
   * Mínimo garantizado
   */
  guaranteed_minimum?: number;
  /**
   * Tipo de Desafío
   */
  type: ChallengeType;
}
export enum ChallengeType {
  "REGISTRO",
  "MULTIPLICADOR",
  "GARANTIZA MINIMO",
}
export interface TransferStatus {
  stage: TransferStage;
  finished_status?: FinishStatus;
  active_report?: Report;
  history: {
    stages: TransferHistoryRecord[];
    reports?: Report[];
    actions?: TransferHistoryActionRecord[];
  };

  scanned?: {
    going?: {
      identification?: string;
      signature?: string;
    };
    return?: {
      identification: string;
      signature: string;
    };
  };
}

export interface TransferHistoryActionRecord {
  action: TransferEventMotive;
  type: TransferActionType;
  geopoint?: FbGeoPoint;
  time: FbDate;
}

export enum TransferActionType {
  "Devolver",
  "Informe",
}

export enum FinishStatus {
  "Exitoso",
  "Cancelado",
  "Negativo",
  "Incumplimiento",
}

export enum TransferStage {
  "scheduled", //
  "going_to_meeting_point", //
  "waiting_on_meeting_point", //
  "origin_to_destination",
  "waiting_return_request",
  "return_requested",
  "going_to_meeting_point_return",
  "waiting_on_meeting_point_return",
  "destination_to_origin",
  "finished",
  "searching_driver_return", //SOLO USADAS EN MODULO
  "searching_driver_incident", //SOLO USADAS EN MODULO
  "searching_driver", //SOLO USADAS EN MODULO //
  "waiting_admin_action", //SOLO USADAS EN MODULO
  "canceled_on_waiting_return_request", //SOLO USADAS EN MODULO
  "recieved_by_feleval",
  "ended_by_feleval",
  "tolls_and_vouchers_recieved",
  "bill_data_recieved",
}

export enum TransferStageDefinitions {
  "Agendado",
  "Yendo a origen",
  "En origen",
  "De origen al destino",
  "Esperando el pedido de vuelta",
  "Retorno solicitado",
  "Yendo al punto de encuentro a buscar paciente para la vuelta",
  "En punto de encuentro para empezar la vuelta",
  "Paciente en retorno a origen",
  "Finalizado",
  "Buscando conductor para retorno",
  "Buscando conductor a causa de informe",
  "Buscando conductor",
  "Esperando Resolución de Admin",
  "Esperando el pedido de vuelta.",
}

export interface TransferHistoryRecord {
  stage: TransferStage;
  geopoint?: FbGeoPoint;
  time: FbDate;
  description?: string;
}

export enum TransferType {
  SIMPLE_TRIP,
  TRIP_AND_RETURN,
}
export enum DefinitionsTransferType {
  "Tramo",
  "Traslado con módulo espera",
}

export type Meeting = Location & {
  time: FbDate;
};

export interface Passenger {
  dni: number;
  name: string;
  tel: [TelephonePassenger] | string;
}

export interface TelephonePassenger {
  number: string;
  obs: string;
}

export interface Tolls {
  data: Toll[];
  revised: boolean;
}

export interface Toll {
  date_upload: FbDate;
  photo: string;
  amount: number;
  status: RevisionStatus;
  amount_corrected?: number;
  comment_revised?: string;
  doing_return?: boolean;
  /**
   * Nombre del peaje
   */
  name?: string;
  /**
   * concesión
   */
  concession?: string;
  /**
   * Orientación
   */
  direction?: string;
}

export interface TransferInformation {
  /** Distancia origen a destino en metros */
  distance_origin_destination: number;
  /** Distancia destino al retorno en metros */
  distance_destination_backtrack?: number;

  /** Tiempo de origen a destino en minutos */
  duration_origin_destination: number;
  /** Tiempo de destino al retorno en minutos */
  duration_destination_backtrack?: number;

  /** Id por si fue cancelado y republicado */
  republished?: FbDocument;
  /** Traslado del cual se generó este traslado */
  past_transfer?: FbDocument;

  amount?: {
    /**
     * Adicional Cumplimiento
     */
    additional_C?: number;
    /**
     * Adicional Tiempo de espera
     */
    additional_5M?: number;
    /**
     * Adicional 5M
     */
    additional_K?: number;
    //Distancia con la q calcula los km muertos
    distance_k?: number;
    /**
     * Adicional Tiempo de espera
     */
    additional_E?: number;
    /**
     * Adicional Desafío
     */
    addition?: number;
    //Precio del traslado realizdo como pool
    amount_pool?: number;
    additional_M?: number;
    //plus extraorninario
    additional_G?: number;
    type?: TypeAmountTransfer;
    price_km_trip?: number;
    extrapolated_salary?: number;
    salary_coeficient?: number;
    price_minute_trip?: number;
    minimum_wait?: number;
    extra_wait?: number;
    price_minute_wait?: number;
    price_minimum_trip?: number;
    minimum_salary?: number;
    minutes_day?: number;
    base_value?: number;
    custom_zone?: string;

    duration_start_to_report?: number;
    duration_report?: number;
    distance_start_to_origin?: number;
    real_price?: number;
    maximum_price_negative_transfer?: number;
    minimum_price_negative_transfer?: number;
    price_km_short_trip?: number;
  };

  /** Id del traslado actual */
  id?: string;
}

export enum TypeAmountTransfer {
  "Traslado corto",
  "Traslado corto con espera",
  "Traslado largo",
  "Traslado largo con espera",
}
export interface Pool {
  refTransfer: FbDocument<ScheduledTransfer>;
  destination: MapPoint;
  duration: number;
  origin: MapPoint;
}
