import React from "react"

declare type IProps = {
    name : string,
    inline : boolean
}

export const BooleanInput = ({name, inline} : IProps) => {
    
    return (
    <div>
        <Option value={1} 
            name={name} 
            label="Si" 
            inline={inline} />
        <Option value={0} 
            name={name} 
            label="No" 
            inline={inline} />
    </div>)
}

const Option = ({value, name, label, inline} : any) => (
    <div className={"form-check " + (inline? "form-check-inline" : "")}>
        <input
            className="form-check-input"
            type="radio"
            value={value}
            name={name} /> 
        <label className="form-check-label">
            {label}
        </label>
    </div>
)

export default BooleanInput