import FbImage from "components/Primitives/Image/with-modal";
import {
  ContainerPage,
  H3,
  Padding,
  PageTitle,
  Spinner,
  SubmitButton,
} from "components/styles";
import firebase from "firebase/app";
import "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Alert, Col, Form as BForm, FormControl, Row } from "react-bootstrap";
import Moment from "react-moment";
import { useParams } from "react-router";
import { Field, reduxForm } from "redux-form";
import { QuizParams } from "types/module-transfer";
import { ScheduledTransfer, Toll, Tolls } from "types/transfer";
import { RevisionStatus } from "types/user-data";
import { removeUndefined } from "util/meta";
import { getTransfer } from "../../User/Home/reducer";

const AdminTollRevision = () => {
  const { uidUser, idTransfer } = useParams<QuizParams>();
  const [transfer, setTransfer] = useState<ScheduledTransfer | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (uidUser && idTransfer) {
      setLoading(true);
      getTransfer(uidUser, idTransfer)
        .then(setTransfer)
        .then(() => setLoading(false));
    }
  }, []);

  const handleSubmit = async (values: any) => {
    if (!transfer || !transfer.tolls) return;

    const tollsToSubmit = transfer.tolls.data.map((t, index) => {
      const data: Toll = {
        date_upload: t.date_upload,
        photo: t.photo,
        amount: Number(t.amount),
        status:
          values[`toll-${index}`].status === RevisionStatus.Aprobado
            ? values[`toll-${index}`].status
            : values[`toll-${index}`].amount_corrected
            ? RevisionStatus.Aprobado
            : RevisionStatus.Desaprobado,
        comment_revised: values[`toll-${index}`].comment_revised,
        amount_corrected: values[`toll-${index}`].amount_corrected
          ? Number(values[`toll-${index}`].amount_corrected)
          : undefined,
      };
      removeUndefined(data);
      return data;
    });

    await firebase
      .firestore()
      .doc(`user/${uidUser}/transfer/${idTransfer}`)
      .update({
        "tolls.data": tollsToSubmit,
        "tolls.revised": true,
      });
  };

  if (loading) return <Spinner />;

  if (!transfer || !transfer.tolls) return <>Parámetro mal pasado</>;

  return (
    <ContainerPage>
      <PageTitle>Revisión Peajes Traslado #{idTransfer}</PageTitle>

      <H3>Datos del traslado</H3>

      <FormWithRedux
        item={transfer.tolls}
        initialValues={transfer.tolls.data.reduce(
          (obj: any, item, index) => (
            (obj[`toll-${index}`] = {
              status: item.status ? item.status : RevisionStatus.Aprobado,
              comment_revised: item.comment_revised,
              amount_corrected: item.amount_corrected,
            }),
            obj
          ),
          {}
        )}
        onSubmit={handleSubmit}
      />

      <br />
    </ContainerPage>
  );
};

const Form = ({
  submitting,
  handleSubmit,
  submitSucceeded,
  item,
  ...props
}: {
  item: Tolls;
  submitSucceeded: boolean;
  submitting: boolean;
  handleSubmit: any;
  props: any;
}) => {
  if (submitSucceeded) return <Alert variant="success">Actualizado</Alert>;
  return (
    <form onSubmit={handleSubmit}>
      {item.data.map((t, index) => (
        <Padding key={index} pad="10px">
          <Field
            name={`toll-${index}`}
            component={TollField}
            props={{
              data: {
                amount: t.amount,
                photo: t.photo,
                date: t.date_upload.toDate(),
                amount_corrected: t.amount_corrected,
              },
            }}
          />
          <hr />
        </Padding>
      ))}
      <SubmitButton block submitting={submitting}>
        Enviar
      </SubmitButton>
    </form>
  );
};

const TollField = ({
  input: { value, onChange, name },
  data,
  ...props
}: any) => {
  /* 
        data: {
            amount
            photo
            date
        }
        value: {
            status
            amount_corrected
            comment_revised
        }
    */
  return (
    <>
      <Row>
        <Col xl={6}>
          <Row>
            <Col>
              <FbImage key={data.photo} src={data.photo} />
            </Col>
            <Col>
              <span
                style={{ fontSize: 40, fontWeight: "bold", color: "black" }}
              >
                $
                {data.amount_corrected
                  ? data.amount_corrected + "*"
                  : data.amount}
              </span>
              <br />
              <Moment date={data.date} format="DD/MM/YYYY" />
              <br />
              <Moment date={data.date} format="HH:mm" />
            </Col>
          </Row>
        </Col>
        <Col xl={1}>
          <BForm.Check
            custom
            type="checkbox"
            label={""}
            id={name}
            defaultChecked={value.status === RevisionStatus.Aprobado}
            onChange={(e: any) =>
              onChange({
                status: e.target.checked
                  ? RevisionStatus.Aprobado
                  : RevisionStatus.Desaprobado,
                //amount_corrected: value.amount_corrected,
                //comment_revised: value.comment_revised
              })
            }
          />
        </Col>
        <Col>
          {value.status === RevisionStatus.Desaprobado && (
            <>
              <Row style={{ marginBottom: 15 }}>
                <BForm.Group>
                  <BForm.Label>Precio Corregido (opcional)</BForm.Label>
                  <FormControl
                    type="text"
                    placeholder="Precio Corregido"
                    defaultValue={value.amount_corrected}
                    onChange={(e) =>
                      onChange({
                        status: value.status,
                        amount_corrected: e.target.value,
                        comment_revised: value.comment_revised,
                      })
                    }
                  />
                </BForm.Group>
              </Row>
              <Row>
                <BForm.Group>
                  <BForm.Label>Comentario (opcional)</BForm.Label>
                  <FormControl
                    type="text"
                    placeholder="Comentario"
                    as="textarea"
                    defaultValue={value.comment_revised}
                    onChange={(e) =>
                      onChange({
                        status: value.status,
                        amount_corrected: value.amount_corrected,
                        comment_revised: e.target.value,
                      })
                    }
                  />
                </BForm.Group>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

const FormWithRedux = reduxForm<{}, any>({
  form: "tolls",
})(Form);

export default AdminTollRevision;
