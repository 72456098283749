import ROUTES from "constants/routes";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ArtHomePage from "./Home";
import Navigation from "./Navigation";
import ArtPurchases from "./Purchases";
import ArtReportsPage from "./Reports";
import AdminModuleTransfersViewPage from "components/Admin/Transfers/view/module";
import BillingInfo from "components/Admin/Sales/Sales/view-bill";
import AdminModuleFixDestination from "components/Admin/Module_errors/view/fix_destination";
import AdminModuleConfusingAddress from "components/Admin/Module_errors/view/confusing_address";

const Art = () => {
  return (
    <Navigation>
      <Switch>
        <Route exact path={ROUTES.ART_HOME} component={ArtHomePage} />
        <Route exact path={ROUTES.ART_REPORTS} component={ArtReportsPage} />
        <Route exact path={ROUTES.ART_PURCHASES} component={ArtPurchases} />
        <Route path={ROUTES.ART_PURCHASES_BILL()} component={BillingInfo} />
        <Route
          path={ROUTES.ART_TRANSFER_VIEW()}
          component={AdminModuleTransfersViewPage}
        />
        <Route
          path={ROUTES.ART_MODULE_FIX_DIRECTION()}
          component={AdminModuleFixDestination}
        />
        <Route
          path={ROUTES.ART_MODULE_CONFUSING_ADDRESS()}
          component={AdminModuleConfusingAddress}
        />
        <Redirect from="*" to={ROUTES.ART_HOME} />
      </Switch>
    </Navigation>
  );
};

export default Art;
