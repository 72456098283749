import {
  Button,
  H2,
  NoData,
  Padding,
  Spinner,
  AsyncButton,
} from "components/styles";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import React, { useEffect, useState } from "react";
import { Col, Row, Table as TableB, Modal, Form, Alert } from "react-bootstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FbDocument } from "types/transfer";
import MiniNavigationErrors from "../MiniNavigation";
import moment from "moment";
import { GetProperty } from "components/Admin/Sales/Sales/view-bill";
import { showID } from "components/Admin/Sales/Purchases";

const AdminTransfersWrongCrossPage = () => {
  return (
    <MiniNavigationErrors active={2}>
      <MainContainer viewTransfer={ROUTES.ADMIN_MODULE_TRANSFER_VIEW} />
    </MiniNavigationErrors>
  );
};

export const MainContainer = ({
  viewTransfer,
}: {
  viewTransfer: (a: any) => string;
}) => {
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transfers, setTransfers] = useState<any[]>([]);
  useEffect(() => {
    setLoading(true);
    FIRESTORE.COLLECTION_TRANSFERS()
      .where("destination.time", ">=", moment().subtract(60, "days").toDate())
      .where("sale.billed_from_driver", "==", 0)
      .where("isLate.status", "==", true)
      .get()
      .then((get) =>
        get.docs.map((doc) => ({
          ref: doc.ref,
          data: doc.data(),
          uid: (doc.ref.parent.parent as FbDocument).id,
        }))
      )
      .then((docs) =>
        docs.filter(
          (t) =>
            t.uid !== "zdrEGB4N9dQuGBItxAqWlmTcVtn2" &&
            t.data.isLate?.minutes! > 0
        )
      )
      .then((docsData) => setTransfers(docsData))
      .then(() => setLoading(false));
  }, [reload]);

  return (
    <>
      <Padding pad="5px">
        <Row>
          <Col md={12}>
            <H2>Traslados con Demora</H2>
          </Col>
        </Row>

        {loading ? (
          <Spinner style={{ margin: 20 }} />
        ) : transfers.length === 0 ? (
          <NoData />
        ) : (
          <Table hover responsive>
            <thead>
              <Tr>
                <th>Prestador</th>
                <th>Siniestro-Aut/NPA</th>
                <th>Fecha Traslado</th>
                <th></th>
                <th>Ver Traslado</th>
                <th>Autorizar Facturación</th>
              </Tr>
            </thead>
            <tbody>
              {transfers.map((item) => (
                <tr key={item.data.id}>
                  <td>
                    <GetProperty
                      doc={FIRESTORE.DOCUMENT_USER(item.uid)}
                      property="name"
                    />{" "}
                    <GetProperty
                      doc={FIRESTORE.DOCUMENT_USER(item.uid)}
                      property="surname"
                    />{" "}
                  </td>

                  <Td>{showID(item.data)}</Td>
                  <Td>
                    <Moment
                      date={item.data.origin.time.toDate()}
                      format="DD/MM/YYYY"
                    />
                  </Td>
                  <Td>
                    {"isLate" in item.data &&
                      (item.data.isLate?.minutes &&
                      item.data.isLate.minutes > 0 ? (
                        <Col sm="auto">
                          <Alert variant="warning">
                            {" "}
                            {`Viaje con ${item.data.isLate.minutes} minutos de demora`}
                          </Alert>
                        </Col>
                      ) : (
                        ""
                      ))}
                  </Td>
                  <Td>
                    <Link
                      to={ROUTES.ADMIN_MODULE_TRANSFER_VIEW(
                        item.data.module.id
                      )}
                    >
                      <Button variant="light">Ver Traslado</Button>
                    </Link>
                  </Td>
                  <Td>
                    <AsyncButton
                      variant="info"
                      onClick={async () => {
                        await item.ref.update({ "isLate.status": false });
                        setReload(!reload);
                      }}
                    >
                      Autorizar
                    </AsyncButton>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Padding>
    </>
  );
};

export const LocalitySelector = ({
  localitySelected,
  setLocalitySelected,
  localities,
  setDataSelected,
}: {
  localitySelected: string | undefined;
  setLocalitySelected: (a: string) => void;
  localities?: any[];
  setDataSelected: any;
}) => {
  const [cloudLocalities, setCloudLocalities] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!localities) {
      setLoading(true);
      FIRESTORE.COLLECTION_LOCALITIES_SATAPP()
        .get()
        .then((get) => get.docs.map((doc) => doc.data()))
        .then((docs) =>
          docs.sort((a, b) => a.locality.localeCompare(b.locality))
        )
        .then((docsData) => setCloudLocalities(docsData))
        .then(() => setLoading(false));
    } else {
      setCloudLocalities(localities);
    }
  }, []);
  if (loading) return <Spinner />;
  return (
    <Form.Group controlId="exampleForm.SelectCustom">
      <Form.Control
        as="select"
        value={localitySelected}
        onChange={(e) => {
          setLocalitySelected(e.target.value);
          const splited = e.target.value.split(" - ");
          const locality = splited[0];
          const province = splited[1];
          const locality_name = locality.split("-")[0];
          const locality_id = locality.split("-")[1];
          const province_name = province.split("-")[0];
          const province_id = province.split("-")[1];
          const location_satapp = {
            description: locality_name,
            id: locality_id,
            province: {
              description: province_name,
              id: province_id,
            },
          };
          setDataSelected(location_satapp);
        }}
      >
        <option value={-1}>Localidad</option>
        {cloudLocalities?.map((item) => (
          <option value={item.id} key={item.id}>
            {`${item.locality}-${
              item.locality_id
            } - ${item.province.toUpperCase()}-${item.province_id}`}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

const Tr = styled.tr`
  th {
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  word-wrap: break-word;
`;

const Table = styled(TableB)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default AdminTransfersWrongCrossPage;
