import { Field } from "components/input/field";
import {
  Required,
  SubmitButton,
  Padding,
  Center,
  AsyncButton,
} from "components/styles";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Gestorando from "assets/gestorando/HeroGradientDesktop.png";
import { Container } from "react-bootstrap";
import conductor from "assets/gestorando/back_satapp_app-90604a433426f2c339bb6c6c50bfeaee581d869d903524084a77e9756a6f13d3.jpg";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { useSelector } from "react-redux";
import { uidSelector } from "controllers/auth";
import firebase from "firebase/app";

const AfipForm = ({ submitting }: any) => {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 1229px)").matches
  );
  const uid = useSelector(uidSelector);
  const initGestorando = async (uid: string) => {
    console.log("entra");

    const data = (
      await firebase.firestore().collection("user").doc(uid).get()
    ).data();

    let gestorando;
    if (
      data!.delegationGestorando === true ||
      data!.delegationGestorando === false
    ) {
      gestorando = true;
    } else {
      gestorando = false;
    }

    /* if (data!.profile_user_status !== ProfileStatus.DESAPROBADO) {
      return "No tenes Perfil APROBADO";
    } */
    if (
      uid === "bYtlc5cy09VwH8x90aqzXKaUWen1" ||
      uid === "DXLlR4lBALcfvVg63PwM2jSj7nX2"
    ) {
      console.log("No envio la info a Gestorando");
      window.open("https://gestorando.com/satapp/");
    } else if (gestorando === false) {
      console.log("Envio info a Gestorando");
      await CALLABLE_FUNCTIONS.USER_REGISTER_GESTORANDO(uid);
      window.open("https://gestorando.com/satapp/");
    } else {
      console.log("Información ya enviada");
      window.open("https://gestorando.com/satapp/");
    }
  };
  useEffect(() => {
    window
      .matchMedia("(min-width: 1229px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  return (
    <Padding pad="0 0 40%">
      <Container>
        <GralContainer>
          {!matches ? (
            <div
              style={{
                position: "relative",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ flexBasis: "100%", position: "absolute" }}>
                <img
                  src={conductor}
                  width="110%"
                  alt="Conductor"
                  //object-position="bottom"
                  object-fit="contain"
                />
              </div>
              <div style={{ flexBasis: "100%", position: "absolute" }}>
                <img
                  style={
                    {
                      //position: "absolute",
                      //top: "-100px",
                      //marginLeft: -150,
                    }
                  }
                  src={Gestorando}
                  width="110%"
                  alt="Gestorando"
                />
              </div>
              <div
                style={{
                  flexBasis: "100%",
                  position: "absolute",
                  alignSelf: "stretch",
                  WebkitFlexBasis: "5",
                }}
              >
                <h1
                  style={{
                    //position: "absolute",
                    width: "110%",
                    //top: "-80px",
                    color: "#ffffff",
                    //marginLeft: -150,
                    font: "Lato",
                    fontSize: "1em",
                  }}
                >
                  <strong>
                    Registrate en Gestorando para poder facturar tus viajes
                  </strong>
                </h1>
              </div>
              <div
                style={{
                  flexBasis: "100%",
                  position: "absolute",
                  marginTop: "12%",
                  //marginLeft: "10%",
                }}
              >
                <p
                  style={{
                    //position: "absolute",
                    width: "40%",
                    //top: "-20px",
                    color: "#ffffff",
                    font: "Lato",
                    fontSize: "1em",
                    //marginLeft: -60,
                  }}
                >
                  Automatizá tu facturación con Gestorando
                  <strong> sin costo</strong> para recibir el pago de tus
                  viajes.
                </p>
              </div>
              <div
                style={{
                  //flexBasis: "100%",
                  position: "absolute",
                  marginTop: "30%",
                  marginLeft: "75%",
                }}
              >
                <AsyncButton
                  style={{
                    //position: "absolute",
                    //width: "40%",
                    //top: "60px",
                    color: "#ffffff",
                    font: "Lato",
                    fontSize: "1em",
                    //marginLeft: -60,
                  }}
                  variant="primary"
                  onClick={() => initGestorando(uid)}
                >
                  Registrate
                </AsyncButton>
              </div>
            </div>
          ) : (
            <Padding pad="300px">
              <div style={{ position: "relative" }}>
                <img
                  style={{
                    position: "absolute",
                    top: "-270px",
                    marginLeft: -300,
                  }}
                  src={conductor}
                  width="200%"
                  alt="Conductor"
                />

                <img
                  style={{
                    position: "absolute",
                    top: "-300px",
                    marginLeft: -300,
                  }}
                  src={Gestorando}
                  width="200%"
                  alt="Gestorando"
                />

                <h1
                  style={{
                    position: "absolute",
                    width: "100%",
                    top: "-250px",
                    color: "#ffffff",
                    marginLeft: -250,
                    font: "Lato",
                    fontSize: "2em",
                  }}
                >
                  <strong>
                    Registrate en Gestorando para poder facturar tus viajes
                  </strong>
                </h1>

                <p
                  style={{
                    position: "absolute",
                    width: "50%",
                    top: "-150px",
                    color: "#ffffff",
                    font: "Lato",
                    fontSize: "1.2em",
                    marginLeft: -250,
                  }}
                >
                  Automatizá tu facturación con Gestorando{" "}
                  <strong>sin costo</strong> para recibir el pago de tus viajes.
                  Si estás empezando a manejar y necesitás inscribirte en AFIP
                  para facturar, te inscribimos al monotributo de manera
                  gratuita.
                </p>
                <AsyncButton
                  onClick={() => initGestorando(uid)}
                  style={{
                    position: "absolute",
                    width: "40%",
                    top: "60px",
                    color: "#ffffff",
                    font: "Lato",
                    fontSize: "1em",
                    marginLeft: -250,
                  }}
                  variant="primary"
                >
                  Registrate
                </AsyncButton>
              </div>
            </Padding>
          )}
          <Center>
            <SubmitButton type="submit" variant="info" submitting={submitting}>
              Terminar formulario
            </SubmitButton>
          </Center>
        </GralContainer>
      </Container>
    </Padding>
  );
};

const InstructionImage = styled.img`
  width: 90%;
  margin: 0px 0px 20px 0px;
`;

const InstructionText = styled.p`
  font-size: 16px;
  color: black;
  font-weight: bold;
  margin-top: 15px;
`;

const GralContainer = styled.div`
  background-color: white;
  color: #00426a;
  font-family: Quicksand;
`;

export default AfipForm;
