import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Alert, Container, Row, Form, Tooltip } from "react-bootstrap";
import {
  DefinitionsModuleTransferType,
  ModuleTransfer,
  TypeModuleRejectSpanish,
  ModuleTransferType,
} from "types/module-transfer";
import {
  TransferStageDefinitions,
  FinishStatus,
  ScheduledTransfer,
  TransferStage,
} from "types/transfer";
import styled from "styled-components";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { FIRESTORE } from "constants/firestore";
import {
  faPencilAlt,
  faCheck,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { AsyncButton, Button } from "components/styles";
import { BillStatus } from "types/billing";
import { GetProperty } from "components/Admin/Sales/Sales/view-bill";

export const HeaderModule = ({
  module,
  id,
}: {
  module: ModuleTransfer;
  id: string;
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [editModuleState, setEditModuleState] = useState<boolean>(false);
  const [changedSml, setChangedSml] = useState<string | undefined>(
    module.transfer_id
  );
  const changeSmlId = async () => {
    await FIRESTORE.DOCUMENT_MODULE_TRANSFER(id).update({
      transfer_id: changedSml,
    });
    setEdit(false);
  };
  //Lo correcto sería que esto esté en el back
  const changeModuleState = async () => {
    switch (state) {
      case 0:
      case 2:
        await FIRESTORE.DOCUMENT_MODULE_TRANSFER(id).update({
          "sale.billed": BillStatus["No"],
          "status.finished_status": state,
          "status.stage": TransferStage["finished"],
        });
        break;
      case 1:
        await FIRESTORE.DOCUMENT_MODULE_TRANSFER(id).update({
          "sale.billed": BillStatus["No facturar"],
          "status.finished_status": state,
          "status.stage": TransferStage["finished"],
        });
        break;
      case 4:
        await FIRESTORE.DOCUMENT_MODULE_TRANSFER(id).update({
          "status.stage": state,
        });
    }
    setEditModuleState(false);
  };
  const [state, setState] = useState(FinishStatus.Exitoso);

  return (
    <>
      <Container>
        {module.reject?.expertaInvoice !== undefined ? (
          module.reject.expertaInvoice.map((reject) => (
            <Row className="justify-content-md-center">
              <Alert variant="warning">{reject}</Alert>
            </Row>
          ))
        ) : module.reject?.status === true ? (
          <Row className="justify-content-md-center">
            <Alert variant="warning">
              {TypeModuleRejectSpanish[module.reject.type]}
            </Alert>
          </Row>
        ) : (
          ""
        )}
        {module.sale?.billed === 2 && (
          <Row className="justify-content-md-center">
            <Alert variant="danger">MODULE CON VENTA NO FACTURABLE</Alert>
          </Row>
        )}
        {module.status.history.stages.find(
          (s) => s.description === "Devolución por API"
        ) && (
          <Row className="justify-content-md-center">
            <Alert variant="danger">Devolución por API a Feleval</Alert>
          </Row>
        )}
        {module.status.history.stages.find(
          (s) => s.description === "ADMIN Devolución por API"
        ) && (
          <Row className="justify-content-md-center">
            <Alert variant="danger">Devolución manual por API a Feleval</Alert>
          </Row>
        )}
        {module.art.id === "V6WzgUy054XGnhTKGS6HTF44ej52" && (
          <Row className="justify-content-md-center">
            <Alert variant="danger">
              Traslado TEST. NO AGENDAR Cancelar ART sin aviso
            </Alert>
          </Row>
        )}
        {module.priority !== undefined && (
          <Row className="justify-content-md-center">
            <Alert variant="warning">
              Traslado con prioridad de Retorno{" "}
              {
                <GetProperty
                  doc={FIRESTORE.DOCUMENT_USER(module.priority)}
                  property="name"
                />
              }{" "}
              {
                <GetProperty
                  doc={FIRESTORE.DOCUMENT_USER(module.priority)}
                  property="surname"
                />
              }
            </Alert>
          </Row>
        )}

        <Row>
          <span style={{ margin: "auto", fontSize: "1.5em" }}>
            Siniestro{" "}
            <b>
              {`${module.art.accident} ${
                module.art.authorization
              } ${moduleTransferStage(module)} `}
              {!module.transfers.active && (
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faPencilAlt}
                  onClick={() => setEditModuleState(true)}
                />
              )}
            </b>
            {editModuleState && (
              <Row className="justify-content-md-center">
                <Form.Group controlId="formBasicSelect">
                  <Form.Control
                    as="select"
                    value={state}
                    onChange={(e) => {
                      console.log("e.target.value", e.target.value);
                      setState(Number(e.target.value));
                    }}
                  >
                    <option value={FinishStatus.Exitoso}>
                      Finalizado Exitoso
                    </option>
                    <option value={FinishStatus.Cancelado}>
                      Finalizado Cancelado
                    </option>
                    <option value={FinishStatus.Negativo}>
                      Finalizado Negativo
                    </option>
                    {module.type ===
                      ModuleTransferType.TRIP_AND_RETURN_WITH_DIFFERENT_DRIVER && (
                      <option value={TransferStage.waiting_return_request}>
                        Esperando pedido de vuelta
                      </option>
                    )}
                  </Form.Control>
                </Form.Group>
                <AsyncButton variant="success" onClick={changeModuleState}>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faCheck}
                  />
                </AsyncButton>
              </Row>
            )}
            {module.keywords!.find((s) => s === "Verificado por API") && (
              <b>
                <FontAwesomeIcon icon={faCheckCircle} color="green" />
              </b>
            )}
          </span>
        </Row>
        <Row className="justify-content-md-center">
          <span style={{ margin: "auto", fontSize: "1.5em" }}>
            {module.api ? (
              <span>
                <b>API</b>
              </span>
            ) : (
              ""
            )}{" "}
            {"Sml id: "}
            {edit === false ? (
              <>
                <b>{module.transfer_id + " "}</b>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  icon={faPencilAlt}
                  onClick={() => setEdit(true)}
                />
              </>
            ) : (
              <Row className="justify-content-md-center">
                <span>
                  <Form.Control
                    onChange={(e) => setChangedSml(e.target.value)}
                    value={changedSml}
                    type="text"
                  />
                </span>
                <span>
                  <Button onClick={changeSmlId}>
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faCheck}
                    />
                  </Button>
                </span>
              </Row>
            )}
          </span>
        </Row>
        <Row>
          <TripDate>
            <FontAwesomeIcon icon={faCalendarAlt} />{" "}
            {moment(module.origin.time.toDate()).format("DD/MM/YY")} -{" "}
            {_.capitalize(DefinitionsModuleTransferType[module.type])}
            {module.priority ? " - PRIORIDAD" : ""}
          </TripDate>
        </Row>
      </Container>
    </>
  );
};

export const moduleTransferStage = (module: ModuleTransfer) => {
  if (module.status.finished_status === FinishStatus.Negativo) {
    if (module.status.doing_return) return "Finalizado, Retorno Negativo";
    else return "Finalizado Negativo";
  }
  if (module.status.finished_status !== undefined)
    return (
      TransferStageDefinitions[module.status.stage] +
      ` (${FinishStatus[module.status.finished_status]})`
    );

  return TransferStageDefinitions[module.status.stage];
};

export const transferStage = (transfer: ScheduledTransfer) => {
  if (transfer.status?.finished_status === FinishStatus.Negativo) {
    return "Finalizado Negativo";
  }
  if (transfer.status?.finished_status !== undefined)
    return (
      TransferStageDefinitions[transfer.status.stage] +
      ` (${FinishStatus[transfer.status.finished_status]})`
    );
  if (transfer.status?.stage !== undefined) {
    return TransferStageDefinitions[transfer.status.stage];
  }
  return "Error en stage";
};

export const TripDate = styled.div`
  margin: auto;
  margin-top: 5px;
  font-size: 1.2em;
  border: solid 1px #ddd;
  padding: 5px 15px;
`;
