import { List } from "antd";
import { InfoRow, Title } from "components/Admin/Transfers/view/scheduled";
import {
  AdminTable,
  Button,
  ButtonWithConfirmation,
  ContainerPage,
  ErrorPage,
  H3,
  Link,
  PageTitle,
} from "components/styles";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import { useDatabaseDocref } from "controllers/hooks";
import { orderBy } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import Moment from "react-moment";
import { useParams } from "react-router";
import {
  PaymentOrderArt,
  TIPOS_COMPROBANTES,
  TypePayment,
} from "types/billing";
import { GetProperty } from "../Sales/view-bill";
import * as fs from "file-saver";
import { QuizParams } from "types/module-transfer";

const ViewArtPayment = () => {
  const { id } = useParams<QuizParams>();
  const order = useDatabaseDocref<PaymentOrderArt>(
    FIRESTORE.DOCUMENT_PAYMENT_ART(id)
  );
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const userArt = useDatabaseDocref(
    order?.art ? FIRESTORE.DOCUMENT_USER(order.art) : undefined
  );
  const [erasedBill, setErasedBill] = useState(false);

  const returnSelectedBills = async () => {
    if (!order) return;

    const returnBills = [...selected];
    const newBills = order.art_bills.filter(
      (v) => returnBills.indexOf(v.id) === -1
    );

    if (newBills.length > 0) {
      const dataNewBills = await Promise.all(
        newBills.map((v) => v.get().then((v) => v.data()!))
      );
      await FIRESTORE.DOCUMENT_PAYMENT_ART(id).update({
        art_bills: newBills,
        amount: dataNewBills.reduce((a, b) => a + b.ImpTotal, 0),
      });
    } else {
      //No queda ninguna factura, eliminamos el pago
      setErasedBill(true);
      await FIRESTORE.DOCUMENT_PAYMENT_ART(id).delete();
    }

    await Promise.all(
      returnBills.map(async (v) => {
        await FIRESTORE.DOCUMENT_ART_INVOICE(v).update({ payment_order: null });
        return true;
      })
    );
  };

  if (erasedBill) return <Alert variant="warning">Factura eliminada</Alert>;

  if (!order) return <ErrorPage />;

  return (
    <ContainerPage>
      <Row>
        <Col>
          <PageTitle>
            Pago ART #{id}{" "}
            <Moment format="DD/MM/YYYY" date={order.creation_date.toDate()} />
          </PageTitle>
          <Button
            className="pull-right"
            style={{ margin: "0 10px 5px" }}
            onClick={() => {
              generateTxt(order);
            }}
          >
            BAS
          </Button>
        </Col>
      </Row>

      <Row>
        <Title>Detalles</Title>
      </Row>
      <InfoRow label="ART" info={userArt?.name} />
      <InfoRow label="Importe" info={order.amount} />
      <InfoRow label="Operación Bancaria" info={order.bank_operation} />
      <InfoRow label="Tipo de pago" info={TypePayment[order.type]} />
      <InfoRow label="Recibo" info={order.receipt} />

      <Row>
        <Title>Facturas Adjuntas</Title>
      </Row>

      <AdminTable responsive>
        <thead>
          <tr>
            <th>ID de pago</th>
            <th>Número de Factura</th>
            <th>Importe</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {order?.art_bills.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{<GetProperty doc={item} property="voucher_number" />}</td>
              <td>{<GetProperty doc={item} property="ImpTotal" />}</td>
              <td>
                <Link to={ROUTES.ADMIN_SALES_SALES_ART_BILL_INFO(item.id)}>
                  <Button variant="light">Ver</Button>
                </Link>
              </td>
              <td>
                {!selected.has(item.id) && (
                  <Button
                    onClick={() => {
                      const z = new Set(selected);
                      z.add(item.id);
                      setSelected(z);
                    }}
                  >
                    Agregar
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </AdminTable>

      <H3>Facturas seleccionadas</H3>
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={[...selected]}
        renderItem={(item) => (
          <List.Item
            actions={[
              <a
                key="list-loadmore-edit"
                onClick={() => {
                  const z = new Set(selected);
                  z.delete(item);
                  setSelected(z);
                }}
              >
                DESELECCIONAR
              </a>,
            ]}
          >
            <div>{item}</div>
          </List.Item>
        )}
      />
      <Row>
        <Col>
          <ButtonWithConfirmation
            variant="danger"
            className="pull-right"
            onClick={returnSelectedBills}
            confirmText="¿Está seguro que quiere remover las facturas del pago y devolverlas a facturas pendientes de pago?"
            style={{ marginLeft: 10 }}
          >
            Devolver facturas seleccionados a pendientes
          </ButtonWithConfirmation>
        </Col>
      </Row>
    </ContainerPage>
  );
};

const generateTxt = async (orden: PaymentOrderArt) => {
  let data = `${moment(orden?.creation_date.toDate()).format(
    "DD/MM/YYYY"
  )}||||||${orden.amount.toFixed(2)}||L|||||||||`;
  await Promise.all(
    orden.art_bills.map(async (bill) => {
      const billData = (await bill.get()).data();
      data =
        data +
        `
      APL|${
        billData!.type === TIPOS_COMPROBANTES["FACTURA A"]
          ? "FA"
          : billData!.type === TIPOS_COMPROBANTES["FACTURA B"]
          ? "FB"
          : billData!.type === TIPOS_COMPROBANTES["FACTURA C"]
          ? "FC"
          : "FE"
      }|${String(billData?.point_of_sale).padStart(5, "0")}|${String(
          billData?.voucher_number
        ).padStart(8, "0")}|${moment(billData?.expiration_date.toDate()).format(
          "DD/MM/YYYY"
        )}|${billData?.ImpTotal.toFixed(2)}|${
          billData?.cae ? billData.cae : 0
        }`;
    })
  );

  let blob = new Blob([data], { type: "text/plain;charset=utf-8" });
  fs.saveAs(blob, "Pago.txt");
};

export default ViewArtPayment;
