import { Field } from "components/input/field";
import { Button, ContainerPage, H3, Padding, PageTitle, Spinner, SubmitButton } from "components/styles";
import { FIRESTORE } from "constants/firestore";
import { useDatabaseDocref, useDbCollection } from "controllers/hooks";
import { required } from "controllers/validations";
import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import { isEmpty, isLoaded } from "react-redux-firebase";
import { reduxForm } from "redux-form";
import { User, UserType } from "types/user-data";
import { ConfigMaxPriceLocalities } from "./localities";
import { ConfigMaxPriceProvinces } from "./provinces";
import { ConfigMaxPriceZones } from "./zones";

const MaxPrice = () => {
    const [artSelected, setArtSelected] = useState<string | undefined>(undefined);
    const arts = useDbCollection<User>("user", [["type", "==", UserType.art]])

    if (!isLoaded(arts)) return <Spinner />;

    if (isEmpty(arts)) return <>No hay arts</>;

    return (
        <ContainerPage>
            <Padding pad="10px">
                <PageTitle>Máximo precio traslados por ART y ubicación</PageTitle>
                <Form>
                    <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Label>Seleccionar Art</Form.Label>
                        <Form.Control as="select" value={artSelected} onChange={(e) => setArtSelected(e.target.value)}>
                            <option>Seleccionar</option>
                            {arts.map((v) => (
                                <option value={v.id} key={v.id}>
                                    {v.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
                {artSelected && <ViewMaxPrices art={artSelected} />}
            </Padding>
        </ContainerPage>
    );
};

const ViewMaxPrices = ({ art }: { art: string }) => {
    const data = useDatabaseDocref(FIRESTORE.DOCUMENT_CONFIGURATION_MAXPRICE(art));
    const [show, setShow] = useState(false);

    const createIfNotExist = async () => {
        await FIRESTORE.DOCUMENT_CONFIGURATION_MAXPRICE(art).set({});
    };

    useEffect(() => {
        if (data === null) createIfNotExist();
    }, [data]);

    if (!isLoaded(data)) return <Spinner />;

    return (
        <Padding pad="5px" style={{ marginBottom: 10 }}>
            <Row>
                <Col>
                    <H3>Global</H3>
                    {data?.global ? (
                        <>
                            Precio global <b>${data.global}</b>
                        </>
                    ) : (
                        "Precio global no seteado"
                    )}
                    <Button onClick={() => setShow(true)}>Editar</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ConfigMaxPriceLocalities art={art} data={data} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ConfigMaxPriceProvinces art={art} data={data} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ConfigMaxPriceZones art={art} data={data} />
                </Col>
            </Row>
            <span>El máximo precio se determinará por este orden (mayor a menor importancia): zona-localidad-provincia-global</span>
            <ModalChangePrize show={show} art={art} close={() => setShow(false)} />
        </Padding>
    );
};

const ModalChangePrize = ({ art, show, close }: { art: string; show: boolean; close: () => void }) => {
    const updateGlobal = async (v: any) => {
        await FIRESTORE.DOCUMENT_CONFIGURATION_MAXPRICE(art).update({
            global: Number(v.amount),
        });
    };
    return (
        <Modal show={show} onHide={close} size="lg">
            <Modal.Header closeButton>Añadir nueva localidad</Modal.Header>
            <Modal.Body>
                <FormWithRedux onSubmit={updateGlobal} />
            </Modal.Body>
        </Modal>
    );
};
const Form_ = ({ submitting, handleSubmit, submitSucceeded, disabled = false }: any) => {
    if (submitSucceeded) return <Alert variant="success">Modificado</Alert>;
    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col xl={6}>
                    <Field disabled={disabled} labelinput={<>Nuevo Precio Máximo</>} name="amount" type="number" validate={required} />
                </Col>
            </Row>
            <SubmitButton submitting={submitting} disabled={disabled}>
                Guardar
            </SubmitButton>
        </form>
    );
};
const FormWithRedux = reduxForm<{}, any>({ form: "config-form" })(Form_);

export default MaxPrice;
