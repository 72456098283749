import React, { useState, useEffect } from "react";
import { FIRESTORE } from "constants/firestore";
import {
  Spinner,
  NoData,
  H2,
  Padding,
  Button,
  AsyncButton,
  ButtonWithConfirmation,
} from "components/styles";
import styled from "styled-components";
import { Col, Row, Table as BTable } from "react-bootstrap";
import moment from "moment";
import {
  EditCrossModal,
  AddNewCrossModal,
  AdjustPriceModal,
} from "./modalsCross";
import { usePaginator } from "util/hooks";
import { LocalitySelector } from "components/Admin/Module_errors/view/wrong_cross";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import MiniNavigationErrors from "./MiniNavigation";

const AdminCrossPage = () => {
  return (
    <MiniNavigationErrors active={1}>
      <MainContainer />
    </MiniNavigationErrors>
  );
};

export const MainContainer = () => {
  const [loading2, setLoading2] = useState<boolean>(false);
  const [cloudLocalities, setCloudLocalities] = useState<any[]>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showPriceModal, setShowPriceModal] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState({
    modulo: 0,
    negativo: 0,
    tramo: 0,
    kmGoogle: 0,
  });
  const [localityA, setLocalityA] = useState<string | undefined>(undefined);
  const [localityB, setLocalityB] = useState<string | undefined>(undefined);
  const [dataA, setDataA] = useState<
    | {
        description: string;
        id: string;
        province: {
          description: string;
          id: string;
        };
      }
    | undefined
  >(undefined);
  const [dataB, setDataB] = useState<
    | {
        description: string;
        id: string;
        province: {
          description: string;
          id: string;
        };
      }
    | undefined
  >(undefined);
  const [idCross, setIdCross] = useState("");

  const [crosses, loading, Pagination, setCrosses] = usePaginator(
    FIRESTORE.COLLECTION_CROSS_EXPERTA(),
    100
  );

  useEffect(() => {
    setLoading2(true);
    FIRESTORE.COLLECTION_LOCALITIES_SATAPP()
      .get()
      .then((get) => get.docs.map((doc) => doc.data()))
      .then((docs) => docs.sort((a, b) => a.locality.localeCompare(b.locality)))
      .then((docsData) => setCloudLocalities(docsData))
      .then(() => setLoading2(false));
  }, []);

  useEffect(() => {
    setUp();
  }, [dataA, dataB]);

  const revertPrices = async () => {
    await CALLABLE_FUNCTIONS.ADMIN_REVERT_CROSS_PRICES();
    setUp();
  };

  const setUp = () => {
    if (dataA && !dataB) {
      setCrosses(
        FIRESTORE.COLLECTION_CROSS_EXPERTA().where(
          "keywords",
          "array-contains",
          dataA.description
        )
      );
    } else if (dataA && dataB) {
      setCrosses(
        FIRESTORE.COLLECTION_CROSS_EXPERTA().where(
          "index",
          "array-contains",
          `${dataA.id}-${dataB.id}`
        )
      );
    } else if (!dataA && dataB) {
      setCrosses(
        FIRESTORE.COLLECTION_CROSS_EXPERTA().where(
          "keywords",
          "array-contains",
          dataB.description
        )
      );
    } else {
      setCrosses(FIRESTORE.COLLECTION_CROSS_EXPERTA());
    }
  };

  const editCross = (item: any) => {
    setInitialValues({
      modulo: item.modulo,
      tramo: item.tramo,
      negativo: item.negativo,
      kmGoogle: item["Km Google"] ? item["Km Google"] : 0,
    });
    console.log(item);
    setIdCross(item.id);
    setShowEditModal(true);
  };

  if (loading || loading2) return <Spinner />
  return (
    <Padding pad="5px">
      <Row>
        <Col md={2}>
          <H2>Tabla de cruces</H2>
        </Col>
        <Col md={2}>
          Punto A{" "}
          <LocalitySelector
            setDataSelected={setDataA}
            setLocalitySelected={setLocalityA}
            localitySelected={localityA}
            localities={cloudLocalities}
          />
        </Col>
        <Col md={2}>
          Punto B{" "}
          <LocalitySelector
            setDataSelected={setDataB}
            setLocalitySelected={setLocalityB}
            localitySelected={localityB}
            localities={cloudLocalities}
          />
        </Col>
        <Col md={2}>
          <Button
            style={{ marginTop: "9%" }}
            onClick={() => {
              setDataA(undefined);
              setDataB(undefined);
              setLocalityA(undefined);
              setLocalityB(undefined);
            }}
          >
            Limpiar Selección
          </Button>
        </Col>
        <Col md={1}>
          <Button
            variant="success"
            style={{ marginTop: "9%" }}
            onClick={() => setShowAddModal(true)}
          >
            Agregar Cruce
          </Button>
        </Col>
        <Col md={2}>
          <Button
            variant="warning"
            style={{ marginTop: "9%" }}
            onClick={() => setShowPriceModal(true)}
          >
            Ajustar Precios
          </Button>
        </Col>
        <Col md={1}>
          <ButtonWithConfirmation
            variant="success"
            onClick={revertPrices}
            style={{ marginTop: "9%" }}
            confirmText="¿Está seguro que quiere anular y revertir la última actualización de precios? ESTA ACCION NO SE PUEDE DESHACER"
          >
            Revertir Cruces
          </ButtonWithConfirmation>
        </Col>
      </Row>

      {crosses !== undefined && crosses.length === 0 ? (
        <NoData />
      ) : (
        <Table hover responsive>
          <thead>
            <Tr>
              <th>Válido desde</th>
              <th>Válido Hasta</th>
              <th>Id de Cruce</th>
              <th>Ciudad Origen</th>
              <th>Ciudad Destino</th>
              <th>Módulo</th>
              <th>Tramo</th>
              <th>Negativo</th>
              <th>Km Google/Máx</th>
              {/* <th>Facturado</th> */}
              <th>Editar</th>
              <th>Borrar</th>
            </Tr>
          </thead>
          <tbody>
            {crosses !== undefined &&
              crosses.map(
                (item) =>
                  item !== undefined && (
                    <tr key={item.id}>
                      <Td>
                        {moment(item.valid_from.toDate()).format("DD/MM/YY")}
                      </Td>
                      <Td>
                        {item.valid_to
                          ? moment(item.valid_to.toDate()).format("DD/MM/YY")
                          : "-"}
                      </Td>
                      <Td>
                        {item["ID ciudad origen"] +
                          "-" +
                          item["ID ciudad destino"]}
                      </Td>
                      <Td>{item["ciudad origen"].toUpperCase()}</Td>
                      <Td>{item["ciudad destino"].toUpperCase()}</Td>
                      <Td>{item.modulo}</Td>
                      <Td>{item.tramo}</Td>
                      <Td>{item.negativo}</Td>
                      <Td>{item["Km Google"] + " / " + item["Km Max"]}</Td>
                      {/* <Td>true/false</Td> */}
                      <Td>
                        <Button onClick={() => editCross(item)}>Editar</Button>
                      </Td>
                      <Td>
                        <AsyncButton
                          onClick={async () => {
                            await FIRESTORE.DOCUMENT_CROSS_EXPERTA(
                              item.id
                            ).delete();
                            setCrosses(FIRESTORE.COLLECTION_CROSS_EXPERTA());
                          }}
                          variant="danger"
                        >
                          BORRAR
                        </AsyncButton>
                      </Td>
                    </tr>
                  )
              )}
          </tbody>
        </Table>
      )}
      <EditCrossModal
        idCross={idCross}
        show={showEditModal}
        handleClose={() => {
          setShowEditModal(false);
          setUp();
        }}
        amounts={initialValues}
      />
      <AddNewCrossModal
        show={showAddModal}
        handleClose={() => setShowAddModal(false)}
      />
      <AdjustPriceModal
        idCross={idCross}
        show={showPriceModal}
        handleClose={() => {
          setShowPriceModal(false);
          setUp();
        }}
      />
      <Pagination />
    </Padding>
  );
};
const Tr = styled.tr`
  th {
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  height: 10px;
`;

const Table = styled(BTable)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default AdminCrossPage;
