import firebase from "firebase/app";
import "firebase/storage";
import React, { useEffect, useState } from "react";

const FbImage = ({src}:{src:string|undefined}) => {
    if(src === undefined || src === "")
        return <>No hay archivo cargado</>
    return <MainContainer src={src} />
}

const MainContainer = ({src}:{src:string}) => {
    
    const [downloadURL, setDownloadURL] = useState<string|undefined>()

    useEffect(() => {
        firebase.storage().ref(src).getDownloadURL().then( setDownloadURL )
        .catch( console.error )
    }, [])

    if(src === undefined || src === "")
        return <>No hay imagen cargada</>

    if(!downloadURL)
        return <></>

    return <img style={{maxWidth:'100%'}} src={downloadURL} />    
}

export default FbImage