import React from 'react'
import steeringWheel from './assets/steering-wheel.png'
import appointment from './assets/appointment.png'
import valoramos from './assets/valoramos.png'
import ServiceBox from './service-box'
import { Row } from 'react-bootstrap'


const Services = () => (
    <div id="learn-more" className="container-fluid p-5 services">
        <div className="container"> 
            <Row className="justify-content-center">
                <ServiceBox 
                    alt="seguridad"
                    title="Traslados seguros"
                    text="Sé parte de la red de conductores de pacientes de ART más grande del país. Todos los traslados son geolocalizados, los pasajeros identificados y calificados, por nosotros y sus empresas."
                    src={steeringWheel} />
                <ServiceBox 
                    alt="trabajo"
                    title="Valoramos tu trabajo"
                    text="Tenemos el objetivo de alcanzar siempre las mejores tarifas del mercado, y no cobramos comisión. Lo que trabajás, es tuyo."
                    src={valoramos} />
                <ServiceBox 
                    alt="semana"
                    title="Viajes programados"
                    text="Programá los traslados con días de anticipación y asegurá tu semana. También podés aceptar viajes en cualquier momento de tu actividad."
                    src={appointment} />
            </Row>
        </div>
    </div>
)


export default Services;