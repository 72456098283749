import styled from "styled-components";
import * as B from 'react-bootstrap'
import { Link } from "react-router-dom";
import React from "react";
import { pxToPt } from "constants/css";


export const NavButton = ({ to, linkComponent = Link, ...props} : any) => {
    return React.createElement(
        linkComponent, {
            to: to,
            children: <Button {...props}  />
        }
    )
}

export const Button = styled(B.Button)`
    font-family: 'Quicksand';
    font-weight: 700;
    background-color: #00426a !important;
    border-color: #00426a !important;
    color: #fff;
    padding: ${pxToPt(10)};
    border-radius: ${pxToPt(20)};
    text-decoration: none;
    margin-right: ${pxToPt(12)};
    font-size: ${pxToPt(14)};
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: #62D1E1;
    }
`