import {
  Button,
  Center,
  Container,
  FormControl,
  Link,
  Nav,
  Padding,
  Spinner,
  AsyncButton,
} from "components/styles";

import React, { useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import * as B from "react-bootstrap";
import styled from "styled-components";
import Formulario from "./form";

const UnsuscribePage = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xl={5}>
          <Padding pad="20px">
            <Center>
              <img
                alt="logo"
                style={{ height: "150px" }}
                src="../assets/logo-070b76280dc0f019580a20151dd1e9515757bbfa7650b5d4955ec4eea815fab1.png"
              />
            </Center>
          </Padding>
          <Container>
            <B.Container>
              <Widget>
                <Ad>
                  <br />
                  <strong>Quiero darme de baja.</strong>
                  <br />
                  <br />
                  Podes darte de baja cuando quieras.
                  <br />
                  <br />
                  Si eliges <strong>Eliminar tu cuenta</strong> ya no podrás
                  volver a agendar viajes, ni recibirás comunicaciones
                  comerciales.
                  <br />
                  <br />
                  Daremos respuesta a tu solicitud en un plazo máximo de 48 hs.{" "}
                  <br />
                  <br />
                  Siempre que quieras volver, te esperamos con las puestas
                  abiertas.
                </Ad>
                <FormWithRedux />
              </Widget>
            </B.Container>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
const FormWithRedux = reduxForm({
  form: "unsuscribe-form",
})(Formulario);
{
  /* const UnsuscribeForm = ({ handleSubmit, submitSucceeded, submitting }: any) => {
  const [disabled, setDisabled] = useState(true);
  const toggleEdit = () => setDisabled(!disabled);
  const send = async () => console.log("envia");
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        {submitSucceeded && (
          <Alert variant="success" style={{ width: "100%" }}>
            Solicitud enviada
          </Alert>
        )}
        <Form.Group controlId="formBasicEmail">
          <Form.Label>¿Cuál es tu email?</Form.Label>
          <Field name="email" type="email" placeholder="Email" />
        </Form.Group>
        <Form.Label>¿Por qué quieres darte de baja?</Form.Label>
        <Form.Group controlId="formBasicPassword">
          <Field
            name="text"
            type="text"
            placeholder="Contanos el motivo por el que querés cancelar tu cuenta."
          />
        </Form.Group>

        <AsyncButton
          type="submit"
          variant="success"
          style={{ width: 300 }}
          onClick={() => send()}
        >
          Enviar solicitud de baja
        </AsyncButton>
      </Row>
    </form>
  );
}; */
}
const Widget = styled.div`
  padding-top: "10px";
  background-color: #fafafa;
  width: 100%;
  padding: 0 15px 20px 15px;
  border-radius: 20px;
  float: left;
`;
const Ad = styled.h3`
  margin: 0 -15px 25px;
  padding: 20px;
  font-size: 16px;
  font-weight: 500 !important;
  letter-spacing: 1px;
  color: #00426a;
  background-color: #fff;
  border-bottom: 1px solid #00426a;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

export default UnsuscribePage;
