import { FirePoint } from "geofirex";
import { FbDocument, FbDate, ScheduledTransfer } from "./transfer";
import { dbEntry } from "util/meta";
import { Location } from "./map";

@dbEntry
export class User {
  name!: string;
  surname!: string;
  phone!: PhoneNumber;
  email!: string;
  promo_code?: string;
  type?: UserType;
  step_form_newcomers?: StepsFormNewcomers;
  profile_user_status?: ProfileStatus;
  /** Terminos y condiciones completado (completar cada vez que se realiza una alguna modificación del usuario) */
  contract_completed?: boolean;
  active_transfer?: FbDocument<ScheduledTransfer>;
  tokens?: string[];
  gps?: GpsStatusUser;
  user_code?: number;
  account_number?: string;
  bank_entry?: boolean;
  delegation?: boolean;
  delegationGestorando?: boolean;
  /**
   * Detalles del desafío activo
   */
  active_challenge?: UserChallengeBody;
  /**
   * Historial de desafíos
   */
  challenges_history?: UserChallengeBody[];
  //Autorización para pool
  authorizedPool?: boolean;
  authorizedRequestReturn?: boolean;
}
@dbEntry
export class Challenge {
  /**
   * Tipo de desafío
   */
  type!: ChallengeType;
  /**
   * Estado del desafío
   */
  active!: boolean;
  /**
   *Fecha de inicio del desafío
   */
  start_date?: FbDate;
  /**
   * Fecha límite del desafío
   */
  end_date?: FbDate;
  /**
   * Zona del desafío
   */
  zone?: string;
  /**
   * Markup usado para la facturación
   */
  custom_markup?: number;
  /**
   * Multiplicador del valor del transfer
   */
  multiplier?: number;
  /**
   * Monto mínimo garantizado
   */
  guaranteed_minimum?: number;
  /**
   * Traslados requeridos para que se complete el desafío
   */
  transfers_required!: number;
  /**
   * Días activo
   */
  active_days?: number;
  /**
   * Renovarse automáticamente
   */
  renew?: boolean;
}
export type GpsStatusUser = FirePoint & { date: FbDate; heading: number };
export interface UserChallengeBody {
  /**
   * Referencia del desafío
   */
  ref: FbDocument<Challenge>;
  /**
   * Id del desafío
   */
  challenge_id: string;
  /**
   * Estado de la espera de la facturación
   */
  waiting_for_billing: boolean;
  /**
   * Fecha de expiración del desafío
   */
  expiration_date: FbDate;
  /**
   * Progreso del desafío
   */
  progress: number;
}
@dbEntry
export class GpsStatus {
  gps!: FirePoint;
  date!: FbDate;
  heading!: number;
}

export enum ProfileStatus {
  "APROBADO",
  "DESAPROBADO",
  "A REVISAR",
  "REVISADO",
}
export enum ChallengeType {
  "REGISTRO",
  "MULTIPLICADOR",
  "GARANTIZA MINIMO",
}
export interface PhoneNumber {
  area_code: string;
  number: string;
}
export enum UserType {
  regular,
  admin,
  art,
}

export enum RevisionStatus {
  "No revisado",
  "Aprobado",
  "Desaprobado",
}

export enum StepsFormNewcomers {
  beginning,
  profile,
  licence,
  taxable,
  car,
  terms_conditions,
  quiz,
  afip,
  finished,
}

@dbEntry
export class Revision {
  status!: RevisionStatus;
  wrong_fields?: string[];
  date_updated!: FbDate;
  date_revised?: FbDate;
  comment_revised?: string;
}

export type RevisionAdmin = Omit<Revision, "date_updated">;

@dbEntry
export class PersonalInformation {
  name!: string;
  surname!: string;
  idType!: IdType;
  id!: string;
  email?: string;
  birthday!: FbDate;
  country!: string;
  address!: Location;
  address2?: string;
  phone!: PhoneNumber;
  civil_status!: CivilStatus;
  job!: string;
  genre!: Genre;
  criminal_records?: string;
}
export enum CivilStatus {
  "Casado/a",
  "Soltero/a",
  "Divorciado/a",
  "Viudo/a",
}
export enum Genre {
  "Masculino",
  "Femenino",
}
export enum IdType {
  "DNI",
  "CUIT",
}

@dbEntry
export class TaxableEntity {
  typeAccount?: string;
  cbu?: string;
  cvu?: string;
  cuit!: string;
  tax_entity!: TaxableEntityType;
  agency_relation?: string;
  point_of_sale?: string;
  typeBank?: CBU_CVU;
}

export enum TaxableEntityType {
  "Responsable Inscripto",
  "Monotributista",
}

export enum CBU_CVU {
  "CBU",
  "CVU",
}

export enum AccountType {
  "Cuenta Corriente en pesos",
  "Caja de ahorro en pesos",
}
@dbEntry
export class ArtInformation {
  charge_frequency!: ChargeFrequency;
  payment_expiracy_days!: number;
  internal_code!: number;
  operations_mails!: string[];
  administration_mails!: string[];
  billing_mails!: string[];
  sms_enabled?: boolean;
  vat!: VatOptions;
  cuit!: string;
  billing_address!: Location;
}

export enum VatOptions {
  "Iva excento",
  "Iva inscripto",
}

export enum ArtEmailType {
  "OPERACIONAL",
  "ADMINISTRATIVO",
  "COBRANZA",
}

export enum ChargeFrequency {
  "Por Día",
  "Por Viaje",
  "Por Semana",
  "Por Quincena",
  "Por Mes",
  "Manual",
}

@dbEntry
export class Licence {
  number!: string;
  expire_date!: FbDate;
  category!: LicenceCategory;
  photo_front!: string;
  photo_back!: string;
}

export enum LicenceCategory {
  D1,
  D2,
  D3,
  PROFESIONAL,
}

@dbEntry
export class Contract {
  date!: firebase.firestore.Timestamp;
  text!: string;
  validated!: boolean;
}

export interface ContractAdmin {
  id: string;
  email: string;
  name: string;
  surname: string;
  phone: PhoneNumber;
  date: firebase.firestore.Timestamp;
}
