import { Button, Link, Padding, PageTitle, Spinner, H3, AdminTable, QuestionTooltip, ContainerPage } from "components/styles";
import { CALLABLE_FUNCTIONS, OnCallCreateModuleTransfer } from "constants/cloud-functions";
import ROUTES from "constants/routes";
import { useAsyncFunction } from "controllers/hooks";
import React, { useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import XLSX from "xlsx";
import { TransferType } from "types/transfer";
import { removeUndefined } from "util/meta";
import { FIRESTORE } from "constants/firestore";
import { UserType } from "types/user-data";
import Moment from "react-moment";
import { Data } from "@react-google-maps/api";
import { getAddressFromPartial } from "controllers/map";

const AdminNewTransfersExcel = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string[]>([]);
    const [success, setSuccess] = useState<boolean>(false);
    const [transfersData, setTransfersData] = useState<Object[]>([]);

    const readXLSX = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        setLoading(true);
        reader.onload = (event) => {
            const data = new Uint8Array(event.target!.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: "array", cellDates: true });
            const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]) as Object[];
            setTransfersData(jsonData);
            setLoading(false);
        };
        reader.readAsArrayBuffer(file);
    };

    const uploadTransfers = async () => {
        setLoading(true);
        const _errors: string[] = [];

        const getArts = await FIRESTORE.COLLECTION_USERS()
            .where("type", "==", UserType.art)
            .get()
            .then((v) => v.docs)
            .then((v2) => v2.map((v3) => ({ name: v3.data().name, id: v3.id })));
        await Promise.all(
            transfersData.map(async (data: any, index: number) => {
                try {
                    if (
                        data["CUIL/DNI"] === undefined ||
                        data["TELEFONO ACCIDENTADO"] === undefined ||
                        data["ACCIDENTADO"] === undefined ||
                        data["SINIESTRO"] === undefined ||
                        data["CATEGORIA"] === undefined ||
                        data["FECHA DE TURNO"] === undefined
                    )
                        throw Error("Algún campo obligatorio no está completado");

                    const uidArt = getArts.find((v) => v.name === data["ART"])?.id;
                    if (uidArt === undefined) throw Error("No existe art llamada:" + data["ART"]);

                    let addOr;

                    if (data["CALLE Y NUMERO ORIGEN"]) {
                        const res = await getAddressFromPartial(
                            `${data["CALLE Y NUMERO ORIGEN"]} ${data["LOCALIDAD ORIGEN"]} ${data["PROVINCIA ORIGEN"]}`
                        );
                        addOr = res.length > 0 ? res[0] : data["CALLE Y NUMERO ORIGEN"];
                    }
                    let addDes;

                    if (data["CALLE Y NUMERO DESTINO"]) {
                        const res = await getAddressFromPartial(
                            `${data["CALLE Y NUMERO DESTINO"]} ${data["LOCALIDAD DESTINO"]} ${data["PROVINCIA DESTINO"]}`
                        );
                        addDes = res.length > 0 ? res[0] : data["CALLE Y NUMERO DESTINO"];
                    }

                    const formatedData = {
                        origin_date: data["HORA ORIGEN"]
                            ? new Date(
                                  Number("20" + data["FECHA DE TURNO"].split("/")[2]),
                                  data["FECHA DE TURNO"].split("/")[1] - 1,
                                  data["FECHA DE TURNO"].split("/")[0],
                                  data["HORA ORIGEN"].split(":")[0],
                                  data["HORA ORIGEN"].split(":")[1]
                              ).toISOString()
                            : undefined,
                        destination_date: new Date(
                            Number("20" + data["FECHA DE TURNO"].split("/")[2]),
                            Number(data["FECHA DE TURNO"].split("/")[1]) - 1,
                            Number(data["FECHA DE TURNO"].split("/")[0]),
                            Number(data["HORA DE TURNO"].split(":")[0]),
                            Number(data["HORA DE TURNO"].split(":")[1])
                        ).toISOString(),
                        backtrack_date: data["HORA RETORNO"]
                            ? new Date(
                                  Number("20" + data["FECHA DE TURNO"].split("/")[2]),
                                  data["FECHA DE TURNO"].split("/")[1] - 1,
                                  data["FECHA DE TURNO"].split("/")[0],
                                  data["HORA RETORNO"].split(":")[0],
                                  data["HORA RETORNO"].split(":")[1]
                              ).toISOString()
                            : undefined,

                        origin_address: {
                            street: addOr ? addOr.split(",")[0] : undefined,
                            locality: addOr ? addOr.split(",")[1] : undefined,
                            province: addOr ? addOr.split(",")[2] : undefined,
                        },
                        origin_coords:
                            data["LATITUD ORIGEN"] && data["LONGITUD ORIGEN"]
                                ? {
                                      lat: Number(data["LATITUD ORIGEN"]),
                                      lng: Number(data["LONGITUD ORIGEN"]),
                                  }
                                : undefined,
                        destination_address: {
                            street: addDes ? addDes.split(",")[0] : undefined,
                            locality: addDes ? addDes.split(",")[1] : undefined,
                            province: addDes ? addDes.split(",")[2] : undefined,
                        },
                        destination_coords:
                            data["LATITUD DESTINO"] && data["LONGITUD DESTINO"]
                                ? {
                                      lat: Number(data["LATITUD DESTINO"]),
                                      lng: Number(data["LONGITUD DESTINO"]),
                                  }
                                : undefined,

                        passenger: {
                            dni: data["CUIL/DNI"],
                            name: data["ACCIDENTADO"],
                            tel: (data["TELEFONO ACCIDENTADO"] as Number).toString(),
                        },
                        type: data["CATEGORIA"] === "TRAMO" ? 0 : data["CATEGORIA"] === "MODULO CON ESPERA" ? 1 : 2,
                        art_id_accident: String(data["SINIESTRO"]),
                        art_id_expert: data["EXPERTA"],
                        art_id_authorization: data["AUTORIZACION"],
                        art_id_npa: data["NPA"],
                        wheelchair: data["SILLA DE RUEDAS"] === "SI" ? true : false,
                        acom_aut: data["ACOMPAÑANTE"] === "SI" ? true : false,
                        art: uidArt,
                        obs:
                            (data["PISO"] ? `PISO : ${data["PISO"]} ` : "") +
                            (data["DPTO"] ? `DPTO : ${data["DPTO"]} ` : "") +
                            (data["MOTIVO DE TRASLADO"] ? `MOTIVO DE TRASLADO : ${data["MOTIVO DE TRASLADO"]} ` : "") +
                            (data["OBSERVACIONES"] ? `OBSERVACIONES : ${data["OBSERVACIONES"]} ` : ""),
                    };
                    removeUndefined(formatedData);
                    console.log(addOr);
                    await CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_CREATE_EXCEL(formatedData);
                    return true;
                } catch (e) {
                    console.log(e);
                    _errors.push(`Error en la línea ${index} : ${e.message}`);
                }
            })
        );
        setLoading(false);
        if (_errors.length === 0) {
            setSuccess(true);
        } else {
            _errors.push("Los demás traslados fueron creados con éxito.");
            setError(_errors);
        }
    };

    return (
        <ContainerPage>
            <Row>
                <Col>
                    <PageTitle>Nuevo Traslado - Carga desde Excel</PageTitle>
                </Col>
            </Row>
            {success ? (
                <Row>
                    <Col xl={8}>
                        <Alert variant="success">Traslados cargados correctamente</Alert>
                    </Col>
                    <Col xl={4}>
                        <Button
                            variant="info"
                            block
                            onClick={() => {
                                setSuccess(false);
                                setTransfersData([]);
                            }}
                        >
                            Cargar otro excel
                        </Button>
                    </Col>
                </Row>
            ) : loading ? (
                <Spinner />
            ) : error.length > 0 ? (
                <Alert variant="danger">
                    {error.map((err) => (
                        <>
                            {err}
                            <br />
                        </>
                    ))}
                </Alert>
            ) : (
                <>
                    {transfersData.length > 0 ? (
                        <ShowLoadedData data={transfersData} />
                    ) : (
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.File label="Cargar Excel (.xlsx)" onChange={readXLSX} />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col xl={3}>
                            <Link to={ROUTES.ADMIN_NEW_TRANSFER_EXCEL}>
                                <Button block variant="info" onClick={uploadTransfers}>
                                    Cargar
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </>
            )}
            <hr style={{ marginTop: 50 }} />
            <ExampleExcelTable />
        </ContainerPage>
    );
};

const ShowLoadedData = ({ data }: { data: Object[] }) => {
    return (
        <AdminTable responsive>
            <thead>
                <tr>
                    <th>FECHA&nbsp;DE&nbsp;TURNO</th>
                    <th>HORA&nbsp;DE&nbsp;TURNO</th>
                    <th>HORA&nbsp;ORIGEN</th>
                    <th>HORA&nbsp;RETORNO</th>
                    <th>CALLE&nbsp;ORIGEN</th>
                    <th>LOCALIDAD&nbsp;ORIGEN</th>
                    <th>DIRECCION&nbsp;ORIGEN</th>
                    <th>PISO</th>
                    <th>DPTO</th>
                    <th>LATITUD&nbsp;ORIGEN</th>
                    <th>LONGITUD&nbsp;ORIGEN</th>
                    <th>CALLE&nbsp;DESTINO</th>
                    <th>LOCALIDAD&nbsp;DESTINO</th>
                    <th>DIRECCION&nbsp;DESTINO</th>
                    <th>LATITUD&nbsp;DESTINO</th>
                    <th>LONGITUD&nbsp;DESTINO</th>
                    <th>CUIL/DNI</th>
                    <th>ACCIDENTADO</th>
                    <th>TELEFONO&nbsp;ACCIDENTADO</th>
                    <th>CATEGORIA</th>
                    <th>SINIESTRO</th>
                    <th>EXPERTA</th>
                    <th>AUTORIZACION</th>
                    <th>NPA</th>
                    <th>SILLA&nbsp;DE&nbsp;RUEDAS</th>
                    <th>ACOMPAÑANTE</th>
                    <th>MOTIVO&nbsp;DE&nbsp;TRASLADO</th>
                    <th>OBSERVACIONES</th>
                    <th>ART</th>
                </tr>
            </thead>

            <tbody>
                {data.map((v: any, index) => (
                    <tr key={index}>
                        <td>{v["FECHA DE TURNO"]}</td>
                        <td>{v["HORA DE TURNO"]}</td>
                        <td>{v["HORA ORIGEN"]}</td>
                        <td>{v["HORA RETORNO"]}</td>
                        <td>{v["CALLE Y NUMERO ORIGEN"]}</td>
                        <td>{v["LOCALIDAD ORIGEN"]}</td>
                        <td>{v["PROVINCIA ORIGEN"]}</td>
                        <td>{v["PISO"]}</td>
                        <td>{v["DPTO"]}</td>
                        <td>{v["LATITUD ORIGEN"]}</td>
                        <td>{v["LONGITUD ORIGEN"]}</td>
                        <td>{v["CALLE Y NUMERO DESTINO"]}</td>
                        <td>{v["LOCALIDAD DESTINO"]}</td>
                        <td>{v["PROVINCIA DESTINO"]}</td>
                        <td>{v["LATITUD DESTINO"]}</td>
                        <td>{v["LONGITUD DESTINO"]}</td>
                        <td>{v["CUIL/DNI"]}</td>
                        <td>{v["ACCIDENTADO"]}</td>
                        <td>{v["TELEFONO ACCIDENTADO"]}</td>
                        <td>{v["CATEGORIA"]}</td>
                        <td>{v["SINIESTRO"]}</td>
                        <td>{v["EXPERTA"]}</td>
                        <td>{v["AUTORIZACION"]}</td>
                        <td>{v["NPA"]}</td>
                        <td>{v["SILLA DE RUEDAS"]}</td>
                        <td>{v["ACOMPAÑANTE"]}</td>
                        <td>{v["MOTIVO DE TRASLADO"]}</td>
                        <td>{v["OBSERVACIONES"]}</td>
                        <td>{v["ART"]}</td>
                    </tr>
                ))}
            </tbody>
        </AdminTable>
    );
};

const ExampleExcelTable = () => (
    <>
        <H3>Ejemplo de tabla de excel</H3>
        <AdminTable responsive>
            <thead>
                <tr>
                    <th>FECHA&nbsp;ORIGEN</th>
                    <th>FECHA&nbsp;DESTINO</th>
                    <th>FECHA&nbsp;RETORNO</th>
                    <th>DIRECCION&nbsp;ORIGEN</th>
                    <th>DIRECCION&nbsp;DESTINO</th>
                    <th>NOMBRE&nbsp;PASAJERO</th>
                    <th>DNI&nbsp;PASAJERO</th>
                    <th>TELEFONO&nbsp;PASAJERO</th>
                    <th>TIPO&nbsp;TRASLADO</th>
                    <th>SINIESTRO</th>
                    <th>EXPERTA</th>
                    <th>AUTORIZACION</th>
                    <th>NOMBRE&nbsp;ART</th>
                    <th>OBSERVACIONES</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>30/10/2020 18:00</td>
                    <td>30/10/2020 19:00</td>
                    <td>30/10/2020 20:00</td>
                    <td>Av. Cabildo 4300</td>
                    <td>Av. Cabildo 5300</td>
                    <td>Juan Perez</td>
                    <td>40123456</td>
                    <td>12345678</td>
                    <td>
                        0{" "}
                        <QuestionTooltip
                            text={
                                <>
                                    0 = Tramo
                                    <br />1 = Módulo con espera
                                    <br />2 = Módulo
                                </>
                            }
                        />
                    </td>
                    <td>1234</td>
                    <td>5678</td>
                    <td>9123</td>
                    <td>art1</td>
                    <td>Es una casa...</td>
                </tr>
            </tbody>
        </AdminTable>
        <hr style={{ marginTop: 50 }} />
        <b>Aclaraciones</b>
        <ul>
            <li>Las columnas pueden tener distinto orden pero tienen que tener exactamente el mismo nombre que en el ejemplo.</li>
            <li>La tabla tiene que estar posicionada en el A1 de la tabla de la primer hoja del documento.</li>
            <li>Los campos FECHA ORIGEN, FECHA RETORNO, EXPERTA, AUTORIZACION y OBSERVACIONES son opcionales y pueden estar vacíos.</li>
        </ul>
    </>
);

export default AdminNewTransfersExcel;
