import { useSelector } from "react-redux";
import { uidSelector } from "controllers/auth";
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { H2, NoData, Spinner } from "components/styles";
import { FIRESTORE } from "constants/firestore";
import { FbDate, ScheduledTransfer, FbDocument } from "types/transfer";
import moment from "moment";
import TransferCard from "./TransferCard";
import { User } from "types/user-data";
import TransferCardActive from "./TransferCArdActive";


const UserActive = () => {
  const uid = useSelector(uidSelector);

  console.log(uid);
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<
    { data: ScheduledTransfer; ref: FbDocument<ScheduledTransfer> }[]
  >([]);
  const [userInfo, setUserInfo] = useState<User>();

  console.log(userInfo);

  const setTransfers = () => {
    setLoading(true);
    FIRESTORE.DOCUMENT_USER(uid)
      .get()
      .then((doc) => doc.data())
      .then((data) => setUserInfo(data))
      .then(() =>
        FIRESTORE.COLLECTION_USER_TRANSFERS(uid)
          .where(
            "destination.time",
            ">=",
            FbDate.fromDate(moment().startOf("day").toDate())
          )
          .where(
            "destination.time",
            "<",
            FbDate.fromDate(
              moment(moment()).startOf("day").add(1, "day").toDate()
            )
          )
          .get()
      )
      .then((data) => data.docs)
      .then((docs) =>
        docs
          .map((doc) => ({ data: doc.data(), ref: doc.ref }))
          .filter((t) => t.data.status.finished_status === undefined)
      )
      .then((item) => setItems(item))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    setTransfers();
  }, []);

  console.log(items, "item");

  return (
    <div style={{ maxWidth: "100%" }}>
      <Row>
        <Col xs={12}>
          <H2>TRASLADO ACTIVO</H2>
        </Col>
      </Row>

      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        items
          .filter((t) =>
            userInfo !== undefined
              ? t.ref.id === userInfo.active_transfer?.id
              : false
          )
          .map((tranfer) => (
            <div key={tranfer.data.id}>
              <TransferCardActive
                transfer={tranfer}
                uidDriver={uid}
                reload={setTransfers}
              />
            </div>
          ))
      )}
      <br></br>
      <Row>
        <Col xs={12}>
          <H2>Activar traslado App</H2>
        </Col>
      </Row>

      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : userInfo!.authorizedPool === true ? (
        items
          .filter((t) =>
            userInfo !== undefined
              ? t.ref.id !== userInfo.active_transfer?.id
              : false
          )
          .map((tranfer) => (
            <div key={tranfer.data.id}>
              <TransferCard
                transfer={tranfer}
                uidDriver={uid}
                reload={setTransfers}
              />
            </div>
          )) /* : userInfo!.authorizedPool === true &&
        items.some((i) => i.data.status.stage < 3) ? (
        <Row>
          <Col xs={12}>
            <H2>DEBES TENER AL MENOS 1 VIAJE DE ORIGEN A DESTINO</H2>
          </Col>
        </Row>
      )*/
      ) : (
        ""
      )}
      <div
        style={{
          position: "fixed",
          bottom: "3%",
          right: "3%",
        }}
      ></div>
    </div>
  );
};

export default UserActive;
