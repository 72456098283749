import React from "react";
import * as B from "react-bootstrap";
import styled from "styled-components";
import gpsImg from "./assets/gps.png";
import businessmanImg from "./assets/businessman.png";
import stopwatchImg from "./assets/stopwatch.png";
import { pxToPt } from "constants/css";

const WhatWeDo = (props: any) => (
  <Container fluid={true} className="container-fluid border-bottom">
    <div className="container">
      <RemovePaddingOnMobile id="what-we-do" className="col-md-12 row">
        <div className="col-md-3 col-sm-6">
          <About className="about">
            <h5>Sobre</h5>
            <h2>SATAPP</h2>
          </About>
        </div>
        <div className="col-md-9 col-sm-6">
          <StyledActivity
            src={gpsImg}
            description="Somos una empresa argentina dedicada a realizar la logística especializada en el traslado de asegurados de ART a nivel nacional."
          />
          <StyledActivity
            src={businessmanImg}
            description="Nuestra misión es conseguir la mayor eficiencia y el mejor servicio en el momento de trasladar a cada asegurado."
          />
          <StyledActivity
            src={stopwatchImg}
            description="Nuestro objetivo es garantizar que cada traslado sea concretado siempre a tiempo."
          />
        </div>
      </RemovePaddingOnMobile>
    </div>
  </Container>
);

const RemovePaddingOnMobile = styled.div`
  padding-top: 0.2em;
  @media only screen and (min-device-width: 800px) {
    padding-top: 3em;
  }
`;

const Activity = ({ src, description, ...props }: any) => (
  <B.Media {...props} style={{ marginBottom: 30 }}>
    <img className="mr-4 mb-4" src={src} style={{ marginBottom: 0 }} />
    <B.Media.Body style={{ alignSelf: "center" }}>
      <p>{description}</p>
    </B.Media.Body>
  </B.Media>
);

const StyledActivity = styled(Activity)`
  p {
    font-weight: 600 !important;
    line-height: 1;
    /*min-width: 200pt;*/
  }
`;

const About = styled.div`
  h5 {
    color: #d1d1d1;
    text-transform: uppercase;
    font-weight: 600;
  }
  h2 {
    font-weight: 600;
    color: #00426d;
  }
`;

const Container = styled(B.Container)`
  img {
    height: ${pxToPt(64)};
    width: ${pxToPt(64)};
  }
`;

export default WhatWeDo;
