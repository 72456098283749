import { ModuleTransfer } from "types/module-transfer";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import React from "react";
import moment from "moment";
import { AsyncButton } from "components/styles";
import { Meeting } from "types/transfer";
import { FIRESTORE } from "constants/firestore";
import { useSelector } from "react-redux";
import { uidSelector } from "controllers/auth";

const ModuleRequestCard = ({
  data,
  id,
  reload,
}: {
  data: ModuleTransfer;
  id: string;
  reload: () => void;
}) => {
  const uid = useSelector(uidSelector);
  const defineTelephone = (transfer: ModuleTransfer) => {
    if (transfer) {
      if (typeof transfer!.passenger.tel === "string") {
        return transfer!.passenger.tel;
      } else {
        for (let i = 0; i < transfer!.passenger.tel.length; i++) {
          if (
            transfer!.passenger.tel[i].number != "" &&
            transfer!.passenger.tel[i].number != "-"
          ) {
            const phone = transfer.passenger.tel[i].number.replace("-", "");
            return phone;
          }
        }
      }
    }
    return "";
  };

  const schedule = async () => {
    try {
      const moduleRef = (await FIRESTORE.DOCUMENT_MODULE_TRANSFER(id).get())
        .ref;
      await moduleRef.update({
        priority: uid,
      });
      alert("Prioridad agendada");
      setTimeout(() => {
        reload();
      }, 1000);
    } catch (e) {
      setTimeout(() => {
        alert("Viaje no disponible");
        reload();
      }, 1000);
    }
  };

  return (
    <Card border="secondary" style={{ width: "auto", marginTop: "4%" }}>
      <Card.Header style={{ backgroundColor: "#d1d3d4" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              color: "#000",
              fontWeight: "bolder",
            }}
          >
            {dateToText(data.origin.time.toDate())}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexGrow: 1,
            }}
          >
            <span style={{ marginRight: "5%", color: "black" }}>{`${(
              data.info.distance_origin_destination / 1000
            ).toFixed(2)}km`}</span>
            <span style={{ color: "#1877F2", fontWeight: "bold" }}>
              <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    <p>Base ${data.info.price}</p>
                    <p>Peaje: A calcular </p>
                  </Tooltip>
                }
              >
                <p>${data.info.price}</p>
              </OverlayTrigger>
            </span>
          </div>
        </div>
      </Card.Header>
      <Card.Body style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div
            style={{ fontWeight: "bold" }}
          >{`Hora Aprox. de retorno: ${moment(data.destination.time.toDate())
            .add(2, "hours")
            .format("HH:mm")}`}</div>
          <br />
          <br />
          <div
            style={{ fontWeight: "bold" }}
          >{`Teléfono paciente: ${defineTelephone(data)}`}</div>
        </div>

        <div
          style={{
            marginLeft: "2%",
            fontWeight: "bold",
            flexShrink: 2,
          }}
        >
          <div>{getAddress(data.destination)}</div>
          <br />
          <br />
          <div>{getAddress(data.origin)}</div>
        </div>
      </Card.Body>
      <Card.Footer>
        <AsyncButton onClick={schedule} variant="success">
          AGENDAR PRIORIDAD
        </AsyncButton>
      </Card.Footer>
    </Card>
  );
};

const dayToString = (m: number) => {
  if (m == 1) return "Lunes";
  if (m == 2) return "Martes";
  if (m == 3) return "Miércoles";
  if (m == 4) return "Jueves";
  if (m == 5) return "Viernes";
  if (m == 6) return "Sábado";
  if (m == 0) return "Domingo";
  return "Error";
};

const monthToString = (m: number) => {
  if (m == 0) return "enero";
  if (m == 1) return "febrero";
  if (m == 2) return "marzo";
  if (m == 3) return "abril";
  if (m == 4) return "mayo";
  if (m == 5) return "junio";
  if (m == 6) return "julio";
  if (m == 7) return "agosto";
  if (m == 8) return "septiembre";
  if (m == 9) return "octubre";
  if (m == 10) return "noviembre";
  if (m == 11) return "diciembre";
  return "Error";
};

const dateToText = (d: Date) =>
  dayToString(d.getDay()) +
  " " +
  d.getDate() +
  " " +
  monthToString(d.getMonth());

const getAddress = (z: Meeting): string => {
  /* if (z.address.locality?.length == 3 && z.address.number) {
        return `${z.address.street}, ${z.address.number}, CABA, ${z.address.province}`;
      } else */
  const localityToShow =
    z.address.locality && z.address.locality.length <= 3
      ? z.address.district
      : z.address.locality;
  if (z.address.number) {
    return `${z.address.street}, ${z.address.number}, ${localityToShow}, ${z.address.province}`;
  } else if (!z.address.locality && z.address.number) {
    return `${z.address.street}, ${z.address.number}, CABA, ${z.address.province}`;
  } else if (!z.address.number) {
    return `${z.address.street}, S/N, ${localityToShow}, ${z.address.province}`;
  } else {
    return `${z.address.street}, S/N, CABA, ${z.address.province}`;
  }
};

export default ModuleRequestCard;
