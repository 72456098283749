const codigosTelefono : [number, number][] = [
    [11, 15],
    [220, 15],
    [221, 15],
    [223, 15],
    [230, 15],
    [236, 15],
    [237, 15],
    [249, 15],
    [260, 15],
    [261, 15],
    [263, 15],
    [264, 15],
    [266, 15],
    [280, 15],
    [291, 15],
    [294, 15],
    [297, 15],
    [298, 15],
    [299, 15],
    [336, 15],
    [341, 15],
    [342, 15],
    [343, 15],
    [345, 15],
    [348, 15],
    [351, 15],
    [353, 15],
    [358, 15],
    [362, 15],
    [364, 15],
    [370, 15],
    [376, 15],
    [379, 15],
    [380, 15],
    [381, 15],
    [383, 15],
    [385, 15],
    [387, 15],
    [388, 15],
    [2202, 15],
    [2221, 15],
    [2223, 15],
    [2224, 15],
    [2225, 15],
    [2226, 15],
    [2227, 15],
    [2229, 15],
    [2241, 15],
    [2242, 15],
    [2243, 15],
    [2244, 15],
    [2245, 15],
    [2246, 15],
    [2252, 15],
    [2254, 15],
    [2255, 15],
    [2257, 15],
    [2261, 15],
    [2262, 15],
    [2264, 15],
    [2265, 15],
    [2266, 15],
    [2267, 15],
    [2268, 15],
    [2271, 15],
    [2272, 15],
    [2273, 15],
    [2274, 15],
    [2281, 15],
    [2283, 15],
    [2284, 15],
    [2285, 15],
    [2286, 15],
    [2291, 15],
    [2292, 15],
    [2296, 15],
    [2297, 15],
    [2302, 15],
    [2314, 15],
    [2316, 15],
    [2317, 15],
    [2320, 15],
    [2323, 15],
    [2324, 15],
    [2325, 15],
    [2326, 15],
    [2331, 15],
    [2333, 15],
    [2334, 15],
    [2335, 15],
    [2336, 15],
    [2337, 15],
    [2338, 15],
    [2342, 15],
    [2343, 15],
    [2344, 15],
    [2345, 15],
    [2346, 15],
    [2352, 15],
    [2353, 15],
    [2354, 15],
    [2355, 15],
    [2356, 15],
    [2357, 15],
    [2358, 15],
    [2392, 15],
    [2393, 15],
    [2394, 15],
    [2395, 15],
    [2396, 15],
    [2473, 15],
    [2474, 15],
    [2475, 15],
    [2477, 15],
    [2478, 15],
    [2622, 15],
    [2624, 15],
    [2625, 15],
    [2626, 15],
    [2646, 15],
    [2647, 15],
    [2648, 15],
    [2651, 15],
    [2652, 15],
    [2655, 15],
    [2656, 15],
    [2657, 15],
    [2658, 15],
    [2901, 15],
    [2902, 15],
    [2903, 15],
    [2920, 15],
    [2921, 15],
    [2922, 15],
    [2923, 15],
    [2924, 15],
    [2925, 15],
    [2926, 15],
    [2927, 15],
    [2928, 15],
    [2929, 15],
    [2931, 15],
    [2932, 15],
    [2933, 15],
    [2934, 15],
    [2935, 15],
    [2936, 15],
    [2940, 15],
    [2942, 15],
    [2945, 15],
    [2946, 15],
    [2948, 15],
    [2952, 15],
    [2953, 15],
    [2954, 15],
    [2962, 15],
    [2963, 15],
    [2964, 15],
    [2966, 15],
    [2972, 15],
    [2982, 15],
    [2983, 15],
    [3327, 15],
    [3329, 15],
    [3382, 15],
    [3385, 15],
    [3387, 15],
    [3388, 15],
    [3400, 15],
    [3401, 15],
    [3402, 15],
    [3404, 15],
    [3405, 15],
    [3406, 15],
    [3407, 15],
    [3408, 15],
    [3409, 15],
    [3435, 15],
    [3436, 15],
    [3437, 15],
    [3438, 15],
    [3442, 15],
    [3444, 15],
    [3445, 15],
    [3446, 15],
    [3447, 15],
    [3454, 15],
    [3455, 15],
    [3456, 15],
    [3458, 15],
    [3460, 15],
    [3462, 15],
    [3463, 15],
    [3464, 15],
    [3465, 15],
    [3466, 15],
    [3467, 15],
    [3468, 15],
    [3469, 15],
    [3471, 15],
    [3472, 15],
    [3476, 15],
    [3482, 15],
    [3483, 15],
    [3487, 15],
    [3489, 15],
    [3491, 15],
    [3492, 15],
    [3493, 15],
    [3496, 15],
    [3497, 15],
    [3498, 15],
    [3521, 15],
    [3522, 15],
    [3524, 15],
    [3525, 15],
    [3532, 15],
    [3533, 15],
    [3537, 15],
    [3541, 15],
    [3542, 15],
    [3543, 15],
    [3544, 15],
    [3546, 15],
    [3547, 15],
    [3548, 15],
    [3549, 15],
    [3562, 15],
    [3563, 15],
    [3564, 15],
    [3571, 15],
    [3572, 15],
    [3573, 15],
    [3574, 15],
    [3575, 15],
    [3576, 15],
    [3582, 15],
    [3583, 15],
    [3584, 15],
    [3585, 15],
    [3711, 15],
    [3715, 15],
    [3716, 15],
    [3718, 15],
    [3721, 15],
    [3725, 15],
    [3731, 15],
    [3734, 15],
    [3735, 15],
    [3741, 15],
    [3743, 15],
    [3751, 15],
    [3754, 15],
    [3755, 15],
    [3756, 15],
    [3757, 15],
    [3758, 15],
    [3772, 15],
    [3773, 15],
    [3774, 15],
    [3775, 15],
    [3777, 15],
    [3781, 15],
    [3782, 15],
    [3786, 15],
    [3821, 15],
    [3825, 15],
    [3826, 15],
    [3827, 15],
    [3832, 15],
    [3835, 15],
    [3837, 15],
    [3838, 15],
    [3841, 15],
    [3843, 15],
    [3844, 15],
    [3845, 15],
    [3846, 15],
    [3854, 15],
    [3855, 15],
    [3856, 15],
    [3857, 15],
    [3858, 15],
    [3861, 15],
    [3862, 15],
    [3863, 15],
    [3865, 15],
    [3867, 15],
    [3868, 15],
    [3869, 15],
    [3873, 15],
    [3876, 15],
    [3877, 15],
    [3878, 15],
    [3885, 15],
    [3886, 15],
    [3889, 15],
    [3888, 15],
    [3891, 15],
    [3892, 15],
    [3894, 15]
  ];

  export const getValue = (code : [number, number]) => (code[0].toString())
  export const codigoBuenosAires = getValue(codigosTelefono[0])
  export default codigosTelefono;