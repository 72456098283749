import { Spinner, NoData, H2 } from "components/styles";
import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { FbDate, TransferStage, TransferRequest } from "types/transfer";
import moment from "moment";
import { DatePickerInput } from "components/input/date-picker";
import { FIRESTORE } from "constants/firestore";
import { useSelector } from "react-redux";
import { uidSelector } from "redux/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRecycle } from "@fortawesome/free-solid-svg-icons";
import { User } from "types/user-data";
import { doesNotOverlapPool } from "./reducer";
import TransferRequestCard from "./transferRequestCard";

const UserScheduleTransfersPool = () => {
  const uid = useSelector(uidSelector);
  //const uid = "h1jMajBRDpbEXv8u9WxJ8v40sY13";
  const [inputDate, setInputDate] = useState<Date>(
    moment().startOf("day").add(1, "day").toDate()
  );
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [selectedZone, setSelectedZone] = useState<string>("Todas");
  const [userInfo, setUserInfo] = useState<User>();

  //TODO CAMBIAR EL SISTEMA DE ZONAS PARA QUE NO ESTE HARDCODEADO
  const zones = [
    "CABA",
    "LA PLATA",
    "GBA NORTE",
    "GBA SUR",
    "GBA OESTE",
    "Zona Norte Plus",
  ];
  //TODO CAMBIAR EL SISTEMA DE ZONAS PARA QUE NO ESTE HARDCODEADO

  const setTransfers = () => {
    setLoading(true);
    FIRESTORE.DOCUMENT_USER(uid)
      .get()
      .then((doc) => doc.data())
      .then((data) => setUserInfo(data));
    FIRESTORE.COLLECTION_TRANSFER_REQUEST()
      .where("destination.time", ">=", FbDate.fromDate(inputDate))
      .where(
        "destination.time",
        "<",
        FbDate.fromDate(moment(inputDate).endOf("day").toDate())
      )
      .orderBy("destination.time")
      .get()
      .then((data) =>
        data.docs
          .map((d) => ({
            ...d.data(),
            id: d.id,
          }))
          .filter(
            (transfer) =>
              transfer.art.id === "MekXauxMVPd1ayCGpIwWjg7j6Z73" ||
              (transfer.art.id === "GjP1gJT9T1eK0FGe2kfx6o4T80J2" /* ||
              (transfer.art.id === "7tzbTgnqELcQi0AzlEkYGxORsVW2" */ &&
                transfer.wheelchair !== true)
          )
          .filter((transfer) => {
            if (
              selectedZone !== "Todas" &&
              transfer.info.amount?.custom_zone !== selectedZone
            )
              return false;
            else return true;
          })
      )
      .then((filtered) => {
        FIRESTORE.COLLECTION_USER_TRANSFERS(uid)
          .where("status.stage", "==", TransferStage.scheduled)
          .where("origin.time", ">=", FbDate.fromDate(inputDate))
          .where(
            "origin.time",
            "<",
            FbDate.fromDate(moment(inputDate).endOf("day").toDate())
          )
          .get()
          .then((data) =>
            data.docs.map((d) => ({
              ...d.data(),
              id: d.id,
            }))
          )
          .then((scheduled) => scheduled.filter((t) => t.pool === undefined))
          .then((scheduled) => doesNotOverlapPool(filtered, scheduled))
          .then((filtered) => setItems(filtered));
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTransfers();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTransfers();
  }, [inputDate, selectedZone]);
  console.log(items, "items");
  items.map((t) => console.log(t));

  return (
    <div style={{ maxWidth: "100%" }}>
      <Row>
        <Col xs={12}>
          <H2>TRASLADOS COMBINABLES DISPONIBLES</H2>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Label>Buscar por fecha</Form.Label>
          <DatePickerInput
            value={inputDate}
            onChange={setInputDate}
            minDate={moment().startOf("day").add(1, "day").toDate()}
            maxDate={moment().endOf("day").add(28, "days").toDate()}
          ></DatePickerInput>
        </Col>
        <Col xs={6}>
          <Form.Label>Buscar por zona</Form.Label>
          <Form.Control
            as="select"
            value={selectedZone}
            onChange={(e) => {
              setSelectedZone(e.target.value);
            }}
          >
            <option value="Todas">TODAS LAS ZONAS</option>
            {zones.map((z) => (
              <option key={z} value={z}>
                {z}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : userInfo!.authorizedPool === true ? (
        items.map((item) => (
          <div key={item[0]}>
            <TransferRequestCard
              transfers1={item[1]}
              transfers2={item[2]}
              scheduleTransfer={item[4]}
              order={item[3]}
              distance1={item[5]}
              distance2={item[6]}
              distance3={item[7]}
              uid={uid}
              duration={item[8]}
              geoOrigin={item[9]}
              geoDestination={item[10]}
              reload={setTransfers}
            />
          </div>
        ))
      ) : (
        <NoData />
      )}
      <div
        style={{
          position: "fixed",
          bottom: "3%",
          right: "3%",
        }}
      >
        <Button>
          <FontAwesomeIcon icon={faRecycle} onClick={setTransfers} />
        </Button>
      </div>
    </div>
  );
};

export default UserScheduleTransfersPool;
