import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { useAsyncFunction } from "controllers/hooks";
import React, { useEffect } from "react";
import { useParams } from "react-router";

const ChangeCabify = () => {
    const { idModule } = useParams() as { idModule: string };
    const [func, loading, error] = useAsyncFunction(() => CALLABLE_FUNCTIONS.CHANGE_MODULE_CABIFY(idModule));
    useEffect(() => {
        if (idModule !== undefined) func();
    }, []);

    if (idModule === undefined) return <>Link inválido</>;
    if (loading) return <>Generando cambios...</>;
    if (error) return <>{error}</>;
    return (
        <div>
            <p>Hemos cambiado el horario del traslado. </p>
        </div>
    );
};

export default ChangeCabify;
