import { DatePickerInput } from "components/input/date-picker";
import {
  AdminTable,
  Button,
  ContainerPage,
  H2,
  Link,
  NoData,
  QuestionTooltip,
  Spinner,
} from "components/styles";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Moment from "react-moment";
import {
  BillInfoTransfer,
  PaymentOrderDriver,
  SupportedBanks,
} from "types/billing";
import { FbCollection, FbDocument, FbQuery } from "types/transfer";
import { usePaginator } from "util/hooks";
import { SelectDriverInput } from "../Purchases";

const ProviderPayments = () => {
  const [bank, setBank] = useState(SupportedBanks.Credicoop);
  const [driver, setDriver] = useState<string | undefined>(undefined);
  const [keyword, setKeyword] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date>(
    moment().subtract(14, "days").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(
    moment().subtract(1, "day").toDate()
  );
  const [items, loading, Paginator, setCol] = usePaginator<PaymentOrderDriver>(
    getProviderPayments(undefined, undefined, undefined, startDate, endDate)
  );

  useEffect(() => {
    setCol(getProviderPayments(bank, driver, keyword, startDate, endDate));
  }, [driver, bank, keyword, startDate, endDate]);

  return (
    <ContainerPage>
      <Row>
        <Col>
          <H2>Pagos de prestadores</H2>
        </Col>

        <Col>
          <Link to={ROUTES.ADMIN_SALES_DRIVERS_PAYMENTS_GENERATE_PAYMENT}>
            <Button className="pull-right">Ingresar Pago</Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Seleccionar Banco</Form.Label>
            <Form.Control
              as="select"
              value={bank}
              onChange={(e) => setBank(Number(e.target.value))}
            >
              {Object.keys(SupportedBanks)
                .filter((x) => !(parseInt(x) >= 0))
                .map((v) => (
                  <option value={SupportedBanks[v as any]} key={v}>
                    {v}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col style={{ marginTop: 32 }}>
          <SelectDriverInput onChange={setDriver} value={driver} />
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>
              Buscador{" "}
              <QuestionTooltip
                text={
                  <>
                    Número de factura
                    <br />
                    Siniestro
                    <br />
                    Cód. Aut
                    <br />
                    NPA
                  </>
                }
              />
            </Form.Label>
            <Form.Control
              value={keyword}
              onChange={(v) => setKeyword(v.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Desde</Form.Label>
            <DatePickerInput value={startDate} onChange={setStartDate} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Hasta (Inclusive)</Form.Label>
            <DatePickerInput value={endDate} onChange={setEndDate} />
          </Form.Group>
        </Col>
      </Row>
      <AdminTable>
        {loading ? (
          <Spinner style={{ margin: 20 }} />
        ) : items.length === 0 ? (
          <NoData />
        ) : (
          <AdminTable responsive>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Id de pago</th>
                <th>Operación bancaria</th>
                <th>Importe</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.id}>
                  <td>
                    <Moment
                      format="DD/MM/YYYY"
                      date={item.creation_date.toDate()}
                    />
                  </td>
                  <td>{item.id}</td>
                  {/* <td>{item.bills?.map(v => <><GetProperty doc={v} property='voucher_number' /> </>)}</td>
                   */}
                  <td>{item.bank_operation}</td>
                  <td>{item.amount.toFixed(2)}</td>
                  <td>
                    <Link to={ROUTES.ADMIN_SALES_DRIVER_PAYMENT(item.id)}>
                      <Button variant="light">Ver</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </AdminTable>
        )}
      </AdminTable>
      <Paginator />
    </ContainerPage>
  );
};

const ShowAccidentsIds = ({ doc }: { doc: FbDocument<BillInfoTransfer> }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<string[]>([]);
  useEffect(() => {
    doc
      .get()
      .then((v) => v.data()!)
      .then((v) =>
        Promise.all(v.transfers.map((v) => v.get().then((v) => v.data()!)))
      )
      .then((v) => v.map((v) => v.art.accident!))
      .then(setData)
      .then(() => setLoading(false));
  }, [doc]);
  if (loading) return <>Cargando...</>;
  if (!data) return <>ERROR</>;
  return <>{data}</>;
};
const ShowAuthorizationsIds = ({
  doc,
}: {
  doc: FbDocument<BillInfoTransfer>;
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<string[]>([]);
  useEffect(() => {
    doc
      .get()
      .then((v) => v.data()!)
      .then((v) =>
        Promise.all(v.transfers.map((v) => v.get().then((v) => v.data()!)))
      )
      .then((v) =>
        v.map((v) => v.art.authorization!).filter((v) => v !== undefined)
      )
      .then(setData)
      .then(() => setLoading(false));
  }, [doc]);
  if (loading) return <>Cargando...</>;
  if (!data) return <>ERROR</>;
  return <>{data}</>;
};

const getProviderPayments = (
  bank?: SupportedBanks,
  driver?: string,
  keyword?: string,
  startDate?: Date,
  endDate?: Date
) => {
  let col: FbCollection<any> | FbQuery<any> =
    FIRESTORE.COLLECTION_PAYMENTS_DRIVERS();
  if (bank) col = col.where("bank", "==", bank);
  if (driver) col = col.where("driver", "==", driver);
  if (keyword) col = col.where("keywords", "array-contains", keyword);

  return col
    .where("creation_date", ">=", startDate)
    .where("creation_date", "<=", endDate)
    .orderBy("creation_date", "desc");
};

export default ProviderPayments;
