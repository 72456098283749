import ROUTES from "constants/routes";
import firebase from "firebase/app";
import "firebase/firestore"
import React from "react";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { Redirect } from "react-router";

const SignOut = () => {

    const auth = useSelector( (state:any) => state.firebase.auth)
    firebase.auth().signOut()

    if( isLoaded(auth) && isEmpty(auth) )
        return <Redirect to={{pathname:ROUTES.SIGN_IN, state:{loggedOut:true}}} />
    else
        return <div>Loading...</div>
}

export default SignOut