import { TransferRequest, ScheduledTransfer } from "types/transfer";
import _ from "lodash";
import moment from "moment";

export const doesNotOverlap = (
  transferRequests: { data: TransferRequest; id: string }[],
  scheduledTransfers: ScheduledTransfer[]
) => {
  return _.filter(transferRequests, function (transferRequest) {
    return doesntOverlapWithAnotherTransfer(
      transferRequest.data,
      scheduledTransfers
    );
  });
};

const doesntOverlapWithAnotherTransfer = (
  transferRequest: TransferRequest,
  scheduledTransfers: ScheduledTransfer[]
) => {
  let allowedToSchedule: boolean = true;

  for (let i = 0; i < scheduledTransfers.length; i++) {
    const scheduled = scheduledTransfers[i];

    let travel1, travel2;
    if (
      moment(scheduled.destination.time.toDate()) <
      moment(transferRequest.destination.time.toDate())
    ) {
      travel1 = scheduled;
      travel2 = transferRequest;
    } else if (
      moment(scheduled.destination.time.toDate()) >
      moment(transferRequest.destination.time.toDate())
    ) {
      travel1 = transferRequest;
      travel2 = scheduled;
    } else {
      if (
        transferRequest.info.duration_origin_destination >
        scheduled.info.duration_origin_destination
      ) {
        travel1 = transferRequest;
        travel2 = scheduled;
      } else {
        travel1 = scheduled;
        travel2 = transferRequest;
      }
    }

    const linealDistance = getDistanceFromLatLonInKm(
      travel1.destination.geopoint.latitude,
      travel1.destination.geopoint.longitude,
      travel2.origin.geopoint.latitude,
      travel2.origin.geopoint.longitude
    );
    const durationDestination1ToOrigin2 = (linealDistance * 1.4 * 60) / 40;
    if (scheduled.isUrgent) {
      if (transferRequest.isUrgent) {
        // Ya agendado
        // contra
        // Ya Tranfer Request
        // H.Destino1 + Dist.O1~D2 <= H.Destino2 - Dur.2

        allowedToSchedule =
          moment(travel1.destination.time.toDate()).add(
            durationDestination1ToOrigin2,
            "minutes"
          ) <=
          moment(travel2.destination.time.toDate()).subtract(
            travel2.info.duration_origin_destination,
            "minutes"
          );

        if (allowedToSchedule === false) {
          break;
        }
      } else {
        // Ya agendado
        // contra
        // Programado
        // H.Destino1 + Dist.O1~D2 <= H.Destino2 - Dur.2

        allowedToSchedule =
          moment(travel1.destination.time.toDate()).add(
            durationDestination1ToOrigin2,
            "minutes"
          ) <=
          moment(travel2.destination.time.toDate()).subtract(
            travel2.info.duration_origin_destination,
            "minutes"
          );

        if (allowedToSchedule === false) {
          break;
        }
      }
    } else {
      if (transferRequest.isUrgent) {
        //PROGRAMADO CONTRA YA
        // H.Destino1 + Dist.O1~D2 <= H.Destino2 - Dur.2

        allowedToSchedule =
          moment(travel1.destination.time.toDate()).add(
            durationDestination1ToOrigin2,
            "minutes"
          ) <=
          moment(travel2.destination.time.toDate()).subtract(
            travel2.info.duration_origin_destination,
            "minutes"
          );

        if (allowedToSchedule === false) {
          break;
        }
      } else {
        //PROGRAMADO CONTRA PROGRAMADO

        const buffer =
          travel1.info.duration_origin_destination <= 15
            ? 30
            : travel1.info.duration_origin_destination <= 30
            ? 20
            : 0;

        const totalTravelTime =
          travel1.info.duration_origin_destination +
          travel2.info.duration_origin_destination +
          durationDestination1ToOrigin2 +
          buffer;

        allowedToSchedule =
          moment(travel2.destination.time.toDate()).subtract(
            totalTravelTime,
            "minutes"
          ) >= moment(travel1.origin.time.toDate());

        if (allowedToSchedule === false) {
          break;
        }
      }
    }
  }

  return allowedToSchedule;
};

const getDistanceFromLatLonInKm = (
  lat1: any,
  lon1: any,
  lat2: any,
  lon2: any
) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
};

const deg2rad = (deg: any) => deg * (Math.PI / 180);
