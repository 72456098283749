import { Field } from "components/input/field"
import { Button, Padding, Spinner, SubmitButton, H2 } from "components/styles"
import { ProvincesArgentina, ProvincesArgentinaEscaped } from "constants/places-argentina"
import { useAsyncValue } from "controllers/hooks"
import { required } from "controllers/validations"
import firebase from "firebase/app"
import "firebase/firestore"
import _ from "lodash"
import React, { useCallback, useState } from "react"
import { Alert, Col, Row } from "react-bootstrap"
import { reduxForm } from "redux-form"
import { FIRESTORE } from "constants/firestore"

const EditIibb = () => {
    const onSubmit = async (values:object) => {
        await FIRESTORE.DOCUMENT_CONFIGURATION_IIBB().set(_.mapValues(values as any, v => Number(v)))
    }

    const loadData = useCallback(async () => FIRESTORE.DOCUMENT_CONFIGURATION_IIBB().get().then(v => v.data()), [])
    const [initialValues, loading] = useAsyncValue(loadData)
    
    return (
        <Padding pad='10px'>
            {loading ? 
                <Spinner /> 
            :
                <MainFormRedux onSubmit={onSubmit} initialValues={initialValues ? initialValues : {}}/>
            }
        </Padding>
)}

const MainForm = ({handleSubmit, submitting, submitSucceeded}:any) => {
    const [disabled, setDisabled] = useState(true)
    const toggleEdit = () => setDisabled(!disabled)

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <H2>Ingresos Brutos (Porcentajes)</H2>
                </Col>
                <Col>
                {disabled ? 
                    <Button className="pull-right" style={{margin:"0 10px 5px"}} variant="info" onClick={toggleEdit}>
                        Editar
                    </Button>
                :
                <>
                    <Button className="pull-right" style={{margin:"0 10px 5px"}} variant="info" onClick={toggleEdit}>
                        Deshabilitar
                    </Button>
                    
                    <SubmitButton submitting={submitting} className="pull-right" style={{margin:"0 10px 5px"}} variant="dark" >
                        Guardar
                    </SubmitButton>
                </>
                }
                </Col>
            </Row>
            <Row>
                {submitSucceeded && <Alert variant="success" style={{width:'100%'}}>Valores Guardados</Alert>}
            </Row>
            <Row>
            {ProvincesArgentinaEscaped.map((v,index) => 
                <Col lg={4} key={v}>
                    <Field 
                        disabled={disabled}
                        labelinput={<>{ProvincesArgentina[index]}</>}
                        name={v}
                        type="number"
                        validate={required}/>
                </Col>
            )}
            </Row>
        </form>
    )
}

const MainFormRedux = reduxForm({form: "iibb"})(MainForm);

export default EditIibb