import React from 'react'
import {Carousel} from 'react-bootstrap'
import BookingForm from '../registration-widget/form'
import styled from 'styled-components';
import backSatappWomanImg from './assets/back_satapp_woman.jpg'
import backSatappManImg from './assets/back_satapp_man.jpg'
import backSatappAppImg from './assets/back_satapp_app.jpg'
import RegistrationWidget from 'components/Landing/registration-widget'

const PhotoSlider = (props: any) => (
    <MainContainer className="container-fluid photos-slider2 p-0">
        <Carousel className="slide" controls={false} indicators={false} interval={4000} data-ride="carousel">     
            <CarouselItem 
                src={backSatappAppImg}
                msg="Traslados programados, y para el momento." />
            <CarouselItem 
                src={backSatappManImg}
                msg="Las mejores tarifas del mercado." />
            <CarouselItem 
                src={backSatappWomanImg}
                msg="Tus viajes, libres de comisión." />
            
        </Carousel>
        <RegistrationWidget/>
    </MainContainer>
)

const MainContainer = styled.div`
    position: relative;
    text-align: center;
    color: #00426d;
    font-size: 14px;
`

const PhotosSliderContent = styled.div`
    position: absolute;
    color: white;
    bottom: 35%;
    left: 0;
    right: 0;
    text-align: right;

    h1 {
        font-weight: bold;
        text-transform: uppercase;
    }
    @media only screen and (max-width: 767px) and (min-width: 319px){
        top: 3%;
    }
`

const SlideBackground = styled.img`
    height: auto;
    max-width: 100%;
`

const CarouselItem = (props : any) => {
    return (
    <Carousel.Item className={props.className} {...props}>
        <SlideBackground src={props.src} alt="side-1"/>
        <PhotosSliderContent className="container photos-slider2-content">            
            <div className="col-md-8 col-sm-12 col-xs12 pull-right">
                <div className="slider-content">
                    <H2>Conducí con SatApp</H2>
                    
                    <P>{props.msg}</P>
                </div>
            </div>
        </PhotosSliderContent>
    </Carousel.Item>
)}

const H2 = styled.h2`
    font-family: 'Quicksand';
    font-weight: 600; 
    font-size: 35px;
    line-height: 45px;
    text-shadow: 1px 1px #ffffff4a;
    @media only screen and (max-width: 767px) and (min-width: 319px){
        line-height: 35px;
    }
`
const P = styled.p`
    @media only screen and (min-width: 767px){
        font-size:30px;
    }
    @media only screen and (max-width: 767px) and (min-width: 319px){
        font-size:17px;
    }
`

export default PhotoSlider;