import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { useAsyncFunction } from "controllers/hooks";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { QuizParams } from "types/module-transfer";

const AskReturnWithWait = () => {
  const { idModule } = useParams<QuizParams>();
  const [func, loading, error] = useAsyncFunction(() =>
    CALLABLE_FUNCTIONS.PASSENGER_ASK_RETURN({
      idModule: idModule,
      isDriver: false,
    })
  );
  useEffect(() => {
    if (idModule !== undefined) func();
  }, []);

  if (idModule === undefined) return <>Link inválido</>;
  if (loading) return <>Pidiendo traslado ...</>;
  if (error) return <>{error}</>;
  return (
    <div>
      <p>
        Hemos notificado al conductor para que vuelva a buscarlo. Le mandaremos
        un SMS cuando llegue.{" "}
      </p>
    </div>
  );
};

export default AskReturnWithWait;
