export const stepsArray = [
  "Menú Traslados",
  "Agendar un traslado",
  "Tipos de viaje",
  "Antes de buscar el pasajero",
  "Buscando al pasajero ¿Qué acciones debo hacer?",
  "Distancia sin paciente a bordo",
  "Trasladando al pasajero ¿Qué acciones debo hacer?",
  "Interrupción de Viajes",
  "Tiempo de espera en origen",
  "Hora en Origen / Hora en Destino",
  "Ejemplos de Situaciones ¿Cómo resolverlas?",
  "¿Cómo registrar autos?",
];

export const videosArray = [
  "NBJOfByrpDg", //Menú traslados
  "Egf0-aVrkiM", //Como agendar un traslado FALTAN PREGUNTAS
  "v5tzomdwCoU", //Tipos de viaje
  "rewOUHE4Kc8", //Antes de buscar el pasajero
  "wpk2jXjgu3s", //Buscando al paciente
  "xa1fm4YLeb8", //Distancia sin paciente a bordo
  "uNtTjSg9tBk", //Trasladando al paciente
  "Js1FNsscP9Q", //Interrupciones
  "4yyvBDfLedI", //Tiempo de espera en origen
  "XFyKbGDkfLo", //Tipos de hora
  "Ou-O-EavgDo", //Ejemplos de situaciones ¿Cómo resolverlas?
  "ZyxDICJPS88", //Como registrar autos,
];

export const quizData = [
  {
    idVideo: 0,
    question: "Traslados Disponibles",
    options: [
      "Son traslados que tengo agendados para hacer.",
      "Son traslados que puedo seleccionar y agendarlos para realizar.",
      "Son traslados que otros conductores no quisieron hacer.",
    ],
    answer: "Son traslados que puedo seleccionar y agendarlos para realizar.",
  },
  {
    idVideo: 0,
    question: "Traslados Agendados",
    options: [
      "Son los traslados que yo elegí y me comprometo a realizar.",
      "Son traslados que estoy esperando me confirmen si se van a hacer.",
      "Son todos los traslados que otros conductores pueden hacer.",
    ],
    answer: "Son los traslados que yo elegí y me comprometo a realizar.",
  },
  {
    idVideo: 0,
    question: "Historial de Traslados",
    options: [
      "Son traslados que no se pudieron hacer.",
      "Son traslados que aún no fueron publicados.",
      "Son los traslados que yo realicé y tengo como registrados.",
    ],
    answer: "Son los traslados que yo realicé y tengo como registrados.",
  },
  {
    idVideo: 1,
    question: "¿En qué apartado se pueden ver los viajes para agendar?",
    options: [
      "En Traslados Agendados.",
      "En Historial de traslados.",
      "En Traslados Disponibles se agendan los viajes del momento y Traslados Futuros se agendan los viajes a futuro.",
    ],
    answer:
      "En Traslados Disponibles se agendan los viajes del momento y Traslados Futuros se agendan los viajes a futuro.",
  },
  {
    idVideo: 1,
    question: "¿Cómo se puede agendar un viaje?",
    options: [
      "Anotando en un papel las direcciones y horarios.",
      "Abriendo el viaje que se quiere ver, deslizando hacia abajo y seleccionando el botón “agendar traslado.",
      "Sacandole captura de pantalla.",
    ],
    answer:
      "Abriendo el viaje que se quiere ver, deslizando hacia abajo y seleccionando el botón “agendar traslado.",
  },
  {
    idVideo: 2,
    question:
      "En un viaje tipo TRAMO, ¿Debo esperar al pasajero para llevarlo de regreso a su domicilio?",
    options: [
      "No. Un viaje tipo Tramo finaliza en el lugar de Destino.",
      "Si. Y no importa cuanto dure la espera.",
      "Siempre que el pasajero me avise que va a retornar conmigo.",
    ],
    answer: "No. Un viaje tipo Tramo finaliza en el lugar de Destino.",
  },
  {
    idVideo: 2,
    question:
      "En un viaje tipo Módulo Con Espera, ¿Debo esperar al pasajero para llevarlo de regreso a su domicilio?",
    options: [
      "No. El servicio termina cuando termina finaliza el tramo de ida.",
      "Siempre que la espera sea menor a 2 horas.",
      "Si. El valor de la espera me será abonado, y si no realizo el regreso del paciente a su domicilio, no me será abonado el viaje de ida.",
    ],
    answer:
      "Si. El valor de la espera me será abonado, y si no realizo el regreso del paciente a su domicilio, no me será abonado el viaje de ida.",
  },
  {
    idVideo: 2,
    question: "Un viaje tipo NEGATIVO es",
    options: [
      "Un viaje que yo no quiero hacer.",
      "Un  viaje que puedo DEVOLVER.",
      "Un viaje que al llegar al lugar de Origen, el pasajero no viaja, y luego de INFORMAR, se me autorizar a retirarme del domicilio, cobrando el importe correspondiente a VIAJE NEGATIVO.",
    ],
    answer:
      "Un viaje que al llegar al lugar de Origen, el pasajero no viaja, y luego de INFORMAR, se me autorizar a retirarme del domicilio, cobrando el importe correspondiente a VIAJE NEGATIVO.",
  },
  {
    idVideo: 3,
    question: "¿Para qué debo tener en cuenta la HORA MAXIMA en Origen?",
    options: [
      "Para avisarle al paciente que voy a llegar antes de la Hora Maxima en Origen.",
      "Si llego pasada la Hora Máxima en Origen puede que el viaje no sea abonado.",
      "Si llego pasada la Hora Maxima en Origen el paciente tiene la obligación de viajar igual.",
    ],
    answer:
      "Si llego pasada la Hora Máxima en Origen puede que el viaje no sea abonado.",
  },
  {
    idVideo: 3,
    question:
      "Para qué debo utilizar el Informe : “Conductor coordina horario con paciente”",
    options: [
      "En los casos en que me comunico con el paciente y acordamos en que va a viajar pasada la Hora Maxima en Origen.",
      "En los casos que no estoy seguro si voy a poder llegar a buscar al paciente.",
      "En los casos que el paciente quiere coordinar el viaje antes de la Hora en Origen.",
    ],
    answer:
      "En los casos en que me comunico con el paciente y acordamos en que va a viajar pasada la Hora Maxima en Origen.",
  },
  {
    idVideo: 3,
    question:
      "Cuándo debo utilizar el informe: “Paciente informa que no va a viajar“",
    options: [
      "Cuando estoy en el domicilio del paciente y no me puedo comunicar.",
      "Cuando lo llamo para coordinar el viaje y no me puedo comunicar.",
      "Cuando habiéndose comunicado con el paciente (antes de salir a buscarlo, o mientras estoy yendo a su domicilio ), me informa que no va viajar, y por lo tanto es un viaje que no se va a facturar ni cobrar.",
    ],
    answer:
      "Cuando habiéndose comunicado con el paciente (antes de salir a buscarlo, o mientras estoy yendo a su domicilio ), me informa que no va viajar, y por lo tanto es un viaje que no se va a facturar ni cobrar.",
  },
  {
    idVideo: 4,
    question:
      "¿Hay que apretar algun botón cuando se está yendo a buscar al pasajero?",
    options: [
      "No, solamente avisar cuando se llega al punto donde se encuentra el pasajero.",
      "Si, Debo apretar varias veces INICIAR TRASLADO para que el paciente sepa que estoy en camino.",
      "Si, es muy importante, apretar el botón de INICIAR, así el pasajero sabe que se lo está yendo a buscar y puede esperar la llegada del auto con tiempo y se me calculen los km sin paciente a bordo.",
    ],
    answer:
      "Si, es muy importante, apretar el botón de INICIAR, así el pasajero sabe que se lo está yendo a buscar y puede esperar la llegada del auto con tiempo y se me calculen los km sin paciente a bordo.",
  },
  {
    idVideo: 4,
    question:
      "¿Qué hay que hacer apenas se llega al domicilio a buscar al pasajero?",
    options: [
      "Apretar botón EN ORIGEN, para avisar al pasajero que llegué, y buscar de hacer contacto, tocando timbre o de la manera más conveniente.",
      "Esperar a que el pasajero salga sin hacer nada.",
      "No avisar en la aplicación, pero tocar timbre del domicilio.",
    ],
    answer:
      "Apretar botón EN ORIGEN, para avisar al pasajero que llegué, y buscar de hacer contacto, tocando timbre o de la manera más conveniente.",
  },
  {
    idVideo: 4,
    question: "¿Qué hacer si el paciente no está en domicilio?",
    options: [
      "Retirarse sin avisar.",
      "Debo apretar INFORMAR y elegir la opción que se adecue a la situación para que la ART , dentro de los 15 minutos siguientes resuelva si el paciente viaja, o no.",
      "INTERRUMPIR el viaje y retirarme.",
    ],
    answer:
      "Debo apretar INFORMAR y elegir la opción que se adecue a la situación para que la ART , dentro de los 15 minutos siguientes resuelva si el paciente viaja, o no.",
  },
  {
    idVideo: 4,
    question:
      "¿Qué hacer durante los 15 minutos que la ART tiene para resolver un INFORME?",
    options: [
      "Debo esperar en la dirección de origen los 15 minutos que dura el informe,  para poder cobrar el NEGATIVO.",
      "Debo llamar al paciente para confirmar si viaja o no.",
      "Puedo retirarme y comenzar otro viaje mientras la ART resuelve el informe.",
    ],
    answer:
      "Debo esperar en la dirección de origen los 15 minutos que dura el informe,  para poder cobrar el NEGATIVO.",
  },
  {
    idVideo: 4,
    question: "Al finalizar los 15 minutos del INFORME",
    options: [
      "Debo llamar a soporte de Satapp para que autoricen el viaje NEGATIVO.",
      "Debo contactar a la ART para confirmar que el viaje es NEGATIVO.",
      "Debo apretar CONFIRMAR NEGATIVO para dar el viaje por terminado, continuar con otro viaje.",
    ],
    answer:
      "Debo apretar CONFIRMAR NEGATIVO para dar el viaje por terminado, continuar con otro viaje.",
  },
  {
    idVideo: 5,
    question: "La app empieza a calcular los km cuando:",
    options: [
      "Apretas el botón iniciar traslado.",
      "Agendas el viaje.",
      "Llegas a origen.",
    ],
    answer: "Apretas el botón iniciar traslado.",
  },
  {
    idVideo: 5,
    question: "¿Cuántos son los km reconocidos por la app?",
    options: [
      "A partir de 12 km desde mi ubicación hasta la de origen del  paciente.",
      "El máximo de distancia reconocida es de 8 km. Descontando 4km ya contemplados en el valor del viaje publicado.",
      "Solo se me reconoceran 4 km.",
    ],
    answer:
      "El máximo de distancia reconocida es de 8 km. Descontando 4km ya contemplados en el valor del viaje publicado.",
  },
  {
    idVideo: 5,
    question: "¿Cómo calcula la distancia sin paciente a bordo?",
    options: [
      "La app calcula según el recorrido que yo elija como conductor.",
      "La app calcula los km sin paciente a bordo en base al recorrido publicado por Satapp. Empieza a contar cuando aprieto el botón iniciar.",
      "La app calcula según el tiempo que tarda en llegar al origen según el tránsito.",
    ],
    answer:
      "La app calcula los km sin paciente a bordo en base al recorrido publicado por Satapp. Empieza a contar cuando aprieto el botón iniciar.",
  },
  {
    idVideo: 6,
    question: "¿Qué hacer apenas se sube el pasajero al auto?",
    options: [
      "Escanear el DNI y luego comenzar el viaje.",
      "Debo preguntarle si el viaje es de IDA y RETORNO.",
      "Debo preguntarle el nombre y a dónde quiere que lo lleve.",
    ],
    answer: "Escanear el DNI y luego comenzar el viaje.",
  },
  {
    idVideo: 6,
    question: "¿Qué hay que hacer cuando se llega el destino final?",
    options: [
      "Apretar botón “FINALIZAR”, pedirle al pasajero que firme en el celular y luego que baje del auto.",
      "Debo avisar por mensaje a Satapp que el viajes se hizo correctamente.",
      "Que no firme el pasajero, no hay problema.",
    ],
    answer:
      "Apretar botón “FINALIZAR”, pedirle al pasajero que firme en el celular y luego que baje del auto.",
  },
  {
    idVideo: 6,
    question: "Al llegar a la dirección de DESTINO",
    options: [
      "Debo informar al paciente que puede descender del vehículo.",
      "Debo informar a la ART que el viaje se terminó en el lugar indicado.",
      "Debo hacer firmar OBLIGATORIAMENTE al paciente para poder cobrar el viaje.",
    ],
    answer:
      "Debo hacer firmar OBLIGATORIAMENTE al paciente para poder cobrar el viaje.",
  },
  {
    idVideo: 6,
    question:
      "Una vez finalizado el tramo de IDA en traslado tipo Modulo Con Espera, ¿Qué acción debemos realizar?",
    options: [
      "Acordar con el paciente hora aproximada para buscarlo para el viaje de retorno.",
      "Esperar que en la aplicación me llegue una notificación que el paciente solicitó el retorno, y apretar INICIAR para que le llegue un aviso automático al pasajero que estoy en camino.",
      "Buscar en Traslados Disponibles el Tramo de Retorno y Agendarlo.",
    ],
    answer:
      "Esperar que en la aplicación me llegue una notificación que el paciente solicitó el retorno, y apretar INICIAR para que le llegue un aviso automático al pasajero que estoy en camino.",
  },
  {
    idVideo: 7,
    question:
      "Mientras estoy en camino a buscar al pasajero a la Direccion de Origen, y se me rompe el automóvil, ¿Qué hago?",
    options: [
      "Presiono el botón CANCELAR TRASLADO, y elijo la opcion “Descompostura de automóvil”.",
      "Llamo por teléfono a la ART y aviso.",
      "Llamo por teléfono a Satapp y aviso.",
    ],
    answer:
      "Presiono el botón CANCELAR TRASLADO, y elijo la opcion “Descompostura de automóvil”.",
  },
  {
    idVideo: 7,
    question:
      "Mientras estoy con el pasajero a bordo en viaje al lugar de Destino y hay un corte de ruta por manifestación",
    options: [
      "Selecciono CANCELAR TRASLADO, elijo la opcion Corte de Ruta/ Manifestación. Luego aguardo que la ART me envíe un nuevo viaje para Retornar al pasajero al domicilio de Origen.",
      "Retorno al pasajero al domicilio de Origen  y Finalizo el traslado.",
      "Le pido al paciente que llame a la ART para que le cambie el Turno.",
    ],
    answer:
      "Selecciono CANCELAR TRASLADO, elijo la opcion Corte de Ruta/ Manifestación. Luego aguardo que la ART me envíe un nuevo viaje para Retornar al pasajero al domicilio de Origen.",
  },
  {
    idVideo: 7,
    question:
      "Si se me rompe el automóvil durante el viaje con un pasajero a bordo",
    options: [
      "Le digo al pasajero que llame a la ART y que avise.",
      "Selecciono CANCELAR TRASLADO, y elijo la opción “Descompostura de automóvil”, y aguardo hasta que otro automóvil venga por el pasajero.",
      "Le pido al pasajero que descienda del automóvil y espere hasta que solucione el problema.",
    ],
    answer:
      "Selecciono CANCELAR TRASLADO, y elijo la opción “Descompostura de automóvil”, y aguardo hasta que otro automóvil venga por el pasajero.",
  },
  {
    idVideo: 7,
    question: "Si durante la Espera tengo que volverme a mi casa",
    options: [
      "Seleccion CANCELAR TRASLADO, y elijo la opcion “Decisión Personal”, sabiendo que no me será abonado el traslado de IDA.",
      "Le mando un mensaje al pasajero para que pido otro movil.",
      "Le aviso a Satapp por email que me tengo que ir.",
    ],
    answer:
      "Seleccion CANCELAR TRASLADO, y elijo la opcion “Decisión Personal”, sabiendo que no me será abonado el traslado de IDA.",
  },
  {
    idVideo: 8,
    question: "¿Cuál es el tiempo de espera que se reconoce?",
    options: [
      "Se abonarán los minutos de espera posteriores a los primeros 15 minutos del informe.",
      "Se abonan los minutos que se espera al paciente en origen.",
      "Se abonan los minutos que se espera al paciente en el centro médico.",
    ],
    answer:
      "Se abonarán los minutos de espera posteriores a los primeros 15 minutos del informe.",
  },
  {
    idVideo: 9,
    question: "¿Qué se entiende por Hora en Origen?",
    options: [
      "Es la hora que debo INICIAR el viaje y salir a buscar al pasajero.",
      "Es la hora en la que debo estar en la Dirección de Origen.",
      "Es la hora que el pasajero tiene el turno médico.",
    ],
    answer: "Es la hora en la que debo estar en la Dirección de Origen.",
  },
  {
    idVideo: 9,
    question: "¿Qué se entiende por Hora en Destino?",
    options: [
      "Es la hora que el pasajero debe regresar a su domicilio",
      "Es la hora aproximada que debo salir a buscar al pasajero.",
      "Es la hora en que el pasajero debe llegar a destino para cumplir con su TURNO MÉDICO.",
    ],
    answer:
      "Es la hora en que el pasajero debe llegar a destino para cumplir con su TURNO MÉDICO.",
  },
  {
    idVideo: 9,
    question: "¿Qué se entiende por Hora MÁXIMA en Origen?",
    options: [
      "Es la hora máxima en la que puedo llegar al domicilio del paciente y si no viaja poder cobrar NEGATIVO.",
      "Es la hora máxima para poder informar que no voy a realizar el viaje.",
      "Es la hora máxima que tiene el paciente para decidir si quiere viajar.",
    ],
    answer:
      "Es la hora máxima en la que puedo llegar al domicilio del paciente y si no viaja poder cobrar NEGATIVO.",
  },
  {
    idVideo: 10,
    question: "Una vez Agendado un traslado, ¿Puedo devolverlo?",
    options: [
      "No, si me comprometí debo hacerlo.",
      "Si, debo pulsar el botón DEVOLVER TRASLADO siempre que no la haya INICIADO ( estos sería que aún no estoy yendo a buscar al paciente).",
      "No hace falta que haga nada, ya que la aplicación se va a dar cuenta que no lo voy ha realizar.",
    ],
    answer:
      "Si, debo pulsar el botón DEVOLVER TRASLADO siempre que no la haya INICIADO ( estos sería que aún no estoy yendo a buscar al paciente).",
  },
  {
    idVideo: 10,
    question:
      "Si el pasajero quiere hacer un cambio de destino o parada en el viaje, ¿Qué hacer?",
    options: [
      "Hacerle caso al pasajero y cambiar sin avisar.",
      "Debo comunicarme con soporte para que autorice el cambio de recorrido.",
      "Debo informarle al paciente que no se puede cambiar el recorrido del viaje.",
    ],
    answer:
      "Debo informarle al paciente que no se puede cambiar el recorrido del viaje.",
  },
  {
    idVideo: 11,
    question: "¿Puedo registrar más de un auto?",
    options: [
      "Si, Debo ingresar con mi usuario y contraseña en la pagina de Satapp y elegir AGREGAR NUEVO AUTO y seguir el proceso.",
      "Debo realizar el viaje y mandar foto de patente.",
      "Debo solicitar por email autorización para usar otro auto.",
    ],
    answer:
      "Si, Debo ingresar con mi usuario y contraseña en la pagina de Satapp y elegir AGREGAR NUEVO AUTO y seguir el proceso.",
  },
];
