import { Skeleton } from "antd";
import FbFile from 'components/Primitives/File';
import { AdminTable, Button, ContainerPage, H2, NoData } from "components/styles";
import { getModuleInvoicesFromArt } from "components/User/Home/reducer";
import ROUTES from "constants/routes";
import { uidSelector } from "controllers/auth";
import { WithId, WithUid } from "controllers/database";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ModuleTransfer } from "types/module-transfer";
import { ScheduledTransfer } from "types/transfer";
import { usePaginator } from "util/hooks";
import { BillStatus } from "types/billing";

const ArtPurchases = () => {
    const [type, setType] = useState(0)
    const uid = useSelector(uidSelector)
    const [collection, setCollection] = useState(getModuleInvoicesFromArt(type, uid))
    const [items, loading, Pagination] = usePaginator<ModuleTransfer>(collection, 10)

    const changeType = (type : number) => {
        setType(type)
        setCollection(getModuleInvoicesFromArt(type, uid))
    }

    const optionsFilter = [
		"Pendientes de facturar",
		"Facturados"
    ];
	return (

        <ContainerPage>
            <Row style={{marginBottom:15}}>
				<Col md={3}>
					<H2>Facturas</H2>
				</Col>
				<Col>
					<Row>
						<Col md={4}>
							<Form.Control
								as="select" value={type.toString()}
                                onChange={(event) => changeType( parseInt((event.target as HTMLTextAreaElement).value) )}>

								{optionsFilter.map((op, index) => (
									<option key={index} value={index}>
										{op}
									</option>
								))}
							</Form.Control>
						</Col>
					</Row>
				</Col>
			</Row>
            {loading ? 
                <Skeleton active paragraph={{ rows: 19 }} />
            :
            items.length > 0 ? (
                <AdminTable responsive>
                    <thead>
                        <tr>
                            <th>ID Módulo Traslado</th>
                            <th>Fecha Traslado</th>
                            <th>Fecha Facturado</th>
                            <th>Factura</th>
                            <th>Estado Factura</th>
                            <th>Ver Traslado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td>{item.id}</td>
                                <td><Moment date={item.origin.time.toDate()} format="DD/MM/YYYY" /></td>
                                <td></td>
                                <td>{item.sale && item.sale.bill_info?.pdf && <FbFile src={item.sale.bill_info.pdf}/>}</td>
                                <td>{(item.sale !== undefined && item.sale.billed_from_drivers !== undefined) ? 
                                        (item.sale.billed_from_drivers === BillStatus.No ?
                                            "Falta Facturar Conductores"
                                        : item.sale.billed_from_drivers === BillStatus.Si ?
                                            "Falta Facturar"
                                        : item.sale.billed_from_drivers === BillStatus["No facturar"] ?
                                            "No Facturar"
                                        : null)
                                    : null}</td>
                                <td>
                                    <Link to={ROUTES.ART_TRANSFER_VIEW(item.id)}>
                                        <Button variant="light">Ver Traslado</Button>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </AdminTable>
            ) : 
                <NoData/>
            }
            <Pagination/> 
        </ContainerPage>
	);
}

export const PurchasesTable = ({items, loading}:{items:(WithUid & WithId & ScheduledTransfer)[], loading:boolean}) => (
    <>
        
    </>
)

export default ArtPurchases;