import TermsAndConditions from "../Newcomers/pages/TermsAndConditions";
import React from "react";
import { reduxForm } from "redux-form";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { H3 } from "components/styles";

const SignUserContract = () => {
  const onSubmit = () => CALLABLE_FUNCTIONS.USER_ACCEPT_CONTRACT();

  return (
    <>
      <H3>Tienes que firmar la carta oferta para continuar</H3>
      <MainContainer onSubmit={onSubmit} />
    </>
  );
};

const MainContainer = reduxForm({
  form: "ContractForm",
})(({ submitting, handleSubmit }: any) => (
  <form onSubmit={handleSubmit}>
    <TermsAndConditions submitting={submitting} />
  </form>
));

export default SignUserContract;
