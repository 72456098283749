import * as B from "react-bootstrap";
import { Form, Table, Modal, Alert } from "react-bootstrap";
import styled from "styled-components";
import * as R from "react-router-dom";
import React, { useState } from "react";
import { faChevronLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { Placement } from "react-bootstrap/Overlay";
import { Empty, Tooltip } from "antd";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

const Button = styled(B.Button)`
  font-size: 14px;
  margin: 5px;
`;
const Label = styled.div`
  border-radius: 6px;
  border: 1px solid;
  display: inline-block;
  cursor: default;
  color: white;
  font-size: 13px;
  padding: 6px 24px;
  text-decoration: none;
`;

const Center = styled(B.Container)`
  text-align: center;
`;

const FormControl = styled(Form.Control)`
  font-size: 14px !important;
  height: 40px !important;
`;

const Container = styled(B.Container)`
  /*font-size:14px;*/
  @media (min-width: 1200px) {
    max-width: 100%;
  }
`;

const Link = styled(R.Link)`
  &:hover {
    text-decoration: none;
  }
`;

const Nav = styled(B.Nav)`
  margin: 7px;
  /*font-size: 13px;*/
  text-align: center;
  flex-direction: column;
`;

const P = styled.p`
  text-align: justify;
`;

/*function Padding({pad, ...props}: any) {
  return <div style={{ padding: pad }} {...props}>{props.children}</div>;
}*/
const Padding = styled.div<{ pad: string }>`
  padding: ${(props) => props.pad};
`;

const LinkWithoutHref = styled.button`
  border: none;
  background-color: transparent;
  color: #409eff;
  padding: 0;
`;
const LinkWithoutColor = styled(Link)`
  color: inherit;
  &:hover {
    color: inherit;
  }
`;

const ContainerGrey = styled(Container)`
  background-color: rgb(238, 238, 238);
  margin-bottom: 15px;
  border-radius: 6px;
`;

const H1 = styled.h1`
  font-weight: 700 !important;
  font-family: "Quicksand";
  font-size: 2em;
  /*color: #303133;*/
`;
const H2 = styled.h2`
  font-weight: 700 !important;
  font-family: "Quicksand";
  font-size: 1.5em !important;
  color: #58595b;
`;
const H3 = styled.h3`
  font-weight: 700 !important;
  font-family: "Quicksand";
  font-size: 1.6em !important;
  color: #58595b;
`;
const H4 = styled.h4`
  font-weight: 700 !important;
  font-family: "Quicksand";
  font-size: 1em;
  color: #58595b;
`;

const Required = styled.span`
  color: red;
  &:after {
    content: "*";
  }
`;

const Ribbon = styled.div`
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;

  &:before,
  &:after {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid ${(props) => props.color};
  }
  span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: ${(props) => props.color};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font: 700 18px/1 "Lato", sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
  }
`;

const RibbonTopLeft = styled(Ribbon)`
  top: -10px;
  left: -10px;
  &:before,
  &:after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  &:before {
    top: 0;
    right: 0;
  }
  &:after {
    bottom: 0;
    left: 0;
  }
  span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
  }
`;

const RibbonTopRight = styled(Ribbon)`
  top: -10px;
  right: -10px;

  &:before,
  &:after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  &:before {
    top: 0;
    left: 0;
  }
  &:after {
    bottom: 0;
    right: 0;
  }
  span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }
`;

const RibbonContainer = styled.div`
  position: relative;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  /*display: flex;*/
`;

const Spinner = ({
  style,
  size,
}: {
  style?: React.CSSProperties;
  size?: "sm" | undefined;
}) => {
  let internStyle = style;
  if (internStyle === undefined) internStyle = { margin: 50 };

  return (
    <B.Row className="justify-content-center" style={internStyle}>
      <B.Spinner animation="border" role="status" size={size} />
    </B.Row>
  );
};

const AdminTable = styled(Table)`
  @media (min-width: 1200px) {
    max-width: 100%;
  }
  border: solid 1px #ccc;
  background-color: #fff;
  border-radius: 5px;
  border-collapse: collapse;
  overflow: hidden;
  font-size: 14px;
  color: #606266;

  thead {
    color: #909399;
  }
  th,
  td {
    padding: 12px !important;

    overflow: hidden;
    text-overflow: ellipsis;

    /*white-space: nowrap;*/
    text-align: left;
  }
  th {
    user-select: none;
    background-color: #f5f5f5;
    font-weight: bold;
    border-bottom: none !important;
  }
  td {
    border-bottom: none;
    border-top: none;
  }
  tr {
    background-color: #fff;
  }
`;

const AdminTableAlternative = styled(Table)`
  border: solid 1px #ccc;
  background-color: #fff;
  border-radius: 5px;
  /*border-collapse: collapse*/
  overflow: hidden;
  font-size: 14px;
  color: #606266;

  thead {
    color: #909399;
  }
  th,
  td {
    padding: 12px !important;

    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;
    text-align: left;
  }
  th {
    user-select: none;
    background-color: #f5f5f5;
    font-weight: bold;
    border-bottom: none !important;
  }
  td {
    border-bottom: none;
    border-top: none;
  }
  tr {
    background-color: #fff;
  }
`;

const ContainerPage = styled.div`
  @media (min-width: 1200px) {
    max-width: 100%;
  }
  padding: 30px;
  border-radius: 6px;
  background-color: white;
  margin-bottom: 100px;
  -webkit-box-shadow: 0px 2px 7px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 7px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 7px -5px rgba(0, 0, 0, 0.75);
`;
const LinkWhatsApp = styled.div`
  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #fff;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }

  .float:hover {
    text-decoration: none;
    color: #25d366;
    background-color: #25d366;
  }

  .my-float {
    margin-top: 16px;
  }
`;

const SubmitButton = ({
  children,
  submitting,
  ...props
}: {
  children: any;
  submitting: boolean;
  [key: string]: any;
}) => (
  <Button type="submit" {...props}>
    {submitting ? <Spinner size="sm" style={{}} /> : children}
  </Button>
);
const AsyncButton = ({
  children,
  onClick,
  onSuccess,
  ...props
}: {
  children: any;
  onClick: () => Promise<any>;
  onSuccess: () => void;
} & any) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const _onClick = () => {
    setLoading(true);
    onClick()
      .then(() => {
        setLoading(false);
        if (onSuccess !== undefined) onSuccess();
      })
      .catch((v: firebase.functions.HttpsError) => {
        setLoading(false);
        setError(v.message);
      });
  };

  return (
    <Button
      {...props}
      onClick={error === undefined && loading !== true ? _onClick : undefined}
    >
      {error ? (
        <ErrorSpan>ERROR: {error}</ErrorSpan>
      ) : loading ? (
        <Spinner size="sm" style={{}} />
      ) : (
        children
      )}
    </Button>
  );
};

const ErrorSpan = styled.span`
  font-weight: bold;
  color: #f44336;
`;

const PageTitle = ({ children }: { children: any }) => {
  const history = useHistory();
  return (
    <H2>
      <FontAwesomeIcon icon={faChevronLeft} onClick={() => history.goBack()} />{" "}
      {children}
    </H2>
  );
};

const Searcher = ({ onChange, onSearch, placeHolder, value, tooltip }: any) => (
  <B.InputGroup className="mb-3">
    <Form.Control
      type="text"
      placeholder={placeHolder}
      value={value}
      onChange={(e: any) => onChange(e.target.value)}
      onKeyPress={(e: any) => {
        if (e.key === "Enter") onSearch(value);
      }}
    />
    <B.InputGroup.Append>
      <ButtonSearch onClick={() => onSearch(value)} variant="light">
        <FontAwesomeIcon icon={faSearch} />
      </ButtonSearch>
    </B.InputGroup.Append>
    {tooltip && (
      <B.InputGroup.Append>
        <B.InputGroup.Text>
          <QuestionTooltip text={tooltip} placement="bottom" />
        </B.InputGroup.Text>
      </B.InputGroup.Append>
    )}
  </B.InputGroup>
);

const ButtonSearch = styled(Button)`
  background-color: transparent !important;
  color: grey !important;
  margin: 0px;
`;

const ButtonWithTooltip = ({
  children,
  textTooltip,
  placement = "bottom",
  ...props
}: { children: any; placement: Placement; textTooltip: string } & any) => {
  return (
    <B.OverlayTrigger
      placement={placement}
      overlay={<B.Tooltip id={placement}>{textTooltip}</B.Tooltip>}
    >
      <Button {...props}>{children}</Button>
    </B.OverlayTrigger>
  );
};

const TextWithTooltip = ({
  children,
  textTooltip,
  placement = "bottom",
  id,
  ...props
}: {
  children: any;
  placement: Placement;
  textTooltip: string;
  id?: string;
} & any) => {
  return (
    <Tooltip placement={placement} title={textTooltip}>
      <span>{children}</span>
    </Tooltip>
  );
};

export const QuestionTooltip = ({
  placement,
  text,
}: {
  placement?: string;
  text: JSX.Element | string;
}) => (
  <TextWithTooltip placement={placement || "right"} textTooltip={() => text}>
    <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
  </TextWithTooltip>
);

const NoDataRow = styled(B.Row)`
  padding: 20px;
  background: rgb(245, 245, 245);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
  margin: 10px;
`;
const NoData = () => (
  <NoDataRow>
    <Empty style={{ margin: "auto" }} description="No hay datos" />
  </NoDataRow>
);
const ErrorPage = () => (
  <>Error</> //FIXME: agregar linda imagen de error
);

/**
 * Botón con Modal para confirmar. Solo sirve para funciones de backend (CALLABLE_FUNCTIONS)
 */
export const ButtonWithConfirmation = ({
  confirmText,
  onClick,
  children,
  closeOnDone,
  ...props
}: {
  closeOnDone?: boolean;
  children: React.ReactNode;
  confirmText: string;
  onClick: () => Promise<any>;
  [x: string]: any;
}) => {
  const [billModal, setBillModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState(false);
  const runFunc = async () => {
    setLoading(true);
    onClick()
      .then(() => {
        if (closeOnDone) setBillModal(false);
        else setSuccess(true);
      })
      .catch((v) => setError(v.message))
      .then(() => setLoading(false));
  };
  const closeAndReset = () => {
    setBillModal(false);
    setSuccess(false);
    setError(undefined);
  };
  return (
    <>
      <Modal show={billModal} onHide={closeAndReset} size="lg">
        <Modal.Header closeButton>Confirmación</Modal.Header>
        <Modal.Body>
          {success ? (
            <Alert variant="success">Realizado con éxito</Alert>
          ) : error ? (
            <Alert variant="danger">{error}</Alert>
          ) : loading ? (
            <Spinner />
          ) : (
            <>{confirmText}</>
          )}
        </Modal.Body>
        {!success && (
          <Modal.Footer>
            <Button variant="danger" onClick={runFunc}>
              Continuar
            </Button>
            <Button variant="secondary" onClick={closeAndReset}>
              Cancelar
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <Button {...props} onClick={() => setBillModal(true)}>
        {children}
      </Button>
    </>
  );
};

export {
  Container,
  Button,
  Center,
  Nav,
  Link,
  FormControl,
  Padding,
  LinkWithoutHref,
  LinkWithoutColor,
  P,
  ContainerGrey,
  H1,
  H2,
  H3,
  H4,
  Required,
  RibbonTopRight,
  RibbonTopLeft,
  RibbonContainer,
  Spinner,
  AdminTable,
  AdminTableAlternative,
  ContainerPage,
  SubmitButton,
  PageTitle,
  Label,
  Searcher,
  AsyncButton,
  ButtonWithTooltip,
  TextWithTooltip,
  NoData,
  ErrorPage,
  LinkWhatsApp,
};
