import { faIdCard } from "@fortawesome/free-regular-svg-icons";
import { faUniversity, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FbImage from "components/Primitives/Image";
import {
  Button,
  ContainerGrey,
  Link,
  Padding,
  Spinner,
} from "components/styles";
import ROUTES from "constants/routes";
import { uidSelector } from "controllers/auth";
import { useDbDocument } from "controllers/hooks";
import { getFullAddress } from "controllers/map";
import {
  getLicenceInformationColDoc,
  getPersonalInformationColDoc,
  getTaxableInformationColDoc,
} from "controllers/user/info/personal";
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import styled from "styled-components";
import {
  CivilStatus,
  IdType,
  Licence,
  PersonalInformation,
  TaxableEntity,
  TaxableEntityType,
} from "types/user-data";

const UserMyProfilePage = () => {
  const uid = useSelector(uidSelector);
  const personal = useDbDocument<PersonalInformation>(
    ...getPersonalInformationColDoc(uid)
  );
  const licence = useDbDocument<Licence>(...getLicenceInformationColDoc(uid));
  const taxable = useDbDocument<TaxableEntity>(
    ...getTaxableInformationColDoc(uid)
  );
  const [editProfileModal, setEditProfileModal] = useState(false);

  const openEditProfileModal = () => setEditProfileModal(true);
  const closeEditProfileModal = () => setEditProfileModal(false);

  if (!isLoaded(personal)) return <Spinner />;
  if (!personal || !licence || !taxable) return <>Error</>;
  return (
    <Padding pad="5px">
      <Modal show={editProfileModal} onHide={closeEditProfileModal}>
        <Modal.Header closeButton>
          <Modal.Title>Atención</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Al modificar algún dato del perfil, este entrará en modo aprobación y
          no podrá usarlo. Desea continuar?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={closeEditProfileModal}>
            No, cancelar
          </Button>
          <Link to={ROUTES.USER_NEWCOMERS} style={{ margin: "10px" }}>
            <Button variant="info">Si, continuar</Button>
          </Link>
        </Modal.Footer>
      </Modal>

      <ContainerGrey style={{ display: "inline-block", marginBottom: "0px" }}>
        <Row>
          <Col xs={12} md={6}>
            <Span variant="info">Perfil Validado</Span>
            <Span variant="primary">Conductor Particular</Span>
          </Col>
          <Col style={{ textAlign: "right" }}>
            {/*<Button variant="secondary" style={{margin:"10px"}}>Test Notificación</Button>
                <Link to={ROUTES.USER_PROFILE_HISTORY} style={{margin:"10px"}}>
                    <Button variant="secondary" style={{margin:"10px"}}>Historial</Button>
                </Link>*/}

            <Button
              variant="info"
              onClick={openEditProfileModal}
              style={{ margin: "10px" }}
            >
              Editar
            </Button>
          </Col>
        </Row>
      </ContainerGrey>

      <H4>
        <FontAwesomeIcon icon={faUser} /> Datos Personales
      </H4>

      <ContainerGrey>
        <Padding pad="5px">
          <Row>
            <Col md={6}>
              <Label>Nombre Completo</Label>
              <P>{personal.name + " " + personal.surname}</P>
            </Col>
            <Col md={3}>
              <Label>Documento identidad</Label>
              <P>
                {personal.idType === IdType.CUIT
                  ? "CUIT " + personal.id
                  : "DNI " + personal.id}
              </P>
            </Col>
            <Col md={3} xs={12}>
              <Label>Fecha Nacimiento</Label>
              <P>
                <Moment date={personal.birthday.toDate()} format="DD/MM/YYYY" />
              </P>
            </Col>
          </Row>
        </Padding>
      </ContainerGrey>
      <ContainerGrey>
        <Padding pad="10px">
          <Row>
            <Col md={3}>
              <Label>Nacionalidad</Label>
              <P>{personal.country}</P>
            </Col>
            <Col md={3}>
              <Label>Estado civil</Label>
              <P>{CivilStatus[personal.civil_status]}</P>
            </Col>
            <Col md={3}>
              <Label>Ocupacion</Label>
              <P>{personal.job}</P>
            </Col>
            <Col md={3}>
              <Label>Telefono</Label>
              <P>{personal.phone.area_code + " " + personal.phone.number}</P>
            </Col>
          </Row>
        </Padding>
      </ContainerGrey>
      <ContainerGrey>
        <Padding pad="10px">
          <Row>
            <Col xs={6}>
              <Label>Dirección</Label>
              <P>{getFullAddress(personal.address)}</P>
            </Col>
            <Col xs={6}>
              <Label>Datos adicionales de dirección</Label>
              <P>{personal.address2}</P>
            </Col>
          </Row>
        </Padding>
      </ContainerGrey>
      <ContainerGrey>
        <Padding pad="10px">
          <Row>
            <Col xs={3}>
              <Label>Ciudad</Label>
              <P>{/* personal.address.address.district */}</P>
            </Col>
            <Col xs={3}>
              <Label>Codigo Postal</Label>
              <P>{personal.address.address.postal_code}</P>
            </Col>
            <Col xs={3}>
              <Label>Partido</Label>
              <P>{personal.address.address.locality}</P>
            </Col>
            <Col xs={3}>
              <Label>Provincia</Label>
              <P>{personal.address.address.province}</P>
            </Col>
          </Row>
        </Padding>
      </ContainerGrey>

      <H4>
        <FontAwesomeIcon icon={faIdCard} /> Datos Conductor
      </H4>

      <ContainerGrey>
        <Padding pad="10px">
          <Row>
            <Col md={3}>
              <Label>Numero registro</Label>
              <P>{licence.number}</P>
            </Col>
            <Col md={3}>
              <Label>Fecha de vencimiento</Label>
              <P>{licence.expire_date.toDate().toLocaleDateString()}</P>
            </Col>
            <Col md={3}>
              <Label>Licencia frente</Label>
              <P>
                <FbImage src={licence.photo_front} />
              </P>
            </Col>
            <Col md={3}>
              <Label>Licencia dorso</Label>
              <P>
                <FbImage src={licence.photo_back} />
              </P>
            </Col>
          </Row>
        </Padding>
      </ContainerGrey>

      <H4>
        <FontAwesomeIcon icon={faUniversity} /> Datos bancarios / AFIP
      </H4>

      <ContainerGrey>
        <Padding pad="10px">
          <Row>
            <Col xs={6}>
              <Label>CUIT</Label>
              <P>{taxable.cuit}</P>
            </Col>
            <Col xs={6}>
              {taxable.cbu ? (
                <>
                  <Label>CBU</Label>
                  <P>{taxable.cbu}</P>
                </>
              ) : (
                <>
                  <Label>CVU</Label>
                  <P>{taxable.cvu}</P>
                </>
              )}
            </Col>
          </Row>
        </Padding>
      </ContainerGrey>
      <ContainerGrey>
        <Padding pad="10px">
          <Row>
            <Col md={6}>
              <Label>Tipo de Inscripto</Label>
              <P>{TaxableEntityType[taxable.tax_entity]}</P>
            </Col>
            <Col md={6}>
              <Label>Punto de venta</Label>
              <P>{taxable.point_of_sale}</P>
            </Col>
          </Row>
        </Padding>
      </ContainerGrey>
      <ContainerGrey style={{ display: "inline-block", marginBottom: "0px" }}>
        <Row>
          <Col style={{ textAlign: "right" }}>
            {/*<Button variant="secondary" style={{margin:"10px"}}>Test Notificación</Button>
                <Link to={ROUTES.USER_PROFILE_HISTORY} style={{margin:"10px"}}>
                    <Button variant="secondary" style={{margin:"10px"}}>Historial</Button>
                </Link>*/}
            <Link to={ROUTES.UNSUSCRIBE}>
              <Button variant="info">Quiero darme de baja</Button>
            </Link>
          </Col>
        </Row>
      </ContainerGrey>
    </Padding>
  );
};

const Span = styled(Button)`
  cursor: auto !important;
  margin: 10px;
  &:focus {
    box-shadow: none !important;
  }
`;

const Label = styled.label`
  color: #27428b;
  font-size: 13px;
  margin-bottom: 0px;
`;
const P = styled.p`
  font-size: 24px;
  margin: 5px 0px 10px 0px;
  color: #888;
`;

const H4 = styled.h4`
  font-family: "Quicksand";
  font-weight: 700;
  padding: 8px 5px;
  margin-top: 21px;
  margin-bottom: 21px;
  font-size: 16px;
  color: #58595b;
`;

export default UserMyProfilePage;
