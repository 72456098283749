import firebase from "firebase/app";
import { ArtResponse, TransferEventMotive } from "types/report";
import {
  ModuleTransferType,
  ModuleTransfer,
  WaitingActionsAdminMotives,
} from "types/module-transfer";
import { ArtInformation, User } from "types/user-data";
import { FbDate } from "types/transfer";
import { Localities } from "types/cross";
import { CustomConfig, ZoneConfig, GlobalConfig } from "types/config";
import { WithId } from "controllers/database";
import { FirePoint } from "geofirex";

const BACKEND_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001/satapp-27658/southamerica-east1/api"
    : "https://southamerica-east1-satapp-27658.cloudfunctions.net/api";
const callFunc = (d: string, data: any) =>
  firebase
    .app()
    .functions(
      process.env.NODE_ENV === "development" ? undefined : "southamerica-east1"
    )
    .httpsCallable(d)(data);

/** Callable Cloud Functions */
const CALLABLE_FUNCTIONS = {
  ADMIN_REVERT_CROSS_PRICES: (d?: any) => callFunc("cross-revertLastPrice", d),
  ADMIN_ADJUST_CROSS_PRICES: (d: any) => callFunc("cross-adjustPrices", d),
  ADMIN_EDIT_CROSS_DATABASE: (d: {
    modulo?: number;
    negativo?: number;
    tramo?: number;
    id: string;
    date?: FbDate;
    kmGoogle?: number;
    raw_valid_from?: number;
    raw_valid_to?: number;
    editingPastPrices?: boolean;
  }) => callFunc("cross-editCross", d),
  ADMIN_EDIT_LOCALITY_DATABASE: (d: {
    id: string;
    locality: string;
    locality_id: string;
    province: string;
    province_id: string;
  }) => callFunc("cross-editLocality", d),
  ADMIN_ADD_CROSS_DATABASE: (d: any) => callFunc("cross-addCross", d),
  ADMIN_ADD_LOCALITY_DATABASE: (d: Localities) =>
    callFunc("locality-addLocality", d),
  ADMIN_DELETE_LOCALITY_DATABASE: (d: { id: string }) =>
    callFunc("locality-deleteLocality", d),
  ADMIN_CANCEL_PAYMENT_ORDER: (d: { id: string }) =>
    callFunc("billing-cancelPaymentOrder", d),
  REVERT_NEGATIVE: (d: { idTransfer: string; uidDriver: string }) =>
    callFunc("transfer-revertNegative", d),
  ADMIN_COORDINATE_SCHEDULE: (d: {
    idTransfer: string;
    motive: TransferEventMotive;
    uid: string;
  }) => callFunc("transfer-coordinateSchedule", d),
  SEND_DRIVER_CANCELATION_MAIL: (d: {
    idTransfer: string;
    uidDriver: string;
  }) => callFunc("billing-notifyDriverCancelation", d),
  SEND_UNSUSCRIBE_MAIL: (d: {
    emailUser: string;
    motive: string;
    data: string;
  }) => callFunc("billing-notifyDriverCancelation", d),
  FORCE_BILL_NEGATIVE_RETURNS: (d?: any) =>
    callFunc("billing-billAllNegativeReturn", d),
  FORCE_BILL_NEGATIVE_RETURNS_BYKM: (d?: any) =>
    callFunc("billing-BillAllNegativeReturnByKm", d),
  FORCE_MULTIPLE_BILLS_BONIF: (d: {
    modules: { idModule: string; bonif: number }[];
    artId: string;
    manual?: boolean;
  }) => callFunc("billing-forceArtBillMultipleBonif", d),
  ADMIN_FORCE_EXPERTA_BILL: (d: { startDate: any }) =>
    callFunc("billing-forceExpertaBill", d),
  FACTURAR_CONJUNTO: (d: { desde: any; hasta: any }) =>
    callFunc("billing-facturarConjunto", d),
  ADMIN_FIX_PRICE: (d: { refTransfer: string; uidDriver: string }) =>
    callFunc("transfer-fixPrice", d),
  ADMIN_CHANGE_MODULE_TYPE: (d: { idModule: string; newType: number }) =>
    callFunc("transfer-changeModuleType", d),
  ADMIN_HANDLE_NEGATIVE_MODULE: (d: {
    smlNegativo?: string;
    authNegativo?: string;
    moduleId: string;
    return?: boolean;
    authExitoso?: string;
    smlExitoso?: string;
    negative?: boolean;
  }) => callFunc("transfer-handleNegativeModule", d),
  CREATE_BILL_PDF: (d: { idArtBill: string }) =>
    callFunc("billing-createBillPdf", d),
  SEND_REQUEST_SML: (d: string) => callFunc("report-requestSML", d),
  SEND_REQUEST_NEGATIVE: (d: string) => callFunc("report-requestNegative", d),
  SEND_REQUEST_MAIL: (d: string) => callFunc("report-requestMail", d),
  ADMIN_RUN_BACK: (d?: any) => callFunc("transfer-controllerTests", d),
  ADMIN_CHANGE_PRICE: (d?: any) => callFunc("transfer-priceChange", d),
  //CREADOR DE VOUCHERS
  CREATE_VOUCHERS: (d: { idArtBill: string }) =>
    callFunc("billing-createVouchers", d),
  //CREADOR DE VOUCHERS
  RESEND_BILL: (d: { idArtBill: string }) => callFunc("billing-resendBill", d),
  CREATE_DEBIT_NOTE: (d: { idArtBill: string }) =>
    callFunc("billing-createDebitNote", d),
  ADMIN_RETURN_ACTIVE: (d: {
    refTransfer: string;
    uidDriver: string;
    isDriver: boolean;
  }) => callFunc("transfer-returnActive", d),
  //terminar este
  ADMIN_GENERATE_PDF: (d: { modules: any[] }) =>
    callFunc("billing-generatePDF", d),
  //terminar este
  ADMIN_CHANGE_TRANSFER_STATE: (d: {
    state: string;
    idTransfer: string;
    uidDriver: string;
  }) => callFunc("transfer-changeTransferState", d),
  ADMIN_MODULE_TRANSFER_FORCE_RETURN: (d: {
    idModule: string;
    userAdmin: User;
  }) => callFunc("transfer-forceReturnAdmin", d),
  ADMIN_MODULE_TRANSFER_CREATE: (d: OnCallCreateModuleTransfer) =>
    callFunc("transfer-create", d),
  ADMIN_MODULE_TRANSFER_CREATE_EXCEL: (d: OnCallCreateModuleTransferExcel) =>
    callFunc("transfer-createExcel", d),
  ADMIN_WRONG_MODULE_REMAKE: (data: any) =>
    callFunc("transfer-remakeModuleTransfer", data),
  ADMIN_MODULE_TRANSFER_CANCEL: (
    idModule: string,
    republish?: boolean,
    silent?: boolean,
    provider?: string,
    apiExperta?: boolean
  ) =>
    callFunc("transfer-cancelModule", {
      idModule,
      republish: republish ? true : false,
      silent,
      provider,
      apiExperta,
    }),
  ADMIN_MODULE_REPUBLISH_BREACH: (d: { idModule: string }) =>
    callFunc("transfer-republishBreach", d),
  ADMIN_MODULE_SEND_REPORT_ART: (data: { idModule: string; minutes: number }) =>
    callFunc("transfer-sendReportMailArt", data),
  GENERATE_EXCEL: (startDate: any, endDate: any, mailsSelected: string[]) =>
    callFunc("billing-generateExcel", {
      startDate,
      endDate,
      mailsSelected,
    }),
  GENERATE_EXCEL_NEW: (queryParams: {
    driver?: string;
    startDate?: any;
    endDate?: any;
    type?: number;
    mailsSelected: string[];
  }) => callFunc("billing-generateExcelNew", queryParams),
  ADMIN_TRANSFER_EDIT: (idTransfer: string, idDriver: string, data: any) =>
    callFunc("transfer-editTransfer", { idTransfer, idDriver, ...data }),
  ADMIN_ADD_SML_ID: (idModule: string, transfer_id: string) =>
    callFunc("transfer-addTransferID", { idModule, transfer_id }),
  ADMIN_ADD_CROSS: (idModule: string, cross: any) =>
    callFunc("transfer-addCross", { idModule, cross }),
  SEND_PAYMENT_MAIL: (d: any) => callFunc("billing-sendDriverPayment", d),
  ADMIN_BILL_CABIFY_EDIT: (idTransfer: string, idDriver: string, data: any) =>
    callFunc("transfer-editBillCabify", { idTransfer, idDriver, data }),
  ADMIN_TRANSFER_EDIT_CABIFY: (
    idTransfer: string,
    idDriver: string,
    cabify: string
  ) =>
    callFunc("transfer-editTransferCabify", { idTransfer, idDriver, cabify }),
  ADMIN_FORCE_STAGES_CABIFY: (
    idTransfer: string,
    idDriver: string,
    tramo: string
  ) => callFunc("transfer-endStageForce", { idTransfer, idDriver, tramo }),
  ADMIN_FORCE_END: (idDriver: string) =>
    callFunc("transfer-forceEnd", { idDriver }),
  ADMIN_FORCE_REPUBLISH_SCHEDULED: (idDriver: string) =>
    callFunc("transfer-republishAllScheduled", { idDriver }),
  ADMIN_FORCE_SMS: (idTransfer: string, idDriver: string) =>
    callFunc("transfer-sendSMS", { idTransfer, idDriver }),
  ADMIN_MODULE_TRANSFER_EDIT: (idModule: string, data: any) =>
    callFunc("transfer-editModule", { idModule, ...data }),
  /* ADMIN_MODULE_TRANSFER_SEND_FELEVAL: (idModule: string) =>
    callFunc("transfer-buttonSendFeleval", idModule), */
  /* ADMIN_MODULE_TRANSFER_SEND_STRETCH_FELEVAL: (idModule: string) =>
    callFunc("transfer-buttonSendStretchFeleval", idModule), */
  ADMIN_REMOVE_ALERT: (idModule: string) =>
    callFunc("transfer-removeAlert", idModule),
  ADMIN_UPLOAD_BILLING_FELEVAL: (data: any) =>
    callFunc("billing-uploadBillingFeleval", { data }),

  //onCallbuttonCancelStretchFeleval,

  /* ADMIN_MODULE_TRANSFER_CANCEL_FELEVAL: (idModule: string) =>
    callFunc("transfer-buttonCancelFeleval", idModule), */
  REPORT_CREATE_CABIFY: (
    idTransfer: string,
    idDriver: string,
    motive: number,
    isLate?: boolean
  ) =>
    callFunc("report-createCabify", { idTransfer, idDriver, motive, isLate }),
  REPORT_CREATE_MANUAL: (
    idTransfer: string,
    idDriver: string,
    motive: number,
    isLate?: boolean,
    manual?: boolean
  ) =>
    callFunc("report-createReportManual", {
      idTransfer,
      idDriver,
      motive,
      isLate,
      manual,
    }),
  SEND_EMAIL_CABIFY: (idTransfer: string, idDriver: string, time: any) =>
    callFunc("report-sendEmailCabify", { idTransfer, idDriver, time }),
  CANCEL_MODULE_CABIFY: (idModule: string) =>
    callFunc("report-cancelModuleCabify", { idModule }),
  CHANGE_MODULE_CABIFY: (idModule: string) =>
    callFunc("report-changeModuleCabify", { idModule }),
  REPORT_CANCEL: (idTransfer: string, uid: string) =>
    callFunc("report-cancelCabify", { idTransfer, uid }),
  ADMIN_ART_REPORT_CHANGE_LOCATION: (d: any) =>
    callFunc("report-artChangeLocation", d),
  ADMIN_ART_REPORT_RESOLUTION: (d: {
    uidDriver?: string;
    buy15?: boolean;
    response?: ArtResponse;
    on_resolution?: boolean;
    customMessage?: string;
    idTransfer?: string;
  }) => callFunc("report-artAdminResolution", d),
  ADMIN_ART_REPORT_RESOLUTION_CABIFY: (d: {
    uidDriver?: string;
    idTransfer?: string;
  }) => callFunc("report-artAdminResolutionCabify", d),
  ADMIN_ART_REPORT_RESOLUTION_CABIFY_CUSTOM: (d: {
    uidDriver?: string;
    idTransfer?: string;
    custom?: string;
  }) => callFunc("report-artAdminResolutionCabifyCustom", d),
  ADMIN_NEW_ART: (d: OnCallNewArt) => callFunc("user-createArt", d),
  ADMIN_CHANGE_PRICE_SALE: (d: {
    data: (CustomConfig & WithId) | (ZoneConfig & WithId);
    type: string;
  }) => callFunc("transfer-changePrice", d),
  ADMIN_GENERATE_CONTRACT_PDF: (d: { uidUser: string; idContract: string }) =>
    callFunc("user-generateContractPdf", d),
  PASSENGER_ASK_RETURN: (d: { idModule: string; isDriver: boolean }) =>
    callFunc("passenger-askReturn", d),
  ART_LATE_MODULE_TRANSFER: (idCanceledTransfer: string, republish: boolean) =>
    callFunc("transfer-handleLateModuleTransfer", {
      idCanceledTransfer,
      republish,
    }),
  ADMIN_MODULE_TRANSFER_INSTRUCTION_TO_CONTINUE: (d: {
    idModule: string;
    decision: AdminDecision;
    motive?: WaitingActionsAdminMotives;
  }) => callFunc("transfer-handleAdminInstruction", d),

  ADMIN_FORCE_MULTIPLE_DRIVER_BILLS: (d: {
    transfers: { uidDriver?: string; idTransfer?: string }[];
    allInOneBill?: boolean;
    forced?: boolean;
    gestorando?: boolean;
  }) => callFunc("billing-forceMultipleDriverBills", d),
  //Facturación forzada
  ADMIN_FORCE_MULTIPLE_ART_BILLS: (d: {
    idModules?: string[];
    allInOneBill?: boolean;
  }) => callFunc("billing-forceMultipleArtBills", d),
  ADMIN_FORCE_ART_BILL_WITH_BONIF: (d: { idModule?: string; bonif: number }) =>
    callFunc("billing-forceArtBillBonif", d),
  ADMIN_SCHEDULE_POOL: (d: {
    transfer1: string;
    transfer2: string;
    uid: string;
    pool: boolean;
    double: boolean;
    duration: string;
    geoOrigin?: { latitude: number; longitude: number };
    geoDestination?: { latitude: number; longitude: number };
  }) => callFunc("transfer-schedulePool", d),
  USER_SCHEDULE_TRANSFER: (transferId: string) =>
    callFunc("transfer-schedule", transferId),
  USER_STARTER_FORM: () => callFunc("user-startedNewcomersForm", {}),
  USER_FINISHED_FORM: () => callFunc("user-finishedNewcomersForm", {}),
  USER_ACCEPT_CONTRACT: () => callFunc("user-acceptContract", {}),
  USER_REGISTER_GESTORANDO: (uid: string) =>
    callFunc("user-registerGestorando", { uid }),
  USER_UNSUSCRIBE: (d: { name: string; email: string; reason: string }) =>
    callFunc("user-emailUnsuscribe", d),
  USER_RESEND_INFO_GESTORANDO: (d: { id: string; cuit: string }) =>
    callFunc("user-resendInfoGestorando", d),
  USER_ADD_TOLL: (d: {
    idTransfer: string;
    toll: string;
    onReturn: boolean;
    amount: number;
  }) => callFunc("user-addTollTransfer", d),
  REGISTER_USER: (d: any) => callFunc("user-create", d),
  DOWLOAD_PDF_GESTORANDO: (d: { idBill: string }) =>
    callFunc("billing-downloadPdfGestorando", d),
  DELETE_TRANSFER_BILL: (d: { idBill: string; justAFIP?: boolean }) =>
    callFunc("billing-deleteDriverBill", d),
  DELETE_ART_BILL: (d: { idArtBill: string; justAFIP?: boolean }) =>
    callFunc("billing-deleteArtBill", d),
  CONSULT_BILL_LIQUIDATION: (d: { voucher_number: number }) =>
    callFunc("billing-consultLiquidation", d),
  MANUAL_ART_BILL: (d: {
    idModules: string[];
    pdf: string;
    CAEFchVto: string;
    voucher_number: string;
    point_of_sale: string;
  }) => callFunc("billing-manualArtBill", d),
  MANUAL_DRIVER_BILL: (d: {
    uidDriver: string;
    idTransfers: string[];
    pdf: string;
    CAEFchVto: string;
    voucher_number: string;
    point_of_sale: string;
    creation_date: string;
  }) => callFunc("billing-manualDriverBill", d),

  SEND_EMAIL_ART_BILL: (d: { idBill: string }) =>
    callFunc("billing-sendEmailArtBill", d),
  SEND_EMAIL_DRIVER_BILL: (d: { idBill: string }) =>
    callFunc("billing-sendEmailDriverBill", d),
};

const HTTPS = {
  API: "/api",
  API_USER: `${BACKEND_URL}/user`,
  API_TRANSFERS: `${BACKEND_URL}/transfer`,
  API_MAP_ADDRESS: `${BACKEND_URL}/map/address`,
  API_MAP_LOCATION: `${BACKEND_URL}/map/location`,
  API_MAP_COMPLETE_ADDRESS: `${BACKEND_URL}/map/complete-address`,
};

export enum AdminDecision {
  cancel,
  republish,
  publish,
  breach,
  republishBreach,
  inform,
  schedule,
}

export interface OnCallCreateModuleTransfer {
  origin_date?: string;
  destination_date: string;
  backtrack_date?: string;

  origin_address: string;
  destination_address: string;
  backtrack_address?: string;

  passenger: {
    dni: string;
    name: string;
    tel: string;
  };
  type: ModuleTransferType;
  art_id_accident: string;
  art_id_expert: string;
  art_id_authorization: string;
  art_id_npa?: string;
  wheelchair?: boolean;
  acom_aut?: boolean;
  provider?: string | undefined;
  art: string;
  obs: string;
}

export interface OnCallCreateModuleTransferExcel {
  origin_date?: string;
  destination_date: string;
  backtrack_date?: string;

  origin_coords?: { lat: number; lng: number };
  origin_address?: { street: string; locality: string; province: string };
  destination_coords?: { lat: number; lng: number };
  destination_address?: { street: string; locality: string; province: string };
  backtrack_address?: { street: string; locality: string; province: string };
  backtrack_coords?: { lat: number; lng: number };

  passenger: {
    dni: string;
    name: string;
    tel: string;
  };
  type: ModuleTransferType;
  art_id_accident: string;
  art_id_expert: string;
  art_id_authorization: string;
  art_id_npa?: string;
  wheelchair?: boolean | undefined;
  acom_aut?: boolean | undefined;
  provider?: string | undefined;
  art: string;
  obs: string;
}

export interface OnCallNewArt
  extends User,
    Omit<ArtInformation, "billing_address"> {
  billing_address: string;
  password: string;
}

export { HTTPS, CALLABLE_FUNCTIONS };
