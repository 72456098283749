import { Divider, Timeline } from "antd";
import { Button, Spinner } from "components/styles";
import { useDatabaseDocref, useDbDocument } from "controllers/hooks";
import { getPersonalInformationColDoc } from "controllers/user/info/personal";
import moment from "moment";
import Moment from "react-moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { isLoaded } from "react-redux-firebase";
import styled from "styled-components";
import {
  ModuleTransfer,
  ModuleTransferAction,
  ModuleTransferHistoryAction,
} from "types/module-transfer";
import { FbDocument, ScheduledTransfer } from "types/transfer";
import { PersonalInformation } from "types/user-data";
import { HistoryScheduledTransfer } from "../../scheduled/modals/history";

export const HistoryModal = ({
  module,
  show,
  handleClose,
}: {
  module: ModuleTransfer;
  show: boolean;
  handleClose: any;
}) => {
  const moduleIsActive =
    module.transfers.active !== undefined ||
    module.transfers.searching_driver !== undefined;
  let cont = 0;
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Historial Traslado</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ padding: "0 2rem" }}>
        {module.transfers.past?.going &&
          module.transfers.past.going.map((r) => {
            cont += 1;
            return (
              <React.Fragment key={r.id}>
                <TransferDivider>{`Traslado ${cont}`}</TransferDivider>
                <div style={{ textAlign: "center" }}>
                  <Moment
                    date={module.destination.time.toDate()}
                    format="DD/MM/YYYY"
                  />
                </div>
                <HistoryTransfer refTransfer={r} />
              </React.Fragment>
            );
          })}
        {module.transfers.past?.return &&
          module.transfers.past.return.map((r) => {
            cont += 1;
            return (
              <React.Fragment key={r.id}>
                <TransferDivider>{`Traslado ${cont}`}</TransferDivider>
                <HistoryTransfer refTransfer={r} />
              </React.Fragment>
            );
          })}
        {moduleIsActive && (
          <>
            <TransferDivider>Traslado {cont + 1}</TransferDivider>
            {module.transfers.active ? (
              <HistoryTransfer refTransfer={module.transfers.active} />
            ) : (
              module.transfers.searching_driver && <HistoryTranferRequest />
            )}
          </>
        )}
        {module.status.history?.actions?.length !== undefined &&
          module.status.history.actions.length > 0 && (
            <>
              <TransferDivider>
                HISTORIAL ACCIONES TOMADAS POR ADMIN
              </TransferDivider>
              <HistoryActionsAdmin
                actions={module.status.history.actions}
                timeInterval={new Interval()}
              />
            </>
          )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const HistoryTransfer = ({
  refTransfer,
}: {
  refTransfer: FbDocument<ScheduledTransfer>;
}) => {
  const uidDriver = refTransfer.parent.parent!.id;
  const transfer = useDatabaseDocref(refTransfer);
  const driverData = useDbDocument<PersonalInformation>(
    ...getPersonalInformationColDoc(uidDriver)
  );

  if (!isLoaded(transfer, driverData)) return <Spinner />;

  if (!transfer || !driverData) return <>Error</>;

  return (
    <>
      <div style={{ marginBottom: 20, textAlign: "center" }}>
        Conductor: <b>{driverData.name + " " + driverData.surname}</b>
      </div>
      <HistoryScheduledTransfer transfer={transfer} />
    </>
  );
};

const HistoryTranferRequest = () => (
  <Timeline mode="left" style={{ marginTop: 15 }}>
    <Timeline.Item color="#faad14" label={" "}>
      Buscando Conductor
    </Timeline.Item>
  </Timeline>
);

const HistoryActionsAdmin = ({
  actions,
  timeInterval,
}: {
  actions: ModuleTransferHistoryAction[];
  timeInterval: Interval;
}) => {
  const _actions = actions.filter((a) =>
    timeInterval.isInside(moment(a.time.toDate()))
  );
  return (
    <Timeline mode="left" style={{ marginTop: 15 }}>
      {_actions.map((a) => (
        <Timeline.Item
          key={a.time.toDate().toString()}
          color="red"
          label={moment(a.time.toDate()).format("HH:mm")}
        >
          {ModuleTransferAction[a.action]}
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

const TransferDivider = styled(Divider)`
  margin-bottom: 0px !important;
`;

class Interval {
  begin: moment.Moment | undefined;
  end: moment.Moment | undefined;
  constructor(begin?: moment.Moment, end?: moment.Moment) {
    this.begin = begin;
    this.end = end;
  }
  isInside(d: moment.Moment) {
    if (this.begin === undefined && this.end === undefined) return true;

    if (this.begin === undefined) return d.diff(this.end, "minutes") < 0;

    if (this.end === undefined) return d.diff(this.begin, "minutes") > 0;

    return d.diff(this.begin, "minutes") > 0 && d.diff(this.end, "minutes") < 0;
  }
}
