import { Button, H2, NoData, Padding, Spinner } from "components/styles";
import { FIRESTORE } from "constants/firestore";
import ROUTES from "constants/routes";
import React, { useEffect, useState } from "react";
import { Form, Table as TableB, Col, Row } from "react-bootstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  FinishStatus,
  DefinitionsTransferType,
  TransferStageDefinitions,
} from "types/transfer";
import MiniNavigationErrors from "../MiniNavigation";
import { usePaginator } from "util/hooks";

const AdminTransfersSatappInterno = () => {
  return (
    <MiniNavigationErrors active={2}>
      <MainContainer
        viewTransfer={ROUTES.ADMIN_MODULE_TRANSFER_VIEW}
        art={false}
      />
    </MiniNavigationErrors>
  );
};

export const MainContainer = ({
  viewTransfer,
}: {
  viewTransfer: (a: any) => string;
  art: boolean;
}) => {
  const [items, loading, Pagination, setCollection] = usePaginator(
    FIRESTORE.COLLECTION_USER_TRANSFERS("2PRTXVK5kXVpiPvYIkui8TN0aUt1").orderBy(
      "origin.time",
      "desc"
    ),
    50
  );
  const [items2, loading2, Pagination2, setCollection2] = usePaginator(
    FIRESTORE.COLLECTION_USER_TRANSFERS("2PRTXVK5kXVpiPvYIkui8TN0aUt1")
      .where("status.stage", "==", 0)
      .orderBy("origin.time", "desc"),
    50
  );
  const [items3, loading3, Pagination3, setCollection3] = usePaginator(
    FIRESTORE.COLLECTION_USER_TRANSFERS("2PRTXVK5kXVpiPvYIkui8TN0aUt1")
      .where("status.finished_status", "==", 1)
      .orderBy("origin.time", "desc"),
    50
  );

  const [filter, setFilter] = useState("Todos los traslados");
  return (
    <Padding pad="5px">
      <Row>
        <Col md={2}>
          <H2>Satapp Interno</H2>
        </Col>
      </Row>

      <Form.Control
        as="select"
        value={filter}
        onChange={(event) => setFilter(event.target.value)}
      >
        <option key={0} value={"Todos los traslados"}>
          Todos los traslados
        </option>
        <option key={1} value={"Agendados"}>
          Agendados
        </option>
        <option key={2} value={"Cancelados"}>
          Cancelados
        </option>
      </Form.Control>
      {loading || loading2 || loading3 ? (
        <Spinner style={{ margin: 20 }} />
      ) : (
        <Table hover responsive>
          <thead>
            <Tr>
              <th>ART</th>
              <th>Fecha</th>
              <th>Siniestro-Aut/NPA</th>
              <th>Tipo</th>
              <th>Estado</th>
              <th>Ver</th>
            </Tr>
          </thead>
          <tbody>
            {filter === "Todos los traslados" ? (
              <>
                {items.map((item) => (
                  <tr key={item.id}>
                    <Td>{item.art.name}</Td>
                    <Td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY HH:mm"
                      />
                    </Td>
                    <Td style={{ wordBreak: "break-all" }}>
                      {item.art.accident && item.art.authorization
                        ? item.art.accident + "-" + item.art.authorization
                        : item.art.accident
                        ? item.art.accident
                        : "NPA: " + item.art.npa}
                    </Td>
                    <Td>{DefinitionsTransferType[item.type]}</Td>
                    <Td>
                      {item.status.finished_status
                        ? TransferStageDefinitions[item.status.stage] +
                          ` (${FinishStatus[item.status.finished_status]})`
                        : TransferStageDefinitions[item.status.stage]}
                    </Td>
                    <Td>
                      <Link to={viewTransfer(item.module.id)}>
                        <Button variant="light">Ver</Button>
                      </Link>
                    </Td>
                  </tr>
                ))}
                <Pagination />
              </>
            ) : filter === "Agendados" ? (
              <>
                {items2.map((item) => (
                  <tr key={item.id}>
                    <Td>{item.art.name}</Td>
                    <Td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY HH:mm"
                      />
                    </Td>
                    <Td style={{ wordBreak: "break-all" }}>
                      {item.art.accident && item.art.authorization
                        ? item.art.accident + "-" + item.art.authorization
                        : item.art.accident
                        ? item.art.accident
                        : "NPA: " + item.art.npa}
                    </Td>
                    <Td>{DefinitionsTransferType[item.type]}</Td>
                    <Td>
                      {item.status.finished_status
                        ? TransferStageDefinitions[item.status.stage] +
                          ` (${FinishStatus[item.status.finished_status]})`
                        : TransferStageDefinitions[item.status.stage]}
                    </Td>
                    <Td>
                      <Link to={viewTransfer(item.module.id)}>
                        <Button variant="light">Ver</Button>
                      </Link>
                    </Td>
                  </tr>
                ))}
                <Pagination2 />
              </>
            ) : filter === "Cancelados" ? (
              <>
                {items3.map((item) => (
                  <tr key={item.id}>
                    <Td>{item.art.name}</Td>
                    <Td>
                      <Moment
                        date={item.origin.time.toDate()}
                        format="DD/MM/YYYY HH:mm"
                      />
                    </Td>
                    <Td style={{ wordBreak: "break-all" }}>
                      {item.art.accident && item.art.authorization
                        ? item.art.accident + "-" + item.art.authorization
                        : item.art.accident
                        ? item.art.accident
                        : "NPA: " + item.art.npa}
                    </Td>
                    <Td>{DefinitionsTransferType[item.type]}</Td>
                    <Td>
                      {item.status.finished_status
                        ? TransferStageDefinitions[item.status.stage] +
                          ` (${FinishStatus[item.status.finished_status]})`
                        : TransferStageDefinitions[item.status.stage]}
                    </Td>
                    <Td>
                      <Link to={viewTransfer(item.module.id)}>
                        <Button variant="light">Ver</Button>
                      </Link>
                    </Td>
                  </tr>
                ))}
                <Pagination3 />
              </>
            ) : (
              ""
            )}
          </tbody>
        </Table>
      )}
    </Padding>
  );
};

const Tr = styled.tr`
  th {
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  word-wrap: break-word;
`;

const Table = styled(TableB)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default AdminTransfersSatappInterno;
